import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Skeleton from "react-loading-skeleton";
import DeliveryCard from "./DeliveryCard";
import { connect } from "react-redux";
import { groupByProductCategories } from "../utils/functions";
import { getItemsCategoryOrder, getPopularItems } from "../utils/api";
import ProductLoader from "./ProductLoader";
import { Form, FormControl } from "react-bootstrap";
import { FaSearch, FaRegWindowClose } from "react-icons/fa";
import InputGroup from "react-bootstrap/InputGroup";
import BrandingHeader from "./BrandingHeader";
// import ButtonGroup from "react-bootstrap/ButtonGroup";
// import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Cart } from "../utils";
import CartSide from "../pages/cart-sidebar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import { Helmet } from "react-helmet";
import moment from "moment-timezone";
import MultiLingualContent from "../context/multilingualContent";
import { translations } from "../context/translations";
import CustomDeliveryCard from "./DeliveryCard/CustomDeliveryCard";

const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
const day = {
  0: "sunday",
  1: "monday",
  2: "tue",
  3: "wed",
  4: "thurs",
  5: "fri",
  6: "sat",
};
var nextDay = day[new Date().getDay() + 1];
const dayOrder = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
function ShowMenuEmpty() {
  return (
    <div className="text-center mt-5 no-cart">
      <h4>This menu is empty 😢</h4>
    </div>
  );
}
class DeliveryMenu extends Component {
  state = {
    search: "",
    searchTemp: "",
    filter: "",
    items: [],
    orders: [],
    menu: [],
    saran: false,
    tabContent: "",
    searchshow: false,
    popular: [],
    orders1: [],
  };
  translatedWord = (word) =>
    translations[this.props.restaurant.language]
      ? translations[this.props.restaurant.language][word]
      : translations["en"][word];
  async componentDidMount() {
    // console.log(this.props.items);
    try {
      if (window.innerWidth < 768) {
        let navbar = document.getElementById("nav-section");
        let navOffset = navbar.offsetTop;
        window.addEventListener("scroll", () => {
          window.scrollY >= 100
            ? navbar.classList.add("fix")
            : navbar.classList.remove("fix");
        });
      } else {
      }
      window.scrollTo(0, 0);
      var order = await getItemsCategoryOrder(
        this.props.restaurant_id,
        this.props.restaurant.language
      );
      let Others = this.translatedWord("Others");
      order = [...order, Others, "Popular Items"];
      let popularItems = await getPopularItems(
        this.props.restaurant_id,
        this.props.restaurant.integrate,
        this.props.restaurant.language
      );
      // console.log(popularItems);
      this.setState({ popular: popularItems ? popularItems.slice(0, 5) : [] });
      this.setState({ orders: [...order] });

      let m = [];
      this.state.orders.map((cat) =>
        Object.values(this.props.items)
          .filter((item) => item.stock && item.category.includes(cat))
          .map((item) => {
            !m.includes(item.category) && m.push(item.category);
          })
      );

      this.forceUpdate();
      this.setState({
        menu: this.state.orders.filter((i) => m.includes(i)),
      });
      this.forceUpdate();

      /**** more button */
      if (window.innerWidth < 768) {
      } else {
        let More = this.translatedWord("More");
        const container = document.querySelector(".tabs");
        const primary = container.querySelector(".-primary");
        const primaryItems = container.querySelectorAll(
          ".-primary > li:not(.-more)"
        );
        container.classList.add("--jsfied");
        primary.insertAdjacentHTML(
          "beforeend",
          `
 <li class="-more">
 <button type="button" aria-haspopup="true" aria-expanded="false">
 ${More} <img src="/arrowmore.webp" alt="more"/>
 </button>
 <ul class="-secondary">
 ${primary.innerHTML}
 </ul>
 </li>
 `
        );
        const secondary = container.querySelector(".-secondary");
        const secondaryItems = secondary.querySelectorAll("li");
        const allItems = container.querySelectorAll("li");
        const moreLi = primary.querySelector(".-more");
        const moreBtn = moreLi.querySelector("button");
        moreBtn.addEventListener("click", (e) => {
          e.preventDefault();
          container.classList.toggle("--show-secondary");
          moreBtn.setAttribute(
            "aria-expanded",
            container.classList.contains("--show-secondary")
          );
        });
        var selectD = container.querySelectorAll(".-secondary li");
        selectD.forEach((item) => {
          item.addEventListener("click", (e) => {
            // e.preventDefault(); //
            const list = document.getElementById("categorylist");
            var items = list.getElementsByTagName("li");
            // console.log("items", items);
            for (var i = 0; i < items.length; ++i) {
              if (
                items[i].getAttribute("name") === e.target.getAttribute("name")
              ) {
                items[i].classList.add("active");
              } else {
                items[i].classList.remove("active");
              }
            }

            this.setState({ filter: e.target.innerText });
            this.setState({ search: "" });
            var more = document.querySelector(".-more");
            more.click();
          });
        });

        const doAdapt = () => {
          // reveal all items for the calculation
          allItems.forEach((item) => {
            item.classList.remove("--hidden");
          });
          var stopWidth = moreBtn.offsetWidth + 50;
          var primaryWidth = 450;

          //let stopWidth = moreBtn.offsetWidth + 50
          //console.log("stopWidth", stopWidth)
          let hiddenItems = [];
          //const primaryWidth = 600
          //const primaryWidth = primary.offsetWidth

          if (window.innerWidth > 1450) {
            primaryItems.forEach((item, i) => {
              if (i <= 5) {
                stopWidth += item.offsetWidth;
              } else {
                item.classList.add("--hidden");
                hiddenItems.push(i);
              }
              // if (primaryWidth >= stopWidth + item.offsetWidth) {
              // stopWidth += item.offsetWidth;
              // // console.log("if", stopWidth + item.offsetWidth)
              // } else {
              // item.classList.add("--hidden");
              // hiddenItems.push(i);
              // // console.log("else", stopWidth);
              // }
            });
          } else {
            primaryItems.forEach((item, i) => {
              if (i <= 3) {
                stopWidth += item.offsetWidth;
              } else {
                item.classList.add("--hidden");
                hiddenItems.push(i);
              }
              // if (primaryWidth >= stopWidth + item.offsetWidth) {
              // stopWidth += item.offsetWidth;
              // // console.log("if", stopWidth + item.offsetWidth)
              // } else {
              // item.classList.add("--hidden");
              // hiddenItems.push(i);
              // // console.log("else", stopWidth);
              // }
            });
          }

          // toggle the visibility of More button and items in Secondary
          if (!hiddenItems.length) {
            moreLi.classList.add("--hidden");
            container.classList.remove("--show-secondary");
            moreBtn.setAttribute("aria-expanded", false);
          } else {
            secondaryItems.forEach((item, i) => {
              if (!hiddenItems.includes(i)) {
                item.classList.add("--hidden");
              }
            });
          }
        };

        doAdapt(); // adapt immediately on load
        window.addEventListener("resize", doAdapt); // adapt on window resize

        // hide Secondary on the outside click

        document.addEventListener("click", (e) => {
          let el = e.target;

          while (el) {
            if (el === secondary || el === moreBtn) {
              return;
            }
            el = el.parentNode;
          }
          container.classList.remove("--show-secondary");
          moreBtn.setAttribute("aria-expanded", false);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleShow = (e) => {
    this.setState({
      searchshow: true,
    });
    document.getElementById("searchopen").style.display = "none";
  };
  handleClose = (e) => {
    this.setState({
      searchshow: false,
      search: "",
    });
    document.getElementById("searchopen").style.display = "block";
  };
  handleChange = (e) => {
    this.setState({
      search: e.target.value.toUpperCase(),
      searchTemp: e.target.value,
    });
  };
  itemsIterate = (items) => {
    return Object.values(items).map((item) => this.cardRender(item));
  };

  filterChange = (e) => {
    const list = document.getElementById("categorylist");
    var items = list.getElementsByTagName("li");
    // console.log("items", items);
    for (var i = 0; i < items.length; ++i) {
      if (items[i].getAttribute("name") === e.target.getAttribute("name")) {
        items[i].classList.add("active");
      } else {
        items[i].classList.remove("active");
      }
    }

    this.setState({ filter: e.target.getAttribute("name") });
    this.setState({ search: "" });
  };
  cardRender = (ite) => {
    let item = this.props.items[ite.title];
    const { integrate } = this.props.restaurant;
    return (
      <>
        {" "}
        {item ? (
          integrate === "custom" ? (
            <CustomDeliveryCard
              key={item.title}
              img={item.img}
              modifierGroups={item.modifierGroups}
              title={item.title}
              price={item.price}
              modifiersPriceClicked={item.modifiersPriceClicked}
              description={item.desc}
              num={item.num}
              stock={item.stock}
              hidden={item.hidden}
              isOpen={this.props.restaurant.isOpen}
              id={item.productId}
            />
          ) : (
            <DeliveryCard
              key={item.title}
              img={item.img}
              modifierGroups={item.modifierGroups}
              title={item.title}
              price={item.price}
              modifiersPriceClicked={item.modifiersPriceClicked}
              description={item.desc}
              num={item.num}
              stock={item.stock}
              hidden={item.hidden}
              isOpen={this.props.restaurant.isOpen}
              id={item.productId}
            />
          )
        ) : (
          <></>
        )}
      </>
    );
  };
  render() {
    if (this.props.restaurant.timing) {
      const day = moment().format("dddd").toLocaleLowerCase();
      const todayDate = this.props.restaurant.timing[day];
      const dayIndex = dayOrder.indexOf(day);
      var openStatus = "";
      var openHours = "";
      if (todayDate) {
        // console.log(todayDate, this.props.restaurant.isOpen);
        if (todayDate != "Closed" && this.props.restaurant.isOpen) {
          // console.log("1s If");
          openHours = `${day.slice(0, 3)} ${todayDate}`;
        }
        if (todayDate == "Closed" || !this.props.restaurant.isOpen) {
          // console.log("2n If");
          for (let i = dayIndex; i < dayOrder.length; i++) {
            if (this.props.restaurant.timing[dayOrder[i]] !== "Closed") {
              // console.log("2.1n If");
              openStatus = `${dayOrder[i].slice(0, 3)} ${this.props.restaurant.timing[dayOrder[i]]
                }`;
              break;
            }
          }
        }
        if (
          !openStatus &&
          todayDate == "Closed" &&
          !this.props.restaurant.isOpen
        ) {
          // console.log("3s If");
          for (let i = 0; i < dayIndex; i++) {
            if (this.props.restaurant.timing[dayOrder[i]] !== "Closed") {
              // console.log("3.1n If");
              openStatus = `${dayOrder[i].slice(0, 3)} ${this.props.restaurant.timing[dayOrder[i]]
                }`;
              break;
            }
          }
        }
      }
      const nextDay = day[new Date().getDay() + 1];
    }
    var itemsBefore = [];
    var itemsAfter = [];
    if (this.state.orders.length) {
      if (this.state.search) {
        Object.values(this.props.items)
          .filter(
            (item) =>
              item.stock && item.title.toUpperCase().includes(this.state.search)
          )
          .map((item) => (itemsBefore = [...itemsBefore, item]));
        Object.values(this.state.popular)
          .filter(
            (item) =>
              item.stock &&
              item.title.toUpperCase().slice(0, 1).includes(this.state.search)
          )
          .map((item) => (itemsBefore = [...itemsBefore, item]));
      } else if (this.state.filter) {
        Object.values(this.props.items)
          .filter(
            (item) =>
              item.stock &&
              item.category
                .toLowerCase()
                .includes(this.state.filter.toLowerCase())
          )
          .map((item) => (itemsBefore = [...itemsBefore, item]));
        Object.values(this.state.popular)
          .filter(
            (item) =>
              item.stock &&
              item.category
                .toLowerCase()
                .includes(this.state.filter.toLowerCase())
          )
          .map((item) => (itemsBefore = [...itemsBefore, item]));
      } else {
        Object.values(this.props.items).map((item) => {
          itemsBefore = [...itemsBefore, item];
        });
        Object.values(this.state.popular).map((item) => {
          itemsBefore = [...itemsBefore];
          // itemsBefore = [...itemsBefore, item];
        });
      }

      itemsBefore = groupByProductCategories("category", itemsBefore);
      itemsAfter = itemsBefore.sort((a, b) => {
        return (
          this.state.orders.indexOf(a.category) -
          this.state.orders.indexOf(b.category)
        );
      });
    }

    return (
      <>
        <div className="Delivery new-interface">
          <div className="online-items">
            <>
              <div className="tab-example">
                <div
                  className="search-filter"
                  style={{ padding: "0", margin: "0" }}
                >
                  <div className="menu-group">
                    <div className="menu-left hidden-scroll">
                      <BrandingHeader />
                      <div className="tab-group fixed" id="nav-section">
                        <Container>
                          <div
                            className="tabs"
                            style={{
                              display: "flex",
                              flexWrap: "no-wrap",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Form
                              className=""
                              inline
                              style={{ marginBottom: "0" }}
                            >
                              <InputGroup style={{ width: "100%" }}>
                                {this.state.searchshow ? (
                                  <>
                                    <div id="close">
                                      <FormControl
                                        onChange={this.handleChange}
                                        value={this.state.searchTemp}
                                        autoFocus
                                        type="text"
                                        placeholder="search for food"
                                      />

                                      <FaRegWindowClose
                                        onClick={this.handleClose}
                                        id="closeicon"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </InputGroup>
                            </Form>
                            {this.state.menu.length ? (
                              <FaSearch
                                id="searchopen"
                                style={{
                                  margin: "0 25px 0 10px",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                                onClick={this.handleShow}
                              />
                            ) : (
                              <></>
                            )}

                            <ul id="categorylist" className="-primary">
                              {this.state.menu.length ? (
                                <>
                                  <li
                                    onClick={this.filterChange}
                                    className="active"
                                    name=""
                                  >
                                    <MultiLingualContent contentID="All" />
                                  </li>
                                  {this.state.popular?.length ? (
                                    <li
                                      onClick={this.filterChange}
                                      name="Popular Items"
                                    >
                                      <MultiLingualContent contentID="Popular Items" />
                                    </li>
                                  ) : (
                                    <></>
                                  )}

                                  {this.state.menu.map((category) => {
                                    return (
                                      <li
                                        key={category}
                                        name={category}
                                        onClick={this.filterChange}
                                      >
                                        {capitalizeFirstLetter(category)}
                                      </li>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </Container>
                      </div>
                      {itemsAfter.length > 0 ? (
                        <>
                          {window.innerWidth < 800 ? (
                            <></>
                          ) : (
                            <>
                              <div className="banners">
                                <div className="container">
                                  <img
                                    src={this.props.restaurant.restaurant.logo}
                                  />
                                  <h6>
                                    {capitalizeFirstLetter(
                                      this.props.restaurant.restaurant.name.replace(
                                        /-/g,
                                        " "
                                      )
                                    )}{" "}
                                  </h6>
                                  <p>
                                    {capitalizeFirstLetter(
                                      this.props.restaurant.address.address1
                                    )}{" "}
                                    {capitalizeFirstLetter(
                                      this.props.restaurant.address.city
                                    )}
                                    , {this.props.restaurant.address.state}{" "}
                                    {this.props.restaurant.address.zip}
                                  </p>
                                  {/* {this.props.restaurant.id.toLowerCase() ===
                                    "vishnuji-ki-rasoi-festival-mela".toLowerCase() ? (
                                    <p
                                      style={{
                                        fontSize: "18px !important",
                                        margin: "0",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Event: Aishwarya Majmudar on October 15,
                                      2022
                                    </p>
                                  ) : (
                                    <></>
                                  )} */}

                                  <div className="restaurant-status1">
                                    {this.props.restaurant.isOpen ? (
                                      <>
                                        <p
                                          style={{
                                            color: "rgb(84, 171, 26)",
                                            display: "inline-block",
                                            // color: "#fff",
                                            // padding: "5px 10px",
                                            // borderRadius: "5px",
                                            fontSize: "18px",
                                            // letterSpacing: "1.1px",
                                          }}
                                        >
                                          <MultiLingualContent contentID="Open Now" />
                                        </p>
                                        <p className="timings">
                                          {capitalizeFirstLetter(openHours)}
                                        </p>
                                      </>
                                    ) : this.props.restaurant
                                      .restaurant_open ? (
                                      <>
                                        <p
                                          style={{
                                            color: "rgb(169, 68, 66)",
                                            display: "inline-block",
                                            // color: "#fff",
                                            // padding: "5px 10px",
                                            // borderRadius: "5px",
                                            fontSize: "18px",
                                            // letterSpacing: "1.1px",
                                          }}
                                        >
                                          <MultiLingualContent contentID="Closed" />
                                        </p>
                                        {/* {console.log(openStatus)} */}
                                        {openStatus ? (
                                          <p className="timings">
                                            Open Next :{" "}
                                            {capitalizeFirstLetter(openStatus)}
                                          </p>
                                        ) : (
                                          <></>
                                        )}
                                        {/* <p id="demo">Open Next: {capitalizeFirstLetter(nextDay)}</p> */}
                                      </>
                                    ) : (
                                      <>
                                        <p
                                          style={{
                                            color: "rgb(169, 68, 66)",
                                            display: "inline-block",
                                            // color: "#fff",
                                            // padding: "5px 10px",
                                            // borderRadius: "5px",
                                            fontSize: "18px",
                                            // letterSpacing: "1.1px",
                                          }}
                                        >
                                          <MultiLingualContent contentID="Temporarily closed" />
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {itemsAfter.length > 0 ? (
                            itemsAfter.map((items) => {
                              return (
                                <div
                                  className="items-group"
                                  key={items.category}
                                >
                                  <h1 className="pt-5 mx-auto">
                                    {capitalizeFirstLetter(items.category)}
                                    <i className="arrow down"></i>
                                  </h1>

                                  <Row className="pb-3">
                                    {this.itemsIterate(items.items)}
                                  </Row>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>{this.state.search ? <ShowMenuEmpty /> : <></>}</>
                      )}
                    </div>
                    <div className="cart-right hidden-scroll">
                      <div className="cart-cont">
                        <CartSide />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant_id: state.restaurant.id,
    restaurant: state.restaurant,
    timeZone: state.restaurant.restaurant.timeZone,
  };
};
export default connect(mapStateToProps)(DeliveryMenu);