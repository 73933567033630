import React, { useEffect, Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import CartTableItem from "../../components/CartTableItem";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

import CartTotalsCheckout from "../../components/CartTotalsCheckout";

import Card from "react-bootstrap/Card";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Form from "react-bootstrap/Form";
import {
  addCustomerPhone,
  postNewOrderCash,
  postNewOrderCashCustom,
  updateCustomer,
  // updateCustomerAddress,
  updateCustomerEmail,
  updateCustomerPhone,
} from "../../utils/api";
import { Set, Reset } from "../../redux/actions/cartActions";
import { round } from "../../utils/functions";
import { FaUserEdit } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getOrderTypes } from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import MultiLingualContent from "../../context/multilingualContent";
import { translations } from "../../context/translations";
class PayRestaurant extends Component {
  state = {
    firstName: "",
    email: "",
    name: "",
    tableOrderId: this.props.table.table_order_id,
    tableOrder: false,
    tableId: this.props.table.table_id,
    tableName: this.props.table.table_name,
    lastName: "",
    address: "",
    city: "",
    state: "",
    orderId: "",
    country: "US",
    zip: "",
    email: "",
    mobile: "",
    type: "cash",
    notes: this.props.cart.notes ? this.props.cart.notes : "",
    updated: true,
    customer_id: "",
    modalShow: false,
    loader: false,
    edit: false,
    isDisable: false,
    orderType: "",
    timeZone: this.props.restaurant.restaurant.timeZone,
    discountPrecent: 0,
    orderNum: "",
    coupon_value: "",
    coupon_error: "",
    applied_coupon_code: "",
    coupon_discount_amount: 0,
    coupon_discount_percentage: 0,
    coupon_applied: true,
    points: 0,
  };
  async componentDidMount() {
    console.log("custom", this.props.table);
    window.scrollTo(0, 0);

    const response = await fetch(
      `/api/v1/merchant/${this.props.restaurant.id}/${this.props.restaurant.integrate}/discount`
    );
    const json = await response.json();
    const tableId = window.location.pathname.split("/")[
      window.location.pathname.split("/").indexOf("table") + 1
    ]
      ? window.location.pathname.split("/")[
      window.location.pathname.split("/").indexOf("table") + 1
      ]
      : 0;
    if (tableId) {
      this.setState({
        tableOrder: true,
        table: tableId,
      });
    }
    if (json.isDiscount) {
      this.setState({ discountPrecent: json.percentage });
    }

    if (this.props.restaurant.integrate === "clover") {
      const orderTypes = await getOrderTypes(
        window.location.pathname.split("/")[1]
      );
      var orderType = orderTypes.filter(
        (type) => type.label.includes("out") || type.label.includes("up")
      );
      orderType = orderType.length
        ? orderType[orderType.length - 1]["id"]
        : orderTypes[0]["id"];
      this.setState({
        orderType,
      });
    }
    // console.log(this.props.customer);
    this.setState({
      customer_id: this.props.customer.id ? this.props.customer.id : "",
      restaurant_id: this.props.restaurant.id ? this.props.restaurant.id : "",
      firstName: this.props.customer.firstName
        ? this.props.customer.firstName
        : "",
      name: this.props.customer.firstName
        ? this.props.customer.firstName
        : "",
      lastName: this.props.customer.lastName
        ? this.props.customer.lastName
        : "",
      email:
        this.props.customer.emailAddresses &&
          this.props.customer.emailAddresses.elements[0]
          ? this.props.customer.emailAddresses.elements[0].emailAddress
          : "",
      mobile: this.props.customer.phone ? this.props.customer.phone : "",
    });
  }
  handleChange = (e) => {
    e.target.style.boxShadow = "none";
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleEdit = (e) => {
    this.setState({
      updated: false,
      edit: true,
    });
  };
  updateUser = async (e) => {
    e.preventDefault();
    if (
      this.props.customer.phoneNumbers &&
      this.props.customer.phoneNumbers.elements[0]
    ) {
      await updateCustomerPhone(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.phoneNumbers.elements[0]["id"],
        this.state
      );
    } else {
      await addCustomerPhone(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.addresses.elements[0]["id"],
        this.state
      );
    }
    if (
      this.props.customer.emailAddresses &&
      this.props.customer.emailAddresses.elements[0]
    ) {
      await updateCustomerEmail(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.emailAddresses.elements[0]["id"],
        this.state
      );
    }
    await updateCustomer(
      this.state.restaurant_id,
      this.state.customer_id,
      this.state
    );
    this.setState({
      edit: false,
      updated: true,
    });
  };
  validateFormAddress() {
    return !(
      this.state.firstName.length > 0 ||
      (this.state.name.length > 0 && this.state.mobile.length > 0)
    );
  }
  handleNewOrder = async (event) => {
    event.preventDefault();
    if (
      // this.state.email.length > 0 &&
      this.state.name.length > 0 &&
      this.state.mobile.length >= 10
    ) {
      this.setState({
        isDisable: true,
        loader: true,
      });
      var res = {};
      res = await postNewOrderCashCustom(
        this.state,
        this.props.orderCart,
        this.props.tip,
        this.props.tax,
        this.props.restaurant.convenience_fee,
        this.props.restaurant.convenience_rate,
        this.props.restaurant.convenience_limit,
        this.props.customer.connect,
        this.state.discountPrecent,
        this.props.customer.custom,
        this.props.checkout
      );
      this.setState({ isDisable: false });
      if (res) {
        localStorage.removeItem(this.state.restaurant_id);
        if (this.state.tableId) {
          if (res.points) {
            window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&reward_points=${res.points}`;
          } else {
            window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}`;
          }
        } else {
          let [str, num] = res.id.split("-");
          let SendId = res.id;
          let points = res.points;
          if (num.length === 1) {
            SendId = str + "-" + "0" + "0" + num;
          } else if (num.length === 2) {
            SendId = str + "-" + "0" + num;
          } else {
            SendId = str + "-" + num;
          }
          this.setState({
            modalShow: true,
            orderId: SendId,
            orderNum: res.id,
            loader: false,
            points,
          });
        }

      }
    } else {
      if (this.state.name === "") {
        document.getElementById("name").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        document.getElementById("name").focus();
      }
      if (this.state.mobile.length < 12) {
        document.querySelector(".react-tel-input input").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
      }
      // if (this.state.email === "") {
      //   document.getElementById("email").style.boxShadow =
      //     "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
      //   document.getElementById("email").focus();
      // }
    }
  };
  handleBlur = (e) => {
    // e.target.style.boxShadow = "none";
  };
  handleConinue = () => {
    window.location.href = `/${this.props.restaurant.id}`;
    // this.props.Reset();
  };
  translatedWord = (word) => translations[this.props.restaurant.language] ? translations[this.props.restaurant.language][word] : translations["en"][word]

  render() {
    const cartTableItemRender = (title) => (
      <CartTableItem
        key={this.props.items[title]["productId"]}
        id={this.props.items[title]}
        img={this.props.items[title].img}
        title={this.props.items[title].title}
        price={this.props.items[title].price}
        num={this.props.items[title].num}
      />
    );

    const checkout = (
      <Row>
        {/* {console.log(this.state)} */}

        <Col xs={12} lg={6}>
          <Card className="mt-5">
            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span><MultiLingualContent contentID="Billing Details" /></span>
              {this.state.edit ? (
                <></>
              ) : this.state.customer_id ? (
                <>
                  <FaUserEdit
                    className="mr-1"
                    size="1.5em"
                    onClick={this.handleEdit}
                  />
                </>
              ) : (
                <></>
              )}
            </Card.Header>
            {!this.state.edit && this.state.customer_id ? (
              <Card.Body>
                <Card.Text>
                  {this.state.firstName} {this.state.lastName}
                </Card.Text>
                <Card.Text>{this.state.email}</Card.Text>
                <Card.Text>{this.state.mobile}</Card.Text>
              </Card.Body>
            ) : (
              <Form
                // onSubmit={this.handleSubmit}
                className="p-4"
              >
                <Form.Row>
                  <Form.Group as={Col} controlId="name">
                    <Form.Control
                      onChange={this.handleChange}
                      onFocus={this.handleBlur}
                      value={this.state.name}
                      placeholder="Name"
                      required
                    />
                  </Form.Group>

                  {/* <Form.Group as={Col} controlId="lastName">
                    <Form.Control
                      onChange={this.handleChange}
                      onFocus={this.handleBlur}
                      placeholder="Last Name"
                      value={this.state.lastName}
                      required
                    />
                  </Form.Group> */}
                </Form.Row>
                {/* <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Control
                      onFocus={this.handleBlur}
                      onChange={this.handleChange}
                      value={this.state.email}
                      type="email"
                      placeholder="Email"
                      required
                    />
                  </Form.Group>
                </Form.Row> */}
                <Form.Row>
                  <Form.Group as={Col} controlId="mobile">
                    {/* <Form.Control
                      onChange={this.handleChange}
                      value={this.state.mobile}
                      type="text"
                      placeholder="Phone"
                      required
                    /> */}
                    {(!this.state.mobile.replace("+", "").length ||
                      !this.state.mobile.replace("+1", "").length) && (
                        <span
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            //   textAlign: "center",
                            fontWeight: "500",
                            lineHeight: "15px",
                            display: "inline-block",
                          }}
                        >
                          <MultiLingualContent contentID="Please enter the phone number to receive the order confirmation text." />
                        </span>
                      )}
                    <PhoneInput
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      disableDropdown={true}
                      country={"us"}
                      onFocus={this.handleBlur}
                      placeholder="Phone"
                      inputclassName="phoneinput"
                      value={this.state.mobile}
                      onChange={(mobile) => {
                        this.setState({
                          mobile: `+${mobile}`,
                        });
                      }}
                    />
                  </Form.Group>
                </Form.Row>

                {this.state.edit ? (
                  <Button
                    block
                    variant="warning"
                    type="submit"
                    disabled={this.validateFormAddress()}
                    onClick={this.updateUser}
                  >
                    Update
                  </Button>
                ) : (
                  <></>
                )}
              </Form>
            )}
          </Card>
          <Modal
            show={this.state.modalShow}
            size="md"
            aria-labelledby="ordersucess"
            centered
          >
            <Modal.Body>
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  Your Order ID
                </p>
                <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                  {" "}
                  <b>{this.state.orderId}</b>
                </p>
                <p style={{ textAlign: "center" }}>
                  {`Your order has been received. Your order will be ready for
                  pick up in ${this.props.restaurant.deliverytime} minutes.`}
                </p>
                {this.state.points ? (
                  <p style={{ textAlign: "center" }}>
                    {`You have earned ${this.state.points} points as a reward for this order.`}
                  </p>
                ) : (
                  <></>
                )}

                {/* {this.props.restaurant.id.toLowerCase() === "biryani-bowl-mela" ||
                this.props.restaurant.id.toLowerCase() ===
                  "vishnuji-ki-rasoi-festival-mela" ? (
                  <p style={{ textAlign: "center" }}>
                    <a
                      href={`/contact/${this.props.restaurant.id}/${this.state.orderNum}`}
                    >
                      Click here to Chat with us
                    </a>
                  </p>
                ) : (
                  <></>
                )} */}
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ marginBottom: "0" }}
                onClick={this.handleConinue}
              >
                Continue
              </Button>
              {/* <Link
              style={{ marginBottom: "0" }}
              to={`/${this.props.restaurant.id}/feedback`}
              state={this.state.orderId}
            >
              Continue
            </Link> */}
            </Modal.Footer>
          </Modal>
        </Col>

        <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={5}>
          <CartTotalsCheckout
            handleNewOrder={this.handleNewOrder}
            disabled={this.state.isDisable}
            // disabled={!this.state.updated}
            custom={true}
            buttonText={this.translatedWord("Place Order")}
            payment={false}
            total={this.props.total}
            totalPrice={round(this.props.totalPrice)}
          />
        </Col>
      </Row>
    );
    // eslint-disable-next-line
    const needToLogin = (
      <div className="text-center">
        <h2>You must Login or Signup to checkout</h2>
        <Link to={`/${this.props.restaurant.id}/login-signup`}>
          <Button variant="warning" className="mt-4">
            To Login/Signup
          </Button>
        </Link>
      </div>
    );

    return (
      <React.Fragment>
        {/* <CustomParallax title="Checkout" img={home_top} height={300} /> */}
        <Container
          className="my-auto Checkout"
          style={{ opacity: this.state.loader ? "0.4" : "1" }}
        >
          {checkout}
          {/* {this.props.loggedIn ? checkout : needToLogin} */}
        </Container>
      </React.Fragment>
    );
  }
}

function CustomPayCash(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const cartTableItemRender = (title) => (
  //   <CartTableItem
  //     key={props.items[title]["productId"]}
  //     id={props.items[title]}
  //     img={props.items[title].img}
  //     title={props.items[title].title}
  //     price={props.items[title].price}
  //     num={props.items[title].num}
  //   />
  // );

  const full = (
    <Row>
      {/* <div className="d-flex">
          <h4 className="text-uppercase mr-2 my-auto">Your Cart</h4>
          <p className="noprod my-auto">{props.total} Products</p>
        </div>
        <Table className=" cart-page mt-3" responsive>
          <thead>
            <tr className="head text-center">
              <th>Image</th>
              <th>Product</th>

              <th>Quantity</th>
              <th>Total Price</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {Object.keys(props.items).map(
              (title) =>
                props.items[title].num > 0 && cartTableItemRender(title)
            )}
          </tbody>
        </Table> */}
      <PayRestaurant {...props} />
      {/* <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={3}>
        <CartTotalsCart
          disabled={false}
          buttonText="Checkout"
          total={props.total}
          totalPrice={round(props.totalPrice)}
        />
      </Col> */}
    </Row>
  );
  const empty = (
    <div className="text-center">
      <h2>Your cart is currently empty...</h2>
      <Link to={`/${props.restaurant.id}`}>
        <Button variant="warning" className="mt-4">
          Back to Order
        </Button>
      </Link>
    </div>
  );

  return (
    <>
      <div className="cart-header">
        <p>{props.restaurant.restaurant.name}</p>
        <button
          onClick={() => window.history.back()}
          style={{
            padding: "5px 10px",
            background: "none",
            border: "0",
            fontWeight: "600",
            fontSize: "25px",
          }}
        >
          <FontAwesomeIcon icon={faClose} size={"1x"} />
        </button>
      </div>
      <React.Fragment>
        <Container className="my-auto Cart" style={{ minHeight: "200px" }}>
          {props.total > 0 ? full : empty}
        </Container>
      </React.Fragment>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    total: state.cart.total,
    totalPrice: state.cart.totalPrice,
    tip: state.cart.tip,
    orderCart: state.cart.orderCart,
    restaurant: state.restaurant,
    customer: state.customer,
    table: state.table,
    tablePath: state.restaurant.tablePath,
    loggedIn: state.customer.isLoggedIn,
    tax: state.restaurant.totalTax,
    cart: state.cart,
    checkout: state.checkout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Set: (title, num) => {
      dispatch(Set(title, num));
    },
    Reset: (title, num) => {
      dispatch(Reset(title, num));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPayCash);
