import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "../pages.css";
import { Spinner } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import { compose } from "redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { MdCancel, MdModeEdit } from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import { toast } from "react-toastify";

function CategoryGroups(props) {
  const [catList, setCatList] = useState([]);
  const [pending, setPending] = useState(true);
  const [catName, setCatName] = useState("");
  const [modelState, setModelState] = useState({
    addCategory: false,
    addCategorySpinner: false,
  });
  const navigate = useNavigate();
  useEffect(() => {
    (function () {
      getCategoryList();
    })();
  }, []);

  async function getCategoryList() {
    try {
      let response = await fetch(
        `/api/v1/merchant/get/square/category/${props.restaurant.id}`
      );
      response = await response.json();
      console.log(response);
      if (!response.data.length) {
        setPending(false);
        return 1;
      }
      let modData = response.data.map((item) => {
        let temp = {};
        temp.id = item.id;
        temp.name = item.category_data.name;
        return temp;
      });
      setCatList(modData);
      setPending(false);
    } catch (error) {
      console.log(error);
    }
  }
  async function deleteCategroyGroup(ID) {
    let res = await fetch(
      `/api/v1/merchant/delete/square/object/${ID}/${props.restaurant.id}`
    );
    if (res) {
      getCategoryList();
      toast.error("Category deleted");
    }
  }
  async function closeModal() {
    setCatName("");
    setModelState((prevState) => {
      return {
        ...prevState,
        addCategory: false,
        addCategorySpinner: false,
      };
    });
  }
  async function addCategoryHandle() {
    let res = await fetch(
      `/api/v1/merchant/create/square/category/${props.restaurant.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: { name: catName } }),
      }
    );
    if (res) {
      closeModal();
      // navigate("/merchant/categories");
      getCategoryList();
      toast.success("Added");
    }
  }
  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <div
          className="table-title"
          onClick={() =>
            navigate(`/merchant/categories/${row.id}`, { state: row })
          }
        >
          {row.name}
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <div className="table-title">
          <Button
            style={{ marginRight: "9px" }}
            onClick={() => {
              navigate(`/merchant/categories/${row.id}`, { state: row });
              // setSelectedMod(row.data);
              // setName(row.name);
              // setIsOpenAdd(true);
            }}
          >
            <BsIcons.BsPencil />
          </Button>
          <Button onClick={() => deleteCategroyGroup(row.id)}>
            <MdIcons.MdDelete />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        onClick={() =>
          setModelState((prevState) => {
            return {
              ...prevState,
              addCategory: true,
            };
          })
        }
      >
        Create Category
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        columns={columns}
        data={catList}
        // pagination
        progressPending={pending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
        // onChangePage={this.handlePageChange}
      />
      {modelState.addCategory ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={modelState.addCategory}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "33%" }}>
                          Name <span style={{ color: "red" }}>*</span>
                        </td>
                        <td>
                          <Form.Control
                            onChange={(e) => {
                              setCatName(e.target.value);
                            }}
                            type="text"
                            size="sm"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <center>
                    <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={addCategoryHandle}
                      style={{ display: "inline-block" }}
                      disabled={modelState.addCategorySpinner || catName === ""}
                    >
                      Add
                      {/* {modelState.addModifierSpinner ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        <></>
                      )} */}
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(CategoryGroups);