import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Row, Col, Image, Card, Form, Modal, Button } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import CreditCardInput from "react-credit-card-input";
import MultiLingualContent from "../../context/multilingualContent";
import {
    squareInit,
    squarePaymentsInit,
    squarePaymentsReInit,
    squareTokenize,
} from "../../utils/functions";
import { timeDelay } from '../../utils/api';
import promisify from 'promisify-node';

let StyleSheet = {
    centerDiv: {
        display: 'flex',
        justifyContent: 'center'
    }
}

export default function PaymentAiOrder(props) {
    const { order_id } = useParams();
    const [searchParams] = useSearchParams();
    const [modalState, setModalState] = useState({ loading: false, error: false });
    const [initPaymentState, setInitPaymentState] = useState({ subTotal: 0, tax: 0, tip: 0, total: 0, discountAmount: 0 });
    const [paymentState, setPaymentState] = useState({ subTotal: 0, tax: 0, tip: 0, total: 0, discountAmount: 0 });
    const [restaurantData, setRestaurantData] = useState({});
    const [cardNum, setCardNum] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [squarePayment, setSquarePayment] = useState();
    const keyLock = '_sms_orders'
    useEffect(() => { getData() }, []);
    async function getData() {
        setModalState(prevState => ({ ...prevState, loading: true }));
        let stateUpdater = {};
        try {
            let response = await fetch(`/api/v1/aiorder/orders/${order_id}`, { headers: { 'x-api-key': 'af7c1fe6-d669-414e-b066-e9733f0de7a8' } })
            if (response.ok) {
                response = await response.json()
                const { restaurantData, metaData, lineItems, customer_details, order_details } = response;
                const restaurantId = metaData.restaurant_id.toLowerCase()
                const keyName = `${restaurantId}${keyLock}`;
                const getLoc = localStorage.getItem(keyName);
                if (metaData.expired) {
                    localStorage.removeItem(keyName);
                    throw 'order link expired!'
                }
                let subTotal = 0;
                for (const item of lineItems) {
                    const { totalPrice } = item;
                    subTotal += totalPrice;
                }
                // console.log(lineItems)
                let to_store = {
                    lineItems: lineItems,
                    order_details: order_details,
                    customer_details: customer_details
                }
                if (getLoc) {
                    localStorage.removeItem(keyName);
                    localStorage.setItem(keyName, JSON.stringify(to_store))
                } else {
                    localStorage.setItem(keyName, JSON.stringify(to_store))
                }
                let expires = "";
                let date = new Date();
                let expireMin = (15 * 60 * 1000) //min * sec * millsec
                date.setTime(date.getTime() + expireMin);
                expires = "; expires=" + date.toUTCString();
                document.cookie = `${keyName}_id` + "=" + (order_id) + expires + "; path=/";
                if (customer_details?.mobile_number) {
                    window.location.href = `/${restaurantId}?sms_order=true&ph=${customer_details.mobile_number}`
                } else {
                    window.location.href = `/${restaurantId}?sms_order=true`
                }
            } else {
                stateUpdater.error = true;
            }
        } catch (error) {
            console.log(error)
            stateUpdater.error = true;
        }
        await timeDelay(3)
        setModalState(prevState => ({ ...prevState, loading: false, ...stateUpdater }));
    }

    return (<React.Fragment>
        <Container
            className="my-auto Checkout"
            // style={{ opacity: this.state.loader ? "0.4" : "1", padding: 0 }}
            style={{ padding: 0 }}
        >
            {modalState.loading ?
                <div style={StyleSheet.centerDiv}> <BeatLoader size={12} color="#ec7211" /></div>
                :
                modalState.error ?
                    <div style={StyleSheet.centerDiv}>Page Invalid</div>
                    :
                    <div style={StyleSheet.centerDiv}></div>}
        </Container>
    </React.Fragment>)
}
