import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import screenshot from "../../assets/screenshot.png";
import { toast } from "react-toastify";
import * as queryString from "query-string";
import {
  postBankAccount,
  getBankAccount,
  getCloverToken,
  updateCloverToken,
  getMailOrderVerify,
  getSquareToken,
} from "../Functions/helper";
import { async } from "q";
import FileDownload from "js-file-download";
import { postBankAccountVerify } from "../Functions/fetch";

let formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
};
const urlParams = queryString.parse(window.location.search);

class Integrations extends Component {
  state = {
    clover_menu_item: [],
    clover_update_item: {},
    restaurant_id: this.props.restaurant_id,
    gmailData: [],
    gmailSign: [],
    squareData: [],
    selectedSquareLocation: "",
    locationSquareData: "",
    integrateStatus: "",
    filterCreated: false,
    showModal: false,
    selectedLocation: "",
    selectedLocationId: "",
    bankAdded: false,
    selectedLocationPhone: "",
    showNumber: false,
    showUrl: false,
    urlModal: false,
    getNewNumber: "",
    getMenuUrl: "",
    updatePhone: "",
    getLocation: "",
    primaryNumber: "",
    isOpen: false,
    locationData: "",
    displayLocation: "",
    NumberOpen: false,
    showMenu: "",
    getprivateToken: "",
    ecomprivateToken: "",
    ecompublicToken: "",
    setValue: "1",
    tax_id: "",
    accountnumber: "",
    confirmaccountnumber: "",
    routingnumber: "",
    voidLink: "",
    voidName: "",
    voidFile: "",
    editable: false,
    tokenModal: false,
    merchant_id: "",
    cloverVerify: false,
    squareVerify: false,
    screenshotOpen: false,
    disable: true,
    doordashName: "",
    grubhubName: "",
    uberName: "",
    doordashMail: "",
    grubhubMail: "",
    posStatus: {},
  };
  buttonClick = (e) => {
    axios
      .get("https://order.phonedash.us/gmail/download", {
        responseType: "blob",
      })
      .then((res) => {
        FileDownload(res.data, "mailFilters.xml");
        console.log(res.data);
      });
  };
  async componentDidMount() {
    this.getSquareUpLocation();
    this.getIntegrationStatus();
    this.getSquareLocations();
    this.getGmbLocations();
    this.getEmailAddress();
    this.getLocations();
    this.updateAccountState();
    this.updateCloverTokenState();
    // this.updateSquareTokenState();
    this.updateMailVerifyState();
    axios
      .get(`/api/v1/merchant/${this.props.restaurant_id}/product/mapItems`)
      .then((res) => {
        this.setState({
          clover_menu_item: res.data,
        });
      });
  }
  getIntegrationStatus = () => {
    axios
      .get(`/api/integrations/integration-status/${this.props.restaurant_id}`)
      .then((res) => {
        const { integrate } = res.data;
        console.log(integrate);
        this.setState({
          integrateStatus: integrate,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleCloverDelete = () => {
    axios
      .delete(`/api/integrations/delete-clover/${this.state.restaurant_id}`)
      .then((response) => {
        // this only runs on success
        console.log("RESPONSE FROM POST", response.data);
      })
      .finally(() => {
        toast.success("Signed Out Successfully");
        const interval = setInterval(() => {
          window.location.replace("/merchant/integration");
        }, 5000);
        return () => clearInterval(interval);
      });
  };
  ActivateClover = () => {
    axios
      .post(`/api/integrations/activate-clover/${this.props.restaurant_id}`, {
        data: "clover",
      })
      .then((res) => {
        console.log(res.data);
      })
      .finally(() => {
        toast.success("Activated");
        const interval = setInterval(() => {
          window.location.replace("/merchant/integration");
        }, 4000);
        return () => clearInterval(interval);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  ActivateSquare = () => {
    axios
      .post(`/api/integrations/activate-square/${this.props.restaurant_id}`, {
        data: "square",
      })
      .then((res) => {
        console.log(res.data);
      })
      .finally(() => {
        toast.success("Activated");
        const interval = setInterval(() => {
          window.location.replace("/merchant/integration");
        }, 4000);
        return () => clearInterval(interval);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  updateMailVerifyState = async () => {
    ["doordash", "grubhub"].forEach(async (name) => {
      const res = await getMailOrderVerify(this.props.restaurant_id, name);

      this.setState({ ...this.state, ...res });
    });
    // console.log(posStatus);
  };
  updateCloverTokenState = async () => {
    const res = await getCloverToken(this.props.restaurant_id);
    this.setState({ ...this.state, ...res });
  };
  // updateSquareTokenState = async () => {
  //   const res = await getSquareToken(this.props.restaurant_id);
  //   // console.log(res);
  //   this.setState({ ...this.state, ...res });
  // };
  updateAccountState = async () => {
    const res = await getBankAccount(this.props.restaurant_id);

    if (res) {
      if (res.accountnumber) {
        this.setState({
          bankAdded: true,
        });
      } else {
        this.setState({
          bankAdded: false,
        });
      }
      this.setState({ ...this.state, ...res });
      if (res.voidLink) {
        let [filename] = this.state.voidLink.split("/").slice(-1);
        this.setState({ voidName: filename, voidFile: "" });
      }
    }
  };
  handleTab = (event, newValue) => {
    console.log(event.target.value, newValue);
    this.setState({
      setValue: newValue,
    });
  };
  handleFileUpload = (e) => {
    // console.log(e.target);
    const image = e.target.files[0];
    console.log(image);
    this.setState({ [e.target.name]: image });
  };
  handleVoidDownload = async () => {
    const { voidLink } = this.state;
    console.log(voidLink);
    // fetch(voidLink).then((res) => {
    // console.log(res.data);
    // });
    const res = await fetch(voidLink);
    console.log(res);
    // FileDownload(res.data, `${businessName}.pdf`);
  };
  handleVerify = async () => {
    let { amount1, amount2 } = this.state;
    let temp = {};
    temp["restaurant_name"] = this.props.restaurant_id;
    temp["amount1"] = amount1;
    temp["amount2"] = amount2;
    const posres = await postBankAccountVerify(this.props.restaurant_id, temp);
    if (posres) window.location.reload();
  };
  handleAddBank = async () => {
    let { tax_id, routingnumber, accountnumber, voidFile } = this.state;
    let temp = {};
    temp["restaurant_name"] = this.props.restaurant_id;
    temp["modifiedTime"] = Math.round(new Date().getTime() / 1000).toString();
    if (tax_id) {
      temp = { ...temp, tax_id };
    }
    if (routingnumber) {
      temp = { ...temp, routingnumber };
    }
    if (accountnumber) {
      temp = { ...temp, accountnumber };
    }
    if (routingnumber && accountnumber && tax_id) {
      const posres = await postBankAccount(this.props.restaurant_id, temp);
      this.updateAccountState();
      if (voidFile) {
        let formData = new FormData();
        let time = Math.round(new Date().getTime() / 1000).toString();
        let blob = voidFile.slice(0, voidFile.size, voidFile.type);
        const newFile2 = new File(
          [blob],
          `${this.props.restaurant_id + "_void_" + time}.${voidFile.type.split("/")[1]
          }`,
          {
            type: voidFile.type,
          }
        );
        formData.append("voidfile", newFile2);
        const res = await fetch(
          `/api/integrations/voidfile/${this.props.restaurant_id}`,
          {
            method: "POST",
            body: formData,
            responseType: "blob",
          }
        );
        // const json = await res.json();
        this.updateAccountState();
      }
    } else {
      toast.error("Please fill all the details.")
    }
  };
  submitMailDoordash = () => {
    const restaurant = this.props.restaurant_id;
    fetch(
      `/api/integrations/send/mail/verify/${restaurant}/doordash/${this.state.doordashMail}`
    ).then((res) => window.location.reload());
  };
  submitMailGrubhub = () => {
    const restaurant = this.props.restaurant_id;
    fetch(
      `/api/integrations/send/mail/verify/${restaurant}/grubhub/${this.state.grubhubMail}`
    );
  };
  handleClose = () =>
    this.setState({
      showModal: false,
      screenshotOpen: false,
    });
  handleShow = () =>
    this.setState({
      showModal: true,
    });
  outputShow = () =>
    this.setState({
      isOpen: true,
    });
  outputClose = () =>
    this.setState({
      isOpen: false,
    });
  handleChange = (e) => {
    this.setState({
      selectedLocation: e.target.value,
    });
  };
  handleSquareChange = (e) => {
    this.setState({
      selectedSquareLocation: e.target.value,
    });
  };
  handleConnect = (e) => {
    this.setState({
      showModal: false,
      selectedLocationId: this.state.gmailData.filter(
        (locationData) => locationData.title === this.state.selectedLocation
      )[0]["name"],
      NumberOpen: true,
      primaryNumber: this.state.gmailData.filter(
        (locationData) => locationData.title === this.state.selectedLocation
      )[0]["phoneNumbers"]["primaryPhone"],
    });
    let data = {};
    data["location_name"] = this.state.selectedLocation;
    data["location"] = this.state.gmailData.filter(
      (locationData) => locationData.title === this.state.selectedLocation
    )[0]["name"];
    axios
      .post(
        `/api/integrations/update-gmb-locations/${this.state.restaurant_id}`,
        data
      )
      .then((res) => {
        this.setState({
          locationData: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    window.location.reload();
  };
  handleSquareConnect = (e) => {
    this.setState({
      showModal: false,
      selectedSquareLocation: this.state.squareData.filter(
        (locationSquareData) =>
          locationSquareData.name === this.state.selectedSquareLocation
      )[0]["name"],
    });
    console.log("Connected", this.state.selectedSquareLocation);
    let data = {};
    data["location_name"] = this.state.selectedSquareLocation;
    data["location_id"] = this.state.squareData.filter(
      (locationSquareData) =>
        locationSquareData.name === this.state.selectedSquareLocation
    )[0]["id"];
    console.log("data", data);
    axios
      .post(
        `/api/integrations/update-square-locations/${this.state.restaurant_id}`,
        data
      )
      .then((res) => {
        console.log(res.data);
        this.setState({
          locationSquareData: res.data,
          squareVerify: true,
        });
      })
      .finally(() => {
        toast.success("Location Updated");
        const interval = setInterval(() => {
          // window.location.reload();
          window.location.replace("/merchant/integration");
        }, 4000);
        return () => clearInterval(interval);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleSquareLocationDelete = () => {
    this.setState({
      selectedSquareLocation: "",
    });
    console.log("delete");
    axios
      .delete(
        `/api/integrations/delete-square-location/${this.state.restaurant_id}`
      )
      .then((response) => {
        // this only runs on success
        console.log("RESPONSE FROM POST", response.data);
      })
      .finally(() => {
        toast.success("Location Deleted");
        const interval = setInterval(() => {
          // window.location.reload();
          window.location.replace("/merchant/integration");
        }, 4000);
        return () => clearInterval(interval);
      });
  };
  handleSquareDelete = () => {
    this.setState({
      selectedSquareLocation: "",
    });
    console.log("delete");
    axios
      .delete(`/api/integrations/delete-square/${this.state.restaurant_id}`)
      .then((response) => {
        // this only runs on success
        console.log("RESPONSE FROM POST", response.data);
      })
      .finally(() => {
        toast.success("Signed Out Successfully");
        const interval = setInterval(() => {
          window.location.replace("/merchant/integration");
        }, 5000);
        return () => clearInterval(interval);
      });
  };
  handleDelete = () => {
    this.setState({
      selectedLocation: "",
    });
    console.log("delete");
    axios
      .delete(`/api/integrations/delete-location/${this.state.restaurant_id}`)
      .then(
        (response) => {
          // this only runs on success
          //window.location.reload();
          console.log("RESPONSE FROM POST", response.data);
        },
        (err) => {
          // this only runs on error
          console.log("Error While Posting Data", err);
        }
      );
  };
  handleOpen = () => {
    this.setState({
      showNumber: true,
    });
  };
  handleOpenUrl = () => {
    this.setState({
      showUrl: true,
    });
  };
  handleAdd = (e) => {
    this.setState({
      getNewNumber: e.target.value,
    });
  };
  handleMenuUrl = (e) => {
    this.setState({
      getMenuUrl: e.target.value,
    });
  };
  handlesubmit = (e) => {
    let data = {};
    data["existing_number"] = this.state.gmailData.filter(
      (locationData) => locationData.title === this.state.selectedLocation
    )[0]?.phoneNumbers?.primaryPhone;
    data["new_number"] = this.state.getNewNumber;
    data["location"] = this.state.selectedLocationId;

    axios
      .post(
        `/api/integrations/update-locations/${this.state.restaurant_id}`,
        data
      )
      .then((res) => {
        this.setState({
          updatePhone: res.data,
          primaryNumber: this.state.getNewNumber,
          isOpen: true,
        });
        // console.log("rest", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleUrlsubmit = (e) => {
    let data = {};
    data["url"] = this.state.getMenuUrl;
    data["location"] = this.state.selectedLocationId;
    axios
      .post(
        `/api/integrations/update-locations-url/${this.state.restaurant_id}`,
        data
      )
      .then((res) => {
        this.setState({
          updatePhone: res.data,
          showMenu: this.state.getMenuUrl,
          urlModal: true,
        });
        console.log("rest", res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
        alert(err.response.data.msg);
      });
  };

  removeEmail = () => {
    console.log("delete");
    axios
      .delete(`/api/integrations/delete-gmail/${this.state.restaurant_id}`)
      .then(
        (response) => {
          // this only runs on success
          window.location.reload();
          console.log("RESPONSE FROM POST", response.data);
        },
        (err) => {
          // this only runs on error
          console.log("Error While Posting Data", err);
        }
      );
  };

  getGmbLocations = () => {
    axios
      .get(`/api/integrations/gmb-locations/${this.state.restaurant_id}`)
      .then((res) => {
        this.setState({
          gmailData: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getSquareLocations = () => {
    axios
      .get(`/api/integrations/squareup/locations/${this.state.restaurant_id}`)
      .then((res) => {
        this.setState({
          squareData: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getEmailAddress = () => {
    axios
      .get(`/api/integrations/integrations/${this.state.restaurant_id}`)
      .then((res) => {
        this.setState({
          gmailSign: res.data,
        });
        // console.log("test", this.state.gmailSign);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  createFilter = (e) => {
    axios
      .get(`/api/integrations/gmail/create-filter/${this.state.restaurant_id}`)
      .then((res) => {
        this.setState({
          filterCreated: res.data,
        });
        alert("Filter Created...");
      })
      .catch((err) => {
        alert("Filter Already Exists...");
      });
  };
  getLocations = () => {
    axios
      .get(
        `/api/integrations/display-gmb-locations/${this.props.restaurant_id}`
      )
      .then((res) => {
        // console.log("menu", res.data?.attributes.filter(
        // (menuLink) => menuLink.name === "attributes/url_menu"
        // )[0]?.uriValues[0]?.uri);
        this.setState({
          displayLocation: res.data,
          selectedLocationId: res.data.location_id,
          selectedLocation: res.data.location_name,
          primaryNumber: res.data?.phoneNumbers?.primaryPhone,
          showMenu: res.data.attributes
            ? res.data?.attributes?.filter(
              (menuLink) => menuLink.name === "attributes/url_menu"
            )[0]?.uriValues[0]?.uri
            : "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getSquareUpLocation = () => {
    if (urlParams.squaretab) {
      this.setState({ setValue: urlParams.squaretab });
      this.handleShow();
      console.log("url", urlParams.squaretab);
    }
    console.log(1);
    axios
      .get(
        `/api/integrations/display-square-locations/${this.props.restaurant_id}`
      )
      .then((res) => {
        console.log("final", res.data);
        this.setState({
          selectedSquareLocation: res.data.location.name,
          squareVerify: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleprivateToken = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleItems = (e, clover_id) => {
    let obj = {
      [e.target.name]: e.target.value,
    };
    let pre = this.state.clover_update_item[clover_id];
    this.setState((prevState) => ({
      clover_update_item: {
        // object that we want to update
        ...prevState.clover_update_item, // keep all other key-value pairs
        [clover_id]: {
          ...pre,
          ...obj,
        }, // update the value of specific key
      },
    }));
    //
    // this.setState({
    // [e.target.name]: e.target.value,
    // })
  };
  submitItems = (e, clover_id) => {
    let element = e.target;
    if (this.state.clover_update_item[clover_id]) {
      axios
        .post(
          `/api/v1/merchant/${this.props.restaurant_id}/product/mapItems/${clover_id}`,
          this.state.clover_update_item[clover_id]
        )
        .then((res) => {
          element.innerHTML = "Saved";
          setTimeout(() => {
            element.innerHTML = "Save";
          }, 1000);
        })
        .catch((err) => {
          element.innerHTML = "Error";
          setTimeout(() => {
            element.innerHTML = "Save";
          }, 1000);
        });
    }
  };
  privateSubmit = async (e) => {
    // console.log("private", this.state.getprivateToken);
    // this.setState({
    // getprivateToken: "",
    // ecompublicToken: "",
    // ecomprivateToken: "",
    // });
    let data = {};
    data["integrate"] = "clover";
    if (this.state.getprivateToken)
      data["api_key"] = this.state.getprivateToken;
    if (this.state.ecompublicToken)
      data["ecom_public_key"] = this.state.ecompublicToken;
    if (this.state.ecomprivateToken)
      data["ecom_private_key"] = this.state.ecomprivateToken;
    if (this.state.merchant_id) data["merchant_id"] = this.state.merchant_id;
    // console.log(data);
    let res = await updateCloverToken(this.props.restaurant_id, data);
    if (res.message === "success") {
      console.log("update");
      setTimeout(() => {
        // window.location.href = "/merchant/logout";
        window.location.replace("/merchant/integration");
      }, 3000);
      this.setState({ tokenModal: false, editable: false });
      this.updateCloverTokenState();
    } else {
      console.log("not update");
      this.setState({ tokenModal: false, disable: false });
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <>
        <div
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={this.state.setValue}>
              <Box
                className="tab-links"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <TabList
                  onChange={this.handleTab}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  <Tab label="Google Integration" value="1" />
                  <Tab label="Square Integration" value="2" />
                  <Tab label="Clover Integration" value="3" />
                  <Tab label="Banking Details" value="4" />
                  <Tab
                    label="Doordash, Grubhub and MealHi5 Integration"
                    value="5"
                  />
                  {/* <Tab label="Item Mapping" value="6" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="d-flex justify-content-flex-start mt-3 text-center integration">
                  <div className="mr-10" style={{ marginRight: "10em" }}>
                    <div
                      style={{
                        background: "rgb(58 47 47 / 15%)",
                        display: "inline-block",
                        padding: "10px",
                        borderRadius: "50%",
                      }}
                    >
                      <img style={{ width: "50px" }} src="/google.png" />
                    </div>

                    {this.state.gmailSign.gmail_status ? (
                      <>
                        <h2>
                          Connect your location's <br /> Google Account <br />(
                          {this.state.gmailSign.email}) <br />
                          <button
                            style={{
                              color: "rgba(255, 0, 0, 0.72)",
                              background: "none",
                              padding: "5px",
                              display: "inline-block",
                              border: "1px solid",
                            }}
                            onClick={this.removeEmail}
                          >
                            Remove
                          </button>{" "}
                        </h2>
                        <p style={{ color: "green" }}>Connected!</p>

                        {this.state.gmailSign.forward_address &&
                          this.state.gmailSign.forward_address.map(
                            (address) => {
                              return (
                                <>
                                  <p
                                    style={{
                                      fontSize: "15px",
                                      margin: "5px 0",
                                    }}
                                  >
                                    {address.forwardingEmail}{" "}
                                    {address.verificationStatus ===
                                      "accepted" ? (
                                      <>
                                        <i
                                          class="fa fa-check"
                                          aria-hidden="true"
                                        ></i>
                                      </>
                                    ) : address.verificationStatus ===
                                      "pending" ? (
                                      <>
                                        <i
                                          class="fa fa-spinner"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </>
                                    ) : (
                                      <>
                                        <i
                                          class="fa fa-ban"
                                          aria-hidden="true"
                                        ></i>
                                      </>
                                    )}
                                  </p>
                                </>
                              );
                            }
                          )}
                      </>
                    ) : (
                      <>
                        <h2>
                          Connect your location's <br /> Google Account <br />
                        </h2>
                        <a
                          style={{
                            border: "1px solid #ccc",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                            padding: "10px",
                            fontSize: "18px",
                            borderRadius: "5px",
                            color: "#504e4e",
                            fontFamily: "PT Sans",
                          }}
                          href={`https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&include_granted_scopes=true&response_type=code&client_id=583778606561-qojut5321k03rqc2337f33c05cbrik9u.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Forder.phonedash.us%2Fapi%2Fintegrations%2Fgoogle&state=${this.props.restaurant_id}`}
                        >
                          <img
                            style={{ width: "20px", verticalAlign: "center" }}
                            src="/google.png"
                          />{" "}
                          Sign in with Google
                        </a>
                      </>
                    )}
                  </div>

                  {this.state.gmailSign.email ? (
                    <>
                      <div>
                        <div
                          style={{
                            background: "rgb(58 47 47 / 15%)",
                            display: "inline-block",
                            padding: "10px",
                            borderRadius: "50%",
                          }}
                        >
                          <img style={{ width: "50px" }} src="/gmb-2.png" />
                        </div>
                        <h2>Google My Business</h2>

                        {this.state.selectedLocation ? (
                          <>
                            <div>
                              <p>Selected page:</p>
                              <p>{this.state.selectedLocation}</p>
                            </div>
                            <button onClick={this.handleShow}>
                              Change page
                            </button>
                            <button
                              onClick={this.handleDelete}
                              style={{
                                backgroundColor: "#fff",
                                color: "#ff0000b8",
                                border: "1px solid #ff0000b8",
                              }}
                            >
                              Remove page
                            </button>
                          </>
                        ) : (
                          <>
                            <button onClick={this.handleShow}>
                              Select a GMB page
                            </button>
                          </>
                        )}
                      </div>
                      <Modal
                        show={this.state.showModal}
                        dialogClassName="modal-fullscreen"
                        onHide={this.handleClose}
                      >
                        <Modal.Header style={{ border: "0" }} closeButton>
                          <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <h4 style={{ marginBottom: "1em" }}>
                            Which business do you want to link to this location?
                          </h4>

                          {this.state.gmailData.length ? (
                            <>
                              <div className="location-select">
                                <select onChange={this.handleChange}>
                                  <option value="Select Page">
                                    Select Page
                                  </option>
                                  {this.state.gmailData.map((item, i) => {
                                    //console.log("item", item.name);
                                    return (
                                      <>
                                        <option
                                          key={i}
                                          id={item.name}
                                          value={item.title}
                                        >
                                          {item.title}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                              <div
                                style={{
                                  display: "block",
                                  margin: "5em auto",
                                  textAlign: "right",
                                }}
                              >
                                <button
                                  style={{
                                    background: "green",
                                    color: "#fff",
                                    fontWeight: "600",
                                    border: "0",
                                    padding: "6px 20px",
                                    borderRadius: "5px",
                                  }}
                                  onClick={this.handleConnect}
                                >
                                  Connect!
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <p>
                                You don't have any connected businesses under
                                this account.
                              </p>
                              <div
                                style={{
                                  display: "block",
                                  margin: "5em auto",
                                  textAlign: "right",
                                }}
                              >
                                <button
                                  style={{
                                    background: "green",
                                    color: "#fff",
                                    fontWeight: "600",
                                    border: "0",
                                    padding: "6px 20px",
                                    borderRadius: "5px",
                                  }}
                                  onClick={this.handleClose}
                                >
                                  Close!
                                </button>
                              </div>
                            </>
                          )}
                        </Modal.Body>
                      </Modal>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {this.state.gmailSign.email && this.state.selectedLocation ? (
                  <>
                    <div
                      className="integrate-phone"
                      style={{ margin: "4em 0 0" }}
                    >
                      <h4> Add Phone Numbers</h4>
                      {/* <p>{formatPhoneNumber(9164762425)}</p> */}
                      <div>
                        {this.state.NumberOpen || this.state.primaryNumber ? (
                          <>
                            <table>
                              <thead>
                                <th>Location Name</th>
                                <th>Phone Number</th>
                              </thead>
                              <tbody>
                                <tr>
                                  {/* <td>{this.state.gmailData.filter(locationData=>locationData.title == this.state.selectedLocation)[0]?.title}</td> */}
                                  <td>{this.state.selectedLocation}</td>
                                  <td>
                                    {formatPhoneNumber(
                                      this.state.primaryNumber
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <></>
                        )}

                        <div>
                          <div className="addnewnumber">
                            {this.state.showNumber ? (
                              <>
                                <div>
                                  <input
                                    type="tel"
                                    value={this.state.getNewNumber}
                                    placeholder="7565376737"
                                    name="addphone"
                                    onChange={this.handleAdd}
                                  />
                                  <button onClick={this.handlesubmit}>
                                    Add
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <button onClick={this.handleOpen}>
                                  Add New Number
                                </button>
                              </>
                            )}

                            {this.state.isOpen ? (
                              <>
                                <Modal show={this.state.isOpen}>
                                  <Modal.Body>
                                    <h4>Thank You</h4>
                                    Your Phone Number has been updated
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={() =>
                                        this.setState({
                                          isOpen: false,
                                          showNumber: false,
                                        })
                                      }
                                    >
                                      Close
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.showMenu ? (
                      <div
                        className="integrate-phone"
                        style={{ margin: "4em 0 0" }}
                      >
                        <h4> Add Menu Link</h4>
                        {/* <p>{formatPhoneNumber(9164762425)}</p> */}
                        <div>
                          <>
                            <table>
                              <thead>
                                <th>Location Name</th>
                                <th>Menu Link</th>
                              </thead>
                              <tbody>
                                <tr>
                                  {/* <td>{this.state.gmailData.filter(locationData=>locationData.title == this.state.selectedLocation)[0]?.title}</td> */}
                                  <td>{this.state.selectedLocation}</td>
                                  <td>{this.state.showMenu}</td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                          <div>
                            <div className="addnewnumber">
                              {this.state.showUrl ? (
                                <>
                                  <div>
                                    <input
                                      type="text"
                                      value={this.state.getMenuUrl}
                                      placeholder="Enter Menu link"
                                      name="addphone"
                                      onChange={this.handleMenuUrl}
                                    />
                                    <button onClick={this.handleUrlsubmit}>
                                      Add
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <button onClick={this.handleOpenUrl}>
                                    Add New Menu Link
                                  </button>
                                </>
                              )}
                              {/* {console.log(this.state.urlModal)} */}
                              <Modal show={this.state.urlModal}>
                                <Modal.Body>
                                  <h4>Thank You</h4>
                                  Your Menu Link has been updated
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={() =>
                                      this.setState({
                                        urlModal: false,
                                        showUrl: false,
                                      })
                                    }
                                  >
                                    Close
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </TabPanel>
              <TabPanel value="2">
                <div className="square">
                  {/* <a
                    style={{
                      color: "#fff",
                      fontWeight: "700",
                      boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "5px",
                      fontFamily: "PT Sans",
                      backgroundColor: "#ec7211",
                    }}
                    href={`https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-jT6QbGjR_Y_5JbdWmKPc1w&scope=ITEMS_WRITE+ORDERS_READ+ORDERS_WRITE+PAYMENTS_READ+PAYMENTS_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE_IN_PERSON+PAYMENTS_WRITE_SHARED_ONFILE+CUSTOMERS_WRITE+CUSTOMERS_READ+MERCHANT_PROFILE_READ+ITEMS_READ+DEVICE_CREDENTIAL_MANAGEMENT+DISPUTES_WRITE+DISPUTES_READ+GIFTCARDS_READ+GIFTCARDS_WRITE+INVOICES_READ+INVOICES_WRITE+MERCHANT_PROFILE_WRITE+PAYOUTS_READ+ONLINE_STORE_SITE_READ+VENDOR_WRITE+VENDOR_READ&session=False&state=${this.props.restaurant_id}`}
                    className="btn"
                  >
                    Sign in{" "}
                  </a> */}
                  {/* {console.log("data", this.state.squareData)} */}
                  {this.state.squareVerify ? (
                    <>
                      <button
                        onClick={this.handleSquareDelete}
                        style={{
                          background: "red",
                          color: "#fff",
                          fontWeight: "600",
                          border: "0",
                          padding: "6px 20px",
                          borderRadius: "5px",
                        }}
                      >
                        Sign Out
                      </button>
                      <p
                        style={{
                          display: "inline-block",
                          padding: "5px",
                          background: "#fff",
                          color: "#079907",
                          margin: "0",
                          fontFamily: "PT sans",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        Connected!
                      </p>
                    </>
                  ) : (
                    <>
                      <a
                        style={{
                          color: "#fff",
                          fontWeight: "700",
                          boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                          padding: "10px",
                          fontSize: "18px",
                          borderRadius: "5px",
                          fontFamily: "PT Sans",
                          backgroundColor: "#ec7211",
                        }}
                        href={`https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-jT6QbGjR_Y_5JbdWmKPc1w&scope=APPOINTMENTS_ALL_READ+APPOINTMENTS_ALL_WRITE+APPOINTMENTS_BUSINESS_SETTINGS_READ+APPOINTMENTS_READ+APPOINTMENTS_WRITE+BANK_ACCOUNTS_READ+CASH_DRAWER_READ+CUSTOMERS_READ+CUSTOMERS_WRITE+DEVICE_CREDENTIAL_MANAGEMENT+DISPUTES_READ+DISPUTES_WRITE+EMPLOYEES_READ+EMPLOYEES_WRITE+GIFTCARDS_READ+GIFTCARDS_WRITE+INVENTORY_READ+INVENTORY_WRITE+INVOICES_READ+INVOICES_WRITE+ITEMS_READ+ITEMS_WRITE+LOYALTY_READ+LOYALTY_WRITE+MERCHANT_PROFILE_READ+MERCHANT_PROFILE_WRITE+ONLINE_STORE_SITE_READ+ONLINE_STORE_SNIPPETS_READ+ONLINE_STORE_SNIPPETS_WRITE+ORDERS_READ+ORDERS_WRITE+PAYMENTS_READ+PAYMENTS_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE_IN_PERSON+PAYMENTS_WRITE_SHARED_ONFILE+PAYOUTS_READ+SETTLEMENTS_READ+SUBSCRIPTIONS_READ+SUBSCRIPTIONS_WRITE+TIMECARDS_READ+TIMECARDS_SETTINGS_READ+TIMECARDS_SETTINGS_WRITE+TIMECARDS_WRITE+VENDOR_READ+VENDOR_WRITE&session=False&state=${this.props.restaurant_id}`}
                        className="btn"
                      >
                        Sign in{" "}
                      </a>
                      <p
                        style={{
                          display: "inline-block",
                          padding: "5px",
                          background: "#fff",
                          color: "#ff0000bf",
                          margin: "0",
                          fontFamily: "PT sans",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        Not Connected
                      </p>
                    </>
                  )}
                  {this.state.squareVerify &&
                    this.state.integrateStatus === "square" ? (
                    <p
                      style={{
                        display: "inline-block",
                        padding: "5px",
                        background: "#fff",
                        color: "#079907",
                        margin: "0",
                        fontFamily: "PT sans",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Active
                    </p>
                  ) : (
                    <></>
                  )}
                  {this.state.squareVerify &&
                    this.state.integrateStatus !== "square" ? (
                    <button
                      onClick={this.ActivateSquare}
                      style={{
                        textAlign: "center",
                        background: "green",
                        color: "#fff",
                        fontWeight: "600",
                        border: "0",
                        padding: "5px 20px",
                        borderRadius: "5px",
                      }}
                    >
                      Activate
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
                {this.state.squareData.length ? (
                  <>
                    <div className="square">
                      {this.state.selectedSquareLocation ? (
                        <>
                          <h3>Square Location</h3>
                          <div>
                            <p>{this.state.selectedSquareLocation}</p>
                          </div>
                          <div style={{ display: "grid" }}>
                            <button
                              style={{
                                background: "green",
                                color: "#fff",
                                fontWeight: "600",
                                border: "0",
                                padding: "6px 20px",
                                borderRadius: "5px",
                              }}
                              onClick={this.handleShow}
                            >
                              Change location
                            </button>
                            <br />
                            <button
                              onClick={this.handleSquareLocationDelete}
                              style={{
                                background: "red",
                                color: "#fff",
                                fontWeight: "600",
                                border: "0",
                                padding: "6px 20px",
                                borderRadius: "5px",
                              }}
                            >
                              Remove location
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            style={{
                              background: "green",
                              color: "#fff",
                              fontWeight: "600",
                              border: "0",
                              padding: "6px 20px",
                              borderRadius: "5px",
                            }}
                            onClick={this.handleShow}
                          >
                            Select a Square Location
                          </button>
                        </>
                      )}
                    </div>
                    <Modal
                      show={this.state.showModal}
                      dialogClassName="modal-fullscreen"
                      onHide={this.handleClose}
                    >
                      <Modal.Header style={{ border: "0" }} closeButton>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <h4 style={{ marginBottom: "1em" }}>
                          Which business do you want to link to this location?
                        </h4>

                        {this.state.squareData.length ? (
                          <>
                            <div className="location-select">
                              <select onChange={this.handleSquareChange}>
                                <option value="Select Page">
                                  Select Location
                                </option>
                                {this.state.squareData.map((item, i) => {
                                  return (
                                    <>
                                      <option
                                        key={i}
                                        id={item.id}
                                        value={item.name}
                                      >
                                        {item.name}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              style={{
                                display: "block",
                                margin: "5em auto",
                                textAlign: "right",
                              }}
                            >
                              <button
                                style={{
                                  background: "green",
                                  color: "#fff",
                                  fontWeight: "600",
                                  border: "0",
                                  padding: "6px 20px",
                                  borderRadius: "5px",
                                }}
                                onClick={this.handleSquareConnect}
                              >
                                Connect!
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <p>
                              You don't have any connected businesses under this
                              account.
                            </p>
                            <div
                              style={{
                                display: "block",
                                margin: "5em auto",
                                textAlign: "right",
                              }}
                            >
                              <button
                                style={{
                                  background: "green",
                                  color: "#fff",
                                  fontWeight: "600",
                                  border: "0",
                                  padding: "6px 20px",
                                  borderRadius: "5px",
                                }}
                                onClick={this.handleClose}
                              >
                                Close!
                              </button>
                            </div>
                          </>
                        )}
                      </Modal.Body>
                    </Modal>
                  </>
                ) : (
                  <></>
                )}
              </TabPanel>
              <TabPanel value="3">
                <div className="square">
                  {/* {["foodparadise", "phonedash", "phonedash-restaurant"].includes(this.props.restaurant_id.toLowerCase()) || true ? ( */}
                  {!this.state.cloverVerify ? (
                    <a
                      style={{
                        color: "#fff",
                        fontWeight: "700",
                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                        padding: "10px",
                        fontSize: "18px",
                        borderRadius: "5px",
                        fontFamily: "PT Sans",
                        backgroundColor: "#ec7211",
                      }}
                      // SANDBOX
                      // href={`https://sandbox.dev.clover.com/oauth/authorize?client_id=DF5CGX59EHRG4&packageName=UNKNOWN&state=${this.props.restaurant_id}`}
                      // PRODUCTION
                      href={`https://www.clover.com/oauth/authorize?client_id=FQASQRFMZ2C1A&state=${this.props.restaurant_id}`}
                      // href={`https://www.clover.com/oauth/merchants/X3WKCYHB0J7S1?client_id=FQASQRFMZ2C1A&packageName=UNKNOWN&state=${this.props.restaurant_id}`}
                      className="btn"
                    >
                      Sign in{" "}
                    </a>
                  ) : (
                    <></>
                  )}
                  {this.state.cloverVerify &&
                    this.state.integrateStatus === "clover" ? (
                    <p
                      style={{
                        display: "inline-block",
                        padding: "5px",
                        background: "#fff",
                        color: "#079907",
                        margin: "0",
                        fontFamily: "PT sans",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Active
                    </p>
                  ) : (
                    <></>
                  )}
                  {this.state.cloverVerify &&
                    this.state.integrateStatus !== "clover" ? (
                    <button
                      onClick={this.ActivateClover}
                      style={{
                        textAlign: "center",
                        background: "green",
                        color: "#fff",
                        fontWeight: "600",
                        border: "0",
                        padding: "5px 20px",
                        borderRadius: "5px",
                      }}
                    >
                      Activate
                    </button>
                  ) : (
                    <></>
                  )}
                  {this.state.cloverVerify ? (
                    <p
                      style={{
                        display: "inline-block",
                        padding: "5px",
                        background: "#fff",
                        color: "#079907",
                        margin: "0",
                        fontFamily: "PT sans",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Connected!
                    </p>
                  ) : (
                    <p
                      style={{
                        display: "inline-block",
                        padding: "5px",
                        background: "#fff",
                        color: "#ff0000bf",
                        margin: "0",
                        fontFamily: "PT sans",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Not Connected
                    </p>
                  )}
                </div>
                {window.innerWidth > 767 ? (
                  <>
                    {" "}
                    <div className="d-flex flex-row-reverse clover-integration">
                      <div style={{ width: "66%" }}>
                        <div className="form-group">
                          <p style={{ marginBottom: "5em" }}>
                            <label>API Token</label>
                            <input
                              type="text"
                              value={this.state.getprivateToken}
                              placeholder="Enter API token"
                              name="getprivateToken"
                              onChange={this.handleprivateToken}
                              disabled={this.state.disable}
                            />
                          </p>
                          <p>
                            <label>Ecommerce Private Token</label>
                            <input
                              type="text"
                              value={this.state.ecomprivateToken}
                              placeholder="Ecommerce private token"
                              name="ecomprivateToken"
                              onChange={this.handleprivateToken}
                              disabled={this.state.disable}
                            />
                          </p>
                          <p style={{ marginBottom: "5em" }}>
                            <label>Ecommerce Public Token</label>
                            <input
                              type="text"
                              value={this.state.ecompublicToken}
                              placeholder="Ecommerce public token"
                              name="ecompublicToken"
                              onChange={this.handleprivateToken}
                              disabled={this.state.disable}
                            />
                          </p>
                          <p>
                            <label>Merchant ID</label>
                            <input
                              type="text"
                              value={this.state.merchant_id}
                              placeholder="Enter Merchant Id"
                              name="merchant_id"
                              onChange={this.handleprivateToken}
                              disabled={this.state.disable}
                            />
                          </p>

                          {!this.state.editable ? (
                            <button
                              style={{ textAlign: "center" }}
                              onClick={() =>
                                this.setState({
                                  editable: true,
                                  disable: false,
                                })
                              }
                            >
                              Edit
                            </button>
                          ) : (
                            <></>
                          )}
                          {this.state.cloverVerify ? (
                            <div style={{ textAlign: "right" }}>
                              <button
                                onClick={this.handleCloverDelete}
                                style={{
                                  textAlign: "right",
                                  background: "red",
                                  color: "#fff",
                                  fontWeight: "600",
                                  border: "0",
                                  padding: "5px 20px",
                                  borderRadius: "5px",
                                }}
                              >
                                Sign Out
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                          {this.state.editable ? (
                            <div style={{ textAlign: "right" }}>
                              <button
                                style={{ textAlign: "right" }}
                                onClick={() =>
                                  this.setState({
                                    editable: true,
                                    tokenModal: true,
                                    disable: true,
                                  })
                                }
                              >
                                Save
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="token-steps">
                        <h5>Clover API Token</h5>
                        <ul>
                          <li>
                            On the clover account click{" "}
                            <b>Account & Setup &gt;</b> under{" "}
                            <b>Business Operations &gt; API Tokens. </b>
                          </li>
                          <li>
                            Click the <b>Create new token</b>. Enter the name of
                            the token and mark all checkbox for read and write
                            permissions.
                          </li>
                          <li>
                            Select <b>Create Token</b>. Then copy the private
                            token and paste it into the API Token.
                          </li>
                        </ul>
                        <h5>Clover E-commerce Public and Private Token</h5>
                        <ul>
                          <li>
                            On the clover account click{" "}
                            <b>Account & Setup &gt;</b> under{" "}
                            <b>Ecommerce &gt; Ecommerce API Tokens.</b>
                          </li>
                          <li>
                            Click the <b>Create new token</b>. Select
                            Integration type as API.
                          </li>
                          <li>
                            Select <b>Create Token.</b>{" "}
                          </li>
                          <li>
                            Then copy the <b>Public and Private</b> tokens and
                            paste them into the{" "}
                            <b>
                              E-commerce Public and E-commerce Private Token.
                            </b>
                          </li>
                        </ul>
                        <h5>Get Merchant ID</h5>
                        <ul>
                          <li>
                            On the clover account click <b>Account & Setup</b>{" "}
                            &gt; under <b>About Your Business</b> &gt;{" "}
                            <b>Merchants</b> or <b>Click Here</b>.
                          </li>
                          <li>Copy the ID and paste it into the Merchant</li>
                        </ul>
                        <button
                          style={{
                            padding: "0",
                            textDecoration: "underline",
                            background: "none",
                            border: "0",
                            color: "#007eff",
                          }}
                          onClick={() =>
                            this.setState({
                              screenshotOpen: true,
                            })
                          }
                        >
                          See Screenshot
                        </button>
                        {this.state.screenshotOpen ? (
                          <>
                            <Modal
                              onHide={this.handleClose}
                              show={this.state.screenshotOpen}
                              dialogClassName="open-img"
                              size="lg"
                            >
                              <Modal.Header style={{ border: "0" }} closeButton>
                                <Modal.Title></Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <img
                                  style={{ width: "100%" }}
                                  src={screenshot}
                                />
                              </Modal.Body>
                            </Modal>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {this.state.editable ? (
                        <>
                          <Modal show={this.state.tokenModal}>
                            <Modal.Body>
                              <p>Are you proceed to change the token</p>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                style={{
                                  background: "#079907",
                                  padding: "4px",
                                }}
                                onClick={this.privateSubmit}

                              // onClick={() =>
                              // this.setState({
                              // tokenModal: false,
                              // })
                              // }
                              >
                                Confirm
                              </Button>
                              <Button
                                variant="secondary"
                                style={{
                                  background: "rgb(211, 89, 95)",
                                  padding: "4px",
                                }}
                                onClick={() =>
                                  this.setState({
                                    tokenModal: false,
                                  })
                                }
                              >
                                Cancel
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="d-flex justify-content-between">
                      <div>
                        {!this.state.editable ? (
                          <button
                            className="btn-mobile-clover"
                            style={{ textAlign: "center" }}
                            onClick={() =>
                              this.setState({
                                editable: true,
                                disable: false,
                              })
                            }
                          >
                            Edit
                          </button>
                        ) : (
                          <></>
                        )}
                        {this.state.editable ? (
                          <div style={{ textAlign: "right" }}>
                            <button
                              style={{ textAlign: "right" }}
                              className="btn-mobile-clover"
                              onClick={() =>
                                this.setState({
                                  editable: true,
                                  tokenModal: true,
                                  disable: true,
                                })
                              }
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {this.state.cloverVerify ? (
                        <div style={{ textAlign: "right" }}>
                          <button
                            onClick={this.handleCloverDelete}
                            style={{
                              textAlign: "right",
                              background: "red",
                              color: "#fff",
                              fontWeight: "600",
                              border: "0",
                              padding: "5px 20px",
                              borderRadius: "5px",
                            }}
                          >
                            Sign Out
                          </button>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse clover-integration">
                      <div className="token-steps">
                        <div className="d-flex flex-column">
                          <div>
                            <h5>Clover API Token</h5>
                            <ul>
                              <li>
                                On the clover account click{" "}
                                <b>Account & Setup &gt;</b> under{" "}
                                <b>Business Operations &gt; API Tokens. </b>
                              </li>
                              <li>
                                Click the <b>Create new token</b>. Enter the
                                name of the token and mark all checkbox for read
                                and write permissions.
                              </li>
                              <li>
                                Select <b>Create Token</b>. Then copy the
                                private token and paste it into the API Token.
                              </li>
                            </ul>
                          </div>
                          <div>
                            {" "}
                            <p style={{ marginBottom: "5em" }}>
                              <label>API Token</label>
                              <input
                                type="text"
                                value={this.state.getprivateToken}
                                placeholder="Enter API token"
                                name="getprivateToken"
                                onChange={this.handleprivateToken}
                                disabled={this.state.disable}
                              />
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div>
                            <h5>Clover E-commerce Public and Private Token</h5>
                            <ul>
                              <li>
                                On the clover account click{" "}
                                <b>Account & Setup &gt;</b> under{" "}
                                <b>Ecommerce &gt; Ecommerce API Tokens.</b>
                              </li>
                              <li>
                                Click the <b>Create new token</b>. Select
                                Integration type as API.
                              </li>
                              <li>
                                Select <b>Create Token.</b>{" "}
                              </li>
                              <li>
                                Then copy the <b>Public and Private</b> tokens
                                and paste them into the{" "}
                                <b>
                                  E-commerce Public and E-commerce Private
                                  Token.
                                </b>
                              </li>
                            </ul>
                          </div>
                          <div>
                            {" "}
                            <p>
                              <label>Ecommerce Private Token</label>
                              <input
                                type="text"
                                value={this.state.ecomprivateToken}
                                placeholder="Ecommerce private token"
                                name="ecomprivateToken"
                                onChange={this.handleprivateToken}
                                disabled={this.state.disable}
                              />
                            </p>
                            <p style={{ marginBottom: "5em" }}>
                              <label>Ecommerce Public Token</label>
                              <input
                                type="text"
                                value={this.state.ecompublicToken}
                                placeholder="Ecommerce public token"
                                name="ecompublicToken"
                                onChange={this.handleprivateToken}
                                disabled={this.state.disable}
                              />
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div>
                            <h5>Get Merchant ID</h5>
                            <ul>
                              <li>
                                On the clover account click{" "}
                                <b>Account & Setup</b> &gt; under{" "}
                                <b>About Your Business</b> &gt; <b>Merchants</b>{" "}
                                or <b>Click Here</b>.
                              </li>
                              <li>
                                Copy the ID and paste it into the Merchant
                              </li>
                            </ul>
                            <button
                              style={{
                                padding: "0",
                                textDecoration: "underline",
                                background: "none",
                                border: "0",
                                color: "#007eff",
                              }}
                              onClick={() =>
                                this.setState({
                                  screenshotOpen: true,
                                })
                              }
                            >
                              See Screenshot
                            </button>
                            {this.state.screenshotOpen ? (
                              <>
                                <Modal
                                  onHide={this.handleClose}
                                  show={this.state.screenshotOpen}
                                  dialogClassName="open-img"
                                  size="lg"
                                >
                                  <Modal.Header
                                    style={{ border: "0" }}
                                    closeButton
                                  >
                                    <Modal.Title></Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <img
                                      style={{ width: "100%" }}
                                      src={screenshot}
                                    />
                                  </Modal.Body>
                                </Modal>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div>
                          {" "}
                          <p>
                            <label>Merchant ID</label>
                            <input
                              type="text"
                              value={this.state.merchant_id}
                              placeholder="Enter Merchant Id"
                              name="merchant_id"
                              onChange={this.handleprivateToken}
                              disabled={this.state.disable}
                            />
                          </p>
                        </div>
                      </div>

                      {this.state.editable ? (
                        <>
                          <Modal show={this.state.tokenModal}>
                            <Modal.Body>
                              <p>Are you proceed to change the token</p>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                style={{
                                  background: "#079907",
                                  padding: "4px",
                                }}
                                onClick={this.privateSubmit}

                              // onClick={() =>
                              // this.setState({
                              // tokenModal: false,
                              // })
                              // }
                              >
                                Confirm
                              </Button>
                              <Button
                                variant="secondary"
                                style={{
                                  background: "rgb(211, 89, 95)",
                                  padding: "4px",
                                }}
                                onClick={() =>
                                  this.setState({
                                    tokenModal: false,
                                  })
                                }
                              >
                                Cancel
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
                {/* <div style={{ padding: "0" }}>
                  {this.state.cloverVerify ? (
                    <>
                      <div style={{ textAlign: "right" }}>
                        <p
                          style={{
                            display: "inline-block",
                            padding: "5px",
                            background: "#079907",
                            margin: "0",
                            color: "#fff",
                            fontFamily: "PT sans",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          Connected!
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <p
                        style={{
                          textAlign: "right",
                          margin: "0",
                          color: "red",
                          fontFamily: "PT sans",
                          fontWeight: "600",
                          fontSize: "20px",
                        }}
                      >
                        Not Connected
                      </p>
                    </>
                  )}

                  <div className="d-flex flex-row-reverse clover-integration">
                    <div style={{ width: "66%" }}>
                      <div className="form-group">
                        <p style={{ marginBottom: "5em" }}>
                          <label>API Token</label>
                          <input
                            type="text"
                            value={this.state.getprivateToken}
                            placeholder="Enter API token"
                            name="getprivateToken"
                            onChange={this.handleprivateToken}
                            disabled={this.state.disable}
                          />
                        </p>
                        <p>
                          <label>Ecommerce Private Token</label>
                          <input
                            type="text"
                            value={this.state.ecomprivateToken}
                            placeholder="Ecommerce private token"
                            name="ecomprivateToken"
                            onChange={this.handleprivateToken}
                            disabled={this.state.disable}
                          />
                        </p>
                        <p style={{ marginBottom: "5em" }}>
                          <label>Ecommerce Public Token</label>
                          <input
                            type="text"
                            value={this.state.ecompublicToken}
                            placeholder="Ecommerce public token"
                            name="ecompublicToken"
                            onChange={this.handleprivateToken}
                            disabled={this.state.disable}
                          />
                        </p>
                        <p>
                          <label>Merchant ID</label>
                          <input
                            type="text"
                            value={this.state.merchant_id}
                            placeholder="Enter Merchant Id"
                            name="merchant_id"
                            onChange={this.handleprivateToken}
                            disabled={this.state.disable}
                          />
                        </p>

                        {!this.state.editable ? (
                          <button
                            style={{ textAlign: "center" }}
                            onClick={() =>
                              this.setState({
                                editable: true,
                                disable: false,
                              })
                            }
                          >
                            Edit
                          </button>
                        ) : (
                          <></>
                        )}
                        {this.state.editable ? (
                          <div style={{ textAlign: "right" }}>
                            <button
                              style={{ textAlign: "right" }}
                              onClick={() =>
                                this.setState({
                                  editable: true,
                                  tokenModal: true,
                                  disable: true,
                                })
                              }
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="token-steps">
                      <h5>Clover API Token</h5>
                      <ul>
                        <li>
                          On the clover account click{" "}
                          <b>Account & Setup &gt;</b> under{" "}
                          <b>Business Operations &gt; API Tokens. </b>
                        </li>
                        <li>
                          Click the <b>Create new token</b>. Enter the name of
                          the token and mark all checkbox for read and write
                          permissions.
                        </li>
                        <li>
                          Select <b>Create Token</b>. Then copy the private
                          token and paste it into the API Token.
                        </li>
                      </ul>
                      <h5>Clover E-commerce Public and Private Token</h5>
                      <ul>
                        <li>
                          On the clover account click{" "}
                          <b>Account & Setup &gt;</b> under{" "}
                          <b>Ecommerce &gt; Ecommerce API Tokens.</b>
                        </li>
                        <li>
                          Click the <b>Create new token</b>. Select Integration
                          type as API.
                        </li>
                        <li>
                          Select <b>Create Token.</b>{" "}
                        </li>
                        <li>
                          Then copy the <b>Public and Private</b> tokens and
                          paste them into the{" "}
                          <b>E-commerce Public and E-commerce Private Token.</b>
                        </li>
                      </ul>
                      <h5>Get Merchant ID</h5>
                      <ul>
                        <li>
                          On the clover account click <b>Account & Setup</b>{" "}
                          &gt; under <b>About Your Business</b> &gt;{" "}
                          <b>Merchants</b> or <b>Click Here</b>.
                        </li>
                        <li>Copy the ID and paste it into the Merchant</li>
                      </ul>
                      <button
                        style={{
                          padding: "0",
                          textDecoration: "underline",
                          background: "none",
                          border: "0",
                          color: "#007eff",
                        }}
                        onClick={() =>
                          this.setState({
                            screenshotOpen: true,
                          })
                        }
                      >
                        See Screenshot
                      </button>
                      {this.state.screenshotOpen ? (
                        <>
                          <Modal
                            onHide={this.handleClose}
                            show={this.state.screenshotOpen}
                            dialogClassName="open-img"
                            size="lg"
                          >
                            <Modal.Header style={{ border: "0" }} closeButton>
                              <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <img style={{ width: "100%" }} src={screenshot} />
                            </Modal.Body>
                          </Modal>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {this.state.editable ? (
                      <>
                        <Modal show={this.state.tokenModal}>
                          <Modal.Body>
                            <p>Are you proceed to change the token</p>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              style={{ background: "#079907" }}
                              onClick={this.privateSubmit}
                              // onClick={() =>
                              // this.setState({
                              // tokenModal: false,
                              // })
                              // }
                            >
                              Confirm
                            </Button>
                            <Button
                              variant="secondary"
                              style={{ background: "rgb(211, 89, 95)" }}
                              onClick={() =>
                                this.setState({
                                  tokenModal: false,
                                })
                              }
                            >
                              Cancel
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div> */}
              </TabPanel>
              <TabPanel value="4">
                {/* <h5 style={{ marginBottom: "1em" }}>Add Details</h5> */}
                <div className="banking-details">
                  <div className="form-group">
                    {" "}
                    <label htmlFor="Tax Number">EIN/Tax ID Number</label>
                    {this.state.verified ? (
                      <span
                        style={{
                          backgroundColor: " #cce6cc",
                          padding: "4px 8px",
                          borderRadius: "7px",
                          color: "#4da64d",
                          marginLeft: '10px'
                        }}
                      >
                        Verified
                      </span>
                    ) : (
                      <span
                        style={{
                          backgroundColor: " #fbeeef",
                          padding: "4px 8px",
                          borderRadius: "7px",
                          color: "#d76a6f",
                        }}
                      >
                        Not verified
                      </span>
                    )}
                    <br />
                    <input
                      type="text"
                      onChange={this.handleInput}
                      name="tax_id"
                      value={this.state.tax_id}
                      className="form-control1"
                      id="tax_id"
                      placeholder="2434747904465"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Routing">
                      Routing Number (Transit Number)
                    </label>
                    <br />

                    <input
                      type="number"
                      onChange={this.handleInput}
                      name="routingnumber"
                      value={this.state.routingnumber}
                      className="form-control1"
                      id="routingnumber"
                      placeholder="123456789"
                    />
                  </div>
                  <div className="form-group">
                    {" "}
                    <label htmlFor="restaurant">Account Number</label>
                    <br />
                    <input
                      type="number"
                      onChange={this.handleInput}
                      name="accountnumber"
                      value={this.state.accountnumber}
                      className="form-control1"
                      id="accountnumber"
                      placeholder="000123456789"
                    />
                  </div>

                  {/*
 <p
 style={{
 textAlign: "left",
 padding: "16px 0 0",
 margin: "0",
 }}
 >
 Or
 </p> */}
                  <div className="form-group">
                    {" "}
                    <label htmlFor="void">Void Check</label>
                    <br />
                    <input
                      type="file"
                      onChange={this.handleFileUpload}
                      name="voidFile"
                      //value={this.state.voidFile}
                      className="form-control1"
                      id="uploadcheque"
                    />
                    {this.state.voidLink ? (
                      <p style={{ padding: "10px 0 0" }}>
                        {/* {this.state.voidName} */}
                        {/* <i
 style={{ color: "blue" }}
 className="fa fa-download"
 onClick={this.handleVoidDownload}
 ></i> */}
                        <i
                          style={{ color: "blue" }}
                          className="fa fa-paperclip"
                        >
                          {" "}
                          {this.state.voidName}
                        </i>
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>

                  {this.state.bankAdded && !this.state.verified ? (
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        type="number"
                        onChange={this.handleInput}
                        name="amount1"
                        placeholder="Amount 1"
                        value={this.state.amount1}
                        className="form-control"
                        id="amount1"
                      />
                      <input
                        type="number"
                        onChange={this.handleInput}
                        placeholder="Amount 2"
                        name="amount2"
                        value={this.state.amount2}
                        className="form-control"
                        id="amount2"
                      />
                      <button onClick={this.handleVerify}>Verify</button>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div>
                    <button onClick={this.handleAddBank}>Submit</button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="5">
                <div className="filter-page">
                  {/* <p
 style={{
 display: "inline-block",
 padding: "5px",
 background: "#079907",
 margin: "0",
 color: "#fff",
 fontFamily: "PT sans",
 fontWeight: "600",
 fontSize: "18px",
 }}
 >
 Connected!
 </p> */}

                  {/* <div className="form-group">
 <p style={{ margin: "0 0 5px", fontFamily: "PT Sans" }}>
 Grubhub
 </p>
 <input
 type="text"
 value={this.state.grubhubMail}
 placeholder="Enter Email"
 name="grubhubMail"
 onChange={this.handleprivateToken}
 // disabled={this.state.disable}
 />
 {this.state.grubhubstatus ? (
 <span>{this.state.grubhubstatus}</span>
 ) : (
 <>Not verifed</>
 )}
 <br />
 <br />
 <button
 style={{ padding: "5px 10px" }}
 onClick={this.submitMailGrubhub}
 >
 Submit
 </button>
 </div> */}

                  <div className="filter-overlay d-flex">
                    <div>
                      <h3>To Create A Forwarding Email</h3>
                      <ul>
                        <li>Open Gmail</li>
                        <li>
                          In the top right, click Settings and then See all
                          settings.
                        </li>
                        <li>Click the Forwarding and POP/IMAP tab.</li>
                        <li>
                          In the "Forwarding" section, click Add a forwarding
                          address.
                        </li>
                        <li>
                          Enter{" "}
                          <a href="mailto:order@phonedash.us">
                            order@phonedash.us
                          </a>{" "}
                          and{" "}
                          <a href="mailto:phonedashselftest@gmail.com ">
                            phonedashselftest@gmail.com{" "}
                          </a>
                          .
                        </li>
                        <li>Click Next and then Proceed and then OK.</li>
                      </ul>
                    </div>
                    <div>
                      <h3>To Create A Filter</h3>
                      <ul>
                        <li>Open Gmail</li>
                        <li>
                          In the top right, click Settings and then See all
                          settings.
                        </li>
                        <li>Click the Filters and Blocked Addresses.</li>
                        <li>Click Import Filters.</li>
                        <li>Click on Choose file.</li>
                        <li>
                          <button onClick={this.buttonClick}>
                            Click here to download file
                          </button>
                        </li>
                        <li>Upload this file here.</li>
                        <li>Click On Open File.</li>
                        <li>Finally Click on Create filter button.</li>
                        <li>Refresh the page.</li>
                        <li>Make sure it is added.</li>
                      </ul>
                    </div>
                  </div>
                  <div className="d-flex doordash-testing">
                    <div className="form-group">
                      <p
                        style={{
                          fontWeight: "600",
                          margin: "0 0 5px",
                          fontSize: "22px",
                          fontFamily: "PT Sans",
                        }}
                      >
                        Doordash{" "}
                        {this.state.doordashstatus ? (
                          <span
                            style={{
                              fontFamily: "PT Sans",
                              padding: "2px 5px",
                              color: "#fff",
                              background: "#008000a6",
                              fontSize: "14px",
                              borderRadius: "3px",
                              verticalAlign: "middle",
                            }}
                          >
                            {this.state.doordashstatus}
                          </span>
                        ) : (
                          <>
                            <span
                              style={{
                                fontFamily: "PT Sans",
                                padding: "2px 5px",
                                color: "#fff",
                                background: "rgb(255 0 0 / 48%)",
                                fontSize: "14px",
                                borderRadius: "3px",
                                verticalAlign: "middle",
                              }}
                            >
                              Not verifed
                            </span>
                          </>
                        )}
                      </p>
                      <input
                        type="text"
                        value={this.state.doordashMail}
                        placeholder="Enter Email"
                        name="doordashMail"
                        onChange={this.handleprivateToken}
                      // disabled={this.state.disable}
                      />
                    </div>
                    <div className="doordash-btns">
                      <button
                        style={{ padding: "10px" }}
                        onClick={this.submitMailDoordash}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* <TabPanel value="6">
 <div className="item-mapping">
 <table>
 <thead>
 <th>Clover Name</th>
 <th>Doordash Name</th>
 
 <th>Grubhub Name</th>
 <th>Action</th>
 </thead>
 <tbody>
 {this.state.clover_menu_item.map((item) => {
 return (
 <>
 <tr>
 <td>{item.clover_name}</td>
 <td>
 <input
 type="text"
 // value={this.state.doordashName}
 placeholder="Enter Doordash Name"
 name="doordash_name"
 defaultValue={item.doordash_name}
 onChange={(e) =>
 this.handleItems(e, item.clover_id)
 }
 // disabled={this.state.disable}
 />
 </td>
 <td>
 <input
 type="text"
 //value={this.state.uberName}
 placeholder="Enter Uber Name"
 name="uber_name"
 onChange={(e) => this.handleItems(e, item.clover_id)}
 //disabled={this.state.disable}
 />
 </td>
 <td>
 <input
 type="text"
 // value={this.state.grubhubName}
 defaultValue={item.grubhub_name}
 placeholder="Enter Grubhub Name"
 name="grubhub_name"
 onChange={(e) =>
 this.handleItems(e, item.clover_id)
 }
 //disabled={this.state.disable}
 />
 </td>
 <td align="center">
 <button
 style={{ padding: "3px 10px", margin: "0" }}
 onClick={(e) =>
 this.submitItems(e, item.clover_id)
 }
 >
 Save
 </button>
 </td>
 </tr>
 </>
 );
 })}
 </tbody>
 </table>
 </div>
 </TabPanel> */}
            </TabContext>
          </Box>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    restaurant_id: state.customer.resId,
  };
};
export default connect(mapStateToProps, null)(Integrations);