import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllOrders, getReceipt } from "../../utils/api";
import { setOrderStatus } from "../Functions/fetch";
import { Col, Container } from "react-bootstrap";
import moment from "moment-timezone";
import BounceLoader from "react-spinners/BounceLoader";
// import axios from 'axios';
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { getUser } from "../../utils/api";
import Form from "react-bootstrap/Form";
import { CustomerData } from "../../redux/actions/customerActions";
import Modal from "react-bootstrap/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./pages.css";
import { async } from "q";
import { collapseToast } from "react-toastify";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { BiCreditCard } from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
import { FaGoogle, FaCcApplePay, FaCcVisa } from "react-icons/fa";
import { SiGooglepay, SiApple } from "react-icons/si";
import { getPayoutAll } from "../Functions/helper";
import { GiWallet } from "react-icons/gi";
const payIcon = {
  google: <SiGooglepay style={{ fontSize: "38px", color: "#444" }} />,
  wallet: <GiWallet style={{ fontSize: "20px", color: "#444" }} />,
  apple: <SiApple style={{ fontSize: "22px", color: "#444" }} />,
  card: <BiCreditCard style={{ fontSize: "26px", color: "#444" }} />,
  null: <></>,
};
const stripeStatus = {
  paid: {
    style: {
      backgroundColor: "#cce6cc",
      padding: "4px 8px",
      borderRadius: "7px",
      color: "#4da64d",
    },
    text: "Credited",
  },
  in_transit: {
    style: {
      backgroundColor: " #cccccc",
      padding: "4px 8px",
      borderRadius: "7px",
      color: "#1a1a1a",
    },
    text: "Processing",
  },
  canceled: {
    style: {
      backgroundColor: " #fbeeef",
      padding: "4px 8px",
      borderRadius: "7px",
      color: "#d76a6f",
    },
    text: "Canceled",
  },
  debit: {
    style: {
      backgroundColor: " #fbeeef",
      padding: "4px 8px",
      borderRadius: "7px",
      color: "#d76a6f",
    },
    text: "Debited",
  },
};
const capitalizeFirstLetter = (str) => {
  // console.log(str);
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
};

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
}

class Payout extends Component {
  state = {
    orders: [],
    offset: 0,
    page: 1,
    pending: true,
    user: "",
    password: "",
    isAdmin: true,
    isOpen: false,
    isReady: false,
    isClose: false,
    loader: false,
    nodata: false,
    columns: [
      {
        name: "Payout Date",
        cell: (row) => (
          <>
            <p className="order-date">
              {row.date} <br />
            </p>
            <p>
              <span style={{ color: "#666666" }}>{row.id}</span>
              <span
                onClick={() => {
                  navigator.clipboard.writeText(row.id);
                  // setCopy(true);
                }}
              >
                <FaIcons.FaRegCopy />
              </span>
            </p>
            {/* <CopyPayoutId data={row} /> */}
          </>
        ),
      },

      {
        name: "Transaction Dates",
        selector: (row) => <div>{`${row.startDate} - ${row.endDate}`}</div>,
      },
      {
        name: "Total Amount",
        selector: (row) =>
          (row.amount / 100).toLocaleString("en-US", {
            style: "currency",
            currency: row.currency,
          }),
        // <div style={{ textTransform: "uppercase", fontWeight: "600" }}>
        //   {row.amount}
        // </div>
      },
      {
        name: "Payout Status",
        cell: (row) => (
          <>
            <p>
              <span style={stripeStatus[row.status]["style"]}>
                {stripeStatus[row.status]["text"]}
              </span>
              {/* {row.status === "paid" ? (
                row.amount > 0 ? (
                  <span id="paid">
                    Paid <span>{payIcon[row.type]}</span>
                  </span>
                ) : (
                  <span>Withdraw</span>
                )
              ) : (
                <span id="not-paid">Not Paid 👎</span>
              )} */}
            </p>
          </>
        ),
      },
    ],
  };

  async componentDidMount() {
    this.setState({ loader: true });
    if (this.props.stripe_pk) {
      const items = await getPayoutAll(
        this.props.restaurantId,
        this.props.restaurant.integrate
      );
      try {
        if (items.length) {
          items.forEach((item) => {
            var data = {};
            // console.log(item);
            data["date"] = item["Date"];
            data["id"] = item["id"];
            data["description"] = item["Description"];
            data["type"] = item["Type"];
            data["amount"] = item["amount"];
            data["currency"] = item["currency"];
            data["status"] = item["status"];
            data["startDate"] = item["startDate"];
            data["endDate"] = item["endDate"];
            // data["payment"] = `${item["payment"]}`;
            // data["payment_type"] = `${item["payment_type"]}`;
            // data["status"] = item["status"];
            // data["link"] = `/r/${item["orderId"]}`;
            // data["lineItem"] = item["items"];
            // data["tax"] = item["tax"];
            // data["notes"] = item["notes"];
            this.setState({
              orders: [...this.state.orders, data],
            });
            this.setState({ loader: false });
          });
        } else {
          this.setState({
            orders: [],
          });
        }
        this.setState({
          pending: false,
          loader: false,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState({ loader: false, nodata: true });
    }
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                // minHeight: "100vh",
                transition: "0.5s ease-in-out 0s",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ScaleLoader size={12} color="#ec7211" />
            </div>
          </div>
        ) : this.state.nodata ? (
          <div style={{ textAlign: "center" }}>
            <h5 style={{ lineHeight: "1.5em", fontSize: "22px" }}>
              {/* Store Closed <br /> */}
              There are no records to display
            </h5>
          </div>
        ) : (
          <div className="table-group orders-dashboard" style={{ margin: "0" }}>
            <Container
              style={{ minHeight: "auto", marginTop: "6 rem !important" }}
            >
              <h3>
                <i class="fa fa-address-card-o"></i> Payouts
              </h3>
              {/* <DataTableExtensions
              columns={this.state.columns}
              data={this.state.orders}
              export={false}
              filterPlaceholder="Search Orders"
              print={false}
            > */}
              <DataTable
                paginationPerPage={10}
                highlightOnHover={true}
                paginationRowsPerPageOptions={[10, 20, 25, 30]}
                columns={this.state.columns}
                data={this.state.orders}
                // pagination
                progressPending={this.state.pending}
              // progressComponent={<BounceLoader color="#ec7211" />}
              // onChangePage={this.handlePageChange}
              />
              {/* </DataTableExtensions> */}
            </Container>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerId: state.customer.id,
    customer: state.customer,
    restaurantId: state.restaurant.id,
    restaurant: state.restaurant,
    restaurantEle: state.restaurant.items.elements,
    stripe_pk: state.restaurant.restaurant.stripe_pk,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payout);
