import React, { Component, useState, useEffect } from "react";
// import storage from "../firebase/firebase";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "./pages.css";

import { Spinner } from "react-bootstrap";
import { getItemsCategory, addCategoryItems } from "../Functions/fetch";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useLocation } from "react-router-dom";
import _ from 'lodash';

function CategoryItems(props) {
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [isOpenAddProduct, setIsOpenAddProduct] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [modifierPending, setModifierPending] = useState(true);
  const [itemspending, setItemPending] = useState(true);
  const { state } = useLocation();
  const [modifiers, setModifiers] = useState([]);
  const [items, setItems] = useState([]);
  const [choosedItems, setChoosedItems] = useState([]);
  const [choosedItemsId, setChoosedItemsId] = useState([]);
  const [allItems, setAllItems] = useState([]);

  // call your hook here
  useEffect(() => {
    let data = _.sortBy(Object.values(props.items).map(item => _.pick(item, ['productId', 'title'])), 'title')
    setAllItems(data)
    async function helper() {
      try {
        const items = await getItemsCategory(
          props.restaurant.id,
          props.restaurant.integrate,
          state.id
        );
        let c = items.elements.map(item => item.id)
        setChoosedItemsId(c)
        if (modifiers) {
          let data1 = _.sortBy(items.elements, 'name')
          setItems(data1);
          setItemPending(false);
        } else {
          setItemPending(false);
        }

      } catch (error) {
        console.log(error);
      }
    }
    helper();
  }, []);


  const itemsColumn = [
    {
      name: "Name",
      cell: (row) => <div >{row.name}</div>,
    },
    {
      name: "Price",
      cell: (row) => <div> $ {parseFloat(row.price / 100).toFixed(2)}</div>,
    },
  ];
  const closeModal = () => {
    setisOpenAdd(false);
    // console.log(descData.length || image)
  };

  const handleChooseItem = async (e) => {
    if (choosedItemsId.includes(e.target.id)) {
      let c = choosedItemsId.filter(id => id !== e.target.id)
      setChoosedItemsId(c)

    } else {
      setChoosedItemsId(choosedItemsId => [...choosedItemsId, e.target.id])
    }
  }

  const addItem = async () => {
    setDisabled(false);
    setSpinner(true);
    const response = await addCategoryItems(
      props.restaurant.id,
      props.restaurant.integrate,
      state.id,
      choosedItemsId
    );
    if (response) {
      window.location.reload(false);
    }
  }
  return (
    <>
      <div>
        <p style={{
          fontSize: '20px',
          fontWeight: 'bolder'
        }}>Category - {state.name}</p>
      </div>
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        onClick={() => setIsOpenAddProduct(true)}
      >
        Add Product
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        title="Items"
        columns={itemsColumn}
        data={items}
        pagination
        progressPending={itemspending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
      // onChangePage={this.handlePageChange}
      />

      {isOpenAddProduct ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={isOpenAddProduct}
          onHide={() => setIsOpenAddProduct(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="modifiers-itmes">
              <div id="bot" style={{ height: '400px', overflowY: 'scroll' }}>
                <div >
                  {
                    allItems.map(item => {
                      return (
                        <div key={item.productId}>
                          <label >
                            <input
                              style={{ marginRight: '10px' }}
                              value={item.title}
                              id={item.productId}
                              defaultChecked={choosedItemsId.includes(item.productId) ? true : false}
                              onChange={handleChooseItem}
                              type="checkbox"
                            />
                            {item.title}
                          </label>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn mt-3 mb-3 btn-sm"
              onClick={addItem}
              style={{ display: "inline-block" }}
              disabled={!disabled}
            >
              Add
              {spinner ? (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(CategoryItems);
