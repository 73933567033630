import { useContext } from "react";
import { LanguageContext } from "./multilingualContext.js";
import { translations } from "./translations.js";

/**
 * Utility function to generate text dependant on the language
 * @param {React Component props} props destructured just for the contentID
 * @returns
 */
export default function MultiLingualContent({ contentID }) {
  const { language } = useContext(LanguageContext);
  // console.log(contentID, [contentID], language);
  // console.log(translations["en"][contentID])
  return `${translations[language] ? translations[language][contentID] : translations["en"][contentID]}`;
}
