import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import fileDownload from "js-file-download";
import { useParams, useNavigate } from "react-router-dom";

const TableQRDownload = (props) => {
  const { restaurantsId: restaurantId, tableId, tableName } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    (async function () {
      const rawAjaxCall = await fetch(
        `/qrcode/generate/${restaurantId}/table/${tableId}`
      );
      const json = await rawAjaxCall.json();
      if (json && json.data) {
        const blobImage = json.data;
        const data = Uint8Array.from(json.bufferData.data);
        const content = new Blob([data.buffer], { type: "application/pdf" });
        fileDownload(content, `${tableName}.pdf`);
        navigate(-1);
      }
    })();
  }, []);

  return <></>;
};

export default TableQRDownload;
