import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import { Button, Form, Modal } from "react-bootstrap";

class IntagrationClover extends Component {
  state = {
    restaurant_name: "",
    integrate: "clover",
    merchant_id: "",
    api_key: "",
    rst_number: "",
    modalShow: false,
    msg: "",
    stripe_pk: "",
    stripe_sk: "",
    rst_name: "",
    timezone: "",
    logo: "",
    rst_name: "",
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { client_id, merchant_id, code, restaurant_name } = this.props;
    if (restaurant_name) {
      axios
        .post("/api/clover-data", this.props)
        .then((res) => {
          window.location.href = "/merchant/integration";
          this.setState(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      window.location.href = "/merchant/integration";
    }
  }
  render() {
    return (
      <React.Fragment>
        <Container
          className="my-auto Checkout"
          style={{ opacity: this.state.loader ? "0.4" : "1" }}
        >
        </Container>
      </React.Fragment>
    );
  }
}

function Integation(props) {
  const [searchParams] = useSearchParams();
  const merchant_id = searchParams.get("merchant_id");
  const client_id = searchParams.get("client_id");
  const code = searchParams.get("code");
  const restaurant_name = searchParams.get("state");

  return (
    <>
      <IntagrationClover
        restaurant_name={restaurant_name}
        merchant_id={merchant_id}
        code={code}
        client_id={client_id}
      />
    </>
  );
}

export default Integation;
