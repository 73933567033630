import React, { Component, useState, useEffect } from "react";
// import storage from "../firebase/firebase";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "./pages.css";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { addModifiers, getProductItem, getModifiers, getModifiersItems, addModifiersItems, deleteModifier, updateModifiers } from "../Functions/fetch";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useLocation, useNavigate } from "react-router-dom";
import _ from 'lodash';
import { MdCancel, MdModeEdit } from 'react-icons/md'
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
function Modifiers(props) {
  const navigate = useNavigate();
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [subModel, setSubModel] = useState(false);
  const [isOpenAddProduct, setIsOpenAddProduct] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [modifierPending, setModifierPending] = useState(true);
  const [itemspending, setItemPending] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useLocation();
  const [modifiers, setModifiers] = useState([]);
  const [items, setItems] = useState([]);
  const [choosedItems, setChoosedItems] = useState([]);
  const [choosedItemsId, setChoosedItemsId] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [modifierId, setModifierId] = useState("");
  const [isOpenDel, setisOpenDel] = useState(false);
  const [subModData, setSubModData] = useState({ name: '', price: 0, model: false, type: '', data: '', loader: false })
  // call your hook here
  useEffect(() => {
    // console.log(state)
    let data = _.sortBy(Object.values(props.items).map(item => _.pick(item, ['productId', 'title'])), 'title')
    setAllItems(data)
    async function helper() {
      try {
        const modifiers = await getModifiers(
          props.restaurant.id,
          props.restaurant.integrate,
          state.id
        );
        if (modifiers) {
          let data = _.sortBy(modifiers.elements, 'name')
          setModifiers(data);
          setModifierPending(false);
        } else {
          setModifierPending(false);
        }
        const items = await getModifiersItems(
          props.restaurant.id,
          props.restaurant.integrate,
          state.id
        );
        let c = items.elements.map(item => item.id)
        setChoosedItemsId(c)
        if (modifiers) {
          let data1 = _.sortBy(items.elements, 'name')
          setItems(data1);
          setItemPending(false);
        } else {
          setItemPending(false);
        }

      } catch (error) {
        console.log(error);
      }
    }
    helper();
  }, []);
  function handleDelSubMod(data) {
    setSubModData(prevState => ({ ...prevState, loader: true }))
    console.log(data, state.id)
    const { id, sub_modifier_group_id, sub_modifer_id, modifer_id } = data;
    let payload = {
      "modifer_group_id": state.id,
      "modifer_id": id,
      "sub_modifer_id": sub_modifer_id,
      "sub_modifier_group_id": sub_modifier_group_id
    }
    // console.log(payload)
    let body = {
      "restaurant": { "restaurant_id": props.restaurant.id },
      "payload": payload
    }
    // console.log(body)
    const rawResponse = fetch(`/api/v1/merchant/delete/modifier/submodifier/custom`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(rawResponse => {
      if (rawResponse.ok) {
        toast.success("SubModifer removed");
        navigate("/merchant/modifiers");
      } else {
        toast.error("Something went wrong not able to remove SubModifer");
      }
    }).catch(error => { console.log(error); toast.error("Something went wrong not able to remove SubModifer"); })
  }
  function handleAddSubMod(data) {
    setSubModData(prevState => ({ ...prevState, loader: true }))
    // console.log(data, state.id)
    const { id } = data;
    let payload = { "modifer_group_id": state.id, "modifer_id": id, "name": subModData.name, "price": subModData.price };
    let body = {
      "restaurant": { "restaurant_id": props.restaurant.id },
      "payload": payload
    }
    // console.log(body)
    const rawResponse = fetch(`/api/v1/merchant/add/modifier/submodifier/custom`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(rawResponse => {
      if (rawResponse.ok) {
        toast.success("SubModifer added");
        navigate("/merchant/modifiers");
      } else {
        toast.error("Something went wrong not able to add SubModifer");
      }
    }).catch(error => { console.log(error); toast.error("Something went wrong not able to add SubModifer"); })
  }
  function handleEditSubMod(data) {
    setSubModData(prevState => ({ ...prevState, loader: true }))
    const { id, sub_modifier_group_id, sub_modifer_id } = data;
    let payload = { "sub_modifer_id": sub_modifer_id, "sub_modifier_group_id": sub_modifier_group_id, "name": subModData.name, "price": subModData.price }
    let body = {
      "restaurant": { "restaurant_id": props.restaurant.id },
      "payload": payload
    }
    // console.log(body)
    const rawResponse = fetch(`/api/v1/merchant/update/modifier/submodifier/custom`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(rawResponse => {
      if (rawResponse.ok) {
        toast.success("SubModifer updated");
        navigate("/merchant/modifiers");
      } else {
        toast.error("Something went wrong not able to update SubModifer");
      }
    }).catch(error => { console.log(error); toast.error("Something went wrong not able to update SubModifer"); })
  }
  const modifiersColumn = [
    {
      name: "Name",
      cell: (row) => <div>
        {/* {console.log(row)} */}
        <div className="my-2">{row.name}</div>
        {['custom'].includes(props.restaurant.integrate) ? <div style={{ display: 'flex', gap: '6px', alignItems: 'center', flexWrap: 'wrap' }}>
          {row.sub_modifiers?.length ? row.sub_modifiers.map((ite, key) => {
            return <p key={key} style={{
              backgroundColor: '#808080',
              border: 'none',
              color: '#fff',
              padding: '4px 8px',
              textAlign: 'center',
              textDecoration: 'none',
              display: 'inline-block',
              fontSize: '14px',
              borderRadius: '6px',
              cursor: 'pointer'
            }}
              onClick={() => {
                setSubModData(prev => ({ ...prev, type: 'edit', model: true, data: { ...ite, sub_modifer_id: ite.id, id: row.id }, name: ite.name }))
                // handleEditSubMod(ite)
              }}
            >{ite.name}</p>
          }) : <></>}
          <p style={{
            fontSize: '13px',
            textDecoration: 'underline',
            color: 'blue',
            cursor: 'pointer',
          }}
            onClick={() => {
              setSubModData(prev => ({ ...prev, type: 'add', model: true, data: row }))
              // handleAddSubMod(row)
            }
            }
          >Add submodifier</p>
        </div> : <></>}

      </div>,
    },
    {
      name: "Price",
      cell: (row) => <div> $ {parseFloat(row.price / 100).toFixed(2)}</div>,
    },
    {
      name: "Actions",
      cell: (row) => <div><MdCancel color="red" size={"2em"} onClick={async () => {
        handleModifierDelete(row.id)
        // await deleteModifier(state.restaurant_id, state.integrate, row.id, state.id);
        // window.location.reload();
      }} /> <span style={{ paddingLeft: '10px', marginBotton: 10 }}><MdModeEdit color="green" size={"1.2em"} onClick={() => handleModifierEdit(row.id, row.name, parseFloat(row.price / 100).toFixed(2))} /></span></div>,
    },
  ];
  const itemsColumn = [
    {
      name: "Name",
      cell: (row) => <div >{row.name}</div>,
    },
    {
      name: "Price",
      cell: (row) => <div> $ {parseFloat(row.price / 100).toFixed(2)}</div>,
    },
  ];
  const closeModal = () => {
    setisOpenAdd(false);
    setName("");
    setPrice(0);
    setisOpenDel(false);
    setIsEdit(false);
    setSubModData({ name: '', price: 0, model: false, type: '', loader: false })
    // console.log(descData.length || image)
  };

  const handleModifierEdit = (id, name, price) => {
    setName(name);
    setModifierId(id);
    setPrice(price);
    setIsEdit(true);
    setisOpenAdd(true);
  }

  const handleChooseItem = async (e) => {
    if (choosedItemsId.includes(e.target.id)) {
      let c = choosedItemsId.filter(id => id !== e.target.id)
      setChoosedItemsId(c)

    } else {
      setChoosedItemsId(choosedItemsId => [...choosedItemsId, e.target.id])
    }
  }
  const addModifier = async () => {
    setDisabled(false);
    setSpinner(true);
    const response = await addModifiers(
      props.restaurant.id,
      props.restaurant.integrate,
      state.id,
      name,
      price
    );
    if (response) {
      window.location.reload(false);
      // updateItemState();
      setDisabled(true);
      setSpinner(false);
    }
  };
  const handleDeleteItem = async () => {
    setSpinner(true);
    setDisabled(false);

    const res = await deleteModifier(
      props.restaurant.id,
      props.restaurant.integrate,
      modifierId,
      state.id
    );
    if (res) {
      window.location.reload();
    }
  };
  const handleModifierDelete = async (id, name) => {
    setModifierId(id);
    setisOpenDel(true);
  }
  const updateModifier = async () => {
    setDisabled(false);
    setSpinner(true);
    const response = await updateModifiers(
      props.restaurant.id,
      props.restaurant.integrate,
      state.id,
      modifierId,
      name,
      price
    );
    if (response) {
      window.location.reload(false);
      // updateItemState();
      setDisabled(true);
      setSpinner(false);
    }
  };
  const addItem = async () => {
    setDisabled(false);
    setSpinner(true);
    const response = await addModifiersItems(
      props.restaurant.id,
      props.restaurant.integrate,
      state.id,
      choosedItemsId
    );
    if (response) {
      window.location.reload(false);
    }
  }
  return (
    <>
      <div>
        <p style={{
          fontSize: '20px',
          fontWeight: 'bolder'
        }}>Modifier Group - {state?.name}</p>
      </div>
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        onClick={() => setisOpenAdd(true)}
      >
        Add Modifiers
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        columns={modifiersColumn}
        data={modifiers}
        title="Modifiers"
        pagination
        progressPending={modifierPending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
      // onChangePage={this.handlePageChange}
      />
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        onClick={() => setIsOpenAddProduct(true)}
      >
        Add Product
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        title="Items"
        columns={itemsColumn}
        data={items}
        pagination
        progressPending={itemspending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
      // onChangePage={this.handlePageChange}
      />
      {isOpenAdd ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={isOpenAdd}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "33%" }}>Modifier Name</td>
                        <td>
                          {" "}
                          <Form.Control
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            value={name}
                            type="text"
                            size="sm"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "33%" }}>Price $</td>
                        <td>
                          {" "}
                          <Form.Control
                            value={price}
                            onChange={(e) => {
                              setPrice(e.target.value);
                            }}
                            type="text"
                            size="sm"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <center>
                    {
                      isEdit ? <Button
                        className="btn mt-3 mb-3 btn-sm"
                        onClick={updateModifier}
                        style={{ display: "inline-block" }}
                        disabled={name ? false : true}
                      >
                        Update
                        {spinner ? (
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        ) : (
                          <></>
                        )}
                      </Button> : <Button
                        className="btn mt-3 mb-3 btn-sm"
                        onClick={addModifier}
                        style={{ display: "inline-block" }}
                        disabled={name ? false : true}
                      >
                        Add
                        {spinner ? (
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        ) : (
                          <></>
                        )}
                      </Button>
                    }

                  </center>
                </div>
              </div>
            </div>
          </Modal.Body>

        </Modal>
      ) : (
        <></>
      )}

      {isOpenAddProduct ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={isOpenAddProduct}
          onHide={() => setIsOpenAddProduct(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="modifiers-itmes">
              <div id="bot" style={{ height: '400px', overflowY: 'scroll' }}>
                <div >
                  {
                    allItems.map(item => {
                      return (
                        <div key={item.productId}>
                          <label >
                            <input
                              style={{ marginRight: '10px' }}
                              value={item.title}
                              id={item.productId}
                              defaultChecked={choosedItemsId.includes(item.productId) ? true : false}
                              onChange={handleChooseItem}
                              type="checkbox"
                            />
                            {item.title}
                          </label>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn mt-3 mb-3 btn-sm"
              onClick={addItem}
              style={{ display: "inline-block" }}
              disabled={!disabled}
            >
              Add
              {spinner ? (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
      {isOpenDel ? (
        <Modal
          dialogClassName="del-model del-item"
          show={isOpenDel}
          onHide={closeModal}
        >
          <Modal.Body>
            <h5>You are about to delete a modifier</h5>
            <p>
              This will delete your modifier from inventory <br />
              Are you sure?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ background: "transparent", color: "#000", border: "0" }}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button variant="secondary" onClick={handleDeleteItem}>
              Delete
              {spinner ? (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
      {subModData.model ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={subModData.model}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="table">
              {subModData.type === 'add' ? <>
                <table className="mt-5" style={{ margin: '0 auto' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "33%" }}>Name</td>
                      <td>
                        <Form.Control
                          onChange={(e) => {
                            setSubModData(prev => ({ ...prev, name: e.target.value }))
                          }}
                          value={subModData.name}
                          type="text"
                          size="sm"
                        />
                      </td>
                    </tr>
                    {/* <tr>
                        <td style={{ width: "33%" }}>Price $</td>
                        <td>
                          {" "}
                          <Form.Control
                            value={price}
                            onChange={(e) => {
                              setPrice(e.target.value);
                            }}
                            type="text"
                            size="sm"
                          />
                        </td>
                      </tr> */}
                  </tbody>
                </table>
                <center>
                  <Button
                    className="btn mt-3 mb-3 btn-sm"
                    onClick={() => handleAddSubMod(subModData.data)}
                    style={{ display: "inline-block" }}
                    disabled={subModData.name.length > 3 ? false : true}
                  >
                    Add
                    {subModData.spinner ? (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    ) : (
                      <></>
                    )}
                  </Button>
                </center>
              </> : <>
                <table className="mt-5" style={{ margin: '0 auto' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "33%" }}>Name</td>
                      <td>
                        <Form.Control
                          onChange={(e) => {
                            setSubModData(prev => ({ ...prev, name: e.target.value }))
                          }}
                          value={subModData.name}
                          type="text"
                          size="sm"
                        />
                      </td>
                    </tr>
                    {/* <tr>
                        <td style={{ width: "33%" }}>Price $</td>
                        <td>
                          {" "}
                          <Form.Control
                            value={price}
                            onChange={(e) => {
                              setPrice(e.target.value);
                            }}
                            type="text"
                            size="sm"
                          />
                        </td>
                      </tr> */}
                  </tbody>
                </table>
                <center>
                  <Button
                    className="btn mt-3 mb-3 btn-sm"
                    onClick={() => handleEditSubMod(subModData.data)}
                    style={{ display: "inline-block" }}
                    disabled={subModData.name.length > 3 ? false : true}
                  >
                    Update
                    {subModData.spinner ? (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    ) : (
                      <></>
                    )}
                  </Button>

                </center>
              </>}

            </div>
            {subModData.type === 'edit' ? <Modal.Footer>
              <Button onClick={() => handleDelSubMod(subModData.data)} style={{ backgroundColor: '#b33a3a' }}>
                <MdIcons.MdDelete />
              </Button>
            </Modal.Footer> : <></>}
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(Modifiers);
