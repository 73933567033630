import React from 'react';
import {
    Routes,
    Route,
} from "react-router-dom";
import QrcodeGen from "../pages/QrcodeGen";
import CustomPayments from "../pages/custom/CustomPayments";
import Receipt from "../pages/Receipt";
import {
    TableReceipt,
    Login,
    CloverIntegration,
    Payments,
} from "../utils";
import PaymentAiOrder from '../pages/AiOrder/PaymentAiOrder';

const WebsiteRouters = (props) => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/test"
                    element={<Login />}
                />
                <Route
                    exact
                    path="/payment/:restaurantsId/:orderId"
                    element={<CustomPayments />}
                />
                <Route
                    exact
                    path="/pay/:restaurantsId/:orderId"
                    element={<Payments />}
                />
                <Route
                    exact
                    path="/r/:restaurantsId/:orderId"
                    element={<Receipt />}
                />
                <Route
                    exact
                    path="/r/:restaurantsId/table/:orderId"
                    element={<TableReceipt />}
                />
                <Route
                    exact
                    path="/contact/:restaurantsId/:orderId"
                    element={<QrcodeGen />}
                />
                <Route
                    exact
                    path="/qr/:restaurantsId/:orderId"
                    element={<QrcodeGen />}
                />
                <Route
                    exact
                    path="/clover-integration"
                    element={<CloverIntegration />}
                />
                <Route
                    exact
                    path="/pay/order/:order_id"
                    element={<PaymentAiOrder />}
                />
            </Routes>
        </>
    )

}

export default WebsiteRouters