import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { CustomerData } from "../../redux/actions/customerActions";
import { RestaurantData } from "../../redux/actions/restauratActions";
import { INIT, LOC } from "../../redux/actions/cartActions";
import DefaultLogo from "../../assets/default.jpg";
import ProductDash from "./Inventory";
import Button from "react-bootstrap/Button";
import {
  // getTax,
  getCustomer,
  // getLatestOrder,
  getRestaurantDetails,
  // getItems,
  getProdMetaData,
} from "../../utils/api";
const getCookies = () => {
  return document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value),
      }),
      {}
    );
};
// const capitalizeFirstLetter = (str) => {
//   console.log(str);
//   str.replace(
//     /(^\w|\s\w)(\S*)/g,
//     (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
//   );
// };

class AdminHome extends Component {
  state = {
    orders: [],
    offset: 0,
    page: 1,
    pending: true,
    user: "",
    password: "",
    isAdmin: true,
    isOpen: false,
    isReady: false,
    isClose: false,
  };
  async componentDidMount() {
    window.location.href = "/merchant/dashboard";
    // console.log(this.props);
    // const restaurant_id = this.props.restaurant_id;
    // if (restaurant_id) {
    //   if (
    //     window.location.pathname.replace(/^\/*|\/*$/g, "").split("/").length ===
    //     1
    //   ) {
    //     document
    //       .getElementsByTagName("body")[0]
    //       .classList.add("restaurant-body");
    //   }
    //   if (
    //     window.location.pathname.replace(/^\/|\/$/g, "").split("/").length === 2
    //   ) {
    //     document
    //       .getElementsByTagName("body")[0]
    //       .classList.add("restaurant-body-table");
    //   }
    //   const data = await getRestaurantDetails(restaurant_id);
    //   const prodData = await getProdMetaData(restaurant_id);
    //   data["tablePath"] = window.location.pathname
    //     .replace("/cart", "")
    //     .replace("/pay-on-cash", "")
    //     .replace("/pay-online", "")
    //     .replace(/\/+$/, "");
    //   data["id"] = restaurant_id;
    //   this.props.RestaurantData(data);
    //   let products = {};
    //   // if (data.integrate === 'clover') {
    //   await Promise.all(
    //     data["items"]["elements"].map(async (product) => {
    //       products[product.name] = {
    //         num: 0,
    //         img: prodData?.[product.id]
    //           ? prodData[product.id]["img"]
    //             ? prodData[product.id]["img"]
    //             : DefaultLogo
    //           : DefaultLogo,
    //         desc: prodData?.[product.id]
    //           ? prodData[product.id]["desc"]
    //             ? prodData[product.id]["desc"]
    //             : ""
    //           : "",
    //         title: product.name,
    //         category: product.categories.elements.length
    //           ? product.categories.elements[0]["name"]
    //           : "Others",
    //         modifierGroups: product.modifierGroups.elements.length
    //           ? product.modifierGroups.elements.map((group) => ({
    //               [group.id]: group.name,
    //             }))
    //           : [],
    //         productId: product.id,
    //         price: product.price / 100,
    //         stock: product.available,
    //         hidden: product.hidden,
    //       };
    //       return 1;
    //     })
    //   );
    //   // console.log(products);
    //   this.props.INIT(products);
    //   this.setState({ loading: !this.state.loading, type: "app" });
    // }
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(this.state.user);
    // let res = await getUser(
    //   this.props.restaurantId,
    //   this.state.user,
    //   this.state.password
    // );
    // if (res) {
    //   this.setState({ isAdmin: true });
    //   this.props.CustomerData({ isAdmin: true });
    //   var today = new Date();
    //   var expire = new Date();
    //   expire.setTime(today.getTime() + 3600000 * 24 * 14);
    //   document.cookie =
    //     "hasaccess" +
    //     "=" +
    //     encodeURI(
    //       JSON.stringify({ resId: this.props.restaurantId, admin: true })
    //     ) +
    //     ";expires=" +
    //     expire.toGMTString();
    //   //   window.location.reload(false);
    // }
  };
  render() {
    return <React.Fragment></React.Fragment>;
  }
}
const mapStateToProps = (state) => {
  return {
    restaurant_id: state.customer.resId,
    customer: state.customer,
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    RestaurantData: (data) => {
      dispatch(RestaurantData(data));
    },
    INIT: (data) => {
      dispatch(INIT(data));
    },
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
    LOC: (data) => {
      dispatch(LOC(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
