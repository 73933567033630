import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllOrdersItem, getUser } from "../../utils/api";
import { Col, Container } from "react-bootstrap";
import moment from "moment-timezone";
import DataTable from "react-data-table-component";
import { CustomerData } from "../../redux/actions/customerActions";
import Modal from "react-bootstrap/Modal";
import "../../dashboard/pages/pages.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import { BiCreditCard } from "react-icons/bi";
import { SiGooglepay, SiApple } from "react-icons/si";
import { getOrderFilter } from "../../dashboard/Functions/helper";
import { GiWallet } from "react-icons/gi";
const payIcon = {
  google: <SiGooglepay style={{ fontSize: "38px", color: "#444" }} />,
  wallet: <GiWallet style={{ fontSize: "20px", color: "#444" }} />,
  apple: <SiApple style={{ fontSize: "22px", color: "#444" }} />,
  card: <BiCreditCard style={{ fontSize: "26px", color: "#444" }} />,
  null: <></>,
};
const getCookies = () => {
  return document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value),
      }),
      {}
    );
};
const capitalizeFirstLetter = (str) => {
  // console.log(str);
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
};
function getIsoString(value, timeZone) {
  let date = new Date();
  const timestamp = date.setDate(date.getDate() - value);
  const timeIso = new Date(timestamp).toISOString();
  const time = moment(timeIso).tz(timeZone).format("YYYY-MM-DD");
  // console.log(time);
  return time;
}

// const UpdateButton = (props) => {
//   const [ready, setready] = useState(false);
//   const [close, setclose] = useState(false);

//   const foodReady = async (data) => {
//     let action = "ready";
//     setready(true);
//     const res = setFoodStatus(data, action);
//   };
//   const foodClose = async (data) => {
//     let action = "close";
//     setclose(true);
//     const res = setFoodStatus(data, action);
//   };
//   return (
//     <div style={{ display: "flex" }}>
//       <Button
//         size="sm"
//         variant="success"
//         // style={{ margin: '0px 15px 0 0' }}
//         onClick={() => foodReady(props.data)}
//         disabled={props.data.status === "ready" || ready}
//       >
//         Ready
//       </Button>
//       {""}
//       <Button
//         size="sm"
//         variant="danger"
//         onClick={() => foodClose(props.data)}
//         disabled={props.data.status === "close" || close}
//       >
//         Close
//       </Button>
//     </div>
//   );
// };
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
}
class OrderPage extends Component {
  state = {
    orders: [],
    offset: 0,
    page: 1,
    pending: true,
    user: "",
    password: "",
    isAdmin: true,
    isOpen: false,
    isReady: false,
    isClose: false,
    loader: false,
    nodata: false,
    valid: true,
    warnvalid: false,
    startDate: "",
    endDate: "",
    columns: [
      {
        name: "Date",
        cell: (row) => (
          <p className="order-date">
            {row.date}
            <br />
            {row.orderId} <br />
            {row.CustomerName && row.CustomerPhone ? (
              <>
                <span className="order-phone">{row.CustomerName}</span> <br />
                <span className="order-phone">
                  Phone :{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#000",
                      background: "none",
                    }}
                    href={`tel:${row.CustomerPhone}`}
                  >
                    {row.CustomerPhone}
                  </a>
                </span>
              </>
            ) : row.CustomerName ? (
              <>
                <span className="order-phone">{row.CustomerName}</span>{" "}
              </>
            ) : row.CustomerPhone ? (
              <>
                <span className="order-phone">
                  Phone :{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#000",
                      background: "none",
                    }}
                    href={`tel:${row.CustomerPhone}`}
                  >
                    {row.CustomerPhone}
                  </a>
                </span>
              </>
            ) : (
              <></>
            )}
          </p>
        ),
      },
      {
        name: "Line Items",
        cell: (row) => (
          <ul>
            {row.lineItem.map((item) => {
              // console.log(item);
              return (
                <li key={item.id}>
                  {item.name} <br />{" "}
                  {/* {capitalizeFirstLetter(item.name)} <br />{" "} */}
                  <span>
                    $ {item.price / 100} * {item.quantity}
                  </span>
                </li>
              );
            })}
          </ul>
        ),
      },
      {
        name: "Total",
        selector: (row) => (
          <div>
            Total: $ {parseFloat(row.total / 100).toFixed(2)}
            {/* <br />
            <span style={{ padding: "5px 0 0", display: "inline-block" }}>
              Subtotal: $ {parseFloat(row.subTotal / 100).toFixed(2)}
            </span> */}
          </div>
        ),
      },
      {
        name: "Status",
        cell: (row) => (
          <>
            <p>
              {row.payment === "Not Paid" ? (
                <span id="not-paid">Not Paid 👎</span>
              ) : (
                <span id="paid">
                  Paid <span>{payIcon[row.payment_type]}</span>
                </span>
              )}
            </p>
            <br />
            <div style={{ display: "block", width: "100%" }}>
              {/* <UpdateButton data={row} /> */}
              {/* <Button
 onClick={() => this.foodReady(row)}
 disabled={row.status === "ready"}
 >
 Ready
 </Button>
 <Button
 onClick={() => this.foodClose(row)}
 disabled={row.status === "close"}
 >
 Close
 </Button> */}
            </div>
          </>
        ),
      },
      // {
      // name: "Payment",
      // selector: (row) => row.payment,
      // },
      {
        name: "Details",
        cell: (row) => (
          <a onClick={() => this.openModal(row)} id={row.id}>
            Details
          </a>
        ),
      },
    ],
  };
  todayDate = moment()
    .tz(this.props.timeZone)
    .format("YYYY-MM-DD")
    .toLocaleLowerCase();
  last7Date = moment()
    .tz(this.props.timeZone)
    .subtract(7, "days")
    .format("YYYY-MM-DD");
  yesterday = moment()
    .tz(this.props.timeZone)
    .subtract(1, "days")
    .format("YYYY-MM-DD");
  openModal = async (row) => {
    // console.log(e.target.id);
    // console.log(row);
    // const data = await getReceipt(e.target.id);
    this.setState({
      data: row,
      isOpen: true,
    });
  };
  closeModal = () => this.setState({ isOpen: false });

  async componentDidMount() {
    this.setState({ loader: true });
    const resId = this.props.restaurant.id,
      intergate = this.props.restaurant.integrate;
    const items = await getOrderFilter(resId, intergate, null, null);
    // const items = await getAllOrdersItem(
    //   this.props.restaurantId,
    //   40,
    //   this.state.offset,
    //   this.props.restaurantEle
    // );
    try {
      if (items.length) {
        items.forEach((item) => {
          var data = {};
          data["date"] = item["createdAt"];
          data["id"] = item["orderId"];
          data["orderId"] = `ID : ${item["orderId"]}`;
          data["subTotal"] = item["subTotal"];
          data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
          data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
            ? `${formatPhoneNumber(item["Phone"])}`
            : "";
          data["total"] = `${item["total"]}`;
          data["tip"] = item["tip"];
          data["payment"] = `${item["payment"]}`;
          data["payment_type"] = `${item["payment_type"]}`;
          data["status"] = item["status"];
          data["link"] = `/r/${item["orderId"]}`;
          data["lineItem"] = item["items"];
          data["tax"] = item["tax"];
          data["notes"] = item["notes"];
          this.setState({
            orders: [...this.state.orders, data],
            loader: false,
          });
          // setTimeout(() => this.setState({ loader: false }), 3000);
        });
        // console.log(items);
      } else {
        this.setState({
          orders: [],
          loader: false,
          nodata: true,
        });
        // setTimeout(() => this.setState({ loader: false }), 3000);
      }
      this.setState({
        pending: false,
      });
    } catch (error) {
      console.log(error);
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(this.state.user);
    let res = await getUser(
      this.props.restaurantId,
      this.state.user,
      this.state.password
    );
    if (res) {
      this.setState({ isAdmin: true });
      this.props.CustomerData({ isAdmin: true });
      var today = new Date();
      var expire = new Date();
      expire.setTime(today.getTime() + 3600000 * 24 * 14);
      document.cookie =
        "hasaccess" +
        "=" +
        encodeURI(
          JSON.stringify({ resId: this.props.restaurantId, admin: true })
        ) +
        ";expires=" +
        expire.toGMTString();
      // window.location.reload(false);
    }
  };
  applydayfilter = async (startDate, endDate) => {
    // console.log(startDate, endDate);
    const items = await getAllOrdersItem(
      this.props.restaurantId,
      40,
      this.state.offset,
      this.props.restaurantEle
    );
    // const items = this.state.items;
    if (items.length) {
      let temp = [];
      items.forEach((item) => {
        var data = {};
        const current = item["createdAt"];
        // console.log(current);
        const currentDate = moment(current)
          // .tz(this.props.timeZone)
          .format("YYYY-MM-DD");
        // const start = moment(startDate)
        //   // .tz(this.props.timeZone)
        //   .format("YYYY-MM-DD");
        // const end = moment(endDate)
        //   // .tz(this.props.timeZone)
        //   .format("YYYY-MM-DD");
        const start = startDate;
        const end = endDate;
        console.log(currentDate, start, end);
        if (currentDate <= end && currentDate >= start) {
          // console.log(start, currentDate, end);
          // console.log(item);
          data["date"] = item["createdAt"];
          data["id"] = item["orderId"];
          data["orderId"] = `ID : ${item["orderId"]}`;
          data["subTotal"] = item["subTotal"];
          data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
          data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
            ? `${formatPhoneNumber(item["Phone"])}`
            : "";
          data["total"] = `${item["total"]}`;
          data["tip"] = item["tip"];
          data["payment"] = `${item["payment"]}`;
          data["payment_type"] = `${item["payment_type"]}`;
          data["status"] = item["status"];
          data["link"] = `/r/${item["orderId"]}`;
          data["lineItem"] = item["items"];
          data["tax"] = item["tax"];
          data["notes"] = item["notes"];
          // this.setState({
          // orders: [...this.state.orders, data],
          // });
          temp = [...temp, data];
        }
      });
      this.setState({
        orders: temp,
      });
    } else {
      this.setState({
        orders: [],
      });
    }
  };
  applyfilter = async (startDate, endDate) => {
    const resId = this.props.restaurant.id,
      intergate = this.props.restaurant.integrate;
    // console.log(startDate, endDate);
    // const items = await getAllOrdersItem(
    //   this.props.restaurantId,
    //   40,
    //   this.state.offset,
    //   this.props.restaurantEle
    // );
    const items = await getOrderFilter(resId, intergate, startDate, endDate);
    // const items = this.state.items;
    if (items.length) {
      let temp = [];
      items.forEach((item) => {
        var data = {};
        data["date"] = item["createdAt"];
        data["id"] = item["orderId"];
        data["orderId"] = `ID : ${item["orderId"]}`;
        data["subTotal"] = item["subTotal"];
        data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
        data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
          ? `${formatPhoneNumber(item["Phone"])}`
          : "";
        data["total"] = `${item["total"]}`;
        data["tip"] = item["tip"];
        data["payment"] = `${item["payment"]}`;
        data["payment_type"] = `${item["payment_type"]}`;
        data["status"] = item["status"];
        data["link"] = `/r/${item["orderId"]}`;
        data["lineItem"] = item["items"];
        data["tax"] = item["tax"];
        data["notes"] = item["notes"];
        temp = [...temp, data];
      });
      this.setState({
        orders: temp,
      });
    } else {
      this.setState({
        orders: [],
      });
    }
  };
  // applyfilter = async (startDate, endDate) => {
  //   // console.log(startDate, endDate);
  //   const items = await getAllOrdersItem(
  //     this.props.restaurantId,
  //     40,
  //     this.state.offset,
  //     this.props.restaurantEle
  //   );
  //   // const items = this.state.items;
  //   if (items.length) {
  //     let temp = [];
  //     items.forEach((item) => {
  //       var data = {};
  //       const current = item["createdAt"];
  //       // console.log(current);
  //       const currentDate = moment(current)
  //         // .tz(this.props.timeZone)
  //         .format("YYYY-MM-DD");
  //       // console.log(currentDate, startDate, endDate);
  //       const start = moment(startDate)
  //         // .tz(this.props.timeZone)
  //         .format("YYYY-MM-DD");
  //       const end = moment(endDate)
  //         // .tz(this.props.timeZone)
  //         .format("YYYY-MM-DD");
  //       // console.log(currentDate, start, end);
  //       if (currentDate <= end && currentDate >= start) {
  //         // console.log(start, currentDate, end);
  //         // console.log(item["createdAt"]);
  //         data["date"] = item["createdAt"];
  //         data["id"] = item["orderId"];
  //         data["orderId"] = `ID : ${item["orderId"]}`;
  //         data["subTotal"] = item["subTotal"];
  //         data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
  //         data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
  //           ? `${formatPhoneNumber(item["Phone"])}`
  //           : "";
  //         data["total"] = `${item["total"]}`;
  //         data["tip"] = item["tip"];
  //         data["payment"] = `${item["payment"]}`;
  //         data["payment_type"] = `${item["payment_type"]}`;
  //         data["status"] = item["status"];
  //         data["link"] = `/r/${item["orderId"]}`;
  //         data["lineItem"] = item["items"];
  //         data["tax"] = item["tax"];
  //         data["notes"] = item["notes"];
  //         // this.setState({
  //         // orders: [...this.state.orders, data],
  //         // });
  //         temp = [...temp, data];
  //       }
  //     });
  //     this.setState({
  //       orders: temp,
  //     });
  //   } else {
  //     this.setState({
  //       orders: [],
  //     });
  //   }
  // };
  checkValid = () => {
    const start = this.state.startDate;
    const end = this.state.endDate;
    console.log(start > end);
    if (start > end) {
      this.setState({ warnvalid: true, valid: true });
    } else {
      this.setState({ warnvalid: false, valid: false });
    }
  };
  handleDate = async () => {
    this.applyfilter(this.state.startDate, this.state.endDate);
  };
  dayfilter = async (day, cond = true) => {
    console.log(day);
    const resId = this.props.restaurant.id,
      intergate = this.props.restaurant.integrate;
    let query = cond
      ? getOrderFilter(resId, intergate, null, day)
      : getOrderFilter(resId, intergate, this.last7Date, this.yesterday);
    const items = await query;
    // const items = this.state.items;
    if (items.length) {
      let temp = [];
      items.forEach((item) => {
        var data = {};
        data["date"] = item["createdAt"];
        data["id"] = item["orderId"];
        data["orderId"] = `ID : ${item["orderId"]}`;
        data["subTotal"] = item["subTotal"];
        data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
        data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
          ? `${formatPhoneNumber(item["Phone"])}`
          : "";
        data["total"] = `${item["total"]}`;
        data["tip"] = item["tip"];
        data["payment"] = `${item["payment"]}`;
        data["payment_type"] = `${item["payment_type"]}`;
        data["status"] = item["status"];
        data["link"] = `/r/${item["orderId"]}`;
        data["lineItem"] = item["items"];
        data["tax"] = item["tax"];
        data["notes"] = item["notes"];
        temp = [...temp, data];
      });
      this.setState({
        orders: temp,
      });
    } else {
      this.setState({
        orders: [],
        loader: false,
      });
    }
  };
  // dayfilter = async (day) => {
  //   const items = await getAllOrdersItem(
  //     this.props.restaurantId,
  //     40,
  //     this.state.offset,
  //     this.props.restaurantEle
  //   );
  //   // const items = this.state.items;
  //   if (items.length) {
  //     let temp = [];
  //     let condition;
  //     items.forEach((item) => {
  //       var data = {};
  //       const date = item["createdAt"];
  //       const toMatch = moment(date).format("YYYY-MM-DD");
  //       let time = 0;
  //       if (day === 0) {
  //         time = getIsoString(0, this.props.timeZone);
  //         console.log(time, toMatch);
  //         condition = toMatch === time;
  //       } else if (day === 1) {
  //         // console.log(day);
  //         time = getIsoString(1, this.props.timeZone);
  //         condition = toMatch === time;
  //       } else if (day === 6) {
  //         // console.log(day);
  //         time = getIsoString(6, this.props.timeZone);
  //         condition = toMatch >= time;
  //       }
  //       if (condition) {
  //         console.log(toMatch, time);
  //         // console.log(item);
  //         data["date"] = item["createdAt"];
  //         data["id"] = item["orderId"];
  //         data["orderId"] = `ID : ${item["orderId"]}`;
  //         data["subTotal"] = item["subTotal"];
  //         data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
  //         data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
  //           ? `${formatPhoneNumber(item["Phone"])}`
  //           : "";
  //         data["total"] = `${item["total"]}`;
  //         data["tip"] = item["tip"];
  //         data["payment"] = `${item["payment"]}`;
  //         data["payment_type"] = `${item["payment_type"]}`;
  //         data["status"] = item["status"];
  //         data["link"] = `/r/${item["orderId"]}`;
  //         data["lineItem"] = item["items"];
  //         data["tax"] = item["tax"];
  //         data["notes"] = item["notes"];
  //         // this.setState({
  //         // orders: [...this.state.orders, data],
  //         // });
  //         temp = [...temp, data];
  //       }
  //     });
  //     this.setState({
  //       orders: temp,
  //     });
  //   } else {
  //     this.setState({
  //       orders: [],
  //     });
  //   }
  // };
  render() {
    const { columns, data } = this.state;
    const table = { columns, data };
    return (
      <>
        <div className="full-width pt-5">
          <Container>
            {this.state.loader ? (
              <div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    // minHeight: "100vh",
                    transition: "0.5s ease-in-out 0s",
                    height: "100%",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ScaleLoader size={12} color="#ec7211" />
                </div>
              </div>
            ) : this.state.nodata ? (
              <div style={{ textAlign: "center" }}>
                <h5 style={{ lineHeight: "1.5em", fontSize: "22px" }}>
                  {/* Store Closed <br /> */}
                  There are no online orders to display
                </h5>
              </div>
            ) : (
              <>
                <h3>
                  <i className="fa fa-address-card-o"></i> Orders
                </h3>
                <div className="order-filter">
                  <div className="searchbtns">
                    <h6>Filter By Date</h6>
                    <button
                      id="0"
                      onClick={(e) => this.dayfilter(this.todayDate)}
                    >
                      Today
                    </button>
                    <button
                      id="1"
                      onClick={(e) => this.dayfilter(this.yesterday)}
                    >
                      Yesterday
                    </button>
                    <button
                      id="6"
                      onClick={(e) => this.dayfilter(this.last7Date, false)}
                    >
                      Last 7 days
                    </button>
                  </div>
                  <div className="daterange">
                    From :{" "}
                    <input
                      type="date"
                      placeholder="Start date"
                      onChange={(e) => {
                        this.setState({ startDate: e.target.value });
                        setTimeout(this.checkValid, 100);
                      }}
                    />
                    To :{" "}
                    <input
                      type="date"
                      placeholder="End date"
                      onChange={(e) => {
                        this.setState({ endDate: e.target.value });
                        setTimeout(this.checkValid, 100);
                        // this.checkValid();
                      }}
                    />
                    <button
                      disabled={this.state.valid}
                      onClick={(e) => {
                        console.log("clicked");
                        this.handleDate();
                        // this.applydayfilter(this.state.start, this.state.end);
                      }}
                      className="btn-search"
                    >
                      Search
                    </button>
                  </div>
                  {this.state.warnvalid ? (
                    <p style={{ color: "red" }}>Please select valid Date</p>
                  ) : (
                    <></>
                  )}
                  {/* <div className="daterange">
                <DateRangePicker
                  onApply={(x, y) => {
                    // console.log(y.startDate, y.endDate);
                    this.applyfilter(y.startDate._d, y.endDate._d);
                  }}
                >
                  <input placeholder="Start" />
                </DateRangePicker>
              </div> */}
                </div>
                <div
                  className="table-group orders-dashboard orders-mobile"
                  style={{ margin: "0" }}
                >
                  {/* <div
style={{
maxWidth: "1140px",
margin: "0 auto",
padding: "0 10px 1em",
}}
>
<h4>
<i className="fa fa-file-text" style={{ fontSize: "25px" }}></i>{" "}
Orders{" "}
</h4>
</div> */}

                  <Container style={{ minHeight: "auto", padding: "0" }}>
                    <DataTableExtensions
                      columns={this.state.columns}
                      data={this.state.orders}
                      export={false}
                      filterPlaceholder="Search Orders"
                      print={false}
                    >
                      <DataTable
                        defaultSortField="id"
                        defaultSortAsc={true}
                        paginationPerPage={10}
                        highlightOnHover={true}
                        paginationRowsPerPageOptions={[
                          20, 30, 40, 50, 60, 70, 80, 90, 100,
                        ]}
                        columns={this.state.columns}
                        data={this.state.orders}
                        pagination
                        export={false}
                        progressPending={this.state.pending}
                      // progressComponent={<BounceLoader color="#ec7211" />}
                      // onChangePage={this.handlePageChange}
                      />
                    </DataTableExtensions>
                  </Container>
                </div>
              </>
            )}

            {this.state.isOpen ? (
              <>
                <Modal
                  dialogClassName="receipt-model1"
                  show={this.state.isOpen}
                  onHide={this.closeModal}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <div id="invoice-POS" className="invoice-dashboard">
                      {/* <center id="top">
 <div className="logo">
 <img
 style={{ width: "30%" }}
 src={this.props.restaurant.restaurant.logo}
 />
 </div>
 <div className="info">
 <h2>{this.props.restaurant.restaurant.name}</h2>
 <p>
 {this.props.restaurant.address.address1}{" "}
 {this.props.restaurant.address.address2}
 <br />
 {this.props.restaurant.address.city},{" "}
 {this.props.restaurant.address.state},{" "}
 {this.props.restaurant.address.country},{" "}
 {this.props.restaurant.address.zip}
 <br />
 {this.props.restaurant.address.phoneNumber}
 <br />
 {this.props.restaurant.restaurant.website}
 </p>
 </div>
 </center> */}

                      <div id="bot">
                        <div id="table">
                          <table>
                            <tbody>
                              <tr className="tablehead1">
                                <td>
                                  <h2>Order Details</h2>
                                </td>
                                {/* <td className="Hours">Qty</td>
 <td className="Rate1">Sub Total</td> */}
                              </tr>
                              {this.state.data.lineItem.map((item) => {
                                return (
                                  <tr className="service" key={item.id}>
                                    <td className="tableitem">
                                      <p className="itemtext">{item.name}</p>
                                    </td>
                                    <td className="tableitem">
                                      <p className="itemtext">
                                        {item.quantity}
                                      </p>
                                    </td>
                                    <td className="tableitem">
                                      <p className="itemtext">
                                        {" "}
                                        ${" "}
                                        {parseFloat(
                                          (item.price / 100) * item.quantity
                                        ).toFixed(2)}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr className="inv-title">
                                <td className="Rate1">
                                  <h2>Subtotal</h2>
                                </td>
                                <td></td>
                                <td className="payment">
                                  <h2>
                                    {" "}
                                    ${" "}
                                    {parseFloat(
                                      this.state.data.subTotal / 100
                                    ).toFixed(2)}
                                  </h2>
                                </td>
                              </tr>
                              <tr className="inv-title">
                                <td className="Rate">
                                  <h2>Total Tax</h2>
                                </td>
                                <td></td>
                                <td className="payment">
                                  <h2>
                                    {" "}
                                    ${" "}
                                    {parseFloat(
                                      this.state.data.tax / 100
                                    ).toFixed(2)}
                                  </h2>
                                </td>
                              </tr>

                              <tr className="inv-title">
                                <td className="Rate">
                                  <h2>Order Total</h2>
                                </td>
                                <td></td>
                                <td className="payment">
                                  <h2>
                                    {" "}
                                    ${" "}
                                    {parseFloat(
                                      this.state.data.total / 100
                                    ).toFixed(2)}
                                  </h2>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* <p className="additional">
 {this.state.data.date} <br />
 {this.state.data.notes ? (
 <>"{this.state.data.notes}"</>
 ) : (
 <></>
 )}
 </p>*/}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  {/* <Modal.Footer>
 <Button variant="secondary" onClick={this.closeModal}>
 Close
 </Button>
 </Modal.Footer> */}
                </Modal>
              </>
            ) : (
              <></>
            )}
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerId: state.customer.id,
    customer: state.customer,
    restaurantId: state.restaurant.id,
    restaurant: state.restaurant,
    restaurantEle: state.restaurant.items.elements,
    timeZone: state.restaurant.restaurant.timeZone,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
