import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
function ProtectedRoutes(props) {
  return (props.restaurant.isOpen && props.cart.total) || props.restaurant.sms_order ? (
    // return true ? (
    <Outlet />
  ) : (
    <Navigate
      to={props.tablePath ? `${props.tablePath}` : `/${props.restaurant.id}`}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    cart: state.cart,
    tablePath: state.restaurant.tablePath,
  };
};

export default connect(mapStateToProps)(ProtectedRoutes);
