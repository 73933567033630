import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
// import { userLogin } from "../redux/actions/userActions";
import { customerLogin, getLogin } from "../../utils/api";
import { connect } from "react-redux";
import { CustomerData } from "../../redux/actions/customerActions";
import brandLogo from "../../assets/PhonedashLogo.jpg";
import "./login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { Set, Reset } from "../redux/actions/cartActions";

class UserLogin extends Component {
  state = {
    email: "",
    password: "",
    remember: false,
    spinner: false,
    disabled: true,
    revealed: false,
  };
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("test");
    if (
      window.location.hostname.includes("sandbox") ||
      window.location.hostname.includes("localhost")
    ) {
      switch (myParam) {
        case "foodparadise":
          this.updateStateLogin("clover@test.com", "clover@test");
          break;
        case "square-test":
          this.updateStateLogin("square@test.com", "square@test");
          break;
        case "custom-test":
          this.updateStateLogin("custom@test.com", "custom@test");
          break;
        case "developer":
          this.updateStateLogin("developer@trysimmer.com", "Chennai!43");
          break;
      }
    }
    console.log(myParam);
  }
  handleReveal = () => {
    this.setState({
      revealed: !this.state.revealed,
    });
  };
  validateForm() {
    return !(this.state.email.length > 0 && this.state.password.length > 0);
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleCheckbox = (e) => {
    this.setState({
      remember: e.target.checked,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ spinner: true, disabled: false });
    let res = await customerLogin(this.state.email, this.state.password, this.state.remember);
    if (res) {
      window.location.reload(false);
    } else {
      this.setState({ spinner: false, disabled: true });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="Login">
          <Row className="loginsignup">
            <Col xs={12} lg={5} className="login-col">
              <h3 className="pt-2 pb-5 text-center">
                {/* Already registered login with credentials */}
                <a data-v-a1f69832="" href="#">
                  <img
                    data-v-a1f69832=""
                    src={`${brandLogo}`}
                    width="200px"
                    // style="max-height: 75px;"
                  />
                </a>
              </h3>
              <Form onSubmit={this.handleSubmit} className="mt-4">
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder="Enter your email"
                    autoFocus
                    required
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  {/*  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text> */}
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={this.state.revealed ? "text" : "password"}
                    placeholder="Enter your password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      {this.state.revealed ? (
                        // <i
                        //   onClick={this.handleReveal}
                        //   className="fa fa-eye-slash"
                        // ></i>
                        <FaEyeSlash onClick={this.handleReveal} />
                      ) : (
                        <FaEye onClick={this.handleReveal} />
                        // <i
                        //   onClick={this.handleReveal}
                        //   className="fa fa-eye"
                        // ></i>
                      )}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </Form.Group>

                <Form.Group controlId="remember">
                  <Form.Check
                    type="checkbox"
                    label="Remember for 14 days"
                    onChange={this.handleCheckbox}
                  />
                </Form.Group>
                <Button
                  block
                  variant="dark"
                  type="submit"
                  disabled={this.validateForm() || !this.state.disabled}
                >
                  Login
                  {this.state.spinner ? (
                    <Spinner
                      as="span"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                    />
                  ) : (
                    <></>
                  )}
                </Button>
              </Form>
              <Col xs={12}>
                <p style={{ padding: "10px 0" }} className="text-left ">
                  New Customer?{" "}
                  <a
                    // to="#"
                    // onClick={() => (window.location.href = "http://phonedash.us")}
                    href="http://phonedash.us/res-signup"
                    target="_blank"
                    style={{
                      cursor: "pointer",
                      color: "#ec7211",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Sign Up here
                  </a>
                </p>
              </Col>
            </Col>
            <Col xs={12} lg={6} className="login-right"></Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default UserLogin;
