import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from 'react-redux';

const Reports = () => {
  const restaurant = useSelector(state => state.restaurant)
  let frameElement = document.querySelector('iframe')
  console.log(frameElement)
  return <>
    <div style={{}}>
      <div className="table-group orders-dashboard" style={{ margin: "0" }}>
        <Container
          style={{ minHeight: "auto", marginTop: "6 rem !important" }}
        >
          <h3>
            <i class="fa fa-address-card-o"></i> Reports
          </h3>
          {
            restaurant.report_url ?
              <>
                <iframe
                  title="external-iframe"
                  src={restaurant.report_url}
                  style={{ width: '100%', height: '100vh', border: 'none' }}
                />
              </>
              :
              <></>
          }
        </Container>
      </div>


    </div>
  </>;
}


export default Reports