import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { getConvOption, getDiscountOption } from "../Functions/fetch";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { toast } from "react-toastify";
//import avatar from "../avatar.png";

async function warnRedBox(id, action = "add") {
    if (action === "add") {
        document.getElementById(id).style.boxShadow =
            "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
    } else {
        document.getElementById(id).style.boxShadow = "none";
    }
}
class ConvenienceOption extends Component {
    state = {
        conv_option: "",
        percentage: "",
        limit: "",
        data: {},
        stateUpdating: true,
    };
    async componentDidMount() {
        this.handleUpdateState();
    }
    handleUpdateState = async () => {
        this.setState({ stateUpdating: true });
        const res = await getConvOption(this.props.restaurant_id);
        let { convenience_fee, convenience_rate, convenience_limit } = res;
        this.setState({ stateUpdating: true, conv_option: convenience_fee ? true : false, percentage: convenience_rate, limit: convenience_limit });
    };

    handleChange = (e) => {
        warnRedBox(e.target.id, "remove");
        this.setState({
            [e.target.id]: e.target.value,
        });
    };




    handleSave = async () => {
        const { conv_option, percentage, limit } = this.state;
        let query = "";
        if (this.state.conv_option) {
            query = `/api/integrations/conv-settings/${this.props.restaurant.id}?status=add`;
        } else {
            query = `/api/integrations/conv-settings/${this.props.restaurant.id}?status=remove`;
        }
        if (percentage && limit) {
            try {
                await axios
                    .post(
                        query,
                        {
                            limit: this.state.limit,
                            percentage: this.state.percentage,
                        }
                    )
                this.handleUpdateState();
                toast.success("Convenience Settings Updated");
            } catch (error) {
                console.log(error);
            }
        } else {
            [
                { value: limit, id: "limit" },
                { value: percentage, id: "percentage" },
            ].forEach((ite) => {
                if (!ite.value) {
                    warnRedBox(ite.id);
                }
            });
        }
    };
    render() {
        return (
            <div style={{ marginTop: "25px" }}>
                <h4>Convenience Option</h4>
                <Card className="order-filter" style={{ boxShadow: "none" }}>
                    <div className="coupon-container">
                        <Form className="pb-4">
                            <Row className="align-items-center">
                                <Col lg="5" md="5" sm="12">
                                    <div className="coupon-label">Convenience Option</div>
                                </Col>
                                <Col lg="7" md="7" sm="12">
                                    <div>
                                        <Form.Group className="mb-0">
                                            <Form.Check
                                                type="switch"
                                                onChange={() =>
                                                    this.setState({
                                                        conv_option: !this.state.conv_option,
                                                    })
                                                }
                                                id="conv_option"
                                                label="OFF/ON"
                                                //value = "1"
                                                checked={this.state.conv_option}
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        <Form className="pb-4">
                            <Row className="align-items-center">
                                <Col lg="5" md="5" sm="12">
                                    <div className="coupon-label">
                                        Percentage
                                    </div>
                                </Col>
                                <Col lg="7" md="7" sm="12">
                                    <div style={{ display: "flex" }}>
                                        <Form.Group className="mb-0">
                                            <Form.Control
                                                onChange={this.handleChange}
                                                value={this.state.percentage}
                                                type="number"
                                                name="percentage"
                                                placeholder="Percentage"
                                                id="percentage"
                                                disabled={!this.state.conv_option}
                                                required
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        <Form className="pb-4">
                            <Row className="align-items-center">
                                <Col lg="5" md="5" sm="12">
                                    <div className="coupon-label">
                                        Max Limit ($)
                                    </div>
                                </Col>
                                <Col lg="7" md="7" sm="12">
                                    <div style={{ display: "flex" }}>
                                        <Form.Group className="mb-0">
                                            <Form.Control
                                                onChange={this.handleChange}
                                                value={this.state.limit}
                                                type="number"
                                                name="limit"
                                                id="limit"
                                                placeholder="Limit"
                                                disabled={!this.state.conv_option}
                                                required
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                    <div className="d-flex justify-content-center">
                        <div></div>
                        <div>
                            <Button
                                onClick={this.handleSave}
                                disabled={this.stateUpdating}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}
// const mapStateToProps = (state) => {
//   return {
//     restaurant_id: state.customer.resId,
//     customer: state.customer,
//     items: state.cart.items,
//     restaurant: state.restaurant,
//   };
// };
// export default connect(mapStateToProps, null)(DiscountOption);
export default ConvenienceOption;