import React, { Component, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CartTotalsCheckout from "../../components/CartTotalsCheckout";
import { connect } from "react-redux";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import _ from "lodash";
import {
  addCustomerCard,
  addCustomerPhone,
  getPaymentType,
  getRestauranOrdertDetails,
  payRequest,
  updateCustomer,
  payOnline,
  updateCustomerEmail,
  updateCustomerPhone,
  payOnlineCustom,
  builtStripItems,
  payOnlineCustomLater,
} from "../../utils/api";
import BrandingHeader from "../../components/BrandingHeader";
import { FaUserEdit } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { Button } from "react-bootstrap";
import CreditCardInput from "react-credit-card-input";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentRequestButtonElement,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faBars } from '@fortawesome/free-solid-svg-icons';
import MultiLingualContent from "../../context/multilingualContent";

import { round, squareInit, squarePaymentsInit, squareTokenize } from "../../utils/functions";
import { toast } from "react-toastify";

// const stripePromise = loadStripe('pk_live_51IvaDEB8IOuWU0mtRX2RnIxZ9d8ZiYc5I2WbV69olt2u9QuXQBrr8c3iV4PzPJPmXJQW1NQgzz62Qs2ak7fpSo2d00T69wBGPG');
// const stripePromise = loadStripe(
//   "pk_test_51IvaDEB8IOuWU0mtzQ6MfdLjqyD70SGdFfsMxJvTcrn1v3cMGjrGy79t5sY5XzLUqQtvRJqV6IgkgyP7BOFBsp8h00p1rMPUGP"
// );
const RequestPay = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const [modalShow, setModalShow] = useState(false);
  const [res_id, setResId] = useState(props.res_id);
  const [name, setName] = useState("");
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: `Total`,
          amount: parseInt(props.total),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        pr.on("paymentmethod", async function (e) {
          //2
          setName(e.paymentMethod.billing_details.name);
          const paymentDetails = {
            payment_method: e.paymentMethod.id,
            shipping: {
              name: e.paymentMethod.billing_details.name,
              phone: e.paymentMethod.billing_details.phone
                ? e.paymentMethod.billing_details.phone
                : "",
              address: {
                line1: `${e.paymentMethod.billing_details.address.line1
                    ? e.paymentMethod.billing_details.address.line1
                    : ""
                  }, ${e.paymentMethod.billing_details.address.line2
                    ? e.paymentMethod.billing_details.address.line2
                    : ""
                }`,
                city: e.paymentMethod.billing_details.address.city
                  ? e.paymentMethod.billing_details.address.city
                  : "",
                country: e.paymentMethod.billing_details.address.country
                  ? e.paymentMethod.billing_details.address.country
                  : "",
                state: e.paymentMethod.billing_details.address.state
                  ? e.paymentMethod.billing_details.address.state
                  : "",
                postal_code: e.paymentMethod.billing_details.address.postal_code
                  ? e.paymentMethod.billing_details.address.postal_code
                  : "",
              },
            },
          };
          const customer_data = {
            'name': e.paymentMethod.billing_details.name
              ? e.paymentMethod.billing_details.name
              : "",
            'phone': e.paymentMethod.billing_details.phone
              ? e.paymentMethod.billing_details.phone
              : "",
            'email': e.paymentMethod.billing_details.email
              ? e.paymentMethod.billing_details.email
              : ""
          }
          let response = await fetch(
            `/api/order/${res_id}/create-payment-intent`,
            {
              method: "POST",
              body: JSON.stringify({
                paymentDetails,
                customer_data,
                amount: parseInt(props.total),
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          response = await response.json()
          if (response.error) {
            console.log(response.error);
            e.complete("fail");
          } else {
            // e.complete('success');
            let stripe_customer_id = response.customer
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              response.client_secret
            );
            if (error) {
              console.log(error);
              return;
            }
            if (paymentIntent.status === "succeeded") {
              var data = {};
              data["stripe_customer_id"] = stripe_customer_id;
              data["orderId"] = props.orderId;
              data["restaurant_id"] = res_id;
              data["notes"] = props.notes;
              data["customer_id"] = props.customer_id;

              if (e.walletName === "googlePay") {
                data["payment"] = "google";
                data["paymentId"] = props.paymentMethodIds["google"];
              } else {
                data["payment"] = "apple";
                data["paymentId"] = props.paymentMethodIds["apple"];
              }
              data["payment_id"] = e.paymentMethod.id;
              const response = await payRequest(data);
              if (!response) {
                e.complete("fail");
              } else {
                e.complete("success");
                localStorage.removeItem(res_id);
                setModalShow(true);
              }
            } else {
              console.warn(
                `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
              );
            }
          }
        });
        // pr.on('token', function (e) { // 1
        //   console.log(e)
        // });
        // pr.on('source', function (e) { //3
        //   console.log(e)
        // });
        pr.on("cancel", function () {
          alert("Payment Cancelled");
        });
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);
  const handleConinue = () => {
    window.location.href = `/${props.res_id}`;
  };
  if (paymentRequest) {
    return (
      <>
        <PaymentRequestButtonElement
          className="payment-element"
          options={{ paymentRequest }}
        />{" "}
        <p className="payment-or">OR</p>
        <Modal
          show={modalShow}
          size="md"
          aria-labelledby="ordersucess"
          centered
        >
          <Modal.Body>
            <>
              <p style={{ textAlign: "center" }}>
                Hi {name}, <br />
                Transaction Completed Successfully.
              </p>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ marginBottom: "0" }} onClick={handleConinue}>
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  // Use a traditional checkout form.
  return <></>;
};

class CustomPayments extends Component {
  state = {
    cardNum: "",
    expiry: "",
    cvv: "",
    stripePromise: "",
    payment_gateway: this.props.restaurant.payment_gateway,
    paymentMethod: {},
    modalShow: false,
    loader: false,
    type: "online",
    updated: true,
    isDisable: false,
    orderData: null,
    restaurant_id: window.location.pathname.split("/")[2],
    orderId: window.location.pathname.split("/")[3],
    restaurant_name: "Restaurant",
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const orderData = await getRestauranOrdertDetails(
      window.location.pathname.split("/")[2],
      window.location.pathname.split("/")[3]
    );
    const stripePromise = await loadStripe(
      orderData["restaurant"]["stripe_pk"]
    );
    // console.log(orderData['orderData']['state'])
    if (
      orderData["orderData"]["payment"] !== "Not Paid" &&
      orderData["orderData"]["payment"] !== "Not Paid"
    ) {
      console.log("IF");
      this.setState({
        paid: true,
      });
    } else {
      console.log("ELSE");
      this.setState({
        paid: false,
      });
    }
    var paymentId = {};
    var orderDetails = {};
    let restaurantData = {};
    this.setState({
      stripePromise,
    });

    restaurantData["convenience_fee"] =
      orderData["restaurant"]["convenience_fee"];
    restaurantData["convenience_rate"] =
      orderData["restaurant"]["convenience_rate"];
    restaurantData["convenience_limit"] =
      orderData["restaurant"]["convenience_limit"];
    restaurantData["tax"] = orderData["restaurant"]["tax"];
    this.setState({ restaurant_name: orderData["restaurant"]["name"] });
    orderDetails["totalItems"] = orderData["orderData"]["items"].length;
    orderDetails["subTotal"] = parseFloat(
      orderData["orderData"]["subTotal"] / 100
    ).toFixed(2);
    orderDetails["tip"] = parseFloat(
      orderData["orderData"]["tip"] / 100
    ).toFixed(2);
    orderDetails["discount"] = parseFloat(
      orderData["orderData"]["discount"] / 100
    ).toFixed(2);
    orderDetails["total"] = parseFloat(
      orderData["orderData"]["total"] / 100
    ).toFixed(2);
    orderDetails["tax"] = parseFloat(
      orderData["orderData"]["tax"] / 100
    ).toFixed(2);

    this.setState({
      restaurantData,
      orderData: {
        ...this.state.orderData,
        ...orderDetails,
        items: orderData["orderData"]["items"],
      },
      // customer_id: orderData["orderData"]["customers"]["elements"][0]["id"],
      tip: parseInt(orderDetails["tip"] * 100),
      notes: orderData["orderData"]["note"].replace("\nPayment : Not Paid", ""),
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.target.style.boxShadow = "none";
  };
  handleEdit = (e) => {
    this.setState({
      updated: false,
      edit: true,
    });
  };

  handlePaymentEdit = (e) => {
    this.setState({
      updated: false,
      editPayment: true,
    });
  };

  handleNewOrder = async (event) => {
    event.preventDefault();

    if (
      this.state.cvv.length > 0 &&
      this.state.expiry.length > 0 &&
      this.state.cardNum.length > 0
    ) {
      var data = _.pick(this.state, [
        "cardNum",
        "cvv",
        "expiry",
        "orderId",
        "restaurant_id",
        "customer_id",
        "tip",
        "notes",
        "items",
        "orderData",
        "restaurantData",
      ]);
      this.setState({ isDisable: true, loader: true });
      if (
        data.restaurant_id.toLowerCase() ===
        "vishnuji-ki-rasoi-festival-mela".toLowerCase()
      ) {
        const res = await payOnlineCustom(data);
        if (res) {
          localStorage.removeItem(this.state.restaurant_id);
          this.setState({
            loader: false,
            modalShow: true,
          });
        } else {
          this.setState({
            loader: false,
          });
        }
      } else {
        const checkoutItems = await builtStripItems(
          data.restaurant_id,
          data.orderData.items,
          data.orderData.tip,
          data.restaurantData.tax,
          data.restaurantData.convenience_fee,
          data.restaurantData.convenience_rate,
          data.restaurantData.convenience_limit,
          true,
          0
        );
        const res = await payOnlineCustomLater(data, checkoutItems.checkoutId, {
          payment_gateway: this.state.payment_gateway,
        });
        if (res) {
          localStorage.removeItem(this.state.restaurant_id);
          this.setState({
            loader: false,
            modalShow: true,
          });
        } else {
          this.setState({
            loader: false,
          });
        }
      }
    } else {
      if (
        this.state.cvv === "" ||
        this.state.expiry === "" ||
        this.state.cardNum === ""
      ) {
        document.getElementById("field-wrapper").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
      }
    }
  };
  handleBlur = (e) => {
    // e.target.style.boxShadow = "none";
  };
  handleConinue = () => {
    window.location.href = `/${this.state.restaurant_id}`;
    this.props.Reset();
  };

  setValue = (obj) => {
    this.setState({
      ...obj
    })
  }
  squareInit = async (app_id, location_id) => {
    const payments = await squareInit(app_id, location_id);
    let amount = 0;
    let { convenience_fee: action, convenience_rate: rate, convenience_limit: limit } = this.props.restaurant;
    let { tip, tax } = this.props;
    let discountPercent = this.state.discountPrecent;
    let total = Number(parseFloat(this.props.totalPrice).toFixed(2));
    let orderTotal = total;
    let { checkout: checkoutData } = this.props;
    if (checkoutData.couponCode.percentage) {
      amount = Number((checkoutData.finalTotal * 100).toFixed(2));
    } else if (discountPercent) {
      let discount = Number(((total * discountPercent) / 100).toFixed(2));
      let applyiedTotal = total - discount;
      let to_tax = applyiedTotal * tax;
      let to_tip = applyiedTotal * (tip / 100);
      orderTotal = Number((applyiedTotal + to_tax + to_tip).toFixed(2));

      if (action) {
        let convenience_fee = Number(((orderTotal * rate) / 100).toFixed(2));
        let limitfee = Number((limit * 100).toFixed(2));
        if (limit && convenience_fee > limitfee) {
          convenience_fee = limitfee;
        }
        orderTotal += convenience_fee;
        amount = Number(orderTotal.toFixed(2)) * 100;
      } else {
        amount = Number(orderTotal.toFixed(2)) * 100;
      }
    } else {
      console.log("non-discount");
      if (action) {
        orderTotal += Number((total * (tip / 100) + total * tax).toFixed(2));

        let convenience_fee = Number(((orderTotal * rate) / 100).toFixed(2));
        let limitfee = limit * 100;
        if (limit && convenience_fee > limitfee) {
          convenience_fee = limitfee;
        }
        orderTotal += convenience_fee;
        amount = orderTotal * 100;
      } else {
        orderTotal = Number(
          (total + total * (tip / 100) + total * tax).toFixed(2)
        );
        amount = orderTotal * 100;
      }
    }
    await squarePaymentsInit(orderTotal, payments, this.setValue);

  }

  createPayment = async (token) => {
    var data = _.pick(this.state, [
      "cardNum",
      "cvv",
      "expiry",
      "orderId",
      "restaurant_id",
      "customer_id",
      "tip",
      "notes",
      "items",
      "orderData",
      "restaurantData",
    ]);
    const res = await payOnlineCustomLater(
      data,
      "",
      {
        payment_gateway: this.state.payment_gateway,
        payment_type: this.state.payment_type,
        token: token,
        location_id: this.props.restaurant.location_id
      }
    );
    localStorage.removeItem(this.state.restaurant_id);
    if (this.state.tableId) {
      window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&language=${this.props.restaurant.language}`;
    } else {
      this.setState({ isDisable: false, loader: false });
      if (Object.keys(res).includes("msg") && res.msg == "Order Placed") {
        this.setState({
          payment_status_failure: true,
          orderId: res.id,
          payment_status_msg: res,
          order_placed: true,
        });
      } else if (Object.keys(res).includes("id")) {
        let points = res.points;
        this.setState({
          modalShow: true,
          orderId: res.id,
          loader: false,
          points,
        });
      } else {
          toast.error(res);
      }
    }

  };

  handlePaymentMethodSubmission = async (event, paymentMethod) => {
    event.preventDefault();
    try {
        this.setState({ isDisable: true, loader: true });
        const token = await squareTokenize(paymentMethod, this.setValue);
        await this.createPayment(token);
    } catch (e) {
      this.setState({ isDisable: false, loader: false, cardDecline: true });
    }
  };

  handleCardPayment = async (e) => {
    e.preventDefault();
    this.setState({
      payment_type: "card",
    });
    const res = await this.handlePaymentMethodSubmission(e, this.state.card);

  };

  handleApplePayment = async (e) => {
    this.setState({
      payment_type: "apple-pay",
      isDisable: true,
      loader: true,
    });
    await this.handlePaymentMethodSubmission(e, this.state.applePay);
  }
  handleGooglePayment = async (e) => {
    this.setState({
      payment_type: "google-pay",
      isDisable: true,
      loader: true,
    });
    await this.handlePaymentMethodSubmission(e, this.state.googlePay);
  };

  render() {
    const checkout = (
      <Row style={{ marginTop: "40px" }}>
        {/* {console.log(this.state)} */}
        <Col xs={12} lg={6}>
          <div className="payment-button" style={{ paddingTop: "23px" }}>
            {this.state.stripePromise && this.state.orderData ? (
              <div style={{ width: "100%" }}>
                {" "}
                {
                  this.state.payment_gateway === 'square' ?
                    <><div
                      id="google-pay-button"
                      onClick={this.handleGooglePayment}
                    >
                    </div>
                      <div
                        id="apple-pay-button"
                        onClick={this.handleGooglePayment}
                      >
                      </div>
                    </>
                    :
                    <>
                {/* <Elements stripe={this.state.stripePromise}>
                  <RequestPay
                    customer_id={this.state.customer_id}
                    orderId={this.state.orderId}
                    paymentMethodIds={this.state.paymentMethod}
                    subTotal={this.state.orderData.subTotal * 100}
                    total={this.state.orderData.total * 100}
                    res_id={this.state.restaurant_id}
                    tip={this.state.orderData.tip * 100}
                    tax={this.state.orderData.tax * 100}
                    notes={this.state.notes}
                  />
                </Elements> */}
                          </>
                }

              </div>
            ) : (
              <></>
            )}
          </div>

          <Modal
            show={this.state.modalShow}
            size="md"
            aria-labelledby="ordersucess"
            centered
          >
            <Modal.Body>
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  Transaction Completed Successfully
                </p>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ marginBottom: "0" }}
                onClick={this.handleConinue}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
          <Card className="mt-3 mb-5">
            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span>PAYMENT</span>
              {/* {this.state.editPayment ? (
                                <></>
                            ) : this.state.customer_id ? (
                                <>
                                    {" "}
                                    <FaUserEdit
                                        className="mr-1"
                                        size="1.5em"
                                        onClick={this.handlePaymentEdit}
                                    />
                                </>
                            ) : (
                                <></>
                            )} */}
            </Card.Header>
            <Card.Body>
              <Form
                className="p-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <br />

                {
                  this.state.payment_gateway === 'square' ? <div id="card-container"></div>
                    :
                <CreditCardInput
                  cardNumberInputProps={{
                    value: this.state.cardNum,
                    onBlur: (e) =>
                      (document.getElementById(
                        "field-wrapper"
                      ).style.boxShadow = "none"),
                    onChange: (e) => {
                      this.setState({
                        cardNum: e.target.value,
                      });
                    },
                  }}
                  cardExpiryInputProps={{
                    value: this.state.expiry,
                    onBlur: (e) =>
                      (document.getElementById(
                        "field-wrapper"
                      ).style.boxShadow = "none"),
                    onChange: (e) => {
                      this.setState({
                        expiry: e.target.value,
                      });
                    },
                  }}
                  cardCVCInputProps={{
                    value: this.state.cvv,
                    onBlur: (e) =>
                      (document.getElementById(
                        "field-wrapper"
                      ).style.boxShadow = "none"),
                    onChange: (e) => {
                      this.setState({
                        cvv: e.target.value,
                      });
                    },
                  }}
                  fieldClassName="card-input form-control"
                    />
                  }
              </Form>
            </Card.Body>
          </Card>
        </Col>
        {this.state.orderData && (
          <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={5}>
            <div className="checkout-cont">
              {/* {console.log(props.tip)} */}
              <h4
                className="my-auto"
                style={{ fontSize: "17px", fontWeight: "600" }}
              >
               <MultiLingualContent contentID="Order Summary" />
              </h4>
              <Card className="mt-3">
                <Card.Header className="pl-2 font-weight-bold">
                  {this.state.orderData.totalItems} items
                </Card.Header>
                <Card.Body className="d-flex border-bottom justify-content-between p-2 ">
                  <Card.Text><MultiLingualContent contentID="Subtotal" /></Card.Text>
                  <Card.Text>${this.state.orderData.subTotal}</Card.Text>
                </Card.Body>
                {parseFloat(this.state.orderData.discount) ? (
                  <Card.Body className="d-flex justify-content-between p-2">
                    <Card.Text>10% Discount (Mela)</Card.Text>
                    <Card.Text>-$ {this.state.orderData.discount}</Card.Text>
                  </Card.Body>
                ) : (
                  <></>
                )}
                <Card.Body className="d-flex justify-content-between p-2">
                  <Card.Text>Tax</Card.Text>
                  <Card.Text>$ {this.state.orderData.tax}</Card.Text>
                </Card.Body>{" "}
                {parseFloat(this.state.orderData.tip) ? (
                  <Card.Body className="d-flex justify-content-between p-2">
                    <Card.Text>Tip</Card.Text>
                    <Card.Text>{this.state.orderData.tip}</Card.Text>
                  </Card.Body>
                ) : (
                  <></>
                )}
                <Card.Body className="d-flex justify-content-between p-2">
                  <Card.Text><MultiLingualContent contentID="Total" /></Card.Text>
                  <Card.Text>$ {this.state.orderData.total}</Card.Text>
                </Card.Body>
                <Card.Footer
                  style={{
                    border: "0",
                    background: "none",
                    padding: "22px 0 0",
                  }}
                >
                  <Button
                    style={{ width: "auto" }}
                    onClick={this.state.payment_gateway === 'square' ? this.handleCardPayment : this.handleNewOrder}
                    variant="warning"
                    block
                  >
                    Pay
                  </Button>{" "}
                  <hr />
                  <h6 className="mt-3">
                  <MultiLingualContent contentID="WE ACCEPT" />:
                    <Image
                      className="img-thumbnail mt-1"
                      src="https://assets.asosservices.com/asos-finance/images/marketing/single.png"
                      alt="payment options"
                    />
                  </h6>
                </Card.Footer>
              </Card>
            </div>
          </Col>
        )}
      </Row>
    );

    return (
      <>
        <div className="cart-header">
          <p>{this.state.restaurant_name}</p>
          <button
            onClick={() => window.history.back()}
            style={{
              padding: "5px 10px",
              background: "none",
              border: "0",
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            <FontAwesomeIcon icon={faClose} size={'1x'} />
          </button>
        </div>
        <React.Fragment>
          <Container
            className="my-auto Checkout"
            style={{ opacity: this.state.loader ? "0.4" : "1", padding: 0 }}
          >
            {!this.state.paid && <>{checkout}</>}
            {this.state.paid && (
              <>
                <Modal
                  show={true}
                  size="md"
                  aria-labelledby="ordersucess"
                  centered
                >
                  <Modal.Body>
                    <>
                      <p
                        style={{
                          textAlign: "center",
                          margin: "0px 0 6px 0",
                          paddingTop: " 12px",
                          fontSize: "18px",
                        }}
                      >
                        Order Already Paid
                      </p>
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      style={{ marginBottom: "0" }}
                      onClick={this.handleConinue}
                    >
                      Continue
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </Container>
        </React.Fragment>
      </>
    );
  }
}

export default CustomPayments;
