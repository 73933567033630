import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { CustomerData } from "../redux/actions/customerActions";
import { checkoutInit } from "../redux/actions/checkoutActions";
import Select from "react-select";
import axios from "axios";
import {
  getTable,
  postUpdateTableOrder,
  postTableOrder,
  getChargeId,
  getOrderTypes,
  getCloverOrderType,
} from "../utils/api";
import { getUserAll } from "../dashboard/Functions/fetch";
import { Tip, Notes } from "../redux/actions/cartActions";
import MultiLingualContent from "../context/multilingualContent";
import { translations } from "../context/translations";
const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
function CartTotalsCart(props) {
  const [coupon_code, setCoupon] = useState("");
  const [coupon_error, setCouponMessage] = useState("");
  const [coupon_applied, setCouponApplied] = useState(true);
  const [applied_coupon_code, setAppliedCouponCode] = useState("");
  const [coupon_discount_percentage, setCouponDiscountPercentage] = useState(0);
  const [coupon_discount_amount, setCouponDiscountAmount] = useState(0);
  const [coupon_tax, setCouponTax] = useState(0);
  const [coupon_tip, setCouponTip] = useState(0);
  const [coupon_total, setCouponTotal] = useState(0);

  const [user_data, setUser] = useState(0);

  const [tip, setTip] = useState(0);
  const [to, setTo] = useState("");
  const [tables, setTables] = useState([]);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [tableStatus, setTableStatus] = useState(1);
  const [tableId, setTableId] = useState(0);
  const [type, setType] = useState("");
  const [chargeId, setChargeId] = useState("");
  const [orderType, setOrderType] = useState("");
  const [loader, setLoader] = useState(false);
  const [discount, setDiscount] = useState({ isDiscount: 0 });
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountData, setDiscountData] = useState({ name: "" });
  const [discountPrice, setDiscountPrice] = useState();
  const [convTotal, setConvTotal] = useState(props.totalPrice);
  const [tax, setTax] = useState(
    parseFloat(props.restaurant.totalTax * props.totalPrice).toFixed(2)
  );
  const [toTip, setToTip] = useState(parseFloat(props.tips));
  const [finalPrice, setFinalPrice] = useState(
    parseFloat(
      props.totalPrice +
      props.restaurant.totalTax * props.totalPrice +
      parseFloat(props.tips)
    ).toFixed(2)
  );
  // const [finalPrice, setFinalPrice] = useState(
  // parseFloat(
  // props.totalPrice +
  // props.restaurant.totalTax * props.totalPrice +
  // (props.totalPrice) * (18 / 100)
  // ).toFixed(2)
  // );

  let navigate = useNavigate();
  const tipsChange = (e) => {
    const list = document.getElementById("tipslist");
    var items = list.getElementsByTagName("li");
    for (var i = 0; i < items.length; ++i) {
      if (items[i].getAttribute("name") === e.target.getAttribute("name")) {
        items[i].classList.add("active");
      } else {
        items[i].classList.remove("active");
      }
    }
    if (e.target.getAttribute("name") !== "custom") {
      console.log(e.target.getAttribute("name"));
      const tip = e.target.getAttribute("name");
      setTipData(tip);
    }
  };
  function setTipData(tip) {
    if (props.restaurant.is_tip) {
      setTip(parseInt(tip));
      props.Tip(parseInt(tip));
    }
  }
  const handleTipChange = (e) => {
    if (e.target.value.length) {
      setTip(e.target.value);
      props.Tip(parseInt(e.target.value));
    } else {
      setTip(0);
      props.Tip(0);
    }
  };
  const notesChange = (e) => {
    props.Notes(e.target.value);
  };
  useEffect(() => {
    setTipData(tip);
  }, [props.totalPrice])
  useEffect(() => {
    // console.log(is_coupon_discount);
    (async function () {
      const res = await getUserAll(props.restaurant.id);
      // console.log(res);
      // console.log(res[0].is_coupon_discount);
      if (res[0].is_coupon_discount == 1) {
        setUser(1);
      } else {
        setUser(0);
      }
      const response = await fetch(
        `/api/v1/merchant/${props.restaurant.id}/${props.restaurant.integrate}/discount`
      );
      const json = await response.json();
      setDiscount(json);
      if (json.isDiscount) {
        setDiscountData(json.data);
        setDiscountPercent(json.percentage / 100);

        // console.log(props.totalPrice);
        // const discountprice = parseFloat(
        // props.totalPrice * (json.percentage / 100)
        // ).toFixed(2);
        // setConvTotal(props.totalPrice - discountprice);
        // const toTax = parseFloat(
        // (
        // props.restaurant.totalTax *
        // (props.totalPrice - discountprice)
        // ).toFixed(2)
        // );
        // setTax(toTax);
        // const contip = parseFloat(
        // (props.tip * (props.totalPrice - discountprice)).toFixed(2)
        // );
        // setFinalPrice(
        // parseFloat(
        // props.totalPrice - discountprice + toTax + parseFloat(props.tips)
        // ).toFixed(2)
        // );
      }
      setTipData(18);
      if (window.location.pathname.split("/")[2].includes("table")) {
        console.log(`TABLE FOUND!!!`);
        async function getTables() {
          const tables = await getTable(props.restaurant.id);
          var data = [];
          tables.forEach((table) => {
            var temp = {};
            temp["value"] = table["tables_id"];
            temp["label"] = `Table ${table["tables_id"]}`;
            temp["available"] = table["available"];
            data = [...data, temp];
          });
          props.checkoutInit({
            subTotal: props.totalPrice,
            tip: {
              value: Number(
                parseFloat(((tip / 100) * props.totalPrice).toFixed(2))
              ),
              percentage: tip,
            },
            tax: {
              value: Number(
                parseFloat(
                  (props.restaurant.totalTax * props.totalPrice).toFixed(2)
                )
              ),
              percentage: props.restaurant.totalTax,
            },
            total: Number(
              parseFloat(
                props.totalPrice +
                props.restaurant.totalTax * props.totalPrice +
                parseFloat(props.tips)
              ).toFixed(2)
            ),
          });
          // console.log(data);
          setTables(data);
        }
        getTables();
        const ServicechargesId = await getChargeId(props.restaurant.id);
        console.log(ServicechargesId);
        setChargeId(ServicechargesId.id);

        // const orderTypeId = await getCloverOrderType(
        // props.restaurant.id,
        // "Dine In"
        // );
        // setOrderType(orderTypeId["id"]);
        // const orderType = async () => {
        // const type = await await getOrderTypes(
        // window.location.pathname.split("/")[1]
        // );
        // return type;
        // };
        // orderType()
        // .then((typeEle) => {
        // // console.log(type);
        // let orderType = typeEle.filter((type) =>
        // type.label.toLocaleLowerCase().includes("table")
        // );
        // if (orderType.length > 0) {
        // orderType = orderType[0]["id"];
        // setOrderType(orderType);
        // } else {
        // orderType = typeEle.filter(
        // (type) =>
        // type.label.includes("out") || type.label.includes("up")
        // );
        // orderType = orderType[0]["id"];
        // setOrderType(orderType);
        // }
        // })
        // .catch((err) => {});
      }
    })();
  }, []);

  const handleTableSelect = (e) => {
    if (e.available) {
      // console.log(e);
      setType("clear");
      setTableStatus(1);
      setTableId(e.value);
      setTo(`/${props.restaurant.id}/table/${e.value}/pay-on-cash`);
    } else {
      console.log(e);
      setType("update");
      setTableId(e.value);
      setTableStatus(0);
    }
  };

  const handleClose = (e) => {
    if (type === "clear") {
      setType("update");
      document.getElementById("clear").classList.remove("active");
    } else {
      setTo(`/${props.restaurant.id}/table/${tableId}/pay-on-cash`);
      setType("clear");
      document.getElementById("clear").classList.add("active");
    }
  };

  const handleUpdateOrder = async (e) => {
    setLoader(true);
    var res = await postUpdateTableOrder(
      props.restaurant.id,
      tableId,
      props.items,
      props.cart.tip,
      props.tax
    );
    console.log(res);
    if (Object.keys(res).length) {
      setLoader(false);
      localStorage.removeItem(props.restaurant.id);
      setOpen(true);
      setOrderId(res.id);
    }
  };
  const handleConinue = async (e) => {
    window.location.href = `${window.location.href.split("table")[0]}table`;
  };
  const handlePayOnline = async () => {
    props.checkoutInit({
      subTotal: props.totalPrice,
      tip: {
        value: Number(parseFloat(((tip / 100) * props.totalPrice).toFixed(2))),
        percentage: tip,
      },
      tax: {
        value: Number(
          parseFloat((props.restaurant.totalTax * props.totalPrice).toFixed(2))
        ),
        percentage: props.restaurant.totalTax,
      },
      total: Number(
        parseFloat(
          props.totalPrice +
          props.restaurant.totalTax * props.totalPrice +
          parseFloat(props.tips)
        ).toFixed(2)
      ),
    });
    //console.log("click");
    const items = Object.values(props.items);
    const needToRemove = items.filter((ite) => ite.num && !ite.price);
    // console.log(needToRemove);
    const toDirect = props.tablePath
      ? `${props.tablePath}/pay-online`
      : `/${props.restaurant.id}/pay-online`;
    navigate(toDirect);
  };
  const placeTable = async (e) => {
    // console.log(chargeId);
    setLoader(true);
    const state = {
      firstName: `Table ${tableId}`,
      tableOrder: true,
      table: tableId,
      restaurant_id: props.restaurant.id,
      orderType: orderType,
      chargeId: chargeId,
    };
    console.log(state.chargeId);
    const res = await postTableOrder(
      state,
      props.orderCart,
      props.cart.tip,
      state.chargeId,
      props.tax,
      props.customer.connect,
      props.checkout
    );
    console.log(res);
    if (Object.keys(res).length) {
      setLoader(false);
      localStorage.removeItem(props.restaurant.id);
      setOpen(true);
      setOrderId(res.id);
    }
  };

  const couponChange = (e) => {
    setCoupon(e.target.value);
    console.log(e.target.value);
    setCouponMessage("");
    setAppliedCouponCode("");
  };
  const couponSubmit = (e) => {
    e.preventDefault();
    if (coupon_code != "") {
      console.log(props.customer);
      axios
        .post(`/api/integrations/get/coupon_code/${props.restaurant.id}`, {
          coupon_code,
        })
        .then((res) => {
          //const [result] = JSON.parse(JSON.stringify(res));
          //console.log(55,res.data.coupon_discount);

          if (res.data.msg == "Applied") {
            setCouponApplied(false);
            let discount = 0;
            let couponTotal = 0;
            let couponTax = 0;
            let convFee = 0;
            if (res.data.coupon_discount > 0) {
              discount = parseFloat(
                (props.totalPrice * res.data.coupon_discount) / 100
              ).toFixed(2);
              console.log(10, discount);
              couponTotal = props.totalPrice - discount;
              couponTax = Number(
                parseFloat(props.restaurant.totalTax * couponTotal).toFixed(2)
              );
              setCouponTax(couponTax);
              setCouponTotal(couponTotal);
              if (props.restaurant.convenience_fee) {
                convFee = couponTotal * (props.restaurant.convenience_rate / 100);
                let limitfee = props.restaurant.convenience_limit;
                if (props.restaurant.convenience_limit && convFee > limitfee) {
                  convFee = limitfee;
                }

              }
              setFinalPrice(Number(
                parseFloat(
                  couponTotal + couponTax + couponTotal * (tip / 100)
                ).toFixed(2)
              ))
              setTimeout(() => {
                props.checkoutInit({
                  subTotal: props.totalPrice,
                  tip: {
                    value: Number(
                      parseFloat(((tip / 100) * props.totalPrice).toFixed(2))
                    ),
                    percentage: tip,
                  },
                  tax: {
                    value: Number(
                      parseFloat(
                        (props.restaurant.totalTax * props.totalPrice).toFixed(
                          2
                        )
                      )
                    ),
                    percentage: props.restaurant.totalTax,
                  },
                  total: Number(
                    parseFloat(
                      props.totalPrice +
                      props.restaurant.totalTax * props.totalPrice +
                      parseFloat(props.tips)
                    ).toFixed(2)
                  ),
                  finalSubTotal: props.totalPrice - discount,
                  convFee: convFee,
                  finalTip: {
                    value: Number(
                      parseFloat(
                        (props.totalPrice - discount) * (tip / 100)
                      ).toFixed(2)
                    ),
                    percentage: tip,
                  },
                  convenience_fee: {
                    is_enabled: props.restaurant.convenience_fee,
                    percentage: props.restaurant.convenience_rate,
                    limit: props.restaurant.convenience_limit,
                  },
                  finalTax: {
                    value: Number(
                      parseFloat(
                        props.restaurant.totalTax *
                        (props.totalPrice - discount)
                      ).toFixed(2)
                    ),
                    percentage: props.restaurant.totalTax,
                  },
                  finalTotal: Number(
                    parseFloat(
                      couponTotal + couponTax + couponTotal * (tip / 100)
                    ).toFixed(2)
                  ),
                  discount: {
                    name: "",
                    value: discount,
                    percentage: res.data.coupon_discount,
                  },
                  couponCode: {
                    name: "",
                    code: coupon_code,
                    percentage: res.data.coupon_discount,
                  },
                });
              }, 200);
            }
            setCouponMessage(res.data.msg);
            setAppliedCouponCode(coupon_code);
            setCouponDiscountPercentage(res.data.coupon_discount);
            setCouponDiscountAmount(discount);
            setCouponApplied(true);
          } else {
            setAppliedCouponCode("");
            setCouponMessage(res.data.msg);
          }
          // setState({
          // locationData: res.data,
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };
  const translatedWord = (word) =>
    translations[props.restaurant.language]
      ? translations[props.restaurant.language][word]
      : translations["en"][word];
  let order_notes = translatedWord("Order notes");
  return (
    <div className="total-cart">
      {/* {console.log(66,coupon_error,applied_coupon_code,coupon_discount_percentage,coupon_discount_amount,coupon_applied,coupon_tip)} */}
      <div className="cart-flow">
        <Card className="mt-31">
          <Card.Header
            className="pl-2 d-flex align-items-center justify-content-between"
            style={{ background: "none" }}
          >
            <div>
              <MultiLingualContent contentID="Pickup Time" />
              <br />
              <span
                style={{
                  fontWeight: "500",
                  padding: "1em 0",
                  display: "inline-block",
                }}
              >
                <img
                  style={{
                    maxWidth: "22px",
                    marginRight: "8px",
                    verticalAlign: "sub",
                  }}
                  src="/clock.svg"
                />
                <MultiLingualContent contentID="ASAP" />
                {/* {props.restaurant.id.toLowerCase() ===
 "vishnuji-ki-rasoi-festival-mela" ? <>7.30 PM PST to 12.30 AM PST </> : <> ASAP</>
 } */}
              </span>
            </div>
            <div className="pick-grp">
              <div className="pick-bg">
                <div className="pick"></div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="d-flex justify-content-between p-2">
            <Card.Text style={{ width: "100%", display: "flex" }}>
              <img
                src="/editIcon.svg"
                style={{ maxWidth: "25px", padding: "0 5px 0 0" }}
              />
              <textarea
                col="10"
                rows="1"
                onChange={notesChange}
 /* {this.state.notes} */ placeholder={order_notes}
                style={{
                  fontWeight: "600",
                  height: "auto",
                  padding: "10px",
                  width: "100%",
                  border: "0px solid #ccc",
                  fontSize: "16px",
                  resize: "vertical",
                }}
              ></textarea>
            </Card.Text>
          </Card.Body>
          {props.restaurant.is_tip ? (
            <Card.Body className="border-top" style={{ padding: "10px" }}>
              <div className="tab-group tips">
                <h6>
                  <MultiLingualContent contentID="Add Tip" />
                </h6>
                <ul id="tipslist">
                  <li onClick={tipsChange} className="" name="0">
                    <MultiLingualContent contentID="No Tip" />
                  </li>
                  <li onClick={tipsChange} className="" name="10">
                    10%
                  </li>
                  <li onClick={tipsChange} className="active" name="18">
                    18%
                  </li>
                  <li onClick={tipsChange} className="" name="20">
                    20%
                  </li>
                </ul>
              </div>
            </Card.Body>
          ) : (
            <></>
          )}

          <Card.Body className="d-flex font-weight-medium justify-content-between p-2">
            <Card.Text>
              <MultiLingualContent contentID="Summary" />
            </Card.Text>
          </Card.Body>
          <Card.Body className="d-flex justify-content-between p-2">
            <Card.Text style={{ fontSize: "15px", fontWeight: "500" }}>
              <img
                style={{
                  maxWidth: "22px",
                  marginRight: "8px",
                  verticalAlign: "sub",
                }}
                src="/clock.svg"
              />{" "}
              <MultiLingualContent contentID="ASAP" />
              {/* {props.restaurant.id.toLowerCase() ===
 "vishnuji-ki-rasoi-festival-mela" ? <>7.30 PM PST to 12.30 AM PST </> : <>Ready ASAP</>
 } */}
            </Card.Text>
          </Card.Body>
          <Card.Body className="d-flex justify-content-between p-2 border-top border-bottom pickup">
            <Card.Text style={{ fontSize: "15px", fontWeight: "500" }}>
              {props.restaurant.id.toLowerCase() ===
                "vishnuji-ki-rasoi-festival-mela" ? (
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/place/Marriott+Dallas+Allen+Hotel+%26+Convention+Center/@33.0920568,-96.6820153,15z/data=!4m8!3m7!1s0x0:0xbdae87adf7c0dafb!5m2!4m1!1i2!8m2!3d33.0920568!4d-96.6820153`}
                // href={`https://www.google.com/maps/search/?api=1&query=${
                // "Kay Bailey Hutchison Convention Center Dallas"
                // .replaceAll(" ", "+")
                // .replaceAll(".", "") +
                // "650 South Griffin Street"
                // .replaceAll(" ", "+")
                // .replaceAll(".", "") +
                // "Dallas"
                // }`}
                >
                  <img
                    style={{
                      maxWidth: "22px",
                      marginRight: "8px",
                      verticalAlign: "sub",
                    }}
                    src="/walking.svg"
                  />{" "}
                  <MultiLingualContent contentID="Pickup at" /> at Marriott
                  Dallas Allen Hotel &amp; Convention Center
                  {/* Pickup at Kay Bailey Hutchison Convention Center Dallas */}
                </a>
              ) : (
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${props.restaurant.address.address1 +
                    props.restaurant.address.address2 +
                    props.restaurant.address.city +
                    props.restaurant.address.country
                    }`}
                >
                  <img
                    style={{
                      maxWidth: "22px",
                      marginRight: "8px",
                      verticalAlign: "sub",
                    }}
                    src="/walking.svg"
                  />{" "}
                  <MultiLingualContent contentID="Pickup at" />{" "}
                  {capitalizeFirstLetter(props.restaurant.address.address1)}{" "}
                  {capitalizeFirstLetter(props.restaurant.address.city)}
                </a>
              )}
            </Card.Text>
          </Card.Body>
          {user_data == 1 && props.restaurant.isCoupon ? (
            <Card.Body className="coupon-field border-bottom p-2 pricing">
              <Card.Text>
                <MultiLingualContent contentID="Coupon" />:
              </Card.Text>
              <Card.Text>
                <Form>
                  <div style={{ display: "flex" }}>
                    <Form.Group className="mb-0" style={{ width: "100%" }}>
                      <Form.Control
                        onChange={couponChange}
                        type="text"
                        name="coupon_value"
                        placeholder="Enter Coupon code"
                      //value={coupon_value}
                      />
                    </Form.Group>
                    <div className="text-center">
                      <button
                        onClick={couponSubmit}
                        style={{ padding: "5px 10px" }}
                        className="btn btn-primary mb-0 ml-2"
                        type="button"
                      >
                        <MultiLingualContent contentID="Apply" />
                      </button>
                    </div>
                  </div>
                  {coupon_error ? (
                    <div style={{ padding: "5px 10px" }}>{coupon_error}</div>
                  ) : (
                    <></>
                  )}
                </Form>
              </Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}

          <Card.Body className="d-flex justify-content-between border-bottom p-2 pricing">
            <Card.Text>
              <MultiLingualContent contentID="Subtotal" />: {props.total}{" "}
              <MultiLingualContent contentID="items" />
            </Card.Text>
            <Card.Text>${parseFloat(props.totalPrice).toFixed(2)}</Card.Text>
          </Card.Body>
          {coupon_discount_amount ? (
            <Card.Body className="d-flex justify-content-between border-bottom p-2 pricing">
              <Card.Text>
                <MultiLingualContent contentID="Coupon Discount" />:
              </Card.Text>
              <Card.Text>
                -${parseFloat(coupon_discount_amount).toFixed(2)}
              </Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}

          {discountPercent ? (
            <Card.Body
              className="d-flex justify-content-between p-2 pricing"
              style={{ padding: "10px" }}
            >
              <Card.Text>{discountData.name}</Card.Text>
              {/* <Card.Text>10% Discount (Mela)</Card.Text> */}

              <Card.Text>
                - ${parseFloat(props.totalPrice * discountPercent).toFixed(2)}
              </Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}
          {props.restaurant.totalTax ? (
            <Card.Body className="d-flex justify-content-between p-2 pricing">
              <Card.Text>
                <MultiLingualContent contentID="Taxes" />
              </Card.Text>
              <Card.Text>
                {/* $ {tax} */}$
                {coupon_discount_amount
                  ? coupon_tax
                  : discountPercent
                    ? parseFloat(
                      props.restaurant.totalTax *
                      (props.totalPrice - props.totalPrice * discountPercent)
                    ).toFixed(2)
                    : parseFloat(
                      props.restaurant.totalTax * props.totalPrice
                    ).toFixed(2)}
              </Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}

          {props.tips !== "0.00" ? (
            <Card.Body
              className="d-flex justify-content-between p-2 pricing"
              style={{ padding: "10px" }}
            >
              <Card.Text>
                <MultiLingualContent contentID="Tips" />
              </Card.Text>

              <Card.Text>
                $
                {coupon_discount_amount
                  ? parseFloat(coupon_total * (tip / 100)).toFixed(2)
                  : discountPercent
                    ? parseFloat(
                      (
                        (props.totalPrice -
                          props.totalPrice * discountPercent) *
                        (tip / 100)
                      ).toFixed(2)
                    )
                    : parseFloat((tip / 100) * props.totalPrice).toFixed(2)}
              </Card.Text>
              {/* <Card.Text>${parseFloat(props.tips)}</Card.Text> */}
            </Card.Body>
          ) : (
            <></>
          )}

          <Card.Body className="d-flex font-weight-bold border-top justify-content-between p-2">
            <Card.Text>
              <MultiLingualContent contentID="Total" />
            </Card.Text>
            {!(props.restaurant.id === "naan-n-curry-bayarea-pl") ? (
              <Card.Text>
                $
                {/* {finalPrice} */}
                {coupon_discount_amount
                  ? parseFloat(
                    props.totalPrice -
                    coupon_discount_amount +
                    coupon_tax +
                    coupon_total * (tip / 100)
                  ).toFixed(2)
                  : discountPercent
                    ? parseFloat(
                      props.totalPrice -
                      props.totalPrice * discountPercent +
                      props.restaurant.totalTax *
                      (props.totalPrice -
                        props.totalPrice * discountPercent) +
                      parseFloat(
                        (
                          (props.totalPrice -
                            props.totalPrice * discountPercent) *
                          (tip / 100)
                        ).toFixed(2)
                      )
                    ).toFixed(2)
                    : parseFloat(
                      props.totalPrice +
                      props.restaurant.totalTax * props.totalPrice +
                      (props.totalPrice -
                        props.totalPrice * discountPercent) *
                      (tip / 100)
                    ).toFixed(2)}
              </Card.Text>
            ) : (
              <Card.Text>
                $
                {parseFloat(finalPrice).toFixed(2)}
              </Card.Text>
            )}
          </Card.Body>
          <Card.Footer className="paybtns"></Card.Footer>
          <Modal show={open} size="md" aria-labelledby="ordersucess" centered>
            <Modal.Body>
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  Your Order ID
                </p>
                <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                  {" "}
                  <b>{orderId}</b>
                </p>
                <p style={{ textAlign: "center" }}>
                  <MultiLingualContent contentID="Your order has been received. Your order will be ready in 10 minutes." />
                </p>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button style={{ marginBottom: "0" }} onClick={handleConinue}>
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>
      </div>
      <div className="paybtns">
        {!window.location.pathname.split("/")[2].includes("table") &&
          props.payOnline ? (
          <>

            <Link
              to={
                props.tablePath
                  ? `${props.tablePath}/pay-online`
                  : `/${props.restaurant.id}/pay-online`
              }
            >
              <Button
                variant="warning"
                block
                disabled={props.disabled}
                onClick={handlePayOnline}
              >
                <MultiLingualContent contentID={props.cart.payment_order_id ? 'Checkout' : "Pay Online"} />
              </Button>
            </Link>
          </>
        ) : (
          <></>
        )}
        {(props.payRestaurant && props.payOnline && !props.method && !props.cart.payment_order_id) ||
          props.restaurant.is_support ? (
          <div className="my-2 text-center">OR</div>
        ) : (
          <></>
        )}
        {props.tablePath.includes("table") ? (
          <div style={{ width: "100%" }}>
            <p
              style={{
                textAlign: "center",
                margin: "0px 0 6px 0",
                padding: " 12px",
                fontSize: "18px",
              }}
            >
              Choose Table
            </p>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={tables[0]}
              name="table"
              onChange={handleTableSelect}
              options={tables}
            />
            {tableStatus ? (
              <></>
            ) : (
              <>
                <div className="tab-group">
                  <ul
                    id="tipslist"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <li onClick={handleClose} id="clear" className="">
                      Clear Table
                    </li>
                  </ul>
                </div>
              </>
            )}
            {type !== "clear" ? (
              <>
                {" "}
                <Button
                  variant="warning"
                  block
                  style={{
                    margin: "12px 0px",
                  }}
                  onClick={handleUpdateOrder}
                >
                  {tableStatus ? "Place Order" : "Update Table"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="warning"
                  style={{
                    margin: "12px 0px",
                  }}
                  block
                  onClick={placeTable}
                >
                  {" "}

                  <MultiLingualContent contentID="Place Order" />
                </Button>
              </>
            )}
          </div>
        ) : (
          <>
            {/* {props.payRestaurant || props.restaurant.is_support ? ( */}
            {(props.payRestaurant || props.restaurant.is_support) &&
              !props.method && !props.cart.payment_order_id ? (
              <Link to={`/${props.restaurant.id}/pay-on-cash`}>
                <Button
                  variant="warning"
                  block
                  disabled={props.disabled}
                  onClick={() => {
                    props.CustomerData({
                      routeTo: `/${props.restaurant.id}/pay-on-cash`,
                    });
                    props.checkoutInit({
                      subTotal: props.totalPrice,
                      tip: {
                        value: Number(
                          parseFloat(
                            ((tip / 100) * props.totalPrice).toFixed(2)
                          )
                        ),
                        percentage: tip,
                      },
                      tax: {
                        value: Number(
                          parseFloat(
                            (
                              props.restaurant.totalTax * props.totalPrice
                            ).toFixed(2)
                          )
                        ),
                        percentage: props.restaurant.totalTax,
                      },
                      total: Number(
                        parseFloat(
                          props.totalPrice +
                          props.restaurant.totalTax * props.totalPrice +
                          parseFloat(props.tips)
                        ).toFixed(2)
                      ),
                    });
                  }}
                >
                  {" "}
                  {props.restaurant.id.toLowerCase() === "biryani-bowl-mela"
                    ? "Pay at Counter"
                    : "Pay at Restaurant"}
                </Button>
              </Link>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
    checkoutInit: (data) => {
      dispatch(checkoutInit(data));
    },
    Tip: (tip) => {
      dispatch(Tip(tip));
    },
    Notes: (notes) => {
      dispatch(Notes(notes));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    items: state.cart.items,
    customer: state.customer,
    tax: state.restaurant.totalTax,
    payOnline: state.restaurant.isPayonline,
    payRestaurant: state.restaurant.isPayRestaurant,
    tablePath: state.restaurant.tablePath,
    cart: state.cart,
    tips: state.cart.tips,
    orderCart: state.cart.orderCart,
    checkout: state.checkout,
    method: state.restaurant.method,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartTotalsCart);