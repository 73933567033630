import React, { useEffect, useState, Component } from "react";
import { getReceipt, joinObjSameKeyGname } from "../utils/api";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import * as HiIcons from "react-icons/hi";
const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
class Receipt extends Component {
  state = {
    data: {},
    loaded: false,
    default: false,
    resId: "",
    orderId: "",
    qrcode: "",
  };
  async componentDidMount() {
    const { restaurantId: resId, orderId, qrcode } = this.props;
    this.setState({
      resId,
      orderId,
      qrcode,
    });
    const data = await getReceipt(resId, orderId);
    if (data) {
      let newObj = { ...data };
      let { restaurant } = data;
      let itemarray = newObj.order.items;
      let newArray = [];
      // console.log(itemarray);
      if (restaurant.integrate !== "square") {
        let groupId = {};
        itemarray.forEach((item) => {
          let temp = {};
          let parse = JSON.parse(item.modifiers);
          let group = item.modifierGroups?.elements;
          if (group) {
            group.forEach((grup) => {
              // console.log(grup);
              groupId[grup.id] = grup.name;
              // groupId["name"] = grup.name;
              // console.log(groupId);
            });
          }
          if (parse) {
            let tempArr = [];
            let obijtemp = {};
            parse.forEach((ite) => {
              // console.log(ite);
              if (obijtemp[ite.modifier_group_id]) {
                obijtemp[ite.modifier_group_id]["modarr"].push(
                  ite.modifier_name
                );
              } else {
                obijtemp[ite.modifier_group_id] = {
                  gname: groupId[ite.modifier_group_id]?.toUpperCase() || "",
                  modarr: [ite.modifier_name],
                };
              }
              // tempArr.push(
              //   `${groupId[ite.modifier_group_id]} : ${ite.modifier_name}`
              // );
            });
            // console.log(obijtemp);
            // console.log(tempArr);
            let toJoinArr = [];
            Object.values(obijtemp).forEach((i) => {
              toJoinArr.push(`${i.gname} : ${i.modarr.join(", ")}`);
            });
            temp = { ...item, modifiers: obijtemp };
            // temp = { ...item, modifiers: toJoinArr.join(", ") };
            // temp = { ...item, modifiers: tempArr.join(", ") };
          } else {
            temp = { ...item };
          }
          // console.log(parse);
          newArray.push(temp);
        });
        newObj.order.items = newArray;
      } else {
        let groupId = {};
        itemarray.forEach((item) => {
          let temp = {};
          let parse = JSON.parse(item.modifiers);
          console.log(parse);

          if (parse && parse.length) {
            let tempArr = [];
            let obijtemp = {};
            parse.forEach((ite) => { });

            let modifiers = {};
            parse.forEach((ite) => {
              Object.values(ite).forEach((data, i) => {
                modifiers = {
                  ...modifiers,
                  [i]: {
                    gname: data.modifier_group_name || "Type",
                    modarr: [data.modifier_name],
                  },
                };
              });
            });
            modifiers = joinObjSameKeyGname(modifiers);
            temp = {
              ...item,
              modifiers,
            };
          } else {
            temp = { ...item, modifiers: {} };
          }
          newArray.push(temp);
        });
        newObj.order.items = newArray;
      }
      let order = newObj["order"];
      if (order["order_method"] === 'delivery') {
        order["delivery_address"] = JSON.parse(order["delivery_address"])
        let { state, city, zipcode, country, street } = order["delivery_address"];
        order["delivery_address"] = state && `${street}, ${city}, ${state}, ${zipcode}, ${country}.`;
      }
      this.setState({
        data: newObj,
        loaded: true,
      });
    } else {
      this.setState({ default: true });
    }
    const response = await fetch(`/qrcode/generate/${resId}/${orderId}`);
    const json = await response.json();
    console.log(json.data);
    if (response.status == 200) this.setState({ qrcode: json.data });
  }
  render() {
    const { order: orderData, restaurant: restaurantData } = this.state?.data;
    return (
      <>
        {this.state.loaded ? (
          <div
            id="invoice-POS"
            className="orderReceipt"
            style={{ width: "100%", marginTop: "2rem", maxWidth: "350px" }}
          >
            <center id="top">
              <div className="logo">
                <img
                  style={{ width: "30%" }}
                  src={restaurantData.logo}
                />
              </div>
              <div className="info">
                <h2>{restaurantData.name}</h2>
                <p>
                  {restaurantData.address1}{" "}
                  {restaurantData.address2}
                  <br />
                  {restaurantData.city},{" "}
                  {restaurantData.state},{" "}
                  {restaurantData.country},{" "}
                  {restaurantData.zip}
                  <br />
                  {restaurantData.phoneNumber}
                  <br />
                  {restaurantData.website ? (
                    restaurantData.website
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </center>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                }}
              >{`Name: ${capitalizeFirstLetter(
                orderData.customer_name
              )}`}</p>
              {orderData.mobile ? (
                <p
                  style={{
                    fontWeight: "600",
                  }}
                >{`Mobile: ${orderData.mobile}`}</p>
              ) : (
                <></>
              )}
            </div>
            <div
            >
              {orderData.order_method.toLowerCase() === 'delivery' ? (
                <>
                  <p
                    style={{
                      fontWeight: "600",
                      textAlign: 'center',
                      margin: 0
                    }}
                  >Delivery Address</p>
                <p
                  style={{
                    fontWeight: "600",
                      textAlign: 'center',
                      marginTop: '8px',
                  }}
                  >{orderData.delivery_address}</p>
                </>
              ) : (
                <></>
              )}
            </div>
            <div id="bot">
              <div id="table">
                <table>
                  <tbody>
                    <tr className="tabletitle heading1">
                      <td className="item">
                        <h2 style={{ textAlign: "left", padding: "0 5px" }}>
                          Item
                        </h2>
                      </td>
                      <td className="Hours">
                        <h2>Qty</h2>
                      </td>
                      <td className="Rate">
                        <h2 style={{ textAlign: "left" }}>Sub Total</h2>
                      </td>
                    </tr>
                    {orderData.items.map((item) => {
                      return (
                        <tr className="service1" key={item.id}>
                          {/* <td className="tableitem">
                           
                            <p className="itemtext">{item.name}</p>
                            {item.modifiers ? (
                              Object.values(item.modifiers).map((ite) => {
                                return (
                                  <>
                                    <div className="itemmodify">
                                      [
                                      {
                                        <>
                                          <span
                                            style={{ fontWeight: "600" }}
                                          >{` ${ite.gname}: `}</span>
                                          <span>{`${ite.modarr.join(
                                            ", "
                                          )} `}</span>
                                        </>
                                      }
                                      ]
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </td> */}
                          <td
                            className="tableitem"
                            style={{ width: "300px", textAlign: "left" }}
                          >
                            {/* {item.modifiers} */}
                            <p
                              style={{ fontSize: "16px" }}
                              className="itemtext"
                            >
                              <strong>{item.name}</strong>
                              {item.instructions ? (
                                <>
                                  <br />
                                  <span
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item.instructions}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </p>
                            {item.modifiers ? (
                              Object.values(item.modifiers).map((ite) => {
                                return (
                                  <>
                                    <div className="itemmodify">
                                      [
                                      {
                                        <>
                                          {Boolean(ite.gname) && <span
                                            style={{ fontWeight: "600" }}
                                          >{` ${ite.gname}: `}
                                          </span>}
                                          <span>{`${ite.modarr.join(
                                            ", "
                                          )} `}</span>
                                        </>
                                      }
                                      ]
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="tableitem">
                            <p
                              style={{ fontSize: "16px" }}
                              className="itemtext"
                            >
                              {item.quantity}
                            </p>
                          </td>
                          <td
                            className="tableitem"
                            style={{ textAlign: "left" }}
                          >
                            <p
                              className="itemtext"
                              style={{ fontSize: "16px" }}
                            >
                              {" "}
                              $ {parseFloat(item.totalPrice / 100).toFixed(2)}
                            </p>
                          </td>
                        </tr>
                      );
                    })}

                    <tr
                      className="tabletitle1"
                      style={{
                        paddingTop: "10px",
                        borderTop: "1px solid #eee",
                      }}
                    >
                      <td className="Rate">
                        <h2>Subtotal</h2>
                      </td>
                      <td></td>
                      <td className="payment">
                        <h2>
                          {" "}
                          ${" "}
                          {parseFloat(
                            orderData.subTotal / 100
                          ).toFixed(2)}
                        </h2>
                      </td>
                    </tr>

                    {orderData.discount ? (
                      <tr className="tabletitle1">
                        <td className="Rate">
                          <h2>Discount</h2>
                        </td>
                        <td></td>
                        <td className="payment">
                          <h2>
                            {" "}
                            - ${" "}
                            {parseFloat(
                              orderData.discount / 100
                            ).toFixed(2)}
                          </h2>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr className="tabletitle1">
                      <td className="Rate">
                        <h2>Total Tax</h2>
                      </td>
                      <td></td>
                      <td className="payment">
                        <h2>
                          {" "}
                          ${" "}
                          {parseFloat(orderData.tax / 100).toFixed(
                            2
                          )}
                        </h2>
                      </td>
                    </tr>
                    {orderData.tip ? (
                      <tr className="tabletitle1">
                        <td className="Rate">
                          <h2>Tips</h2>
                        </td>
                        <td></td>
                        <td className="payment">
                          <h2>
                            {" "}
                            ${" "}
                            {parseFloat(
                              orderData.tip / 100
                            ).toFixed(2)}
                          </h2>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {orderData.conv_fee ? (
                      <tr className="tabletitle1">
                        <td className="Rate">
                          <h2>Convenience fee</h2>
                        </td>
                        <td></td>
                        <td className="payment">
                          <h2>
                            {" "}
                            ${" "}
                            {parseFloat(
                              orderData.conv_fee / 100
                            ).toFixed(2)}
                          </h2>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}

                    <tr className="tabletitle1">
                      <td className="Rate">
                        <h2>Order Total</h2>
                      </td>
                      <td></td>
                      <td className="payment">
                        <h2>
                          {" "}
                          ${" "}
                          {parseFloat(
                            orderData.total / 100
                          ).toFixed(2)}
                        </h2>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    textAlign: "center",
                    marginTop: "14px",
                  }}
                >{`Order ID: ${this.state.orderId}`}</div>
                <p className="additional">
                  {orderData.notes ? (
                    <>
                      {" "}
                      <center>
                        <HiIcons.HiPencilAlt
                          style={{ marginRight: "6px", fontSize: "18px" }}
                        />
                        {orderData.notes.split(",|").filter(note => note.trim()).map(note => note.replace(/,/g, '')).join(",")}
                      </center>
                      {/* <>"{orderData.notes}"</> */}
                    </>
                  ) : (
                    <></>
                  )}{" "}
                  <br />
                  {this.state.qrcode ? (
                    <img
                      src={this.state.qrcode}
                      style={{
                        maxWidth: "150px",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  ) : (
                    <center>
                      Order Delivered ID: <strong>{this.state.orderId} </strong>
                    </center>
                  )}
                  <br />
                  {orderData.createdAt}
                  <br />
                  <span style={{ fontWeight: 600 }}>
                    {capitalizeFirstLetter(orderData.order_method)}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {this.state.default ? (
          <p>{`We couldn't find that receipt. The order id was ${this.state.orderId}`}</p>
        ) : (
          <></>
        )}
      </>
    );
  }
}
const QrcodeGen = () => {
  const { restaurantsId: restaurantId, orderId } = useParams();
  // const [qrcode, setQrcode] = useState("");
  // useEffect(() => {
  //   (async function () {
  //     const response = await fetch(
  //       `/qrcode/generate/${restaurantId}/${orderId}`
  //     );
  //     const json = await response.json();
  //     console.log(json.data);
  //     if (response.status == 200) setQrcode(json.data);
  //   })();
  // }, []);

  return (
    <>
      <Receipt {...{ restaurantId, orderId }} />
      {/* {qrcode ? (
        <>
          <div
            style={{
              display: "flex",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Container>
            
              <img
                src={qrcode}
                style={{
                  maxWidth: "150px",
                  display: "block",
                  margin: "0 auto",
                }}
              />
              <p
                style={{ textAlign: "center", margin: "0", padding: "10px 0" }}
              >
                Scan this QR with the restaurant
              </p>
            </Container>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>
              The Order ID <strong>{`${orderId}`}</strong> has been delivered
            </p>
          </div>
        </>
      )} */}
    </>
  );
};
export default QrcodeGen;