import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { joinObjSameKeyGname } from "../../utils/api";
import { Col, Container } from "react-bootstrap";
import moment from "moment-timezone";
// import axios from 'axios';
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../dashboard/pages/pages.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import { BiCreditCard } from "react-icons/bi";
import {
  FaMoneyBillAlt,
} from "react-icons/fa";
import { SiGooglepay, SiApple } from "react-icons/si";
import { getOrders, getTableOrders } from "../Functions/helper";
import { MdCancel } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";
import { GiWallet } from "react-icons/gi";
const payIcon = {
  google: <SiGooglepay style={{ fontSize: "38px", color: "#444" }} />,
  wallet: <GiWallet style={{ fontSize: "20px", color: "#444" }} />,
  apple: <SiApple style={{ fontSize: "22px", color: "#444" }} />,
  card: <BiCreditCard style={{ fontSize: "26px", color: "#444" }} />,
  cash: <FaMoneyBillAlt style={{ fontSize: "26px", color: "#666" }} />,
  null: <></>,
};

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
}
class Orders extends Component {
  state = {
    orders: [],
    tableOrders: [],
    pending: true,
    isOpen: false,
    loader: false,
    nodata: false,
    columns: [
      {
        name: "Date",
        cell: (row) => (
          <p className="order-date">
            {row.date}
            <br />
            {row.orderId} <br />
            {row.CustomerName && row.CustomerPhone ? (
              <>
                <span className="order-phone">{row.CustomerName}</span> <br />
                <span className="order-phone">
                  Phone :{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#000",
                      background: "none",
                    }}
                    href={`tel:${row.CustomerPhone}`}
                  >
                    {row.CustomerPhone}
                  </a>
                </span>
              </>
            ) : row.CustomerName ? (
              <>
                <span className="order-phone">{row.CustomerName}</span>{" "}
              </>
            ) : row.CustomerPhone ? (
              <>
                <span className="order-phone">
                  Phone :{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#000",
                      background: "none",
                    }}
                    href={`tel:${row.CustomerPhone}`}
                  >
                    {row.CustomerPhone}
                  </a>
                </span>
              </>
            ) : (
              <></>
            )}
          </p>
        ),
      },
      {
        name: "Restaurant",
        selector: (row) => (
          <div>
            {row.restaurant_name} <br />

          </div>
        ),
      },
      {
        name: "Line Items",
        cell: (row) => (
          <ul>
            {row.lineItem.map((item) => {
              return (
                <li key={item.id}>
                  <span
                    style={{
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.name}
                  </span>{" "}
                  <span style={{ fontSize: "12px" }}>x</span>{" "}
                  <span>{item.quantity}</span> <br />{" "}
                  {item.modifiers ? (
                    Object.values(item.modifiers).map((ite) => {
                      return (
                        <>
                          {console.log(ite)}
                          <div
                            className="itemmodify"
                            style={{ padding: "3px 0" }}
                          >
                            {
                              <>
                                <span

                                >
                                  {` ${ite.gname
                                    ?.replace(/choose /gi, "")
                                    ?.replace(/an /gi, "")
                                    ?.replace(/add /gi, "")
                                    ?.toLocaleLowerCase()}: `}
                                </span>
                                <span>{`${ite.modarr.join(", ")}, `}</span>
                              </>
                            }
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  <span style={{ paddingTop: "10px" }}>
                    $ {(item.totalPrice / 100).toFixed(2)}
                  </span>
                </li>
              );
            })}
          </ul>
        ),
      },
      {
        name: "Total",
        selector: (row) => (
          <div>
            Total: $ {parseFloat(row.total / 100).toFixed(2)}

          </div>
        ),
      },
      {
        name: "Status",
        cell: (row) => (
          <>
            <p>
              {row.payment === "Not Paid" ? (
                <span id="not-paid">Not Paid 👎</span>
              ) : (
                <span id="paid">
                  Paid <span>{payIcon[row.payment_type.toLowerCase()]}</span>
                </span>
              )}
            </p>
            <br />
            <div style={{ display: "block", width: "100%" }}>
            </div>
          </>
        ),
      },
      {
        name: "Details",
        cell: (row) => (
          <a onClick={() => this.openModal(row)} id={row.id}>
            Details
          </a>
        ),
      },
    ],
    tableColumns: [
      {
        name: "Table",
        cell: (row) => <p style={{ fontWeight: 600 }} className="order-date">{row.tableName}</p>,
      },
      {
        name: "Date",
        cell: (row) => (
          <p className="order-date">
            <span
              style={{ fontWeight: 600, marginBottom: "8px" }}
            >{`Order Id: ${row.tableOrderId}`}</span>{" "}
            <br />

            <span className="order-phone">
              {row.customerOrders.length} Customer(s)
            </span>

            {
              row.customerOrders.slice(0, 1).map(customer => {
                return (
                  <>
                    <br />
                    {customer.Name && customer.Mobile ? (
                      <>
                        {window.innerWidth > 767 ? <span className="order-phone">{customer.Name}</span> : <span className="order-phone">Name : {customer.Name}</span>}
                        <br />
                        <span className="order-phone">
                          Phone :{" "}
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#000",
                              background: "none",
                            }}
                            href={`tel:${customer.Mobile}`}
                          >
                            {customer.Mobile}
                          </a>
                        </span>
                      </>
                    ) : customer.Name ? (
                      <>
                        <span className="order-phone">{customer.Name}</span>{" "}
                      </>
                    ) : customer.Mobile ? (
                      <>
                        <span className="order-phone">
                          Phone :{" "}
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#000",
                              background: "none",
                            }}
                            href={`tel:${customer.Mobile}`}
                          >
                            {customer.Mobile}
                          </a>
                        </span>
                      </>
                    ) : (
                      <></>
                    )}

                  </>
                )
              })
            }
            <br />
            {window.innerWidth > 767 ? <span className="order-phone">
              {" "}
              {moment(row.date).format("YYYY-MM-DD hh:mm a")}{" "}
            </span> :
              <span className="order-phone">
                {" "}
                Date : {moment(row.date).format("YYYY-MM-DD hh:mm a")}{" "}
              </span>}
            <br />
          </p>
        ),
      },
      {
        name: "Line Items",
        cell: (row) => (
          <div>
            {window.innerWidth > 767 ?
              (<ul key={row.id}>
                {/* {
              row.customerOrders.map(customer => {
                return <> */}
                {
                  row.customerOrders.flatMap(item => item.lineItem).slice(0, 2).map((item, index) => {
                    return (
                      <li key={index}>
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {item.product_name}
                        </span>{" "}
                        <span style={{ fontSize: "12px" }}>x</span>{" "}
                        <span>{item.quantity}</span> <br />{" "}
                        {item.modifiers ? (
                          Object.values(item.modifiers).map((ite, indexMod) => {
                            return (
                              <>
                                <div
                                  key={indexMod}
                                  className="itemmodify"
                                  style={{ padding: "3px 0" }}
                                >
                                  {
                                    <>
                                      <span
                                      >{` ${ite.gname
                                        .replace(/choose /gi, "")
                                        .replace(/an /gi, "")
                                        .replace(/add /gi, "")
                                        .toLocaleLowerCase()}: `}</span>
                                      <span>{`${ite.modarr.join(", ")}, `}</span>
                                    </>
                                  }
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                        <span style={{ paddingTop: "10px" }}>
                          $ {(item.totalPrice / 100).toFixed(2)}
                        </span>
                      </li>
                    );
                  })
                }
                {/* </>
              })
            } */}

              </ul>) :
              (
                <ul>

                  {
                    row.customerOrders.flatMap(item => item.lineItem).slice(0, 2).map((item, index) => {
                      return (
                        <li key={item.id + index}>
                          {item.product_name} - <span style={{ paddingTop: "10px" }}>
                            $ {item.price / 100} * {item.quantity}
                          </span><br />{" "}
                          {item.modifiers ? (
                            Object.values(item.modifiers).map((ite, indexMod) => {
                              return (
                                <>
                                  <div
                                    key={indexMod}
                                    className="itemmodify"
                                    style={{ padding: "3px 0" }}
                                  >
                                    [
                                    {
                                      <>
                                        <span
                                          style={{
                                            fontWeight: "600",
                                            textTransform: "capitalize",
                                          }}
                                        >{` ${ite.gname
                                          .replace(/choose /gi, "")
                                          .replace(/an /gi, "")
                                          .replace(/add /gi, "")
                                          .toLocaleLowerCase()}: `}</span>
                                        <span>{`${ite.modarr.join(", ")} `}</span>
                                      </>
                                    }
                                    ]
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}

                        </li>
                      );
                    })
                  }
                </ul>)}
          </div>
        ),
      },
      {
        name: "Total",
        selector: (row) => (
          <div style={{ fontSize: '18px' }}>
            {/* Total: $ {parseFloat(row.customerOrders.reduce((acc, curr) => {
              return (acc = acc + curr.total);
            }, 0) / 100).toFixed(2)} */}
            Total: $ {parseFloat(row.total / 100).toFixed(2)}
          </div>
        ),
      },
      {
        name: "Status",
        cell: (row) => (
          <div>
            {
              window.innerWidth > 767 ?
                <>
                  <p>
                    {
                      row.split_bills ?
                        <>
                          <h5 className="split font-weight-bold">{row.split_bills == 1 ? "Split By Orders" : "Split By Members"}</h5>

                          <div className="d-flex1">
                            {
                              row.splitDetails.slice(0, 2).map((split, i) => {
                                return (
                                  <>

                                    {

                                      <h6 className="guest">Guest {i + 1} - $ {parseFloat(split.amount).toFixed(2)} - {split.payment_status === "Not Paid" ? (
                                        <span id="not-paid">Not Paid 👎</span>
                                      ) : (
                                        <span id="paid">
                                          Paid <span>{payIcon['cash']}</span>
                                        </span>
                                      )} </h6>

                                    }
                                  </>
                                )
                              })
                            }
                            {
                              row.splitDetails.length > 2 && "more..."
                            }
                          </div>
                        </> :
                        <>
                          {
                            row.customerOrders.filter(customer => !customer.payment.toLowerCase().includes('not paid')).length ?
                              <span id="paid">
                                Paid <span>{payIcon[row.customerOrders.filter(customer => customer.payment === 'Paid' && customer.payment_type).length ? row.customerOrders.filter(customer => customer.payment === 'Paid' && customer.payment_type)[0]['payment_type'] : 'cash']}</span>
                              </span>
                              :
                              <div style={{ textAlign: 'center' }}>
                                <span id="not-paid">Not Paid 👎</span><br />

                                {
                                  !row.order_merge ||
                                  <div className="table-select" style={{ position: 'relative', top: 10, right: 0 }}>
                                    <select
                                      onChange={(e) => this.changePaymentStatus(row, e)}
                                      style={{ width: '100%' }}
                                    >
                                      <option value="Not Paid">Not Paid</option>
                                      <option value="Paid">Paid</option>
                                    </select>
                                  </div>
                                }

                              </div>
                          }
                        </>
                    }

                  </p>

                  <div style={{ display: "block", width: "100%" }}>
                  </div>
                </> :
                <></>
            }
          </div>
        ),
      },
      // {
      //   name: "Details",
      //   cell: (row) => (
      //     <div className="detail-btn" style={{ width: '100%' }}>
      //       {/* <a onClick={() => this.openModal(row)} id={row.id}>
      //         More Details
      //       </a> */}
      //     </div>
      //   ),
      // },
    ]
  };

  openModal = async (row) => {
    this.setState({
      data: row,
      isOpen: true,
    });
  };
  closeModal = () => this.setState({ isOpen: false });

  async componentDidMount() {
    this.setState({ loader: true });
    const customerId = this.props.customer.id;
    const items = await getOrders(customerId);
    this.updateOrderState(items);
    // const tableItems = await getTableOrders(customerId);
    // this.updateTableOrderState(tableItems);
  }
  updateOrderState = async (items) => {
    try {
      this.setState({
        orders: [],
      });
      if (items.length) {
        items.forEach((item) => {
          var data = {};
          data["date"] = item["createdAt"];
          data["id"] = item["orderId"];
          data["orderId"] = `ID : ${item["orderId"]}`;
          data["subTotal"] = item["subTotal"];
          data["discount"] = item["discount"];
          data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
          data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
            ? `${formatPhoneNumber(item["Phone"])}`
            : "";
          data["total"] = `${item["total"]}`;
          data["message_status"] = item["message_status"]
            ? item["message_status"]
            : "";
          data["tip"] = item["tip"];
          data["restaurant_name"] = item["restaurant_name"];
          data["payment"] = `${item["payment"]}`;
          data["payment_type"] = `${item["payment_type"]}`;
          data["status"] = item["status"];
          data["link"] = `/r/${item["orderId"]}`;
          item["items"] = item["items"].map((it) => {
            let temp = { ...it };
            let parse = JSON.parse(it.modifiers);
            let group = it.modifierGroups?.elements;
            let groupId = {};
            if (
              ["custom", "clover"].includes(item['integrate'])
            ) {
              if (group) {
                group.forEach((grup) => {
                  groupId[grup.id] = grup.name;
                });
              }
              if (parse) {
                let tempArr = [];
                let obijtemp = {};
                parse.forEach((ite) => {
                  if (obijtemp[ite.modifier_group_id]) {
                    obijtemp[ite.modifier_group_id]["modarr"].push(
                      ite.modifier_name
                    );
                  } else {
                    if (groupId[ite.modifier_group_id]) {
                      obijtemp[ite.modifier_group_id] = {
                        gname: groupId[ite.modifier_group_id].toUpperCase(),
                        modarr: [ite.modifier_name],
                      };
                    }
                  }
                });
                temp = { ...it, modifiers: obijtemp };
              } else {
                temp = { ...it };
              }
            } else if (item['integrate'] === "square") {
              if (parse) {
                let obijtemp = {};
                Object.values(parse).forEach((ite) => {
                  if (obijtemp[ite.modifier_group_id]) {
                    obijtemp[ite.modifier_group_id]["modarr"].push(
                      ite.modifier_name
                    );
                  } else {
                    obijtemp[ite.modifier_group_id] = {
                      gname: ite.modifier_group_name || "Type",
                      modarr: [ite.modifier_name],
                    };
                  }
                });
                obijtemp = joinObjSameKeyGname(obijtemp);
                temp = { ...it, modifiers: obijtemp };

              } else {
                temp = { ...it, modifiers: {} };
              }
            }
            // console.log(temp);
            return temp;
          });
          data["lineItem"] = item["items"];
          data["tax"] = item["tax"];
          data["notes"] = item["notes"];
          this.setState({
            orders: [...this.state.orders, data],
            loader: false,
          });
        });
      } else {
        this.setState({
          orders: [],
          loader: false,
          nodata: true,
        });
        // setTimeout(() => this.setState({ loader: false }), 3000);
      }
      this.setState({
        pending: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateTableOrderState = async (items) => {
    try {
      this.setState({
        tableOrders: [],
      });
      if (items.length) {
        items.forEach((item) => {
          var data = {};
          data["date"] = item["createdAt"];
          data["tableName"] = item["tableName"];
          data["tableOrderId"] = item["tableTokenId"];
          data["split_bills"] = item["split_bills"];
          data['order_merge'] = item["order_merge"];
          data["splitDetails"] = item["splitDetails"];
          data["discount"] = item.discount
          data["tax"] = item.tax
          data["subTotal"] = item.subTotal
          data["tip"] = item.tip
          data["total"] = item.total
          data['integrate'] = item.integrate
          if (item.split_bills) {
            data["payment"] = item.splitDetails.length === item.splitDetails.filter(item => item.payment_status === 'Paid').length ? "Paid" : item.splitDetails.filter(item => item.payment_status === 'Paid').length ? "Partially Paid" : "Not Paid"
          } else {
            data["payment"] = item.customerOrders.filter(customer => !customer.payment.toLowerCase().includes('not paid')).length ? "Paid" : "Not Paid"
          }
          data["id"] = item["id"];
          data["tableId"] = item["tableId"];
          item['customerOrders'].map((order, i) => {
            item['customerOrders'][i]['lineItem'] = order['items'].map((ite, i) => {
              let temp = {};
              let parse = JSON.parse(ite.modifiers);
              if (parse) {
                let obijtemp = {};
                Object.values(parse).forEach((ite) => {
                  if (obijtemp[ite.modifier_group_id]) {
                    obijtemp[ite.modifier_group_id]["modarr"].push(
                      ite.modifier_name
                    );
                  } else {
                    obijtemp[ite.modifier_group_id] = {
                      gname: ite.modifier_group_name || "Type",
                      modarr: [ite.modifier_name],
                    };
                  }
                });
                obijtemp = joinObjSameKeyGname(obijtemp);
                temp = { ...ite, modifiers: obijtemp };

              } else {
                temp = { ...ite, modifiers: {} };
              }
              return temp
            })
          })
          data['customerOrders'] = item['customerOrders']
          this.setState({
            tableOrders: [...this.state.tableOrders, data],
          });
        });
      } else {
        this.setState({
          tableOrders: [],
          noTableData: true,
        });
      }

    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { columns, data } = this.state;
    const table = { columns, data };
    return (
      <>
        {this.state.loader ? (
          <div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                // minHeight: "100vh",
                transition: "0.5s ease-in-out 0s",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ScaleLoader size={12} color="#ec7211" />
            </div>
          </div>
        ) : this.state.nodata ? (
          <div style={{ textAlign: "center" }}>
            <h5 style={{ lineHeight: "1.5em", fontSize: "22px" }}>
              {/* Store Closed <br /> */}
              There are no online orders to display
            </h5>
          </div>
        ) : (
          <>
            <div className="table-group orders-dashboard orders-mobile">
              <Container style={{ minHeight: "auto", padding: "0" }} className="customer">
                <DataTableExtensions
                  columns={this.state.columns}
                  data={this.state.orders}
                  export={false}
                  // filter={false}
                  filterPlaceholder="Search Your Orders"
                  print={false}
                >
                  <DataTable
                    defaultSortField="id"
                    defaultSortAsc={true}
                    paginationPerPage={10}
                    highlightOnHover={true}
                    paginationRowsPerPageOptions={[
                      20, 30, 40, 50, 60, 70, 80, 90, 100,
                    ]}
                    columns={this.state.columns}
                    data={this.state.orders}
                    pagination={true}
                    export={false}
                    progressPending={this.state.pending}
                  />
                </DataTableExtensions>
              </Container>
              {/* <Container style={{ minHeight: "auto", padding: "0" }} className="customer">
                <DataTableExtensions
                  columns={this.state.tableColumns}
                  data={this.state.tableOrders}
                  export={false}
                  filterPlaceholder="Search Your Orders"
                  print={false}
                >
                  <DataTable
                    defaultSortField="id"
                    defaultSortAsc={true}
                    paginationPerPage={10}
                    highlightOnHover={true}
                    paginationRowsPerPageOptions={[
                      20, 30, 40, 50, 60, 70, 80, 90, 100,
                    ]}
                    columns={this.state.tableColumns}
                    data={this.state.tableOrders}
                    pagination={true}
                    export={false}
                    // progressPending={this.state.pending}
                  />
                </DataTableExtensions>
              </Container> */}
            </div>
          </>
        )}

        {this.state.isOpen ? (
          <>
            <Modal
              dialogClassName="receipt-model1"
              show={this.state.isOpen}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div id="invoice-POS" className="invoice-dashboard">

                  <div id="bot">
                    <div id="table">
                      <table>
                        <tbody>
                          <tr className="tablehead1">
                            <td>
                              <h2>Order Details</h2>
                            </td>

                          </tr>
                          {this.state.data.lineItem.map((item) => {
                            return (
                              <tr className="service" key={item.id}>
                                <td className="tableitem">
                                  <p className="itemtext">{item.name}</p>
                                  {item.modifiers ? (
                                    <>
                                      {Object.values(item.modifiers).map(
                                        (ite) => {
                                          return (
                                            <>
                                              <div
                                                className="itemmodify"
                                                style={{
                                                  textAlign: "left",
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {
                                                  <>
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >{` ${ite.gname
                                                      .replace(/choose /gi, "")
                                                      .replace(/an /gi, "")
                                                      .replace(/add /gi, "")
                                                      .toLocaleLowerCase()}: `}</span>
                                                    <span>{`${ite.modarr.join(
                                                      ", "
                                                    )} `}</span>
                                                  </>
                                                }
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td className="tableitem">
                                  <p className="itemtext">{item.quantity}</p>
                                </td>
                                <td className="tableitem">
                                  <p className="itemtext">
                                    {" "}
                                    $
                                    {parseFloat(item.totalPrice / 100).toFixed(
                                      2
                                    )}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}

                          <tr className="inv-title">
                            <td className="Rate1">
                              <h2>Subtotal</h2>
                            </td>
                            <td></td>
                            <td className="payment">
                              <h2>
                                {" "}
                                ${" "}
                                {parseFloat(
                                  this.state.data.subTotal / 100
                                ).toFixed(2)}
                              </h2>
                            </td>
                          </tr>
                          {this.state.data.discount ? (
                            <tr className="inv-title">
                              <td className="Rate1">
                                <h2>Discount</h2>
                              </td>
                              <td></td>
                              <td className="payment">
                                <h2>
                                  {" "}
                                  - ${" "}
                                  {parseFloat(
                                    this.state.data.discount / 100
                                  ).toFixed(2)}
                                </h2>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr className="inv-title">
                            <td className="Rate">
                              <h2>Total Tax</h2>
                            </td>
                            <td></td>
                            <td className="payment">
                              <h2>
                                {" "}
                                ${" "}
                                {parseFloat(this.state.data.tax / 100).toFixed(
                                  2
                                )}
                              </h2>
                            </td>
                          </tr>
                          <tr className="inv-title">
                            <td className="Rate">
                              <h2>Total Tip</h2>
                            </td>
                            <td></td>
                            <td className="payment">
                              <h2>
                                {" "}
                                ${" "}
                                {parseFloat(this.state.data.tip / 100).toFixed(
                                  2
                                )}
                              </h2>
                            </td>
                          </tr>
                          <tr className="inv-title">
                            <td className="Rate">
                              <h2>Order Total</h2>
                            </td>
                            <td></td>
                            <td className="payment">
                              <h2>
                                {" "}
                                ${" "}
                                {parseFloat(
                                  this.state.data.total / 100
                                ).toFixed(2)}
                              </h2>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Modal.Body>

            </Modal>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);