import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Button, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getCustomerAll } from "../Functions/fetch";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { async } from "q";
//import avatar from "../avatar.png";
class Customers extends Component {
  state = {
    data: [],
    loader: false,
    nodata: false,
    cmData: "",
    spl_discount_percentage: "",
    ermsg: "",
    customerCoupon: [],
    customerRef: [],
  };

  async componentDidMount() {
    this.getCustomerState();
    // this.setState({ loader: true });
    // const res = await getCustomerAll(this.props.restaurant_id);
    // if (res.length) {
    //   this.setState({ data: res });
    //   // console.log(res);
    //   setTimeout(() => this.setState({ loader: false }), 1000);
    // } else {
    //   this.setState({ nodata: true, loader: false });
    // }
  }
  openModal = async (customerData) => {
    console.log(11, customerData);
    let customerid = customerData.id;
    this.setState({
      isOpen: true,
      cmData: customerData,
    });
    fetch(
      `/api/v1/merchant/${this.props.restaurant.id}/${this.props.restaurant.integrate}/refercoupon/${customerid}`
    )
      .then((res) => res.json())
      .then((res) => {
        console.log();
        this.setState({ customerRef: res.data });
      });
    this.getCouponState(customerid);
    //console.log(12,this.state.cmData);
  };

  closeModal = () => {
    this.setState({ isOpen: false, ermsg: false });
    this.getCustomerState();
  };

  getCustomerState = async () => {
    this.setState({ loader: true });
    const res = await getCustomerAll(this.props.restaurant_id);
    if (res.length) {
      const customerDataSorted = res.sort(
        (a, b) => b.total_discount_points - a.total_discount_points
      );
      this.setState({ data: customerDataSorted });
      // console.log(res);
      setTimeout(() => this.setState({ loader: false }), 1000);
    } else {
      this.setState({ nodata: true, loader: false });
    }
  };
  getCouponState = (customerid) => {
    axios
      .post(
        `/api/integrations/get/customer_coupon/${this.props.restaurant_id}`,
        { customerid: customerid }
      )
      .then((res) => {
        this.setState({
          customerCoupon: res.data,
        });
        //console.log(70,res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  splCouponPercentChange = (e) => {
    this.setState({
      spl_discount_percentage: e.target.value,
    });
    // console.log(e.target.value);
  };
  splPercentageSubmit = (e) => {
    e.preventDefault();
    this.setState({});
    if (this.state.spl_discount_percentage != "") {
      // console.log(this.state);
      axios
        .post(
          `/api/integrations/get/spl_discount_percentage/${this.props.restaurant_id}`,
          {
            spl_discount_percentage: this.state.spl_discount_percentage,
            cmdetails: this.state.cmData,
          }
        )
        .then((res) => {
          this.setState({
            locationData: res.data,
            ermsg: res.data.msg,
            spl_discount_percentage: "",
          });
          setTimeout(() => {
            // document.getElementById("ermsg").style.display = "none";
            this.setState({ isOpen: false });
          }, 2000);
          //console.log(70,res.data.msg);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };
  render() {
    const { data } = this.state;
    return (
      <>
        {this.state.loader ? (
          <div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                // minHeight: "100vh",
                transition: "0.5s ease-in-out 0s",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ScaleLoader size={12} color="#ff9900" />
            </div>
          </div>
        ) : this.state.nodata ? (
          <div style={{ textAlign: "center" }}>
            <h5 style={{ lineHeight: "1.5em", fontSize: "22px" }}>
              {/* Store Closed <br /> */}
              There are no customer to display
            </h5>
          </div>
        ) : (
          <>
            <h3>
              <i class="fa fa-address-card-o"></i> Customers
            </h3>
            <Row>
              {data.length ? (
                Object.values(data).map((item, key) => {
                  return (
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      className="customer-nav"
                      key={key}
                    >
                      {/* {console.log(67,item)} */}
                      <Card
                        className="customer-items"
                        onClick={() => this.openModal(item)}
                      >
                        <Card.Body className="d-flex p-2">
                          <Card.Text style={{ margin: "0" }}>
                            <Card.Img
                              variant="top"
                              style={{ marginRight: "15px", width: "50px" }}
                              src={process.env.PUBLIC_URL + "/avatar.png"}
                            />
                          </Card.Text>
                          <Card.Text>
                            <Card.Title style={{ marginBottom: "5px" }}>
                              <i class="fa fa-user"></i> {item.name}
                            </Card.Title>
                            <Card.Title>
                              <i class="fa fa-phone"></i> {item.mobile}
                            </Card.Title>
                            {/*  <Card.Text className="mb-0 pb-0">
                          <i class="fa fa-envelope-o"></i> {item.email}
                        </Card.Text> */}
                          </Card.Text>
                          <Card.Text style={{ margin: "0" }}>
                            <Card.Title className="point">
                              <div>Points</div>
                              <div>{item.total_discount_points}</div>
                            </Card.Title>
                            {/* <Card.Title
                              className="point"
                          
                            >
                              {item.total_discount_points}
                            </Card.Title> */}
                          </Card.Text>
                          {/* <Card.Text
                            style={{ paddingLeft: "30px", textAlign: "center" }}
                          >
                            <Card.Title>Reward points</Card.Title>
                            <Card.Title>{item.discount_points}</Card.Title>
                          </Card.Text> */}
                        </Card.Body>
                        {/* <Card.Body className="py-31">
                        <Card.Img
                          variant="top"
                          style={{ width: "50px" }}
                          src={process.env.PUBLIC_URL + "/avatar.png"}
                        />
                        <Card.Title>
                          <i class="fa fa-user"></i> {item.name}
                        </Card.Title>
                        <Card.Text className="mb-0 pb-0">
                          <i class="fa fa-phone"></i> {item.mobile}
                        </Card.Text>
                        <Card.Text className="mb-0 pb-0">
                          <i class="fa fa-envelope-o"></i> {item.email}
                        </Card.Text>
                      </Card.Body> */}
                        {/* <Card.Footer>
                          <button>Show Details</button>
                        </Card.Footer> */}
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <></>
              )}
            </Row>
          </>
        )}
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Discount Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form className="pb-4">
                <div className="pb-2">Generate Special Coupon code</div>
                <div className="customer-popup">
                  <Form.Group className="mb-0">
                    <Form.Control
                      onChange={this.splCouponPercentChange}
                      value={this.state.spl_discount_percentage}
                      type="number"
                      name="spl_discount_percentage"
                      placeholder="Enter Discount Percentage"
                      required
                    />
                  </Form.Group>
                  <div className="text-center d-flex">
                    <button
                      onClick={this.splPercentageSubmit}
                      style={{ padding: "6px 10px" }}
                      className="btn btn-primary mb-0 ml-2 spl-btn"
                      type="button"
                    >
                      Generate Coupon
                    </button>
                  </div>
                </div>
                <div
                  id="ermsg"
                  style={{ color: "#ec7211", fontWeight: "bold" }}
                  className="text-center"
                >
                  {this.state.ermsg}
                </div>
              </Form>
            </div>
            <Row>
              <Col xs={6} sm={6} md={6} lg={5}>
                <div>Total Earned Points</div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={7}>
                <div>: {this.state.cmData.total_discount_points || 0}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={6} md={6} lg={5}>
                <div>Current Points</div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={7}>
                <div>: {this.state.cmData.discount_points || 0}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={6} md={6} lg={5}>
                <div>Coupon Code</div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={7}>
                {this.state.customerCoupon.length ? (
                  this.state.customerCoupon.map((item, key) => {
                    return (
                      <div key={key} className="coupon-code-list">
                        {item.coupon}
                      </div>
                    );
                  })
                ) : (
                  <div>: No Coupon Code</div>
                )}
              </Col>
            </Row>
            {this.state.customerRef.length ? (
              <Row>
                <Col xs={6} sm={6} md={6} lg={5}>
                  <div>Reference IDs</div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={7}>
                  {this.state.customerRef.map((item, key) => {
                    return (
                      <div key={key} className="coupon-refer-list">
                        {item.name}
                      </div>
                    );
                  })}
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    restaurant_id: state.customer.resId,
    customer: state.customer,
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(Customers);
