const initialState = {
  total: 0,
  totalPrice: 0,
  items: {},
  restaurantId: "",
  orderCart: [],
  tip: 0,
  tips: 0,
};
const _ = require("lodash");
export default (state = initialState, action) => {
  switch (action.type) {
    case "INIT":
      state = {
        ...state,
        items: action.data,
      };
      break;
    case "UPDATE":
      let totalprice = 0;
      let totalNum = 0;
      Object.entries(action.data).forEach((item) => {
        const itemIndex = item.at(1);
        if (itemIndex.num) {
          totalNum = totalNum + itemIndex.num;
          totalprice += itemIndex.price * itemIndex.num;
        }
      });
      state = {
        ...state,
        items: action.data,
        total: totalNum,
        totalPrice: totalprice,
      };
      break;
    case "LOC":
      state = {
        ...state,
        total: action.data.total,
        orderCart: action.data.orderCart,
        totalPrice: action.data.totalPrice,
        items: action.data.items,
        tips: parseFloat(action.data.totalPrice * (0 / 100)).toFixed(2),
      };
      break;
    case "UTILS":
      state = {
        ...state,
        ...action.data
      };
      break;
    case "ADD":
      let totalPrice = 0;
      let orderCart = [];
      let orderCartE = state.orderCart;
      let modPrice = 0;
      const productIdPair = {};
      Object.values(state.items).map(iteD => (productIdPair[iteD.productId] = iteD));
      Object.values(action.modifications).forEach((mod) => {
        modPrice += mod.modifier_price * action.num;
      });
      console.log(action)
      function addOrderCartNew() {
        console.log(`Add Item ProductId: ${action.productId}`)
        let insertObj = action.productId ? productIdPair[action.productId] : state.items[action.title];
        console.log('ordercar', insertObj)
        console.log("productIdPair", productIdPair)
        orderCart = [
          ...state.orderCart,
          {
            ...insertObj,
            num: action.num,
            instructions: action.instructions,
            modifiers: action.modifications,
            modifiersPrice: modPrice ? modPrice : 0,
          },
        ];
      }
      // let i = state.orderCart.findIndex((cart) => cart.title === action.title);
      let matchIndex = state.orderCart.map((cart, i) => {
        if (cart.title === action.title) {
          return i;
        } else {
          return -1;
        }
      });
      let matchIndexInit = matchIndex.filter((ite) => ite >= 0);
      matchIndex = matchIndexInit.filter((index) =>
        _.isEqual(orderCartE[index]?.modifiers, action.modifications)
      );
      let foundSameNote = matchIndexInit.filter(
        (index) => orderCartE[index].instructions === action.instructions
      );
      if (matchIndex.length) {
        let [index] = matchIndex;
        if (action.instructions) {
          addOrderCartNew();
        } else {
          if (!orderCartE[index].instructions) {
            orderCartE[index].num += action.num;
            orderCartE[index].modifiersPrice += modPrice;
            orderCart = [...orderCartE];
          } else {
            if (foundSameNote.length) {
              let [indexNote] = foundSameNote;
              orderCartE[indexNote].num += action.num;
              orderCartE[indexNote].modifiersPrice += modPrice;
              orderCart = [...orderCartE];
            } else {
              addOrderCartNew();
            }
          }
        }

        // matchIndex.forEach(index=>{
        //   let isModMatch = _.isEqual(orderCartE[index]?.modifiers, action.modifications);
        //   if(isModMatch){

        //   }else{
        //     addOrderCartNew()
        //   }
        //   // if (i >= 0 && isDiffer) {
        //   //   orderCartE[i].num = action.num;
        //   //   orderCart = [...orderCartE];
        //   // } else {
        //   //   addOrderCartNew()
        //   //   // orderCart = [
        //   //   //   ...state.orderCart,
        //   //   //   {
        //   //   //     ...state.items[action.title],
        //   //   //     num: action.num,
        //   //   //     instructions: action.instructions,
        //   //   //     modifiers: action.modifications,
        //   //   //     modifiersPrice: modPrice ? modPrice : 0,
        //   //   //   },
        //   //   // ];
        //   // }
        // })
      } else {
        addOrderCartNew();
      }

      if (Object.keys(action.modifications).length) {
        orderCart.forEach((item) => {
          totalPrice += item.price * item.num;
          Object.values(item.modifiers).forEach((mod) => {
            totalPrice += (mod.modifier_price / 100) * item.num;
          });
        });
      } else {
        orderCart.forEach((item) => {
          totalPrice += item.price * item.num;
          Object.values(item.modifiers).forEach((mod) => {
            totalPrice += (mod.modifier_price / 100) * item.num;
          });
        });
        // totalPrice = parseFloat((state.totalPrice - (state.items[action.title].price * state.items[action.title].num) + (state.items[action.title].price * action.num)).toFixed(2))
      }
      // console.log(totalPrice)
      state = {
        ...state,
        total: orderCart.length,
        totalPrice: totalPrice,
        items: {
          ...state.items,
          [action.title]: {
            ...state.items[action.title],
            instructions: action.instructions,
            num: action.num,
            modifiersPrice: modPrice ? modPrice : 0,
          },
        },
        orderCart,
        tips: parseFloat(totalPrice * (0 / 100)).toFixed(2),
        restaurantId: action.id,
        expire: new Date().getTime() + 30 * 60000,
      };

      localStorage.setItem(action.id, JSON.stringify(state));
      break;
    case "EDIT":
      let totalP = 0;
      let orderCartEdit = state.orderCart;
      let modP = 0;
      if (action.edit_type === "SUM") {
        orderCartEdit[action.index].num = orderCartEdit[action.index].num + 1;
      } else {
        if (orderCartEdit[action.index].num >= 2) {
          orderCartEdit[action.index].num = orderCartEdit[action.index].num - 1;
        }
      }
      Object.values(action.modifications).forEach((mod) => {
        modP += parseInt(mod.modifier_price) * orderCartEdit[action.index].num;
      });
      orderCartEdit[action.index].modifiersPrice = modP;
      if (Object.keys(action.modifications).length) {
        orderCartEdit.forEach((item) => {
          totalP += item.price * item.num;
          Object.values(item.modifiers).forEach((mod) => {
            totalP += (mod.modifier_price / 100) * item.num;
          });
        });
      } else {
        orderCartEdit.forEach((item) => {
          totalP += item.price * item.num;
          Object.values(item.modifiers).forEach((mod) => {
            totalP += (mod.modifier_price / 100) * item.num;
          });
        });
      }
      state = {
        ...state,
        totalPrice: totalP,
        items: {
          ...state.items,
          [action.title]: {
            ...state.items[action.title],
            // num: action.edit_type === 'SUM' ? action.num + 1 : action.num - 1,
            num: orderCartEdit[action.index].num,
            modifiersPrice: modP ? modP : 0,
          },
        },
        orderCart: orderCartEdit,
      };
      localStorage.setItem(action.id, JSON.stringify(state));
      break;
    case "TIP":
      state = {
        ...state,
        tip: action.tip,
        tips: parseFloat(state.totalPrice * (action.tip / 100)).toFixed(2),
      };
      break;
    case "NOTES":
      state = {
        ...state,
        notes: action.note.replace(/[^a-zA-Z0-9 ]/gm, ""),
      };
      break;
    case "ADDWITHMODIFIER":
      state = {
        ...state,
        items: {
          ...state.items,
          [action.title]: {
            ...state.items[action.title],
            modifiersId: action.modifierId,
            modifiersName: action.modifierName,
            modifiersPriceClicked: action.price,
          },
        },
      };
      break;
    case "SET":
      state = {
        ...state,
        total: state.total + action.num,
        // totalPrice: state.totalPrice + (state.items[action.title].price * action.num),
        // items: {
        //     ...state.items,
        //     [action.title]: {
        //         ...state.items[action.title],
        //         num: action.num
        //     }
        // }
      };
      break;
    case "SUB":
      let totalPrice1 = 0;
      if (state.items[action.title]["modifiersPrice"]) {
        totalPrice1 = parseFloat(
          (
            state.totalPrice -
            state.items[action.title].price * state.items[action.title].num -
            (state.items[action.title]["modifiersPrice"] / 100) * action.num
          ).toFixed(2)
        );
      } else {
        totalPrice1 = parseFloat(
          state.totalPrice - state.items[action.title].price * action.num
        );
      }
      state = {
        ...state,
        total: state.total - action.num,
        totalPrice: totalPrice1,
        items: {
          ...state.items,
          [action.title]: {
            ...state.items[action.title],
            num: state.items[action.title].num - action.num,
          },
        },
        tips: parseFloat(
          (state.totalPrice - state.items[action.title].price * action.num) *
          (0 / 100)
        ).toFixed(2),
        restaurantId: action.id,
        expire: new Date().getTime() + 30 * 60000,
      };
      localStorage.setItem(action.id, JSON.stringify(state));
      break;
    case "ZERO":
      let totalPrice2 = 0;
      let orderCart2 = state.orderCart;
      orderCart2.splice(action.index, 1);
      orderCart2.forEach((item) => {
        totalPrice2 += item.price * item.num;
        Object.values(item.modifiers).forEach((mod) => {
          totalPrice2 += (mod.modifier_price / 100) * item.num;
        });
      });

      state = {
        ...state,
        total: orderCart2.length,
        // total: state.total - state.items[action.title].num,
        totalPrice: totalPrice2,
        items: {
          ...state.items,
          [action.title]: {
            ...state.items[action.title],
            num: 0,
          },
        },
        orderCart: orderCart2,
        tips: parseFloat(
          Math.abs(
            (state.totalPrice -
              state.items[action.title].num * state.items[action.title].price) *
            (0 / 100)
          )
        ).toFixed(2),
        expire: new Date().getTime() + 30 * 60000,
      };
      localStorage.setItem(action.id, JSON.stringify(state));
      break;
    case "RESET":
      state = {
        items: state.initialItems,
      };
      break;

    default:
      break;
  }

  return state;
};