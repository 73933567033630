import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment-timezone";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import { toast } from "react-toastify";
import {
  Container,
  Button,
  Modal,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { async } from "q";
function ShortenUrl(props) {
  const [tableData, SetTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [urlEdit, setUrlEdit] = useState(false);
  const [editable, setEditable] = useState(true);
  const [longUrl, setLongUrl] = useState("");
  const [urlSelected, setUrlSelected] = useState({});
  useEffect(() => {
    (function () {
      return updateState();
    })();
  }, []);
  const updateState = async () => {
    const rawAjaxCall = await fetch(
      `/api/v1/merchant/get/shortenurl/${props.restaurant.id}/${props.restaurant.integrate}`
    );
    const jsonData = await rawAjaxCall.json();
    // {
    //     "restaurant_name": "foodparadise",
    //     "description": null,
    //     "shorten_code": "420B9BBD08",
    //     "shorten_url": "https://sandbox.phonedash.us/420B9BBD08",
    //     "long_url": "https://sandbox.phonedash.us/foodparadise/table?tableId=420B9BBD08",
    //     "created_time": 1670421431,
    //     "expire_time": null,
    //     "no_expire": 1
    // }
    if (!jsonData.data) {
      SetTableData([]);
      setPending(false);
      return 0;
    }
    const tableData = jsonData.data;
    const toSetTable = tableData.map((item) => {
      let currentTimesec = Math.floor(Date.now() / 1000);
      return {
        id: item.shorten_code,
        code: item.shorten_code,
        shortenUrl: item.shorten_url,
        longUrl: item.long_url,
        createdAt:
          item.created_time < currentTimesec
            ? item.created_time * 1000
            : item.created_time,
        expireTime: item.expire_time,
        noExpire: Boolean(item.no_expire),
      };
    });
    SetTableData(toSetTable);
    //   setTableActivated(Boolean(jsonData.tableActivated));
    //   setIsTabledisabled(!jsonData.tableActivated);
    setPending(false);
  };
  const columns = [
    {
      name: "SHORTEN URL",
      cell: (row) => (
        <div className="table-title">
          <a href={row.shortenUrl} target="_blank">
            {row.shortenUrl}
          </a>
        </div>
      ),
    },
    {
      name: "LONG URL",
      cell: (row) => (
        <div className="table-title">
          {" "}
          <a href={row.longUrl} target="_blank">
            {row.longUrl}
          </a>
        </div>
      ),
    },
    {
      name: "CREATED DATE",
      cell: (row) => (
        <div className="table-title">
          {moment(row.createdAt)
            .tz(props.restaurant.restaurant.timeZone)
            .format("YYYY-MM-DD hh:mm a")}
        </div>
      ),
    },
    {
      name: "EXPIRED DATE",
      cell: (row) => (
        <div className="table-title">
          {row.noExpire ? `NOT EXPIRE` : row.expireTime}
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <div className="table-title">
          <Button
            onClick={() => {
              setUrlSelected(row);
              setLongUrl(row.longUrl);
              setUrlEdit(true);
            }}
          >
            <BsIcons.BsPencil />
          </Button>
          {/* <Button>
            <MdIcons.MdDelete />
          </Button> */}
        </div>
      ),
    },
  ];
  const clearState = () => {
    setUrlSelected({});
    setLongUrl("");
    setUrlEdit(false);
    setEditable(true);
  };
  const updateUrl = async () => {
    const updateCall = await fetch(
      `/api/v1/merchant/update/shortenurl/${props.restaurant.id}/${props.restaurant.integrate}`,
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: { ...urlSelected, longUrl: longUrl } }),
      }
    );
    const response = await updateCall.json();
    if (response) {
      updateState();
      clearState();
      toast.success("Updated");
    }
  };
  return (
    <>
      {/* <DataTableExtensions
        columns={columns}
        data={tableData}
        export={false}
        filterPlaceholder="Search"
        print={false}
      > */}
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        columns={columns}
        data={tableData}
        // pagination
        progressPending={pending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
        // onChangePage={this.handlePageChange}
      />
      {/* </DataTableExtensions> */}
      {urlEdit ? (
        <Modal
          dialogClassName="del-model del-item"
          show={urlEdit}
          onHide={clearState}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {" "}
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                      <tr>
                        <td>ID</td>
                        <td>{urlSelected.id}</td>
                      </tr>
                      <tr>
                        <td>SHORTEN URL</td>
                        <td>{urlSelected.shortenUrl}</td>
                      </tr>
                      <tr>
                        <td>LONG URL</td>
                        <td>
                          <Form.Control
                            as="textarea"
                            aria-label="With textarea"
                            onChange={(e) => {
                              setLongUrl(e.target.value);
                            }}
                            value={longUrl}
                            // value={urlSelected.longUrl}
                            disabled={editable}
                          />
                          {editable ? (
                            <Button
                              onClick={() => {
                                setEditable(false);
                              }}
                            >
                              <BsIcons.BsPencil />
                            </Button>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          {!editable ? (
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={updateUrl}
                //   disabled={isDisabledAddTable}
              >
                Update
              </Button>
            </Modal.Footer>
          ) : (
            <></>
          )}
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
  };
};

export default connect(mapStateToProps)(ShortenUrl);
