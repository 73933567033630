import React, { Component, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CartTableCheckout from "../../components/table/CartTableCheckout";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import BrandingHeader from "../../components/BrandingHeader";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  addCustomerCard,
  addCustomerPhone,
  getPaymentType,
  postTablePayment,
  postNewPayTableOnline,
  updateCustomer,
  updateCustomerAddress,
  updateCustomerEmail,
  updateCustomerPhone,
} from "../../utils/api";
import { Set, Reset } from "../../redux/actions/cartActions";
import { round } from "../../utils/functions";
import { FaUserEdit } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { Button } from "react-bootstrap";
import CreditCardInput from "react-credit-card-input";
import { getOrderTypes, getCloverOrderType } from "../../utils/api";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentRequestButtonElement,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import MultiLingualContent from "../../context/multilingualContent";
import { translations } from "../../context/translations";
// const stripePromise = loadStripe('pk_live_51IvaDEB8IOuWU0mtRX2RnIxZ9d8ZiYc5I2WbV69olt2u9QuXQBrr8c3iV4PzPJPmXJQW1NQgzz62Qs2ak7fpSo2d00T69wBGPG');
// const stripePromise = loadStripe(
//   "pk_test_51IvaDEB8IOuWU0mtzQ6MfdLjqyD70SGdFfsMxJvTcrn1v3cMGjrGy79t5sY5XzLUqQtvRJqV6IgkgyP7BOFBsp8h00p1rMPUGP"
// );
const RequestPay = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [total, setTotal] = useState(
    parseFloat(
      (
        parseFloat(props.total) +
        parseFloat(props.tax) +
        parseFloat(props.tip)
      ).toFixed(2)
    )
  );
  const [modalShow, setModalShow] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [points, setPoints] = useState(0);
  const [name, setName] = useState("");
  const [res_id, setResId] = useState(props.res_id);
  useEffect(() => {
    async function fetchData() {
      if (stripe) {
        let amount = parseInt(total * 100);
        console.log(amount);
        const { checkoutData } = props;
        if (props.tableId) {
          // const orderDataRaw = await fetch(
          //   `/api/v1/table/get/table/order/data/${res_id}?tableId=${props.tableId}`
          // );
          // const orderData = await orderDataRaw.json();

          const {
            previous_subTotal: sub_merge,
            previous_tip: tip_merge,
            previous_tax: tax_merge,
            previous_total: total_merge,
          } = props.table;
          console.log(Number(total_merge));
          amount = parseInt(amount + Number(total_merge));
          console.log(amount);
        } else if (checkoutData.couponCode.percentage) {
          amount = Number((checkoutData.finalTotal * 100).toFixed(2));
        }
        console.log(amount);
        const pr = stripe.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: `Total`,
            amount: amount,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {
          pr.on("paymentmethod", async function (e) {
            //2
            setName(e.paymentMethod.billing_details.name);
            const paymentDetails = {
              payment_method: e.paymentMethod.id,
              shipping: {
                name: e.paymentMethod.billing_details.name,
                phone: e.paymentMethod.billing_details.phone
                  ? e.paymentMethod.billing_details.phone
                  : "",
                address: {
                  line1: `${e.paymentMethod.billing_details.address.line1
                    ? e.paymentMethod.billing_details.address.line1
                    : ""
                    }, ${e.paymentMethod.billing_details.address.line2
                      ? e.paymentMethod.billing_details.address.line2
                      : ""
                    }`,
                  city: e.paymentMethod.billing_details.address.city
                    ? e.paymentMethod.billing_details.address.city
                    : "",
                  country: e.paymentMethod.billing_details.address.country
                    ? e.paymentMethod.billing_details.address.country
                    : "",
                  state: e.paymentMethod.billing_details.address.state
                    ? e.paymentMethod.billing_details.address.state
                    : "",
                  postal_code: e.paymentMethod.billing_details.address
                    .postal_code
                    ? e.paymentMethod.billing_details.address.postal_code
                    : "",
                },
              },
            };
            const customer_data = {
              'name': e.paymentMethod.billing_details.name
                ? e.paymentMethod.billing_details.name
                : "",
              'phone': e.paymentMethod.billing_details.phone
                ? e.paymentMethod.billing_details.phone
                : "",
              'email': e.paymentMethod.billing_details.email
                ? e.paymentMethod.billing_details.email
                : ""
            }
            let response = await fetch(
              `/api/order/${res_id}/create-payment-intent`,
              {
                method: "POST",
                body: JSON.stringify({
                  paymentDetails,
                  customer_data,
                  amount: amount,
                }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            response = await response.json()
            if (response.error) {
              console.log(response.error);
              e.complete("fail");
            } else {
              // e.complete('success');
              let stripe_customer_id = response.customer
              const { error, paymentIntent } = await stripe.confirmCardPayment(
                response.client_secret
              );
              if (error) {
                console.log(error);
                return;
              }
              if (paymentIntent.status === "succeeded") {
                var data = {};
                data["stripe_customer_id"] = stripe_customer_id;
                data["name"] = e.paymentMethod.billing_details.name
                  ? e.paymentMethod.billing_details.name
                  : "";
                data["mobile"] = e.paymentMethod.billing_details.phone
                  ? e.paymentMethod.billing_details.phone
                  : "";
                data["email"] = e.paymentMethod.billing_details.email
                  ? e.paymentMethod.billing_details.email
                  : "";
                data["orderType"] = props.orderType;
                data["notes"] = props.notes ? props.notes : "";
                data["restaurant_id"] = res_id;
                data["customer_id"] = props.customer_id;
                data["address"] = `${e.paymentMethod.billing_details.address.line1
                  ? e.paymentMethod.billing_details.address.line1
                  : ""
                  }, ${e.paymentMethod.billing_details.address.line2
                    ? e.paymentMethod.billing_details.address.line2
                    : ""
                  }`;
                data["city"] = e.paymentMethod.billing_details.address.city
                  ? e.paymentMethod.billing_details.address.city
                  : "";
                data["country"] = e.paymentMethod.billing_details.address
                  .country
                  ? e.paymentMethod.billing_details.address.country
                  : "";
                data["state"] = e.paymentMethod.billing_details.address.state
                  ? e.paymentMethod.billing_details.address.state
                  : "";
                data["zip"] = e.paymentMethod.billing_details.address
                  .postal_code
                  ? e.paymentMethod.billing_details.address.postal_code
                  : "";
                if (e.walletName === "googlePay") {
                  data["payment"] = "google";
                  data["paymentId"] = props.paymentMethodIds["google"];
                } else if (e.walletName.toLowerCase().includes('apple')) {
                  data["payment"] = "apple";
                  data["paymentId"] = props.paymentMethodIds["apple"];
                } else {
                  data["payment"] = "wallet";
                  data["paymentId"] = props.paymentMethodIds["wallet"];
                }
                data["payment_id"] = e.paymentMethod.id;
                data["tableId"] = props.tableId;
                data["orderIds"] = props.orderIds;
                data["tableName"] = props.tableName;
                data["tableOrderId"] = props.tableOrderId;
                const response = await postNewPayTableOnline(
                  data,
                  props.items,
                  props.tip,
                  props.tax,
                  e.walletName,
                  props.connect,
                  checkoutData
                );
                if (!response) {
                  e.complete("fail");
                } else {
                  e.complete("success");
                  localStorage.removeItem(res_id);
                  if (props.tableId) {
                    window.location.href = `/${res_id}/table/thankyou/${props.tableId}?order_id=${response.tableOrderId}&language=${props.language}`;
                  } else {
                    setOrderId(response.id);
                    setPoints(response.points);
                    setModalShow(true);
                  }
                }
              } else {
                console.warn(
                  `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
                );
              }
            }
          });
          // pr.on('token', function (e) { // 1
          //   console.log(e)
          // });
          // pr.on('source', function (e) { //3
          //   console.log(e)
          // });
          pr.on("cancel", function () {
            alert("Payment Cancelled");
          });
          if (result) {
            setPaymentRequest(pr);
          }
        });
      }
    }
    fetchData();
  }, [stripe]);
  const handleConinue = () => {
    window.location.href = `/${props.res_id}`;
  };
  if (paymentRequest) {
    return (
      <>
        <PaymentRequestButtonElement
          className="payment-element"
          options={{ paymentRequest }}
        />{" "}
        <p className="payment-or">OR</p>
        <Modal
          show={modalShow}
          size="md"
          aria-labelledby="ordersucess"
          centered
        >
          <Modal.Body>
            <>
              <p
                style={{
                  textAlign: "center",
                  margin: "0px 0 6px 0",
                  paddingTop: " 12px",
                  fontSize: "18px",
                }}
              >
                {`Hi ${name}, Your Order ID`}
              </p>
              <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                {" "}
                <b>{orderId}</b>
              </p>
              <p style={{ textAlign: "center" }}>
                {/* {`Your order has been received. Your order will be ready for
                  pick up in ${props.deliverytime} minutes.`} */}
                {props.res_id.toLowerCase() === "roadhouse-48".toLowerCase()
                  ? `Your order has been received. Your order will be ready within 20-30 minutes.`
                  : props.tableId
                    ? `Your order has been received`
                    : `Your order has been received. Your order will be ready for
                pick up in ${props.deliverytime} minutes.`}
              </p>
              {points ? (
                <p style={{ textAlign: "center" }}>
                  {`You have earned ${points} points as a reward for this order.`}
                </p>
              ) : (
                <></>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ marginBottom: "0" }} onClick={handleConinue}>
              Continue
            </Button>
            {/* <Link
              style={{ marginBottom: "0" }}
              to={`/${props.res_id}/feedback`}
              state={orderId}
            >
              Continue
            </Link> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  // Use a traditional checkout form.
  return <></>;
};

class CloverPayTable extends Component {
  state = {
    customer_id: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    notes: "",
    cardNum: "",
    expiry: "",
    cvv: "",
    cardType: "",
    orderId: "",
    orderType: "",
    stripePromise: "",
    paymentMethod: {},
    modalShow: false,
    loader: false,
    type: "online",
    updated: true,
    isDisable: false,
    editPayment: false,
    edit: false,
    tableId: this.props.table.table_id,
    tableName: this.props.table.table_name,
    orderIds: this.props.table.table_orders_id,
    tableOrderId: this.props.table.table_order_id,
    amount: 0,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    // const orderDataRaw = await fetch(
    //   `/api/v1/table/get/table/order/data/${this.props.restaurant.id}?tableId=${this.props.table.table_id}`
    // );
    // const orderData = await orderDataRaw.json();
    // console.log(orderData);
    const {
      previous_subTotal: sub_merge,
      previous_tip: tip_merge,
      previous_tax: tax_merge,
      previous_total: total_merge,
    } = this.props.table;
    const toTax = this.props.restaurant.totalTax * this.props.totalPrice;

    const toFinalPrice =
      toTax + Number(this.props.tips) + Number(this.props.totalPrice);
    this.setState({
      notes: this.props.cart.notes ? this.props.cart.notes : "",
      amount: Math.round(total_merge + toFinalPrice * 100),
    });
    try {
      if (this.props.stripe_pk) {
        const stripePromise = await loadStripe(this.props.stripe_pk);
        this.setState({
          stripePromise,
        });
      }
      const paymentTypes = await getPaymentType(
        window.location.pathname.split("/")[1]
      );
      var paymentType = paymentTypes.filter(
        (type) =>
          type.label.toLowerCase().includes("google") ||
          type.label.toLowerCase().includes("apple") || type.label.toLowerCase().includes("wallet")
      );
      var paymentId = {};
      paymentType.forEach((typ) => {
        if (typ.label.toLowerCase().includes("google")) {
          paymentId["google"] = typ.id;
        }
        if (typ.label.toLowerCase().includes("apple")) {
          paymentId["apple"] = typ.id;
        } if (typ.label.toLowerCase().includes("wallet")) {
          paymentId["wallet"] = typ.id;
        }
      });
      this.setState({
        paymentMethod: paymentId,
      });
      // const orderTypes = await getOrderTypes(
      //   window.location.pathname.split("/")[1]
      // );
      let orderType;
      if (this.props.table.table_id) {
        const orderTypeId = await getCloverOrderType(
          this.props.restaurant.id,
          "table_order"
        );
        orderType = orderTypeId["id"];
        // if (this.props.restaurant.id.toLowerCase().includes("biryani-bowl")) {
        //   const orderTypeId = await getCloverOrderType(
        //     this.props.restaurant.id,
        //     "Simmer"
        //   );
        //   orderType = orderTypeId["id"];
        // } else {
        //   const orderTypeId = await getCloverOrderType(
        //     this.props.restaurant.id,
        //     "Table Order"
        //   );
        //   orderType = orderTypeId["id"];
        // }
      } else {
        const orderTypeId = await getCloverOrderType(
          this.props.restaurant.id,
          "online_order"
        );
        orderType = orderTypeId["id"];
      }
      //  else if (
      //   this.props.restaurant.id.toLowerCase().includes("biryani-bowl")
      // ) {
      //   // orderType = orderTypes.filter((type) =>
      //   //   type.label?.includes("Online Order Pickup")
      //   // );
      //   const orderTypeId = await getCloverOrderType(
      //     this.props.restaurant.id,
      //     "Online Order Pickup"
      //   );
      //   orderType = orderTypeId["id"];
      // } else {
      //   // orderType = orderTypes.filter((type) =>
      //   //   type.label?.includes("Curbside")
      //   // );
      //   const orderTypeId = await getCloverOrderType(
      //     this.props.restaurant.id,
      //     "Curbside"
      //   );
      //   orderType = orderTypeId["id"];
      // }
      // orderType = orderType.length
      //   ? orderType[orderType.length - 1]["id"]
      //   : orderTypes[0]["id"];
      this.setState({
        orderType,
      });
    } catch (e) {
      console.log(e);
    }
    this.setState({
      customer_id: this.props.customer.id ? this.props.customer.id : "",
      restaurant_id: this.props.restaurant.id ? this.props.restaurant.id : "",
      firstName: this.props.customer.firstName
        ? this.props.customer.firstName
        : "",
      lastName: this.props.customer.lastName
        ? this.props.customer.lastName
        : "",
      email:
        this.props.customer.emailAddresses &&
          this.props.customer.emailAddresses.elements[0]
          ? this.props.customer.emailAddresses.elements[0].emailAddress
          : "",
      mobile: this.props.customer.phone ? this.props.customer.phone : "",
      cardNum:
        this.props.customer.cards && this.props.customer.cards.elements[0]
          ? `${this.props.customer.cards.elements[0].first6}*****${this.props.customer.cards.elements[0].last4}`
          : "",
      // expiry:
      //   this.props.customer.cards && this.props.customer.cards.elements[0]
      //     ? this.props.customer.cards.elements[0].expirationDate
      //       .match(/.{1,2}/g)
      //       .join("/")
      //     : "",
      cardType:
        this.props.customer.cards && this.props.customer.cards.elements[0]
          ? this.props.customer.cards.elements[0].cardType
          : "",
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.target.style.boxShadow = "none";
  };
  handleEdit = (e) => {
    this.setState({
      updated: false,
      edit: true,
    });
  };

  handlePaymentEdit = (e) => {
    this.setState({
      updated: false,
      editPayment: true,
    });
  };

  updateUser = async (e) => {
    e.preventDefault();

    if (
      this.props.customer.phoneNumbers &&
      this.props.customer.phoneNumbers.elements[0]
    ) {
      await updateCustomerPhone(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.phoneNumbers.elements[0]["id"],
        this.state
      );
    } else {
      await addCustomerPhone(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.addresses.elements[0]["id"],
        this.state
      );
    }
    if (
      this.props.customer.emailAddresses &&
      this.props.customer.emailAddresses.elements[0]
    ) {
      await updateCustomerEmail(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.emailAddresses.elements[0]["id"],
        this.state
      );
    }
    await updateCustomer(
      this.state.restaurant_id,
      this.state.customer_id,
      this.state
    );
    this.setState({
      edit: false,
      updated: true,
    });
  };
  handlePaymentUpdate = async (e) => {
    e.preventDefault();
    // if (this.props.customer.cards && this.props.customer.cards.elements[0]) {
    //   await updateCustomerCard(this.state.restaurant_id, this.state.customer_id, this.props.customer.cards.elements[0]['id'], this.state.cardNum, this.state.expiry, this.state.cvv)
    // } else {
    await addCustomerCard(
      this.state.restaurant_id,
      this.state.customer_id,
      this.state.cardNum,
      this.state.expiry,
      this.state.cvv
    );
    // }
    this.setState({
      editPayment: false,
      updated: true,
    });
  };
  handleNewOrder = async (event) => {
    event.preventDefault();
    if (this.props.restaurant.integrate === "clover") {
      if (this.state.firstName.toLocaleLowerCase().includes("doordash")) {
        const orderTypes = await getOrderTypes(this.props.restaurant.id);
        var orderType = orderTypes.filter((type) =>
          type.label.toLocaleLowerCase().includes("doordash")
        );
        orderType.length && this.setState({ orderType: orderType[0].id });
      }
    }
    if (
      this.props.customer.id ||
      (this.state.firstName.length > 0 &&
        this.state.email.length > 0 &&
        this.state.mobile.length === 12 &&
        // this.state.address.length > 0 &&
        // this.state.country.length > 0 &&
        // this.state.city.length > 0 &&
        // this.state.state.length > 0 &&
        // this.state.zip.length > 0 &&
        this.state.cvv.length > 0 &&
        this.state.expiry.length > 0 &&
        this.state.cardNum.length > 0)
    ) {
      this.setState({ isDisable: true, loader: true });
      const res = await postTablePayment(
        this.state,
        this.props.orderCart,
        parseInt(this.props.tips.replace(".", "")),
        this.props.tip,
        this.props.tax,
        this.props.customer.connect,
        this.props.checkout
      );
      if (Object.keys(res).length) {
        localStorage.removeItem(this.state.restaurant_id);
        if (this.state.tableId) {
          window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&language=${this.props.restaurant.language}`;
        } else {
          this.setState({ orderId: res.id, points: res.points });
          this.setState({
            loader: false,
            modalShow: true,
          });
        }
      }
    } else {
      if (this.state.firstName === "") {
        document.getElementById("firstName").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.getElementById("firstName").focus();
      }
      if (this.state.mobile === "") {
        document.querySelector(".react-tel-input input").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.querySelector(".react-tel-input input").focus();
      }
      if (this.state.email === "") {
        document.getElementById("email").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        document.getElementById("email").focus();
      }
      if (this.state.mobile === "") {
        document.querySelector(".react-tel-input input").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.querySelector(".react-tel-input input").focus();
      }
      // if (this.state.email === "") {
      //   document.getElementById("email").style.boxShadow =
      //     "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
      //   document.getElementById("email").focus();
      // }
      if (
        this.state.cvv === "" ||
        this.state.expiry === "" ||
        this.state.cardNum === ""
      ) {
        document.getElementById("field-wrapper").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
      }
    }
  };
  handleBlur = (e) => {
    // e.target.style.boxShadow = "none";
  };
  handleConinue = () => {
    window.location.href = `/${this.props.restaurant.id}`;
    this.props.Reset();
  };
  translatedWord = (word) => translations[this.props.restaurant.language] ? translations[this.props.restaurant.language][word] : translations["en"][word]

  render() {
    const checkout = (
      <Row>
        {/* {console.log(this.state)} */}
        <Col xs={12} lg={6}>
          <div className="payment-button">
            {this.state.orderType && this.state.stripePromise ? (
              // &&
              // (this.props.restaurant.id === "naan-n-curry-bayarea-pl" ||
              //   this.props.restaurant.id === "foodparadise")
              <div style={{ width: "100%" }}>
                {" "}
                <Elements stripe={this.state.stripePromise}>
                  <RequestPay
                    notes={this.state.notes}
                    customer_id={this.props.customer.id}
                    paymentMethodIds={this.state.paymentMethod}
                    total={parseFloat(this.props.totalPrice).toFixed(2)}
                    orderType={this.state.orderType}
                    name={this.props.name}
                    res_id={this.props.restaurant.id}
                    items={this.props.orderCart}
                    tip={this.props.tips}
                    checkoutData={this.props.checkout}
                    tax={
                      parseFloat(this.props.totalPrice) *
                      parseFloat(this.props.tax)
                    }
                    deliverytime={this.props.restaurant.deliverytime}
                    connect={this.props.customer.connect}
                    tableId={this.props.table.table_id}
                    tableName={this.props.table.table_name}
                    tableOrderId={this.props.table.table_order_id}
                    orderIds={this.state.orderIds}
                    table={this.props.table}
                    language={this.props.restaurant.language}
                  />
                </Elements>
              </div>
            ) : (
              <></>
            )}
          </div>

          <Card className="mt-3">
            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span><MultiLingualContent contentID="Billing Details" /></span>
              {this.state.edit ? (
                <></>
              ) : this.state.customer_id ? (
                <>
                  <FaUserEdit
                    className="mr-1"
                    size="1.5em"
                    onClick={this.handleEdit}
                  />
                </>
              ) : (
                <></>
              )}
            </Card.Header>
            {!this.state.edit && this.props.customer.customerId ? (
              <Card.Body>
                <Card.Text>
                  {this.state.firstName} {this.state.lastName}
                </Card.Text>
                <Card.Text>{this.state.email}</Card.Text>
                <Card.Text>{this.state.mobile}</Card.Text>
              </Card.Body>
            ) : (
              <Form
                // onSubmit={this.handleSubmit}
                className="p-4"
              >
                <Form.Row>
                  <Form.Group as={Col} controlId="firstName">
                    <Form.Control
                      onChange={this.handleChange}
                      onFocus={this.handleBlur}
                      value={this.state.firstName}
                      placeholder="Name"
                      required
                    />
                  </Form.Group>

                  {/* <Form.Group as={Col} controlId="lastName">
                    <Form.Control
                      onChange={this.handleChange}
                      onFocus={this.handleBlur}
                      placeholder="Last Name"
                      value={this.state.lastName}
                      required
                    />
                  </Form.Group> */}
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Control
                      onFocus={this.handleBlur}
                      onChange={this.handleChange}
                      value={this.state.email}
                      type="email"
                      placeholder="Email"
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="mobile">
                    {/* <Form.Control
                      onChange={this.handleChange}
                      value={this.state.mobile}
                      type="text"
                      placeholder="Phone"
                      required
                    /> */}
                    {(!this.state.mobile.replace("+", "").length ||
                      !this.state.mobile.replace("+1", "").length) && (
                        <span
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            //   textAlign: "center",
                            fontWeight: "500",
                            lineHeight: "15px",
                            display: "inline-block",
                          }}
                        >
                          <MultiLingualContent contentID="Please enter the phone number to receive the order confirmation text." />
                        </span>
                      )}
                    <PhoneInput
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      disableDropdown={true}
                      country={"us"}
                      onFocus={this.handleBlur}
                      placeholder="Phone"
                      inputclassName="phoneinput"
                      value={this.state.mobile}
                      onChange={(mobile) => {
                        this.setState({ mobile: `+${mobile}` });
                      }}
                    />
                  </Form.Group>
                </Form.Row>

                {this.state.edit ? (
                  <Button
                    block
                    variant="warning"
                    type="submit"
                    disabled={this.validateFormAddress()}
                    onClick={this.updateUser}
                  >
                    Update
                  </Button>
                ) : (
                  <></>
                )}
              </Form>
            )}
          </Card>
          <Modal
            show={this.state.modalShow}
            size="md"
            aria-labelledby="ordersucess"
            centered
          >
            <Modal.Body>
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  Your Order ID
                </p>
                <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                  {" "}
                  <b>{this.state.orderId}</b>
                </p>
                <p style={{ textAlign: "center" }}>
                  {this.props.restaurant.id.toLowerCase() ===
                    "roadhouse-48".toLowerCase()
                    ? `Your order has been received. Your order will be ready within 20-30 minutes.`
                    : this.state.tableId
                      ? `Your order has been received`
                      : `Your order has been received. Your order will be ready for
                pick up in ${this.props.restaurant.deliverytime} minutes.`}
                  {/* {`Your order has been received. Your order will be ready for
                  pick up in ${this.props.restaurant.deliverytime} minutes.`} */}
                </p>
                {this.state.points ? (
                  <p style={{ textAlign: "center" }}>
                    {`You have earned ${this.state.points} points as a reward for this order.`}
                  </p>
                ) : (
                  <></>
                )}
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ marginBottom: "0" }}
                onClick={this.handleConinue}
              >
                Continue
              </Button>
              {/* <Link
                style={{ marginBottom: "0" }}
                to={`/${this.props.restaurant.id}/feedback`}
                state={this.state.orderId}
              >
                Continue
              </Link> */}
            </Modal.Footer>
          </Modal>
          <Card className="m">
            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span><MultiLingualContent contentID="Enter card details" /></span>
              {this.state.editPayment ? (
                <></>
              ) : this.state.customer_id ? (
                <>
                  {" "}
                  <FaUserEdit
                    className="mr-1"
                    size="1.5em"
                    onClick={this.handlePaymentEdit}
                  />
                </>
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body style={{ padding: "0" }}>
              {!this.state.editPayment &&
                Object.keys(this.props.customer).length &&
                this.props.customer.cards &&
                this.props.customer.cards.elements.length ? (
                <Card.Body>
                  <Card.Text>{this.state.cardNum}</Card.Text>
                  <Card.Text>Expiry : {this.state.expiry}</Card.Text>
                </Card.Body>
              ) : (
                <Form
                  className="payment"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CreditCardInput
                    cardNumberInputProps={{
                      value: this.state.cardNum,
                      onBlur: (e) =>
                      (document.getElementById(
                        "field-wrapper"
                      ).style.boxShadow = "none"),
                      onChange: (e) => {
                        this.setState({
                          cardNum: e.target.value,
                        });
                      },
                    }}
                    cardExpiryInputProps={{
                      value: this.state.expiry,
                      onBlur: (e) =>
                      (document.getElementById(
                        "field-wrapper"
                      ).style.boxShadow = "none"),
                      onChange: (e) => {
                        this.setState({
                          expiry: e.target.value,
                        });
                      },
                    }}
                    cardCVCInputProps={{
                      value: this.state.cvv,
                      onBlur: (e) =>
                      (document.getElementById(
                        "field-wrapper"
                      ).style.boxShadow = "none"),
                      onChange: (e) => {
                        this.setState({
                          cvv: e.target.value,
                        });
                      },
                    }}
                    fieldClassName="card-input form-control"
                  />
                  {/* {this.state.customer_id ? (
                    <span
                      style={{
                        marginLeft: "15px",
                        border: "1px solid #ced4da",
                        borderRadius: "3px",
                      }}
                    >
                      <MdChevronRight
                        size="2em"
                        onClick={this.handlePaymentUpdate}
                      />
                    </span>
                  ) : (
                    <></>
                  )} */}
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={5}>
          <CartTableCheckout
            handleNewOrder={this.handleNewOrder}
            disabled={this.state.isDisable}
            // disabled={!this.state.updated}
            buttonText={this.translatedWord("Place Order")}
            payment={true}
            total={this.props.total}
            totalPrice={round(this.props.totalPrice)}
            tablePayment={true}
          />
        </Col>
      </Row>
    );
    // eslint-disable-next-line
    const needToLogin = (
      <div className="text-center">
        <p>You must login or signup to checkout</p>
        <Link to={`/${this.props.restaurant.id}/login-signup`}>
          <Button variant="warning" className="mt-4">
            Login/Signup
          </Button>
        </Link>
      </div>
    );
    return (
      <>
        {/* <BrandingHeader />{" "} */}
        <div className="cart-header">
          <p>{this.props.restaurant.restaurant.name}</p>
          <button
            onClick={() => window.history.back()}
            style={{
              padding: "5px 10px",
              background: "none",
              border: "0",
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            <FontAwesomeIcon icon={faClose} size={"1x"} />
          </button>
        </div>
        <React.Fragment>
          {/* <CustomParallax title="Checkout" img={home_top} height={300} /> */}
          <Container
            className="my-auto Checkout"
            style={{ opacity: this.state.loader ? "0.4" : "1" }}
          >
            {checkout}
            {/* {this.props.loggedIn ? checkout : needToLogin} */}
          </Container>
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    total: state.cart.total,
    totalPrice: state.cart.totalPrice,
    tax: state.restaurant.totalTax,
    tip: state.cart.tip,
    orderCart: state.cart.orderCart,
    tips: state.cart.tips,
    restaurant: state.restaurant,
    name: state.restaurant.restaurant.name,
    stripe_pk: state.restaurant.restaurant.stripe_pk,
    customer: state.customer,
    tax: state.restaurant.totalTax,
    cart: state.cart,
    loggedIn: state.customer.isLoggedIn,
    checkout: state.checkout,
    table: state.table,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Set: (title, num) => {
      dispatch(Set(title, num));
    },
    Reset: (title, num) => {
      dispatch(Reset(title, num));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CloverPayTable);
