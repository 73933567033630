import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
function TranslateConfig(props) {

  const [spanish, setSpanish] = React.useState(0);
  const [chinese, setChinese] = React.useState(0);
  const [hindi, setHindi] = React.useState(0);
  const [italian, setItalian] = React.useState(0);
  const [tamil, setTamil] = React.useState(0);
  React.useEffect(() => {
    (() => {
      getRestaurantLanguageSettings();
    })();
  }, []);
  async function getRestaurantLanguageSettings() {
    try {
      let response = await fetch(
        `/api/v1/merchant/get/restaurant/online-order/language-support/settings/${props.restaurant.id}`
      );
      response = await response.json();
      if (response) {
        if (response.es) {
          setSpanish(1);
        } else {
          setSpanish(0);
        }
        if (response.hi) {
          setHindi(1);
        } else {
          setHindi(0);
        }
        if (response["zh-CN"]) {
          setChinese(1);
        } else {
          setChinese(0);
        }
        if (response["it"]) {
          setItalian(1);
        } else {
          setItalian(0);
        }
        if (response["ta"]) {
          setTamil(1);
        } else {
          setTamil(0);
        }
      } else {
      }
      // console.log(1,spanish,2,chinese,3,hindi);
    } catch (error) {
      console.log(error);
    }
  }

  async function updateRestaurantLanguageSettings(type) {
    try {
      if (type === "es") {
        setSpanish(prevState => !prevState)
      }
      if (type === "hi") {
        setHindi(prevState => !prevState)
      }
      if (type === "zh-CN") {
        setChinese(prevState => !prevState)
      }
      if (type === "ta") {
        setTamil(prevState => !prevState)
      }
      if (type === "it") {
        setItalian(prevState => !prevState)
      }
      // getRestaurantLanguageSettings();
      let response = await fetch(
        `/api/v1/merchant/fetch-language/${props.restaurant.id}/${type}/${props.restaurant.integrate}`
      );
      console.log(11, response);
      toast.success("Language updated");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <h4>Language Support</h4>
      <Card className="order-filter" style={{ boxShadow: "none" }}>
        <div className="coupon-container" style={{ paddingTop: "0" }}>
          <Form className="pb-4">
            {/* {props.restaurant.integrate && ( */}
            <Row className="align-items-center mt-4">
              <Col lg="5" md="5" sm="12">
                <div className="coupon-label">Spanish</div>
              </Col>
              <Col lg="7" md="7" sm="12">
                <div>
                  <Form.Group className="mb-0">
                    <Form.Check
                      type="switch"
                      onChange={(e) => updateRestaurantLanguageSettings("es")}
                      //checked={!!this.state.checked[user.SD_Emplid]}
                      id="custom-switch3"
                      label="OFF/ON"
                      // value = "es"
                      name="spanish"
                      checked={spanish}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col lg="5" md="5" sm="12">
                <div className="coupon-label">Chinese</div>
              </Col>
              <Col lg="7" md="7" sm="12">
                <div>
                  <Form.Group className="mb-0">
                    <Form.Check
                      type="switch"
                      onChange={() => updateRestaurantLanguageSettings("zh-CN")}
                      //checked={!!this.state.checked[user.SD_Emplid]}
                      id="custom-switch4"
                      label="OFF/ON"
                      // value = "es"
                      name="chinese"
                      checked={chinese}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col lg="5" md="5" sm="12">
                <div className="coupon-label">Italian</div>
              </Col>
              <Col lg="7" md="7" sm="12">
                <div>
                  <Form.Group className="mb-0">
                    <Form.Check
                      type="switch"
                      onChange={() => updateRestaurantLanguageSettings("it")}
                      //checked={!!this.state.checked[user.SD_Emplid]}
                      id="custom-switch5"
                      label="OFF/ON"
                      // value = "es"
                      name="Italian"
                      checked={italian}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col lg="5" md="5" sm="12">
                <div className="coupon-label">Hindi</div>
              </Col>
              <Col lg="7" md="7" sm="12">
                <div>
                  <Form.Group className="mb-0">
                    <Form.Check
                      type="switch"
                      onChange={() => updateRestaurantLanguageSettings("hi")}
                      //checked={!!this.state.checked[user.SD_Emplid]}
                      id="custom-switch6"
                      label="OFF/ON"
                      // value = "es"
                      name="Hindi"
                      checked={hindi}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col lg="5" md="5" sm="12">
                <div className="coupon-label">Tamil</div>
              </Col>
              <Col lg="7" md="7" sm="12">
                <div>
                  <Form.Group className="mb-0">
                    <Form.Check
                      type="switch"
                      onChange={() => updateRestaurantLanguageSettings("ta")}
                      //checked={!!this.state.checked[user.SD_Emplid]}
                      id="custom-switch7"
                      label="OFF/ON"
                      // value = "es"
                      name="Tamil"
                      checked={tamil}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            {/* )} */}
          </Form>
        </div>
      </Card>
    </>
  );
}

export default TranslateConfig;
