import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { Tip } from "../redux/actions/cartActions";
import MultiLingualContent from "../context/multilingualContent";

function CartTotalsCheckout(props) {
  const [tip, setTip] = useState(props.tip);
  const [path, setPath] = useState(window.location.pathname.split("/")[2]);
  const [subtotal, setsubtotal] = useState(props.totalPrice);
  const [tax, setTax] = useState(
    parseFloat((props.restaurant.totalTax * props.totalPrice).toFixed(2))
  );
  const [tips, settips] = useState(parseFloat(props.tips));
  const [disTips, setDisTips] = useState(0);
  const [conv_fee, setconv_fee] = useState(
    props.totalPrice * (props.restaurant.convenience_rate / 100)
  );
  const [discount, setDiscount] = useState({ isDiscount: 0 });
  const [discountPrice, setDiscountPrice] = useState();
  const [coupon_discount_amount, setcoupon_discount_amount] = useState(
    props.couponDiscount
  );
  const [discountData, setDiscountData] = useState({ name: "" });
  const [finalPrice, setFinalPrice] = useState(
    parseFloat(subtotal + tax + tips).toFixed(2)
  );
  if (
    props.restaurant.convenience_limit &&
    conv_fee > props.restaurant.convenience_limit
  ) {
    setconv_fee(props.restaurant.convenience_limit);
  }
  useEffect(() => {
    (async function () {
      const response = await fetch(
        `/api/v1/merchant/${props.restaurant.id}/${props.restaurant.integrate}/discount`
      );
      const json = await response.json();
      setDiscount(json);
      if (props.restaurant.convenience_fee && path !== "pay-on-cash") {
        let toTotal = subtotal + tax + tips;
        let calcFee = subtotal * (props.restaurant.convenience_rate / 100);
        let limitfee = props.restaurant.convenience_limit;
        if (props.restaurant.convenience_limit && calcFee > limitfee) {
          calcFee = limitfee;
        }
        setconv_fee(calcFee);
        setFinalPrice(parseFloat(toTotal + calcFee).toFixed(2));
      }
      console.log(55, props.checkout);
      if (props.checkout.finalTotal) {
        if (props.restaurant.convenience_fee && path !== "pay-on-cash") {
          const tosetTotal = props.checkout.finalTotal;
          const toConv_fee = Number((props.checkout.convFee).toFixed(2));
          setconv_fee(toConv_fee);
          const finallPrice = props.checkout.finalTotal + toConv_fee;
          setFinalPrice(parseFloat(finallPrice).toFixed(2));
        } else {
          // const subTotalDis = props.totalPrice - discountprice;
          // const toTip = subTotalDis * (props.tip / 100);
          // setFinalPrice(parseFloat(subTotalDis + toTax + toTip).toFixed(2));
          const finallPrice = props.checkout.finalTotal;
          setFinalPrice(parseFloat(finallPrice).toFixed(2));
        }
      } else if (json.isDiscount) {
        setDiscountData(json.data);
        const discountprice = parseFloat(
          props.totalPrice * (json.percentage / 100)
        );
        setDiscountPrice(discountprice);
        const discountMinusSubtotal = Number(
          props.totalPrice - discountprice.toFixed(2)
        );
        let toTax = Number(
          parseFloat(props.restaurant.totalTax * discountMinusSubtotal).toFixed(
            2
          )
        );
        setTax(toTax);
        const toTip = +parseFloat(
          discountMinusSubtotal * (props.tip / 100)
        ).toFixed(2);
        setDisTips(toTip);
        if (props.restaurant.convenience_fee && path !== "pay-on-cash") {
          const tosetTotal = +discountMinusSubtotal + toTax + toTip;
          const toConv_fee = Number(
            +discountMinusSubtotal * (props.restaurant.convenience_rate / 100).toFixed(2)
          );
          setconv_fee(toConv_fee);
          if (
            props.restaurant.convenience_limit &&
            toConv_fee > props.restaurant.convenience_limit
          ) {
            setconv_fee(props.restaurant.convenience_limit);
          }
          console.log(discountMinusSubtotal, toTip, toConv_fee, toTax);
          const finallPrice =
            discountMinusSubtotal + toTip + toConv_fee + toTax;
          setFinalPrice(parseFloat(finallPrice).toFixed(2));
        } else {
          // const subTotalDis = props.totalPrice - discountprice;
          // const toTip = subTotalDis * (props.tip / 100);
          // setFinalPrice(parseFloat(subTotalDis + toTax + toTip).toFixed(2));
          const finallPrice = discountMinusSubtotal + toTip + toTax;
          setFinalPrice(parseFloat(finallPrice).toFixed(2));
        }
      }
    })();
  }, []);
  const tipsChange = (e) => {
    const list = document.getElementById("tipslist");
    var items = list.getElementsByTagName("li");
    for (var i = 0; i < items.length; ++i) {
      if (items[i].getAttribute("name") === e.target.getAttribute("name")) {
        items[i].classList.add("active");
      } else {
        items[i].classList.remove("active");
      }
    }
    if (e.target.getAttribute("name") !== "custom") {
      setTip(parseInt(e.target.getAttribute("name")));
      props.Tip(parseInt(e.target.getAttribute("name")));
    }
  };
  const handleTipChange = (e) => {
    setTip(e.target.value);
    props.Tip(parseInt(e.target.value));
  };
  return (
    <div className="checkout-cont">
      {/* {console.log(props.tip)} */}
      <h4 className="my-auto" style={{ fontSize: "17px", fontWeight: "600" }}>
        <MultiLingualContent contentID="Order Summary" />
      </h4>
      <Card className="mt-3">
        <Card.Header className="pl-2 font-weight-bold">
          {props.total} <MultiLingualContent contentID="items" />
        </Card.Header>
        <Card.Body className="d-flex border-bottom justify-content-between p-2 ">
          <Card.Text>
            <MultiLingualContent contentID="Subtotal" />
          </Card.Text>
          <Card.Text>${parseFloat(subtotal).toFixed(2)}</Card.Text>
        </Card.Body>
        {discountPrice ? (
          <Card.Body
            className="d-flex justify-content-between p-2 pricing"
            style={{ padding: "10px" }}
          >
            <Card.Text>{discountData.name}</Card.Text>
            {/* <Card.Text>10% Discount (Mela)</Card.Text> */}
            <Card.Text>- ${parseFloat(discountPrice).toFixed(2)}</Card.Text>
          </Card.Body>
        ) : (
          <></>
        )}
        {props.checkout.discount.value ? (
          <Card.Body
            className="d-flex justify-content-between p-2 pricing"
            style={{ padding: "10px" }}
          >
            <Card.Text>
              <MultiLingualContent contentID="Coupon Applied" />
            </Card.Text>
            {/* <Card.Text>10% Discount (Mela)</Card.Text> */}
            <Card.Text>
              - ${parseFloat(props.checkout.discount.value).toFixed(2)}
            </Card.Text>
          </Card.Body>
        ) : (
          <></>
        )}
        {props.checkout.finalTax.value ? (
          <Card.Body className="d-flex justify-content-between p-2 pricing">
            <Card.Text>
              <MultiLingualContent contentID="Taxes" />
            </Card.Text>
            <Card.Text>
              ${parseFloat(props.checkout.finalTax.value).toFixed(2)}
            </Card.Text>
          </Card.Body>
        ) : tax ? (
          <Card.Body className="d-flex justify-content-between p-2 pricing">
            <Card.Text>
              <MultiLingualContent contentID="Taxes" />
            </Card.Text>
            <Card.Text>${parseFloat(tax).toFixed(2)}</Card.Text>
          </Card.Body>
        ) : (
          <></>
        )}

        {props.checkout.finalTip.value ? (
          <Card.Body
            className="d-flex justify-content-between p-2 pricing"
            style={{ padding: "10px" }}
          >
            <Card.Text>
              <MultiLingualContent contentID="Tips" />
            </Card.Text>
            <Card.Text>
              ${parseFloat(props.checkout.finalTip.value).toFixed(2)}
            </Card.Text>
          </Card.Body>
        ) : tips ? (
          <Card.Body
            className="d-flex justify-content-between p-2 pricing"
            style={{ padding: "10px" }}
          >
            <Card.Text>
              <MultiLingualContent contentID="Tips" />
            </Card.Text>
            <Card.Text>
              $
              {discountPrice
                ? parseFloat(((subtotal - discountPrice) * tip) / 100).toFixed(
                  2
                )
                : parseFloat(tips).toFixed(2)}
            </Card.Text>
            {/* <Card.Text>${tips}</Card.Text> */}
          </Card.Body>
        ) : (
          <></>
        )}

        {props.restaurant.convenience_fee && path !== "pay-on-cash" && !props.table.table_id ? (
          // {props.restaurant.convenience_fee ? (
          <Card.Body className="d-flex justify-content-between p-2">
            <Card.Text>
              {props.restaurant.convenience_limit > conv_fee ? ` Convenience Fee (${props.restaurant.convenience_rate}%)` : 'Convenience Fee'}
            </Card.Text>
            <Card.Text>${parseFloat(conv_fee).toFixed(2)}</Card.Text>
            {/* <Card.Text>
              ${" "}
              {props.totalPrice * (props.restaurant.convenience_rate / 100) <=
              props.restaurant.convenience_limit
                ? parseFloat(
                    props.totalPrice * (props.restaurant.convenience_rate / 100)
                  ).toFixed(2)
                : `1.0`}
            </Card.Text> */}
          </Card.Body>
        ) : (
          <></>
        )}
        <Card.Body className="d-flex justify-content-between p-2">
          <Card.Text>
            <MultiLingualContent contentID="Total" />
          </Card.Text>
          {props.restaurant.convenience_fee && path !== "pay-on-cash" ? (
            // <Card.Text>${parseFloat(finalPrice).toFixed(2)}</Card.Text>
            // {/* {props.restaurant.convenience_fee ? ( */}
            <Card.Text>
              $ {parseFloat(subtotal + tax + tips + conv_fee).toFixed(2)}
            </Card.Text>
          ) : (

            <Card.Text>
              $ {parseFloat(subtotal + tax + tips).toFixed(2)}

            </Card.Text>
          )}
        </Card.Body>
        <Card.Footer
          style={{ border: "0", background: "none", padding: "22px 0 0" }}
        >
          <Button
            style={{ width: "auto" }}
            onClick={props.handleNewOrder}
            variant="warning"
            block
            disabled={props.disabled}
          >
            {props.buttonText}
          </Button>

          {props.payment && (
            <>
              {" "}
              <hr />
              <h6 className="mt-3">
                <MultiLingualContent contentID="WE ACCEPT" />:
                <Image
                  className="img-thumbnail mt-1"
                  src="https://assets.asosservices.com/asos-finance/images/marketing/single.png"
                  alt="payment options"
                />
              </h6>
              {/* <p style={{ fontSize: "0.8em" }}>
                Got a coupon code? Add it in the next step
              </p>{" "} */}
            </>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    Tip: (tip) => {
      dispatch(Tip(tip));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    tip: state.cart.tip,
    tips: state.cart.tips,
    checkout: state.checkout,
    table: state.table,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartTotalsCheckout);