import { toast } from "react-toastify";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import moment from "moment-timezone";
export async function getCloverOrderType(
  restaurantId,
  orderMethod = "cash_order"
) {
  const res = await fetch(
    `/api/clover/orderType/${restaurantId}?orderMethod=${orderMethod}`
  );
  // `/api/clover/orderType/${restaurantId}?orderType=${orderType}&orderMethod=${orderMethod}`
  const json = await res.json();
  if (res.status === 200) {
    // console.log(json);
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function postTableOrder(
  data,
  items,
  tip_per,
  tipId,
  tax,
  connect,
  checkoutData
) {
  console.log("postTableOrder", tipId);
  let orderedItems = [];
  var url = "";
  let orderItemData = [];
  let total = 0;
  // Object.values(items)
  //   .filter((item) => item.num > 0)
  //   .map(function (item) {
  //     for (let i = 1; i <= item.num; i++) {
  //       let temp = { item: { id: "" } };
  //       temp["item"]["id"] = item.productId;
  //       temp["name"] = item.title;
  //       temp["note"] = "";
  //       temp["price"] = item.price * 100;
  //       temp["tags"] = item.tags.elements.map((item) =>
  //         _.pick(item, ["id", "name", "showInReporting"])
  //       );
  //       // if (item.modifier) {
  //       //   temp['modifications'] = [
  //       //     {
  //       //       "modifier": {
  //       //         "id": item.modifier
  //       //       }
  //       //     }
  //       //   ]
  //       // }
  //       orderedItems.push(temp);
  //     }
  //     return 1;
  //   });
  console.log(items);
  items.map(function (item) {
    for (let i = 1; i <= item.num; i++) {
      let temp = { item: { id: "" } };
      temp["item"]["id"] = item.productId;
      temp["name"] = item.title;
      temp["note"] = "";
      temp["note"] = item.instructions;

      temp["price"] = item.price * 100;
      temp["tags"] = item.tags.elements.map((item) =>
        _.pick(item, ["id", "name", "showInReporting"])
      );
      if (Object.values(item.modifiers).length) {
        temp["modifications"] = Object.values(item.modifiers).map(
          (modifier) => {
            return {
              modifier: {
                available: "true",
                id: modifier.modifier_id,
              },
              name: modifier.modifier_name,
              amount: modifier.modifier_price,
            };
          }
        );
      }
      var it = {};
      it["name"] = item["title"];
      it["productId"] = item["productId"];
      it["price"] = item["price"] * 100;
      it["note"] = item.instructions;
      it["quantity"] = item["num"];
      it["modifiers"] = item["modifiers"];
      it["totalPrice"] = it["price"] * it["quantity"];
      total += it["price"] * it["quantity"];
      orderItemData = [...orderItemData, it];
      orderedItems.push(temp);
    }
    return 1;
  });
  if (data.tableOrder) {
    url = `/api/order/${data.restaurant_id}/new/table/${data.table}`;
  } else {
    url = `/api/order/${data.restaurant_id}/new/cash`;
  }
  // console.log(url);

  // Object.values(items)
  //   .filter((item) => item.num > 0)
  //   .map(function (item) {
  //     var it = {};
  //     it["name"] = item["title"];
  //     it["productId"] = item["productId"];
  //     it["price"] = item["price"] * 100;
  //     it["quantity"] = item["num"];
  //     it["totalPrice"] = it["price"] * it["quantity"];
  //     total += it["price"] * it["quantity"];
  //     orderItemData = [...orderItemData, it];
  //   });
  // data.notes
  let notes =
    data.firstName && data.mobile
      ? `Name : ${data.firstName},\nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
      }\nPayment : Not Paid`
      : data.firstName
        ? `Name : ${data.firstName},${data.notes ? `\nNotes : ${data.notes}` : ""
        }\nPayment : Not Paid`
        : data.mobile
          ? `Phone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
          }\nPayment : Not Paid`
          : "";

  let payload = {
    customer: {
      phoneNumbers: [
        {
          phoneNumber: data.mobile ? data.mobile : "",
        },
      ],
      firstName: data.firstName,
      lastName: "",
    },
    orderCart: {
      orderCart: {
        // discounts: [
        //   {
        //     name: "Coupon Discount",
        //     amount: -Number(checkoutData.discount.value) * 100,
        //   },
        // ],
        serviceCharge: {
          name: "Tip",
          percentageDecimal: tip_per * 10000,
          enabled: true,
          id: tipId,
        },
        lineItems: orderedItems,
        orderType: {
          id: data.orderType,
          taxable: "true",
        },
        currency: "USD",
        note: notes,
        groupLineItems: "true",
      },
    },
    customer_id: data.customer_id,
    tips: tip_per,
    tax: tax,
    item: orderItemData,
    notes: data.notes,
    subTotal: total,
    connect: connect || "",
    checkoutData: checkoutData,
  };
  if (checkoutData.discount.value) {
    payload.orderCart.orderCart["discounts"] = [
      {
        name: "Coupon Discount",
        amount: -Number(checkoutData.discount.value) * 100,
      },
    ];
  }
  if (data.email) {
    payload["customer"] = {
      ...payload["customer"],
      emailAddresses: [
        {
          emailAddress: data.email,
          primaryEmail: true,
        },
      ],
    };
  }
  console.log(payload);
  let res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function postTableOrderV1(
  data,
  items,
  tip_per,
  tipId,
  tax,
  connect,
  checkoutData
) {
  console.log("postTableOrder", tipId);
  let orderedItems = [];
  var url = "";
  let orderItemData = [];
  let total = 0;
  // Object.values(items)
  //   .filter((item) => item.num > 0)
  //   .map(function (item) {
  //     for (let i = 1; i <= item.num; i++) {
  //       let temp = { item: { id: "" } };
  //       temp["item"]["id"] = item.productId;
  //       temp["name"] = item.title;
  //       temp["note"] = "";
  //       temp["price"] = item.price * 100;
  //       temp["tags"] = item.tags.elements.map((item) =>
  //         _.pick(item, ["id", "name", "showInReporting"])
  //       );
  //       // if (item.modifier) {
  //       //   temp['modifications'] = [
  //       //     {
  //       //       "modifier": {
  //       //         "id": item.modifier
  //       //       }
  //       //     }
  //       //   ]
  //       // }
  //       orderedItems.push(temp);
  //     }
  //     return 1;
  //   });
  console.log(items);
  items.map(function (item) {
    for (let i = 1; i <= item.num; i++) {
      let temp = { item: { id: "" } };
      temp["item"]["id"] = item.productId;
      temp["name"] = item.title;
      temp["note"] = "";
      temp["note"] = item.instructions;
      temp["price"] = item.price * 100;
      temp["tags"] = item.tags.elements.map((item) =>
        _.pick(item, ["id", "name", "showInReporting"])
      );
      if (Object.values(item.modifiers).length) {
        temp["modifications"] = Object.values(item.modifiers).map(
          (modifier) => {
            return {
              modifier: {
                available: "true",
                id: modifier.modifier_id,
              },
              name: modifier.modifier_name,
              amount: modifier.modifier_price,
            };
          }
        );
      }
      var it = {};
      it["name"] = item["title"];
      it["productId"] = item["productId"];
      it["price"] = item["price"] * 100;
      it["note"] = item.instructions;
      it["quantity"] = item["num"];
      it["modifiers"] = item["modifiers"];
      it["totalPrice"] = it["price"] * it["quantity"];
      total += it["price"] * it["quantity"];
      orderItemData = [...orderItemData, it];
      orderedItems.push(temp);
    }
    return 1;
  });
  if (data.tableOrder) {
    url = `/api/order/${data.restaurant_id}/new/table/${data.table}`;
  } else {
    url = `/api/order/${data.restaurant_id}/new/cash`;
  }
  // console.log(url);

  // Object.values(items)
  //   .filter((item) => item.num > 0)
  //   .map(function (item) {
  //     var it = {};
  //     it["name"] = item["title"];
  //     it["productId"] = item["productId"];
  //     it["price"] = item["price"] * 100;
  //     it["quantity"] = item["num"];
  //     it["totalPrice"] = it["price"] * it["quantity"];
  //     total += it["price"] * it["quantity"];
  //     orderItemData = [...orderItemData, it];
  //   });
  // data.notes
  let notes =
    data.firstName && data.mobile
      ? `Name : ${data.firstName},\nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
      }\nPayment : Not Paid`
      : data.firstName
        ? `Name : ${data.firstName},${data.notes ? `\nNotes : ${data.notes}` : ""
        }\nPayment : Not Paid`
        : data.mobile
          ? `Phone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
          }\nPayment : Not Paid`
          : "";

  let payload = {
    customer: {
      phoneNumbers: [
        {
          phoneNumber: data.mobile ? data.mobile : "",
        },
      ],
      firstName: data.firstName,
      lastName: "",
    },
    orderCart: {
      orderCart: {
        // discounts: [
        //   {
        //     name: "Coupon Discount",
        //     amount: -Number(checkoutData.discount.value) * 100,
        //   },
        // ],
        serviceCharge: {
          name: "Tip",
          percentageDecimal: tip_per * 10000,
          enabled: true,
          id: tipId,
        },
        lineItems: orderedItems,
        orderType: {
          id: data.orderType,
          taxable: "true",
        },
        currency: "USD",
        note: notes,
        groupLineItems: "true",
      },
    },
    customer_id: data.customer_id,
    tips: tip_per,
    tax: tax,
    item: orderItemData,
    notes: data.notes,
    subTotal: total,
    connect: connect || "",
    checkoutData: checkoutData,
  };
  if (checkoutData.discount.value) {
    payload.orderCart.orderCart["discounts"] = [
      {
        name: "Coupon Discount",
        amount: -Number(checkoutData.discount.value) * 100,
      },
    ];
  }
  if (data.email) {
    payload["customer"] = {
      ...payload["customer"],
      emailAddresses: [
        {
          emailAddress: data.email,
          primaryEmail: true,
        },
      ],
    };
  }
  console.log(payload);
  let res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function squareOrderOnline(
  data,
  items,
  tip,
  tax,
  payment,
  action,
  rate,
  limit,
  connect,
  discountPercent,
  discountData,
  custom,
  notes,
  checkoutData,
  language
) {
  let orderedItems = [];
  let order_items = [];
  let total = 0;
  let insertOrderItems = [];
  items.map(function (item) {
    var it = {};
    var temp = {};

    let order_items_data = {}
    order_items_data["name"] = item["title"];
    order_items_data["productId"] = item["productId"];
    order_items_data["price"] = item["price"] * 100;
    order_items_data["quantity"] = item["num"];
    order_items_data["modifiers"] = item["modifiers"];
    order_items_data["note"] = item.instructions;

    console.log("mod", item);
    it["quantity"] = item["num"].toString();
    it["basePriceMoney"] = { amount: item["price"] * 100, currency: "USD" };
    let modifier_price = 0;
    if (Object.values(item["modifiers"]).length) {
      temp["modifiers"] = JSON.stringify([item["modifiers"]]);
      it["modifiers"] = Object.values(item["modifiers"]).map((modifier) => {
        if (Number(modifier.modifier_price)) {
          modifier_price += Number(modifier.modifier_price) * item["num"];
        }
        return {
          catalogObjectId: modifier.modifier_id,
        };
      });
      temp["totalPrice"] = item["price"] * 100 * item["num"] + modifier_price;
      total = total + item["price"] * 100 * item["num"] + modifier_price;
      order_items_data["totalPrice"] = item["price"] * 100 * item["num"] + modifier_price;
    } else {
      temp["modifiers"] = JSON.stringify([]);
      temp["totalPrice"] = item["price"] * 100 * item["num"];
      total = total + item["price"] * 100 * item["num"];
      order_items_data["totalPrice"] = item["price"] * 100 * item["num"];
    }
    it["productId"] = item["productId"];

    temp["productId"] = item["productId"];
    temp["restaurant_name"] = data.restaurant_id;
    temp["price"] = item["price"] * 100;
    temp["quantity"] = item["num"];
    temp["name"] = item["title"];
    insertOrderItems.push(temp);
    order_items = [...order_items, order_items_data];
    orderedItems = [...orderedItems, it];
  });
  // console.log(orderedItems);
  let orderTotal = total;
  let orders = {};
  if (discountPercent) {
    let discount = Math.round((total * discountPercent) / 100);
    let applyiedTotal = total - discount;
    let to_tax = applyiedTotal * Number(tax.toFixed(2));
    let to_tip = applyiedTotal * (tip / 100);

    orderTotal = applyiedTotal + to_tax + to_tip;
    if (action) {
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += Math.round(convenience_fee);
      orders = {
        subTotal: total,
        total: Math.round(orderTotal),
        discount: discount,
        notes: notes,
        tax: Math.round(to_tax),
        tip: Math.round(to_tip),
        conv_fee: convenience_fee,
        restaurant_name: data.restaurant_id,
        payment_type: payment.square_payment_type,
      };
    } else {
      orders = {
        subTotal: total,
        total: Math.round(orderTotal),
        discount: discount,
        notes: notes,
        tax: Math.round(to_tax),
        tip: Math.round(to_tip),
        restaurant_name: data.restaurant_id,
        payment_type: payment.square_payment_type,
      };
    }
  } else {
    console.log("non-discount");
    if (action) {
      orderTotal +=
        total * (tip / 100) + Math.round(total * Number(tax.toFixed(2)));
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += convenience_fee;
      orders = {
        subTotal: total,
        total: Math.round(orderTotal),
        notes: notes,
        tax: Math.round(total * Number(tax.toFixed(2))),
        tip: Math.round(total * (tip / 100)),
        conv_fee: convenience_fee,
        restaurant_name: data.restaurant_id,
        payment_type: payment.square_payment_type,
      };
    } else {
      orderTotal =
        total +
        total * (tip / 100) +
        Math.round(total * Number(tax.toFixed(2)));
      orders = {
        restaurant_name: data.restaurant_id,
        payment_type: payment.square_payment_type,
        subTotal: total,
        total: Math.round(orderTotal),
        notes: notes,
        tax: Math.round(total * Number(tax.toFixed(2))),
        tip: Math.round(total * (tip / 100)),
      };
    }
  }
  if (custom) {
    orders = { ...orders, custom };
  }
  let payload = {
    customer: {
      phoneNumber: data.mobile ? data.mobile.trim().split(" ").join("") : "",
      givenName: data.firstName,
      emailAddress: data.email,
    },
    orderCart: {
      order: {
        referenceId: uuid(),
        locationId: "",
        lineItems: orderedItems,
        taxes: [
          {
            uid: "state-sales-tax",
            name: "State Sales Tax",
            percentage: parseInt(tax * 100).toString(),
            scope: "ORDER",
          },
        ],
        fulfillments: [
          {
            type: "PICKUP",
            state: "PROPOSED",
            pickupDetails: {
              recipient: {
                displayName: data.firstName,
                emailAddress: data.email,
                phoneNumber: data.mobile,
              },
              scheduleType: "ASAP",
              pickupAt: moment().add("30", "minutes"),
              note: notes || "",
              isCurbsidePickup: false,
            },
          },
        ],
      },
      idempotencyKey: uuid(),
    },
    orders: orders,
    order_item: insertOrderItems,
    payment,
    item: order_items,
    subTotal: total,
    connect: connect || "",
    notes: notes || "",
    checkoutData: checkoutData,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    tableName: data.tableName,
    orderMethod: data.method,
  };
  if (checkoutData.couponCode.percentage) {
    payload["orderCart"]["order"]["discounts"] = [
      {
        name: "Coupon Promo",
        type: "FIXED_PERCENTAGE",
        percentage: String(checkoutData.couponCode.percentage),
        scope: "ORDER",
      },
    ];
  } else if (discountPercent) {
    payload["orderCart"]["order"]["discounts"] = [
      {
        name: discountData.title,
        type: "FIXED_PERCENTAGE",
        percentage: String(discountPercent),
        scope: "ORDER",
      },
    ];
  }
  // console.log(payload);
  let res = await fetch(`/api/square/${data.restaurant_id}/new/order/online?language=${language}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    // toast.error(json.msg);
  }
}

export async function postCustomerFeed(restaurantId, integrate, orderId, data) {
  const payload = {
    data,
  };
  const res = await fetch(
    `/api/post/customer/feedback/${restaurantId}/${integrate}/${orderId}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getCustomerFeed(restaurantId, integrate, orderId) {
  const res = await fetch(
    `/api/get/customer/feedback/${restaurantId}/${integrate}/${orderId}`
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getRestaurantService(restaurantId) {
  const res = await fetch(`/api/get/service/${restaurantId}`);
  const json = await res.json();
  console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getDashboardDetails(restaurantId) {
  const res = await fetch(`/api/get/dashboard/restaurant/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  }
  // else {
  //   toast.error(json.msg);
  // }
}
export async function getRestaurantDetails(restaurantId, language) {
  const res = await fetch(
    `/api/get/restaurant/${restaurantId}?language=${language}`,
    {
      method: "GET",
      redirect: "follow",
    }
  );
  // const res = await fetch(`/api/get/restaurant/${restaurantId}`);
  // const { url, redirected } = res;
  // if (redirected) window.location.href = url;
  const json = await res.json();
  if (res.status === 200) {
    return { ...json };
  } else {
    toast.error(json.msg);
  }
}
export async function getConditionalModifierGroups(
  restaurantId,
  conditionalModifierGroupId,
  modifierGroupId,
  integrate
) {
  const res = await fetch(
    `/api/get-conditional-modifiers/${restaurantId}/${conditionalModifierGroupId}/${modifierGroupId}/${integrate}`
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getRestauranOrdertDetails(restaurantId, orderId) {
  console.log(restaurantId, orderId);
  const res = await fetch(`/api/get/${restaurantId}/order/${orderId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getProdMetaData(restaurantId, language) {
  const res = await fetch(
    `/api/get/${restaurantId}/product-metadata?language=${language}`
  );
  const json = await res.json();
  // console.log(json)
  // var data = {
  //   images: {},
  //   desc: {},
  // };

  if (res.status === 200) {
    let data = {};
    var image = [];
    var desc = [];
    json.forEach((prod) => {
      let item = {};
      item[prod.product_id] = { img: prod.image_url, desc: prod.description };
      // image[prod.product_Id]["url"] = prod.image_url;
      // image[prod.product_Id]["desc"] = prod.description;
      // data["images"][prod.menu] = prod.image;
      // data["desc"][prod.menu] = prod.description;
      data = { ...data, ...item };
    });

    return data;
  } else {
    toast.error(json.msg);
  }
}
export async function getModifierListByProductId(restaurantId, productId) {
  const res = await fetch(`/api/v1/catalog/object/${restaurantId}/product/${productId}/modifer`);
  if (res.status === 200) {
    const json = await res.json();
    return json;
  } else {
    return null;
  }
}
export async function getModifierGroups(
  restaurantId,
  modifierGroupId,
  modifierGroupName,
  integrate,
  language
) {
  const res = await fetch(
    `/api/get-modifiers/${restaurantId}/${modifierGroupId}/${encodeURIComponent(
      modifierGroupName
    )}/${integrate}?language=${language}`
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getChargeId(restaurantId) {
  const res = await fetch(`/api/get-chargeId/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getOrderTypes(restaurantId) {
  const res = await fetch(`/api/get-orderTypes/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    // console.log(json);
    return json["elements"];
  } else {
    toast.error(json.msg);
  }
}
export async function getPaymentType(restaurantId) {
  const res = await fetch(`/api/get-paymentType/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json["elements"];
  } else {
  }
}

export async function getCustomer(restaurantId, phoneNumber) {
  const res = await fetch(`/api/get/customer/${restaurantId}/${phoneNumber}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getLatestOrder(restaurantId, customerId) {
  const res = await fetch(
    `/api/get/customer/latest-order/${restaurantId}/${customerId}`
  );
  const json = await res.json();
  if (res.status === 200) {
    return json["elements"];
  } else {
    toast.error(json.msg);
  }
}

export async function getMyOrders(restaurantId, customerId) {
  const res = await fetch(
    `/api/get/customer/my-orders/${restaurantId}/${customerId}`
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

// export async function getAllOrders(restaurantId) {
//   const res = await fetch(
//     `/api/${restaurantId}/orders`
//   );
//   const json = await res.json();
//   if (res.status === 200) {
//     return json;
//   } else {
//     toast.error(json.msg);
//   }
// }
export async function getUser(resId, user, password) {
  console.log("entered");
  const res = await fetch(`/api/get/user/${resId}/${user}/${password}`);
  const json = await res.json();
  if (res.ok) {
    toast.success(json.msg);
    return json;
  } else {
    toast.error(json.msg);
  }
  console.log(res);
}
export async function getTable(restaurantId) {
  const res = await fetch(`/api/get/table/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    return { restaurant_name: restaurantId, available: 0 };
  }
}
export async function getAllOrdersItem(restaurantId, limit, offset, items) {
  const payload = {
    items: items,
  };
  const res = await fetch(`/api/${restaurantId}/allorders`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getTableOrdersById(restaurantId, items, filter) {
  const payload = {
    items: items,
  };
  const res = await fetch(
    `/api/v1/merchant/get/table/orders/${restaurantId}/orders?filter=${filter}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getAllTableOrders(restaurantId, items) {
  const payload = {
    items: items,
  };
  const res = await fetch(
    `/api/v1/merchant/get/table/orders/${restaurantId}/orders`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getAllOrders(restaurantId, limit, offset, items) {
  const payload = {
    items: items,
  };
  const res = await fetch(
    `/api/${restaurantId}/orders?limit=${limit}&offset=${offset}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getCustomerByPhone(restaurantId, phoneNumber) {
  const res = await fetch(`/api/get/customer/${restaurantId}/${phoneNumber}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getCustomerByEmail(restaurantId, emailAddress) {
  const res = await fetch(
    `/api/get/customer/${restaurantId}/email/${emailAddress}`
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getItemsCategoryOrder(restaurantId, language) {
  const res = await fetch(`/api/get-items-category-order/${restaurantId}?language=${language}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getTax(restaurantId) {
  const res = await fetch(`/api/get-tax/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateCustomerPhone(
  restaurantId,
  customerId,
  phoneNumberId,
  data
) {
  var payload = {
    phoneNumber: data.mobile,
  };

  let res = await fetch(
    `/api/update-customer-phone/${restaurantId}/${customerId}/${phoneNumberId}`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateCustomerEmail(
  restaurantId,
  customerId,
  emailAddressId,
  data
) {
  var payload = {
    emailAddress: data.email,
  };

  let res = await fetch(
    `/api/update-customer-email/${restaurantId}/${customerId}/${emailAddressId}`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function addCustomerPhone(restaurantId, customerId, data) {
  var payload = {
    phoneNumber: data.mobile,
  };

  let res = await fetch(
    `/api/add-customer-phone/${restaurantId}/${customerId}`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function addCustomerEmail(restaurantId, customerId, data) {
  var payload = {
    emailAddress: data.email,
  };

  let res = await fetch(
    `/api/add-customer-email/${restaurantId}/${customerId}`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateCustomer(restaurantId, customerId, data) {
  var payload = {
    customer: {
      firstName: data.firstName,
      lastName: data.lastName,
    },
  };
  let res = await fetch(`/api/update-customer/${restaurantId}/${customerId}`, {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateCustomerCard(
  restaurantId,
  customerId,
  cardId,
  cardNum,
  expiry,
  cvv
) {
  var card = {
    number: cardNum.replace(/ /g, ""),
    exp_month: expiry.split("/")[0].replace(/ /g, ""),
    exp_year: expiry.split("/")[1].replace(/ /g, ""),
    cvv: cvv,
  };
  let res = await fetch(
    `/api/update-customer-card/${restaurantId}/${customerId}/${cardId}`,
    {
      method: "PUT",
      body: JSON.stringify(card),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function addCustomerCard(
  restaurantId,
  customerId,
  cardNum,
  expiry,
  cvv
) {
  var card = {
    number: cardNum.replace(/ /g, ""),
    exp_month: expiry.split("/")[0].replace(/ /g, ""),
    exp_year: expiry.split("/")[1].replace(/ /g, ""),
    cvv: cvv,
  };
  let res = await fetch(
    `/api/add-customer-card/${restaurantId}/${customerId}`,
    {
      method: "PUT",
      body: JSON.stringify(card),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function postNewPayOrderOnline(
  data,
  items,
  tip,
  tax,
  wallet,
  connect,
  checkoutData
) {
  let orderedItems = [];
  let orderItemData = [];
  let total = 0;
  // Object.values(items)
  //   .filter((item) => item.num > 0)
  // console.log(items);
  items.map(function (item) {
    for (let i = 1; i <= item.num; i++) {
      let temp = { item: { id: "" } };
      temp["item"]["id"] = item.productId;
      temp["name"] = item.title;
      temp["note"] = "";
      temp["note"] = item.instructions;
      temp["price"] = item.price * 100;
      temp["tags"] = item.tags.elements.map((item) =>
        _.pick(item, ["id", "name", "showInReporting"])
      );
      console.log(item.modifiers);
      if (item.modifiers && Object.values(item.modifiers).length) {
        temp["modifications"] = Object.values(item.modifiers).map(
          (modifier) => {
            return {
              modifier: {
                available: "true",
                id: modifier.modifier_id,
              },
              name: modifier.modifier_name,
              amount: modifier.modifier_price,
            };
          }
        );
      }
      orderedItems.push(temp);
      // var it = {};
      // let modPrice = 0;
      // if (Object.values(item["modifiers"]).length) {
      //   Object.values(item["modifiers"]).forEach((ite) => {
      //     modPrice += Number(ite.modifier_price);
      //   });
      // }
      // it["id"] = item["title"]
      //   .replace(/ /g, "_")
      //   .replace(/[\])}[{(]/g, "")
      //   .toLowerCase();
      // it["name"] = item["title"];
      // it["price"] = item["price"] * 100 + modPrice;
      // it["quantity"] = item["num"];
      // it["modifiers"] = item["modifiers"];
      // it["note"] = item.instructions;
      // it["productId"] = item["productId"];
      // it["totalPrice"] = it["price"] * it["quantity"];
      // total += it["price"] * it["quantity"];
      // orderItemData = [...orderItemData, it];
    }
    return 1;
  });
  Object.values(items)
    .filter((item) => item.num > 0)
    .map(function (item) {
      var it = {};
      let modPrice = 0;
      if (Object.values(item["modifiers"]).length) {
        Object.values(item["modifiers"]).forEach((ite) => {
          modPrice += Number(ite.modifier_price);
        });
      }
      it["name"] = item["title"];
      it["productId"] = item["productId"];
      it["price"] = item["price"] * 100 + modPrice;
      it["quantity"] = item["num"];
      it["modifiers"] = item["modifiers"];
      it["totalPrice"] = it["price"] * it["quantity"];
      it["note"] = item.instructions;
      total += it["price"] * it["quantity"];
      orderItemData = [...orderItemData, it];
    });
  let notes, conv_fee, is_exceed = false;


  if (data.tableId) {
    // tableName
    notes =
      data.name && data.mobile
        ? `Table No: ${data.tableName} \nName : ${data.name},\nPhone : ${data.mobile
        },${data.notes ? `\nNotes : ${data.notes}` : ""}\nPayment : Paid`
        : data.name
          ? `Table No: ${data.tableName} \nName : ${data.name},${data.notes ? `\nNotes : ${data.notes}` : ""
          }\nPayment : Paid`
          : data.mobile
            ? `Table No: ${data.tableName} \nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }\nPayment : Paid`
            : "";
  } else if (data.method?.toLowerCase() === "togo") {
    notes =
      data.name && data.mobile
        ? `TOGO Order \nName : ${data.name},\nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
        }\nPayment : Paid`
        : data.name
          ? `TOGO Order \nName : ${data.name},${data.notes ? `\nNotes : ${data.notes}` : ""
          }\nPayment : Paid`
          : data.mobile
            ? `TOGO Order \nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }\nPayment : Paid`
            : "";
  } else {
    notes =
      data.name && data.mobile
        ? `Name : ${data.name},\nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
        }\nPayment : Paid`
        : data.name
          ? `Name : ${data.name},${data.notes ? `\nNotes : ${data.notes}` : ""
          }\nPayment : Paid`
          : data.mobile
            ? `Phone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }\nPayment : Paid`
            : "";
  }
  if (data.convenience_fee.is_enabled) {
    if (checkoutData.convFee) conv_fee = Math.round(checkoutData.convFee * 100);
    else {
      let convenience_fee = total * (data.convenience_fee.percentage / 100);
      let limitfee = data.convenience_fee.limit * 100;
      if (data.convenience_fee.limit && convenience_fee > limitfee) {
        is_exceed = true
        convenience_fee = limitfee;
      }
      conv_fee = Math.round(convenience_fee)
    }
  }

  var payload = {
    customer: {
      addresses: [
        {
          address1: data.address,
          city: data.city,
          country: data.country,
          state: data.state,
          zip: data.zip,
        },
      ],
      emailAddresses: [
        {
          emailAddress: data.email,
          primaryEmail: true,
        },
      ],
      phoneNumbers: [
        {
          phoneNumber: data.mobile,
        },
      ],
      firstName: data.name,
    },
    orderCart: {
      orderCart: {
        lineItems: orderedItems,
        orderType: {
          id: data.orderType,
          taxable: "true",
        },
        currency: "USD",
        note: notes,
        groupLineItems: "true",
      },
    },
    tip: tip,
    tax: tax,
    paymentId: data.paymentId,
    payment: data.payment,
    payment_id: data.payment_id,
    note: notes,
    customer_id: data.customer_id,
    item: orderItemData,
    connect: connect || "",
    checkoutData: checkoutData,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    tableName: data.tableName,
    orderMethod: data.method,
    conv_fee: conv_fee ? conv_fee : 0
  };
  if (data['convenience_fee']['is_enabled']) {
    let percentageDecimal;
    if (checkoutData.convFee) percentageDecimal = (checkoutData.convFee / checkoutData.finalSubTotal) * 1000000
    else percentageDecimal = is_exceed ? ((((data.convenience_fee.limit * 100) / total) * 100)) * 10000 : data.convenience_fee.percentage * 10000
    payload["orderCart"]["orderCart"]["serviceCharge"] = {
      name: "Convenience Fee",
      percentageDecimal,
      enabled: true,
      id: data['chargeId'],
    };
  }
  if (checkoutData.discount.value) {
    payload.orderCart.orderCart["discounts"] = [
      {
        name: "Coupon Discount",
        amount: -Number(checkoutData.discount.value) * 100,
      },
    ]
  }
  let res = await fetch(`/api/order/${data.restaurant_id}/new/pay-online`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    toast.error(
      "Error While Placing Order. Please try after sometime. Your amount will be refunded."
    );
    return false;
  }
}

export async function postNewPayTableOnline(
  data,
  items,
  tip,
  tax,
  wallet,
  connect,
  checkoutData
) {
  let orderedItems = [];
  let orderItemData = [];
  let total = 0;
  // Object.values(items)
  //   .filter((item) => item.num > 0)
  // console.log(items);
  items.map(function (item) {
    for (let i = 1; i <= item.num; i++) {
      let temp = { item: { id: "" } };
      temp["item"]["id"] = item.productId;
      temp["name"] = item.title;
      temp["note"] = "";
      temp["note"] = item.instructions;
      temp["price"] = item.price * 100;
      temp["tags"] = item.tags.elements.map((item) =>
        _.pick(item, ["id", "name", "showInReporting"])
      );
      console.log(item.modifiers);
      if (item.modifiers && Object.values(item.modifiers).length) {
        temp["modifications"] = Object.values(item.modifiers).map(
          (modifier) => {
            return {
              modifier: {
                available: "true",
                id: modifier.modifier_id,
              },
              name: modifier.modifier_name,
              amount: modifier.modifier_price,
            };
          }
        );
      }
      orderedItems.push(temp);
      var it = {};
      it["id"] = item["title"]
        .replace(/ /g, "_")
        .replace(/[\])}[{(]/g, "")
        .toLowerCase();
      it["name"] = item["title"];
      it["price"] = item["price"] * 100;
      it["quantity"] = item["num"];
      it["note"] = item.instructions;
      it["modifiers"] = item["modifiers"];
      it["productId"] = item["productId"];
      it["totalPrice"] = it["price"] * it["quantity"];
      total += it["price"] * it["quantity"];
      orderItemData = [...orderItemData, it];
    }
    return 1;
  });
  let notes;
  if (data.tableId) {
    // tableName
    notes =
      data.name && data.mobile
        ? `Table No: ${data.tableName} \nName : ${data.name},\nPhone : ${data.mobile
        },${data.notes ? `\nNotes : ${data.notes}` : ""}\nPayment : Paid`
        : data.name
          ? `Table No: ${data.tableName} \nName : ${data.name},${data.notes ? `\nNotes : ${data.notes}` : ""
          }\nPayment : Paid`
          : data.mobile
            ? `Table No: ${data.tableName} \nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }\nPayment : Paid`
            : "";
  } else {
    notes =
      data.name && data.mobile
        ? `Name : ${data.name},\nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
        }\nPayment : Paid`
        : data.name
          ? `Name : ${data.name},${data.notes ? `\nNotes : ${data.notes}` : ""
          }\nPayment : Paid`
          : data.mobile
            ? `Phone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }\nPayment : Paid`
            : "";
  }

  var payload = {
    customer: {
      addresses: [
        {
          address1: data.address,
          city: data.city,
          country: data.country,
          state: data.state,
          zip: data.zip,
        },
      ],
      emailAddresses: [
        {
          emailAddress: data.email,
          primaryEmail: true,
        },
      ],
      phoneNumbers: [
        {
          phoneNumber: data.mobile,
        },
      ],
      firstName: data.name,
    },
    orderCart: {
      orderCart: {
        discounts: [
          {
            name: "Coupon Discount",
            amount: -Number(checkoutData.discount.value) * 100,
          },
        ],
        lineItems: orderedItems,
        orderType: {
          id: data.orderType,
          taxable: "true",
        },
        currency: "USD",
        note: notes,
        groupLineItems: "true",
      },
    },
    tip: tip,
    tax: tax,
    paymentId: data.paymentId,
    payment: data.payment,
    payment_id: data.payment_id,
    note: notes,
    customer_id: data.customer_id,
    item: orderItemData,
    connect: connect || "",
    checkoutData: checkoutData,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    tableName: data.tableName,
    orderIds: data.orderIds,
  };
  let res = await fetch(
    `/api/v1/table/table/order/${data.restaurant_id}/new/pay-online`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    toast.error(
      "Error While Placing Order. Please try after sometime. Your amount will be refunded."
    );
    return false;
  }
}
export async function postTablePayment(
  data,
  items,
  tip,
  tip_per,
  tax,
  connect,
  checkoutData
) {
  let orderedItems = [];
  let orderItemData = [];
  let total = 0;
  items.map(function (item) {
    for (let i = 1; i <= item.num; i++) {
      let temp = { item: { id: "" } };
      temp["item"]["id"] = item.productId;
      temp["name"] = item.title;
      temp["note"] = "";
      temp["note"] = item.instructions;
      temp["price"] = item.price * 100;
      temp["tags"] = item.tags.elements.map((item) =>
        _.pick(item, ["id", "name", "showInReporting"])
      );
      if (Object.values(item.modifiers).length) {
        temp["modifications"] = Object.values(item.modifiers).map(
          (modifier) => {
            return {
              modifier: {
                available: "true",
                id: modifier.modifier_id,
              },
              name: modifier.modifier_name,
              amount: modifier.modifier_price,
            };
          }
        );
      }
      orderedItems.push(temp);
      var it = {};
      it["productId"] = item["productId"];
      it["price"] = item["price"] * 100;
      it["note"] = item.instructions;
      it["quantity"] = item["num"];
      it["totalPrice"] = it["price"] * it["quantity"];
      it["modifiers"] = item["modifiers"];
      total += it["price"] * it["quantity"];
      orderItemData = [...orderItemData, it];
    }
    return 1;
  });
  let notes;
  if (data.tableId) {
    notes =
      data.firstName && data.mobile
        ? `Table No: ${data.tableName} \nName : ${data.firstName},\nPhone : ${data.mobile
        },${data.notes ? `\nNotes : ${data.notes}` : ""}`
        : data.firstName
          ? `Table No: ${data.tableName} \nName : ${data.firstName},${data.notes ? `\nNotes : ${data.notes}` : ""
          }`
          : data.mobile
            ? `Table No: ${data.tableName} \nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }`
            : "";
  } else {
    notes =
      data.firstName && data.mobile
        ? `Name : ${data.firstName},\nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
        }`
        : data.firstName
          ? `Name : ${data.firstName},${data.notes ? `\nNotes : ${data.notes}` : ""
          }`
          : data.mobile
            ? `Phone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }`
            : "";
  }

  var payload = {
    customer: {
      addresses: [
        {
          address1: data.address ? data.address : "",
          city: data.city ? data.city : "",
          country: data.country ? data.country : "",
          state: data.state ? data.state : "",
          zip: data.zip ? data.zip : "",
        },
      ],
      emailAddresses: [
        {
          emailAddress: data.email,
          primaryEmail: true,
        },
      ],
      phoneNumbers: [
        {
          phoneNumber: data.mobile,
        },
      ],
      firstName: data.firstName,
      lastName: data.lastName,
    },
    orderCart: {
      orderCart: {
        discounts: [
          {
            name: "Coupon Discount",
            amount: -Number(checkoutData.discount.value) * 100,
          },
        ],
        lineItems: orderedItems,
        orderType: {
          id: data.orderType,
          taxable: "true",
        },
        currency: "USD",
        note: notes,
        groupLineItems: "true",
      },
    },
    card: {
      number: data.cardNum.replace(/ /g, ""),
      exp_month: data.expiry ? data.expiry.split("/")[0].replace(/ /g, "") : "",
      exp_year: data.expiry ? data.expiry.split("/")[1].replace(/ /g, "") : "",
      cvv: data.cvv,
    },
    customer_id: data.customer_id,
    tip: Number(checkoutData.finalTip.value) * 100,
    tips: tip_per,
    tax: tax,
    item: orderItemData,
    notes: data.notes,
    connect: connect || "",
    checkoutData: checkoutData,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    tableName: data.tableName,
    orderIds: data.orderIds,
    amount: data.amount,
  };
  console.log(payload);
  let res = await fetch(
    `/api/v1/table/post/table/pay/order/${data.restaurant_id}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    // toast.success(`Your order has been received by the system.`);
    window.localStorage.removeItem("cart");
    return json;
  } else {
    toast.error(json.msg);
  }
}
// Square

export async function squareOrderCash(data, items, tip_per, tax) {
  let orderedItems = [];
  let total = 0;
  items.map(function (item) {
    var it = {};
    it["quantity"] = item["num"].toString();
    it["name"] = item["title"];
    it["basePriceMoney"] = { amount: item["price"] * 100, currency: "USD" };
    // it["price"] =
    //   item["price"] * 100 + parseInt(item["modifiersPrice"]) / it["quantity"];
    // it["modifiers"] = item["modifiers"];

    total = total + item["price"] * 100;
    orderedItems = [...orderedItems, it];
  });

  var payload = {
    customer: {
      phoneNumber: data.mobile,
      givenName: data.firstName,
    },
    orderCart: {
      order: {
        referenceId: uuid(),
        locationId: "",
        lineItems: orderedItems,
        taxes: [
          {
            uid: "state-sales-tax",
            name: "State Sales Tax",
            percentage: parseInt(tax * 100).toString(),
            scope: "ORDER",
          },
        ],
      },
      idempotencyKey: uuid(),
    },
    subTotal: total,
  };
  let res = await fetch(`/api/square/${data.restaurant_id}/new/order/cash`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    toast.error(json.msg);
  }
}

// Square

export async function postNewOrderOnline(
  data,
  items,
  tip,
  tip_per,
  tax,
  connect,
  checkoutData
) {
  let orderedItems = [];
  let orderItemData = [];
  let total = 0;
  items.map(function (item) {
    for (let i = 1; i <= item.num; i++) {
      let temp = { item: { id: "" } };
      temp["item"]["id"] = item.productId;
      temp["name"] = item.title;
      temp["note"] = "";
      temp["note"] = item.instructions;
      temp["price"] = item.price * 100;
      temp["tags"] = item.tags.elements.map((item) =>
        _.pick(item, ["id", "name", "showInReporting"])
      );
      if (Object.values(item.modifiers).length) {
        temp["modifications"] = Object.values(item.modifiers).map(
          (modifier) => {
            return {
              modifier: {
                available: "true",
                id: modifier.modifier_id,
              },
              name: modifier.modifier_name,
              amount: modifier.modifier_price,
            };
          }
        );
      }
      orderedItems.push(temp);
      var it = {};
      // it["productId"] = item["productId"];
      // it["price"] = item["price"] * 100;
      // it["quantity"] = item["num"];
      // it["totalPrice"] = it["price"] * it["quantity"];
      // it["modifiers"] = item["modifiers"];
      // total += it["price"] * it["quantity"];
      // orderItemData = [...orderItemData, it];
    }
    return 1;
  });
  Object.values(items)
    .filter((item) => item.num > 0)
    .map(function (item) {
      var it = {};
      let modPrice = 0;
      if (Object.values(item["modifiers"]).length) {
        Object.values(item["modifiers"]).forEach((ite) => {
          modPrice += Number(ite.modifier_price);
        });
      }
      it["name"] = item["title"];
      it["productId"] = item["productId"];
      it["price"] = item["price"] * 100 + modPrice;
      it["quantity"] = item["num"];
      it["modifiers"] = item["modifiers"];
      it["totalPrice"] = it["price"] * it["quantity"];
      it["note"] = item.instructions;
      total += it["price"] * it["quantity"];
      orderItemData = [...orderItemData, it];
    });
  let notes, conv_fee, is_exceed = false;
  if (data.tableId) {
    notes =
      data.firstName && data.mobile
        ? `Table No: ${data.tableName} \nName : ${data.firstName},\nPhone : ${data.mobile
        },${data.notes ? `\nNotes : ${data.notes}` : ""}`
        : data.firstName
          ? `Table No: ${data.tableName} \nName : ${data.firstName},${data.notes ? `\nNotes : ${data.notes}` : ""
          }`
          : data.mobile
            ? `Table No: ${data.tableName} \nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }`
            : "";
  } else if (data.method?.toLowerCase() === "togo") {
    notes =
      data.firstName && data.mobile
        ? `TOGO Order \nName : ${data.firstName},\nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
        }`
        : data.firstName
          ? `TOGO Order \nName : ${data.firstName},${data.notes ? `\nNotes : ${data.notes}` : ""
          }`
          : data.mobile
            ? `TOGO Order \nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }`
            : "";
  } else {
    notes =
      data.firstName && data.mobile
        ? `Name : ${data.firstName},\nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
        }`
        : data.firstName
          ? `Name : ${data.firstName},${data.notes ? `\nNotes : ${data.notes}` : ""
          }`
          : data.mobile
            ? `Phone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }`
            : "";
  }
  if (data.convenience_fee.is_enabled) {
    if (checkoutData.convFee) conv_fee = Math.round(checkoutData.convFee * 100);
    else {
      let convenience_fee = total * (data.convenience_fee.percentage / 100);
      let limitfee = data.convenience_fee.limit * 100;
      if (data.convenience_fee.limit && convenience_fee > limitfee) {
        is_exceed = true
        convenience_fee = limitfee;
      }
      conv_fee = Math.round(convenience_fee)
    }
  }

  var payload = {
    customer: {
      addresses: [
        {
          address1: data.address ? data.address : "",
          city: data.city ? data.city : "",
          country: data.country ? data.country : "",
          state: data.state ? data.state : "",
          zip: data.zip ? data.zip : "",
        },
      ],
      emailAddresses: [
        {
          emailAddress: data.email,
          primaryEmail: true,
        },
      ],
      phoneNumbers: [
        {
          phoneNumber: data.mobile,
        },
      ],
      firstName: data.firstName,
      lastName: data.lastName,
    },
    orderCart: {
      orderCart: {
        lineItems: orderedItems,
        orderType: {
          id: data.orderType,
          taxable: "true",
        },
        currency: "USD",
        note: notes,
        groupLineItems: "true",
      },
    },
    card: {
      number: data.cardNum.replace(/ /g, ""),
      exp_month: data.expiry ? data.expiry.split("/")[0].replace(/ /g, "") : "",
      exp_year: data.expiry ? data.expiry.split("/")[1].replace(/ /g, "") : "",
      cvv: data.cvv,
    },
    customer_id: data.customer_id,
    tip: Number(checkoutData.finalTip.value) * 100,
    tips: tip_per,
    tax: tax,
    item: orderItemData,
    notes: data.notes,
    subTotal: total,
    connect: connect || "",
    checkoutData: checkoutData,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    tableName: data.tableName,
    orderMethod: data.method,
    conv_fee: conv_fee ? conv_fee : 0
  };
  if (data['convenience_fee']['is_enabled']) {
    let percentageDecimal;
    if (checkoutData.convFee) percentageDecimal = (checkoutData.convFee / checkoutData.finalSubTotal) * 1000000
    else percentageDecimal = is_exceed ? ((((data.convenience_fee.limit * 100) / total) * 100)) * 10000 : data.convenience_fee.percentage * 10000
    payload["orderCart"]["orderCart"]["serviceCharge"] = {
      name: "Convenience Fee",
      percentageDecimal,
      enabled: true,
      id: data['chargeId'],
    };
  }
  if (checkoutData.discount.value) {
    payload.orderCart.orderCart["discounts"] = [
      {
        name: "Coupon Discount",
        amount: -Number(checkoutData.discount.value) * 100,
      },
    ]
  }
  let res = await fetch(`/api/order/${data.restaurant_id}/new/online`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    // toast.success(`Your order has been received by the system.`);
    window.localStorage.removeItem("cart");
    return json;
  } else {
    // toast.error(`Your card declined! please check card details`);
  }
}
export async function postNewOrderCash(
  data,
  items,
  tip_per,
  tipId,
  tax,
  connect,
  checkoutData
) {
  // console.log(data)
  let orderedItems = [];
  var url = "";
  let orderItemData = [];
  let total = 0;
  // Object.values(items)
  //   .filter((item) => item.num > 0)
  //   .map(function (item) {
  //     for (let i = 1; i <= item.num; i++) {
  //       let temp = { item: { id: "" } };
  //       temp["item"]["id"] = item.productId;
  //       temp["name"] = item.title;
  //       temp["note"] = "";
  //       temp["price"] = item.price * 100;
  //       temp["tags"] = item.tags.elements.map((item) =>
  //         _.pick(item, ["id", "name", "showInReporting"])
  //       );
  //       // if (item.modifier) {
  //       //   temp['modifications'] = [
  //       //     {
  //       //       "modifier": {
  //       //         "id": item.modifier
  //       //       }
  //       //     }
  //       //   ]
  //       // }
  //       orderedItems.push(temp);
  //     }
  //     return 1;
  //   });
  // console.log(items);
  items.map(function (item) {
    for (let i = 1; i <= item.num; i++) {
      let temp = { item: { id: "" } };
      temp["item"]["id"] = item.productId;
      temp["name"] = item.title;
      temp["note"] = "";
      temp["note"] = item.instructions;

      temp["price"] = item.price * 100;
      temp["tags"] = item.tags.elements.map((item) =>
        _.pick(item, ["id", "name", "showInReporting"])
      );
      if (Object.values(item.modifiers).length) {
        temp["modifications"] = Object.values(item.modifiers).map(
          (modifier) => {
            return {
              modifier: {
                available: "true",
                id: modifier.modifier_id,
              },
              name: modifier.modifier_name,
              amount: modifier.modifier_price,
            };
          }
        );
      }
      var it = {};
      // it["name"] = item["title"];
      // it["productId"] = item["productId"];
      // it["price"] = item["price"] * 100;
      // it["quantity"] = item["num"];
      // it["modifiers"] = item["modifiers"];
      // it["totalPrice"] = it["price"] * it["quantity"];
      // total += it["price"] * it["quantity"];
      // orderItemData = [...orderItemData, it];
      orderedItems.push(temp);
    }
    return 1;
  });
  if (data.tableId) {
    url = `/api/order/${data.restaurant_id}/new/table/${data.tableId}`;
  } else {
    url = `/api/order/${data.restaurant_id}/new/cash`;
  }
  // console.log(items);

  Object.values(items)
    .filter((item) => item.num > 0)
    .map(function (item) {
      var it = {};
      let modPrice = 0;
      if (Object.values(item["modifiers"]).length) {
        Object.values(item["modifiers"]).forEach((ite) => {
          modPrice += Number(ite.modifier_price);
        });
      }
      it["name"] = item["title"];
      it["productId"] = item["productId"];
      it["price"] = item["price"] * 100 + modPrice;
      it["note"] = item.instructions;
      it["modifiers"] = item["modifiers"];
      it["quantity"] = item["num"];
      it["totalPrice"] = it["price"] * it["quantity"];
      total += it["price"] * it["quantity"];
      orderItemData = [...orderItemData, it];
    });
  let notes;
  if (data.tableId) {
    // tableName
    notes =
      data.firstName && data.mobile
        ? `Table No: ${data.tableName} \nName : ${data.firstName},\nPhone : ${data.mobile
        },${data.notes ? `\nNotes : ${data.notes}` : ""}\nPayment : Not Paid`
        : data.firstName
          ? `Table No: ${data.tableName} \nName : ${data.firstName},${data.notes ? `\nNotes : ${data.notes}` : ""
          }\nPayment : Not Paid`
          : data.mobile
            ? `Table No: ${data.tableName} \nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }\nPayment : Not Paid`
            : "";
  } else {
    notes =
      data.firstName && data.mobile
        ? `Name : ${data.firstName},\nPhone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
        }\nPayment : Not Paid`
        : data.firstName
          ? `Name : ${data.firstName},${data.notes ? `\nNotes : ${data.notes}` : ""
          }\nPayment : Not Paid`
          : data.mobile
            ? `Phone : ${data.mobile},${data.notes ? `\nNotes : ${data.notes}` : ""
            }\nPayment : Not Paid`
            : "";
  }

  let payload = {
    customer: {
      phoneNumbers: [
        {
          phoneNumber: data.mobile ? data.mobile : "",
        },
      ],
      firstName: data.firstName,
      lastName: "",
    },
    orderCart: {
      orderCart: {
        // discounts: [
        //   {
        //     name: "Coupon Discount",
        //     amount: -Number(checkoutData.discount.value) * 100,
        //   },
        // ],
        // serviceCharge: {
        //   name: "Tip",
        //   percentageDecimal: tip_per * 10000,
        //   enabled: true,
        //   id: tipId,
        // },
        lineItems: orderedItems,
        orderType: {
          id: data.orderType,
          taxable: "true",
        },
        currency: "USD",
        note: notes,
        groupLineItems: "true",
      },
    },
    customer_id: data.customer_id,
    tips: tip_per,
    tax: tax,
    item: orderItemData,
    notes: data.notes,
    subTotal: total,
    connect: connect || "",
    checkoutData: checkoutData,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    tableName: data.tableName,
  };
  if (tip_per) {
    payload["orderCart"]["orderCart"]["serviceCharge"] = {
      name: "Tip",
      percentageDecimal: tip_per * 10000,
      enabled: true,
      id: tipId,
    };
  }
  if (checkoutData.discount.value) {
    payload.orderCart.orderCart["discounts"] = [
      {
        name: "Coupon Discount",
        amount: -Number(checkoutData.discount.value) * 100,
      },
    ];
  }
  if (data.email) {
    payload["customer"] = {
      ...payload["customer"],
      emailAddresses: [
        {
          emailAddress: data.email,
          primaryEmail: true,
        },
      ],
    };
  }
  // console.log(payload);
  let res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    toast.error(json.msg);
  }
}
// export async function postNewOrderCash(
//   data,
//   items,
//   tip_per,
//   tipId,
//   tax,
//   connect,
//   action,
//   rate,
//   limit,
//   discountPercent,
//   tip
// ) {
//   // console.log(data)
//   let orderedItems = [];
//   var url = "";
//   Object.values(items)
//     .filter((item) => item.num > 0)
//     .map(function (item) {
//       for (let i = 1; i <= item.num; i++) {
//         let temp = { item: { id: "" } };
//         temp["item"]["id"] = item.productId;
//         temp["name"] = item.title;
//         temp["note"] = "";
//         temp["note"] = item.instructions;
//         temp["price"] = item.price * 100;
//         temp["tags"] = item.tags.elements.map((item) =>
//           _.pick(item, ["id", "name", "showInReporting"])
//         );
//         // if (item.modifier) {
//         //   temp['modifications'] = [
//         //     {
//         //       "modifier": {
//         //         "id": item.modifier
//         //       }
//         //     }
//         //   ]
//         // }
//         orderedItems.push(temp);
//       }
//       return 1;
//     });
//   if (data.tableOrder) {
//     url = `/api/order/${data.restaurant_id}/new/table/${data.table}`;
//   } else {
//     url = `/api/order/${data.restaurant_id}/new/cash`;
//   }
//   // console.log(url);
//   let orderItemData = [];
//   let total = 0;
//   Object.values(items)
//     .filter((item) => item.num > 0)
//     .map(function (item) {
//       var it = {};
//       it["name"] = item["title"];
//       it["productId"] = item["productId"];
//       it["price"] = item["price"] * 100;
//       it["quantity"] = item["num"];
//       it["totalPrice"] = it["price"] * it["quantity"];
//       total += it["price"] * it["quantity"];
//       orderItemData = [...orderItemData, it];
//     });
//   // data.notes
//   let notes =
//     data.firstName && data.mobile
//       ? `Name : ${data.firstName},\nPhone : ${data.mobile},${
//           data.notes ? `\nNotes : ${data.notes}` : ""
//         }\nPayment : Not Paid`
//       : data.firstName
//       ? `Name : ${data.firstName},${
//           data.notes ? `\nNotes : ${data.notes}` : ""
//         }\nPayment : Not Paid`
//       : data.mobile
//       ? `Phone : ${data.mobile},${
//           data.notes ? `\nNotes : ${data.notes}` : ""
//         }\nPayment : Not Paid`
//       : "";
//   let orderTotal = total;
//   var payload = {
//     customer: {
//       phoneNumbers: [
//         {
//           phoneNumber: data.mobile ? data.mobile : "",
//         },
//       ],
//       firstName: data.firstName,
//       lastName: "",
//     },
//     orderCart: {
//       orderCart: {
//         serviceCharge: {
//           name: "Tip",
//           percentageDecimal: tip_per * 10000,
//           enabled: true,
//           id: tipId,
//         },
//         lineItems: orderedItems,
//         orderType: {
//           id: data.orderType,
//           taxable: "true",
//         },
//         currency: "USD",
//         note: notes,
//         groupLineItems: "true",
//       },
//     },
//     customer_id: data.customer_id,
//     tips: tip_per,
//     tax: tax,
//     item: orderItemData,
//     notes: data.notes,
//     subTotal: total,
//     connect: connect || "",
//   };
//   if (data.email) {
//     payload["customer"] = {
//       ...payload["customer"],
//       emailAddresses: [
//         {
//           emailAddress: data.email,
//           primaryEmail: true,
//         },
//       ],
//     };
//   }
//   console.log(discountPercent);
//   if (discountPercent) {
//     let discount = Math.round((total * discountPercent) / 100);
//     let applyiedTotal = total - discount;
//     let to_tax = applyiedTotal * tax;
//     let to_tip = applyiedTotal * (tip / 100);
//     console.log(discount, applyiedTotal, to_tax, to_tip);
//     orderTotal = applyiedTotal + to_tax + to_tip;
//     // Math.round(applyiedTotal) + Math.round(to_tax) + Math.round(to_tip);
//     if (action) {
//       let convenience_fee = Math.round((orderTotal * rate) / 100);
//       let limitfee = limit * 100;
//       if (limit && convenience_fee > limitfee) {
//         convenience_fee = limitfee;
//       }
//       orderTotal += Math.round(convenience_fee);
//       payload["orders"] = {
//         subTotal: total,
//         total: Math.round(orderTotal),
//         discount: discount,
//         notes: data.notes,
//         tax: Math.round(to_tax),
//         tip: Math.round(to_tip),
//         conv_fee: convenience_fee,
//       };
//     } else {
//       payload["orders"] = {
//         subTotal: total,
//         total: Math.round(orderTotal),
//         discount: discount,
//         notes: data.notes,
//         tax: Math.round(to_tax),
//         tip: Math.round(to_tip),
//       };
//     }
//   } else {
//     console.log("non-discount");
//     if (action) {
//       orderTotal += total * (tip / 100) + Math.round(total * tax);
//       let convenience_fee = Math.round((orderTotal * rate) / 100);
//       let limitfee = limit * 100;
//       if (limit && convenience_fee > limitfee) {
//         convenience_fee = limitfee;
//       }
//       orderTotal += convenience_fee;
//       payload["orders"] = {
//         subTotal: total,
//         total: Math.round(orderTotal),
//         notes: data.notes,
//         tax: Math.round(total * tax),
//         tip: Math.round(total * (tip / 100)),
//         conv_fee: convenience_fee,
//       };
//     } else {
//       orderTotal = total + total * (tip / 100) + Math.round(total * tax);
//       payload["orders"] = {
//         subTotal: total,
//         total: Math.round(orderTotal),
//         notes: data.notes,
//         tax: Math.round(total * tax),
//         tip: Math.round(total * (tip / 100)),
//       };
//     }
//   }
//   console.log(payload);
//   // let res = await fetch(url, {
//   //   method: "POST",
//   //   body: JSON.stringify(payload),
//   //   headers: {
//   //     "Content-Type": "application/json",
//   //   },
//   // });
//   // const json = await res.json();
//   // if (res.status === 200) {
//   //   window.localStorage.removeItem("cart");
//   //   return json;
//   // } else {
//   //   toast.error(json.msg);
//   // }
// }

export async function postUpdateTableOrder(
  restaurant_id,
  table_id,
  items,
  tip_per,
  tax
) {
  let orderedItems = [];
  var url = "";
  Object.values(items)
    .filter((item) => item.num > 0)
    .map(function (item) {
      for (let i = 1; i <= item.num; i++) {
        let temp = { item: { id: "" } };
        temp["item"]["id"] = item.productId;
        temp["tags"] = item.tags.elements.map((item) =>
          _.pick(item, ["id", "name", "showInReporting"])
        );
        // if (item.modifier) {
        //   temp['modifications'] = [
        //     {
        //       "modifier": {
        //         "id": item.modifier
        //       }
        //     }
        //   ]
        // }
        orderedItems.push(temp);
      }
      return 1;
    });

  let orderItemData = [];
  let total = 0;
  Object.values(items)
    .filter((item) => item.num > 0)
    .map(function (item) {
      var it = {};
      it["name"] = item["title"];
      it["productId"] = item["productId"];
      it["price"] = item["price"] * 100;
      it["quantity"] = item["num"];
      it["totalPrice"] = it["price"] * it["quantity"];
      total += it["price"] * it["quantity"];
      orderItemData = [...orderItemData, it];
    });
  // data.notes

  var payload = {
    orderCart: {
      orderCart: {
        lineItems: orderedItems,
      },
    },
    tips: tip_per,
    tax: tax,
    item: orderItemData,
    subTotal: total,
  };

  let res = await fetch(
    `/api/order/${restaurant_id}/update/table/${table_id}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function postNewOrderCashCustom(
  data,
  items,
  tip,
  tax,
  action,
  rate,
  limit,
  connect,
  discountPercent,
  custom,
  checkoutData
) {
  let orderItems = [];
  let url = "";
  let total = 0;
  let conv_tax = 0;
  let conv_tips = 0;
  let conv_fee_charge = 0;
  console.log(items);
  items.map(function (item) {
    var it = {};
    // let modPrice = 0;
    // if (Object.values(item["modifiers"]).length) {
    //   Object.values(item["modifiers"]).forEach((ite) => {
    //     modPrice += Number(ite.modifier_price);
    //   });
    // }
    it["quantity"] = item["num"];
    it["price"] =
      item["price"] * 100 + parseInt(item["modifiersPrice"]) / it["quantity"];
    it["productId"] = item["productId"];
    it["name"] = item["title"];
    it["modifiers"] = item["modifiers"];
    it["totalPrice"] =
      item["price"] * 100 * it["quantity"] + parseInt(item["modifiersPrice"]);
    it["note"] = item.instructions;
    total = total + it["totalPrice"];
    orderItems = [...orderItems, it];
    it["note"] = item.instructions;
  });
  let orderTotal = total;
  var payload = {
    customer: {
      name: data.name,
      mobile: data.mobile,
      email: data.email,
    },
    orderItems: orderItems,
    timeZone: data.timeZone,
    connect: connect || "",
    discountPercent: discountPercent || "",
    checkoutData: checkoutData,
    taxInPercent: tax,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    tableName: data.tableName,
    tipInPercent: tip / 100,
    orderMethod: null,
  };

  if (discountPercent) {
    let discount = Math.round((total * discountPercent) / 100);
    let applyiedTotal = total - discount;
    let to_tax = applyiedTotal * tax;
    let to_tip = applyiedTotal * (tip / 100);

    orderTotal = applyiedTotal + to_tax + to_tip;
    // Math.round(applyiedTotal) + Math.round(to_tax) + Math.round(to_tip);
    if (action) {
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += Math.round(convenience_fee);
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        discount: discount,
        notes: data.notes,
        tax: Math.round(to_tax),
        tip: Math.round(to_tip),
        conv_fee: convenience_fee,
      };
    } else {
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        discount: discount,
        notes: data.notes,
        tax: Math.round(to_tax),
        tip: Math.round(to_tip),
      };
    }
  } else {
    console.log("non-discount");
    if (action) {
      orderTotal += total * (tip / 100) + Math.round(total * tax);
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += convenience_fee;
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        notes: data.notes,
        tax: Math.round(total * tax),
        tip: Math.round(total * (tip / 100)),
        conv_fee: convenience_fee,
      };
    } else {
      orderTotal = total + total * (tip / 100) + Math.round(total * tax);
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        notes: data.notes,
        tax: Math.round(total * tax),
        tip: Math.round(total * (tip / 100)),
      };
    }
  }
  if (custom) {
    payload["orders"] = { ...payload["orders"], custom };
  }
  if (data.tableId) {
    url = `/api/order/${data.restaurant_id}/new/custom/cash?table=true`;
  } else {
    url = `/api/order/${data.restaurant_id}/new/custom/cash`;
  }
  let res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    // toast.success(`Your order has been received by the system.`);
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function builtStripItems(
  restaurant,
  items,
  tip,
  tax,
  action,
  rate,
  limit,
  custom_link = false,
  discountPercent,
  checkoutData
) {
  let stripeProduct = [];
  let total = 0;
  items.map(function (item) {
    var it = {};
    it["id"] = item["title"]
      .replace(/ /g, "_")
      .replace(/[\])}[{(]/g, "")
      .replace(/[^a-zA-Z ]/g, "")
      .toLowerCase();
    it["name"] = item["title"];
    it["quantity"] = item["num"];
    if (custom_link) {
      it["price"] = Math.round(item["price"]);
    } else {
      it["price"] =
        Math.round(item["price"] * 100) + parseInt(item["modifiersPrice"]);
    }
    total = total + it["price"] * it["quantity"];

    stripeProduct = [...stripeProduct, it];
  });
  let orderTotal = total;
  stripeProduct = [...stripeProduct];
  let isAnyDiscount = discountPercent;
  if (checkoutData.couponCode.percentage) {
    isAnyDiscount = checkoutData.couponCode.percentage;
    stripeProduct.push(
      {
        id: "tip",
        name: "Tip",
        price: Number((checkoutData.finalTip.value * 100).toFixed(2)),
        quantity: 1,
      },
      {
        id: "tax",
        name: "Tax",
        price: Number((checkoutData.finalTax.value * 100).toFixed(2)),
        quantity: 1,
      }
    );
  } else if (discountPercent) {
    let discount = Math.round((total * discountPercent) / 100);
    let applyiedTotal = total - discount;
    let to_tax = applyiedTotal * tax;
    let to_tip = applyiedTotal * (tip / 100);

    orderTotal = applyiedTotal + to_tax + to_tip;
    // Math.round(applyiedTotal) + Math.round(to_tax) + Math.round(to_tip);
    if (action) {
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += Math.round(convenience_fee);
      stripeProduct.push(
        {
          id: "fee",
          name: "Convenience Fee",
          price: convenience_fee,
          // price: Math.round(convenience_fee),
          quantity: 1,
        },
        {
          id: "tip",
          name: "Tip",
          price: Math.round(to_tip),
          quantity: 1,
        },
        {
          id: "tax",
          name: "Tax",
          price: Math.round(to_tax),
          quantity: 1,
        }
      );
    } else {
      stripeProduct.push(
        {
          id: "tip",
          name: "Tip",
          price: Math.round(to_tip),
          quantity: 1,
        },
        {
          id: "tax",
          name: "Tax",
          price: Math.round(to_tax),
          quantity: 1,
        }
      );
    }
  } else {
    console.log("non-discount");
    if (action) {
      orderTotal += total * (tip / 100) + Math.round(total * tax);
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += convenience_fee;
      stripeProduct.push(
        {
          id: "fee",
          name: "Convenience Fee",
          price: convenience_fee,
          // price: Math.round(convenience_fee),
          quantity: 1,
        },
        {
          id: "tip",
          name: "Tip",
          price: Math.round(total * (tip / 100)),
          quantity: 1,
        },
        {
          id: "tax",
          name: "Tax",
          price: Math.round(total * tax),
          quantity: 1,
        }
      );
    } else {
      orderTotal = total + total * (tip / 100) + Math.round(total * tax);
      stripeProduct.push(
        {
          id: "tip",
          name: "Tip",
          price: Math.round(total * (tip / 100)),
          quantity: 1,
        },
        {
          id: "tax",
          name: "Tax",
          price: Math.round(total * tax),
          quantity: 1,
        }
      );
    }
  }
  var payload = {
    stripeProduct,
    discountPercent: isAnyDiscount,
  };
  let res = await fetch(`/api/order/${restaurant}/checkout`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function postUpdateTableOrderV1(
  restaurant_id,
  table_id,
  items,
  tip_per,
  tax
) {
  let orderedItems = [];
  var url = "";
  Object.values(items)
    .filter((item) => item.num > 0)
    .map(function (item) {
      for (let i = 1; i <= item.num; i++) {
        let temp = { item: { id: "" } };
        temp["item"]["id"] = item.productId;
        temp["tags"] = item.tags.elements.map((item) =>
          _.pick(item, ["id", "name", "showInReporting"])
        );
        // if (item.modifier) {
        //   temp['modifications'] = [
        //     {
        //       "modifier": {
        //         "id": item.modifier
        //       }
        //     }
        //   ]
        // }
        orderedItems.push(temp);
      }
      return 1;
    });

  let orderItemData = [];
  let total = 0;
  Object.values(items)
    .filter((item) => item.num > 0)
    .map(function (item) {
      var it = {};
      it["name"] = item["title"];
      it["productId"] = item["productId"];
      it["price"] = item["price"] * 100;
      it["quantity"] = item["num"];
      it["totalPrice"] = it["price"] * it["quantity"];
      total += it["price"] * it["quantity"];
      orderItemData = [...orderItemData, it];
    });
  // data.notes

  var payload = {
    orderCart: {
      orderCart: {
        lineItems: orderedItems,
      },
    },
    tips: tip_per,
    tax: tax,
    item: orderItemData,
    subTotal: total,
  };

  let res = await fetch(
    `/api/order/${restaurant_id}/update/table/${table_id}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function payTableOnline(data, payment) {
  var payload = {
    orderId: data.orderId,
    orderIds: data.orderIds,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    customer_id: data.customer_id,
    orderType: data.orderType,
    card: payment.payment_gateway !== "square" && {
      number: data.cardNum.replace(/ /g, ""),
      exp_month: data.expiry.split("/")[0].replace(/ /g, ""),
      exp_year: data.expiry.split("/")[1].replace(/ /g, ""),
      cvv: data.cvv,
    },
    tip: data.tip,
    tax: data.orderData.tax,
    subTotal: data.orderData.subTotal,
    total: data.orderData.total,
    notes: data.notes,
    amount: data.amount,
    discountPercent: data.orderData.coupon_discount_percentage,
    discountAmount: data.orderData.discountAmount,
    payment,
  };
  // console.log(payload);
  let res = await fetch(
    `/api/v1/table/api/table/order/merge/charge/${data.restaurant_id}`,
    // `/api/v1/table/api/table/order/charge/${data.restaurant_id}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    // toast.error(`Your card declined! please check card details`);
  }
}
export async function payTableCash(data) {
  var payload = {
    orderId: data.orderId,
    orderIds: data.orderIds,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    customer_id: data.customer_id,
    orderType: data.orderType,
    card: {
      // number: data.cardNum.replace(/ /g, ""),
      // exp_month: data.expiry.split("/")[0].replace(/ /g, ""),
      // exp_year: data.expiry.split("/")[1].replace(/ /g, ""),
      // cvv: data.cvv,
    },
    tip: data.tip,
    tax: data.orderData.tax,
    subTotal: data.orderData.subTotal,
    total: data.orderData.total,
    notes: data.notes,
    amount: data.amount,
    tip_per: data.tip_per,
    discountPercent: data.orderData.coupon_discount_percentage,
    discountAmount: data.orderData.discountAmount,
  };
  // console.log(payload);
  let res = await fetch(
    `/api/v1/table/api/table/order/merge/cash/${data.restaurant_id}`,
    // `/api/v1/table/api/table/order/charge/${data.restaurant_id}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(`Something went wrong!`);
  }
}
export async function payTableStripe(data) {
  console.log(data);
  let res = await fetch(
    `/api/v1/table/api/table/order/merge/stripe/${data.restaurant_id}`,
    // `/api/v1/table/api/table/order/stripe/${data.restaurant_id}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function postNewOrderOnlineCustom(
  data,
  items,
  tip,
  tax,
  action,
  rate,
  limit,
  connect,
  discountPercent,
  custom,
  checkoutData,
  cart,
  payment = null
) {
  let orderItems = [];
  let total = 0;
  let conv_tax = 0;
  let url = "";
  let conv_tips = 0;
  let conv_fee_charge = 0;
  items.map(function (item) {
    var it = {};
    it["quantity"] = item["num"];
    it["price"] =
      item["price"] * 100 + parseInt(item["modifiersPrice"]) / it["quantity"];
    it["productId"] = item["productId"];
    it["name"] = item["title"];
    it["modifiers"] = item["modifiers"];
    it["totalPrice"] =
      item["price"] * 100 * it["quantity"] + parseInt(item["modifiersPrice"]);
    it["note"] = item.instructions;
    total = total + it["totalPrice"];
    orderItems = [...orderItems, it];
  });
  let orderTotal = total;
  let payload = {
    customer: {
      name: data.name || data.email || data.mobile || 'no name',
      mobile: data.mobile,
      email: data.email,
      delivery_address: cart?.delivery_address || {}
    },
    card: payment.payment_gateway !== "square" && {
      number: data.cardNum.replace(/ /g, ""),
      exp_month: data.expiry.split("/")[0].replace(/ /g, ""),
      exp_year: data.expiry.split("/")[1].replace(/ /g, ""),
      cvv: data.cvv,
    },
    order_details: {
      order_method: cart?.order_method || 'pickup'
    },
    orderItems: orderItems,
    timeZone: data.timeZone,
    checkoutId: data.checkoutId,
    amount: data.amount,
    connect: connect || "",
    discountPercent: discountPercent || "",
    checkoutData: checkoutData,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    tableName: data.tableName,
    taxInPercent: tax,
    tipInPercent: tip / 100,
    payment,
    orderMethod: data.method,
    payment_order_id: cart?.payment_order_id || ''
  };

  if (discountPercent) {
    let discount = Math.round((total * discountPercent) / 100);
    let applyiedTotal = total - discount;
    let to_tax = applyiedTotal * tax;
    let to_tip = applyiedTotal * (tip / 100);

    orderTotal = applyiedTotal + to_tax + to_tip;
    // Math.round(applyiedTotal) + Math.round(to_tax) + Math.round(to_tip);
    if (action) {
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += Math.round(convenience_fee);
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        discount: discount,
        notes: data.notes,
        tax: Math.round(to_tax),
        tip: Math.round(to_tip),
        conv_fee: convenience_fee,
      };
    } else {
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        discount: discount,
        notes: data.notes,
        tax: Math.round(to_tax),
        tip: Math.round(to_tip),
      };
    }
  } else {
    console.log("non-discount");
    if (action) {
      orderTotal += total * (tip / 100) + Math.round(total * tax);
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += convenience_fee;
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        notes: data.notes,
        tax: Math.round(total * tax),
        tip: Math.round(total * (tip / 100)),
        conv_fee: convenience_fee,
      };
    } else {
      orderTotal = total + total * (tip / 100) + Math.round(total * tax);
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        notes: data.notes,
        tax: Math.round(total * tax),
        tip: Math.round(total * (tip / 100)),
      };
    }
  }

  if (custom) {
    payload["orders"] = { ...payload["orders"], custom };
  }

  if (data.tableId) {
    url = `/api/order/${data.restaurant_id}/new/custom/online?table=true`;
  } else {
    url = `/api/order/${data.restaurant_id}/new/custom/online`;
  }
  let res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    return json.msg;
  }
}
export async function postNewOrderOnlineCustomPay(
  data,
  items,
  tip,
  tax,
  amount,
  connect,
  discountPercent,
  action,
  limit,
  rate,
  custom,
  checkoutData,
  cart
) {
  let orderItems = [];
  let total = 0;
  let url = "";
  items.map(function (item) {
    var it = {};
    it["quantity"] = item["num"];
    it["price"] =
      item["price"] * 100 + parseInt(item["modifiersPrice"]) / it["quantity"];
    it["productId"] = item["productId"];
    it["name"] = item["title"];
    it["modifiers"] = item["modifiers"];
    it["totalPrice"] =
      item["price"] * 100 * it["quantity"] + parseInt(item["modifiersPrice"]);
    total = total + it["totalPrice"];
    orderItems = [...orderItems, it];
  });
  let orderTotal = total;
  // if (discountPercent) {
  //   let inprice = total - (total * discountPercent) / 100;
  //   to_tax = Math.round(inprice * tax);
  //   to_tip = inprice * (tip / 100);
  // }
  var payload = {
    customer: {
      name: data.name,
      mobile: data.mobile,
      email: data.email,
      delivery_address: cart?.delivery_address || {}
    },
    order_details: {
      order_method: cart?.order_method || 'pickup'
    },
    // orders: {
    //   subTotal: total,
    //   total: amount,
    //   notes: data.notes,
    //   tax: to_tax,
    //   tip: to_tip,
    // payment_id: data.payment_id,
    // payment_type: data.payment,
    // },
    orderItems: orderItems,
    connect: connect || "",
    discountPercent: discountPercent || "",
    checkoutData: checkoutData,
    taxInPercent: tax,
    tipInPercent: tip / 100,
    tableId: data.tableId,
    tableOrderId: data.tableOrderId,
    tableName: data.tableName,
    orderMethod: data.method,
    stripe_customer_id: data.stripe_customer_id,
    payment_order_id: cart?.payment_order_id || ''
  };
  if (discountPercent) {
    let discount = Math.round((total * discountPercent) / 100);
    let applyiedTotal = total - discount;
    let to_tax = applyiedTotal * tax;
    let to_tip = applyiedTotal * (tip / 100);

    orderTotal = applyiedTotal + to_tax + to_tip;
    // Math.round(applyiedTotal) + Math.round(to_tax) + Math.round(to_tip);
    if (action) {
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += Math.round(convenience_fee);
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        discount: discount,
        notes: data.notes,
        tax: Math.round(to_tax),
        tip: Math.round(to_tip),
        conv_fee: convenience_fee,
        payment_id: data.payment_id,
        payment_type: data.payment,
      };
    } else {
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        discount: discount,
        notes: data.notes,
        tax: Math.round(to_tax),
        tip: Math.round(to_tip),
        payment_id: data.payment_id,
        payment_type: data.payment,
      };
    }
  } else {
    console.log("non-discount");
    if (action) {
      orderTotal += total * (tip / 100) + Math.round(total * tax);
      let convenience_fee = Math.round((orderTotal * rate) / 100);
      let limitfee = limit * 100;
      if (limit && convenience_fee > limitfee) {
        convenience_fee = limitfee;
      }
      orderTotal += convenience_fee;
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        notes: data.notes,
        tax: Math.round(total * tax),
        tip: Math.round(total * (tip / 100)),
        conv_fee: convenience_fee,
        payment_id: data.payment_id,
        payment_type: data.payment,
      };
    } else {
      orderTotal = total + total * (tip / 100) + Math.round(total * tax);
      payload["orders"] = {
        subTotal: total,
        total: Math.round(orderTotal),
        notes: data.notes,
        tax: Math.round(total * tax),
        tip: Math.round(total * (tip / 100)),
        payment_id: data.payment_id,
        payment_type: data.payment,
      };
    }
  }
  if (custom) {
    payload["orders"] = { ...payload["orders"], custom };
  }
  if (data.tableId) {
    url = `/api/order/${data.restaurant_id}/new/custom/pay/online?table=true`;
  } else {
    url = `/api/order/${data.restaurant_id}/new/custom/pay/online`;
  }
  console.log(url);
  console.log(payload);
  let res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    return json;
  } else {
    return json.msg;
    // alert(json.msg);
  }
}

export async function postNewOrderOnlineCustomCheckout(data, items, tip, tax) {
  let orderItems = [];
  let stripeProduct = [];
  let total = 0;
  Object.values(items)
    .filter((item) => item.num > 0)
    .map(function (item) {
      var it = {};
      it["id"] = item["title"]
        .replace(/ /g, "_")
        .replace(/[\])}[{(]/g, "")
        .toLowerCase();
      it["name"] = item["title"];
      it["price"] = item["price"] * 100;
      it["quantity"] = item["num"];
      stripeProduct = [...stripeProduct, it];
      it["productId"] = item["productId"];
      it["totalPrice"] = it["price"] * it["quantity"];
      total += it["price"] * it["quantity"];
      orderItems = [...orderItems, it];
    });
  stripeProduct = [
    ...stripeProduct,
    {
      id: "tip",
      name: "Tip",
      price: total * (tip / 100),
      quantity: 1,
    },
  ];
  var payload = {
    customer: {
      name: data.name,
      mobile: data.mobile,
      email: data.email,
    },
    orders: {
      subTotal: total,
      total: total + total * (tip / 100) + parseInt(total * tax),
      notes: data.notes,
      tax: parseInt(total * tax),
      tip: total * (tip / 100),
    },
    // card: {
    //   number: data.cardNum.replace(/ /g, ""),
    //   exp_month: data.expiry.split("/")[0].replace(/ /g, ""),
    //   exp_year: data.expiry.split("/")[1].replace(/ /g, ""),
    //   cvv: data.cvv,
    // },
    orderItems: orderItems,
    stripeProduct: stripeProduct,
    timeZone: data.timeZone,
  };
  let res = await fetch(
    `/api/order/${data.restaurant_id}/new/custom/online-checkout`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    window.localStorage.removeItem("cart");
    // toast.success(`Your order has been received by the system.`);
    return json;
  } else {
    toast.error(json.msg);
  }
}

//Login
export async function customerLogin(email, password, remember) {
  var payload = {
    email,
    password,
    remember,
  };
  let res = await fetch(`/api/customer/login`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    toast.success(`User Loggedin.`);
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getLogin(userId, password, remember) {
  var payload = {
    password,
    remember,
  };
  let res = await fetch(`/api/get/login/${userId}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    // toast.success(`User Loggedin.`);
    return json;
  } else {
    toast.error(json.msg);
  }
}
//SignUp
export async function postSignup(data) {
  var payload = {
    customer: {
      addresses: [
        {
          address1: data.address,
          city: data.city,
          country: data.country,
          state: data.state,
          zip: data.zip,
        },
      ],
      emailAddresses: [
        {
          emailAddress: data.email,
          primaryEmail: true,
        },
      ],
      phoneNumbers: [
        {
          phoneNumber: data.mobile,
        },
      ],
      firstName: data.firstName,
      lastName: data.lastName,
    },
    password: data.password,
  };
  let res = await fetch(`/api/${data.restaurantId}/customer/register`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    toast.success(`User registered.`);
    return json;
  } else {
    toast.error(json.msg);
  }
}

// Existing

export async function getRestaurant(restaurantId) {
  const res = await fetch(`/api/get/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getAddress(restaurantId) {
  const res = await fetch(`/api/get-address/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getHours(restaurantId) {
  const res = await fetch(`/api/get-hours/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getItems(restaurantId) {
  const res = await fetch(`/api/get-items/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getItemsCategory(restaurantId) {
  const res = await fetch(`/api/get-items-category/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getStockItems(restaurantId) {
  const res = await fetch(`/api/get-stockitems/${restaurantId}`);
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export function joinObjSameKeyGname(obj) {
  try {
    let temp = {};
    Object.values(obj).forEach((ite) => {
      const { gname, modarr } = ite;
      if (temp.hasOwnProperty(gname)) {
        temp[gname] = {
          gname,
          modarr: [...temp[gname].modarr, ...modarr],
        };
      } else {
        temp[gname] = ite;
      }
    });
    return temp;
  } catch {
    return obj;
  }
}
export async function getReceipt(resId, orderId) {
  const res = await fetch(`/api/r/${resId}/${orderId}`);

  const json = await res.json();
  if (res.status === 200) {
    // console.log(json);
    return json;
  } else {
    // toast.error(json.msg);
  }
}
export async function getTableReceipt(resId, orderId) {
  const res = await fetch(`/api/r/table/${resId}/${orderId}`);

  const json = await res.json();
  if (res.status === 200) {
    // console.log(json);
    return json;
  } else {
    // toast.error(json.msg);
  }
}

export async function payRequest(data) {
  let res = await fetch(`/api/order/${data.restaurant_id}/request/pay`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function payOnline(data) {
  var payload = {
    orderId: data.orderId,
    customer_id: data.customer_id,
    card: {
      number: data.cardNum.replace(/ /g, ""),
      exp_month: data.expiry.split("/")[0].replace(/ /g, ""),
      exp_year: data.expiry.split("/")[1].replace(/ /g, ""),
      cvv: data.cvv,
    },
    tip: data.tip,
    notes: data.notes,
  };
  let res = await fetch(`/api/order/${data.restaurant_id}/pay/online`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function payOnlineCustom(data) {
  var payload = {
    orderId: data.orderId,
    amount: parseFloat(data.orderData.total) * 100,
    card: {
      number: data.cardNum.replace(/ /g, ""),
      exp_month: data.expiry.split("/")[0].replace(/ /g, ""),
      exp_year: data.expiry.split("/")[1].replace(/ /g, ""),
      cvv: data.cvv,
    },
    notes: data.notes,
  };
  let res = await fetch(`/api/order/${data.restaurant_id}/charge/pay/online`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function payOnlineCustomLater(data, checkoutId) {
  var payload = {
    customer: {
      name: "",
      email: "",
    },
    orderId: data.orderId,
    amount: parseFloat(data.orderData.total) * 100,
    card: {
      number: data.cardNum.replace(/ /g, ""),
      exp_month: data.expiry.split("/")[0].replace(/ /g, ""),
      exp_year: data.expiry.split("/")[1].replace(/ /g, ""),
      cvv: data.cvv,
    },
    checkoutId: checkoutId,
    notes: data.notes,
  };
  let res = await fetch(`/api/order/${data.restaurant_id}/pay/later/online`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getPopularItems(restaurantId, integrate, language) {
  const res = await fetch(`/api/get/popular/${restaurantId}/${integrate}?language=${language}`);
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export function timeDelay(sec = 5) {
  return new Promise(resolve => setTimeout(() => resolve('Success'), sec * 1000));
}