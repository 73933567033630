import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useSearchParams, useParams } from "react-router-dom";
import { async } from "q";
import BeatLoader from "react-spinners/BeatLoader";
import MultiLingualContent from '../../context/multilingualContent';
function TableThankyou(props) {
  // const { tableId } = ;
  const [searchParams] = useSearchParams();
  const [tableId, setTableId] = useState(useParams()["tableId"]);
  const [validData, setValidData] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [fetching, setFetching] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    useParams()["restaurantsId"]
  );
  const [orderId, setOrderId] = useState(searchParams.get("order_id"));
  const [rewardPoints, setRewardPoints] = useState(
    searchParams.get("reward_points")
  );
  const [tablePaymentState, setTablePaymentState] = useState({
    table: false,
    is_pay_online: 0,
    is_pay_restaurant: 0,
  });
  useEffect(() => {
    async function fetchData() {
      getRestaurantTableSettings();
      let checkIsValid = await fetch(
        `/api/v1/table/get/table/thankyou/data/${restaurantId}?tableId=${tableId}&tableOrderId=${orderId}`
      );
      checkIsValid = await checkIsValid.json();
      console.log(checkIsValid);
      if (!checkIsValid) {
        setValidData(false);
      } else {
        console.log(checkIsValid.data.updatedAt);
        let expiresIn =
          new Date(checkIsValid.data.updatedAt).getTime() + 30 * 60000;
        let currentTime = new Date().getTime();
        console.table({ expiresIn, currentTime });
        if (
          checkIsValid.data.payment_status.toLowerCase() === "not paid" ||
          expiresIn > currentTime
        ) {
          setValidData(true);
          setOrderData(checkIsValid.data);
        } else {
          console.log("expired");
          setValidData(false);
        }
      }
      setFetching(false);
    }
    fetchData();
  }, []);
  async function getRestaurantTableSettings() {
    try {
      let response = await fetch(
        `/api/v1/merchant/get/restaurant/table-order/settings/${restaurantId}?tableId=${tableId}`
      );
      response = await response.json();
      console.log("response", response.data);

      if (response.data) {
        let { table, is_pay_online, is_pay_restaurant } = response.data;
        setTablePaymentState((prevState) => ({
          ...prevState,
          table,
          is_pay_online,
          is_pay_restaurant,
        }));
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }
  // START HERE
  const handelContinue = () => {
    window.location.href = `/${restaurantId}/table?tableId=${tableId}`;
  };
  const handelPayNoq = () => {
    window.location.href = `/${restaurantId}/table/payment/${tableId}?table_order_Id=${orderId}`;
  };

  return (
    <>
      <div className="cart-header-thank d-flex">
        {
          props.restaurant.restaurant.logo ? <div className="tlogo">
            <img
              style={{ maxWidth: "85px" }}
              src={props.restaurant.restaurant.logo}
              alt="logo"
            />
          </div> : <></>
        }
        
        <div className="taddress">
          <h2>{props.restaurant.restaurant.name}</h2>
          <p>
            {props.restaurant.address.address1}{" "}
            {props.restaurant.address.address2}, {props.restaurant.address.city}
            , {props.restaurant.address.country}, {props.restaurant.address.zip}
          </p>
        </div>
      </div>
      {fetching ? (
        <></>
      ) : validData ? (
        <>
          {/* {console.log(orderData)} */}

          <div className="table-thank">
            {orderData.payment_status.toLowerCase() === "paid" ? (
              <h2><MultiLingualContent contentID="Your Payment was Successful" />!</h2>
            ) : (
              <></>
            )}
            <h2><MultiLingualContent contentID="Thanks for your order" />! </h2>
            <p>
            <MultiLingualContent contentID="Please rescan the QR code to Add more" /> <br />
            <MultiLingualContent contentID="items to your existing order or Pay" />.
            </p>
            <p><MultiLingualContent contentID="Enjoy your meals" />!</p>
            <p style={{ fontSize: "18px" }}>
              {" "}
              <MultiLingualContent contentID="Please" />{" "}
              <a
                href={`https://${window.location.host}/r/${restaurantId}/table/${orderId}`}
                style={{ textDecoration: "underline", fontWeight: "500" }}
                target="_blank"
              >
                <MultiLingualContent contentID="click here" />
              </a>{" "}
              <MultiLingualContent contentID="to view your order details" />.
            </p>
            {rewardPoints && (
              <p>⭐You Earned {rewardPoints} points as Reward point</p>
            )}
            {tablePaymentState.is_pay_online ? (
              <div className="thankbtns">
                {orderData.payment_status.toLowerCase() === "not paid" ? (
                  <Button onClick={handelPayNoq}><MultiLingualContent contentID="Pay Now" /></Button>
                ) : (
                  <></>
                )}
                {/* <Button onClick={handelContinue}>Continue to Order</Button> */}
              </div>
            ) : (
              <></>
            )}

            {/* <div>
              <img
                style={{ width: "100%", maxWidth: "300px", margin: "0 auto" }}
                src="/orders.gif"
              />
            </div> */}
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="table-thank">
            <h2><MultiLingualContent contentID="Page you are looking is not available or expired" /></h2>
            {/* <div className="thankbtns">
    <Button>Pay Now</Button>
    <Button onClick={handelContinue}>Continue to Order</Button>
  </div> */}
          </div>
        </>
      )}
      <table class="foot-table" style={{ margin: "10em auto 0" }}>
        <tr>
          <td style={{ width: "auto", verticalAlign: "middle" }}>
            <p
              class="footer-txt"
              style={{
                fontSize: "16px",
                textAlign: "right",
                margin: "0",
                fontWeight: "600",
              }}
            >
              Powered by
            </p>
          </td>
          <td style={{ width: "auto", verticalAlign: "middle" }}>
            <img
              style={{ maxWidth: "120px" }}
              src="/logo.svg"
              class="foot-logo"
            />
          </td>
        </tr>
      </table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    orderCart: state.cart.orderCart,
    total: state.cart.total,
    tablePath: state.restaurant.tablePath,
    totalPrice: state.cart.totalPrice,
    restaurant: state.restaurant,
    table: state.table,
  };
};

export default connect(mapStateToProps)(TableThankyou);
