import { v4 as uuid } from "uuid";
import moment from "moment-timezone";

import {
  getCustomer,
  getRestaurantDetails,
  getDashboardDetails,
  getProdMetaData,
  getRestaurantService,
  timeDelay,
} from "./api";
import jwt_decode from "jwt-decode";
import DefaultLogo from "../assets/default.jpg";
import { translations } from "../context/translations";
export function round(num) {
  return Math.round(num * 100) / 100;
}

export function groupByProduct(key, array) {
  var result = [];
  var orders = {};
  for (let i = 0; i < array.length; i++) {
    var added = false;
    for (var j = 0; j < result.length; j++) {
      if (result[j][key] === array[i][key]) {
        result[j].items.push(array[i]);
        added = true;
        break;
      }
    }
    if (!added) {
      var entry = { items: [] };
      entry[key] = array[i][key];
      entry.items.push(array[i]);
      result.push(entry);
    }
  }
  for (let i = 0; i < result.length; i++) {
    orders[result[i]["name"]] = {
      num: result[i]["items"].length,
      title: result[i]["name"],
      productId: result[i]["items"][0]["item"]["id"],
      price: result[i]["items"][0]["price"] / 100,
      img: "",
    };
  }
  return orders;
}

export const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );

export const getCookies = () => {
  return document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value),
      }),
      {}
    );
};
export const removeCookies = (key) => {
  let token = getCookies()[key];
  while (token) {
    document.cookie =
      key + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
    token = getCookies().key;
  }
  return 'removed'
};

export function groupByProductCategories(key, array) {
  var result = [];
  for (let i = 0; i < array.length; i++) {
    var added = false;
    for (var j = 0; j < result.length; j++) {
      if (result[j][key] === array[i][key]) {
        result[j].items.push(array[i]);
        added = true;
        break;
      }
    }
    if (!added) {
      var entry = { items: [] };
      entry[key] = array[i][key];
      entry.items.push(array[i]);
      result.push(entry);
    }
  }
  return result;
}

export default function initialization(state, props, restaurant_id, setValue) {
  return new Promise(async (resolve, reject) => {
    let exceptionList = [
      "pricing",
      "thankyou",
      "r",
      "qr",
      "contact",
      "payment",
      "pay",
      "clover-integration",
      "merchant",
      "customer",
    ];
    if (!exceptionList.includes(restaurant_id)) {
      const cookie = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      const paramsData = new URLSearchParams(window.location.search);
      const aiOrder = paramsData.get('sms_order') === 'true' || false
      if (restaurant_id) {
        if (
          window.location.pathname.replace(/^\/*|\/*$/g, "").split("/")
            .length === 1
        ) {
          document
            .getElementsByTagName("body")[0]
            .classList.add("restaurant-body");
        }
        if (
          window.location.pathname.replace(/^\/|\/$/g, "").split("/").length ===
          2
        ) {
          document
            .getElementsByTagName("body")[0]
            .classList.add("restaurant-body-table");
          if (!Object.keys(cookie).includes("__simmer_sid")) {
            document.cookie = `__simmer_sid=${uuid()}; expires=${moment()
              .endOf("d")
              .format("LLL")}; path=/`;
          }
        }

        const rstService = await getRestaurantService(restaurant_id);
        // FORCED DISABLE AWS CONNECT
        if (rstService && rstService.chatWidget && false) {
          var s = document.createElement("script");
          s.type = "text/javascript";
          var code = `(function(w, d, x, id){
                    s=d.createElement('script');
                    s.src='https://d2s9x5slbvr0vu.cloudfront.net/amazon-connect-chat-interface-client.js';
                    s.async=1;
                    s.id=id;
                    d.getElementsByTagName('head')[0].appendChild(s);
                    w[x] = w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
                        })(window, document, 'amazon_connect', '36c7b0a3-6e85-4e86-9445-adf18363ca7c');
                            amazon_connect('styles', { openChat: { color: 'white', backgroundColor: '#ec7211'}, closeChat: { color: 'white', backgroundColor: '#ec7211'} });
                            amazon_connect('snippetId', 'QVFJREFIajNZWm9TMmtpZVZPZlMwcnFsWVF1ZFc1T1poSjlRM3U2T01NOTdhRGhSRGdINUNKQk1BeDJMSEdQUjNEcmIxbHUyQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNbEJ1SkNkSG41SzM3eEdRekFnRVFnQ3ROanRCS1dBRDJCTXladWROdERkM3NQODJGQVdLUkVRWkxBUWQyL1FWeExRNWxZbDBFeTJFOUZ5clg6OkYveWhRQXBOYmxLb0ZBbHV1cnZva3JMOTBPWDJGR21HYUZHa0ZPVzFhYmQyeGFGWXh2VGErT1cxemQ5ZHE0NFhWM1dxemtNeERrT21Tb2N2bDc4VFNJdTNPWVFQZzlCUTM2NlFzZGJFcmVldFNRbGJRZGNib055bnlFeldVb3ZQQWpVRCtvNmdZRTFCUlR2S0RYNUJzUU8xR3JGejJnOD0=');
                            amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown' ]);
                            amazon_connect('authenticate', function(callback) {
                            window.fetch('/aws/connect/token/${restaurant_id}').then(res => {
                            res.json().then(data => {
                            callback(data.data);
                            });
                        });
                    });
                    `;
          try {
            s.appendChild(document.createTextNode(code));
            document.body.appendChild(s);
          } catch (e) {
            s.text = code;
            document.body.appendChild(s);
          }
        }
        // console.log(restaurant_id, window.location.origin)
        if (!window.location.search.includes('chat_bot_type') && ['square-pie-guys', 'custom-test'].includes(restaurant_id) && false) {
          console.log('df-message')
          let df = document.createElement("df-messenger");
          df.setAttribute("intent", "WELCOME");
          df.setAttribute("chat-title", "AiOrder");
          df.setAttribute("agent-id", "35931068-54ae-4736-85be-aa3b4aabb531");
          df.setAttribute("user-id", restaurant_id);
          df.setAttribute("language-code", "en");
          df.setAttribute(
            "chat-icon",
            "https://storage.googleapis.com/msgsndr/2LdIC4qDXDxOYKynP5Ux/media/6287a54c76aee9205ff8d75b.png"
          );
          document.querySelector("body").appendChild(df);
        }
        try {
          let data = await getRestaurantDetails(restaurant_id, props.language);
          if (data.redirect) {
            setValue({ redirect: true, redirectData: data });
            return 1;
          }
          const prodData = await getProdMetaData(restaurant_id, props.language);
          data["tablePath"] = window.location.pathname
            .replace("/cart", "")
            .replace("/pay-on-cash", "")
            .replace("/pay-online", "")
            .replace(/\/+$/, "");
          data["id"] = restaurant_id;
          if (props.is_support) {
            data["is_support"] = props.is_support;
          }

          if (props.language) {
            data["language"] = props.language;
          }
          if (data["square_env"]) {
            const script = document.createElement("script");
            script.src =
              data["square_env"].toLowerCase() === "production"
                ? "https://web.squarecdn.com/v1/square.js"
                : "https://sandbox.web.squarecdn.com/v1/square.js";
            document.head.appendChild(script);
          }
          if (aiOrder) {
            data["sms_order"] = true;
            data["method"] = 'online';
          } else if (props.method) {
            data["method"] = props.method;
          }
          props.RestaurantData(data);
          if (!props.tableId && !window.location.pathname.includes("table")) {
            setValue({
              temporarily_closed: data.restaurant_open ? !data.isPayRestaurant && !data.isPayonline : true,
              restaurant_name: data.restaurant.name,
            });
          }
          if (props.phone) {
            if (data["integrate"] === "clover") {
              const customer = await getCustomer(restaurant_id, props.phone);
              if (customer["elements"].length) {
                const customerData = customer["elements"][0];
                customerData["isLoggedIn"] = true;
                customerData["phone"] = props.phone.replace("%2B", "+");
                props.CustomerData({
                  ...customerData,
                });
              } else {
                var customerData = {};
                customerData["phone"] = props.phone.replace("%2B", "+");
                props.CustomerData({
                  ...customerData,
                });
              }
            } else {
              var customerData = {};
              customerData["phone"] = props.phone.replace("%2B", "+");
              props.CustomerData({
                ...customerData,
              });
            }
            if (props.connect) {
              var customerData = {};
              customerData["connect"] = props.connect;
              props.CustomerData({
                ...customerData,
              });
            }
          }
          if (props.custom) {
            var customerData = {};
            customerData["custom"] = props.custom;
            props.CustomerData({
              ...customerData,
            });
          }
          if (props.tableId) {
            const tableFetchRaw = fetch(
              `/api/v1/table/get/table/data/${restaurant_id}/${data["integrate"]}?tableId=${props.tableId}`
            );

            const combinedPromise = await Promise.all([tableFetchRaw]);
            const [tableFetchJson] = await Promise.all([
              combinedPromise[0].json(),
            ]);
            if (tableFetchJson) {
              const {
                id,
                name,
                availability,
                customerOrders,
                available,
                is_pay_restaurant,
                is_pay_online,
                restaurant_name,
                order_id,
              } = tableFetchJson.data;
              setValue({
                temporarily_closed: data.restaurant_open ? !is_pay_restaurant && !is_pay_online : true,
                restaurant_name: restaurant_name,
              });
              let customer_id, items, subTotal, tip, tax, total;
              let customerOrdersData = customerOrders[0];
              if (customerOrdersData) {
                customer_id = customerOrdersData.customer_id;
                items = customerOrdersData.items;
                subTotal = customerOrdersData.subTotal;
                tip = customerOrdersData.tip;
                tax = customerOrdersData.tax;
                total = customerOrdersData.total;
              }
              let temp = {
                table_name: name,
                table_id: id,
                table_status: Boolean(available),
                table_availability: Boolean(availability),
                table_customer_id: customer_id,
                table_order_id: order_id,
                table_orders_id: items?.map((item) => item.order_id),
                table_total_amount: total,
              };
              if (availability) {
                if (!available) {
                  const customerFetchRaw = await fetch(
                    `/api/v1/table/get/table/customer/data/${restaurant_id}/${data["integrate"]}?id=${customer_id}`
                  );
                  const customerFetchJson = await customerFetchRaw.json();
                  if (customerFetchJson["phoneNumbers"]["elements"].length) {
                    const customerData = customerFetchJson;
                    customerData["isLoggedIn"] = true;
                    customerData["phone"] =
                      customerFetchJson["phoneNumbers"]["elements"][0][
                      "phoneNumber"
                      ];
                    props.CustomerData({
                      ...customerData,
                    });
                  } else {
                    var customerData = {};
                    customerData["phone"] =
                      customerFetchJson["phoneNumbers"]["elements"][0][
                      "phoneNumber"
                      ];
                    props.CustomerData({
                      ...customerData,
                    });
                  }
                }
                if (customer_id) {
                  temp = {
                    ...temp,
                    previous_total: total,
                    previous_subTotal: subTotal,
                    previous_tax: tax,
                    previous_tip: tip,
                    table_previous: true,
                  };
                }
                if (!temp.table_status && temp.table_orders_id.length) {
                  setValue({ tableOccupied: true });
                }
              }
              setValue({ table_availability: !Boolean(availability) });
              props.TABLE_INIT(temp);
            }
          }
          const isLocalStorage = localStorage.getItem(restaurant_id),
            locItem = JSON.parse(localStorage.getItem(restaurant_id));
          if (isLocalStorage) {
            !locItem.total && localStorage.removeItem(restaurant_id);
            !locItem.expire && localStorage.removeItem(restaurant_id);
            if (locItem.expire <= new Date().getTime()) {
              localStorage.removeItem(restaurant_id);
              window.location.reload(false);
            }
          }

          const translatedWord = (word) =>
            translations[props.language]
              ? translations[props.language][word]
              : translations["en"][word];
          let products = {};
          let Others = translatedWord("Others");
          // console.log(data["items"]["elements"])
          data["items"]["elements"].length &&
            (await Promise.all(
              data["items"]["elements"].map(async (product) => {
                let toHideOtherCategory = !(
                  [
                    "pop-up-tea-coffee-and-snacks",
                    "el-gran-sabor",
                    "el-gran-sabor-food-truck",
                  ].includes(restaurant_id.toLowerCase()) &&
                  product.categories.elements.length === 0
                );
                if (
                  !product.hidden &&
                  toHideOtherCategory &&
                  product.available
                ) {
                  // products[capitalizeFirstLetter(product.name)] = {
                  products[product.name] = {
                    num: 0,
                    img: prodData?.[product.id]
                      ? prodData[product.id]["img"]
                        ? prodData[product.id]["img"]
                        : DefaultLogo
                      : DefaultLogo,
                    desc: prodData?.[product.id]
                      ? prodData[product.id]["desc"]
                        ? prodData[product.id]["desc"]
                        : ""
                      : "",
                    title: product.name,
                    // title: capitalizeFirstLetter(product.name),
                    category: product.categories.elements.length
                      ? product.categories.elements[0]["name"]
                      : Others,
                    modifierGroups: product.modifierGroups?.elements.length
                      ? product.modifierGroups.elements.map((group) => ({
                        [group.id]: group.name ? group.name : "modGroup",
                      }))
                      : [],
                    productId: product.id,
                    tags: product.tags,
                    price: product.price / 100,
                    stock: product.available,
                    hidden: product.hidden,
                  };
                  return 1;
                }
              })
            ));
          props.INIT(products);
          if (isLocalStorage && locItem.total) {
            Object.entries(locItem.items).forEach((value, key) => {
              const title = value[0],
                item = value[1];
              if (
                !(
                  products[title]["stock"] === item["stock"] &&
                  products[title]["hidden"] === item["hidden"]
                )
              ) {
                localStorage.removeItem(restaurant_id);
                window.location.reload(false);
              }
            });
          }
          let keyName = `${restaurant_id}_sms_orders`;
          const orderIdFromCookie = getCookies()[`${keyName}_id`];
          let locItemSms = localStorage.getItem(keyName);
          if (aiOrder && orderIdFromCookie && locItemSms) {
            console.log('aiorders', orderIdFromCookie);
            locItemSms = JSON.parse(locItemSms);
            const { lineItems, customer_details, order_details } = locItemSms;
            await timeDelay(2)
            for (let item of lineItems) {
              console.log(item);
              props.Add(
                item.name,
                item.quantity,
                restaurant_id,
                item.modifiers,
                '',
                item.productId
              );
            }
            props.Utils({ payment_order_id: orderIdFromCookie, order_method: order_details.order_method, delivery_address: customer_details.delivery_address });
            localStorage.removeItem(keyName);
            props.navigate(`/${restaurant_id}/cart`)
          } else {
            console.log('aiorders not found');
            isLocalStorage && locItem.total && props.LOC(locItem);
            if (orderIdFromCookie) {
              const keyName = `${restaurant_id}_sms_orders_id`;
              console.log('aiorders Cookies found!');
              props.Utils({ payment_order_id: orderIdFromCookie });
            }
          }
          setValue({ loading: !state.loading, type: "app" });
        } catch (error) {
          console.log(error);
        }
      } else {
        setValue({ loading: !state.loading, type: "website" });
      }
    } else if (restaurant_id === "merchant") {
      const token = getCookies().fd_token;
      if (token) {
        var decoded = jwt_decode(token);
        let restaurant_id;
        let integrate;
        if (window.localStorage.getItem("simmer")) {
          let localS = JSON.parse(window.localStorage.getItem("simmer"));
          restaurant_id = localS["restaurant"];
          integrate = localS["integrate"];
        } else {
          restaurant_id = decoded.resId;
          integrate = decoded.integrate;
        }
        const expireIn = decoded.exp * 1000;
        if (expireIn < new Date().getTime()) {
          let token = getCookies().fd_token;
          while (token) {
            document.cookie =
              "fd_token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
            token = getCookies().fd_token;
          }
          window.location.reload(false);
        } else {
          try {
            props.CustomerData({ ...decoded, resId: restaurant_id, integrate });
            const data = await getDashboardDetails(restaurant_id);
            const prodData = await getProdMetaData(
              restaurant_id,
              props.language
            );
            data["id"] = restaurant_id;
            props.RestaurantData(data);
            const translatedWord = (word) =>
              translations[props.language]
                ? translations[props.language][word]
                : translations["en"][word];
            let products = {};
            let Others = translatedWord("Others");
            await Promise.all(
              data["items"]["elements"].map(async (product) => {
                // products[capitalizeFirstLetter(product.name)] = {
                products[product.name] = {
                  num: 0,
                  img: prodData?.[product.id]
                    ? prodData[product.id]["img"]
                      ? prodData[product.id]["img"]
                      : DefaultLogo
                    : DefaultLogo,
                  desc: prodData?.[product.id]
                    ? prodData[product.id]["desc"]
                      ? prodData[product.id]["desc"]
                      : ""
                    : "",
                  // title: capitalizeFirstLetter(product.name),
                  title: product.name,
                  tags: product.tags,
                  category: product.categories.elements.length
                    ? product.categories.elements[0]["name"]
                    : Others,
                  modifierGroups: product.modifierGroups?.elements.length
                    ? product.modifierGroups.elements.map((group) => ({
                      [group.id]: group.name,
                    }))
                    : [],
                  productId: product.id,
                  price: product.price,
                  // price: product.price / 100,
                  stock: product.available,
                  stockCount: product.stockCount,
                  initialStockCount: product.initial_stock,
                  autostock: product.autostock,
                  hidden: product.hidden,
                };
                return 1;
              })
            );
            props.INIT(products);
            setValue({ loading: !state.loading, type: "merchant" });
            resolve(1);
          } catch (error) {
            console.log(error);
            console.log("Error in cookie login");
            let token = getCookies().fd_token;
            while (token) {
              document.cookie =
                "fd_token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
              token = getCookies().fd_token;
            }
            window.location.reload(false);
            resolve(1);
          }
        }
      } else {
        window.localStorage.removeItem('simmer');
        setValue({ loading: !state.loading, type: "merchant" });
        resolve(1);
      }
    } else if (restaurant_id === "customer") {
      const token = getCookies().fdc_token;
      if (token) {
        var decoded = jwt_decode(token);
        const expireIn = decoded.exp * 1000;
        if (expireIn < new Date().getTime()) {
          let token = getCookies().fdc_token;
          while (token) {
            document.cookie =
              "fdc_token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
            token = getCookies().fdc_token;
          }
          window.location.reload(false);
          resolve(1);
        } else {
          try {
            props.CustomerData({ ...decoded });
            setValue({ loading: !state.loading, type: "customer" });
          } catch (error) {
            let token = getCookies().fdc_token;
            while (token) {
              document.cookie =
                "fdc_token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
              token = getCookies().fdc_token;
            }
            window.location.reload(false);
          }
        }
        resolve(1);
      } else {
        setValue({ loading: !state.loading, type: "customer" });
      }
    } else if (restaurant_id === "contact") {
      const [route, restId, orderId] = window.location.pathname
        .split("/")
        .slice(1);
      const combinedStr = `${restId}+${orderId}`;
      const rstService = await getRestaurantService(restId);
      if (rstService && rstService.chatWidget) {
        var s = document.createElement("script");
        s.type = "text/javascript";
        var code = ` (function(w, d, x, id){
            s=d.createElement('script');
            s.src='https://d2s9x5slbvr0vu.cloudfront.net/amazon-connect-chat-interface-client.js';
            s.async=1;
            s.id=id;
            d.getElementsByTagName('head')[0].appendChild(s);
            w[x] = w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
            })(window, document, 'amazon_connect', '36c7b0a3-6e85-4e86-9445-adf18363ca7c');
                amazon_connect('styles', { openChat: { color: 'white', backgroundColor: '#ec7211'}, closeChat: { color: 'white', backgroundColor: '#ec7211'} });
                amazon_connect('snippetId', 'QVFJREFIajNZWm9TMmtpZVZPZlMwcnFsWVF1ZFc1T1poSjlRM3U2T01NOTdhRGhSRGdINUNKQk1BeDJMSEdQUjNEcmIxbHUyQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNbEJ1SkNkSG41SzM3eEdRekFnRVFnQ3ROanRCS1dBRDJCTXladWROdERkM3NQODJGQVdLUkVRWkxBUWQyL1FWeExRNWxZbDBFeTJFOUZ5clg6OkYveWhRQXBOYmxLb0ZBbHV1cnZva3JMOTBPWDJGR21HYUZHa0ZPVzFhYmQyeGFGWXh2VGErT1cxemQ5ZHE0NFhWM1dxemtNeERrT21Tb2N2bDc4VFNJdTNPWVFQZzlCUTM2NlFzZGJFcmVldFNRbGJRZGNib055bnlFeldVb3ZQQWpVRCtvNmdZRTFCUlR2S0RYNUJzUU8xR3JGejJnOD0=');
                amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown' ]);
                amazon_connect('authenticate', function(callback) {
                window.fetch('/aws/connect/token/${combinedStr}').then(res => {
                    res.json().then(data => {
                    callback(data.data);
                    });
                });
            });
            amazon_connect('customerDisplayName', function(callback) {
                window.fetch('/aws/connect/displayname/${combinedStr}').then(res => {
                    res.json().then(data => {
                    const {DisplayName} = data.data;
                    callback(DisplayName);
                    });
                });
            });
            `;
        try {
          s.appendChild(document.createTextNode(code));
          document.body.appendChild(s);
        } catch (e) {
          s.text = code;
          document.body.appendChild(s);
        }
      }
      setValue({ loading: !state.loading, type: "website" });
      resolve(1);
    } else {
      setValue({ loading: !state.loading, type: "website" });
      resolve(1);
    }
  });
}

export async function squareInit(app_id, location_id) {
  return new Promise((resolve, reject) => {
    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }
    let payments;
    try {
      payments = window.Square.payments(app_id, location_id);
      resolve(payments);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export async function squarePaymentsInit(
  payments,
  setValue,
  total = undefined
) {
  return new Promise(async (resolve, reject) => {
    let card;
    try {
      card = await payments.card();
      await card.attach("#card-container");
      setValue({
        card,
      });
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }

    if (total) {
      let googlePay;
      let applePay;
      try {
        const paymentRequest = payments.paymentRequest({
          countryCode: "US",
          currencyCode: "USD",
          requestBillingContact: true,
          total: {
            amount: String(total.toFixed(2)),
            label: "Total",
          },
        });
        googlePay = await payments.googlePay(paymentRequest);
        await googlePay.attach("#google-pay-button");
        setValue({
          googlePay,
        });
        // applePay = await payments.applePay(paymentRequest);
        // setValue({
        //     applePay
        // });
        resolve(true);
      } catch (e) {
        resolve(true);
        console.error(e);
      }
    }
  });
}

export async function squarePaymentsReInit(state, total, payments, setValue) {
  return new Promise(async (resolve, reject) => {
    let googlePay;
    let applePay;
    try {
      const paymentRequest = payments.paymentRequest({
        countryCode: "US",
        currencyCode: "USD",
        requestBillingContact: true,
        total: {
          amount: String(total.toFixed(2)),
          label: "Total",
        },
      });
      googlePay = await payments.googlePay(paymentRequest);
      if (state.googlePay) state.googlePay.detach();
      await googlePay.attach("#google-pay-button");
      setValue({
        googlePay,
      });
      // applePay = await payments.applePay(paymentRequest);
      // setValue({
      //     applePay
      // });
      resolve(true);
    } catch (e) {
      resolve(true);
      console.error(e);
    }
  });
}

export async function squareTokenize(paymentMethod, setValue) {
  const tokenResult = await paymentMethod.tokenize();
  if (tokenResult.details.method !== "Card") {
    setValue({
      firstName: tokenResult.details.billing.givenName || tokenResult.details.billing.email || tokenResult.details.billing.phone || 'no name',
      email: tokenResult.details.billing.email
        ? tokenResult.details.billing.email
        : "",
      mobile: tokenResult.details.billing.phone
        ? tokenResult.details.billing.phone
        : "",
    });
  }

  if (tokenResult.status === "OK") {
    return tokenResult.token;
  } else {
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
    }

    throw new Error(errorMessage);
  }
}
