import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllOrders, getReceipt, joinObjSameKeyGname } from "../../utils/api";
import { setOrderStatus } from "../Functions/fetch";
import { Col, Container } from "react-bootstrap";
import moment from "moment-timezone";
import BounceLoader from "react-spinners/BounceLoader";
// import axios from 'axios';
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { getUser } from "../../utils/api";
import Form from "react-bootstrap/Form";
import { CustomerData } from "../../redux/actions/customerActions";
import Modal from "react-bootstrap/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./pages.css";
import { collapseToast } from "react-toastify";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { BiCreditCard } from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
import MultiLingualContent from "../../context/multilingualContent";
import {
  FaGoogle,
  FaCcApplePay,
  FaCcVisa,
  FaMoneyBillAlt,
} from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { SiGooglepay, SiApple } from "react-icons/si";
import { GiWallet } from "react-icons/gi";
import { warnRedBox } from "../Functions/function";
const payIcon = {
  google: <SiGooglepay style={{ fontSize: "38px", color: "#444" }} />,
  wallet: <GiWallet style={{ fontSize: "20px", color: "#444" }} />,
  apple: <SiApple style={{ fontSize: "22px", color: "#444" }} />,
  card: <BiCreditCard style={{ fontSize: "26px", color: "#444" }} />,
  cash: <FaMoneyBillAlt style={{ fontSize: "26px", color: "#666" }} />,
  null: <></>,
};

const getCookies = () => {
  return document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value),
      }),
      {}
    );
};
const capitalizeFirstLetter = (str) => {
  // console.log(str);
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
};

const UpdateButton = (props) => {
  const [ready, setready] = useState(false);
  const [close, setclose] = useState(false);

  // const foodReady = async (data) => {
  // let action = "ready";
  // setready(true);
  // const res = setFoodStatus(data, action);
  // };
  // const foodClose = async (data) => {
  // let action = "close";
  // setclose(true);
  // const res = setFoodStatus(data, action);
  // };
  const [type, setType] = useState("status");
  return (
    <div style={{ display: "flex" }}>
      <Form.Group controlId="formBasicSelect" className="selectbox">
        <Form.Control
          as="select"
          value={type}
          onChange={(e) => {
            // console.log("e.target.value", e.target.value);
            e.target.value && setType(e.target.value);
            e.target.value && setOrderStatus(props.data, e.target.value);
          }}
        >
          <option value="">status</option>
          <option value="processed">Processed</option>
          <option value="ready">Ready</option>
          <option value="delivered">Delivered</option>
        </Form.Control>
      </Form.Group>
    </div>
  );
};
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
}

const ConfirmButton = (props) => {
  const [selected, setSelected] = useState({ title: "15 Minutes", value: 15 });
  const time = [
    { title: "5 Minutes", value: 5 },
    { title: "10 Minutes", value: 10 },
    { title: "15 Minutes", value: 15 },
    { title: "20 Minutes", value: 20 },
    { title: "25 Minutes", value: 25 },
    { title: "30 Minutes", value: 30 },
    { title: "35 Minutes", value: 35 },
    { title: "40 Minutes", value: 40 },
    { title: "45 Minutes", value: 45 },
    { title: "50 Minutes", value: 50 },
    { title: "55 Minutes", value: 55 },
    { title: "60 Minutes", value: 60 },
  ];
  async function submitconfirmedTime() {
    try {
      const response = await fetch(
        `/api/v1/merchant/update/confirmedtime/${props.restaurant.id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: { orderId: props.orderId, confirmedTime: selected, business_name: props.restaurant.restaurant.name.trim() },
          }),
        }
      );
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div>
        <p
          className="text-center"
          style={{ fontSize: "18px", fontWeight: 600 }}
        >
          Order ID: {props.orderId}
        </p>
        <p className="text-center" style={{ fontSize: "18px" }}>
          Preparation Time{" "}
          <BsIcons.BsClockHistory style={{ fontSize: "15px", color: "#444" }} />
        </p>{" "}
        <div className="text-center">
          <select
            // className="form-select"
            style={{
              border: "1px solid #e5e5e5",
              padding: "4px",
              borderRadius: "3px",
            }}
            aria-label="Default select example"
            defaultValue={JSON.stringify(selected)}
            onChange={(e) => {
              let { value } = e.target;
              let index = e.target.selectedIndex;
              let optionElement = e.target.childNodes[index];
              let id = optionElement.id;
              console.log(value, id);
              setSelected(JSON.parse(value));
            }}
          >
            {time.map((cat, key) => (
              <option value={JSON.stringify(cat)} id={cat.value} key={key}>
                {cat.title}
              </option>
            ))}
          </select>
          <Button
            style={{ display: "block", margin: "8px auto" }}
            onClick={() => {
              console.log(selected);
              submitconfirmedTime();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

class TrackerOrders extends Component {
  state = {
    orders: [],
    offset: 0,
    page: 1,
    pending: true,
    user: "",
    password: "",
    isAdmin: true,
    isOpen: false,
    isReady: false,
    isClose: false,
    loader: false,
    nodata: false,
    confirmedTime: false,
    selectedOrderId: "",
    columns: [],
    disbtn: false,
    confirmation: false, currentDiscountBy: "",
  };
  componentWillUnmount() {
    clearInterval(this.countDownInterval);
  }
  openModal = async (row) => {
    this.setState({
      data: row,
      isOpen: true,
    });
  };
  closeModal = () => this.setState({ isOpen: false, confirmedTime: false });

  async componentDidMount() {
    this.setState({ loader: true });
    this.updateColumns();
    const items = await getAllOrders(
      this.props.restaurantId,
      40,
      this.state.offset,
      this.props.restaurantEle
    );
    this.handleUpdateState(items);
    const itemsAfter = await getAllOrders(
      this.props.restaurantId,
      9999999999,
      40,
      this.props.restaurantEle
    );
    if (itemsAfter.length) {
      itemsAfter.forEach((item) => {
        var data = {};
        // console.log(item);
        data["orderType"] = null;
        if (item.table_order) {
          data.orderType = item.order_type || "table";
          data.tableOrder = true;
        } else if (item.order_type) {
          data.orderType = item.order_type;
        }
        data["date"] = item["createdAt"];
        data["subTotal"] = item["subTotal"];
        data["confirmed_time"] = item["confirmed_time"];
        data["discount"] = item["discount"];
        data["conv_fee"] = item["conv_fee"];
        data["id"] = item["orderId"];
        data["tip"] = item["tip"];
        data["orderId"] = item["orderId"];
        data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
        data["payment"] = `${item["payment"]}`;
        data["payment_type"] = `${item["payment_type"]}`;
        data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
          ? `${formatPhoneNumber(item["Phone"])}`
          : "";
        data["total"] = `${item["total"]}`;
        // data["total"] = `$ ${item["total"]}`;
        data["status"] = item["status"];
        data["link"] = `/r/${item["orderId"]}`;
        item["items"] = item["items"].map((item) => {
          let temp = {};
          let parse = JSON.parse(item.modifiers);
          let group = item.modifierGroups?.elements;
          let groupId = {};
          if (["custom", "clover"].includes(this.props.restaurant.integrate)) {
            if (group) {
              group.forEach((grup) => {
                groupId[grup.id] = grup.name;
              });
            }
            if (parse) {
              let tempArr = [];
              let obijtemp = {};
              parse.forEach((ite) => {
                if (obijtemp[ite.modifier_group_id]) {
                  obijtemp[ite.modifier_group_id]["modarr"].push(
                    ite.modifier_name
                  );
                } else {
                  if (groupId[ite.modifier_group_id]) {
                    obijtemp[ite.modifier_group_id] = {
                      gname: groupId[ite.modifier_group_id].toUpperCase(),
                      modarr: [ite.modifier_name],
                    };
                  }
                }
              });
              let toJoinArr = [];
              Object.values(obijtemp).forEach((i) => {
                toJoinArr.push(`${i.gname} : ${i.modarr.join(", ")}`);
              });
              temp = { ...item, modifiers: obijtemp };
            } else {
              temp = { ...item };
            }
          } else if (this.props.restaurant.integrate === "square") {
            if (parse && parse.length) {
              let modifiers = {};
              parse.forEach((ite) => {
                Object.values(ite).forEach((data, i) => {
                  modifiers = {
                    ...modifiers,
                    [i]: {
                      gname: data.modifier_group_name || "Type",
                      modarr: [data.modifier_name],
                    },
                  };
                });
              });
              modifiers = joinObjSameKeyGname(modifiers);
              temp = {
                ...item,
                modifiers,
              };
            } else {
              temp = { ...item, modifiers: {} };
            }
          }

          return temp;
        });
        data["lineItem"] = item["items"];
        data["tax"] = item["tax"];
        data["notes"] = item["notes"];
        this.setState({
          orders: [...this.state.orders, data],
        });
      });
    } else {
      this.setState({
        orders: [...this.state.orders],
      });
    }
    this.countDownInterval = setInterval(async () => {
      const items = await getAllOrders(
        this.props.restaurantId,
        9999999999,
        0,
        this.props.restaurantEle
      );
      try {
        if (items.length !== this.state.orders.length) {
          // this.setState({
          // orders: [],
          // });
          this.handleUpdateState(items);
          // if (items.length) {
          // items.forEach((item) => {
          // var data = {};
          // // data["date"] = `${moment(item["createdAt"]).format(
          // // "DD-MMMM-YYYY HH:mm a"
          // // )}`;
          // data["date"] = item["createdAt"];
          // data["id"] = item["orderId"];
          // data["orderId"] = `ID : ${item["orderId"]}`;
          // data["tip"] = item["tip"];
          // data["CustomerName"] = item["name"]
          // ? `Name : ${item["name"]}`
          // : "";
          // data["subTotal"] = item["subTotal"];
          // data["payment"] = `${item["payment"]}`;
          // data["payment_type"] = `${item["payment_type"]}`;
          // data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
          // ? `${formatPhoneNumber(item["Phone"])}`
          // : "";
          // data["total"] = `${item["total"]}`;
          // // data["total"] = `$ ${item["total"]}`;

          // data["status"] = item["status"];
          // data["link"] = `/r/${item["orderId"]}`;
          // item["items"] = item["items"].map((item) => {
          // let temp = {};
          // let parse = JSON.parse(item.modifiers);
          // let group = item.modifierGroups?.elements;
          // let groupId = {};
          // if (group) {
          // group.forEach((grup) => {
          // // console.log(grup);
          // groupId[grup.id] = grup.name;
          // // groupId["name"] = grup.name;
          // // console.log(groupId);
          // });
          // }
          // if (parse) {
          // let tempArr = [];
          // let obijtemp = {};
          // parse.forEach((ite) => {
          // // console.log(ite);
          // if (obijtemp[ite.modifier_group_id]) {
          // obijtemp[ite.modifier_group_id]["modarr"].push(
          // ite.modifier_name
          // );
          // } else {
          // obijtemp[ite.modifier_group_id] = {
          // gname: groupId[ite.modifier_group_id].toUpperCase(),
          // modarr: [ite.modifier_name],
          // };
          // }
          // // tempArr.push(
          // // `${groupId[ite.modifier_group_id]} : ${ite.modifier_name}`
          // // );
          // });
          // // console.log(obijtemp);
          // // console.log(tempArr);
          // let toJoinArr = [];
          // Object.values(obijtemp).forEach((i) => {
          // toJoinArr.push(`${i.gname} : ${i.modarr.join(", ")}`);
          // });
          // temp = { ...item, modifiers: obijtemp };
          // // temp = { ...item, modifiers: toJoinArr.join(", ") };
          // // temp = { ...item, modifiers: tempArr.join(", ") };
          // } else {
          // temp = { ...item };
          // }
          // return temp;
          // });
          // data["lineItem"] = item["items"];
          // data["tax"] = item["tax"];
          // data["notes"] = item["notes"];
          // this.setState({
          // orders: [...this.state.orders, data],
          // });
          // });
          // } else {
          // this.setState({
          // orders: [],
          // });
          // }
        }
      } catch (error) {
        console.log(error);
      }
    }, 4000);
  }
  updateColumns = async () => {
    let columnsData = [];
    let confirmation = false;
    try {
      let response = await fetch(
        `/api/v1/merchant/get/restaurant/online-order/settings/${this.props.restaurantId}`
      );
      response = await response.json();
      // console.log("response", response.data);
      if (response.data) {
        const {
          common_settings: { order_settings },
        } = response.data;
        if (order_settings) {
          this.setState({
            confirmation:
              order_settings.togo_order_settings.confirmation_required,
          });
          confirmation =
            order_settings.togo_order_settings.confirmation_required;
        }
      }
    } catch (error) {
      console.log(error);
    }
    if (window.innerWidth > 768) {
      columnsData = [
        {
          name: "Date",
          cell: (row) => (
            <>
              {" "}
              <p className="order-date">
                {row.date} <br />
                {`ID: ${row.orderId}`} <br />
                {row.CustomerName && row.CustomerPhone ? (
                  <>
                    <span className="order-phone">{row.CustomerName}</span>{" "}
                    <br />
                    <span className="order-phone">
                      Phone :{" "}
                      <a
                        style={{
                          textDecoration: "underline",
                          color: "#000",
                          background: "none",
                        }}
                        href={`tel:${row.CustomerPhone}`}
                      >
                        {row.CustomerPhone}
                      </a>
                    </span>
                  </>
                ) : row.CustomerName ? (
                  <>
                    <span className="order-phone">{row.CustomerName}</span>{" "}
                  </>
                ) : row.CustomerPhone ? (
                  <>
                    <span className="order-phone">
                      Phone :{" "}
                      <a
                        style={{
                          textDecoration: "underline",
                          color: "#000",
                          background: "none",
                        }}
                        href={`tel:${row.CustomerPhone}`}
                      >
                        {row.CustomerPhone}
                      </a>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </p>
              {row.orderType ? (
                <span
                  style={{
                    backgroundColor: " #cccccc",
                    padding: "4px 8px",
                    borderRadius: "7px",
                    color: "#1a1a1a",
                    textTransform: "capitalize",
                  }}
                >
                  {row.orderType}
                </span>
              ) : (
                <></>
              )}
            </>
          ),
        },
        {
          name: "Line Items",
          cell: (row) => (
            <ul>
              {row.lineItem.map((item) => {
                return (
                  <li key={item.id}>
                    <span
                      style={{
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      {item.name}
                    </span>{" "}
                    <span style={{ fontSize: "12px" }}>x</span>{" "}
                    <span>{item.quantity}</span> <br />{" "}
                    {/* {capitalizeFirstLetter(item.name)} <br />{" "} */}
                    {item.modifiers ? (
                      Object.values(item.modifiers).map((ite) => {
                        return (
                          <>
                            <div
                              className="itemmodify"
                              style={{ padding: "3px 0" }}
                            >
                              {
                                <>
                                  <span>{` ${ite.gname
                                    .replace(/choose /gi, "")
                                    .replace(/an /gi, "")
                                    .replace(/add /gi, "")
                                    .toLocaleLowerCase()}: `}</span>
                                  <span>{`${ite.modarr.join(", ")}, `}</span>
                                </>
                              }
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                    <span style={{ paddingTop: "10px" }}>
                      $ {(item.totalPrice / 100).toFixed(2)}
                    </span>
                  </li>
                );
              })}
            </ul>
          ),
        },
        {
          name: "Total",
          selector: (row) => (
            <div>
              Total: $ {parseFloat(row.total / 100).toFixed(2)}
              {/* <br />
<span style={{ padding: "5px 0 0", display: "inline-block" }}>
Subtotal: $ {parseFloat(row.subTotal / 100).toFixed(2)}
</span> */}
            </div>
          ),
        },
        {
          name: "Status",
          cell: (row) => (
            <>
              <p>
                {row.payment === "Not Paid" ? (
                  <span id="not-paid">Not Paid 👎</span>
                ) : (
                  <span id="paid">
                    Paid <span>{payIcon[row.payment_type.toLowerCase()]}</span>
                  </span>
                )}
              </p>

              <div style={{ display: "block", width: "100%" }}>
                {/* <UpdateButton
data={{
...row,
resId: this.props.restaurantId,
integrate: this.props.restaurant.integrate,
}}
/> */}
              </div>
            </>
          ),
        },
        {
          name: "Details",
          cell: (row) => (
            <a onClick={() => this.openModal(row)} id={row.id}>
              View
            </a>
          ),
        },
        {
          name: "Order Status",
          cell: (row) => (
            <>
              {row.tableOrder ? <></> : row.confirmed_time ? (
                <span
                  style={{
                    color: "#fff",
                    padding: "4px 8px",
                    borderRadius: "7px",
                    backgroundColor: "#4da64d",
                  }}
                >
                  Confirmed
                </span>
              ) : (
                <Button
                  style={{
                    color: " #fff",
                    padding: "4px 8px",
                    borderRadius: "7px",
                    backgroundColor: "#d76a6f",
                    fontSize: "15px",
                  }}
                  onClick={() =>
                    this.setState({
                      confirmedTime: true,
                      selectedOrderId: row.orderId,
                    })
                  }
                >
                  Not Confirmed
                </Button>
              )}
            </>
          ),
        },
      ];
      if (!confirmation) {
        let notToShow = ["Order Status"];
        columnsData = columnsData.filter(
          (ite) => !notToShow.includes(ite.name)
        );
      }
    } else {
      columnsData = [
        {
          name: "Status",
          cell: (row) => (
            <>
              <div className="d-flex justify-content-between w-100">
                <div>
                  {row.orderType ? (
                    <div
                      style={{
                        backgroundColor: " #cccccc",
                        padding: "4px 8px",
                        borderRadius: "7px",
                        color: "#1a1a1a",
                        textTransform: "capitalize",
                      }}
                    >
                      {row.orderType}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div>
                  ID:{" "}
                  <span style={{ color: "#615f5f", fontWeight: "500" }}>
                    {row.orderId}
                  </span>{" "}
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 mt-2 md-3">
                <div>{row.CustomerName}</div>
                <div>{row.CustomerPhone ? row.CustomerPhone : ""}</div>
              </div>
              <div className="d-flex justify-content-between w-100 mt-2 md-3">
                <div>Date: {row.date}</div>
                <div></div>
              </div>
              <div className="mt-2 md-5">
                <ul>
                  {row.lineItem.map((item) => {
                    return (
                      <li key={item.id}>
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {item.name}
                        </span>{" "}
                        <span style={{ fontSize: "12px" }}>x</span>{" "}
                        <span>{item.quantity}</span> <br />{" "}
                        {item.modifiers ? (
                          Object.values(item.modifiers).map((ite) => {
                            return (
                              <>
                                <div
                                  className="itemmodify"
                                  style={{ padding: "3px 0" }}
                                >
                                  {
                                    <>
                                      <span>{` ${ite.gname
                                        .replace(/choose /gi, "")
                                        .replace(/an /gi, "")
                                        .replace(/add /gi, "")
                                        .toLocaleLowerCase()}: `}</span>
                                      <span>{`${ite.modarr.join(
                                        ", "
                                      )}, `}</span>
                                    </>
                                  }
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                        <span style={{ paddingTop: "10px" }}>
                          $ {(item.totalPrice / 100).toFixed(2)}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="d-flex justify-content-between align-items-center w-100 mt-3">
                <div>
                  <span style={{ color: "#615f5f", fontWeight: "600" }}>
                    Total:
                  </span>{" "}
                  $ {parseFloat(row.total / 100).toFixed(2)}
                </div>
                <div>
                  {row.payment === "Not Paid" ? (
                    <span id="not-paid">Not Paid 👎</span>
                  ) : (
                    <span id="paid">
                      Paid{" "}
                      <span>{payIcon[row.payment_type.toLowerCase()]}</span>
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 mt-3">
                <Button
                  onClick={() => this.openModal(row)}
                  id={row.id}
                  style={{
                    backgroundColor: "#808080",
                    padding: "4px 6px",
                    borderRadius: "7px",
                    color: "#fbeeef",
                    fontSize: "14px",
                  }}
                >
                  View
                </Button>
                {row.tableOrder ? <></> : confirmation ? (
                  row.confirmed_time ? (
                    <div
                      style={{
                        backgroundColor: "#4da64d",
                        padding: "4px 8px",
                        borderRadius: "7px",
                        color: "#fff",
                        fontSize: "14px",
                        height: "30px",
                      }}
                    >
                      Confirmed
                    </div>
                  ) : (
                    <Button
                      style={{
                        color: "#fff",
                        padding: "4px 6px",
                        borderRadius: "7px",
                        backgroundColor: "#d76a6f",
                        fontSize: "14px",
                      }}
                      onClick={() =>
                        this.setState({
                          confirmedTime: true,
                          selectedOrderId: row.orderId,
                        })
                      }
                    >
                      Not Confirmed
                    </Button>
                  )
                ) : (
                  <></>
                )}
              </div>
            </>
          ),
        },
      ];
    }
    this.setState({ columns: columnsData });
  };
  handleUpdateState = async (items) => {
    try {
      this.setState({
        orders: [],
      });
      if (items.length) {
        items.forEach((item) => {
          var data = {};
          // console.log(item);
          data["orderType"] = null;
          if (item.table_order) {
            data.orderType = item.order_type || "table";
            data.tableOrder = true;
          } else if (item.order_type) {
            data.orderType = item.order_type;
          }
          data["date"] = item["createdAt"];
          data["id"] = item["orderId"];
          data["confirmed_time"] = item["confirmed_time"];
          data["orderId"] = item["orderId"];
          data["subTotal"] = item["subTotal"];
          data["discount"] = item["discount"];
          data["conv_fee"] = item["conv_fee"];
          data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
          data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
            ? `${formatPhoneNumber(item["Phone"])}`
            : "";
          data["total"] = `${item["total"]}`;
          data["tip"] = item["tip"];
          data["payment"] = `${item["payment"]}`;
          data["payment_type"] = `${item["payment_type"]}`;
          data["status"] = item["status"];
          data["link"] = `/r/${item["orderId"]}`;
          item["items"] = item["items"].map((item) => {
            let temp = {};
            let parse = JSON.parse(item.modifiers);
            let group = item.modifierGroups?.elements;
            let groupId = {};
            if (
              ["custom", "clover"].includes(this.props.restaurant.integrate)
            ) {
              if (group) {
                group.forEach((grup) => {
                  groupId[grup.id] = grup.name;
                });
              }
              if (parse) {
                let tempArr = [];
                let obijtemp = {};
                parse.forEach((ite) => {
                  if (obijtemp[ite.modifier_group_id]) {
                    obijtemp[ite.modifier_group_id]["modarr"].push(
                      ite.modifier_name
                    );
                  } else {
                    if (groupId[ite.modifier_group_id]) {
                      obijtemp[ite.modifier_group_id] = {
                        gname: groupId[ite.modifier_group_id].toUpperCase(),
                        modarr: [ite.modifier_name],
                      };
                    }
                  }
                });
                let toJoinArr = [];
                Object.values(obijtemp).forEach((i) => {
                  toJoinArr.push(`${i.gname} : ${i.modarr.join(", ")}`);
                });
                temp = { ...item, modifiers: obijtemp };
              } else {
                temp = { ...item };
              }
            } else if (this.props.restaurant.integrate === "square") {
              if (parse && parse.length) {
                let modifiers = {};
                parse.forEach((ite) => {
                  Object.values(ite).forEach((data, i) => {
                    modifiers = {
                      ...modifiers,
                      [i]: {
                        gname: data.modifier_group_name || "Type",
                        modarr: [data.modifier_name],
                      },
                    };
                  });
                });
                modifiers = joinObjSameKeyGname(modifiers);
                temp = {
                  ...item,
                  modifiers,
                };
              } else {
                temp = { ...item, modifiers: {} };
              }
            }
            // console.log(temp);
            return temp;
          });
          data["lineItem"] = item["items"];
          data["tax"] = item["tax"];
          data["notes"] = item["notes"];
          this.setState({
            orders: [...this.state.orders, data],
          });
          this.setState({ loader: false });
        });
      } else {
        this.setState({
          orders: [],
          loader: false,
          nodata: true,
        });
      }
      this.setState({
        pending: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(this.state.user);
    let res = await getUser(
      this.props.restaurantId,
      this.state.user,
      this.state.password
    );
    if (res) {
      this.setState({ isAdmin: true });
      this.props.CustomerData({ isAdmin: true });
      var today = new Date();
      var expire = new Date();
      expire.setTime(today.getTime() + 3600000 * 24 * 14);
      document.cookie =
        "hasaccess" +
        "=" +
        encodeURI(
          JSON.stringify({ resId: this.props.restaurantId, admin: true })
        ) +
        ";expires=" +
        expire.toGMTString();
      // window.location.reload(false);
    }
  }; handleDiscount = async (orderId, eleId) => {
    this.setState({ disbtn: true })
    if (this.state.discount_in_percent || this.state.discount_in_amount) {
      const rawResponse = await fetch("/api/v1/merchant/create/discount/order", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          restaurant: {
            restaurant_id: this.props.restaurant.id,
          },
          payload: {
            discount_percent: this.state.discount_in_percent,
            discount_amount: Number(this.state.discount_in_amount) * 100,
            order_id: orderId,
            table_order: false,
          },
        }),
      });
      window.location.reload(false);
    } else {
      let ele = document.getElementById('discount-error')
      ele.style.display = 'block'
      warnRedBox(eleId)
    }
    this.setState({ disbtn: false })
  };
  handleDiscountChange = (e) => {
    const { name, value } = e.target;
    let temp = {};
    warnRedBox(name, 'remove')
    let ele = document.getElementById('discount-error')
    ele.style.display = 'none'
    if (name === "discount_in_percent") {
      temp = { discount_in_percent: value, discount_in_amount: 0 };
    } else {
      temp = { discount_in_percent: 0, discount_in_amount: value };
    }
    this.setState(temp);
  };
  handleDiscountTabs = (key) => {
    this.setState({
      currentDiscountBy: key,
    });
  };
  render() {
    return (
      <>
        {this.state.loader ? (
          <div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                // minHeight: "100vh",
                transition: "0.5s ease-in-out 0s",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ScaleLoader size={12} color="#ec7211" />
            </div>
          </div>
        ) : this.state.nodata ? (
          <div style={{ textAlign: "center" }}>
            <h5 style={{ lineHeight: "1.5em", fontSize: "22px" }}>
              {/* Store Closed <br /> */}
              There are no online orders to display
            </h5>
          </div>
        ) : (
          <div
            className="table-group orders-dashboard orders-mobile"
            style={{ margin: "0" }}
          >
            {/* <div
style={{
maxWidth: "1140px",
margin: "0 auto",
padding: "0 10px 1em",
}}
>
<h4>
<i className="fa fa-file-text" style={{ fontSize: "25px" }}></i>{" "}
Orders{" "}
</h4>
</div> */}

            <Container
              style={{ minHeight: "auto", marginTop: "6 rem !important" }}
            >
              <h3>
                <i className="fa fa-address-card-o"></i> Orders
              </h3>
              <DataTableExtensions
                columns={this.state.columns}
                data={this.state.orders}
                export={false}
                filterPlaceholder="Search Orders"
                print={false}
              >
                <DataTable
                  paginationPerPage={10}
                  highlightOnHover={true}
                  paginationRowsPerPageOptions={[10, 20, 25, 30]}
                  columns={this.state.columns}
                  data={this.state.orders}
                  pagination
                  progressPending={this.state.pending}
                // progressComponent={<BounceLoader color="#ec7211" />}
                // onChangePage={this.handlePageChange}
                />
              </DataTableExtensions>
            </Container>
          </div>
        )}
        {this.state.isOpen ? (
          <Modal
            dialogClassName="receipt-model1"
            show={this.state.isOpen}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div id="invoice-POS">
                <center id="top">
                  {/* <div className="logo">
 <img
 style={{ width: "30%" }}
 src={this.props.restaurant.restaurant.logo}
 />
 </div> */}
                  <div className="info">
                    <h2>{this.props.restaurant.restaurant.name}</h2>
                    <p>
                      {this.props.restaurant.address.address1}{" "}
                      {this.props.restaurant.address.address2}
                      <br />
                      {this.props.restaurant.address.city},{" "}
                      {this.props.restaurant.address.state},{" "}
                      {this.props.restaurant.address.country},{" "}
                      {this.props.restaurant.address.zip}
                      <br />
                      {this.props.restaurant.address.phoneNumber}
                      <br />
                      {this.props.restaurant.restaurant.website}
                    </p>
                  </div>
                </center>

                <div id="bot">
                  <div id="table">
                    <table>
                      <tbody>
                        <tr className="tabletitle">
                          <td className="item" style={{ width: "auto" }}>
                            <h2>Item</h2>
                          </td>
                          <td className="Hours">
                            <h2>Qty</h2>
                          </td>
                          <td className="Rate">
                            <h2>Sub Total</h2>
                          </td>
                        </tr>
                        {this.state.data.lineItem.map((item) => {
                          return (
                            <tr className="service" key={item.id}>
                              <td className="tableitem">
                                <p className="itemtext">{item.name}</p>
                                {item.modifiers ? (
                                  Object.values(item.modifiers).map((ite) => {
                                    return (
                                      <>
                                        <div className="itemmodify">
                                          [
                                          {
                                            <>
                                              <span
                                                style={{
                                                  fontWeight: "600",
                                                  textTransform: "capitalize",
                                                }}
                                              >{` ${ite.gname
                                                .replace(/choose /gi, "")
                                                .replace(/an /gi, "")
                                                .replace(/add /gi, "")
                                                .toLocaleLowerCase()}: `}</span>
                                              <span>{`${ite.modarr.join(
                                                ", "
                                              )} `}</span>
                                            </>
                                          }
                                          ]
                                        </div>
                                      </>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="tableitem">
                                <p className="itemtext">{item.quantity}</p>
                              </td>
                              <td className="tableitem">
                                <p className="itemtext">
                                  {" "}
                                  ${" "}
                                  {parseFloat(item.totalPrice / 100).toFixed(2)}
                                </p>
                              </td>
                            </tr>
                          );
                        })}

                        <tr className="tabletitle">
                          <td className="Rate">
                            <h2>
                              <MultiLingualContent contentID="Subtotal" />
                            </h2>
                          </td>
                          <td></td>
                          <td className="payment">
                            <h2>
                              {" "}
                              ${" "}
                              {parseFloat(
                                this.state.data.subTotal / 100
                              ).toFixed(2)}
                            </h2>
                          </td>
                        </tr>
                        {this.state.data.discount ? (
                          <tr className="tabletitle">
                            <td className="Rate">
                              <h2>Discount</h2>
                            </td>
                            <td></td>
                            <td className="payment">
                              <h2>
                                {" "}
                                - ${" "}
                                {parseFloat(
                                  this.state.data.discount / 100
                                ).toFixed(2)}
                              </h2>
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr className="tabletitle">
                          <td className="Rate">
                            <h2>Total Tax</h2>
                          </td>
                          <td></td>
                          <td className="payment">
                            <h2>
                              {" "}
                              ${" "}
                              {parseFloat(this.state.data.tax / 100).toFixed(2)}
                            </h2>
                          </td>
                        </tr>
                        <tr className="tabletitle">
                          <td className="Rate">
                            <h2>Total Tip</h2>
                          </td>
                          <td></td>
                          <td className="payment">
                            <h2>
                              {" "}
                              ${" "}
                              {parseFloat(this.state.data.tip / 100).toFixed(2)}
                            </h2>
                          </td>
                        </tr>
                        {
                          this.state.data.conv_fee ? <tr className="tabletitle">
                            <td className="Rate">
                              <h2>Convenience Fee</h2>
                            </td>
                            <td></td>
                            <td className="payment">
                              <h2>
                                {" "}
                                ${" "}
                                {parseFloat(this.state.data.conv_fee / 100).toFixed(
                                  2
                                )}
                              </h2>
                            </td>
                          </tr> : <></>
                        }
                        <tr className="tabletitle">
                          <td className="Rate">
                            <h2>Order Total</h2>
                          </td>
                          <td></td>
                          <td className="payment">
                            <h2>
                              {" "}
                              ${" "}
                              {parseFloat(this.state.data.total / 100).toFixed(
                                2
                              )}
                            </h2>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="additional">
                      {this.state.data.date} <br />
                      {this.state.data.notes ? (
                        <>"{this.state.data.notes}"</>
                      ) : (
                        <></>
                      )}
                    </p>
                  </div>
                </div>{!this.state.data.discount &&
                  !this.state.data?.tableOrder &&
                  ["custom", "clover"].includes(
                    this.props.restaurant.integrate
                  ) &&
                  this.state.data.payment.toLowerCase() === "not paid" ? (
                  <div>
                    {" "}
                    <Form className="pb-4 special-discount">
                      <div className="pb-2 text-center">
                        Give Special Discount
                      </div>
                      <Tabs
                        defaultActiveKey="profile"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        onSelect={this.handleDiscountTabs}
                      // defaultActiveKey="percentage"
                      >
                        <Tab eventKey="percentage" title="In Percentage %">
                          <div className="text-center d-flex special-discount-tab">
                            <Form.Control
                              onChange={this.handleDiscountChange}
                              // value={this.state.discount_in_amount}
                              type="number"
                              name="discount_in_percent"
                              placeholder="In Percent"
                              id='discount_in_percent'
                            />
                            <button
                              onClick={() =>
                                this.handleDiscount(this.state.data.id, 'discount_in_percent')
                              }
                              style={{ padding: "6px 10px" }}
                              className="btn btn-primary mb-0 ml-2 spl-btn"
                              type="button"
                              disabled={this.state.disbtn}
                            >
                              Confirm
                            </button>
                          </div>
                        </Tab>
                        <Tab eventKey="amount" title="In Amount $">
                          <div className="text-center d-flex special-discount-tab">
                            <Form.Control
                              onChange={this.handleDiscountChange}
                              // value={this.state.discount_in_percent}
                              type="number"
                              name="discount_in_amount"
                              id="discount_in_amount"
                              placeholder="In Dollar"
                            />
                            <button
                              onClick={() =>
                                this.handleDiscount(this.state.data.id, "discount_in_amount")
                              }
                              style={{ padding: "6px 10px" }}
                              className="btn btn-primary mb-0 ml-2 spl-btn"
                              type="button"
                              disabled={this.state.disbtn}
                            >
                              Confirm
                            </button>
                          </div>
                        </Tab>
                      </Tabs>
                    </Form>
                    <div id='discount-error' className="text-danger" style={{ textAlign: 'center', fontSize: '14px', display: 'none' }}>Must be not empty</div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
 <Button variant="secondary" onClick={this.closeModal}>
 Close
 </Button>
 </Modal.Footer> */}
          </Modal>
        ) : (
          <></>
        )}
        {this.state.confirmedTime ? (
          <Modal
            dialogClassName="receipt-model1"
            show={this.state.confirmedTime}
            onHide={this.closeModal}
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div id="invoice-POS">
                <ConfirmButton
                  {...this.props}
                  setValue={this.setState}
                  orderId={this.state.selectedOrderId}
                />
              </div>
            </Modal.Body>{" "}
          </Modal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerId: state.customer.id,
    customer: state.customer,
    restaurantId: state.restaurant.id,
    restaurant: state.restaurant,
    restaurantEle: state.restaurant.items.elements,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackerOrders);