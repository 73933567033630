import React from "react";
import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import { Button, Modal } from "react-bootstrap";
import CustomButtonGroup from "./CustomButtonGroup";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import { getConditionalModifierGroups, getModifierGroups } from "../utils/api";
import { AddWithModifier } from "../redux/actions/cartActions";
import { CgShoppingCart } from "react-icons/cg";
import { RWebShare } from "react-web-share";
import { useSearchParams } from "react-router-dom";
import { FaShareAlt, FaRegPaperPlane } from "react-icons/fa";
import MultiLingualContent from "../context/multilingualContent";

const subModStyle = {
  active: {
    backgroundColor: '#808080',
    border: 'none',
    color: '#fff',
    padding: '4px 8px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    borderRadius: '6px'
  },
  not_active: {
    // backgroundColor: '#808080',
    border: '2px solid #808080',
    color: '#808080',
    padding: '4px 8px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    borderRadius: '6px',
    cursor: 'pointer'
  },
}

const subModIcon = [
  { keyword: 'whole', url: 'https://storage.googleapis.com/phonedash/images/whole-pizza.png' },
  { keyword: 'left', url: 'https://storage.googleapis.com/phonedash/images/left-pizza.png' },
  { keyword: 'right', url: 'https://storage.googleapis.com/phonedash/images/right-pizza.png' }
]

function DeliveryCard(props) {
  const [searchParams] = useSearchParams();
  const [choosedModifiers, setChoosedModifiers] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [show, setShow] = useState(false);
  const [modifiers, setModifiers] = useState({});
  const [modSelect, setModSelect] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allmodifiers, setAllModifiers] = useState({});
  const [limitmodifiers, setLimitModifiers] = useState({});
  const [subModifierSelected, setSubModifierSelected] = useState({});
  const [instructions, setInstructions] = useState("");
  useEffect(() => {
    if (searchParams.get("itemId") && searchParams.get("itemId") === props.id) {
      handleShow()

    }
  }, [])
  const handleClose = () => {
    setInstructions("");
    setModifiers({});
    setChoosedModifiers({});
    setAllModifiers({});
    setShow(false);
    setModSelect(false);
    setSubModifierSelected({})
  };
  const validateRequired = () => {
    let forms = document.querySelectorAll(`form[required]`);
    for (let form of forms) {
      if (!form.querySelectorAll(`:checked`).length) {
        document.getElementById("required-modifier").style.display = "block";
        return false;
      }
    }
    document.getElementById("required-modifier").style.display = "none";
    return true;
  };

  const handleInstructions = (e) => {
    setInstructions(e.target.value.replace(/[^a-zA-Z0-9 ]/gm, ""));
  };

  let itemTotal = 0;
  {
    props.orderCart
      .filter((item) => item.productId === props.id)
      .map((item, i) => {
        itemTotal = itemTotal + item.num;
      });
  }
  // const unique = [...new Set(data.map(item => item.group))]; // [ 'A', 'B']
  const language = searchParams.get("language");
  async function getModifiers(group) {
    setLoader(true);
    const data = await getModifierGroups(
      props.restaurant.id,
      Object.keys(group)[0],
      Object.values(group)[0],
      props.restaurant.integrate,
      language || "en"
    );
    // console.log(data);
    setModifiers((prevState) => ({
      ...prevState,
      [Object.keys(group)[0]]: data,
    }));
    Object.values(data).forEach((item) => {
      item.forEach((ite) => {
        if (ite.default) {
          if (
            Object.values(data)?.length === 1 &&
            Object.values(data)[0].type !== "checkbox"
          ) {
            setModSelect(true);
          }
          let id = ite.type === "checkbox" ? ite.id : ite.groupId;
          const modData = {
            [id]: {
              modifier_name: ite.name,
              modifier_price: ite.price,
              modifier_id: ite.id,
              modifier_group_id: ite.groupId,
              modifier_group_name: ite.group_name,
            },
          };
          setChoosedModifiers((prevState) => ({
            ...prevState,
            ...modData,
          }));
        }
      });
    });
    setLoader(false);
  }

  const shareOpen = (e) => {
    const item = searchParams.get("item");
    console.log("shared successfully!");
  };
  const handleShow = async () => {
    setShow(true);
    if (
      props?.modifierGroups?.length
    ) {
      // console.log(props.modifierGroups)
      let promise = Promise.resolve();

      props.modifierGroups.forEach((group, index) => {
        // console.log(group)
        promise = promise.then(() => {
          // console.log(group);
          return getModifiers(group);
        });
      });
      // props.modifierGroups.forEach(async (group) => {
      // const data = await getModifierGroups(
      // props.restaurant.id,
      // Object.keys(group)[0],
      // Object.values(group)[0],
      // props.restaurant.integrate
      // );
      // console.log(data)
      // // console.log({ [Object.keys(group)[0]]: data })
      // setModifiers((prevState) => ({
      // ...prevState,
      // [Object.keys(group)[0]]: data,
      // }));
      // // console.log(52, data);
      // Object.values(data).forEach((item) => {
      // item.forEach((ite) => {
      // if (ite.default) {
      // // console.log(ite);
      // const modData = {
      // [ite.groupId]: {
      // modifier_name: ite.name,
      // modifier_price: ite.price,
      // modifier_id: ite.id,
      // modifier_group_id: ite.groupId,
      // },
      // };
      // // console.log(modData);
      // setChoosedModifiers((prevState) => ({
      // ...prevState,
      // ...modData,
      // }));
      // }
      // });
      // });

      // // group.default &&
      // // setChoosedModifiers({
      // // modifier_name: group.name,
      // // modifier_price: group.price,
      // // modifier_id: group.modifyId,
      // // modifier_group_id: group.modifierId,
      // // });
      // // setModifiers((modifiers) => [...modifiers, data]);
      // // setModifiers([...modifiers, data]);
      // });

      // data.map((item) => {
      // // console.log('Name: ' + item.name );
      // });
    }
  };
  const handleChangeCheck = async (e) => {
    let limit = e.target.getAttribute("limit");
    let limitMod = document.querySelectorAll(
      `[type="checkbox"][name="${e.target.getAttribute("name")}"]:checked`
    ).length;
    let Checked = document.querySelectorAll(
      `[type="checkbox"][name="${e.target.getAttribute("name")}"]`
    );
    for (let i = 0, check; (check = Checked[i]); i++) {
      if (limitMod === Number(limit)) {
        console.log("Limit");
        if (!check.checked) {
          check.disabled = !(!e.target.checked || check === e.target);
        }
      } else {
        if (!check.checked) {
          check.disabled = false;
        }
      }
    }
    // console.log(limit);
    let data = {};
    if (
      document.querySelectorAll(
        `[type="checkbox"][name="${e.target.getAttribute("name")}"]:checked`
      ).length <= limit
    ) {
      let items = document.querySelectorAll(
        `[type="checkbox"][name="${e.target.getAttribute("name")}"]:checked`
      );
      // console.log(items);
      items.forEach((item) => {
        // console.log(item);
        let modifier_id = item.value;
        let modifier_name = item.getAttribute("modifier_name");
        let modifier_price = item.getAttribute("price");
        let modifier_group_id = item.getAttribute("modifier_id");
        let modifier_group_name = item.getAttribute("modifier_group_name");
        let sub_modifier = JSON.parse(item.getAttribute("sub_modifier"));
        // console.log(sub_modifier)
        data[modifier_id] = {
          modifier_name: modifier_name,
          modifier_price: modifier_price,
          modifier_id: modifier_id,
          modifier_group_id: modifier_group_id,
          modifier_group_name: modifier_group_name,
          // sub_modifier: sub_modifier
        };

        setChoosedModifiers((prevState) => ({
          ...prevState,
          ...data,
        }));
      });
    } else {
      console.log("exceed");
      e.target.checked = false;
    }
    if (Object.keys(choosedModifiers).includes(e.target.value)) {
      // console.log(choosedModifiers);
      let tempModifier = { ...choosedModifiers };
      delete tempModifier[e.target.value];
      setChoosedModifiers(tempModifier);
    }
    // let choosedModifiersList = {...choosedModifiers}
    // if(choosedModifiersList[modifier_group_id]) {
    // choosedModifiersList[modifier_group_id] = [...choosedModifiersList[modifier_group_id], {
    // modifier_name: modifier_name,
    // modifier_price: modifier_price,
    // modifier_id: modifier_id,
    // modifier_group_id: modifier_group_id,
    // }]

    // console.log(choosedModifiersList)
    // } else {
    // choosedModifiersList[modifier_group_id] = [{
    // modifier_name: modifier_name,
    // modifier_price: modifier_price,
    // modifier_id: modifier_id,
    // modifier_group_id: modifier_group_id,
    // }]
    // }

    setModSelect(true);
  };
  const handleChange = async (e) => {
    let modifier_id = e.target.value;
    let modifier_name = e.target.getAttribute("modifier_name");
    let modifier_price = e.target.getAttribute("price");
    let modifier_group_id = e.target.getAttribute("modifier_id");
    let modifier_group_name = e.target.getAttribute("modifier_group_name");
    let sub_modifier = e.target.getAttribute("sub_modifier");
    let conditional_modifier_id = e.target.getAttribute("conditional_modifier");
    let appliedModifiers = { ...modifiers };
    let allmodifiersList = { ...allmodifiers };
    allmodifiersList[modifier_group_id] = modifier_id;
    setAllModifiers((prevState) => ({
      ...prevState,
      [modifier_group_id]: modifier_id,
    }));
    if (conditional_modifier_id) {
      let conditional_modifiers = await getConditionalModifierGroups(
        props.restaurant.id,
        conditional_modifier_id,
        modifier_id,
        props.restaurant.integrate
      );
      let tem = {};
      tem[conditional_modifiers.name] = JSON.parse(
        conditional_modifiers["modifiers"]
      );
      // setModifiers((prevState) => ({
      // ...prevState,
      // [conditional_modifier_id]: {[conditional_modifiers.name]: []},
      // }))

      appliedModifiers[conditional_modifier_id] = tem;
      setModifiers((prevState) => ({
        ...prevState,
        [conditional_modifier_id]: tem,
      }));
    }
    let data = {};
    // console.log(allmodifiersList, appliedModifiers);
    Object.entries(allmodifiersList).forEach(([key, value]) => {
      // console.log(modifier_group_id, key, value);
      let temData = {};
      if (modifier_group_id != key) {
        try {
          // console.log("Try", appliedModifiers[key])
          temData[key] = {
            modifier_group_name: Object.keys(appliedModifiers[key])[0],
            modifier_name: Object.values(appliedModifiers[key])[0].filter(
              (it) => it.id == value
            ).length
              ? Object.values(appliedModifiers[key])[0].filter(
                (it) => it.id == value
              )[0]["name"]
              : Object.values(appliedModifiers[key])[0][0]["name"],
            modifier_price: Object.values(appliedModifiers[key])[0].filter(
              (it) => it.id == value
            ).length
              ? Object.values(appliedModifiers[key])[0].filter(
                (it) => it.id == value
              )[0]["price"]
              : Object.values(appliedModifiers[key])[0][0]["price"],
            modifier_id: Object.values(appliedModifiers[key])[0].filter(
              (it) => it.id == value
            ).length
              ? Object.values(appliedModifiers[key])[0].filter(
                (it) => it.id == value
              )[0]["id"]
              : Object.values(appliedModifiers[key])[0][0]["id"],
            modifier_group_id: Object.values(appliedModifiers[key])[0].filter(
              (it) => it.id == value
            ).length
              ? Object.values(appliedModifiers[key])[0].filter(
                (it) => it.id == value
              )[0]["groupId"]
              : Object.values(appliedModifiers[key])[0][0]["groupId"],
          };
        } catch (e) {
          // console.log("Catch")
          temData[key] = {
            modifier_group_name: Object.keys(appliedModifiers[key])[0],
            modifier_name: Object.values(appliedModifiers[key])[0][0]["name"],
            modifier_price: Object.values(appliedModifiers[key])[0][0]["price"],
            modifier_id: Object.values(appliedModifiers[key])[0][0]["id"],
            modifier_group_id: Object.values(appliedModifiers[key])[0][0][
              "groupId"
            ],
          };
        }
      }
      // console.log("temData =>", temData)
      setChoosedModifiers((prevState) => ({
        ...prevState,
        ...temData,
      }));
    });
    data[modifier_group_id] = {
      modifier_name: modifier_name,
      modifier_price: modifier_price,
      modifier_id: modifier_id,
      modifier_group_id: modifier_group_id,
      modifier_group_name: modifier_group_name,
      sub_modifier: sub_modifier
    };
    // console.log("data =>", data)
    setChoosedModifiers((prevState) => ({
      ...prevState,
      ...data,
    }));
    setModSelect(true);
  };

  function handleSubMod(data, type = 'check') {
    const { modifier_group_id, id, modifier_id, name } = data;
    setSubModifierSelected(prevState => ({ ...prevState, [modifier_group_id]: { id, name: name, type: type, modifier_id } }))
  }
  return (
    <>
      <Col
        xs={10}
        sm={3}
        md={6}
        lg={6}
        onClick={handleShow}
        className="naancurry"
      >
        <div className="menu-lists-item">
          <div className="menu-lists-item-left">
            <div className="menu-lists-item-sub">
              {props.title.includes("(") ? (
                <>
                  <h2 style={{ fontSize: "16px" }}>
                    {props.title}
                    {/* {props.title.split("(")[0].split(")")[0]} */}
                  </h2>{" "}
                  {props.description.length > 58 ? (
                    <>
                      <p
                        className="description"
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "5px",
                          color: "rgb(105, 114, 128)",
                        }}
                      >
                        {showMore ? (
                          <>{props.description} </>
                        ) : (
                          <>{props.description.substring(0, 50)}...</>
                        )}
                        <button
                          className="btnshow"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowMore(!showMore);
                          }}
                        >
                          {showMore ? "less" : "more"}
                        </button>
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className="description"
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "5px",
                          color: "rgb(105, 114, 128)",
                        }}
                      >
                        {props.description}
                      </p>
                    </>
                  )}
                  <p
                    style={{
                      position: "relative",
                      bottom: "0",
                      margin: "0px",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    {props.price
                      ? `$ ${parseFloat(props.price).toFixed(2)}`
                      : ""}
                  </p>
                </>
              ) : (
                <>
                  <h2>{props.title}</h2>
                  {props.description.length > 58 ? (
                    <>
                      <p
                        className="description"
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "5px",
                          color: "rgb(105, 114, 128)",
                        }}
                      >
                        {showMore ? (
                          <>{props.description} </>
                        ) : (
                          <>{props.description.substring(0, 50)}...</>
                        )}
                        <button
                          className="btnshow"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowMore(!showMore);
                          }}
                        >
                          {showMore ? "less" : "more"}
                        </button>
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className="description"
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "5px",
                          color: "rgb(105, 114, 128)",
                        }}
                      >
                        {props.description}
                      </p>
                    </>
                  )}
                  {/* <p style={{ margin: '0', color: "#000", fontSize: "16px" }}></p> */}
                  <p
                    style={{
                      position: "relative",
                      bottom: "0",
                      margin: "0px",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    {props.price
                      ? `$ ${parseFloat(props.price).toFixed(2)}`
                      : ""}
                    {/* $ {props.price} */}
                  </p>
                  {/* <p>{props.description}</p> */}
                </>
              )}
              <div className="d-flex">
                {props.isOpen ? (
                  <div className="addtocartbtn">
                    <p>
                      {" "}
                      <MultiLingualContent contentID="Add to Cart" />
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="share-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <RWebShare
                    data={{
                      text: `Check out ${props.title
                        } from ${props.restaurant.restaurant.name
                          .replace(/[$@&%]/g, "")
                          .replace(/ /g, " ")}`,
                      url: `${window.location.origin}/${props.restaurant.id}?itemId=${props.id}`,
                      title: `Share ${props.title} via`,
                    }}
                    onClick={shareOpen}
                  >
                    <button id="share">
                      <FaShareAlt />
                    </button>
                  </RWebShare>
                </div>
              </div>
            </div>
          </div>

          <div className="menu-lists-item-right">
            <img src={props.img} alt={props.title} />

            {itemTotal ? (
              <div className="item-no">
                <CgShoppingCart
                  style={{
                    verticalAlign: "-3px",
                    fontSize: "17px",
                    marginRight: "4px",
                  }}
                />
                {itemTotal}
              </div>
            ) : (
              <></>
            )}
            {/* <div style={{ backgroundImage: "url(" + props.img + ")" }}></div> */}
          </div>
        </div>
      </Col>

      {show ? (
        <>
          <Modal
            centered
            animation={true}
            dialogClassName="addcartpop"
            show={show}
            onHide={handleClose}
          >
            <button type="button" id="clo" onClick={handleClose} class="close">
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
            </button>
            <Modal.Body>
              <Modal.Header
                style={{ backgroundImage: "url(" + props.img + ")" }}
              >
                <Modal.Title>
                  {/* <img src={props.img} /> */}
                  {/* <div style={{ backgroundImage: "url(" + props.img + ")" }}></div> */}
                </Modal.Title>
              </Modal.Header>
              <div
                style={{ padding: "15px", background: "rgb(239, 239, 239)" }}
              >
                <p style={{ margin: "0", fontWeight: "600", fontSize: "20px" }}>
                  {props.title}
                </p>
                <p
                  style={{
                    margin: "8px 0 0",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  {props.price ? `$ ${parseFloat(props.price).toFixed(2)}` : ""}
                  {/* ${props.price} */}
                </p>
                <p
                  className="description"
                  style={{
                    padding: "10px 0 0",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "rgb(105, 114, 128)",
                  }}
                >
                  {props.description}
                </p>
                {/* Modifiers */}
                {/* {console.log(modifiers)} */}

                {(Object.keys(modifiers).length > 0 &&
                  props.restaurant.integrate === "custom") ||
                  true ? (
                  <>
                    {Object.keys(modifiers).map((id, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h2
                                style={{ fontSize: "22px", fontWeight: "700" }}
                              >
                                {Object.keys(modifiers[id])[0]}
                              </h2>
                            </div>
                            <div>
                              {Object.values(modifiers[id])[0][0]["limit"] ? (
                                <span className="choose">{`Choose upto ${Object.values(modifiers[id])[0][0]["limit"]
                                  } of these items`}</span>
                              ) : (
                                <span className="choose">{`Choose ${Object.values(modifiers[id])[0][0]["type"] ===
                                  "radio"
                                  ? "one"
                                  : "any"
                                  } item`}</span>
                              )}
                              <span className="choose">
                                {Object.values(modifiers[id])[0][0][
                                  "required"
                                ] ? (
                                  <>
                                    <span style={{ color: "red" }}>
                                      *
                                      <MultiLingualContent contentID="Required" />
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <form
                              required={
                                Object.values(modifiers[id])[0][0]["required"]
                                  ? true
                                  : false
                              }
                            >
                              {Object.values(modifiers[id])[0].map(
                                (group, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      {group.type === "radio" ? (
                                        <div className="radio-model">
                                          <input
                                            onChange={handleChange}
                                            type="radio"
                                            price={group.price}
                                            name={Object.keys(
                                              modifiers[id]
                                            )[0].replace(/ /g, "_")}
                                            modifier_name={group.name}
                                            modifier_id={id}
                                            modifier_group_name={
                                              Object.keys(modifiers[id])[0]
                                            }
                                            // modifier_group_name={group.group_name}
                                            value={group.id}
                                            conditional_modifier={group.cmod}
                                            defaultChecked={group.default}
                                            sub_modifier={JSON.stringify(group.sub_modifier)}
                                          />
                                          <label htmlFor="html">
                                            {group.name}
                                          </label>
                                          <label style={{ float: "right" }}>
                                            {group.price
                                              ? ` $${parseFloat(
                                                group.price / 100
                                              ).toFixed(2)}`
                                              : `$0.00`}
                                          </label>
                                          {group.sub_modifier && group.sub_modifier.length && choosedModifiers[group.groupId]?.modifier_id === group.id ?
                                            <div style={{ marginLeft: '32px', display: 'flex', gap: '10px' }}>{group.sub_modifier.map((ite, key) => {
                                              let { name, price } = ite;
                                              return (<div key={key} style={subModStyle[subModifierSelected[group.id] && subModifierSelected[group.id].id === ite.id ? 'active' : 'not_active']}
                                                onClick={() => handleSubMod({ ...ite, modifier_group_id: group.id, modifier_id: id }, 'radio')}>
                                                {name}
                                              </div>)
                                            })}</div> : <></>}
                                        </div>
                                      ) : (
                                        <div className="checkbox-mod">
                                          <input
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                              verticalAlign: "middle",
                                            }}
                                            onChange={handleChangeCheck}
                                            type="checkbox"
                                            price={group.price}
                                            name={Object.keys(
                                              modifiers[id]
                                            )[0].replace(/ /g, "_")}
                                            modifier_name={group.name}
                                            modifier_group_name={
                                              Object.keys(modifiers[id])[0]
                                            }
                                            // modifier_group_name={group.group_name}
                                            modifier_id={id}
                                            limit={
                                              group.limit ? group.limit : 10
                                            }
                                            value={group.id}
                                            conditional_modifier={group.cmod}
                                            defaultChecked={group.default}
                                            sub_modifier={JSON.stringify(group.sub_modifier)}
                                          />
                                          <label htmlFor="html">
                                            {group.name} +{" "}
                                            {group.price
                                              ? `$${parseFloat(
                                                group.price / 100
                                              ).toFixed(2)}`
                                              : `$0.00`}
                                          </label>
                                          {group.sub_modifier && group.sub_modifier.length && choosedModifiers[group.id] ?
                                            <div style={{ marginLeft: '32px', display: 'flex', gap: '10px' }}>{group.sub_modifier.map((ite, key) => {
                                              let { name, price, id: subMObId } = ite;
                                              return (<div key={key} style={subModStyle[subModifierSelected[group.id] && subModifierSelected[group.id].id === ite.id ? 'active' : 'not_active']}
                                                onClick={() => handleSubMod({ ...ite, modifier_group_id: group.id, modifier_id: id })}>
                                                {name}
                                              </div>)
                                            })}</div> : <></>}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </form>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
                <div className="special-instructions">
                  <Form.Row>
                    <Form.Group as={Col} controlId="instructions">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={handleInstructions}
                        placeholder="Special Instructions"
                        required
                        value={instructions}
                      />
                    </Form.Group>
                  </Form.Row>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ border: "0" }}>
              <span
                style={{ color: "red", fontSize: "18px", display: "none" }}
                id="required-modifier"
              >
                <MultiLingualContent contentID="Please fill the required fields" />
              </span>
              <div className="newcart" style={{ width: "100%" }}>
                <div className="plusbtn">
                  {props.isOpen ? (
                    props.stock ? (
                      <>
                        {props.price || modSelect ? (
                          <CustomButtonGroup
                            toDisabled={loader}
                            validateRequired={validateRequired}
                            choosedModifiers={choosedModifiers}
                            modifierPrice={props.modifiersPriceClicked}
                            id={props.id}
                            title={props.title}
                            num={props.num}
                            modifierGroups={props.modifierGroups}
                            onClick={handleClose}
                            instructions={instructions}
                            subMod={subModifierSelected}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <p
                        style={{
                          color: "#f55142",
                          fontSize: "18px",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        <MultiLingualContent contentID="Out Of Stock" />
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    AddWithModifier: (title, modifierId, modifierName, price) => {
      dispatch(AddWithModifier(title, modifierId, modifierName, price));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
    orderCart: state.cart.orderCart,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCard);