import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
  getRestaurantAddress,
  updateuRestaurantAddress,
  updateuRestaurantHours,
} from "../Functions/fetch";
import { Form, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { async } from "q";
import _, { isArray, isObject } from "lodash";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { MdCancel, MdModeEdit, MdAdd } from "react-icons/md";

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

var sort = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

// const time = ["12:00 PM","12:30 PM","1:00 PM","1:30 PM","2:00 PM","2:30 PM","3:00 PM","3:30 PM","4:00 PM","4:30 PM","5:00 PM","5:30 PM","6:00 PM","6:30 PM","7:00 PM","7:30 PM","8:00 PM","8:30 PM","9:00 PM","9:30 PM","10:00 PM","10:30 PM","11:00 PM","11:30 PM","12:00 AM","12:30 AM","1:00 AM","1:30 AM","2:00 AM","2:30 AM","3:00 AM","3:30 AM","4:00 AM","4:30 AM","5:00 AM","5:30 AM","6:00 AM","6:30 AM","7:00 AM","7:30 AM","8:00 AM","8:30 AM","9:00 AM","9:30 AM","10:00 AM","10:30 AM","11:00 AM","11:30 AM"]
const time = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

const timeZones = [
  "Pacific/Samoa",
  "US/Samoa",
  "Pacific/Honolulu",
  "America/Anchorage",
  "America/Los_Angeles",
  "America/Denver",
  "America/Phoenix",
  "America/Chicago",
  "America/New_York",
  "America/Caracas",
  "America/Puerto_Rico",
  "Canada/Newfoundland",
  "America/Buenos_Aires",
  "Brazil/DeNoronha",
  "Atlantic/Azores",
  "Europe/Dublin",
  "Europe/London",
  "Europe/Warsaw",
  "Africa/Johannesburg",
  "Europe/Berlin",
  "Europe/Helsinki",
  "Africa/Nairobi",
  "Asia/Tehran",
  "Europe/Moscow",
  "Asia/Kabul",
  "Asia/Tashkent",
  "Asia/Calcutta",
  "Asia/Almaty",
  "Asia/Jakarta",
  "Asia/Hong_Kong",
  "Asia/Tokyo",
  "Australia/Adelaide",
  "Australia/Sydney",
  "Asia/Vladivostok",
  "Pacific/Auckland",
  "Pacific/Fiji",
];

function _24HourTime(time) {
  var hourEnd = time.indexOf(":");
  var H = +time.substr(0, hourEnd);
  var h = H % 12 || 12;
  var ampm = H < 12 || H === 24 ? " AM" : " PM";
  time = h + time.substr(hourEnd, 3) + ampm;
  return time;
}
function StoreAvailability(props) {
  const [checked, setChecked] = React.useState(true);
  React.useEffect(() => {
    console.log(props);
    stateUpdate();
  }, []);
  async function stateUpdate() {
    let ajaxCallData = await fetch(
      `/api/v1/merchant/get/openhour/${props.restaurant.id}/${props.restaurant.integrate}`
    );
    ajaxCallData = await ajaxCallData.json();
    let { restaurant_open } = ajaxCallData.data;
    setChecked(Boolean(restaurant_open));
  }
  async function updateRestaurantOpen() {
    let ajaxCallData = await fetch(
      `/api/v1/merchant/post/openhour/${props.restaurant.id}/${props.restaurant.integrate}`
    );
    stateUpdate();
  }
  return (
    <div
      className="d-flex flex-column store-available"
    // style={{ gap: "20px" }}
    >
      <div>
        {" "}
        <p
          style={{
            textTransform: "capitalize",
            fontSize: "1.5em",
            fontWeight: "bold",
          }}
        >
          Store Availability
        </p>
      </div>
      <div className="d-flex align-items-center">
        <span style={{ fontWeight: 600, fontSize: "20px" }}>
          {checked ? `Open` : `Paused`}
        </span>
        <label className="toggle-switch time" style={{ margin: "0 0 0 10px" }}>
          <input
            type="checkbox"
            checked={checked}
            onChange={updateRestaurantOpen}
          // onChange={() => setChecked(!checked)}
          />
          <span className="switch" />
        </label>
      </div>
      <p style={{ fontSize: "18px" }}>
        {" "}
        {checked
          ? `This store is currently available to take orders.`
          : `This store is temporarily unavailable to take orders.`}
      </p>
    </div>
  );
  {
    /* <label className="toggle-switch">
    <input
    type="checkbox"
    // onChange={async (e) => availabilityTable(e.target.id)}
    // id={row.id}
    defaultChecked={this.state.restaurant_open}
    // checked
    />
    <span className="switch" />
    </label> */
  }
}
class AccountSettings extends Component {
  state = {
    name: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    state: "",
    country: "",
    logo: "",
    zip: "",
    timeZone: "",
    phoneNumber: "",
    website: "",
    hours: [],
    results: {},
    BnameEdit: true,
    width: window.innerWidth
  };
  async componentDidMount() {
    this.updateAddressState();
    window.addEventListener("resize", () => {
      const width = window.innerWidth
      this.setState({
        width
      })
    });

  }
  updateAddressState = async () => {
    const rstAddress = await getRestaurantAddress(
      this.props.restaurant.id,
      this.props.restaurant.integrate
    );
    if (['square'].includes(this.props.restaurant.integrate)) {
      this.setState({ BnameEdit: false })
    }
    const { restaurant, address, hours } = rstAddress;
    let temp = {};
    temp["name"] = restaurant.name;
    temp["website"] = restaurant.website;
    temp["logo"] = restaurant.logo;
    temp["timeZone"] = restaurant.timeZone;
    this.setState((prevState, props) => ({
      ...prevState,
      ...rstAddress.address,
      ...temp,
      hours: hours.elements[0],
    }));
    this.getTiming();
  };

  getTiming = async () => {
    var results = {};
    sort.forEach((da) => {
      var hours = "";
      this.state.hours[da]["elements"].forEach((day) => {
        var start = day["start"].toString().padStart(4, "0");
        var end = day["end"].toString().padStart(4, "0");

        start = `${start.toString().slice(0, -2)}:${start
          .toString()
          .slice(-2)}`;
        end = `${end.toString().slice(0, -2) === 24
          ? "00"
          : end.toString().slice(0, -2)
          }:${end.toString().slice(-2)}`;

        hours = `${hours ? ` ${hours} &` : ``} ${_24HourTime(
          start
        )} - ${_24HourTime(end)}`.trim();
      });
      results[da] = this.state.hours[da]["elements"].length
        ? `${hours}`
        : `Closed`;
    });
    this.setState({
      results,
    });
  };
  handleImage = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };
  onChangeHandle = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value.replace(/'/g, "\\'") });
  };
  HandleSubmitBtn = async () => {
    const AddressData = _.pick(this.state, [
      "address1",
      "city",
      "state",
      "country",
      "zip",
      "phoneNumber",
      "timeZone",
      "website",
      "name",
    ]);
    const hoursData = this.state.hours;
    await updateuRestaurantAddress(
      this.props.restaurant.id,
      this.props.restaurant.integrate,
      AddressData
    );
    await updateuRestaurantHours(
      this.props.restaurant.id,
      this.props.restaurant.integrate,
      hoursData
    );
    toast.success("Updated...");
    this.updateAddressState();
    // console.log(this.state);
  };
  HandleLogoUpload = async () => {
    let postid = uuidv4();
    let blob = this.state.image.slice(
      0,
      this.state.image.size,
      this.state.image.type
    );
    console.log(blob);
    const logo = new File(
      [blob],
      `${postid}.${this.state.image.type.split("/")[1]}`,
      {
        type: this.state.image.type,
      }
    );

    let formData = new FormData();
    formData.append("logo", logo);
    let res = await fetch(
      `/api/v1/merchant/${this.props.restaurant.id}/${this.props.restaurant.integrate}/account/update/logo`,
      {
        method: "POST",
        body: formData,
      }
    );
    const json = await res.json();
    console.log(json);
    // .then(res => {
    // console.log(res.json()['logo'])
    this.setState({
      logo: json["logo"],
    });
    toast.success("Logo Updated...");
    // }).catch(err => {
    // console.log(err)
    // toast.error("Logo Not Updated...");
    // })
  };

  handleHours = async (e) => {
    let value = parseInt(moment(e.target.value, "h:mm a").format("HHmm"));
    if (value === 0 && e.target.name === "end") {
      value = 2400;
    }
    let name = e.target.name;
    let day = e.target.getAttribute("day");
    let index = parseInt(e.target.getAttribute("index"));

    let dayHours = this.state.hours[day]["elements"];
    let canSet = true;
    if (name === "start" && index > 0) {
      let prevEnd = dayHours[index - 1]["end"];
      if (value > prevEnd) {
      } else {
        let hour = { ...dayHours[index - 1] };
        hour["end"] = value;
        dayHours[index - 1] = hour;
        document.querySelector(`[index="${index - 1}"][name="end"]`).value =
          moment(value.toString().padStart(4, "0"), "HHmm").format("h:mm A");
      }
    } else if (name === "end" && index < dayHours.length - 1) {
      let nextStart = dayHours[index + 1]["start"];
      if (nextStart > value) {
      } else {
        let hour = { ...dayHours[index + 1] };
        hour["start"] = value;
        dayHours[index + 1] = hour;
        document.querySelector(`[index="${index + 1}"][name="start"]`).value =
          moment(value.toString().padStart(4, "0"), "HHmm").format("h:mm A");
      }
    }

    if (name === "end") {
      if (dayHours[index]["start"] < value) {
        let hour = { ...dayHours[index] };
        hour[name] = value;
        dayHours[index] = hour;
        this.setState((prevState, props) => ({
          hours: {
            ...prevState.hours,
            [day]: { elements: dayHours },
          },
        }));
        this.forceUpdate();
      } else {
        document.querySelector(`[index="${index}"][name="${name}"]`).value =
          moment(
            dayHours[index][name].toString().padStart(4, "0"),
            "HHmm"
          ).format("h:mm A");
        toast.error("End must be greater than start...");
      }
    } else {
    }

    if (name === "start") {
      if (dayHours[index]["end"] > value) {
        let hour = { ...dayHours[index] };
        hour[name] = value;
        dayHours[index] = hour;
        this.setState((prevState, props) => ({
          hours: {
            ...prevState.hours,
            [day]: { elements: dayHours },
          },
        }));
        this.forceUpdate();
      } else {
        document.querySelector(`[index="${index}"][name="${name}"]`).value =
          moment(
            dayHours[index][name].toString().padStart(4, "0"),
            "HHmm"
          ).format("h:mm A");
        toast.error("Start must be less than end...");
      }
    } else {
    }
  };

  handleAddHours = (day) => {
    let hours = this.state.hours[day]["elements"];
    if (hours.length < 2) {
      let lastEl = hours[hours.length - 1]["end"];
      let start = lastEl < 2300 ? lastEl + 100 : 2400;
      let end = lastEl < 2400 ? lastEl + 200 : 2400;
      hours = [...hours, { start, end }];
      this.setState((prevState, props) => ({
        hours: {
          ...prevState.hours,
          [day]: { elements: hours },
        },
      }));
      this.forceUpdate();
    } else {
      toast.error("Only two ranges are allowed...");
    }
  };
  handleDeleteHours = (day) => {
    let hours = this.state.hours[day]["elements"];
    hours.pop();
    if (hours.length) {
      this.setState((prevState, props) => ({
        hours: {
          ...prevState.hours,
          [day]: { elements: hours },
        },
      }));
    } else {
      document.querySelector(`[dayname='${day}']`).value = "Closed";
    }
    this.forceUpdate();
  };
  handleHoursClose = async (e) => {
    let day = e.target.getAttribute("dayname");
    let value = e.target.value;
    this.setState((prevState, props) => ({
      hours: {
        ...prevState.hours,
        [day]:
          value === "Closed"
            ? { elements: [] }
            : { elements: [{ start: 0, end: 2300 }] },
      },
    }));

    this.forceUpdate();
  };
  render() {
    return (
      <>
        <div>
          <StoreAvailability {...this.props} />
        </div>
        <div className="business-settings">
          <h1
            style={{
              textTransform: "capitalize",
              fontSize: "1.5em",
              fontWeight: "bold",
            }}
          >
            Business Information
          </h1>
          <div>
            <small>*required</small>
          </div>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Business Name</td>
                <td>
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.onChangeHandle}
                    name="name"
                    maxlength="127"
                    required="true"
                    value={this.state.name}
                    disabled={this.state.BnameEdit}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Address Line 1</td>
                <td>
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.onChangeHandle}
                    name="address1"
                    maxlength="255"
                    value={this.state.address1}
                    required="true"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>City</td>
                <td>
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.onChangeHandle}
                    name="city"
                    maxlength="127"
                    value={this.state.city}
                    required="true"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>State / Province</td>
                <td>
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.onChangeHandle}
                    name="state"
                    maxlength="127"
                    value={this.state.state}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Country</td>
                <td>
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.onChangeHandle}
                    name="country"
                    maxlength="127"
                    value={this.state.country}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Zip / Postal Code</td>
                <td>
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.onChangeHandle}
                    name="zip"
                    maxlength="127"
                    value={this.state.zip}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Phone Number</td>
                <td>
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.onChangeHandle}
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    maxlength="21"
                    required="true"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Website (Optional)</td>
                <td>
                  <Form.Control
                    size="sm"
                    onChange={this.onChangeHandle}
                    name="website"
                    type="url"
                    value={this.state.website}
                  />
                </td>
              </tr>

              <tr>
                <td style={{ width: "50%" }}>Timezone</td>
                <td>
                  {this.state.timeZone ? (
                    <select
                      style={{ width: "100%" }}
                      name="timeZone"
                      defaultValue={this.state.timeZone}
                      onChange={this.onChangeHandle}
                    >
                      {timeZones.map((ti) => {
                        return <option value={ti}>{ti}</option>;
                      })}
                    </select>
                  ) : (
                    <></>
                  )}

                  {/* <Form.Control
 size="sm"
 onChange={this.onChangeHandle}
 name="timeZone"
 type="text"
 value={this.state.timeZone}
 /> */}
                </td>
              </tr>
            </tbody>
          </table>
          <h1
            style={{
              textTransform: "capitalize",
              fontSize: "1.5em",
              fontWeight: "bold",
            }}
          >
            Business Hours
          </h1>
          <table style={this.state.width < 1024 ? { width: 'auto', display: 'flex', overflowX: 'scroll' } : { width: "100%" }}>
            {Object.entries(this.state.hours).length ? (
              <tbody>
                {Object.entries(this.state.hours).map(([key, value], i) => {
                  return (
                    <React.Fragment key={i}>
                      {isObject(value) ? (
                        <>
                          <tr id={key}>
                            <td
                              style={{ textTransform: "capitalize" }}
                              rowSpan={
                                value.elements.length
                                  ? value.elements.length
                                  : 1
                              }
                            >
                              {key}
                            </td>
                            <td
                              style={{ textTransform: "capitalize" }}
                              rowSpan={
                                value.elements.length
                                  ? value.elements.length
                                  : 1
                              }
                            >
                              <select
                                onChange={this.handleHoursClose}
                                dayname={key}
                                defaultValue={
                                  value.elements.length ? "Open" : "Closed"
                                }
                              >
                                <option value={"Open"}>Open</option>
                                <option value={"Closed"}>Closed</option>
                              </select>
                            </td>
                            {value.elements.slice(0, 1).map((hours, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <select
                                      onChange={this.handleHours}
                                      index={0}
                                      day={key}
                                      id={
                                        value.elements.length
                                          ? "Open"
                                          : "Closed"
                                      }
                                      className={key}
                                      name="start"
                                      defaultValue={moment(
                                        hours.start.toString().padStart(4, "0"),
                                        "HHmm"
                                      ).format("h:mm A")}
                                    >
                                      {time.map((ti) => {
                                        return <option value={ti}>{ti}</option>;
                                      })}
                                    </select>
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <select
                                      onChange={this.handleHours}
                                      index={0}
                                      day={key}
                                      id={
                                        value.elements.length
                                          ? "Open"
                                          : "Closed"
                                      }
                                      className={key}
                                      name="end"
                                      defaultValue={moment(
                                        hours.end.toString().padStart(4, "0"),
                                        "HHmm"
                                      ).format("h:mm A")}
                                    >
                                      {time.map((ti) => {
                                        return <option value={ti}>{ti}</option>;
                                      })}
                                    </select>
                                  </td>
                                </React.Fragment>
                              );
                            })}
                            {value.elements.length ? (
                              <td
                                style={{ textTransform: "capitalize" }}
                                rowSpan={
                                  value.elements.length
                                    ? value.elements.length
                                    : 1
                                }
                              >
                                {value.elements.length < 2 ? (
                                  <span
                                    className={key}
                                    style={this.state.width < 1024 ? { display: 'flex' } : {}}
                                    id={value === "Closed" ? "Closed" : "Open"}
                                  >
                                    <MdAdd
                                      color="green"
                                      size={"2em"}
                                      onClick={() => this.handleAddHours(key)}
                                    />
                                    <span
                                      style={{
                                        paddingLeft: "10px",
                                        marginBotton: 10,
                                      }}
                                    >
                                      <MdCancel
                                        color="red"
                                        size={"2em"}
                                        onClick={() =>
                                          this.handleDeleteHours(key)
                                        }
                                      />
                                    </span>
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                      marginBotton: 10,
                                    }}
                                  >
                                    <MdCancel
                                      color="red"
                                      size={"2em"}
                                      onClick={() =>
                                        this.handleDeleteHours(key)
                                      }
                                    />
                                  </span>
                                )}
                              </td>
                            ) : (
                              <></>
                            )}
                          </tr>
                          {value.elements
                            .slice(1, value.elements.length)
                            .map((hours, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <select
                                      onChange={this.handleHours}
                                      index={i + 1}
                                      day={key}
                                      id={
                                        value.elements.length
                                          ? "Open"
                                          : "Closed"
                                      }
                                      className={key}
                                      name="start"
                                      defaultValue={moment(
                                        hours.start.toString().padStart(4, "0"),
                                        "HHmm"
                                      ).format("h:mm A")}
                                    >
                                      {time.map((ti) => {
                                        return <option value={ti}>{ti}</option>;
                                      })}
                                    </select>
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <select
                                      onChange={this.handleHours}
                                      index={i + 1}
                                      day={key}
                                      id={
                                        value.elements.length
                                          ? "Open"
                                          : "Closed"
                                      }
                                      className={key}
                                      name="end"
                                      defaultValue={moment(
                                        hours.end.toString().padStart(4, "0"),
                                        "HHmm"
                                      ).format("h:mm A")}
                                    >
                                      {time.map((ti) => {
                                        return <option value={ti}>{ti}</option>;
                                      })}
                                    </select>
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            ) : (
              <></>
            )}
          </table>
          {/* <table style={{ width: '100%' }}>
 {
 Object.entries(this.state.results).length ? <tbody>
 {
 Object.entries(this.state.results).map(([key, value], i) => {
 return (

 <tr key={i} id={key}>
 <td style={{ textTransform: 'capitalize' }}>{key}</td>
 <td style={{ textTransform: 'capitalize' }}>
 <select onChange={this.handleHoursClose} id={key} defaultValue={value === 'Closed' ? "Closed" : "Open"}>
 <option value={"Open"}>Open</option>
 <option value={"Closed"}>Closed</option>
 </select>
 </td>
 <td style={{ textTransform: 'capitalize' }} >
 <select onChange={this.handleHours} day={key} id={value === 'Closed' ? "Closed" : "Open"} className={key} name="start" defaultValue={value.split(" - ")[0]}>
 {
 time.map(ti => {
 return <option value={ti}>{ti}</option>
 })
 }
 </select>
 </td>
 
 <td style={{ textTransform: 'capitalize' }} >
 <select onChange={this.handleHours} id={value === 'Closed' ? "Closed" : "Open"} day={key} className={key} name="end" defaultValue={value.split(" - ")[1]}>
 {
 time.map(ti => {
 return <option value={ti}>{ti}</option>
 })
 }
 </select>
 </td>
 <td style={{ textTransform: 'capitalize' }} >
 <span className={key} id={value === 'Closed' ? "Closed" : "Open"}>
 <MdAdd color="green" size={"2em"} onClick={() => this.handleAddHours(key)} />
 <span style={{ paddingLeft: '10px', marginBotton: 10 }}>
 <MdCancel color="red" size={"2em"} onClick={() => this.handleDeleteHours(key)} /> 
 </span>
 </span>
 
 </td>

 </tr>
 )
 })
 }
 </tbody> : <></>
 }

 </table> */}

          <h1
            style={{
              textTransform: "capitalize",
              fontSize: "1.5em",
              fontWeight: "bold",
            }}
          >
            Business Logo
          </h1>
          <div>
            {this.state.logo ? (
              <img
                style={{ width: "100px", height: "100px" }}
                src={this.state.logo}
                className="img-fluid"
              />
            ) : (
              <></>
            )}
            <br />
            <input onChange={this.handleImage} type="file" id="logo" />
            <Button onClick={this.HandleLogoUpload}>Upload</Button>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button onClick={this.HandleSubmitBtn}>Submit</Button>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(AccountSettings);