import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
// import { userSignup } from "../redux/actions/userActions";
import { connect } from "react-redux";
import { CustomerData } from "../redux/actions/customerActions";
import { postSignup } from "../utils/api";

class Signup extends Component {
  state = {
    // agree: false,
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    email: "",
    mobile: "",
    password: "",
  };

  validateForm() {
    return !(
      // this.state.agree &&
      (
        this.state.firstName.length > 0 &&
        this.state.zip.length > 0 &&
        this.state.lastName.length > 0 &&
        this.state.address.length > 0 &&
        this.state.state.length > 0 &&
        this.state.city.length > 0 &&
        this.state.country.length > 0 &&
        this.state.email.length > 0 &&
        this.state.mobile.length > 0 &&
        this.state.password.length > 0
      )
    );
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleCheckbox = (e) => {
    this.setState({
      agree: e.target.checked,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log(this.state);
    this.setState({ restaurantId: this.props.restaurantId });
    await this.setState({ email: this.state.email.toLowerCase() });
    let res = await postSignup(this.state);
    if (res) {
      this.props.customer.routeTo
        ? (window.location.href = this.props.customer.routeTo)
        : (window.location.href = `/${this.props.restaurant.id}`);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="signup-group">
          <Container className="my-5 pb-3 Signup">
            <h5 className="text-center">
              Fill your details for Signup
            </h5>
            <Form onSubmit={this.handleSubmit} className="my-4">
              <Form.Row>
                <Form.Group as={Col} controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    autoFocus
                    placeholder="First Name"
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    placeholder="Last Name"
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    type="email"
                    placeholder="Email"
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="mobile">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Phone"
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  type="password"
                  placeholder="Password"
                  required
                />
              </Form.Group>

              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  onChange={this.handleChange}
                  placeholder="1234 Main St"
                  required
                />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control onChange={this.handleChange} as="select" required>
                    <option disabled>
                      Choose...
                    </option>
                    <option>Is</option>
                    <option>US</option>
                    <option>UK</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    placeholder="City"
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    type="text"
                    placeholder="State"
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="zip">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    type="number"
                    placeholder="Zip"
                    required
                  />
                </Form.Group>
              </Form.Row>

              {/* <Form.Group className="d-flex justify-content-center">
              <Form.Check
                onChange={this.handleCheckbox}
                id="checkbox"
                type="checkbox"
                required
              />
              <label>
                I agree to
                <Link to="terms"> terms of service</Link>
              </label>
            </Form.Group> */}

              <Button
                block
                variant="warning"
                type="submit"
                disabled={this.validateForm()}
              >
                Submit
              </Button>
            </Form>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    restaurantId: state.restaurant.id,
    customer: state.customer,
    loggedIn: state.customer.loggedIn,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);