import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import {
  FaTh,
  FaAddressCard,
  FaNewspaper,
  FaBox,
  FaBullhorn,
  FaColumns,
  FaBars,
  FaRegSun,
  FaAngleLeft,
  FaAngleRight,
  FaPercent,
  FaTable,
} from "react-icons/fa";
import { MdExitToApp, MdOutlineChair } from "react-icons/md";
import { NavLink, Link } from "react-router-dom";
import brandLogo from "../../assets/PhonedashLogo.jpg";
// import { Link } from "react-router-dom";
import "./SideBar.css";
import { RiBankFill } from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import * as FiIcons from "react-icons/fi";
import * as FaIcons from "react-icons/fa";
import { FaRegListAlt } from "react-icons/fa";

import Logout from "../pages/Logout";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import { GiChart } from "react-icons/gi";
import { useSelector } from 'react-redux';

var logout = function (name) {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  window.location.reload(false);
};
const getCookies = () => {
  return document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value),
      }),
      {}
    );
};
const SideState = (props) => {
  return props;
};
const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [integrate, setIntegrate] = useState("");
  const [resId, setResId] = useState("");
  const restaurant = useSelector(state => state.restaurant)
  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    let token = getCookies().fd_token;
    var decoded = jwt_decode(token);
    // console.log(decoded);
    setIntegrate(decoded.integrate);
    setResId(decoded.resId);
  }, []);
  let menuItem = [];
  let sideBarMenu = [
    {
      name: "Dashboard",
      path: "/merchant/dashboard",
      icon: <FaTh />,
      // icon: <AiIcons.AiFillHome />,
    },
    {
      name: "Reports",
      path: "/merchant/reports",
      icon: <GiChart />,
      // icon: <AiIcons.AiFillHome />,
    },
    {
      name: "Todays Buffet Menu",
      path: "/merchant/buffet-menu",
      icon: <FaRegListAlt />,
      // icon: <AiIcons.AiFillHome />,
    },
    {
      name: "QR Scanner",
      path: "/merchant/qr-scanner",
      icon: <FaNewspaper />,
    },
    {
      name: "Orders",
      path: "/merchant/orders",
      icon: <FaColumns />,
      // icon: <AiIcons.AiOutlineDollar />,
    },
    {
      name: "Track Order",
      path: "/merchant/track-order",
      icon: <FaBullhorn />,
    },
    {
      name: "Customers",
      path: "/merchant/customers",
      icon: <FaAddressCard />,
    },
    {
      name: "Inventory",
      path: "/merchant/inventory",
      icon: <FaBox />,
    },
    {
      name: "Table Dine In",
      path: "/merchant/table-dine-in",
      icon: <FaTable />,
    },
    {
      name: "Shorten Url",
      path: "/merchant/shorten-url",
      icon: <FaTable />,
    },
    {
      name: "Table Orders",
      path: "/merchant/table-orders",
      icon: <BiIcons.BiFoodMenu />,
    },
    {
      name: "Categories",
      path: "/merchant/categories",
      icon: <FaIcons.FaGripHorizontal />,
    },
    {
      name: "Modifiers",
      path: "/merchant/modifiers",
      icon: <BsIcons.BsFillGrid1X2Fill />,
    },
    // {
    //   name: "Conditonal Modifier",
    //   path: "/merchant/condition-modifier",
    //   icon: <BsIcons.BsFillGrid1X2Fill />,
    // },
    // {
    //   name: "Discount Option",
    //   path: "/merchant/discount-option",
    //   icon: <FaPercent />,
    // },
    {
      name: "Order Settings",
      path: "/merchant/order-settings",
      icon: <FaIcons.FaPencilRuler />,
    },
    {
      name: "Integrations",
      path: "/merchant/integration",
      icon: <FaRegSun />,
    },
    {
      name: "Show Qrcode",
      path: "/merchant/showqrcode",
      icon: <AiIcons.AiOutlineQrcode />,
    },
    {
      name: "Payments",
      path: "/merchant/payment",
      icon: <RiBankFill />,
    },
    {
      name: "Auto Post",
      path: "/merchant/autopost",
      icon: <AiIcons.AiOutlineQrcode />,
    },
    {
      name: "Logout",
      path: "/merchant/logout",
      icon: <MdExitToApp />,
    },
  ];
  let notToShow = [];
  // console.log(resId);

  if (["simmer-tech-inc", "qr-code-internal"].includes(resId.toLowerCase())) {
    notToShow = [];
    menuItem = sideBarMenu.filter((ite) => !notToShow.includes(ite.name));
  } else {
    switch (integrate.toLowerCase()) {
      case "clover":
        notToShow = ["Auto Post", "Shorten Url", "QR Scanner"];
        menuItem = sideBarMenu.filter((ite) => !notToShow.includes(ite.name));
        break;
      case "square":
        notToShow = [
          "Auto Post",
          "Payments",
          "QR Scanner",
          // "Modifiers",
          // "Categories",
          "Todays Buffet Menu",
          // "Table Dine In",
          // "Table Orders",
          "Shorten Url",
        ];
        menuItem = sideBarMenu.filter((ite) => !notToShow.includes(ite.name));
        // code block
        break;
      case "custom":
        notToShow = [
          "Auto Post",
          "QR Scanner",
          "Todays Buffet Menu",
          // "Table Dine In",
          // "Table Orders",
          "Shorten Url",
        ];
        menuItem = sideBarMenu.filter((ite) => !notToShow.includes(ite.name));
        // code block
        break;
    }
  }
  if (!restaurant.report_url) {
    menuItem = menuItem.filter((ite) => ite.name !== 'Reports');
  }
  return (
    <>
      {window.innerWidth > 991 ? (
        <>
          <div className="container-sidebar ">
            <div className="sidecontent">
              <div
                style={{
                  width: isOpen ? "220px" : "50px",
                  background: "#ec7211",
                }}
                className="sidebar"
              >
                <div className="top_section">
                  {/* <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
 Logo
 </h1>*/}
                  <div
                    style={{
                      marginLeft: isOpen ? "10px" : "0px",
                      marginTop: "10px",
                    }}
                    className="bars"
                  >
                    {isOpen ? (
                      <FaBars onClick={toggle} />
                    ) : (
                      <>
                        {/* <i
 class="fa fa-angle-right"
 onClick={toggle}
 style={{
 fontSize: "25px",
 padding: "0 20px",
 color: "#444",
 fontWeight: "700",
 }}
 ></i> */}
                        <FaAngleRight onClick={toggle} />
                      </>
                    )}
                  </div>
                </div>
                {isOpen ? (
                  <>
                    <div
                      style={{
                        marginBottom: "5px",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        padding: "7px 0",
                      }}
                    >
                      <img
                        data-v-a1f69832=""
                        src={`${brandLogo}`}
                        width="120px"
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="side-bar-scroll">
                  {menuItem.map((item, index) => (
                    <>
                      <NavLink
                        to={item.path}
                        key={index}
                        className="link"
                        activeclassName="active"
                      >
                        <div className="icon">{item.icon}</div>
                        <div
                          style={{ display: isOpen ? "block" : "none" }}
                          className="link_text"
                        >
                          {item.name}
                        </div>
                      </NavLink>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <main>
              <div className="main-content-dash">
                <div className="full-width">{children}</div>
              </div>
            </main>
          </div>
        </>
      ) : (
        <>
          <div className="container-sidebar ">
            <div className="sidecontent">
              <div className="top_section">
                <div
                  style={{ marginLeft: isOpen ? "10px" : "0px" }}
                  className="bars"
                >
                  {isOpen ? (
                    <>
                      <FaBars onClick={toggle} />
                    </>
                  ) : (
                    <>
                      {/* <i
 class="fa fa-angle-right"
 onClick={toggle}
 style={{
 fontSize: "25px",
 padding: "0 15px",
 color: "#444",
 fontWeight: "700",
 }}
 ></i> */}
                      <FaAngleRight onClick={toggle} />
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: isOpen ? "none" : "block",
                  background: isOpen ? "#fff" : "#ec7211",
                }}
                className="sidebar"
              >
                <div className="mobile-header">
                  {isOpen ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          backgroundColor: "#fff",
                          padding: "7px 0",
                        }}
                      >
                        <img
                          data-v-a1f69832=""
                          src={`${brandLogo}`}
                          width="120px"
                        />
                      </div>
                    </>
                  )}
                  {menuItem.map((item, index) => (
                    <>
                      <NavLink
                        to={item.path}
                        key={index}
                        className="link"
                        activeclassName="active"
                        onClick={toggle}
                      >
                        <div className="icon">{item.icon}</div>
                        <div
                          style={{ display: isOpen ? "none" : "block" }}
                          className="link_text"
                        >
                          {item.name}
                        </div>
                      </NavLink>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <main>
              <div className="main-content-dash">
                <div className="full-width">{children}</div>
              </div>
            </main>
          </div>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    restaurant_id: state.customer.resId,
    customer: state.customer,
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};

export default SideBar;
connect(mapStateToProps)(SideState);