import React from 'react';
import {
    Routes,
    Route,
    Outlet,
    Navigate
} from "react-router-dom";

import ProtectedLogin from "../routes/ProtectedLogin";
import Dashboard from "../customerDashboard/pages/Home";
import Orders from "../customerDashboard/pages/Orders";
import TableOrders from "../customerDashboard/pages/TableOrders";
import Logout from "../customerDashboard/pages/Logout";

const CustomerRouters = (props) => {
    return (
        <>
            <Routes>
                <Route exact element={<ProtectedLogin />}>
                    <Route path="/customer" element={<Outlet />}>
                        <Route
                            index
                            element={
                                props.customer.isLoggedIn ? (
                                    <Navigate
                                        to={
                                            window.location.pathname + "/dashboard"
                                        }
                                    />
                                ) : (
                                    <Dashboard />
                                )
                            }
                        />
                        <Route exact path="dashboard" element={<Dashboard />} />
                        <Route exact path="orders" element={<Orders />} />
                        <Route exact path="table-orders" element={<TableOrders />} />
                        <Route exact path="logout" element={<Logout />} />
                    </Route>
                </Route>
            </Routes>

        </>
    )

}

export default CustomerRouters