import React, { Component, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CartTotalsCheckout from "../../components/CartTotalsCheckout";
import { connect } from "react-redux";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useParams, Link, useSearchParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import _ from "lodash";
import axios from "axios";
import {
  addCustomerCard,
  addCustomerPhone,
  getPaymentType,
  getRestauranOrdertDetails,
  payTableStripe,
  updateCustomer,
  payTableOnline,
  payOnlineCustom,
  updateCustomerEmail,
  updateCustomerPhone,
  getCloverOrderType,
  payTableCash,
} from "../../utils/api";
import { FaUserEdit } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { Button } from "react-bootstrap";
import CreditCardInput from "react-credit-card-input";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentRequestButtonElement,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { async } from "q";
import Webcam from "react-webcam";
import vision from "react-cloud-vision-api";
import moment from "moment";
import {
  squareInit,
  squarePaymentsInit,
  squarePaymentsReInit,
  squareTokenize,
} from "../../utils/functions";
import MultiLingualContent from "../../context/multilingualContent";

vision.init({ auth: "AIzaSyC9Lsf6CBqbjjOXuu63QLxA_SIrtj9cEKE" });

const Date = moment().startOf("month");
let videoConstraints = {
  width: 420,
  height: 420,
  // facingMode: { exact: "environment" },
  // facingMode: "user",
};
if (window.innerWidth > 767) {
  videoConstraints.facingMode = "user";
} else {
  videoConstraints.facingMode = { exact: "environment" };
}
const RequestPay = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const [modalShow, setModalShow] = useState(false);
  const [res_id, setResId] = useState(props.res_id);
  const [name, setName] = useState("");
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: `Total`,
          amount: parseInt(props.total),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        pr.on("paymentmethod", async function (e) {
          //2
          setName(e.paymentMethod.billing_details.name);
          const paymentDetails = {
            payment_method: e.paymentMethod.id,
            shipping: {
              name: e.paymentMethod.billing_details.name,
              phone: e.paymentMethod.billing_details.phone
                ? e.paymentMethod.billing_details.phone
                : "",
              address: {
                line1: `${e.paymentMethod.billing_details.address.line1
                  ? e.paymentMethod.billing_details.address.line1
                  : ""
                  }, ${e.paymentMethod.billing_details.address.line2
                    ? e.paymentMethod.billing_details.address.line2
                    : ""
                  }`,
                city: e.paymentMethod.billing_details.address.city
                  ? e.paymentMethod.billing_details.address.city
                  : "",
                country: e.paymentMethod.billing_details.address.country
                  ? e.paymentMethod.billing_details.address.country
                  : "",
                state: e.paymentMethod.billing_details.address.state
                  ? e.paymentMethod.billing_details.address.state
                  : "",
                postal_code: e.paymentMethod.billing_details.address.postal_code
                  ? e.paymentMethod.billing_details.address.postal_code
                  : "",
              },
            },
          };
          const customer_data = {
            'name': e.paymentMethod.billing_details.name
              ? e.paymentMethod.billing_details.name
              : "",
            'phone': e.paymentMethod.billing_details.phone
              ? e.paymentMethod.billing_details.phone
              : "",
            'email': e.paymentMethod.billing_details.email
              ? e.paymentMethod.billing_details.email
              : ""
          }
          let response = await fetch(
            `/api/order/${res_id}/create-payment-intent`,
            {
              method: "POST",
              body: JSON.stringify({
                paymentDetails,
                customer_data,
                amount: parseInt(props.total),
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          response = await response.json()
          if (response.error) {
            console.log(response.error);
            e.complete("fail");
          } else {
            // e.complete('success');
            let stripe_customer_id = response.customer
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              response.client_secret
            );
            if (error) {
              console.log(error);
              return;
            }
            if (paymentIntent.status === "succeeded") {
              var data = {};
              data["stripe_customer_id"] = stripe_customer_id;
              data["orderId"] = props.orderId;
              data["restaurant_id"] = res_id;
              data["notes"] = props.notes;
              data["customer_id"] = props.customer_id;
              data["orderType"] = props.orderType;
              data["orderIds"] = props.orderIds;
              data["tableId"] = props.tableId;
              data["tip"] = props.tip;
              data["tax"] = props.tax;
              data["subTotal"] = props.subTotal;
              data["total"] = props.total;
              data["tableOrderId"] = props.tableOrderId;
              data["discountAmount"] = props.discountAmount;
              if (e.walletName === "googlePay") {
                data["payment"] = "google";
                data["paymentId"] = props.paymentMethodIds["google"];
              } else if (e.walletName.toLowerCase().includes('apple')) {
                data["payment"] = "apple";
                data["paymentId"] = props.paymentMethodIds["apple"];
              } else {
                data["payment"] = "wallet";
                data["paymentId"] = props.paymentMethodIds["wallet"];
              }
              data["payment_id"] = e.paymentMethod.id;
              const response = await payTableStripe(data);
              if (!response) {
                e.complete("fail");
              } else {
                e.complete("success");
                localStorage.removeItem(res_id);
                if (props.tableId) {
                  window.location.href = `/${res_id}/table/thankyou/${props.tableId}?order_id=${response.tableOrderId}&language=${props.language}`;
                } else {
                  setModalShow(true);
                }
              }
            } else {
              console.warn(
                `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
              );
            }
          }
        });
        // pr.on('token', function (e) { // 1
        //   console.log(e)
        // });
        // pr.on('source', function (e) { //3
        //   console.log(e)
        // });
        pr.on("cancel", function () {
          alert("Payment Cancelled");
        });
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);
  const handleConinue = () => {
    window.location.href = `/${props.res_id}`;
  };
  if (paymentRequest) {
    return (
      <>
        <PaymentRequestButtonElement
          className="payment-element"
          options={{ paymentRequest }}
        />{" "}
        <p className="payment-or">OR</p>
        <Modal
          show={modalShow}
          size="md"
          aria-labelledby="ordersucess"
          centered
        >
          <Modal.Body>
            <>
              <p style={{ textAlign: "center" }}>
                Hi {name}, <br />
                Transaction Completed Successfully.
              </p>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ marginBottom: "0" }} onClick={handleConinue}>
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  // Use a traditional checkout form.
  return <></>;
};

class TablePaymentsCC extends Component {
  state = {
    cardNum: "",
    expiry: "",
    cvv: "",
    stripePromise: "",
    paymentMethod: {},
    modalShow: false,
    loader: false,
    type: "online",
    updated: true,
    isDisable: false,
    orderData: null,
    restaurant_id: this.props.params["restaurantsId"],
    customer_id: "",
    tableId: this.props.params["tableId"],
    tableOrderId: this.props.query.get("table_order_Id"),
    orderType: "",
    gpayButton: true,
    tip_per: 0,
    camShow: false,
    webcamRef: "",
    canvasRef: "",
    coupon_code: "",
    integrate: this.props.integrate,
    payment_gateway: this.props.restaurant.payment_gateway,
    coupon_error: "",
    applied_coupon_code: "",
    activeTip: 0,
    table: false,
    is_pay_online: 0,
    is_pay_restaurant: 0,
    cardDecline: false,
    loaderApi: true,
  };
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getRestaurantTableSettings();
    // const orderData = await getRestauranOrdertDetails(
    //   this.state.restaurant_id,
    //   this.state.tableId
    // );
    // console.log(this.state.tableOrderId);

    const orderDataRaw = await fetch(
      `/api/v1/table/get/table/order/data/${this.state.restaurant_id}?tableId=${this.state.tableId}&tableOrderId=${this.state.tableOrderId}`
    );
    const orderData = await orderDataRaw.json();
    if (orderData) {
      var orderDetails = {};
      // this.setState({ ...this.state, ...orderData });
      orderDetails["subTotal"] = orderData.subTotal;
      orderDetails["tip"] = orderData.tip;
      orderDetails["total"] = orderData.total;
      orderDetails["discountAmount"] = 0;
      orderDetails["coupon_discount_percentage"] = 0;
      orderDetails["tax"] = orderData.tax;
      orderDetails["totalTax"] = orderData.totalTax;
      orderDetails["initSubTotal"] = orderData.subTotal;
      orderDetails["initTip"] = orderData.tip;
      orderDetails["initTotal"] = orderData.total;
      orderDetails["initTax"] = orderData.tax;
      this.setState({
        orderData: {
          ...this.state.orderData,
          ...orderDetails,
        },
        customer_id: orderData.customerId,
        tip: orderData.tip,
        orderIds: orderData.orderIds,
        amount: orderData.total,
      });
      if (this.state.integrate === "clover") {
        var paymentId = {};
        const paymentTypes = await getPaymentType(this.state.restaurant_id);
        var paymentType = paymentTypes.filter(
          (type) =>
            type.label.toLowerCase().includes("google") ||
            type.label.toLowerCase().includes("apple") || type.label.toLowerCase().includes("wallet")
        );
        paymentType.forEach((typ) => {
          if (typ.label.toLowerCase().includes("google")) {
            paymentId["google"] = typ.id;
          }
          if (typ.label.toLowerCase().includes("apple")) {
            paymentId["apple"] = typ.id;
          } if (typ.label.toLowerCase().includes("wallet")) {
            paymentId["wallet"] = typ.id;
          }
        });
        const orderTypeId = await getCloverOrderType(
          this.state.restaurant_id,
          "table_order"
        );

        this.setState({
          paid: false,
          customer_id: orderData.customerId,
          paymentMethod: paymentId,
          orderType: orderTypeId["id"],
        });
      }
      if (this.state.payment_gateway === "square") {
        let _ = this;
        setTimeout(() => {
          _.squareInit(
            this.props.restaurant.restaurant.app_id,
            this.props.restaurant.restaurant.location_id
          );
        }, 100);
      } else {
        const stripePromise = await loadStripe(orderData.stripe_pk);
        this.setState({
          stripePromise,
        });
      }
    } else {
      this.setState({
        paid: true,
      });
    }

    this.setPaymentData(18);
    this.setState({ loaderApi: false });
  }

  squareInit = async (app_id, location_id) => {
    const payments = await squareInit(app_id, location_id);
    this.setState({
      squarePayment: payments,
    });
    await squarePaymentsInit(
      payments,
      this.setValue,
      parseFloat(parseFloat(this.state.orderData.total / 100).toFixed(2))
    );
    // await squarePaymentsInit(this.state.orderData.total, payments, this.setValue);
  };
  setValue = (obj) => {
    this.setState({
      ...obj,
    });
  };
  createPayment = async (token) => {
    var data = _.pick(this.state, [
      "cardNum",
      "cvv",
      "expiry",
      "orderId",
      "restaurant_id",
      "customer_id",
      "tip",
      "notes",
      "orderIds",
      "amount",
      "tableId",
      "tableOrderId",
      "orderType",
      "orderData",
    ]);
    const res = await payTableOnline(data, {
      payment_gateway: this.state.payment_gateway,
      payment_type: this.state.payment_type,
      token: token,
      location_id: this.props.restaurant.location_id,
    });

    if (res) {
      localStorage.removeItem(this.state.restaurant_id);
      if (this.state.tableId) {
        window.location.href = `/${this.state.restaurant_id}/table/thankyou/${this.state.tableId}?order_id=${this.state.tableOrderId}&language=${this.props.restaurant.language}`;
      } else {
        this.setState({
          loader: false,
          modalShow: true,
        });
      }
    } else {
      this.setState({ isDisable: false, loader: false, cardDecline: true });
    }
  };

  handlePaymentMethodSubmission = async (event, paymentMethod) => {
    event.preventDefault();
    try {
      this.setState({ isDisable: true, loader: true });
      const token = await squareTokenize(paymentMethod, this.setValue);
      await this.createPayment(token);
    } catch (e) {
      console.log(e);
      this.setState({ isDisable: false, loader: false, cardDecline: true });
    }
  };

  handleCardPayment = async (e) => {
    e.preventDefault();
    this.setState({
      payment_type: "card",
    });
    const res = await this.handlePaymentMethodSubmission(e, this.state.card);
  };

  handleApplePayment = async (e) => {
    this.setState({
      payment_type: "apple",
      isDisable: true,
      loader: true,
    });
    await this.handlePaymentMethodSubmission(e, this.state.applePay);
  };
  handleGooglePayment = async (e) => {
    this.setState({
      payment_type: "google",
      isDisable: true,
      loader: true,
    });
    await this.handlePaymentMethodSubmission(e, this.state.googlePay);
  };
  getRestaurantTableSettings = async () => {
    try {
      let response = await fetch(
        `/api/v1/merchant/get/restaurant/table-order/settings/${this.state.restaurant_id}?tableId=${this.state.tableId}`
      );
      response = await response.json();
      console.log("response", response.data);

      if (response.data) {
        let { table, is_pay_online, is_pay_restaurant } = response.data;
        this.setState((prevState) => ({
          ...prevState,
          table,
          is_pay_online,
          is_pay_restaurant,
        }));
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  capture = async () => {
    const base = this.webcamRef.current.getScreenshot();
    const req = new vision.Request({
      image: new vision.Image({
        base64: base,
      }),
      features: [new vision.Feature("TEXT_DETECTION", 5)],
    });
    vision.annotate(req).then(
      (res) => {
        if (res.responses[0].fullTextAnnotation !== undefined) {
          let dataText = JSON.stringify(
            res.responses[0]?.fullTextAnnotation?.text
          );
          dataText = JSON.parse(dataText);
          let splitNum = dataText.split(/\r?\n/);
          console.log(splitNum);
          const cardNumber1 = splitNum
            .filter((sd) => sd.length === 19)
            .map((sd) => sd.replace(/[^0-9 ]/g, ""));
          const cardNumber = cardNumber1.filter((sd) => sd.length === 19);
          console.log("15151", cardNumber);
          if (cardNumber.length) {
            // this.setState({ cardNum: cardNumber[0] });
            // this.setState({ camShow: false });
            const cardExp = splitNum.filter((sd) => sd.includes("/"));
            console.log(22, cardExp);
            if (/\s/.test(cardExp) === true) {
              const e = cardExp.filter((sd) => sd.includes(" "));
              if (/\s/.test(e) === true) {
                let Exp = e.join(" ").split(" ");
                console.log(Exp);
                const cardExpFinal = Exp.filter(
                  (sd) => sd.includes("/") && sd.length === 5
                );
                console.log("check", cardExpFinal);
                const cardExpFinal1 = cardExpFinal.filter(
                  (sd) => moment(sd, "MM/YY").isSameOrAfter(Date) === true
                );
                console.log("final", cardExpFinal1);
                if (cardExpFinal1.length) {
                  this.setState({ cardNum: cardNumber[0] });
                  this.setState({ expiry: cardExpFinal1[0] });
                  this.setState({ camShow: false });
                } else {
                  this.setState({ expShow: true });
                }
              } else {
                const cardExpFinal = e.filter(
                  (sd) => sd.includes("/") && sd.length === 5
                );
                console.log("check", cardExpFinal);
                const cardExpFinal1 = cardExpFinal.filter(
                  (sd) => moment(sd, "MM/YY").isSameOrAfter(Date) === true
                );
                console.log("final", cardExpFinal1);
                if (cardExpFinal1.length) {
                  this.setState({ cardNum: cardNumber[0] });
                  this.setState({ expiry: cardExpFinal1[0] });
                  this.setState({ camShow: false });
                } else {
                  this.setState({ expShow: true });
                }
              }
            } else {
              if (cardExp.length) {
                const cardExpFinal = cardExp.filter(
                  (sd) => sd.includes("/") && sd.length === 5
                );
                console.log("check", cardExpFinal);
                const cardExpFinal1 = cardExpFinal.filter(
                  (sd) => moment(sd, "MM/YY").isSameOrAfter(Date) === true
                );
                console.log("final", cardExpFinal1);
                if (cardExpFinal1.length) {
                  this.setState({ cardNum: cardNumber[0] });
                  this.setState({ expiry: cardExpFinal1[0] });
                  this.setState({ camShow: false });
                } else {
                  this.setState({ expShow: true });
                }
              }
            }
          } else {
            this.setState({ CardInv: true });
          }
        }
      },
      (e) => {
        console.log("Error: ", e);
      }
    );
  };
  couponChange = (e) => {
    this.setState({
      coupon_code: e.target.value,
      coupon_error: "",
      applied_coupon_code: "",
    });
  };
  openCam = () => {
    this.setState({ camShow: true });
    // this.Interval = setInterval(() => {
    //   this.capture();
    // }, 1000);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.target.style.boxShadow = "none";
  };
  handleEdit = (e) => {
    this.setState({
      updated: false,
      edit: true,
    });
  };

  handlePaymentEdit = (e) => {
    this.setState({
      updated: false,
      editPayment: true,
    });
  };

  updateUser = async (e) => {
    e.preventDefault();

    if (
      this.props.customer.phoneNumbers &&
      this.props.customer.phoneNumbers.elements[0]
    ) {
      await updateCustomerPhone(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.phoneNumbers.elements[0]["id"],
        this.state
      );
    } else {
      await addCustomerPhone(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.addresses.elements[0]["id"],
        this.state
      );
    }
    if (
      this.props.customer.emailAddresses &&
      this.props.customer.emailAddresses.elements[0]
    ) {
      await updateCustomerEmail(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.emailAddresses.elements[0]["id"],
        this.state
      );
    }
    await updateCustomer(
      this.state.restaurant_id,
      this.state.customer_id,
      this.state
    );
    this.setState({
      edit: false,
      updated: true,
    });
  };
  handlePaymentUpdate = async (e) => {
    e.preventDefault();
    // if (this.props.customer.cards && this.props.customer.cards.elements[0]) {
    //   await updateCustomerCard(this.state.restaurant_id, this.state.customer_id, this.props.customer.cards.elements[0]['id'], this.state.cardNum, this.state.expiry, this.state.cvv)
    // } else {
    await addCustomerCard(
      this.state.restaurant_id,
      this.state.customer_id,
      this.state.cardNum,
      this.state.expiry,
      this.state.cvv
    );
    // }
    this.setState({
      editPayment: false,
      updated: true,
    });
  };
  handleNewOrder = async (event) => {
    event.preventDefault();

    if (
      this.state.cvv.length > 0 &&
      this.state.expiry.length > 0 &&
      this.state.cardNum.length > 0
    ) {
      var data = _.pick(this.state, [
        "cardNum",
        "cvv",
        "expiry",
        "orderId",
        "restaurant_id",
        "customer_id",
        "tip",
        "notes",
        "orderIds",
        "amount",
        "tableId",
        "tableOrderId",
        "orderType",
        "orderData",
      ]);
      this.setState({ isDisable: true, loader: true });
      const res = await payTableOnline(data, {
        payment_gateway: this.state.payment_gateway,
      });
      if (res) {
        localStorage.removeItem(this.state.restaurant_id);
        if (this.state.tableId) {
          window.location.href = `/${this.state.restaurant_id}/table/thankyou/${this.state.tableId}?order_id=${this.state.tableOrderId}&language=${this.props.restaurant.language}`;
        } else {
          this.setState({
            loader: false,
            modalShow: true,
          });
        }
      } else {
        this.setState({ isDisable: false, loader: false, cardDecline: true });
      }
    } else {
      if (
        this.state.cvv === "" ||
        this.state.expiry === "" ||
        this.state.cardNum === ""
      ) {
        document.getElementById("field-wrapper").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
      }
    }
  };
  handleBlur = (e) => {
    // e.target.style.boxShadow = "none";
  };
  handleConinue = () => {
    window.location.href = `/${this.state.restaurant_id}`;
    this.props.Reset();
  };
  tipsChange = (e) => {
    const list = document.getElementById("tipslist");
    var items = list.getElementsByTagName("li");
    for (var i = 0; i < items.length; ++i) {
      if (items[i].getAttribute("name") === e.target.getAttribute("name")) {
        items[i].classList.add("active");
      } else {
        items[i].classList.remove("active");
      }
    }
    if (e.target.getAttribute("name") !== "custom") {
      // console.log(e.target.getAttribute("name"))
      const tipSeleted = Number(e.target.getAttribute("name"));
      this.setPaymentData(tipSeleted);
      // setTip(parseInt(e.target.getAttribute("name")));
      // props.Tip(parseInt(e.target.getAttribute("name")));
    }
  };
  setPaymentData = (tip, data = this.state.orderData) => {
    this.setState({ gpayButton: false, activeTip: tip });
    const {
      initSubTotal: subTotal,
      initTotal: total,
      initTax,
      initTip,
      totalTax,
      coupon_discount_percentage,
    } = data;
    let newTip;
    let newTotal;
    let newTax;
    if (coupon_discount_percentage) {
      let disAmount = subTotal * (coupon_discount_percentage / 100);
      let disSub = subTotal - disAmount;
      newTip = disSub * (tip / 100);
      newTax = disSub * totalTax;
      newTotal = subTotal + newTip + newTax - disAmount;
      this.setState({
        orderData: {
          ...this.state.orderData,
          ...{
            total: newTotal,
            tip: newTip,
            tax: newTax,
            discountAmount: disAmount,
          },
        },
        tip: newTip || initTip,
        amount: newTotal,
        tip_per: tip,
      });
    } else {
      newTip = subTotal * (tip / 100);
      newTotal = newTip + total;
      this.setState({
        orderData: {
          ...this.state.orderData,
          ...{ total: newTotal, tip: newTip },
        },
        tip: newTip || initTip,
        amount: newTotal,
        tip_per: tip,
      });
    }
    squarePaymentsReInit(
      this.state,
      parseFloat(parseFloat(newTotal / 100).toFixed(2)),
      this.state.squarePayment,
      this.setValue
    );
    setTimeout(() => this.setState({ gpayButton: true }), 1000);
  };
  handlePayCash = async () => {
    {
      var data = _.pick(this.state, [
        "cardNum",
        "cvv",
        "expiry",
        "orderId",
        "restaurant_id",
        "customer_id",
        "tip",
        "notes",
        "orderIds",
        "amount",
        "tableId",
        "tableOrderId",
        "orderType",
        "orderData",
        "tip_per",
      ]);
      this.setState({ isDisable: true, loader: true });
      const res = await payTableCash(data);
      if (res) {
        localStorage.removeItem(this.state.restaurant_id);
        if (this.state.tableId) {
          window.location.href = `/${this.state.restaurant_id}/table/cash/${this.state.tableId}?order_id=${this.state.tableOrderId}`;
        } else {
          this.setState({
            loader: false,
            modalShow: true,
          });
        }
      } else {
        this.setState({ isDisable: false, loader: false });
      }
    }
  };
  couponSubmit = (e) => {
    e.preventDefault();
    if (this.state.coupon_code != "") {
      axios
        .post(`/api/integrations/get/coupon_code/${this.state.restaurant_id}`, {
          coupon_code: this.state.coupon_code,
        })
        .then((res) => {
          //const [result] = JSON.parse(JSON.stringify(res));
          console.log(res.data);
          let { coupon_discount, msg } = res.data;
          if (msg == "Applied") {
            if (coupon_discount > 0) {
              this.setState({
                orderData: {
                  ...this.state.orderData,
                  ...{ coupon_discount_percentage: Number(coupon_discount) },
                },
              });
              this.setPaymentData(this.state.activeTip);
            }
          } else {
            this.setState({
              coupon_error: msg,
              applied_coupon_code: "",
            });
            //   setAppliedCouponCode("");
            //   setCouponMessage(res.data.msg);
          }
          // if (res.data.msg == "Applied") {
          //   setCouponApplied(false);
          //   let discount = 0;
          //   let couponTotal = 0;
          //   let couponTax = 0;
          //   if (res.data.coupon_discount > 0) {
          //     discount = parseFloat(
          //       (props.totalPrice * res.data.coupon_discount) / 100
          //     ).toFixed(2);
          //     console.log(10, discount);
          //     couponTotal = props.totalPrice - discount;
          //     couponTax = Number(
          //       parseFloat(props.restaurant.totalTax * couponTotal).toFixed(2)
          //     );
          //     setCouponTax(couponTax);
          //     setCouponTotal(couponTotal);
          //     let toSetData = {
          //       subTotal: props.totalPrice,
          //       tip: {
          //         value: Number(
          //           parseFloat(((tip / 100) * props.totalPrice).toFixed(2))
          //         ),
          //         percentage: tip,
          //       },
          //       tax: {
          //         value: Number(
          //           parseFloat(
          //             (props.restaurant.totalTax * props.totalPrice).toFixed(2)
          //           )
          //         ),
          //         percentage: props.restaurant.totalTax,
          //       },
          //       total: Number(
          //         parseFloat(
          //           props.totalPrice +
          //             props.restaurant.totalTax * props.totalPrice +
          //             parseFloat(props.tips)
          //         ).toFixed(2)
          //       ),
          //       finalSubTotal: props.totalPrice - discount,
          //       finalTip: {
          //         value: Number(
          //           parseFloat(
          //             (props.totalPrice - discount) * (tip / 100)
          //           ).toFixed(2)
          //         ),
          //         percentage: tip,
          //       },
          //       finalTax: {
          //         value: Number(
          //           parseFloat(
          //             props.restaurant.totalTax * (props.totalPrice - discount)
          //           ).toFixed(2)
          //         ),
          //         percentage: props.restaurant.totalTax,
          //       },
          //       finalTotal: Number(
          //         parseFloat(
          //           couponTotal + couponTax + couponTotal * (tip / 100)
          //         ).toFixed(2)
          //       ),
          //       discount: {
          //         name: "",
          //         value: discount,
          //         percentage: res.data.coupon_discount,
          //       },
          //       couponCode: {
          //         name: "",
          //         code: coupon_code,
          //         percentage: res.data.coupon_discount,
          //       },
          //     };
          //     setTimeout(() => {
          //       props.checkoutInit(toSetData);
          //       props.reInitGooglePay();
          //     }, 200);
          //     setFinalPrice(toSetData.finalTotal);
          //   }
          //   setCouponMessage(res.data.msg);
          //   setAppliedCouponCode(coupon_code);
          //   setCouponDiscountPercentage(res.data.coupon_discount);
          //   setCouponDiscountAmount(discount);
          //   setCouponApplied(true);
          // } else {
          //   setAppliedCouponCode("");
          //   setCouponMessage(res.data.msg);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };
  render() {
    const checkout = (
      <Row style={{ marginTop: "30px" }}>
        <Col xs={12} lg={6}>
          {this.state.is_pay_restaurant ? (
            <div className="d-flex justify-content-center">
              <Button
                style={{ width: "auto", marginBottom: "10px" }}
                onClick={() => {
                  this.setPaymentData(0);
                  setTimeout(() => this.handlePayCash(), 1000);
                }}
                variant="warning"
                block
              >
                Pay on Cash
              </Button>{" "}
            </div>
          ) : (
            <></>
          )}

          <div className="payment-button">
            {this.state.payment_gateway === "square" ? (
              <>
                <div
                  id="google-pay-button"
                  onClick={this.handleGooglePayment}
                ></div>
                <div
                  id="apple-pay-button"
                  onClick={this.handleGooglePayment}
                ></div>
              </>
            ) : (
              <>
                {this.state.gpayButton &&
                  this.state.stripePromise &&
                  this.state.orderData ? (
                  <div style={{ width: "100%" }}>
                    {" "}
                    <Elements stripe={this.state.stripePromise}>
                      <RequestPay
                        customer_id={this.state.customer_id}
                        orderId={this.state.orderId}
                        paymentMethodIds={this.state.paymentMethod}
                        subTotal={this.state.orderData.subTotal}
                        total={this.state.orderData.total}
                        res_id={this.state.restaurant_id}
                        tip={this.state.orderData.tip}
                        // tip={this.state.orderData.tip * 100}
                        tax={this.state.orderData.tax}
                        notes={this.state.notes}
                        orderIds={this.state.orderIds}
                        tableId={this.state.tableId}
                        tableOrderId={this.state.tableOrderId}
                        orderType={this.state.orderType}
                        discountAmount={this.state.orderData.discountAmount}
                      />
                    </Elements>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          {/* scan card */}
          <Modal
            show={this.state.camShow}
            onHide={(e) => this.setState({ camShow: false })}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {/* <ModalDialog size={true}> */}
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <MultiLingualContent contentID="Scan credit card" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div
                  style={{
                    textAlign: "center",
                    // paddingTop: " 12px",
                  }}
                >
                  <Webcam
                    audio={false}
                    width={360}
                    height={240}
                    ref={this.webcamRef}
                    imageSmoothing={true}
                    screenshotQuality={1}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                </div>
              </>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "center" }}>
              <Button
                style={{ textAlign: "center", marginBottom: "0" }}
                onClick={this.capture}
              >
                Capture
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.expShow}
            onHide={(e) => this.setState({ expShow: false })}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="scan-popup"
          >
            <Modal.Header style={{ border: "0" }} closeButton></Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", fontSize: "20px", padding: "0" }}
            >
              Your card has been expired
            </Modal.Body>
            <Modal.Footer style={{ border: "0", justifyContent: "center" }}>
              <Button
                style={{ textAlign: "center", marginBottom: "0" }}
                onClick={() => this.setState({ expShow: false })}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.CardInv}
            onHide={(e) => this.setState({ CardInv: false })}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="scan-popup"
          >
            <Modal.Header style={{ border: "0" }} closeButton></Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", fontSize: "20px", padding: "0" }}
            >
              Take a clear picture of your card
            </Modal.Body>
            <Modal.Footer style={{ border: "0", justifyContent: "center" }}>
              <Button
                style={{ textAlign: "center", marginBottom: "0" }}
                onClick={() => this.setState({ CardInv: false })}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.modalShow}
            size="md"
            aria-labelledby="ordersucess"
            centered
          >
            <Modal.Body>
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  Transaction Completed Successfully
                </p>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ marginBottom: "0" }}
                onClick={this.handleConinue}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
          <Card className="mt-3 mb-5">
            <div>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                Enter the card details
              </p>
            </div>

            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span>PAYMENT</span>{" "}
              {this.state.payment_gateway === "square" ? (
                <></>
              ) : (
                <Button
                  variant="warning"
                  onClick={this.openCam}
                  className="mt-4"
                >
                  <MultiLingualContent contentID="Scan credit card" />
                </Button>
              )}
            </Card.Header>
            <Card.Body className="card-btn">
              {this.state.payment_gateway === "square" ? (
                <form id="payment-form">
                  <div id="card-container"></div>
                </form>
              ) : (
                <Form
                  className="p-4"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CreditCardInput
                    cardNumberInputProps={{
                      value: this.state.cardNum,
                      onBlur: (e) =>
                      (document.getElementById(
                        "field-wrapper"
                      ).style.boxShadow = "none"),
                      onChange: (e) => {
                        this.setState({
                          cardNum: e.target.value,
                        });
                      },
                    }}
                    cardExpiryInputProps={{
                      value: this.state.expiry,
                      onBlur: (e) =>
                      (document.getElementById(
                        "field-wrapper"
                      ).style.boxShadow = "none"),
                      onChange: (e) => {
                        this.setState({
                          expiry: e.target.value,
                        });
                      },
                    }}
                    cardCVCInputProps={{
                      value: this.state.cvv,
                      onBlur: (e) =>
                      (document.getElementById(
                        "field-wrapper"
                      ).style.boxShadow = "none"),
                      onChange: (e) => {
                        this.setState({
                          cvv: e.target.value,
                        });
                      },
                    }}
                    fieldClassName="card-input form-control"
                  />
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
        {this.state.orderData && (
          <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={5}>
            <div className="checkout-cont">
              <h4
                className="my-auto"
                style={{ fontSize: "17px", fontWeight: "600" }}
              >
                <MultiLingualContent contentID="Order Summary" />
              </h4>
              <Card className="mt-3">
                {/* <Card.Header className="pl-2 font-weight-bold">
                  {this.state.orderData.totalItems} items
                </Card.Header> */}
                <Card.Body className="border-top" style={{ padding: "10px" }}>
                  <div className="tab-group tips">
                    <h6>
                      <MultiLingualContent contentID="Add Tip" />
                    </h6>
                    <ul id="tipslist">
                      <li onClick={this.tipsChange} className="" name="0">
                        <MultiLingualContent contentID="No Tip" />
                      </li>
                      <li onClick={this.tipsChange} className="" name="10">
                        10%
                      </li>
                      <li
                        onClick={this.tipsChange}
                        className="active"
                        name="18"
                      >
                        18%
                      </li>
                      <li onClick={this.tipsChange} className="" name="20">
                        20%
                      </li>
                    </ul>
                  </div>
                </Card.Body>
                <Card.Body className="d-flex border-bottom justify-content-between p-2 ">
                  <Card.Text>
                    <MultiLingualContent contentID="Subtotal" />
                  </Card.Text>
                  <Card.Text>
                    {(this.state.orderData.subTotal / 100).toLocaleString(
                      "en-US",
                      { style: "currency", currency: "USD" }
                    )}
                  </Card.Text>
                  {/* <Card.Text>{this.state.orderData.subTotal}</Card.Text> */}
                </Card.Body>
                {this.state.orderData.discountAmount ? (
                  <Card.Body
                    className="d-flex justify-content-between p-2 pricing"
                    style={{ padding: "10px" }}
                  >
                    <Card.Text>
                      <MultiLingualContent contentID="Coupon Applied" />
                    </Card.Text>
                    {/* <Card.Text>10% Discount (Mela)</Card.Text> */}
                    <Card.Text>
                      -
                      {(
                        this.state.orderData.discountAmount / 100
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Card.Text>
                  </Card.Body>
                ) : (
                  <></>
                )}
                <Card.Body className="d-flex justify-content-between p-2">
                  <Card.Text>Tax</Card.Text>
                  <Card.Text>
                    {(this.state.orderData.tax / 100).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Card.Text>
                  {/* <Card.Text>{this.state.orderData.tax}</Card.Text> */}
                </Card.Body>{" "}
                <Card.Body className="d-flex justify-content-between p-2">
                  <Card.Text>Tip</Card.Text>
                  <Card.Text>
                    {(this.state.orderData.tip / 100).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Card.Text>
                  {/* <Card.Text>{this.state.orderData.tip}</Card.Text> */}
                </Card.Body>
                <Card.Body className="d-flex justify-content-between p-2">
                  <Card.Text>
                    <MultiLingualContent contentID="Total" />
                  </Card.Text>
                  <Card.Text>
                    {(this.state.orderData.total / 100).toLocaleString(
                      "en-US",
                      { style: "currency", currency: "USD" }
                    )}
                  </Card.Text>
                  {/* <Card.Text>{this.state.orderData.total}</Card.Text> */}
                </Card.Body>
                {/* {props.restaurant.isCoupon ? ( */}
                <Card.Body className="coupon-field border-bottom p-2 pricing">
                  <Card.Text>
                    <MultiLingualContent contentID="Coupon" />:
                  </Card.Text>
                  <Card.Text>
                    <Form style={{ boxShadow: "none" }}>
                      <div style={{ display: "flex" }}>
                        <Form.Group className="mb-0" style={{ width: "100%" }}>
                          <Form.Control
                            onChange={this.couponChange}
                            type="text"
                            name="coupon_value"
                            placeholder="Enter Coupon code"
                          />
                        </Form.Group>
                        <div className="text-center">
                          <button
                            onClick={this.couponSubmit}
                            style={{ padding: "5px 10px" }}
                            className="btn btn-primary mb-0 ml-2"
                            type="button"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      {this.state.coupon_error ? (
                        <div style={{ padding: "5px 10px" }}>
                          {this.state.coupon_error}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Form>
                  </Card.Text>
                </Card.Body>
                {/* ) : (
                  <></>
                )} */}
                <Card.Footer
                  style={{
                    border: "0",
                    background: "none",
                    padding: "22px 0 0",
                  }}
                >
                  <Button
                    style={{ width: "auto" }}
                    onClick={
                      this.state.payment_gateway === "square"
                        ? this.handleCardPayment
                        : this.handleNewOrder
                    }
                    variant="warning"
                    block
                  >
                    Pay
                  </Button>{" "}
                  <hr />
                  <h6 className="mt-3">
                    <MultiLingualContent contentID="WE ACCEPT" />:
                    <Image
                      className="img-thumbnail mt-1"
                      src="https://assets.asosservices.com/asos-finance/images/marketing/single.png"
                      alt="payment options"
                    />
                  </h6>
                </Card.Footer>
              </Card>
            </div>
          </Col>
        )}
      </Row>
    );

    return (
      <React.Fragment>
        <Container
          className="my-auto Checkout"
          style={{ opacity: this.state.loader ? "0.4" : "1", padding: 0 }}
        >
          {!this.state.is_pay_online ? (
            <>
              {!this.state.loaderApi ? (
                <Modal
                  show={true}
                  size="md"
                  aria-labelledby="ordersucess"
                  centered
                >
                  <Modal.Body>
                    <>
                      <p
                        style={{
                          textAlign: "center",
                          margin: "0px 0 6px 0",
                          paddingTop: " 12px",
                          fontSize: "18px",
                        }}
                      >
                        We temporarily stopped Online Payments.
                      </p>
                    </>
                  </Modal.Body>
                  {/* <Modal.Footer>
                <Button
                  style={{ marginBottom: "0" }}
                  onClick={this.handleConinue}
                >
                  Continue
                </Button>
              </Modal.Footer> */}
                </Modal>
              ) : (
                <></>
              )}
            </>
          ) : this.state.paid ? (
            <>
              <Modal
                show={true}
                size="md"
                aria-labelledby="ordersucess"
                centered
              >
                <Modal.Body>
                  <>
                    <p
                      style={{
                        textAlign: "center",
                        margin: "0px 0 6px 0",
                        paddingTop: " 12px",
                        fontSize: "18px",
                      }}
                    >
                      Order Already Paid
                    </p>
                  </>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    style={{ marginBottom: "0" }}
                    onClick={this.handleConinue}
                  >
                    Continue
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            checkout
          )}
          <Modal
            show={this.state.cardDecline}
            size="lg"
            aria-labelledby="ordersucess"
            centered
            onHide={() => this.setState({ cardDecline: false })}
          >
            <Modal.Header>
              <div
                className="py-4"
                style={{
                  textAlign: "center",
                  // padding: "3em 0 0",
                  fontWeight: "600",
                  fontSize: "16px",
                  margin: " 0 auto",
                }}
              >
                Your Card Declined, Order is not Placed. Please check your card
                details!
              </div>
            </Modal.Header>
            <Modal.Footer>
              <Button onClick={() => this.setState({ cardDecline: false })}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </React.Fragment>
    );
  }
}
const TablePayment = (props) => {
  const [searchParams] = useSearchParams();
  return (
    <TablePaymentsCC {...props} params={useParams()} query={searchParams} />
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    total: state.cart.total,
    totalPrice: state.cart.totalPrice,
    tip: state.cart.tip,
    tips: state.cart.tips,
    restaurant: state.restaurant,
    name: state.restaurant.restaurant.name,
    orderCart: state.cart.orderCart,
    customer: state.customer,
    loggedIn: state.customer.isLoggedIn,
    stripe_pk: state.restaurant.restaurant.stripe_pk,
    integrate: state.restaurant.integrate,
    cart: state.cart,
    tax: state.restaurant.totalTax,
    table: state.table,
    tablePath: state.restaurant.tablePath,
    support: state.restaurant.support,
    checkout: state.checkout,
  };
};

export default connect(mapStateToProps, null)(TablePayment);
