import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
class Tmenus extends Component {
  state = {
    checkedItem: [],
  };
  async componentDidMount() {
    axios
      .get(`/api/${this.props.restaurant.id}/daily-menu`)
      .then((res) => {
        console.log();
        this.setState({
          items: JSON.parse(res.data[0]["items"])
            ? JSON.parse(res.data[0]["items"])
            : [],
          checkedItem: JSON.parse(res.data[0]["menu"])
            ? JSON.parse(res.data[0]["menu"])
            : [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    return (
      <>
        <Container>
          <div className="special-menu">
            <Card>
              <Card.Header>Today Specials</Card.Header>
              <Card.Body>
                {this.state.checkedItem.map(function (item) {
                  return <Card.Text>{item}</Card.Text>;
                })}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    restaurantId: state.restaurant.id,
  };
};
export default connect(mapStateToProps)(Tmenus);
