const initialState = {
  subTotal: 0,
  tip: { value: 0, percentage: 0 },
  tax: { value: 0, percentage: 0 },
  total: 0,
  finalSubTotal: 0,
  finalTip: { value: 0, percentage: 0 },
  finalTax: { value: 0, percentage: 0 },
  finalTotal: 0,
  discount: { name: "", value: "", percentage: 0 },
  couponCode: { name: "", code: "", percentage: 0 },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "INIT2":
      state = {
        ...state,
        ...action.data,
      };
      // console.log(action.data);
      break;
    default:
      break;
  }
  return state;
};
