import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "../pages.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as BsIcons from "react-icons/bs";
import _ from "lodash";
import { MdCancel, MdModeEdit } from "react-icons/md";
import * as MdIcons from "react-icons/md";
import { toast } from "react-toastify";

async function warnRedBox(id, action = "add") {
  if (action === "add") {
    document.getElementById(id).style.boxShadow =
      "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
  } else {
    document.getElementById(id).style.boxShadow = "none";
  }
}
async function warnNotify(message) {
  toast.error(message);
}
function SquareModifiers(props) {
  const { id: current_modifier_list_id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [modList, setModList] = useState([]);
  const [pending, setPending] = useState(true);
  const [productList, setProductList] = useState([]);
  const [productPending, setProductPending] = useState(true);
  const [modifersList, setModifersList] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [modiferListName, setModiferListName] = useState("");
  const [selectedMod, setSelectedMod] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [choosedItemsId, setChoosedItemsId] = useState([]);
  const [initItemsId, setInitItemsId] = useState([]);
  const [modifer, setModifer] = useState({
    type: "MODIFIER",
    id: `#${new Date().getTime()}`,
    is_deleted: false,
    present_at_all_locations: true,
    modifier_data: {
      name: "",
      price_money: {
        amount: 0,
        currency: "USD",
      },
      ordinal: 0,
      on_by_default: false,
    },
  });
  const [modelState, setModelState] = useState({
    addProduct: false,
    addProductSpinner: false,
    addModifier: false,
    addModifierSpinner: false,
    editModifier: false,
    editModifierSpinner: false,
    customizeModifier: false,
    customizeModifierSpinner: false,
  });
  useEffect(() => {
    (() => {
      updateInitalState(current_modifier_list_id);
    })();
  }, []);
  async function updateInitalState(ID) {
    setModiferListName(state.name);
    try {
      let getList = await fetch(
        `/api/v1/merchant/get/square/modiferList/${ID}/${props.restaurant.id}`
      );
      getList = await getList.json();
      const { modifersList, lineItem } = getList.data;
      modifersTable(modifersList);
      productTable(lineItem);
      setModifersList(modifersList);
      setSelectedMod({});
      setSelectedProduct({});
      resetModiferState();
      getAllItems();
    } catch (error) {
      console.log(error);
    }
  }
  async function productTable(data) {
    try {
      let proData = data;
      if (proData.length) {
        proData = proData.map((ite) => {
          let temp = {};
          temp.id = ite.id;
          temp.name = ite.item_data.name;
          // temp.data = { productId: ite.id, productName: ite.item_data.name };
          setChoosedItemsId((prevState) => [...prevState, ite.id]);
          setInitItemsId((prevState) => [...prevState, ite.id]);
          return temp;
        });
      }
      setProductList(proData);
      setProductPending(false);
    } catch (error) {
      console.log(error);
    }
  }
  async function modifersTable(data) {
    try {
      const { modifier_list_data } = data;
      const { modifiers } = modifier_list_data;
      if (!modifiers.length) {
        setModList([]);
        setPending(false);
      }
      let modData = modifiers.map((ite) => {
        // console.log(ite);
        let { modifier_data } = ite;
        let temp = {};
        temp.id = ite.id;
        temp.type = ite.type;
        temp.name = modifier_data.name;
        temp.default = modifier_data.on_by_default;
        temp.price = modifier_data?.price_money?.amount || 0;
        temp.ordinal = modifier_data.ordinal;
        temp.totalModifer = modifiers.length;
        temp.data = ite;
        return temp;
      });
      setModList(modData);
      setPending(false);
    } catch (error) {
      console.log(error);
    }
  }
  async function getAllItems() {
    try {
      let getList = await fetch(
        `/api/v1/merchant/get/square/product/${props.restaurant.id}`
      );
      getList = await getList.json();
      setAllItems(getList?.data || []);
    } catch (error) {
      console.log(error);
    }
  }
  async function handleChooseItem(e) {
    const { id } = e.target;
    if (choosedItemsId.includes(id)) {
      let c = choosedItemsId.filter((dataId) => dataId !== id);
      setChoosedItemsId(c);
    } else {
      setChoosedItemsId((choosedItemsId) => [...choosedItemsId, id]);
    }
  }
  async function deleteModifiers(ID) {
    let res = await fetch(
      `/api/v1/merchant/delete/square/object/${ID}/${props.restaurant.id}`
    );
    if (res) {
      updateInitalState(current_modifier_list_id);
      closeModal();
      toast.error("Modifier deleted");
    }
  }
  async function updateModifiers(productId = null) {
    setModelState((prevState) => {
      return {
        ...prevState,
        addProductSpinner: true,
      };
    });
    let toAdd = [];
    let toRemove = [];
    if (productId) {
      toRemove = [...toRemove, productId];
    } else {
      choosedItemsId.forEach((ite) => {
        if (!initItemsId.includes(ite)) {
          toAdd.push(ite);
        }
      });
      initItemsId.forEach((ite) => {
        if (!choosedItemsId.includes(ite)) {
          toRemove.push(ite);
        }
      });
    }
    let res = await fetch(
      `/api/v1/merchant/update/square/product/modifierlist/${current_modifier_list_id}/${props.restaurant.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            toAdd: [...new Set(toAdd)],
            toRemove: [...new Set(toRemove)],
          },
        }),
      }
    );
    if (res) {
      if (productId) {
        toast.error("Product removed");
      } else {
        navigate("/merchant/modifiers");
        toast.success("Product added");
      }
      closeModal();
      updateInitalState(current_modifier_list_id);
    }
  }
  async function addModifier() {
    setModelState((prevState) => {
      return {
        ...prevState,
        addModifierSpinner: true,
      };
    });
    let payload = { ...modifer };
    if (
      !payload.modifier_data.price_money.amount ||
      payload.modifier_data.price_money.amount === 0
    ) {
      delete payload.modifier_data.price_money;
    }
    // console.log(payload);
    let res = await fetch(
      `/api/v1/merchant/add/square/modifier/${current_modifier_list_id}/${props.restaurant.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: payload }),
      }
    );
    if (res) {
      navigate("/merchant/modifiers");
      toast.success("updated");
    }
  }
  async function updateModifierListName() {
    if (modiferListName.length) {
      let res = await fetch(
        `/api/v1/merchant/update/square/product/modifierlist/${current_modifier_list_id}/${props.restaurant.id}?parameter=modifier_list_name`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: { name: modiferListName } }),
        }
      );
      if (res) {
        // updateInitalState(current_modifier_list_id);
        // closeModal();
        navigate("/merchant/modifiers");
        toast.success("updated");
      }
    } else {
      let id = "modlist-name";
      let message = "Please enter a name for your modifier set.";
      warnRedBox(id);
      warnNotify(message);
    }
  }
  async function resetModiferState(row, e) {
    setModifer({
      type: "MODIFIER",
      id: `#${new Date().getTime()}`,
      is_deleted: false,
      present_at_all_locations: true,
      modifier_data: {
        name: "",
        price_money: {
          amount: 0,
          currency: "USD",
        },
        ordinal: 0,
        on_by_default: false,
      },
    });
  }
  async function closeModal() {
    setModelState((prevState) => {
      return {
        ...prevState,
        addProduct: false,
        addModifier: false,
        editModifier: false,
        addProductSpinner: false,
        addModifierSpinner: false,
        editModifierSpinner: false,
        customizeModifier: false,
        customizeModifierSpinner: false,
      };
    });
    // setAllItems([]);
    getAllItems();
    setSelectedProduct({});
    setSelectedMod({});
    resetModiferState();
  }
  async function handleEditModifier(e) {
    const { value, id } = e.target;
    let { modifier_data } = selectedMod;
    console.log(id);
    switch (id) {
      case "edit-mod-name":
        modifier_data.name = value;
        break;
      case "edit-mod-price":
        let price = Math.round(Number(value) * 100);
        if (price && typeof price == "number") {
          modifier_data.price_money = { amount: price, currency: "USD" };
        } else if (modifier_data.hasOwnProperty("price_money")) {
          console.log("own");
          delete modifier_data.price_money;
        }
        break;
      case "edit-mod-default":
        modifier_data.on_by_default = !modifier_data.on_by_default;
        break;
      default:
        console.log("NO ID");
    }
    setSelectedMod({ ...selectedMod, modifier_data });
  }
  async function submiteditModifier() {
    setModelState((prevState) => {
      return {
        ...prevState,
        editModifierSpinner: true,
      };
    });
    let res = await fetch(
      `/api/v1/merchant/update/square/modifier/${current_modifier_list_id}/${props.restaurant.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ object: selectedMod }),
      }
    );
    if (res) {
      toast.success("Modifier updated");
      closeModal();
      updateInitalState(current_modifier_list_id);
    }
  }
  async function handleChangeCustomize(e) {
    let { id, value } = e.target;
    switch (id) {
      case "limit":
        setSelectedProduct((prevState) => ({
          ...prevState,
          limit: Number(value),
        }));
        break;
      case "required":
        setSelectedProduct((prevState) => ({
          ...prevState,
          required: !prevState.required,
        }));
        break;

      default:
        break;
    }
  }
  async function handleCustomizeSubmit() {
    // console.log(selectedProduct);
    const { productId } = selectedProduct;
    let res = await fetch(
      `/api/v1/merchant/update/square/modiferList/customize/${productId}/${props.restaurant.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          object: selectedProduct,
          modifier_group_id: current_modifier_list_id,
        }),
      }
    );
    if (res) {
      updateInitalState(current_modifier_list_id);
      closeModal();
    }
  }
  async function handleOpenCustomize(row) {
    const { id, name } = row;
    try {
      let getList = await fetch(
        `/api/v1/merchant/get/square/modiferList/customize/${id}/${current_modifier_list_id}/${props.restaurant.id}`
      );
      let { status } = getList;
      getList = await getList.json();
      let temp = {};
      if (status === 200) {
        const [object] = getList.data;
        if (object) {
          temp = object[0];
        } else {
          temp.productId = row.id;
          temp.productName = row.name;
          temp.required = false;
          temp.limit = 0;
          temp.groupId = current_modifier_list_id;
        }
      }
      setSelectedProduct(temp);
      setModelState((prevState) => ({
        ...prevState,
        customizeModifier: true,
      }));
    } catch (error) {
      console.log(error);
    }
  }
  const productColumns = [
    {
      name: "Product",
      cell: (row) => <div className="table-title">{row.name}</div>,
    },
    {
      name: "",
      cell: (row) => (
        <div className="table-title">
          <Button
            style={{ marginRight: "9px" }}
            onClick={() => {
              handleOpenCustomize(row);
            }}
          >
            <BsIcons.BsPencil />
          </Button>
          <Button onClick={() => updateModifiers(row.id)}>
            <MdIcons.MdDelete />
          </Button>
        </div>
      ),
    },
  ];
  const columns = [
    {
      name: "Modifier",
      cell: (row) => (
        <div
          className="table-title"
          onClick={() => navigate(`/merchant/modifiers/${row.id}`)}
        >
          {row.name}
        </div>
      ),
    },
    {
      name: "Price",
      cell: (row) => (
        <div className="table-title">
          ${parseFloat(row.price / 100).toFixed(2)}
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <div className="table-title">
          <Button
            style={{ marginRight: "9px" }}
            onClick={() => {
              setSelectedMod(row.data);
              setModelState((prevState) => ({
                ...prevState,
                editModifier: true,
              }));
            }}
          >
            <BsIcons.BsPencil />
          </Button>
          <Button
            onClick={() => deleteModifiers(row.id)}
            disabled={row.totalModifer === 1}
          >
            <MdIcons.MdDelete />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <center>
        <h3 style={{ fontWeight: 500 }}>Edit Modifier Set</h3>
      </center>
      <p
        style={{
          textTransform: "capitalize",
          fontSize: "1.5em",
          fontWeight: "bold",
        }}
      >
        <Form.Control
          onChange={(e) => {
            setModiferListName(e.target.value);
            warnRedBox(e.target.id, "remove");
          }}
          placeholder="Name"
          maxLength={255}
          type="text"
          id="modlist-name"
          name="name"
          value={modiferListName}
          autofocus
          style={{ width: "50%" }}
        />
        <div>
          <Button onClick={updateModifierListName}>Save</Button>
        </div>
      </p>
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        onClick={() => {
          // getAllItems();
          setModelState((prevState) => ({ ...prevState, addModifier: true }));
        }}
      >
        Add Modifier
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        columns={columns}
        data={modList}
        // pagination
        progressPending={pending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
      // onChangePage={this.handlePageChange}
      />
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        onClick={() => {
          setModelState((prevState) => ({ ...prevState, addProduct: true }));
        }}
      >
        Add Product
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        columns={productColumns}
        data={productList}
        // pagination
        progressPending={productPending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
      // onChangePage={this.handlePageChange}
      />
      {modelState.customizeModifier ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={modelState.customizeModifier}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "33%" }}>Product Name</td>
                        <td>
                          <Form.Control
                            type="text"
                            size="sm"
                            value={selectedProduct.productName}
                            disabled
                            style={{ boxShadow: "none" }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "33%" }}>Modifier Group</td>
                        <td>
                          <Form.Control
                            type="text"
                            size="sm"
                            value={modiferListName}
                            disabled
                            style={{ boxShadow: "none" }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "33%" }}>Selection Limit</td>
                        <td>
                          <Form.Control
                            onChange={handleChangeCustomize}
                            type="text"
                            size="sm"
                            id="limit"
                            maxLength={2}
                            value={selectedProduct.limit}
                            style={{ boxShadow: "none" }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <span>Required Group</span>
                    <Form.Control
                      style={{
                        width: "40%",
                        boxShadow: "none",
                        marginTop: "1em",
                      }}
                      onChange={handleChangeCustomize}
                      type="checkbox"
                      size="sm"
                      id="required"
                      defaultChecked={selectedProduct.required}
                    // value={selectedProduct.required}
                    />
                  </div>
                  <center>
                    <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={handleCustomizeSubmit}
                      style={{ display: "inline-block" }}
                    // disabled={
                    // modelState.customizeModifierSpinner ||
                    // ["", " "].includes(selectedMod.modifier_data.name)
                    // }
                    >
                      Save
                      {modelState.customizeModifierSpinner ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        <></>
                      )}
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      {modelState.editModifier ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={modelState.editModifier}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "33%" }}>
                          Name <span style={{ color: "red" }}>*</span>
                        </td>
                        <td>
                          {" "}
                          <Form.Control
                            style={{ boxShadow: "none" }}
                            onChange={handleEditModifier}
                            type="text"
                            size="sm"
                            id="edit-mod-name"
                            maxLength={255}
                            value={selectedMod.modifier_data.name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "33%" }}>Price</td>
                        <td style={{ display: "flex" }}>
                          <span className="addprice">$</span>
                          {selectedMod.modifier_data?.price_money?.amount ? (
                            <Form.Control
                              style={{ boxShadow: "none" }}
                              onChange={handleEditModifier}
                              size="sm"
                              id="edit-mod-price"
                              value={
                                selectedMod.modifier_data.price_money.amount /
                                100
                              }
                              type="text"
                              autoCapitalize="off"
                              maxLength={5}
                            />
                          ) : (
                            <Form.Control
                              onChange={handleEditModifier}
                              type="text"
                              autoCapitalize="off"
                              maxLength={5}
                              id="edit-mod-price"
                            // value={
                            // selectedMod.modifier_data.price_money.amount /
                            // 100
                            // }
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <span>Select Default</span>
                    <Form.Control
                      style={{
                        width: "40%",
                        boxShadow: "none",
                        marginTop: "1em",
                      }}
                      onChange={handleEditModifier}
                      type="checkbox"
                      size="sm"
                      id="edit-mod-default"
                      // value={selectedMod.modifier_data.on_by_default}
                      defaultChecked={selectedMod.modifier_data.on_by_default}
                    />
                  </div>
                  <center>
                    <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={submiteditModifier}
                      style={{ display: "inline-block" }}
                      disabled={
                        modelState.editModifierSpinner ||
                        ["", " "].includes(selectedMod.modifier_data.name)
                      }
                    >
                      Save
                      {modelState.editModifierSpinner ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        <></>
                      )}
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      {modelState.addModifier ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={modelState.addModifier}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "33%" }}>
                          Name <span style={{ color: "red" }}>*</span>
                        </td>
                        <td>
                          {" "}
                          <Form.Control
                            onChange={(e) => {
                              setModifer((prevState) => ({
                                ...prevState,
                                modifier_data: {
                                  ...prevState.modifier_data,
                                  name: e.target.value,
                                },
                              }));
                            }}
                            type="text"
                            maxLength={255}
                            size="sm"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "33%" }}>Price</td>
                        <td style={{ display: "flex" }}>
                          <span className="addprice">$</span>
                          <Form.Control
                            onChange={(e) => {
                              let { value } = e.target;
                              setModifer((prevState) => ({
                                ...prevState,
                                modifier_data: {
                                  ...prevState.modifier_data,
                                  price_money: {
                                    amount: Math.round(Number(value) * 100),
                                    currency: "USD",
                                  },
                                },
                              }));
                            }}
                            type="text"
                            autoCapitalize="off"
                            maxLength={5}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <span>Select Default</span>
                    <Form.Control
                      style={{
                        width: "40%",
                        boxShadow: "none",
                        marginTop: "1em",
                      }}
                      onChange={(e) => {
                        setModifer((prevState) => ({
                          ...prevState,
                          modifier_data: {
                            ...prevState.modifier_data,
                            on_by_default:
                              !prevState.modifier_data.on_by_default,
                          },
                        }));
                      }}
                      type="checkbox"
                      size="sm"
                    />
                  </div>
                  <center>
                    <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={addModifier}
                      style={{ display: "inline-block" }}
                      disabled={
                        modelState.addModifierSpinner ||
                        modifer.modifier_data.name === ""
                      }
                    >
                      Add
                      {modelState.addModifierSpinner ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        <></>
                      )}
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      {modelState.addProduct ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={modelState.addProduct}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="modifiers-itmes">
              <div id="bot" style={{ height: "400px", overflowY: "scroll" }}>
                <div>
                  {allItems.map((item) => {
                    return (
                      <div key={item.productId}>
                        <label>
                          <input
                            style={{ marginRight: "10px" }}
                            value={item.name}
                            id={item.productId}
                            defaultChecked={
                              choosedItemsId.includes(item.productId)
                                ? true
                                : false
                            }
                            onChange={handleChooseItem}
                            type="checkbox"
                          />
                          {item.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn mt-3 mb-3 btn-sm"
              onClick={() => updateModifiers()}
              style={{ display: "inline-block" }}
              disabled={modelState.addProductSpinner}
            >
              Add
              {modelState.addProductSpinner ? (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(SquareModifiers);