import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
function TableOrderConfig(props) {
  const [objectSettings, setObjectSettings] = React.useState({
    is_pay_online: 0,
    is_pay_restaurant: 0,
  });
  const [is_pay_online, setIs_pay_online] = React.useState(0);
  const [is_pay_restaurant, setIs_pay_restaurant] = React.useState(0);
  const [tableConfig, setTableConfig] = React.useState(false);
  React.useEffect(() => {
    (() => {
      getRestaurantTableSettings();
    })();
  }, []);
  async function getRestaurantTableSettings() {
    try {
      let response = await fetch(
        `/api/v1/merchant/get/restaurant/table-order/settings/${props.restaurant.id}`
      );
      response = await response.json();
      console.log("response", response.data);
      if (response.data) {
        setTableConfig(response.data.table);
        setIs_pay_online(response.data.is_pay_online);
        setIs_pay_restaurant(response.data.is_pay_restaurant);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function updateRestaurantTableSettings(type) {
    try {
      let response = await fetch(
        `/api/v1/merchant/update/restaurant/table-order/settings/${props.restaurant.id}?type=${type}`
      );
      toast.success("updated");
      getRestaurantTableSettings();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {tableConfig ? (
        <div style={{ marginTop: "22px" }}>
          <h4>Table Order</h4>
          <Card className="order-filter" style={{ boxShadow: "none" }}>
            <div className="coupon-container" style={{ paddingTop: "0" }}>
              <Form className="pb-4">
                <Row className="align-items-center mt-4">
                  <Col lg="5" md="5" sm="12">
                    <div className="coupon-label">Pay Online</div>
                  </Col>
                  <Col lg="7" md="7" sm="12">
                    <div>
                      <Form.Group className="mb-0">
                        <Form.Check
                          type="switch"
                          onChange={() =>
                            updateRestaurantTableSettings("online")
                          }
                          //checked={!!this.state.checked[user.SD_Emplid]}
                          id="custom-switch-table"
                          name="online"
                          label="OFF/ON"
                          //value = "1"
                          checked={is_pay_online}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                {props.restaurant.integrate !== "square" && (
                  <Row className="align-items-center mt-4">
                    <Col lg="5" md="5" sm="12">
                      <div className="coupon-label">Pay at Restaurant</div>
                    </Col>
                    <Col lg="7" md="7" sm="12">
                      <div>
                        <Form.Group className="mb-0">
                          <Form.Check
                            type="switch"
                            onChange={() =>
                              updateRestaurantTableSettings("cash")
                            }
                            //checked={!!this.state.checked[user.SD_Emplid]}
                            id="custom-switch1-table"
                            label="OFF/ON"
                            //value = "1"
                            name="cash"
                            checked={is_pay_restaurant}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            </div>
          </Card>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default TableOrderConfig;