const initialState = {
  table_name: "",
  table_id: "",
  table_status: true,
  table_availability: false,
  table_customer_id: "",
  table_order_id: "",
  table_orders_id: [],
  table_total_amount: 0,
  table_previous: false,
  previous_total: 0,
  previous_subTotal: 0,
  previous_tax: 0,
  previous_tip: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "TABLE_INIT":
      state = {
        ...state,
        ...action.data,
      };
      // console.log(action.data);
      break;
    default:
      break;
  }
  return state;
};
