import { toast } from "react-toastify";

export async function getConvOption(restaurantId) {
  const res = await fetch(`/api/integrations/conv-settings/${restaurantId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateuRestaurantAddress(restaurantId, integrate, data) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/account/update/address`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    // toast.success(json.message);
    return json;
  } else {
    toast.error(json.message);
  }
}

export async function updateuRestaurantHours(restaurantId, integrate, data) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/account/update/hours`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    // toast.success(json.message);
    return json;
  } else {
    toast.error(json.message);
  }
}
export async function getRestaurantAddress(restaurantId, integrate) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/account/address`,
    {
      method: "GET",
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function postBankAccountVerify(restaurantId, data) {
  const res = await fetch(
    `/api/integrations/bankaccount/verification/${restaurantId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    toast.success("Banking Verified");
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getDiscountOption(restaurantId) {
  console.log(restaurantId);
  const res = await fetch(`/api/${restaurantId}/discount_option`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getUserAll(restaurantId) {
  // console.log(restaurantId);
  const res = await fetch(`/api/${restaurantId}/alluser`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function qrOrderStatus(data, action) {
  let newdata = { ...data, status: action };
  console.log(newdata);
  const res = await fetch(
    `/api/v1/merchant/${newdata.restaurantId}/set/order/status/`,
    {
      method: "POST",
      body: JSON.stringify(newdata),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    console.log(json);
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getReceipt(resId, orderId) {
  const res = await fetch(`/api/r/${resId}/${orderId}`);

  const json = await res.json();
  if (res.status === 200) {
    // console.log(json);
    return json;
  } else {
    // toast.error(json.msg);
  }
}
export async function updateCloverToken(restaurantId, data) {
  try {
    const res = await fetch(
      `/api/integrations/update/clovertoken/${restaurantId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const json = await res.json();
    // console.log(json);
    if (res.status === 200) {
      toast.success("your clover keys are updated");
      return json;
    } else {
      toast.warn("Your clover keys are not valid.Please check your key");
      return json;
    }
  } catch (error) {
    console.log(error);
  }
}
export async function getMailOrderVerify(restaurantId, name) {
  const res = await fetch(
    `/api/integrations/get/mail/verify/${restaurantId}/${name}`
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getSquareToken(restaurantId) {
  const res = await fetch(`/api/integrations/get/squaretoken/${restaurantId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(data),
  });
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getCloverToken(restaurantId) {
  const res = await fetch(`/api/integrations/get/clovertoken/${restaurantId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(data),
  });
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getBankAccount(restaurantId) {
  const res = await fetch(`/api/integrations/get/bankaccount/${restaurantId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(data),
  });
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function postBankAccount(restaurantId, data) {
  const res = await fetch(
    `/api/integrations/update/bankaccount/${restaurantId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    toast.success("Banking Details Updated");
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getDoordashNetSales(restaurantId, integrate, start, end) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/doordash/netsales/${start}/${end}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getOrderFilter(restaurantId, integrate, start, end) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/orders/${start}/${end}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getNetSales(restaurantId, integrate, start, end) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/netsales/${start}/${end}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getPayoutAll(restaurantId, integrate) {
  // console.log(restaurantId);
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/stripe/allpayouts`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getProductItem(restaurantId, integrate) {
  //   console.log(restaurantId, integrate);
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/getItem`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  //   console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function addProductItem(restaurantId, integrate, name, price) {
  const data = {
    productName: name,
    productPrice: price,
  };
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/addItem`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getModifierGroup(restaurantId, integrate) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/get-modifier-groups`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function deleteModifierGroup(restaurantId, integrate, id) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/modifier-group`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function deleteModifier(restaurantId, integrate, id, groupId) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/modifier`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, groupId }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getModifiers(restaurantId, integrate, modifierGroupId) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/get-Modifiers/${modifierGroupId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getModifiersItems(
  restaurantId,
  integrate,
  modifierGroupId
) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/get-modifiergroup-items/${modifierGroupId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function addModifiersItems(
  restaurantId,
  integrate,
  modifierGroupId,
  items
) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/modifier-groups/${modifierGroupId}/add_items`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function addModifiers(
  restaurantId,
  integrate,
  modifierGroupId,
  name,
  price
) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/modifier-groups/${modifierGroupId}/modifiers`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        available: "true",
        price: price,
        name: name,
      }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateModifiers(
  restaurantId,
  integrate,
  modifierGroupId,
  modifierId,
  name,
  price
) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/modifier-groups/${modifierGroupId}/update/${modifierId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        available: "true",
        price: price,
        name: name,
      }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function addModifierGroup(
  restaurantId,
  integrate,
  name,
  multiple,
  choose_limit
) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/modifier-groups`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, multiple, choose_limit }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateModifierGroups(
  restaurantId,
  integrate,
  id,
  name,
  multiple,
  choose_limit
) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/modifier-groups/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, multiple, choose_limit }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getCategories(restaurantId, integrate) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/get-category`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function addCategory(restaurantId, integrate, name) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/add-category`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateCategory(restaurantId, integrate, id, name) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/category/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function deleteCategory(restaurantId, integrate, id) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/category`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getItemsCategory(restaurantId, integrate, categoryId) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/get-category-items/${categoryId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function addCategoryItems(
  restaurantId,
  integrate,
  categoryId,
  items
) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/category/${categoryId}/add_items`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateCatOrder(
  restaurantId,
  integrate,
  categoryId,
  order
) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/category/${categoryId}/update_order`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ order }),
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getCustomerAll(restaurantId) {
  console.log(restaurantId);
  const res = await fetch(`/api/${restaurantId}/allcustomers`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function getTableOrders(restaurantId, integrate, start, end) {
  console.log(start, end);
  const res = await fetch(
    `/api/v1/merchant/get/table/orders/${restaurantId}/${integrate}/table-orders/${start}/${end}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function updateStock(restaurantId, integrate, data) {
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/items/updatestock`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function deleteProductItem(restaurantId, integrate, itemId) {
  console.log(restaurantId, integrate, itemId);
  const res = await fetch(
    `/api/v1/merchant/${restaurantId}/${integrate}/product/removeItem/${itemId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
export async function setOrderStatus(data, action) {
  let newdata = { ...data, status: action };
  // console.log(newdata);
  // .replace('(','').replace(')','').replace('-','').replace(/\s+/g, '')
  const res = await fetch(
    `/api/v1/merchant/${newdata.resId}/${newdata.integrate}/order/status/`,
    {
      method: "POST",
      body: JSON.stringify(newdata),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  if (res.status === 200) {
    console.log(json);
    return json;
  } else {
    toast.error(json.msg);
  }
}
