import { async } from "q";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Container } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import Logopng from "../../assets/try-simmer.png";
import FileDownload from "js-file-download";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
const ShowQrCode = (props) => {
  const [image, setImage] = useState("");
  const [toDownload, setToDownload] = useState("");
  const [toBlob, setToBlob] = useState("");
  useEffect(() => {
    (async function () {
      try {
        const { id: restaurantId } = props.restaurant;
        const res = await fetch(`/qrcode/generate/${restaurantId}`);
        const json = await res.json();
        if (json && json.data) {
          const blobImage = json.data;
          // const blobImage = `data:image/png;base64,${json.data}`;
          const data = Uint8Array.from(json.bufferData.data);
          console.log(data);
          const content = new Blob([data.buffer], { type: "application/pdf" });
          setToBlob(content);
          setToDownload(json.bufferData);
          setImage(blobImage);
        }
        //   return 0
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <Container>
      {image ? (
        <>
          <div
            style={{
              textalign: "center",
              width: "300px",
              margin: "auto",
              backgroundColor: "#fff",
            }}
            id="html-to-image"
          >
            <div
              class="logo-section"
              style={{ textAlign: "center", paddingBottom: "20px" }}
            >
              <img src={Logopng} class="try-logo" style={{ width: "100%" }} />
            </div>
            <div>
              <img
                src={image}
                style={{
                  width: "100%",
                  maxWidth: "750px",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </div>
            <div
              class="footer"
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                backgroundColor: "#e4e4e4",
                marginTop: "10px",
                padding: "10px 0",
              }}
            >
              <p
                style={{
                  margin: "4px 0 0 0",
                  fontWeight: "600",
                  paddingRight: "10px",
                  color: "#000",
                }}
              >
                Powered by
              </p>
              <img src={Logopng} class="foot-logo" style={{ width: "100px" }} />
            </div>
          </div>

          <div className="text-center mt-3">
            <a
              // download="qrcode.pdf"
              // href={toDownload}
              className="btn btn-success edit"
              onClick={() => {
                FileDownload(toBlob, "qrcode.pdf");
              }}
            >
              Download
              <AiIcons.AiOutlineDownload style={{ verticalAlign: "-2px" }} />
            </a>
          </div>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <ScaleLoader size={12} color="#ec7211" />
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
  };
};

export default connect(mapStateToProps)(ShowQrCode);
