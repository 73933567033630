import React, { Component, useState, useEffect } from "react";
// import storage from "../firebase/firebase";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import DataTable from "react-data-table-component";
import { async } from "q";
import Modal from "react-bootstrap/Modal";
import * as MdIcons from "react-icons/md";
import "./pages.css";
import {
  addProductItem,
  getProductItem,
  setItem,
  updateStock,
  deleteProductItem,
} from "../Functions/helper";
import { Spinner } from "react-bootstrap";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import ScaleLoader from "react-spinners/ScaleLoader";
const defaultImage =
  "iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw";
//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
// Client side unique ID - This could and probably should move to server with UUID
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

function AutoStock(props) {
  // console.log(props.data)
  const [enabled, setEnabled] = useState(true);
  // const [enabled, setEnabled] = useState(+props.data.autostock);
  const [spinner, setSpinner] = useState(false);
  const [edit, setEdit] = useState(false);
  const [productId, setProductId] = useState(props.data.productId);
  const [productTitle, setProductTitle] = useState(props.data.title);
  const [IntStockCount, setIntStockCount] = useState(
    props.data.initialStockCount
  );
  const [stockCount, setStockCount] = useState(props.data.stockCount);
  function handleChangeStock(e) {
    setIntStockCount(+e.target.value);
  }
  async function handleSubmit(action) {
    setSpinner(true);
    const { id: restaurantId, integrate } = props.restaurant;
    const data = { stockCount: IntStockCount, productId, productTitle };
    const response = await fetch(
      `/api/v1/merchant/${restaurantId}/${integrate}/product/set/autostock/${action}`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    setSpinner(false);
    window.location.reload(false);
  }
  return (
    <>
      {" "}
      <label className="toggle-switch stock">
        {/* <input
          type="checkbox"
          defaultChecked={enabled}
          onChange={() => {
            console.log(enabled)
            enabled && handleSubmit("remove");
            setEnabled(!enabled);
          }}
        /> */}
        {/* <span className="switch"></span> */}
        <span className="stock-details" style={{ padding: 0 }}>
          {enabled ? (
            <>
              <Form.Control
                style={{ width: "70px", height: "34px" }}
                onChange={handleChangeStock}
                disabled={!edit}
                value={IntStockCount || 0}
              />
              {edit ? (
                <button onClick={() => handleSubmit("add")}>
                  Save{" "}
                  {spinner ? (
                    <Spinner
                      as="span"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                    />
                  ) : (
                    <></>
                  )}
                </button>
              ) : (
                <button onClick={() => setEdit(true)}>Edit</button>
              )}
            </>
          ) : (
            <> </>
          )}
        </span>
      </label>
      {props.data.initialStockCount ? (
        <p
          style={{
            fontSize: "15px",
            padding: "5px 4px 0",
            display: "block",
            fontWeight: "600",
            margin: "0",
          }}
        >{`${stockCount <= 0 ? 0 : stockCount} out of ${props.data.initialStockCount
          }`}</p>
      ) : (
        <></>
      )}
    </>
  );
}

function Inventory(props) {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [intProducts, setIntProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [isOpenDel, setisOpenDel] = useState(false);
  const [autoFill, setAutoFill] = useState(false);
  const [data, setData] = useState({});
  const [descData, setDescData] = useState("");
  const [onHide, setOnHide] = useState(false);
  const [priceData, setPriceData] = useState("");
  const [productNameData, setProductNameData] = useState("");
  const [name, setName] = useState("");
  const [price, setprice] = useState(0);
  const [state, set] = useState(0);
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const [Url, setUrl] = useState("");
  const [size, setSize] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [pending, setPending] = useState(true);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [int, setInit] = useState();
  const [toDel, setToDel] = useState("");
  const [removeImageId, setRemoveImageId] = useState("");

  // progress
  const [percent, setPercent] = useState(0);
  // call your hook here
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    async function helper() {
      try {
        setLoader(true);
        let dataItem = [];
        let dataItemInt = [];
        const res = await getProductItem(
          props.restaurant.id,
          props.restaurant.integrate
        );
        if (res) {
          let unOrdedData = await setItem(res, props.restaurant.id, props.restaurant.language);
          const data = Object.keys(unOrdedData)
            .sort()
            .reduce((obj, key) => {
              obj[key] = unOrdedData[key];
              return obj;
            }, {});
          if (Object.keys(data).length) {
            Object.values(data).forEach((item) => {
              let data = {};
              data["id"] = item["productId"];
              data["title"] = item["title"].replace(/\(.*\)/g, " ").trim();
              data["titleId"] = item["title"];
              data["productId"] = item["productId"];
              data["img"] = item["img"];
              data["desc"] = item["desc"];
              data["available"] = item["stock"];
              data["autostock"] = item["autostock"];
              data["initial_stock"] = item["initial_stock"];
              data["category"] = item["category"];
              data["hideOnline"] = item["hideOnline"];
              // setState({
              // products: [...state.products, data],
              // });
              // if (item.productId === "B5EPETZC4EMC6QPOVFYWU3MZ")
              //   console.log(data);
              dataItem.push(data);
              dataItemInt.push(item);
            });
            setProducts(dataItem);
            setIntProducts(dataItemInt);
            // console.log(dataItem);
            setInit(data);
            setLoader(false);
            setPending(false);
            // console.log(state.products);
          } else {
            setProducts([]);
          }
        } else {
          setProducts([]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    helper();
  }, [state]);
  const columns = [
    {
      name: "Product Image",
      cell: (row) => (
        <img src={row.img} title={row.title} alt={row.title} width="80" />
      ),
    },

    {
      name: "Name",
      cell: (row) => <div>{row.title}</div>,
    },
    {
      name: "Category",
      selector: (row) => (
        <>
          {/* {console.log(row)} */}
          <div>{row.category}</div>
        </>
      ),
    },
    {
      name: "Available",
      cell: (row) => (
        <>
          {/* <label className="toggle-switch">
            <input
              type="checkbox"
              onChange={async (e) => {
                let data = {
                  available: !row.available,
                  productId: row.productId,
                };
                let set = await updateStock(
                  props.restaurant.id,
                  props.restaurant.integrate,
                  data
                );
                updateItemState();
              }}
              id={`stock-${row.id}`}
              checked={row.available}
            />
            <span className="switch" />
          </label> */}
          <label className="toggle-switch">
            <input
              type="checkbox"
              onChange={async (e) => {
                let data = {
                  available: !row.available,
                  productId: e.target.id,
                };
                let set = await updateStock(
                  props.restaurant.id,
                  props.restaurant.integrate,
                  data
                );
                // set && setTimeout(updateItemState, 1000);
                updateItemState();
              }}
              id={row.productId}
              defaultChecked={row.available}
            // value={true}
            // checked
            />
            <span className="switch" />
          </label>
          {/* <label className="switch"> */}
        </>
      ),
    },

    {
      name: "View",
      cell: (row) => (
        <div>
          <a
            onClick={openModal}
            id={row.productId}
            className="btn btn-success edit"
          >
            Edit
          </a>
          <Button
            className="btn ml-3 delete"
            onClick={() => {
              handConformDelete(row.productId);
            }}
            style={{ display: "inline-block", backgroundColor: "#d3595f" }}
          // disabled={!disabled}
          >
            Delete
            {/* {spinner ? (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            ) : (
              <></>
            )} */}
          </Button>
        </div>
      ),
    },
  ];
  const autoFillModel = () => {
    window.location.reload(true);
  };
  const autoFillhandle = async () => {
    fetch(
      `/api/v1/merchant/${props.restaurant.id}/${props.restaurant.integrate}/sync/product/image`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        setAutoFill(true);
        console.log(res);
        window.location.reload(true);
        res.text();
      })
      .then(console.log("uploaded"));
  };
  const handleUploadGcp = async (productId, productNameItem) => {
    if (image == null) return;
    let productName = encodeURIComponent(productNameItem);
    removeImageId && handleRemoveImage(removeImageId);
    handleOnhide(productId);
    if (image) {
      let postid = uuidv4();
      // const fileName = `phonedash/clover/product/images/${props.restaurant.id}/${productId}`;
      // Create new file so we can rename the file
      console.log(image.size);
      // let blob = image.slice(0, image.size, "image/jpeg");

      let blob = image.slice(0, image.size, image.type);
      console.log(blob);
      const newFile = new File(
        [blob],
        `${postid}.${image.type.split("/")[1]}`,
        {
          type: image.type,
        }
      );
      // const newFile = new File([blob], `${postid}.jpeg`, {
      // type: "image/jpeg",
      // });

      let formData = new FormData();
      formData.append("imgfile", newFile);
      formData.append("description", descData);

      if (priceData) formData.append("price", priceData);
      if (productNameData) formData.append("newProductName", productNameData);
      fetch(
        `/upload/${props.restaurant.id}/${props.restaurant.integrate}/${productId}/${productName}`,
        {
          method: "POST",
          body: formData,
        }
      )
        .then((res) => {
          window.location.reload(false);
          setImage("");
          setSize("");
          setDescData("");
          setOnHide(false);
          updateItemState();
          setIsOpen(false);
        })
        .then(console.log("uploaded"));
    } else {
      console.log("no image");
      let formData = new FormData();
      // formData.append("imgfile", newFile);
      formData.append("description", descData);
      if (priceData) formData.append("price", priceData);
      if (productNameData) formData.append("newProductName", productNameData);
      fetch(
        `/upload/${props.restaurant.id}/${props.restaurant.integrate}/${productId}/${productName}`,
        {
          method: "POST",
          body: formData,
        }
      )
        .then((res) => {
          window.location.reload(false);
          setImage("");
          setDescData("");
          setOnHide(false);
          updateItemState();
          setIsOpen(false);
        })
        .then(console.log("uploaded"));
    }
  };

  const openModal = async (e) => {
    const { id } = e.target;
    const data = intProducts.find((ite) => ite.productId === id);
    console.log(data);
    setIsOpen(data);
    setData(data);
    setPriceData(Number(data.price.toFixed(2)));
    setProductNameData(data.title);
    setOnHide(data.hideOnline);
    setDescData(data.desc);
  };
  const closeModal = () => {
    // console.log(descData.length || image)
    setDescData("");
    setOnHide(false);
    setImage("");
    setSize("");
    setRemoveImageId("");
    setIsOpen(false);
    setAutoFill(false);
    setisOpenAdd(false);
    setisOpenDel(false);
  };
  const updateItemState = async () => {
    try {
      setPending(true);
      setProducts([]);
      let dataItem = [];
      const res = await getProductItem(
        props.restaurant.id,
        props.restaurant.integrate
      );
      if (res) {
        const data = await setItem(res, props.restaurant.id, props.restaurant.language);
        if (Object.keys(data).length) {
          Object.values(data).forEach((item) => {
            let data = {};
            // console.log(item);
            data["id"] = item["productId"];
            data["title"] = item["title"].replace(/\(.*\)/g, " ").trim();
            data["titleId"] = item["title"];
            data["productId"] = item["productId"];
            data["img"] = item["img"];
            data["desc"] = item["desc"];
            data["available"] = item["stock"];
            data["category"] = item["category"];
            data["hideOnline"] = item["hideOnline"];
            // setState({
            // products: [...state.products, data],
            // });
            dataItem.push(data);
          });
          // console.log(dataItem);
          setProducts(dataItem);
          setInit(data);
          setisOpenAdd(false);
          setPending(false);
          // console.log(state.products);
        } else {
          setProducts([]);
          setisOpenAdd(false);
        }
      } else {
        setProducts([]);
        setisOpenAdd(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const searchItem = async (search) => {
    try {
      let dataItem = [];
      // console.log(int);
      const data = int;
      if (Object.keys(data).length) {
        Object.values(data).forEach((item) => {
          if (
            search &&
            item["title"]
              .replace(/\(.*\)/g, " ")
              .trim()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            // console.log(item["title"]);
            let data = {};
            // console.log(item);
            data["id"] = item["productId"];
            data["title"] = item["title"].replace(/\(.*\)/g, " ").trim();
            data["titleId"] = item["title"];
            data["productId"] = item["productId"];
            data["img"] = item["img"];
            data["desc"] = item["desc"];
            data["available"] = item["stock"];
            data["category"] = item["category"];
            data["hideOnline"] = item["hideOnline"];
            // setState({
            // products: [...state.products, data],
            // });
            dataItem.push(data);
          } else {
            if (search.trim() == "") {
              let data = {};
              // console.log(item);
              data["id"] = item["productId"];
              data["title"] = item["title"].replace(/\(.*\)/g, " ").trim();
              data["titleId"] = item["title"];
              data["productId"] = item["productId"];
              data["img"] = item["img"];
              data["desc"] = item["desc"];
              data["available"] = item["stock"];
              data["category"] = item["category"];
              data["hideOnline"] = item["hideOnline"];
              // setState({
              // products: [...state.products, data],
              // });
              dataItem.push(data);
            } else {
              setProducts([]);
              setisOpenAdd(false);
            }
          }
        });
        setProducts(dataItem);
        setInit(data);
        setisOpenAdd(false);
        // console.log(state.products);
      } else {
        setProducts([]);
        setisOpenAdd(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addItem = async () => {
    setDisabled(false);
    setSpinner(true);
    const response = await addProductItem(
      props.restaurant.id,
      props.restaurant.integrate,
      name,
      price
    );
    if (response) {
      window.location.reload(false);
      updateItemState();
      setDisabled(true);
      setSpinner(false);
    }
  };
  const handConformDelete = async (itemId) => {
    setisOpenDel(true);
    setToDel(itemId);
  };
  const handleDeleteItem = async () => {
    setSpinner(true);
    setDisabled(false);
    let itemId = toDel;

    const res = await deleteProductItem(
      props.restaurant.id,
      props.restaurant.integrate,
      itemId
    );
    if (res) {
      setTimeout(() => {
        updateItemState();
        setToDel("");
        setisOpenDel(false);
        setIsOpen(false);
        setDisabled(true);
        setSpinner(false);
        console.clear();
      }, 1000);
    }
  };
  const handleRemoveImage = async (ID) => {
    try {
      let getList = await fetch(
        `/api/v1/merchant/product/remove/image/${props.restaurant.id}/${ID}`
      );
      // getList = await getList.json();
      // window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleOnhide = async (ID) => {
    try {
      let res = await fetch(
        `/api/v1/merchant/product/online/${props.restaurant.id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: { productId: ID, hide_online: onHide },
          }),
        }
      );
      // getList = await getList.json();
      // window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {loader ? (
        <div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              // minHeight: "100vh",
              transition: "0.5s ease-in-out 0s",
              height: "100%",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ScaleLoader size={12} color="#ec7211" />
          </div>
        </div>
      ) : (
        <div className="table-group product-table" style={{ margin: "6em" }}>
          <div className="table-header">
            <div className="search-orders">
              <input
                placeholder="Search items"
                onChange={(e) => {
                  searchItem(e.target.value);
                }}
                type="text"
                size="sm"
                id="searchvalue"
              />
              {search ? (
                <Button
                  className=""
                  onClick={() => {
                    updateItemState();
                    setSearch("");
                    const firstNameInput =
                      document.getElementById("searchvalue");
                    firstNameInput.value = "";
                  }}
                >
                  <AiOutlineClose />
                </Button>
              ) : (
                <>
                  <div className="icon-search">
                    {" "}
                    <AiOutlineSearch size={22} />
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            class="preview"
            style={{
              padding: "38px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              {props.restaurant.integrate !== "square" ? (
                <>
                  {" "}
                  <Button
                    className="mt-3 mb-2 mx-2"
                    style={{ borderRadius: "3px" }}
                    onClick={() => setisOpenAdd(true)}
                  >
                    Add Item
                  </Button>
                  <Button
                    className="mt-3 mb-2 mx-2"
                    style={{ borderRadius: "3px" }}
                    onClick={autoFillhandle}
                  >
                    Auto Sync
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="mt-3 mb-2 mx-2"
                    style={{ borderRadius: "3px" }}
                    onClick={() => navigate(`/merchant/inventory/new`)}
                  >
                    Add Item
                  </Button>
                  <Button
                    className="mt-3 mb-2"
                    style={{ borderRadius: "3px" }}
                    onClick={autoFillhandle}
                  >
                    Auto Sync
                  </Button>
                </>
              )}
            </span>
            <a
              target="_blank"
              className="mb-2 mt-3"
              style={{
                background: "#ec7211",
                color: "#fff",
                padding: "6px 15px",
                fontWeight: "600",
                borderRadius: "5px",
              }}
              href={`https://${window.location.hostname}/${props.restaurant.id}`}
            >
              Menu Link
            </a>
          </div>
          <Container style={{ minHeight: "auto", padding: "0" }}>
            <DataTable
              paginationPerPage={10}
              highlightOnHover={true}
              paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
              columns={columns}
              data={products}
              pagination
              progressPending={pending}
              progressComponent={<ScaleLoader size={12} color="#ec7211" />}
            // onChangePage={this.handlePageChange}
            />
          </Container>
        </div>
      )}
      {isOpen ? (
        <Modal
          dialogClassName="receipt-model1 modal-90w"
          show={isOpen}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <center id="top">
                <div
                  className="logo"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "275px",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={
                      removeImageId
                        ? { width: "40%", opacity: "0.65" }
                        : { width: "40%" }
                    }
                    src={data.img}
                  />
                  {removeImageId && (
                    <span
                      style={{
                        fontSize: "12px",
                        color: "rgb(211, 89, 95)",
                        fontWeight: "600",
                      }}
                    >
                      Image will be removed
                    </span>
                  )}

                  {/* opacity: 0.65; */}
                  {!data.img.includes(defaultImage) && (
                    <Button
                      style={
                        removeImageId
                          ? {
                            backgroundColor: "rgb(211, 89, 95)",
                            marginTop: "12px",
                            fontSize: "16px",
                            opacity: "0.65",
                          }
                          : {
                            backgroundColor: "rgb(211, 89, 95)",
                            marginTop: "12px",
                            fontSize: "16px",
                          }
                      }
                      onClick={() =>
                        removeImageId
                          ? setRemoveImageId("")
                          : setRemoveImageId(data.productId)
                      }
                    // onClick={() => handleRemoveImage(data.productId)}

                    // disabled={removeImageId === data.productId}
                    >
                      Remove Image <MdIcons.MdDelete />
                    </Button>
                  )}
                </div>
              </center>
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                      <tr>
                        <td>Product</td>
                        <td>
                          <Form.Control
                            // as="textarea"
                            // aria-label="With textarea"
                            // style={{ width: "auto" }}
                            onChange={(e) => {
                              setProductNameData(e.target.value);
                            }}
                            value={productNameData || ""}
                          />
                        </td>
                        {/* {["custom", "clover"].includes(
                          props.restaurant.integrate
                        ) ? (
                          <td>
                            <Form.Control
                              // as="textarea"
                              // aria-label="With textarea"
                              // style={{ width: "auto" }}
                              onChange={(e) => {
                                setProductNameData(e.target.value);
                              }}
                              value={productNameData || ""}
                            />
                          </td>
                        ) : (
                          <td>{data.title}</td>
                        )} */}
                      </tr>
                      <tr>
                        <td>Product Id</td>
                        <td>{data.productId}</td>
                      </tr>
                      {/* {props.restaurant.integrate === "square" ? (
                        <tr>
                          <td>Auto Stock</td>
                          <td style={{ padding: "1.2em 0 1em" }}>
                            <AutoStock
                              {...{ data, restaurant: props.restaurant }}
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )} */}
                      {!["custom", "clover", "square"].includes(
                        props.restaurant.integrate
                      ) ? (
                        <>
                          <tr>
                            <td>Auto Stock</td>
                            <td style={{ padding: "1.2em 0 1em" }}>
                              <AutoStock
                                {...{ data, restaurant: props.restaurant }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Price ($)</td>
                            <td>
                              <Form.Control
                                style={{ width: "70px" }}
                                onChange={(e) => {
                                  setPriceData(e.target.value);
                                }}
                                type="text"
                                maxLength={5}
                                value={priceData || ""}
                              />
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <td>Price ($)</td>
                            <td>
                              <Form.Control
                                // as="textarea"
                                // aria-label="With textarea"
                                style={{ width: "70px" }}
                                onChange={(e) => {
                                  setPriceData(Number(e.target.value));
                                }}
                                value={priceData || 0}
                                type="text"
                                maxLength={5}
                              />
                            </td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <td>Description</td>
                        <td>
                          <Form.Control
                            as="textarea"
                            aria-label="With textarea"
                            onChange={(e) => {
                              setDescData(e.target.value);
                            }}
                            // placeholder={
                            //   data.desc.length > 2
                            //     ? data.desc
                            //     : `No description`
                            // }
                            value={descData}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Image</td>
                        <td>
                          <Form.Control
                            onChange={(e) => {
                              setImage(e.target.files[0]);

                              setSize(e.target.files[0].size);
                              // setFile(event.target.files[0]);
                            }}
                            type="file"
                            size="sm"
                            accept="image/*"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Hide Online Order</td>
                        <td>
                          <Form.Control
                            style={{
                              width: "10%",
                              boxShadow: "none",
                              fontSize: "4px",
                            }}
                            onChange={(e) => {
                              setOnHide(!onHide);
                              // setModifer((prevState) => ({
                              //   ...prevState,
                              //   modifier_data: {
                              //     ...prevState.modifier_data,
                              //     on_by_default:
                              //       !prevState.modifier_data.on_by_default,
                              //   },
                              // }));
                            }}
                            type="checkbox"
                            size="sm"
                            defaultChecked={onHide}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {image || descData || priceData || true ? (
                    <center>
                      <Button
                        className="btn mt-3 mb-3 btn-sm"
                        onClick={() => {
                          // handleUpload(data.productId)

                          const name = data.title
                            .replace(/\(.*\)/g, " ")
                            .toLowerCase();
                          handleUploadGcp(data.productId, name);
                        }}
                        style={{ display: "inline-block" }}
                      >
                        save
                      </Button>
                      {size ? <span>{formatBytes(size)}</span> : <></>}
                    </center>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
 <Button variant="secondary" onClick={closeModal}>
 Close
 </Button>
 </Modal.Footer> */}
        </Modal>
      ) : (
        <></>
      )}
      {isOpenAdd ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={isOpenAdd}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "33%" }}>Product Name</td>
                        <td>
                          {" "}
                          <Form.Control
                            onChange={(e) => {
                              // console.log(e.target.value);
                              setName(e.target.value);
                            }}
                            type="text"
                            size="sm"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "33%" }}>Product Price</td>
                        <td style={{ display: "flex" }}>
                          <span className="addprice">$</span>
                          <Form.Control
                            onChange={(e) => {
                              setprice(e.target.value);
                            }}
                            type="number"
                            size="sm"
                          />
                        </td>
                      </tr>
                      {/* <tr>
 <td>Product Id</td>
 <td>{data.productId}</td>
 </tr> */}
                    </tbody>
                  </table>
                  <center>
                    <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={addItem}
                      style={{ display: "inline-block" }}
                      disabled={!disabled}
                    >
                      Add
                      {spinner ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        <></>
                      )}
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      {isOpenDel ? (
        <Modal
          dialogClassName="del-model del-item"
          show={isOpenDel}
          onHide={closeModal}
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <h5>You are about to delete a product</h5>
            <p>
              This will delete your product from inventory <br />
              Are you sure?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ background: "transparent", color: "#000", border: "0" }}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button variant="secondary" onClick={handleDeleteItem}>
              Delete
              {spinner ? (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(Inventory);