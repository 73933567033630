import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  getDoordashNetSales,
  getNetSales,
  getTableOrders,
} from "../Functions/helper";
import moment from "moment-timezone";
import BeatLoader from "react-spinners/BeatLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import { async } from "q";
class Dashboard extends Component {
  state = {
    last7Data: {},
    totalData: {},
    todayData: {},
    loader: true,
    startDate: "",
    endDate: "",
    valid: true,
    warnvalid: false,
    isFilter: false,
    last7doordashData: {},
    totaldoordashData: {},
    todaydoordashData: {},
    loaderDD: true,
    startdoordashDate: "",
    enddoordashDate: "",
    validDD: true,
    warnvalidDD: false,
    isFilterDD: false,
    last7TableData: {},
    totalTableData: {},
    todayTableData: {},
    loaderTable: true,
    startTableDate: "",
    endTableDate: "",
    validTable: true,
    warnvalidTable: false,
    isFilterTable: false,
  };
  async componentDidMount() {
    const resId = this.props.restaurant_id,
      intergate = this.props.restaurant.integrate;
    const todayDate = moment()
      .tz(this.props.timeZone)
      .format("YYYY-MM-DD")
      .toLocaleLowerCase();
    const last7Date = moment()
      .tz(this.props.timeZone)
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    const yesterday = moment()
      .tz(this.props.timeZone)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    const last7Data = await getNetSales(resId, intergate, last7Date, yesterday);
    const totalData = await getNetSales(resId, intergate, null, null);
    const todayData = await getNetSales(resId, intergate, null, todayDate);
    this.setState({ last7Data, totalData, todayData, loader: false });

    /**doordash */
    const todaydoordashDate = moment()
      .tz(this.props.timeZone)
      .format("YYYY-MM-DD")
      .toLocaleLowerCase();
    const last7doordashDate = moment()
      .tz(this.props.timeZone)
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    const yesterdayDoordash = moment()
      .tz(this.props.timeZone)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    const last7doordashData = await getDoordashNetSales(
      resId,
      intergate,
      last7doordashDate,
      yesterdayDoordash
    );
    const totaldoordashData = await getDoordashNetSales(
      resId,
      intergate,
      null,
      null
    );
    const todaydoordashData = await getDoordashNetSales(
      resId,
      intergate,
      null,
      todaydoordashDate
    );
    this.setState({
      last7doordashData,
      totaldoordashData,
      todaydoordashData,
      loaderDD: false,
    });

    /**table orders */
    // const todayTableDate = moment()
    //   .tz(this.props.timeZone)
    //   .format("YYYY-MM-DD")
    //   .toLocaleLowerCase();
    // const last7TableDate = moment()
    //   .tz(this.props.timeZone)
    //   .subtract(7, "days")
    //   .format("YYYY-MM-DD");
    // const yesterdayTable = moment()
    //   .tz(this.props.timeZone)
    //   .subtract(1, "days")
    //   .format("YYYY-MM-DD");
    // const last7TableData = await getTableOrders(
    //   resId,
    //   intergate,
    //   last7TableDate,
    //   yesterdayTable
    // );
    const totalTableData = await getTableOrders(resId, intergate, null, null);
    // const todayTableData = await getTableOrders(
    //   resId,
    //   intergate,
    //   null,
    //   todayTableDate
    // );
    this.setState({
      // last7TableData,
      totalTableData,
      // todayTableData,
      loaderTable: false,
    });
  }
  totalData = async () => {
    const resId = this.props.restaurant_id,
      intergate = this.props.restaurant.integrate;
    const totalData = await getNetSales(resId, intergate, null, null);
    this.setState({
      totalData,
      loader: false,
      isFilter: false,
    });
  };
  totaldoordashData = async () => {
    const resId = this.props.restaurant_id,
      intergate = this.props.restaurant.integrate;
    const totaldoordashData = await getDoordashNetSales(
      resId,
      intergate,
      null,
      null
    );
    this.setState({
      totaldoordashData,
      loaderDD: false,
      isFilterDD: false,
    });
  };
  checkValid = () => {
    const start = this.state.startDate;
    const end = this.state.endDate;
    console.log(start > end);
    if (start > end) {
      this.setState({ warnvalid: true, valid: true });
    } else {
      this.setState({ warnvalid: false, valid: false });
    }
  };
  checkValidDD = () => {
    const start = this.state.startdoordashDate;
    const end = this.state.enddoordashDate;
    console.log(start > end);
    if (start > end) {
      this.setState({ warnvalidDD: true, validDD: true });
    } else {
      this.setState({ warnvalidDD: false, validDD: false });
    }
  };
  dayfilter = async () => {
    const resId = this.props.restaurant_id,
      intergate = this.props.restaurant.integrate;
    const start = this.state.startDate;
    const end = this.state.endDate;
    const totalData = await getNetSales(resId, intergate, start, end);
    this.setState({ totalData, loader: false, isFilter: true });
  };
  dayfilterDD = async () => {
    const resId = this.props.restaurant_id,
      intergate = this.props.restaurant.integrate;
    const start = this.state.startdoordashDate;
    const end = this.state.enddoordashDate;
    const totaldoordashData = await getDoordashNetSales(
      resId,
      intergate,
      start,
      end
    );
    this.setState({ totaldoordashData, loaderDD: false, isFilterDD: true });
  };
  /***Table orders */
  totalTableData = async () => {
    const resId = this.props.restaurant_id,
      intergate = this.props.restaurant.integrate;
    const totalTableData = await getTableOrders(resId, intergate, null, null);
    this.setState({
      totalTableData,
      loaderTable: false,
      isFilterTable: false,
    });
  };
  checkValidTable = () => {
    /* const start = this.state.startTableDate;
  const end = this.state.endTableDate; */
    // console.log(this.state.startTableDate, this.props.timeZone);
    const start = this.state.startTableDate
      ? moment.tz(this.state.startTableDate, this.props.timeZone).unix() * 1000
      : null;
    const end = this.state.endTableDate
      ? moment
        .tz(this.state.endTableDate, this.props.timeZone)
        .add(1, "day")
        .unix() * 1000
      : null;

    if (start > end) {
      this.setState({ warnvalidTable: true, validTable: true });
    } else {
      this.setState({ warnvalidTable: false, validTable: false });
    }
    console.log(start, end);
  };
  dayfilterTable = async () => {
    const resId = this.props.restaurant_id,
      intergate = this.props.restaurant.integrate;
    /* const start = this.state.startTableDate;
  const end = this.state.endTableDate; */
    const start = this.state.startTableDate
      ? moment.tz(this.state.startTableDate, this.props.timeZone).unix() * 1000
      : null;
    const end = this.state.endTableDate
      ? moment
        .tz(this.state.endTableDate, this.props.timeZone)
        .add(1, "day")
        .unix() * 1000
      : null;
    console.log(this.state.endTableDate, end, start);
    const totalTableData = await getTableOrders(resId, intergate, start, end);
    this.setState({ totalTableData, loaderTable: false, isFilterTable: true });
  };
  // const fromDate = moment
  //     .tz("2022-11-23", this.props.restaurant.timeZone)
  //     .unix();
  //   const toDate = moment
  //     .tz("2022-11-24", this.props.restaurant.timeZone)
  //     .add(1, "day")
  //     .unix();

  //   console.log("date", fromDate, toDate);

  render() {
    const { customer } = this.props;

    return (
      <>
        {this.state.loader ? (
          <div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                // minHeight: "100vh",
                transition: "0.5s ease-in-out 0s",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ScaleLoader size={12} color="#ec7211" />
            </div>
          </div>
        ) : (
          <div className="dashboard-group">
            <h2 style={{ marginBottom: "16px", fontSize: '24px' }}>
              Welcome {customer.userName}
            </h2>
            <Row className="g-3">
              <Col>
                <Card className="net-sales">
                  <Card.Header
                    className="d-flex"
                    style={{ alignItems: "center", padding: "0" }}
                  >
                    <Card.Text style={{ padding: "10px", width: "32%" }}>
                      Net Sales
                    </Card.Text>
                    <Card.Text
                      style={{
                        background: "transparent",
                        padding: "0 10px",
                        borderBottom: "0",
                        textAlign: "right",
                      }}
                    >
                      <div className="daterange dashboard">
                        <input
                          type="date"
                          placeholder="Start date"
                          onChange={(e) => {
                            this.setState({ startDate: e.target.value });
                            setTimeout(this.checkValid, 100);
                          }}
                        />
                        <input
                          type="date"
                          onChange={(e) => {
                            this.setState({ endDate: e.target.value });
                            setTimeout(this.checkValid, 100);
                          }}
                        />
                        {this.state.isFilter ? (
                          <button
                            className="btn-search"
                            onClick={() => {
                              this.totalData();
                            }}
                          >
                            Clear
                          </button>
                        ) : (
                          <button
                            className="btn-search"
                            disabled={this.state.valid}
                            onClick={() => {
                              this.dayfilter();
                            }}
                          >
                            Apply
                          </button>
                        )}

                        {this.state.warnvalid ? (
                          <p
                            style={{
                              margin: "0",
                              padding: "5px 60px 0",
                              color: "red",
                              fontSize: "15px",
                            }}
                          >
                            Please select a valid date
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Card.Text>
                  </Card.Header>
                  <div className="d-flex">
                    <Card.Body>
                      <Card.Text>Total Orders</Card.Text>
                      <Card.Text>{this.state.totalData.noOfOrders}</Card.Text>
                    </Card.Body>
                    <Card.Body>
                      <Card.Text>Total Sales</Card.Text>
                      <Card.Text>
                        $
                        {parseFloat(
                          this.state.totalData.totalAmount / 100
                        ).toFixed(2)}
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Header>Today Sales</Card.Header>
                  <div className="d-flex">
                    <Card.Body>
                      <Card.Text>Total Orders</Card.Text>
                      <Card.Text>{this.state.todayData.noOfOrders}</Card.Text>
                    </Card.Body>
                    <Card.Body>
                      <Card.Text>Total Sales</Card.Text>
                      <Card.Text>
                        $
                        {parseFloat(
                          this.state.todayData.totalAmount / 100
                        ).toFixed(2)}
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="g-3 mt-4 mb-3">
              <Col lg={12}>
                <Card className="net-sales">
                  <Card.Header
                    className="d-flex"
                    style={{ alignItems: "center", padding: "0" }}
                  >
                    <Card.Text style={{ padding: "10px", width: "46%" }}>
                      DoorDash Net Sales
                    </Card.Text>
                    <Card.Text
                      style={{
                        background: "transparent",
                        padding: "0 10px",
                        borderBottom: "0",
                        textAlign: "right",
                      }}
                    >
                      <div className="daterange dashboard">
                        <input
                          type="date"
                          placeholder="Start date"
                          onChange={(e) => {
                            this.setState({
                              startdoordashDate: e.target.value,
                            });
                            setTimeout(this.checkValidDD, 100);
                          }}
                        />
                        <input
                          type="date"
                          onChange={(e) => {
                            this.setState({ enddoordashDate: e.target.value });
                            setTimeout(this.checkValidDD, 100);
                          }}
                        />
                        {this.state.isFilterDD ? (
                          <button
                            className="btn-search"
                            onClick={() => {
                              this.totaldoordashData();
                            }}
                          >
                            Clear
                          </button>
                        ) : (
                          <button
                            className="btn-search"
                            disabled={this.state.validDD}
                            onClick={() => {
                              this.dayfilterDD();
                            }}
                          >
                            Apply
                          </button>
                        )}

                        {this.state.warnvalidDD ? (
                          <p
                            style={{
                              margin: "0",
                              padding: "5px 60px 0",
                              color: "red",
                              fontSize: "15px",
                            }}
                          >
                            Please select a valid date
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Card.Text>
                  </Card.Header>
                  <div className="d-flex">
                    <Card.Body>
                      <Card.Text>Total Orders</Card.Text>
                      <Card.Text>
                        {this.state.totaldoordashData.noOfOrders}
                      </Card.Text>
                    </Card.Body>
                    <Card.Body>
                      <Card.Text>Total Sales</Card.Text>
                      <Card.Text>
                        $
                        {parseFloat(
                          this.state.totaldoordashData.totalAmount / 100
                        ).toFixed(2)}
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="g-3 mt-4 mb-3">
              <Col lg={12}>
                <Card className="net-sales">
                  <Card.Header
                    className="d-flex"
                    style={{ alignItems: "center", padding: "0" }}
                  >
                    <Card.Text style={{ padding: "10px", width: "46%" }}>
                      Table Orders Net Sales
                    </Card.Text>
                    <Card.Text
                      style={{
                        background: "transparent",
                        padding: "0 10px",
                        borderBottom: "0",
                        textAlign: "right",
                      }}
                    >
                      <div className="daterange dashboard">
                        <input
                          type="date"
                          placeholder="Start date"
                          onChange={(e) => {
                            this.setState({
                              startTableDate: e.target.value,
                            });
                            setTimeout(this.checkValidTable, 100);
                          }}
                        />
                        <input
                          type="date"
                          onChange={(e) => {
                            this.setState({ endTableDate: e.target.value });
                            setTimeout(this.checkValidTable, 100);
                          }}
                        />
                        {this.state.isFilterTable ? (
                          <button
                            className="btn-search"
                            onClick={() => {
                              this.totalTableData();
                            }}
                          >
                            Clear
                          </button>
                        ) : (
                          <button
                            className="btn-search"
                            disabled={this.state.validTable}
                            onClick={() => {
                              this.dayfilterTable();
                            }}
                          >
                            Apply
                          </button>
                        )}

                        {this.state.warnvalidTable ? (
                          <p
                            style={{
                              margin: "0",
                              padding: "5px 60px 0",
                              color: "red",
                              fontSize: "15px",
                            }}
                          >
                            Please select a valid date
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Card.Text>
                  </Card.Header>
                  <div className="d-flex">
                    <Card.Body>
                      <Card.Text>Total Orders</Card.Text>
                      <Card.Text>
                        {this.state.totalTableData.totalItems}
                      </Card.Text>
                    </Card.Body>
                    <Card.Body>
                      <Card.Text>Total Sales</Card.Text>
                      <Card.Text>
                        $
                        {parseFloat(
                          this.state.totalTableData.totalPrice / 100
                        ).toFixed(2)}
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
    customer: state.customer,
    restaurant_id: state.customer.resId,
    timeZone: state.restaurant.restaurant.timeZone,
  };
};
export default connect(mapStateToProps, null)(Dashboard);
