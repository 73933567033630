import React, { Component, useState } from "react";
import { Container } from "react-bootstrap";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import axios from "axios";
import { Checkbox } from "react-bootstrap";

class Todaymenu extends Component {
  state = {
    items: [],
    input: "",
    checkedItem: [],
  };
  async componentDidMount() {
    axios
      .get(`/api/${this.props.restaurant.id}/daily-menu`)
      .then((res) => {
        console.log(res);
        this.setState({
          items: JSON.parse(res.data[0]["items"]),
          checkedItem: JSON.parse(res.data[0]["menu"])
            ? JSON.parse(res.data[0]["menu"])
            : [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleCheck = (e, item) => {
    let checkedItem = this.state.checkedItem;
    if (e.target.checked) {
      checkedItem.push(e.target.value);
    } else {
      checkedItem.splice(checkedItem.indexOf(e.target.value), 1);
    }
    this.setState({ checkedItem });
  };
  handleChange = (e) => {
    this.setState({
      input: e.target.value,
    });
  };

  addnewMenu = () => {
    this.setState({
      input: "",
      items: new Array(...new Set([...this.state.items, this.state.input])),
    });
  };
  onSubmit = (e) => {
    var data = {};
    data["items"] = this.state.items;
    data["menu"] = this.state.checkedItem;
    axios
      .post(`/api/admin/${this.props.restaurant.id}/daily-menu`, data)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  render() {
    return (
      <Container>
        <div className="appmenu">
          <div className="checkList">
            <h2>Today Specials</h2>
            <div className="list-container">
              <p>Admin New Menu</p>{" "}
              <input
                value={this.state.input}
                type="text"
                placeholder=""
                onChange={this.handleChange}
              />
              <button className="btn adding" onClick={this.addnewMenu}>
                Add
              </button>
            </div>
            <form>
              <div>
                {this.state.items.length ? (
                  <>
                    {this.state.items.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          {this.state.checkedItem.includes(item) ? (
                            <>
                              <label>
                                <input
                                  value={item}
                                  checked
                                  onChange={this.handleCheck}
                                  type="checkbox"
                                />
                                {item}
                              </label>
                            </>
                          ) : (
                            <>
                              <label>
                                <input
                                  value={item}
                                  onChange={this.handleCheck}
                                  type="checkbox"
                                />
                                {item}
                              </label>
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
          <div style={{ paddingTop: "2em" }}>
            <button onClick={this.onSubmit} className="btn">
              Save
            </button>
          </div>

          <div></div>
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps)(Todaymenu);
