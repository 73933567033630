import React, { Component } from "react";
import moment from "moment-timezone";
import "../pages.css";
import { getTableReceipt, joinObjSameKeyGname } from "../../utils/api";
import * as HiIcons from "react-icons/hi";
import { useParams, useSearchParams } from "react-router-dom";
import MultiLingualContent from "../../context/multilingualContent";
const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
class TableReceipt extends Component {
  state = {
    data: {},
    loaded: false,
    default: false,
    resId: "",
    orderId: "",
  };
  async componentDidMount() {
    const { restaurantsId: resId, orderId } = this.props.params;
    // const resId = window.location.pathname.split("/")[2],
    //   orderId = window.location.pathname.split("/")[3];
    // console.log(this.props.params);
    this.setState({
      resId,
      orderId,
    });
    const data = await getTableReceipt(resId, orderId);
    if (data) {
      let orderData = { ...data };
      orderData.order["customerOrders"].map((order, i) => {
        orderData.order["customerOrders"][i]["items"] = order["items"].map(
          (ite, i) => {
            let temp = {};
          // console.log(ite)
          let parse = JSON.parse(ite.modifiers);
          let group = ite.modifierGroups?.elements;
          let groupId = {};
          if (parse) {
            let obijtemp = {};
            Object.values(parse).forEach((ite) => {
              if (obijtemp[ite.modifier_group_id]) {
                obijtemp[ite.modifier_group_id]["modarr"].push(
                  ite.modifier_name
                );
              } else {
                obijtemp[ite.modifier_group_id] = {
                  gname: ite.modifier_group_name || "Type",
                  modarr: [ite.modifier_name],
                };
              }
            });
            obijtemp = joinObjSameKeyGname(obijtemp);
            temp = { ...ite, modifiers: obijtemp };

          } else {
            temp = { ...ite, modifiers: {} };
          }
            return temp;
        }
        );
      });
      this.setState({
        data: orderData,
        loaded: true,
      });
    } else {
      this.setState({ default: true });
    }
  }
  render() {
    return (
      <>
        {this.state.loaded ? (
          <div
            id="invoice-POS"
            className="orderReceipt"
            style={{ width: "100%", marginTop: "2rem", maxWidth: "350px" }}
          >
            <center id="top">
              <div className="logo">
                <img
                  style={{ width: "30%" }}
                  src={this.state.data.restaurant.logo}
                />
              </div>
              <div className="info">
                <h2>{this.state.data.restaurant.name}</h2>
                <p>
                  {this.state.data.restaurant.address1}{" "}
                  {this.state.data.restaurant.address2}
                  <br />
                  {this.state.data.restaurant.city},{" "}
                  {this.state.data.restaurant.state},{" "}
                  {this.state.data.restaurant.country},{" "}
                  {this.state.data.restaurant.zip}
                  <br />
                  {this.state.data.restaurant.phoneNumber}
                  <br />
                  {this.state.data.restaurant.website ? (
                    this.state.data.restaurant.website
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </center>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                }}
              >{`Name: ${capitalizeFirstLetter(
                this.state.data.order["customerOrders"][0].Name
              )}`}</p>
              {this.state.data.order["customerOrders"][0].Mobile ? (
                <p
                  style={{
                    fontWeight: "600",
                  }}
                >{`Mobile: ${this.state.data.order["customerOrders"][0].Mobile}`}</p>
              ) : (
                <></>
              )}
            </div>
            <div id="bot">
              <div id="table">
              {this.state.data.order["customerOrders"].map((orders, i) => {
                return (
                  <>
                    <div
                      className="customer-details"
                      style={{ textAlign: "center", marginTop: "10px" }}
                    >
                      <h6>Guest {i + 1}</h6>
                    </div>
                    <table>
                      <tbody>
                        <tr className="tabletitle">
                          <td className="item" style={{ width: "55%" }}>
                            <h2>Item</h2>
                          </td>
                          <td className="Hours">
                            <h2>Qty</h2>
                          </td>
                          <td className="Rate">
                            <h2>Sub Total</h2>
                          </td>
                        </tr>
                        {orders.items.map((item, i) => {
                          return (
                            <tr className="service" key={item.id + i}>
                              <td
                                className="tableitem"
                                style={{ width: "200px" }}
                              >
                                <p className="itemtext">
                                  {item.product_name}
                                  {item.instructions ? (
                                    <>
                                      <br />
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {item.instructions}
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </p>
                                {item.modifiers ? (
                                  Object.values(item.modifiers).map((ite) => {
                                    return (
                                      <>
                                        <div className="itemmodify">
                                          [
                                          {
                                            <>
                                              <span
                                                style={{ fontWeight: "600" }}
                                              >{` ${ite.gname}: `}</span>
                                              <span>{`${ite.modarr.join(
                                                ", "
                                              )} `}</span>
                                            </>
                                          }
                                          ]
                                        </div>
                                      </>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="tableitem">
                                <p className="itemtext">{item.quantity}</p>
                              </td>
                              <td className="tableitem">
                                <p className="itemtext">
                                  {" "}
                                  ${" "}
                                  {parseFloat(item.totalPrice / 100).toFixed(
                                    2
                                  )}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                );
              })}
                    
                    <table className="totals-count">
                    <tbody>
                    <tr>
                      <td>
                        <h2><MultiLingualContent contentID="Subtotal" /></h2>
                      </td>
                      <td></td>
                      <td>
                      <h2>
                          {" "}
                          ${" "}
                          {parseFloat(
                            this.state.data.order.subTotal / 100
                          ).toFixed(2)}
                        </h2>
                      </td>
                    </tr>
                    {this.state.data.order.discount ? (
                      <tr>
                        <td>
                          <h2>Discount</h2>
                        </td>
                        <td></td>
                        <td>
                          <h2>
                            {" "}
                            - ${" "}
                            {parseFloat(
                              this.state.data.order.discount / 100
                            ).toFixed(2)}
                          </h2>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <td>
                        <h2>Total Tax</h2>
                      </td>
                      <td></td>
                      <td>
                        <h2>
                          {" "}
                          ${" "}
                          {parseFloat(this.state.data.order.tax / 100).toFixed(
                            2
                          )}
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>Total Tip</h2>
                      </td>
                      <td></td>
                      <td>
                        <h2>
                          {" "}
                          ${" "}
                          {parseFloat(this.state.data.order.tip / 100).toFixed(
                            2
                          )}
                          {/* {parseFloat(this.state.data.order['customerOrders'][0]['tip'] / 100).toFixed(2)} */}
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>Order Total</h2>
                      </td>
                      <td></td>
                      <td>
                        <h2>
                          {" "}
                          ${" "}
                          {parseFloat(
                            this.state.data.order.total / 100
                          ).toFixed(2)}
                        </h2>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    textAlign: "center",
                    marginTop: "14px",
                  }}
                >{`Order ID: ${this.state.data.order.tableTokenId}`}</div>
                <p className="additional">
                  {this.state.data.order.notes?.length ? (
                        <>
                        {" "}
                      <center>
                        <HiIcons.HiPencilAlt
                          style={{ marginRight: "6px", fontSize: "18px" }}
                        />
                       {this.state.data.order.notes
                          .filter((note) => note?.trim())
                          .map((note) => note.replace(/,/g, ""))
                          .join(",")}
                      </center>
                      <br />
                    </>
                  ) : (
                    <></>
                  )}

                  {moment(this.state.data.order.createdAt)
                    .tz(this.state.data.restaurant.timeZone)
                    .format("MMM Do YYYY, h:mm:ss A")}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {this.state.default ? (
          <p>{`We couldn't find that Table receipt. The order id was ${this.state.orderId}`}</p>
        ) : (
          <></>
        )}
      </>
    );
  }
}

// class Receipt extends Component {
//   state = {
//     data: {},
//   };
//   async componentDidMount() {
//     const resId = window.location.pathname.split("/")[2],
//       orderId = window.location.pathname.split("/")[3];
//     console.log(resId, orderId);
//     const data = await getTableReceipt(
//       window.location.pathname.split("/")[2],
//       window.location.pathname.split("/")[3]
//     );
//     this.setState({
//       data,
//     });
//   }
//   render() {
//     return (
//       <>
//         {Object.keys(this.state.data).length ? (
//           <>
//             {" "}
//             <div id="invoice-POS">
//               {console.log(this.state.data)}
//               <center id="top">
//                 <div className="logo">
//                   <img
//                     style={{ width: "70%" }}
//                     src={this.state.data.restaurant.logo}
//                   />
//                 </div>
//                 <div className="info">
//                   <h2>{this.state.data.restaurant.name}</h2>
//                   <p>
//                     {this.state.data.restaurant.address1}{" "}
//                     {this.state.data.restaurant.address2}
//                     <br />
//                     {this.state.data.restaurant.city},{" "}
//                     {this.state.data.restaurant.state},{" "}
//                     {this.state.data.restaurant.country},{" "}
//                     {this.state.data.restaurant.zip}
//                     <br />
//                     {this.state.data.restaurant.phoneNumber}
//                     <br />
//                     {this.state.data.restaurant.website}
//                   </p>
//                 </div>
//               </center>

//               <div id="bot">
//                 <div id="table">
//                   <table>
//                     <tbody>
//                       <tr className="tabletitle">
//                         <td className="item">
//                           <h2>Item</h2>
//                         </td>
//                         <td className="Hours">
//                           <h2>Qty</h2>
//                         </td>
//                         <td className="Rate">
//                           <h2>Sub Total</h2>
//                         </td>
//                       </tr>
//                       {this.state.data.order['customerOrders'][0].items.map((item) => {
//                         return (
//                           <tr className="service" key={item.id}>
//                             <td className="tableitem">
//                               <p className="itemtext">{item.name}</p>
//                             </td>
//                             <td className="tableitem">
//                               <p className="itemtext">{item.quantity}</p>
//                             </td>
//                             <td className="tableitem">
//                               <p className="itemtext">
//                                 {" "}
//                                 $ {(item.price / 100) * item.quantity}
//                               </p>
//                             </td>
//                           </tr>
//                         );
//                       })}

//                       <tr className="tabletitle">
//                         <td className="Rate">
//                           <h2>Subtotal</h2>
//                         </td>
//                         <td></td>
//                         <td className="payment">
//                           <h2> $ {this.state.data.order['customerOrders'][0].subTotal / 100}</h2>
//                         </td>
//                       </tr>
//                       <tr className="tabletitle">
//                         <td className="Rate">
//                           <h2>Total Tax</h2>
//                         </td>
//                         <td></td>
//                         <td className="payment">
//                           <h2> $ {this.state.data.order['customerOrders'][0].tax / 100}</h2>
//                         </td>
//                       </tr>

//                       <tr className="tabletitle">
//                         <td className="Rate">
//                           <h2>Order Total</h2>
//                         </td>
//                         <td></td>
//                         <td className="payment">
//                           <h2> $ {this.state.data.order['customerOrders'][0].total / 100}</h2>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                   <p className="additional">
//                     {moment(this.state.data.order['customerOrders'][0].createdAt).format(
//                       "MMMM DD, YYYY h:mm a"
//                     )}{" "}
//                     <br />
//                     {this.state.data.order['customerOrders'][0].notes ? (
//                       <>"{this.state.data.order['customerOrders'][0].notes}"</>
//                     ) : (
//                       <></>
//                     )}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </>
//         ) : (
//           <></>
//         )}
//       </>
//     );
//   }
// }

export default (props) => {
  const [searchParams] = useSearchParams();
  return <TableReceipt {...props} params={useParams()} query={searchParams} />;
};
