const initialState = {
  isLoggedIn: false,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CUSTOMER":
      state = {
        ...state,
        ...action.data,
      };
      // console.log(action.data);
      break;
    default:
      break;
  }
  return state;
};
