import React, { Component, useState, useEffect } from "react";
// import storage from "../firebase/firebase";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "./pages.css";

import { Spinner } from "react-bootstrap";
import { addCategory,  deleteCategory,  getCategories, updateCategory, updateCatOrder } from "../Functions/fetch";
import ScaleLoader from "react-spinners/ScaleLoader";
import { compose } from "redux";
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import _ from 'lodash';
import { MdCancel, MdModeEdit } from 'react-icons/md'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function ModifierGroups(props) {
  const [categories, setCategories] = useState([]);
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [state, set] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [name, setName] = useState("");
  const [pending, setPending] = useState(true);
  const [isOpenDel, setisOpenDel] = useState(false);

  const [categoryId, setCategoryId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  // call your hook here
  useEffect(() => {
    async function helper() {
      try {
        const res = await getCategories(
          props.restaurant.id,
          props.restaurant.integrate
        );
        if (res) {
          let data = _.sortBy( res.elements, 'name' )
          setCategories(data);
          setPending(false);
        } else {
          setPending(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    helper();
  }, [state]);

  const columns = [
    {
      name: "Name",
      cell: (row) => <div onClick={() => props.router.navigate(`${row.id}`, { state: {...row, length: categories.length} })}>{row.name}</div>,
    },
    {
      name: "No Of Items",
      cell: (row) => <div>{row.items.elements.length}</div>,
    },
    {
      name: "Order",
      cell: (row) => <div>
        <select className="mt-3 mb-2" onChange={async (e) => {
          const response = await updateCatOrder(
            props.restaurant.id,
            props.restaurant.integrate,
            row.id,
            e.target.value
          );
        }} defaultValue={row.sortOrder}>
        {
          new Array(categories.length).fill(1).map((i, ins) => {
            return (
              <option
                key={ins + 1}
                style={{ marginRight: '10px' }}
                value={ins + 1}
                type="checkbox"
              >
                {ins + 1}
              </option>
            )
          })
        }
      </select>
      </div>,
    },
    {
      name: "Actions",
      cell: (row) => <div><MdCancel color="red" size={"2em"} onClick={async () => {
        handleCategoryDelete(row.id)
        // await deleteCategory(props.restaurant.id, props.restaurant.integrate, row.id);
        // window.location.reload();
      }}/><span style={{paddingLeft: '10px', marginBotton: 10}}><MdModeEdit color="green" size={"1.2em"} onClick={() => handleCategoryEdit(row.id, row.name)} /></span></div>,
    },
  ];
  const closeModal = () => {
    setName("");
    setIsEdit(false);
    setisOpenAdd(false);
    setisOpenDel(false);
    // console.log(descData.length || image)
  };
  
  const updateCat = async () => {
    setDisabled(false);
    setSpinner(true);
    const response = await updateCategory(
      props.restaurant.id,
      props.restaurant.integrate,
      categoryId,
      name
    );
    if (response) {
      window.location.reload(false);
      // updateItemState();
      setDisabled(true);
      setSpinner(false);
    }
  }

  const handleCategoryEdit = async (id, name) => {
    setCategoryId(id);
    setName(name);
    setIsEdit(true);
    setisOpenAdd(true);
  }
  const handleDeleteItem = async () => {
    setSpinner(true);
    setDisabled(false);

    const res = await deleteCategory(
      props.restaurant.id,
      props.restaurant.integrate,
      categoryId
    );
    if (res) {
        window.location.reload();
    }
  };
  const handleCategoryDelete = async (id, name) => {
    setCategoryId(id);
    setisOpenDel(true);
  }
  const addItem = async () => {
    setDisabled(false);
    setSpinner(true);
    const response = await addCategory(
      props.restaurant.id,
      props.restaurant.integrate,
      name
    );
    if (response) {
      window.location.reload(false);
      // updateItemState();
      setDisabled(true);
      setSpinner(false);
    }
  };
  return (
    <>
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        onClick={() => setisOpenAdd(true)}
      >
        Add Category
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        columns={columns}
        data={categories}
        pagination
        progressPending={pending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
      // onChangePage={this.handlePageChange}
      />
      {isOpenAdd ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={isOpenAdd}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                    <tr>
                        <td style={{ width: "33%" }}>Category Name</td>
                        <td>
                          {" "}
                          <Form.Control
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            value={name}
                            type="text"
                            size="sm"
                          />
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                  <center>
                    {
                      isEdit ? <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={updateCat}
                      style={{ display: "inline-block" }}
                      disabled={name ? false : true}
                    >
                      Update
                      {spinner ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        <></>
                      )}
                    </Button> : <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={addItem}
                      style={{ display: "inline-block" }}
                      disabled={name ? false : true}
                    >
                      Add
                      {spinner ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        <></>
                      )}
                    </Button>
                    }
                  </center>
                </div>
              </div>
            </div>
          </Modal.Body>

        </Modal>
      ) : (
        <></>
      )}

{isOpenDel ? (
        <Modal
          dialogClassName="del-model del-item"
          show={isOpenDel}
          onHide={closeModal}
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <h5>You are about to delete a category</h5>
            <p>
              This will delete your category from inventory <br />
              Are you sure?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ background: "transparent", color: "#000", border: "0" }}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button variant="secondary" onClick={handleDeleteItem}>
              Delete
              {spinner ? (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
// export default connect(mapStateToProps, null)(Modifiers);

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(ModifierGroups);
