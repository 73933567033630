import React from "react";
import { Card, Form, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";

function Redirect(props) {
  const [fetchlan, setFetlan] = React.useState([]);
  const [apicall, setApi] = React.useState(false);
  const [conditionEn, setCon] = React.useState(false);
  React.useEffect(() => {
    fetchlanguage();
    // apiCall();
  }, []);
  function redirectLink(long_url) {
    console.log('redirectLink')
    const link = document.getElementById('temp_anchor');
    link.href = long_url;
    link.click();
  }
  async function fetchlanguage() {
    try {
      if (!props.data.restaurant_id) {
        // window.location.replace(`${props.data.long_url}`);
        redirectLink(props.data.long_url)
      }
      let response = await fetch(
        `/api/v1/merchant/get/restaurant/online-order/language-support/settings/${props.data.restaurant_id}`
      );
      response = await response.json();
      if (response) {
        setFetlan([response]);
        setApi(true);
        // apiCall();
      }
      console.log(fetchlan, response);
      if (response === false) { 
        // window.location.replace(`${props.data.long_url}`);
        redirectLink(props.data.long_url)
      }
    } catch (e) {
      console.log(e)
    }
  }
  // function apiCall() {
  //   setInterval(() => {
  //     console.log(fetchlan);
  //   }, 3000);
  // }
  async function language(type) {
    try {
      if (props.data.isTable_order) {
        window.location.replace(`${props.data.long_url}&language=${type}`);
      } else {
        window.location.replace(`${props.data.long_url}?language=${type}`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function autoclick() {
    console.log(fetchlan.length);
    if (!fetchlan.length) {
      document.getElementById("autoclick").click();
    }
  }

  return (
    <>
      <a href="https://www.example.com" rel="nofollow" id='temp_anchor' style={{ visibility: 'hidden' }}>
        Click me
      </a>
      {apicall === true ? (
        <>
          {fetchlan.length ? (
            <>
              <Card
                className="order-filter"
                style={{ boxShadow: "none", alignItems: "center" }}
              >
                <div
                  style={{ justifyContent: "space-around" }}
                  className="align-items-center  mt-5"
                >
                  <h3>Choose your language</h3>
                </div>
                <div className="coupon-container" style={{ paddingTop: "0" }}>
                  <Form className="pb-4">
                    <Row
                      style={{ justifyContent: "space-around" }}
                      className="align-items-center  mt-5"
                    >
                      <Form.Group className="mb-3">
                        <Button
                          variant="primary"
                          onClick={(e) => language("en")}
                        >
                          English
                        </Button>{" "}
                      </Form.Group>
                    </Row>
                    {fetchlan.length ? (
                      <>
                        {fetchlan[0].es === "es" ? (
                          <Row
                            style={{ justifyContent: "space-around" }}
                            className="align-items-center  mt-5"
                          >
                            <Form.Group className="mb-3">
                              <Button
                                variant="primary"
                                onClick={(e) => language("es")}
                              >
                                Spanish
                              </Button>{" "}
                            </Form.Group>
                          </Row>
                        ) : (
                          <></>
                        )}
                        {fetchlan[0].it === "it" ? (
                          <Row
                            style={{ justifyContent: "space-around" }}
                            className="align-items-center mt-5"
                          >
                            <Form.Group className="mb-3">
                              <Button
                                variant="primary"
                                onClick={(e) => language("it")}
                              >
                                Italian
                              </Button>{" "}
                            </Form.Group>
                          </Row>
                        ) : (
                          <></>
                        )}
                        {fetchlan[0]["zh-CN"] === "zh-CN" ? (
                          <Row
                            style={{ justifyContent: "space-around" }}
                            className="align-items-center mt-5"
                          >
                            <Form.Group className="mb-3">
                              <Button
                                variant="primary"
                                onClick={(e) => language("zh-CN")}
                              >
                                Chinese
                              </Button>{" "}
                            </Form.Group>
                          </Row>
                        ) : (
                          <></>
                        )}
                        {fetchlan[0].hi === "hi" ? (
                          <Row
                            style={{ justifyContent: "space-around" }}
                            className="align-items-center mt-5"
                          >
                            <Form.Group className="mb-3">
                              <Button
                                variant="primary"
                                onClick={(e) => language("hi")}
                              >
                                Hindi
                              </Button>{" "}
                            </Form.Group>
                          </Row>
                        ) : (
                          <></>
                        )}
                        {fetchlan[0].ta === "ta" ? (
                          <Row
                            style={{ justifyContent: "space-around" }}
                            className="align-items-center mt-5"
                          >
                            <Form.Group className="mb-3">
                              <Button
                                variant="primary"
                                onClick={(e) => language("ta")}
                              >
                                Tamil
                              </Button>{" "}
                            </Form.Group>
                          </Row>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        {/* {window.location.replace(`${props.data.long_url}`)}; */}
                      </>
                    )}
                  </Form>
                </div>
              </Card>
            </>
          ) : (
            <>
              <a
                style={{ display: "none" }}
                id="autoclick"
                href={props.data.long_url}
                rel="nofollow"
              ></a>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    restaurant_id: state.customer.resId,
    customer: state.customer,
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(Redirect);
