const initialState = {
  settings: {},
  sidebar: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "DASHINIT":
      state = {
        ...state,
        ...action.data,
      };
      break;
    default:
      break;
  }
  return state;
};
