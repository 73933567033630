import React from "react";
import { Modal, Button } from "react-bootstrap";

export const StatusModalFail = ({
  modalShow,
  msg,
  onContinue,
  support,
  order_placed,
  orderId,
}) => {
  const onContinueClick = () => {
    onContinue();
  };
  return (
    <>
      <Modal show={modalShow} size="md" aria-labelledby="ordersucess" centered>
        <Modal.Body>
          <>
            {order_placed ? (
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  <strong>Your payment has been declined</strong>, but your
                  order has been placed successfully. You will get a text
                  message with a payment link to process the payment-
                  <strong>Order Id:{orderId}</strong>
                </p>
              </>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  margin: "0px 0 6px 0",
                  paddingTop: " 12px",
                  fontSize: "18px",
                }}
              >
                Your Payment was declined,Please try again or Change the payment
                method.
              </p>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ marginBottom: "0" }} onClick={onContinueClick}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const StatusModalSuccess = ({ modalShow, msg, onContinue, support }) => {
  const onContinueClick = () => {
    onContinue();
  };
  return (
    <>
      <Modal show={modalShow} size="md" aria-labelledby="ordersucess" centered>
        <Modal.Body>
          <>
            <p
              style={{
                textAlign: "center",
                margin: "0px 0 6px 0",
                paddingTop: " 12px",
                fontSize: "18px",
              }}
            >
              {`Order Failed Due to ${msg}...`}
            </p>

            <p style={{ textAlign: "center" }}>
              {`Please call this number for order ${support}.`}
            </p>
            <a href={`tel:${support}`}>click here to call</a>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ marginBottom: "0" }} onClick={onContinueClick}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
