import React from 'react';
import {
    Routes,
    Outlet,
    Route,
} from "react-router-dom";

import ProtectedRoutes from "../routes/ProtectedRoutes";
import OrderPopUp from "../components/OrderPopUp";
import ChatBot from '../components/Chat/ChatBot';
import Feedback from "../components/Feedback";
import AdminHome from "../dashboard/pages/AdminHome";
import Todaymenu from "../dashboard/pages/Todaymenu";
import QrScanner from "../dashboard/pages/QrScanner";
import Inventory from "../dashboard/pages/Inventory";
import Tmenus from "../pages/Tmenus";
import {
    Home,
    Delivery,
    Error,
    Cart,
    TableQRDownload,
    TableCart,
    CloverPayOnline,
    CloverPayTable,
    CloverPayCash,
    TablePayments,
    SignUp,
    Header,
    CustomPayOnline,
    SquarePayOnline,
    CustomPayCash,
    OrderPage,
    TodayOrderPage,
    TableThankyou,
    TablePaycash,
} from "../utils";
import AiChatBot from '../components/AiChatbot/AiChatbot';


const OrderRouters = (props) => {
    return (
        <>
            <Header className="Header" />
            <Routes>
                <Route
                    path="/:restaurantsId/table"
                    element={<Outlet />}
                >
                    <Route index element={<Home />} />
                    <Route
                        exact
                        path="QR/:tableId/:tableName"
                        element={<TableQRDownload />}
                    />
                    <Route
                        exact
                        path="cart"
                        element={<TableCart table={1} />}
                    />
                    <Route
                        exact
                        path="payment/:tableId"
                        element={<TablePayments />}
                    />
                    <Route
                        exact
                        path="thankyou/:tableId"
                        element={<TableThankyou />}
                    />
                    <Route
                        exact
                        path="cash/:tableId"
                        element={<TablePaycash />}
                    />

                    {props.restaurant.integrate === "clover" ? (
                        <>
                            <Route
                                exact
                                path="pay-on-cash"
                                element={<CloverPayCash table={1} />}
                            />
                            <Route
                                exact
                                path="pay-online"
                                element={<CloverPayOnline table={1} />}
                            />
                            <Route
                                exact
                                path="pay-table"
                                element={<CloverPayTable table={1} />}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    {props.restaurant.integrate === "custom" ? (
                        <>
                            <Route
                                exact
                                path="pay-on-cash"
                                element={<CustomPayCash table={1} />}
                            />
                            <Route
                                exact
                                path="pay-online"
                                element={<CustomPayOnline table={1} />}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    {props.restaurant.integrate === "square" ? (
                        <>
                            <Route
                                exact
                                path="pay-online"
                                element={<SquarePayOnline />}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Route>

                <Route
                    exact
                    path="/:restaurantsId/signup"
                    element={<SignUp />}
                />
                <Route
                    exact
                    path="/:restaurantsId/buffet-menu"
                    element={<Todaymenu />}
                />
                <Route
                    exact
                    path="/:restaurantsId/admin-dashboard"
                    element={<AdminHome />}
                />
                <Route
                    exact
                    path="/:restaurantsId/todays-buffet-menu"
                    element={<Tmenus />}
                />
                {props.restaurant.id.toLowerCase() ===
                    "biryani-bowl-mela" ? (
                    <>
                        {" "}
                        <Route
                            exact
                            path="/:restaurantsId/scanner"
                            element={<QrScanner />}
                        // element={<QrScannerPage />}
                        />
                        <Route
                            exact
                            path="/:restaurantsId/orders"
                            element={<OrderPage />}
                        />
                        <Route
                            exact
                            path="/:restaurantsId/today-orders"
                            element={<TodayOrderPage />}
                        />
                    </>
                ) : (
                    <></>
                )}
                <Route
                    exact
                    path="/:restaurantsId/delivery"
                    element={<Delivery />}
                />

                <Route
                    exact
                    path="/:restaurantsId"
                    element={<Home />}
                />
                <Route
                    exact
                    path="/:restaurantsId/dashboard"
                    element={<Inventory />}
                />
                <Route
                    exact
                    path="/:restaurantsId/order-success"
                    element={<OrderPopUp />}
                />

                <Route
                    exact
                    path="/:restaurantsId"
                    element={<ProtectedRoutes />}
                >
                    <Route exact path="feedback" element={<Feedback />} />
                    <Route exact path="cart" element={<Cart />} />
                    {/* SQUARE */}
                    {props.restaurant.integrate === "square" ? (
                        <>
                            <Route
                                exact
                                path="pay-online"
                                element={<SquarePayOnline />}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    {/* CLOVER */}
                    {props.restaurant.integrate === "clover" ? (
                        <>
                            <Route
                                exact
                                path="pay-online"
                                element={<CloverPayOnline />}
                            />

                            <Route
                                exact
                                path="pay-on-cash"
                                element={<CloverPayCash />}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    {/* CUSTOM */}
                    {props.restaurant.integrate === "custom" ? (
                        <>
                            <Route
                                exact
                                path="pay-online"
                                element={<CustomPayOnline />}
                            />
                            <Route
                                exact
                                path="pay-on-cash"
                                element={<CustomPayCash />}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Route>
                <Route path="*" element={<Error />} />
            </Routes>
            {/* <ChatBot notToshow={[]} toShow={[]} /> */}
            <AiChatBot notToshow={[]} toShow={[]} />
        </>
    )
}

export default OrderRouters
