import React, { useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
const getCookies = () => {
  return document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value),
      }),
      {}
    );
};
function Logout(props) {
  useEffect(() => {
    let token = getCookies().fdc_token;
    while (token) {
      console.log(token);
      document.cookie =
        "fdc_token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
      token = getCookies().fdc_token;
    }

    window.location.href = `/customer`;
  }, []);
  return (
    <>
      <div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            minHeight: "100vh",
            transition: "0.5s ease-in-out 0s",
            height: "100%",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BeatLoader size={12} color="#ec7211" />
          <p style={{ margin: "0", fontSize: "15px" }}>
            <i className="fa fa-truck"></i> Powered By{" "}
            <strong>PhoneDash</strong>
          </p>
        </div>
      </div>
    </>
  );
}

export default Logout;
