import React, { Component, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CartTotalsCheckout from "../../components/CartTotalsCheckout";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import BrandingHeader from "../../components/BrandingHeader";
import "react-phone-input-2/lib/style.css";
import {
    addCustomerCard,
    addCustomerPhone,
    getPaymentType,
    squareOrderOnline,
    postNewOrderOnline,
    postNewPayOrderOnline,
    updateCustomer,
    updateCustomerAddress,
    updateCustomerEmail,
    updateCustomerPhone,
} from "../../utils/api";
import { Set, Reset } from "../../redux/actions/cartActions";
import { round } from "../../utils/functions";
import { FaUserEdit } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { Button } from "react-bootstrap";
import CreditCardInput from "react-credit-card-input";
import { getOrderTypes } from "../../utils/api";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import {
    PaymentRequestButtonElement,
    useStripe,
    Elements,
} from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import CartTableCheckoutSquare from "../../components/table/square/CartTableCheckoutSquare";
import { emailValidation } from "../../utils/validation";
import MultiLingualContent from "../../context/multilingualContent";
import { translations } from "../../context/translations";
// const stripePromise = loadStripe('pk_live_51IvaDEB8IOuWU0mtRX2RnIxZ9d8ZiYc5I2WbV69olt2u9QuXQBrr8c3iV4PzPJPmXJQW1NQgzz62Qs2ak7fpSo2d00T69wBGPG');
// const stripePromise = loadStripe(
// "pk_test_51IvaDEB8IOuWU0mtzQ6MfdLjqyD70SGdFfsMxJvTcrn1v3cMGjrGy79t5sY5XzLUqQtvRJqV6IgkgyP7BOFBsp8h00p1rMPUGP"
// );
// const RequestPay = (props) => {
// const stripe = useStripe();
// const [paymentRequest, setPaymentRequest] = useState(null);
// const [total, setTotal] = useState(
// parseFloat(
// (
// parseFloat(props.total) +
// parseFloat(props.tax) +
// parseFloat(props.tip)
// ).toFixed(2)
// )
// );
// const [modalShow, setModalShow] = useState(false);
// const [orderId, setOrderId] = useState(false);
// const [name, setName] = useState("");
// const [res_id, setResId] = useState(props.res_id);
// useEffect(() => {
// if (stripe) {
// const pr = stripe.paymentRequest({
// country: "US",
// currency: "usd",
// total: {
// label: `Total`,
// amount: parseInt(total * 100),
// },
// requestPayerName: true,
// requestPayerEmail: true,
// });

// // Check the availability of the Payment Request API.
// pr.canMakePayment().then((result) => {
// pr.on("paymentmethod", async function (e) {
// //2
// setName(e.paymentMethod.billing_details.name);
// const paymentDetails = {
// payment_method: e.paymentMethod.id,
// shipping: {
// name: e.paymentMethod.billing_details.name,
// phone: e.paymentMethod.billing_details.phone
// ? e.paymentMethod.billing_details.phone
// : "",
// address: {
// line1: `${
// e.paymentMethod.billing_details.address.line1
// ? e.paymentMethod.billing_details.address.line1
// : ""
// }, ${
// e.paymentMethod.billing_details.address.line2
// ? e.paymentMethod.billing_details.address.line2
// : ""
// }`,
// city: e.paymentMethod.billing_details.address.city
// ? e.paymentMethod.billing_details.address.city
// : "",
// country: e.paymentMethod.billing_details.address.country
// ? e.paymentMethod.billing_details.address.country
// : "",
// state: e.paymentMethod.billing_details.address.state
// ? e.paymentMethod.billing_details.address.state
// : "",
// postal_code: e.paymentMethod.billing_details.address.postal_code
// ? e.paymentMethod.billing_details.address.postal_code
// : "",
// },
// },
// };
// let response = await fetch(
// `/api/order/${res_id}/create-payment-intent`,
// {
// method: "POST",
// body: JSON.stringify({
// paymentDetails,
// amount: parseInt(total * 100),
// }),
// headers: {
// "Content-Type": "application/json",
// },
// }
// ).then((res) => {
// return res.json();
// });
// if (response.error) {
// console.log(response.error);
// e.complete("fail");
// } else {
// // e.complete('success');
// const { error, paymentIntent } = await stripe.confirmCardPayment(
// response.client_secret
// );
// if (error) {
// console.log(error);
// return;
// }
// if (paymentIntent.status === "succeeded") {
// var data = {};
// data["name"] = e.paymentMethod.billing_details.name
// ? e.paymentMethod.billing_details.name
// : "";
// data["mobile"] = e.paymentMethod.billing_details.phone
// ? e.paymentMethod.billing_details.phone
// : "";
// data["email"] = e.paymentMethod.billing_details.email
// ? e.paymentMethod.billing_details.email
// : "";
// data["orderType"] = props.orderType;
// data["notes"] = props.notes ? props.notes : "";
// data["restaurant_id"] = res_id;
// data["customer_id"] = props.customer_id;
// data["address"] = `${
// e.paymentMethod.billing_details.address.line1
// ? e.paymentMethod.billing_details.address.line1
// : ""
// }, ${
// e.paymentMethod.billing_details.address.line2
// ? e.paymentMethod.billing_details.address.line2
// : ""
// }`;
// data["city"] = e.paymentMethod.billing_details.address.city
// ? e.paymentMethod.billing_details.address.city
// : "";
// data["country"] = e.paymentMethod.billing_details.address.country
// ? e.paymentMethod.billing_details.address.country
// : "";
// data["state"] = e.paymentMethod.billing_details.address.state
// ? e.paymentMethod.billing_details.address.state
// : "";
// data["zip"] = e.paymentMethod.billing_details.address.postal_code
// ? e.paymentMethod.billing_details.address.postal_code
// : "";
// if (e.walletName === "googlePay") {
// data["payment"] = "google";
// data["paymentId"] = props.paymentMethodIds["google"];
// } else {
// data["payment"] = "apple";
// data["paymentId"] = props.paymentMethodIds["apple"];
// }
// data["payment_id"] = e.paymentMethod.id;
// const response = await postNewPayOrderOnline(
// data,
// props.items,
// props.tip,
// props.tax,
// e.walletName
// );
// if (!response) {
// e.complete("fail");
// } else {
// e.complete("success");
// localStorage.removeItem(res_id);
// setOrderId(response.id);
// setModalShow(true);
// }
// } else {
// console.warn(
// `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
// );
// }
// }
// });
// // pr.on('token', function (e) { // 1
// // console.log(e)
// // });
// // pr.on('source', function (e) { //3
// // console.log(e)
// // });
// pr.on("cancel", function () {
// alert("Payment Cancelled");
// });
// if (result) {
// setPaymentRequest(pr);
// }
// });
// }
// }, [stripe]);
// const handleConinue = () => {
// window.location.href = `/${props.res_id}`;
// };
// if (paymentRequest) {
// return (
// <>
// <PaymentRequestButtonElement
// className="payment-element"
// options={{ paymentRequest }}
// />{" "}
// <p className="payment-or">OR</p>
// <Modal
// show={modalShow}
// size="md"
// aria-labelledby="ordersucess"
// centered
// >
// <Modal.Body>
// <>
// <p
// style={{
// textAlign: "center",
// margin: "0px 0 6px 0",
// paddingTop: " 12px",
// fontSize: "18px",
// }}
// >
// {`Hi ${name}, Your Order ID`}
// </p>
// <p style={{ textAlign: "center", paddingBottom: "10px" }}>
// {" "}
// <b>{orderId}</b>
// </p>
// <p style={{ textAlign: "center" }}>
// {`Your order has been received. Your order will be ready for
// pick up in ${this.props.restaurant.deliverytime} minutes.`}
// </p>
// </>
// </Modal.Body>
// <Modal.Footer>
// <Button style={{ marginBottom: "0" }} onClick={handleConinue}>
// Continue
// </Button>
// {/* <Link
// style={{ marginBottom: "0" }}
// to={`/${this.props.restaurant.id}/feedback`}
// state={orderId}
// >
// Continue
// </Link> */}
// </Modal.Footer>
// </Modal>
// </>
// );
// }

// // Use a traditional checkout form.
// return <></>;
// };

class SquarePayOnline extends Component {
    state = {
        customer_id: "",
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        notes: "",
        cardNum: "",
        expiry: "",
        cvv: "",
        cardType: "",
        orderId: "",
        orderType: "",
        stripePromise: "",
        paymentMethod: {},
        modalShow: false,
        loader: false,
        type: "online",
        square_payment_type: "",
        card: {},
        googlePay: {},
        updated: true,
        isDisable: false,
        cardDecline: false,
        editPayment: false,
        edit: false,
        discountPrecent: 0,
        discountData: 0,
        tableId: this.props.table.table_id,
      tableName: this.props.table.table_name,
      method: this.props.method,
    };

    async componentDidMount() {
      window.scrollTo(0, 0);
      
        // console.log(this.props.restaurant);
        const response = await fetch(
            `/api/v1/merchant/${this.props.restaurant.id}/${this.props.restaurant.integrate}/discount`
        );
        const json = await response.json();

        if (json.isDiscount) {
            console.log("yes discount");
            this.setState({ discountData: json });
            this.setState({ discountPrecent: json.percentage });
        }
        this.squreInit();
        this.setState({
            customer_id: this.props.customer.id ? this.props.customer.id : "",
            restaurant_id: this.props.restaurant.id ? this.props.restaurant.id : "",
            firstName: this.props.customer.firstName
                ? this.props.customer.firstName
                : "",
            lastName: this.props.customer.lastName
                ? this.props.customer.lastName
                : "",
            email:
                this.props.customer.emailAddresses &&
                    this.props.customer.emailAddresses.elements[0]
                    ? this.props.customer.emailAddresses.elements[0].emailAddress
                    : "",
            mobile: this.props.customer.phone ? this.props.customer.phone : "",
            cardNum:
                this.props.customer.cards && this.props.customer.cards.elements[0]
                    ? `${this.props.customer.cards.elements[0].first6}*****${this.props.customer.cards.elements[0].last4}`
                    : "",
            // expiry:
            // this.props.customer.cards && this.props.customer.cards.elements[0]
            // ? this.props.customer.cards.elements[0].expirationDate
            // .match(/.{1,2}/g)
            // .join("/")
            // : "",
            cardType:
                this.props.customer.cards && this.props.customer.cards.elements[0]
                    ? this.props.customer.cards.elements[0].cardType
                    : "",
        });
    }
    // Square Start
    translatedWord = (word) => translations[this.props.restaurant.language] ? translations[this.props.restaurant.language][word] : translations["en"][word]
    squreInit = async () => {
        if (!window.Square) {
            throw new Error("Square.js failed to load properly");
        }
        let payments;
        try {
            payments = window.Square.payments(
                this.props.restaurant.restaurant.app_id,
                this.props.restaurant.restaurant.location_id
            );
        } catch (e) {
            console.log(e);
            return;
        }

        let card;
        try {
            card = await payments.card();
            this.setState({
                card,
            });
            await card.attach("#card-container");
        } catch (e) {
            console.error("Initializing Card failed", e);
            return;
        }

        let googlePay;
        try {
            // console.log(391, this.state.discountPrecent);
            let total;
            const discountPrecent = this.state.discountPrecent;
            if (this.props.checkout.couponCode.percentage) {
                total = Number(this.props.checkout.finalTotal.toFixed(2));
            } else if (discountPrecent) {
                const discount =
                    (Number(parseFloat(this.props.totalPrice).toFixed(2)) *
                        discountPrecent) /
                    100;
                let applyiedsubTotal =
                    Number(parseFloat(this.props.totalPrice).toFixed(2)) - discount;
                const tax = applyiedsubTotal * Number(this.props.tax.toFixed(2));
                // console.table({ discount, applyiedsubTotal, tax });
                total = applyiedsubTotal + tax;
            } else {
                const subTotal = Number(parseFloat(this.props.totalPrice).toFixed(2));
                const tax = subTotal * Number(this.props.tax.toFixed(2));
                const tip = subTotal * (this.props.tip / 100);
                total = subTotal + tax + tip;
            }
            // console.log(total);

            const paymentRequest = payments.paymentRequest({
                countryCode: "US",
                currencyCode: "USD",
                requestBillingContact: true,
                total: {
                    amount: String(total.toFixed(2)),
                    label: "Total",
                },
            });
            googlePay = await payments.googlePay(paymentRequest);
            await googlePay.attach("#google-pay-button");
            this.setState({
                googlePay,
            });
        } catch (e) {
            console.error("Initializing Google Pay failed", e);
        }
    };
    reIntGooglePay = async (totalAmount) => {
        console.log(totalAmount);
        // document.getElementById("google-pay-button").remove();
        let payments;
        try {
            payments = window.Square.payments(
                this.props.restaurant.restaurant.app_id,
                this.props.restaurant.restaurant.location_id
            );
            const paymentRequest = payments.paymentRequest({
                countryCode: "US",
                currencyCode: "USD",
                requestBillingContact: true,
                total: {
                    amount: String(totalAmount.toFixed(2)),
                    label: "Total",
                },
            });
            let googlePay = await payments.googlePay(paymentRequest);
            this.state.googlePay.detach();
            await googlePay.attach("#google-pay-button");
            this.setState({
                googlePay,
            });
        } catch (e) {
            console.log(e);
            return;
        }
    };
    createPayment = async (token) => {
        // console.log(this.props);
        const res = await squareOrderOnline(
            this.state,
            this.props.orderCart,
            this.props.tip,
            this.props.tax,
            {
                locationId: this.props.restaurant.restaurant.location_id,
                sourceId: token,
                square_payment_type: this.state.square_payment_type,
            },
            this.props.restaurant.convenience_fee,
            this.props.restaurant.convenience_rate,
            this.props.restaurant.convenience_limit,
            this.props.customer.connect,
            this.state.discountPrecent,
            this.state.discountData,
            this.props.customer.custom,
            this.props.cart.notes,
            this.props.checkout,
            this.props.restaurant.language
        );
            console.log(this.props.restaurant.language)
        if (Object.keys(res).length) {
            localStorage.removeItem(this.state.restaurant_id);
            if (this.state.tableId) {
                if (res.points) {
                    window.location.href = `/${this.state.restaurant_id}/table/thankyou/${this.state.tableId}?order_id=${res.tableOrderId}&reward_points=${res.points}&language=${this.props.restaurant.language}`;
                  } else {
                    window.location.href = `/${this.state.restaurant_id}/table/thankyou/${this.state.tableId}?order_id=${res.tableOrderId}&language=${this.props.restaurant.language}`;
                  }
                // window.location.href = `/${this.state.restaurant_id}/table/thankyou/${this.state.tableId}?order_id=${res.tableOrderId}`;
            } else {
                this.setState({ orderId: res.id, points: res.points });
                this.setState({
                    loader: false,
                    modalShow: true,
                });
            }
        }
    };

    tokenize = async (paymentMethod) => {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.details.method !== "Card") {
            // console.log(464, tokenResult);
            this.setState({
                firstName: tokenResult.details.billing.givenName || tokenResult.details.billing.email || tokenResult.details.billing.phone || 'no name',
                email: tokenResult.details.billing.email
                    ? tokenResult.details.billing.email
                    : "",
                mobile: tokenResult.details.billing.phone
                    ? tokenResult.details.billing.phone
                    : "",
            });
        }

        if (tokenResult.status === "OK") {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
            }

            throw new Error(errorMessage);
        }
    };
    handlePaymentMethodSubmission = async (event, paymentMethod) => {
        event.preventDefault();
        let checkMailValid = emailValidation(this.state.email);
        console.log(paymentMethod.methodType);
        try {
            if (
                this.props.customer.id ||
                paymentMethod.methodType.toLowerCase().includes("google") ||
                (this.state.firstName.length > 0 &&
                    this.state.email.length > 0 &&
                    checkMailValid &&
                    this.state.mobile.length === 12)
            ) {
                this.setState({ isDisable: true, loader: true });
                const token = await this.tokenize(paymentMethod);
                await this.createPayment(token);
            } else {
                if (this.state.firstName === "") {
                    document.getElementById("firstName").style.boxShadow =
                        "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
                    // document.getElementById("firstName").focus();
                }
                if (this.state.mobile.length < 12) {
                    document.querySelector(".react-tel-input input").style.boxShadow =
                        "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
                    // document.querySelector(".react-tel-input input").focus();
                }
                if (this.state.email === "" || !checkMailValid) {
                    document.getElementById("email").style.boxShadow =
                        "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
                    // document.getElementById("email").focus();
                }
            }
        } catch (e) {
            console.log(e);
            this.setState({ isDisable: false, loader: false, cardDecline: true });
            // toast.error("Please check the card details");
        }
    };
    handleCardPayment = async (e) => {
        e.preventDefault();
        this.setState({
            square_payment_type: "card",
        });
        const res = await this.handlePaymentMethodSubmission(e, this.state.card);

        // this.setState({
        // square_payment_type: "card",
        // isDisable: true,
        // loader: true,
        // });
        // await this.handlePaymentMethodSubmission(e, this.state.card);
    };
    handleGooglePayment = async (e) => {
        this.setState({
            square_payment_type: "google-pay",
            isDisable: true,
            loader: true,
        });
        await this.handlePaymentMethodSubmission(e, this.state.googlePay);
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
        e.target.style.boxShadow = "none";
    };
    handleEdit = (e) => {
        this.setState({
            updated: false,
            edit: true,
        });
    };

    handlePaymentEdit = (e) => {
        this.setState({
            updated: false,
            editPayment: true,
        });
    };

    updateUser = async (e) => {
        e.preventDefault();

        if (
            this.props.customer.phoneNumbers &&
            this.props.customer.phoneNumbers.elements[0]
        ) {
            await updateCustomerPhone(
                this.state.restaurant_id,
                this.state.customer_id,
                this.props.customer.phoneNumbers.elements[0]["id"],
                this.state
            );
        } else {
            await addCustomerPhone(
                this.state.restaurant_id,
                this.state.customer_id,
                this.props.customer.addresses.elements[0]["id"],
                this.state
            );
        }
        if (
            this.props.customer.emailAddresses &&
            this.props.customer.emailAddresses.elements[0]
        ) {
            await updateCustomerEmail(
                this.state.restaurant_id,
                this.state.customer_id,
                this.props.customer.emailAddresses.elements[0]["id"],
                this.state
            );
        }
        await updateCustomer(
            this.state.restaurant_id,
            this.state.customer_id,
            this.state
        );
        this.setState({
            edit: false,
            updated: true,
        });
    };
    handlePaymentUpdate = async (e) => {
        e.preventDefault();
        // if (this.props.customer.cards && this.props.customer.cards.elements[0]) {
        // await updateCustomerCard(this.state.restaurant_id, this.state.customer_id, this.props.customer.cards.elements[0]['id'], this.state.cardNum, this.state.expiry, this.state.cvv)
        // } else {
        await addCustomerCard(
            this.state.restaurant_id,
            this.state.customer_id,
            this.state.cardNum,
            this.state.expiry,
            this.state.cvv
        );
        // }
        this.setState({
            editPayment: false,
            updated: true,
        });
    };
    // handleNewOrder = async (event) => {
    // event.preventDefault();
    // // console.log(this.state.mobile);
    // if (
    // this.props.customer.id ||
    // (this.state.firstName.length > 0 &&
    // this.state.email.length > 0 &&
    // this.state.mobile.length === 12 &&
    // // this.state.address.length > 0 &&
    // // this.state.country.length > 0 &&
    // // this.state.city.length > 0 &&
    // // this.state.state.length > 0 &&
    // // this.state.zip.length > 0 &&
    // this.state.cvv.length > 0 &&
    // this.state.expiry.length > 0 &&
    // this.state.cardNum.length > 0)
    // ) {
    // this.setState({ isDisable: true, loader: true });
    // const res = await postNewOrderOnline(
    // this.state,
    // this.props.items,
    // parseInt(this.props.tips.replace(".", "")),
    // this.props.tip,
    // this.props.tax
    // );
    // if (Object.keys(res).length) {
    // this.setState({ orderId: res.id });
    // localStorage.removeItem(this.state.restaurant_id);
    // this.setState({
    // loader: false,
    // modalShow: true,
    // });
    // }
    // } else {
    // if (this.state.firstName === "") {
    // document.getElementById("firstName").style.boxShadow =
    // "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
    // // document.getElementById("firstName").focus();
    // }
    // if (this.state.mobile === "") {
    // document.querySelector(".react-tel-input input").style.boxShadow =
    // "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
    // // document.querySelector(".react-tel-input input").focus();
    // }
    // if (this.state.email === "") {
    // document.getElementById("email").style.boxShadow =
    // "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
    // document.getElementById("email").focus();
    // }
    // if (this.state.mobile === "") {
    // document.querySelector(".react-tel-input input").style.boxShadow =
    // "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
    // // document.querySelector(".react-tel-input input").focus();
    // }
    // // if (this.state.email === "") {
    // // document.getElementById("email").style.boxShadow =
    // // "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
    // // document.getElementById("email").focus();
    // // }
    // if (
    // this.state.cvv === "" ||
    // this.state.expiry === "" ||
    // this.state.cardNum === ""
    // ) {
    // document.getElementById("field-wrapper").style.boxShadow =
    // "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
    // }
    // }
    // };
    handleBlur = (e) => {
        // e.target.style.boxShadow = "none";
    };
    handleConinue = () => {
        window.location.href = `/${this.props.restaurant.id}`;
        this.props.Reset();
    };
    render() {
        const checkout = (
            <Row>
                {console.log(this.state)}
                <Col xs={12} lg={6}>
                    {/* <h4 className="mr-2 my-auto">Your Cart</h4> */}

                    {/* <Card className="mt-3">
 <Card.Header>EMAIL ADDRESS</Card.Header>
 <Card.Body>
 <Card.Text>{this.props.email}</Card.Text>
 </Card.Body>
 </Card> */}

                    <div className="payment-button">
                        <div
                            id="google-pay-button"
                            onClick={this.handleGooglePayment}
                        ></div>
                        {/* {this.state.orderType && this.state.stripePromise ? (
 <div style={{ width: "100%" }}>
 {" "}
 <Elements stripe={this.state.stripePromise}>
 <RequestPay
 notes={this.state.notes}
 customer_id={this.state.customer_id}
 paymentMethodIds={this.state.paymentMethod}
 total={parseFloat(this.props.totalPrice).toFixed(2)}
 orderType={this.state.orderType}
 name={this.props.name}
 res_id={this.props.restaurant.id}
 items={this.props.items}
 tip={this.props.tips}
 tax={
 parseFloat(this.props.totalPrice) *
 parseFloat(this.props.tax)
 }
 />
 </Elements>
 </div>
 ) : (
 <></>
 )} */}
                    </div>

                    <Card className="mt-3">
                        <Card.Header
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                background: "none",
                                color: "#000",
                                border: "0",
                                fontWeight: "700",
                                padding: "0",
                            }}
                        >
                            <span><MultiLingualContent contentID="Billing Details" /></span>
                            {this.state.edit ? (
                                <></>
                            ) : this.state.customer_id ? (
                                <>
                                    <FaUserEdit
                                        className="mr-1"
                                        size="1.5em"
                                        onClick={this.handleEdit}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </Card.Header>
                        {!this.state.edit && this.props.customer.customerId ? (
                            <Card.Body>
                                <Card.Text>
                                    {this.state.firstName} {this.state.lastName}
                                </Card.Text>
                                <Card.Text>{this.state.email}</Card.Text>
                                <Card.Text>{this.state.mobile}</Card.Text>
                            </Card.Body>
                        ) : (
                            <Form
                                // onSubmit={this.handleSubmit}
                                className="p-4"
                            >
                                <Form.Row>
                                    <Form.Group as={Col} controlId="firstName">
                                        <Form.Control
                                            onChange={this.handleChange}
                                            onFocus={this.handleBlur}
                                            value={this.state.firstName}
                                            placeholder="Name"
                                            required
                                        />
                                    </Form.Group>

                                    {/* <Form.Group as={Col} controlId="lastName">
 <Form.Control
 onChange={this.handleChange}
 onFocus={this.handleBlur}
 placeholder="Last Name"
 value={this.state.lastName}
 required
 />
 </Form.Group> */}
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="email">
                                        <Form.Control
                                            onFocus={this.handleBlur}
                                            onChange={this.handleChange}
                                            value={this.state.email}
                                            type="email"
                                            placeholder="Email"
                                            required
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="mobile">
                                        {/* <Form.Control
 onChange={this.handleChange}
 value={this.state.mobile}
 type="text"
 placeholder="Phone"
 required
 /> */}
                                        {(!this.state.mobile.replace("+", "").length ||
                                            !this.state.mobile.replace("+1", "").length) && (
                                                <span
                                                    style={{
                                                        color: "#000",
                                                        fontSize: "12px",
                                                        // textAlign: "center",
                                                        fontWeight: "500",
                                                        lineHeight: "15px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                   <MultiLingualContent contentID="Please enter the phone number to receive the order confirmation text." />
                                                </span>
                                            )}
                                        <PhoneInput
                                            countryCodeEditable={false}
                                            onlyCountries={["us"]}
                                            disableDropdown={true}
                                            country={"us"}
                                            onFocus={this.handleBlur}
                                            placeholder="Phone"
                                            inputclassName="phoneinput"
                                            value={this.state.mobile}
                                            onChange={(mobile) => {
                                                document.querySelector(
                                                    ".react-tel-input input"
                                                ).style.boxShadow = "none";
                                                this.setState({ mobile: `+${mobile}` });
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Row>

                                {this.state.edit ? (
                                    <Button
                                        block
                                        variant="warning"
                                        type="submit"
                                        disabled={this.validateFormAddress()}
                                        onClick={this.updateUser}
                                    >
                                        Update
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </Form>
                        )}
                    </Card>
                    <Modal
                        show={this.state.modalShow}
                        size="md"
                        aria-labelledby="ordersucess"
                        centered
                    >
                        <Modal.Body>
                            <>
                                <p
                                    style={{
                                        textAlign: "center",
                                        margin: "0px 0 6px 0",
                                        paddingTop: " 12px",
                                        fontSize: "18px",
                                    }}
                                >
                                    Your Order ID
                                </p>
                                <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                                    {" "}
                                    <b>{this.state.orderId}</b>
                                </p>
                                <p style={{ textAlign: "center" }}>
                                    {`Your order has been received. Your order will be ready for
 pick up in ${this.props.restaurant.deliverytime} minutes.`}
                                </p>
                                {this.state.points ? (
                                    <p style={{ textAlign: "center" }}>
                                        {`You have earned ${this.state.points} points as a reward for this order.`}
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                style={{ marginBottom: "0" }}
                                onClick={this.handleConinue}
                            >
                                Continue
                            </Button>
                            {/* <Link
 style={{ marginBottom: "0" }}
 to={`/${this.props.restaurant.id}/feedback`}
 state={this.state.orderId}
 >
 Continue
 </Link> */}
                        </Modal.Footer>
                    </Modal>
                    <Modal
            show={this.state.cardDecline}
            size="lg"
            aria-labelledby="ordersucess"
            centered
            onHide={() => this.setState({ cardDecline: false })}
          >
            <Modal.Header>
              <div
                className="py-4"
                style={{
                  textAlign: "center",
                  // padding: "3em 0 0",
                  fontWeight: "600",
                  fontSize: "16px",
                  margin: " 0 auto",
                }}
              >
                Your Card Declined, Order is not Placed. Please check your card
                details!
              </div>
            </Modal.Header>
            <Modal.Footer>
              <Button onClick={() => this.setState({ cardDecline: false })}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
                    <Card className="m">
                        <Card.Header
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                background: "none",
                                color: "#000",
                                border: "0",
                                fontWeight: "700",
                                padding: "0",
                            }}
                        >
                            <span><MultiLingualContent contentID="Enter card details" /></span>
                            {this.state.editPayment ? (
                                <></>
                            ) : this.state.customer_id ? (
                                <>
                                    {" "}
                                    <FaUserEdit
                                        className="mr-1"
                                        size="1.5em"
                                        onClick={this.handlePaymentEdit}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </Card.Header>
                        <Card.Body style={{ padding: "0" }}>
                            {!this.state.editPayment &&
                                Object.keys(this.props.customer).length &&
                                this.props.customer.cards &&
                                this.props.customer.cards.elements.length ? (
                                <Card.Body>
                                    <Card.Text>{this.state.cardNum}</Card.Text>
                                    <Card.Text>Expiry : {this.state.expiry}</Card.Text>
                                </Card.Body>
                            ) : (
                                <>
                                    {this.props.restaurant.integrate === "clover" ? (
                                        <Form
                                            className="payment"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CreditCardInput
                                                cardNumberInputProps={{
                                                    value: this.state.cardNum,
                                                    onBlur: (e) =>
                                                    (document.getElementById(
                                                        "field-wrapper"
                                                    ).style.boxShadow = "none"),
                                                    onChange: (e) => {
                                                        this.setState({
                                                            cardNum: e.target.value,
                                                        });
                                                    },
                                                }}
                                                cardExpiryInputProps={{
                                                    value: this.state.expiry,
                                                    onBlur: (e) =>
                                                    (document.getElementById(
                                                        "field-wrapper"
                                                    ).style.boxShadow = "none"),
                                                    onChange: (e) => {
                                                        this.setState({
                                                            expiry: e.target.value,
                                                        });
                                                    },
                                                }}
                                                cardCVCInputProps={{
                                                    value: this.state.cvv,
                                                    onBlur: (e) =>
                                                    (document.getElementById(
                                                        "field-wrapper"
                                                    ).style.boxShadow = "none"),
                                                    onChange: (e) => {
                                                        this.setState({
                                                            cvv: e.target.value,
                                                        });
                                                    },
                                                }}
                                                fieldClassName="card-input form-control"
                                            />
                                        </Form>
                                    ) : (
                                        <>
                                            {this.props.restaurant.integrate === "square" ? (
                                                <>
                                                    <form id="payment-form">
                                                        <div id="card-container"></div>
                                                        {/* <button id="card-button" square_payment_type="button" onClick={this.handleCardPayment}>Pay $ {parseFloat(this.props.totalPrice).toFixed(2)}</button> */}
                                                    </form>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={5}>
                    {this.props.table.table_id ? (
                        <CartTableCheckoutSquare
                            handleNewOrder={this.handleCardPayment}
                            googlePay={this.reIntGooglePay}
                            disabled={this.state.isDisable}
                            // disabled={!this.state.updated}
                            buttonText={this.translatedWord("Place Order")}
                            payment={true}
                            total={this.props.total}
                            totalPrice={round(this.props.totalPrice)}
                        />
                    ) : (
                        <CartTotalsCheckout
                            handleNewOrder={this.handleCardPayment}
                            disabled={this.state.isDisable}
                            // disabled={!this.state.updated}
                            buttonText={this.translatedWord("Place Order")}
                            payment={true}
                            total={this.props.total}
                            totalPrice={round(this.props.totalPrice)}
                        />
                    )}
                </Col>
            </Row>
        );
        return (
            <>
                {/* <BrandingHeader /> */}
                <div className="cart-header">
                    <p>{this.props.restaurant.restaurant.name}</p>
                    <button
                        onClick={() => window.history.back()}
                        style={{
                            padding: "5px 10px",
                            background: "none",
                            border: "0",
                            fontWeight: "600",
                            fontSize: "25px",
                        }}
                    >
                        <FontAwesomeIcon icon={faClose} size={"1x"} />
                    </button>
                </div>
                <React.Fragment>
                    {/* <CustomParallax title="Checkout" img={home_top} height={300} /> */}

                    <Container
                        className="my-auto Checkout"
                        style={{ opacity: this.state.loader ? "0.4" : "1" }}
                    >
                        {checkout}
                        {/* {this.props.loggedIn ? checkout : needToLogin} */}
                    </Container>
                </React.Fragment>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.cart.items,
        total: state.cart.total,
        totalPrice: state.cart.totalPrice,
        tax: state.restaurant.totalTax,
        tip: state.cart.tip,
        tips: state.cart.tips,
        restaurant: state.restaurant,
        name: state.restaurant.restaurant.name,
        stripe_pk: state.restaurant.restaurant.stripe_pk,
        customer: state.customer,
        tax: state.restaurant.totalTax,
        orderCart: state.cart.orderCart,
        cart: state.cart,
        loggedIn: state.customer.isLoggedIn,
        square_location_id: state.restaurant.location_id,
        api_key: state.restaurant.api_key,
        checkout: state.checkout,
      table: state.table,
      method: state.restaurant.method,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        Set: (title, num) => {
            dispatch(Set(title, num));
        },
        Reset: (title, num) => {
            dispatch(Reset(title, num));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SquarePayOnline);