import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "../pages.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as BsIcons from "react-icons/bs";
import _, { set } from "lodash";
import { MdCancel, MdModeEdit } from "react-icons/md";
import * as MdIcons from "react-icons/md";
import { toast } from "react-toastify";

async function warnRedBox(id, action = "add") {
  if (action === "add") {
    document.getElementById(id).style.boxShadow =
      "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
  } else {
    document.getElementById(id).style.boxShadow = "none";
  }
}
async function warnNotify(message) {
  toast.error(message);
}
function Category(props) {
  const { categoryId: current_category_list_id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState(state.name);
  const [productPending, setProductPending] = useState(true);
  const [productList, setProductList] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [choosedItemsId, setChoosedItemsId] = useState([]);
  const [modelState, setModelState] = useState({
    addProduct: false,
    addProductSpinner: false,
    addModifier: false,
    addModifierSpinner: false,
    editModifier: false,
    editModifierSpinner: false,
    customizeModifier: false,
    customizeModifierSpinner: false,
  });
  useEffect(() => {
    (() => {
      updateComponentState();
    })();
  }, []);
  async function updateComponentState() {
    updateCategoryListState();
    getAllItems();
  }
  async function updateCategoryListState() {
    try {
      let res = await fetch(
        `/api/v1/merchant/get/product/square/category/${current_category_list_id}/${props.restaurant.id}`
      );
      res = await res.json();
      // console.log(res);
      if (!res.length) {
        setProductPending(false);
        setProductList([]);
      }
      let data = res.map((item) => {
        let temp = {};
        temp.id = item.id;
        temp.productName = item.item_data.name;
        return temp;
      });
      setProductList(data);
      setProductPending(false);
    } catch (error) {
      console.log(error);
    }
  }
  async function getAllItems() {
    try {
      let getList = await fetch(
        `/api/v1/merchant/get/square/product/${props.restaurant.id}`
      );
      getList = await getList.json();
      // console.log(getList);
      if (!getList?.data) {
        setAllItems([]);
        return 1;
      }
      let data = getList.data.filter((item) => {
        return !item.categoryId || item.categoryId !== current_category_list_id;
      });
      setAllItems(data);
    } catch (error) {
      console.log(error);
    }
  }
  async function handleChooseItem(e) {
    const { id } = e.target;
    if (choosedItemsId.includes(id)) {
      let c = choosedItemsId.filter((dataId) => dataId !== id);
      setChoosedItemsId(c);
    } else {
      setChoosedItemsId((choosedItemsId) => [...choosedItemsId, id]);
    }
  }
  async function updateCategoryListName() {
    if (categoryName.length) {
      let res = await fetch(
        `/api/v1/merchant/update/square/category/${current_category_list_id}/${props.restaurant.id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: { name: categoryName } }),
        }
      );
      if (res) {
        // updateInitalState(current_modifier_list_id);
        // closeModal();
        navigate("/merchant/categories");
        toast.success("updated");
      }
    } else {
      let id = "catlist-name";
      let message = "Please enter a name for your Category.";
      warnRedBox(id);
      warnNotify(message);
    }
  }
  async function removeCategory(ID) {
    console.log(ID);
    let res = await fetch(
      `/api/v1/merchant/remove/square/product/category/${ID}/${current_category_list_id}/${props.restaurant.id}`
    );
    res = await res.json();
    if (res) {
      navigate("/merchant/categories");
      toast.success("updated");
    }
  }
  async function closeModal() {
    setModelState((prevState) => {
      return {
        ...prevState,
        addProduct: false,
        addModifier: false,
        editModifier: false,
        addProductSpinner: false,
        addModifierSpinner: false,
        editModifierSpinner: false,
        customizeModifier: false,
        customizeModifierSpinner: false,
      };
    });
    setChoosedItemsId([]);
    // setAllItems([]);
    // setSelectedProduct({});
    // setSelectedMod({});
    // resetModiferState();
  }
  async function addCatergory() {
    if (!choosedItemsId.length) return 1;
    let res = await fetch(
      `/api/v1/merchant/add/square/category/product/${current_category_list_id}/${props.restaurant.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: { items: choosedItemsId } }),
      }
    );
    if (res) {
      closeModal();
      navigate("/merchant/categories");
      toast.success("Added");
    }
  }
  const productColumns = [
    {
      name: "Product",
      cell: (row) => <div className="table-title">{row.productName}</div>,
    },
    {
      name: "",
      cell: (row) => (
        <div className="table-title">
          <Button onClick={() => removeCategory(row.id)}>
            <MdIcons.MdDelete />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      {console.log(choosedItemsId)}
      <center>
        <h3 style={{ fontWeight: 500 }}>Edit Category</h3>
      </center>
      <p
        style={{
          textTransform: "capitalize",
          fontSize: "1.5em",
          fontWeight: "bold",
        }}
      >
        <Form.Control
          onChange={(e) => {
            setCategoryName(e.target.value);
            warnRedBox(e.target.id, "remove");
          }}
          placeholder="Name"
          maxLength={255}
          type="text"
          id="catlist-name"
          name="name"
          value={categoryName}
          autofocus
          style={{ width: "50%" }}
        />
        <div>
          <Button onClick={updateCategoryListName}>Save</Button>
        </div>
      </p>
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        onClick={() => {
          // getAllItems();
          setModelState((prevState) => ({ ...prevState, addProduct: true }));
        }}
      >
        Add Product
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        columns={productColumns}
        data={productList}
        // pagination
        progressPending={productPending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
        // onChangePage={this.handlePageChange}
      />
      {modelState.addProduct ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={modelState.addProduct}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="modifiers-itmes">
              <div id="bot" style={{ height: "400px", overflowY: "scroll" }}>
                <div>
                  {allItems.map((item) => {
                    return (
                      <div key={item.productId}>
                        <label>
                          <input
                            style={{ marginRight: "10px" }}
                            value={item.name}
                            id={item.productId}
                            defaultChecked={
                              item.categoryId === current_category_list_id
                            }
                            onChange={handleChooseItem}
                            type="checkbox"
                          />
                          {item.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn mt-3 mb-3 btn-sm"
              onClick={addCatergory}
              style={{ display: "inline-block" }}
              disabled={modelState.addProductSpinner}
            >
              Add
              {modelState.addProductSpinner ? (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(Category);