import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "../pages.css";
import { Spinner } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import { compose } from "redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { MdCancel, MdModeEdit } from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import { toast } from "react-toastify";
// function withRouter(Component) {
//   function ComponentWithRouterProp(props) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     return <Component {...props} router={{ location, navigate, params }} />;
//   }

//   return ComponentWithRouterProp;
// }

function SquareModifierGroups(props) {
  const navigate = useNavigate();
  const [modList, setModList] = useState([]);
  const [pending, setPending] = useState(true);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [selectedMod, setSelectedMod] = useState({});
  const [name, setName] = useState("");
  useEffect(() => {
    (function () {
      getModifersList();
    })();
  }, []);
  const getModifersList = async () => {
    try {
      let getList = await fetch(
        `/api/v1/merchant/get/square/modiferList/${props.restaurant.id}`
      );
      getList = await getList.json();
      if (!getList.data.length) {
        setPending(false);
        return setModList([]);
      }
      // console.log(getList.data);
      const modData = getList.data.map((ite) => {
        // console.log(ite);
        const { id, modifier_list_data, type } = ite;
        const { modifiers } = modifier_list_data;
        let arrTemp = [];
        // modifiers.forEach((mod) => {
        // console.log(mod.modifier_data.name);
        // });
        let temp = {};
        temp.id = id;
        temp.name = modifier_list_data.name;
        temp.type = type;
        temp.modifiers = modifier_list_data.modifiers;
        temp.data = ite;
        temp.modifiersList = modifiers.map((mod) => mod.modifier_data.name);
        return temp;
      });
      setModList(modData);
      setPending(false);
    } catch (error) {
      console.log(error);
    }
  };
  const closeModal = () => {
    setName("");
    setSelectedMod({});
    setIsOpenAdd(false);
  };
  async function deleteModifierGroup(ID) {
    let res = await fetch(
      `/api/v1/merchant/delete/square/object/${ID}/${props.restaurant.id}`
    );
    if (res) {
      getModifersList();
      toast.error("Modifier List deleted");
    }
  }
  const updateModifierGroupName = async () => {
    let copyObj = { ...selectedMod };
    copyObj.modifier_list_data.name = name;
    let res = await fetch(
      `/api/v1/merchant/update/square/modiferList/${props.restaurant.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ object: copyObj }),
      }
    );
    if (res) {
      window.location.reload(false);
    }
  };
  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <div
          className="table-title"
          onClick={
            () => navigate(`/merchant/modifiers/${row.id}`, { state: row })
            // navigate(`/merchant/modifiers/${row.id}`, { state: row })
          }
        >
          {row.name}
        </div>
      ),
    },
    {
      name: "Modifiers",
      cell: (row) => (
        <div className="table-title">
          {row.modifiersList.length
            ? row.modifiersList.join(", ")
            : "No Modifiers"}
        </div>
      ),
    },
    // {
    //   name: "No Of Modifiers",
    //   cell: (row) => <div className="table-title">{row.modifiers.length}</div>,
    // },
    {
      name: "",
      cell: (row) => (
        <div className="table-title">
          <Button
            style={{ marginRight: "9px" }}
            onClick={() => {
              navigate(`/merchant/modifiers/${row.id}`, { state: row });
              // setSelectedMod(row.data);
              // setName(row.name);
              // setIsOpenAdd(true);
            }}
          >
            <BsIcons.BsPencil />
          </Button>
          <Button onClick={() => deleteModifierGroup(row.id)}>
            <MdIcons.MdDelete />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        // onClick={() => setIsOpenAddProduct(true)}
        onClick={() => navigate(`/merchant/modifiers/new`)}
      >
        Add Modifier List
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        columns={columns}
        data={modList}
        // pagination
        progressPending={pending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
        // onChangePage={this.handlePageChange}
      />
      {isOpenAdd ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={isOpenAdd}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "33%" }}>Modifier Group</td>
                        <td>
                          {" "}
                          <Form.Control
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            type="text"
                            size="sm"
                          />
                        </td>
                      </tr>

                      {/* {multiple ? <tr>
                        <td style={{ width: "33%" }}>Max Select</td>
                        <td>
                          {" "}
                          <Form.Control
                            onChange={(e) => {
                              setChooseLimit(parseInt(e.target.value));
                            }}
                            type="number"
                            size="sm"
                          />
                        </td>
                      </tr>: <></>}
                       */}
                    </tbody>
                  </table>
                  <center>
                    <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={updateModifierGroupName}
                      style={{ display: "inline-block" }}
                      disabled={name ? false : true}
                    >
                      Update
                      {/* {spinner ? (
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        ) : (
                          <></>
                        )} */}
                    </Button>
                    {/* {isEdit ? (
                      <Button
                        className="btn mt-3 mb-3 btn-sm"
                        onClick={updateModifierGroup}
                        style={{ display: "inline-block" }}
                        disabled={name ? false : true}
                      >
                        Update
                        {spinner ? (
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        ) : (
                          <></>
                        )}
                      </Button>
                    ) : (
                      <Button
                        className="btn mt-3 mb-3 btn-sm"
                        onClick={addItem}
                        style={{ display: "inline-block" }}
                        disabled={name ? false : true}
                      >
                        Add
                       
                      </Button>
                    )} */}
                  </center>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(SquareModifierGroups);

// export default compose(
//   withRouter,
//   connect(mapStateToProps, null)
// )(SquareModifierGroups);
