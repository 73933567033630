import React from "react";
import DeliveryMenu from "../components/DeliveryMenu";
import CustomParallax from "../components/CustomParallax";
import home_top from "../assets/Web-image-3.jpg";

export default function Delivery() {
  //   function refreshPage() {
  //     window.location.reload(false);
  //     // console.log("reload");
  //   }
  //   refreshPage();
  //   (async () => {
  //     window.location.reload(false);
  //   })();
  return (
    <React.Fragment>
      <CustomParallax title="Deliveries" img={home_top} height={300} />
      <DeliveryMenu className="Delivery" />
    </React.Fragment>
  );
}
