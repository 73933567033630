import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { CustomerData } from "../../redux/actions/customerActions";
import { checkoutInit } from "../../redux/actions/checkoutActions";
import Select from "react-select";
import axios from "axios";
import {
  getTable,
  postUpdateTableOrderV1,
  postTableOrderV1,
  getChargeId,
  getOrderTypes,
  getCloverOrderType,
} from "../../utils/api";
import { getUserAll } from "../../dashboard/Functions/fetch";
import { Tip, Notes } from "../../redux/actions/cartActions";
import MultiLingualContent from "../../context/multilingualContent";
const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );

function PayButton(props) {
  const {
    apiCall,
    tableOccupied,
    checked,
    tablePaymentState: { table, is_pay_online, is_pay_restaurant },
  } = props;

  return (
    <div className="paybtns">
      <div style={{ width: "100%" }}>
        <>
          {apiCall ? (
            tableOccupied && !checked ? (
              <>
                <Link
                  to={
                    props.tablePath
                      ? `${props.tablePath}/pay-on-cash`
                      : `/${props.restaurant.id}/pay-on-cash`
                  }
                >
                  <Button variant="warning" block disabled={props.disabled}>
                    <MultiLingualContent contentID="Continue Order" />
                  </Button>
                </Link>
              </>
            ) : table ? (
              <>
                {props.restaurant.integrate !== "square" &&
                  is_pay_restaurant ? (
                  <>
                    <Link
                      to={
                        props.tablePath
                          ? `${props.tablePath}/pay-on-cash`
                          : `/${props.restaurant.id}/pay-on-cash`
                      }
                    >
                      <Button variant="warning" block disabled={props.disabled}>
                        Pay Later
                      </Button>
                    </Link>
                  </>
                ) : (
                  <></>
                )}
                {is_pay_online &&
                  is_pay_restaurant &&
                  props.restaurant.integrate !== "square" ? (
                  <div className="my-2 text-center">OR</div>
                ) : (
                  <></>
                )}
                {is_pay_online ? (
                  <>
                    <Link
                      to={
                        props.tablePath
                          ? `${props.tablePath}/pay-online`
                          : `/${props.restaurant.id}/pay-online`
                      }
                    >
                      <Button variant="warning" block disabled={props.disabled}>
                        <MultiLingualContent contentID="Pay Now" />
                      </Button>
                    </Link>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  color: "#ff4d4d",
                  fontWeight: 600,
                }}
              >
                We temporarily stopped taking orders.Please try again later.
              </p>
            )
          ) : (
            <p></p>
          )}
        </>
      </div>
    </div>
  );
}

function CartTableCart(props) {
  const [coupon_code, setCoupon] = useState("");
  const [coupon_error, setCouponMessage] = useState("");
  const [coupon_applied, setCouponApplied] = useState(true);
  const [applied_coupon_code, setAppliedCouponCode] = useState("");
  const [coupon_discount_percentage, setCouponDiscountPercentage] = useState(0);
  const [coupon_discount_amount, setCouponDiscountAmount] = useState(0);
  const [coupon_tax, setCouponTax] = useState(0);
  const [coupon_tip, setCouponTip] = useState(0);
  const [coupon_total, setCouponTotal] = useState(0);
  const [tablePaymentState, setTablePaymentState] = useState({
    table: true,
    is_pay_online: 0,
    is_pay_restaurant: 0,
  });
  const [user_data, setUser] = useState(0);

  const [tip, setTip] = useState(0);
  const [to, setTo] = useState("");
  const [tables, setTables] = useState([]);
  const [open, setOpen] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [tableStatus, setTableStatus] = useState(1);
  const [tableId, setTableId] = useState(0);
  const [type, setType] = useState("");
  const [chargeId, setChargeId] = useState("");
  const [orderType, setOrderType] = useState("");
  const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState(false);
  const [discount, setDiscount] = useState({ isDiscount: 0 });
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountData, setDiscountData] = useState({ name: "" });
  const [discountPrice, setDiscountPrice] = useState();
  const [convTotal, setConvTotal] = useState(props.totalPrice);
  const [tax, setTax] = useState(
    parseFloat(props.restaurant.totalTax * props.totalPrice).toFixed(2)
  );
  const [toTip, setToTip] = useState(parseFloat(props.tips));
  const [finalPrice, setFinalPrice] = useState(
    parseFloat(
      props.totalPrice +
      props.restaurant.totalTax * props.totalPrice +
      parseFloat(props.tips)
    ).toFixed(2)
  );
  const [tableOccupied, setTableOccupied] = useState(false);
  const [tablePrevious, setTablePrevious] = useState(0);
  let navigate = useNavigate();
  const tipsChange = (e) => {
    const list = document.getElementById("tipslist");
    var items = list.getElementsByTagName("li");
    for (var i = 0; i < items.length; ++i) {
      if (items[i].getAttribute("name") === e.target.getAttribute("name")) {
        items[i].classList.add("active");
      } else {
        items[i].classList.remove("active");
      }
    }
    if (e.target.getAttribute("name") !== "custom") {
      setTip(parseInt(e.target.getAttribute("name")));
      props.Tip(parseInt(e.target.getAttribute("name")));
    }
  };

  const handleTipChange = (e) => {
    if (e.target.value.length) {
      setTip(e.target.value);
      props.Tip(parseInt(e.target.value));
    } else {
      setTip(0);
      props.Tip(0);
    }
  };
  const notesChange = (e) => {
    props.Notes(e.target.value);
  };
  async function getRestaurantTableSettings() {
    try {
      let response = await fetch(
        `/api/v1/merchant/get/restaurant/table-order/settings/${props.restaurant.id}?tableId=${props.table.table_id}`
      );
      response = await response.json();
      console.log("response", response.data);

      if (response.data) {
        let { table, is_pay_online, is_pay_restaurant } = response.data;
        setTablePaymentState((prevState) => ({
          ...prevState,
          table,
          is_pay_online,
          is_pay_restaurant,
        }));
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    // console.log(is_coupon_discount);
    console.log("Table Cart Table");
    (async function () {
      getRestaurantTableSettings();
      const {
        table_status,
        table_id,
        table_name,
        table_orders_id,
        previous_total,
      } = props.table;
      if (!table_status && table_orders_id.length) {
        setTableOccupied(true);
      } else {
        setTableOccupied(false);
      }
      setApiCall(true);
      // console.log(previous_total);
      setTablePrevious(Number((previous_total / 100).toFixed(2)));
      const res = await getUserAll(props.restaurant.id);
      if (res[0].is_coupon_discount == 1) {
        setUser(1);
      } else {
        setUser(0);
      }
      const response = await fetch(
        `/api/v1/merchant/${props.restaurant.id}/${props.restaurant.integrate}/discount`
      );
      const json = await response.json();
      setDiscount(json);
      if (json.isDiscount) {
        setDiscountData(json.data);
        setDiscountPercent(json.percentage / 100);

        // console.log(props.totalPrice);
        // const discountprice = parseFloat(
        // props.totalPrice * (json.percentage / 100)
        // ).toFixed(2);
        // setConvTotal(props.totalPrice - discountprice);
        // const toTax = parseFloat(
        // (
        // props.restaurant.totalTax *
        // (props.totalPrice - discountprice)
        // ).toFixed(2)
        // );
        // setTax(toTax);
        // const contip = parseFloat(
        // (props.tip * (props.totalPrice - discountprice)).toFixed(2)
        // );
        // setFinalPrice(
        // parseFloat(
        // props.totalPrice - discountprice + toTax + parseFloat(props.tips)
        // ).toFixed(2)
        // );
      }
    })();
  }, []);

  const handleClose = (e) => {
    if (type === "clear") {
      setType("update");
      document.getElementById("clear").classList.remove("active");
    } else {
      setTo(`/${props.restaurant.id}/table/${tableId}/pay-on-cash`);
      setType("clear");
      document.getElementById("clear").classList.add("active");
    }
  };

  const handleUpdateOrder = async (e) => {
    setLoader(true);
    var res = await postUpdateTableOrderV1(
      props.restaurant.id,
      props.table.table_id,
      props.items,
      props.cart.tip,
      props.tax
    );
    if (Object.keys(res).length) {
      setLoader(false);
      localStorage.removeItem(props.restaurant.id);
      setOpen(true);
      setOrderId(res.id);
    }
  };
  const handleConinue = async (e) => {
    console.log(`${window.location.href.split("table")[0]}table`);
    // navigate(toDirect)
    window.location.href = `${window.location.href.split("table")[0]}table`;
  };
  const couponChange = (e) => {
    setCoupon(e.target.value);
    console.log(e.target.value);
    setCouponMessage("");
    setAppliedCouponCode("");
  };
  const couponSubmit = (e) => {
    e.preventDefault();
    if (coupon_code != "") {
      // console.log(props.customer);
      axios
        .post(`/api/integrations/get/coupon_code/${props.restaurant.id}`, {
          coupon_code,
        })
        .then((res) => {
          //const [result] = JSON.parse(JSON.stringify(res));
          //console.log(55,res.data.coupon_discount);
          if (res.data.msg == "Applied") {
            setCouponApplied(false);
            let discount = 0;
            let couponTotal = 0;
            let couponTax = 0;
            if (res.data.coupon_discount > 0) {
              discount = parseFloat(
                (props.totalPrice * res.data.coupon_discount) / 100
              ).toFixed(2);
              console.log(10, discount);
              couponTotal = props.totalPrice - discount;
              couponTax = Number(
                parseFloat(props.restaurant.totalTax * couponTotal).toFixed(2)
              );
              setCouponTax(couponTax);
              setCouponTotal(couponTotal);

              setTimeout(() => {
                props.checkoutInit({
                  subTotal: props.totalPrice,
                  tip: {
                    value: Number(
                      parseFloat(((tip / 100) * props.totalPrice).toFixed(2))
                    ),
                    percentage: tip,
                  },
                  tax: {
                    value: Number(
                      parseFloat(
                        (props.restaurant.totalTax * props.totalPrice).toFixed(
                          2
                        )
                      )
                    ),
                    percentage: props.restaurant.totalTax,
                  },
                  total: Number(
                    parseFloat(
                      props.totalPrice +
                      props.restaurant.totalTax * props.totalPrice +
                      parseFloat(props.tips)
                    ).toFixed(2)
                  ),
                  finalSubTotal: props.totalPrice - discount,
                  finalTip: {
                    value: Number(
                      parseFloat(
                        (props.totalPrice - discount) * (tip / 100)
                      ).toFixed(2)
                    ),
                    percentage: tip,
                  },
                  finalTax: {
                    value: Number(
                      parseFloat(
                        props.restaurant.totalTax *
                        (props.totalPrice - discount)
                      ).toFixed(2)
                    ),
                    percentage: props.restaurant.totalTax,
                  },
                  finalTotal: Number(
                    parseFloat(
                      couponTotal + couponTax + couponTotal * (tip / 100)
                    ).toFixed(2)
                  ),
                  discount: {
                    name: "",
                    value: discount,
                    percentage: res.data.coupon_discount,
                  },
                  couponCode: {
                    name: "",
                    code: coupon_code,
                    percentage: res.data.coupon_discount,
                  },
                });
              }, 200);
            }
            setCouponMessage(res.data.msg);
            setAppliedCouponCode(coupon_code);
            setCouponDiscountPercentage(res.data.coupon_discount);
            setCouponDiscountAmount(discount);
            setCouponApplied(true);
          } else {
            setAppliedCouponCode("");
            setCouponMessage(res.data.msg);
          }
          // setState({
          // locationData: res.data,
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };
  return (
    <div className="total-cart">
      <div className="cart-flow">
        <Card className="mt-31">
          {/* <Card.Header
 className="pl-2 d-flex align-items-center justify-content-between"
 style={{ background: "none" }}
 >
 <div>
 Pickup Time
 <br />
 <span
 style={{
 fontWeight: "500",
 padding: "1em 0",
 display: "inline-block",
 }}
 >
 <img
 style={{
 maxWidth: "22px",
 marginRight: "8px",
 verticalAlign: "sub",
 }}
 src="/clock.svg"
 />
 ASAP
 </span>
 </div>
 <div className="pick-grp">
 <div className="pick-bg">
 <div className="pick"></div>
 </div>
 </div>
 </Card.Header> */}
          <Card.Body className="d-flex justify-content-between p-2">
            <Card.Text style={{ width: "100%", display: "flex" }}>
              <img
                src="/editIcon.svg"
                style={{ maxWidth: "25px", padding: "0 5px 0 0" }}
              />
              <textarea
                col="10"
                rows="1"
                onChange={notesChange}
                /* {this.state.notes} */ placeholder="Order notes"
                style={{
                  fontWeight: "600",
                  height: "auto",
                  padding: "10px",
                  width: "100%",
                  border: "0px solid #ccc",
                  fontSize: "16px",
                  resize: "vertical",
                }}
              ></textarea>
            </Card.Text>
          </Card.Body>
          {/* {props.restaurant.is_tip ? (
 <Card.Body className="border-top" style={{ padding: "10px" }}>
 <div className="tab-group tips">
 <h6>Add tip</h6>
 <ul id="tipslist">
 <li onClick={tipsChange} className="active" name="0">
 No Tip
 </li>
 <li onClick={tipsChange} className="" name="10">
 10%
 </li>
 <li onClick={tipsChange} className="" name="15">
 15%
 </li>
 <li onClick={tipsChange} className="" name="20">
 20%
 </li>
 </ul>
 </div>
 </Card.Body>
 ) : (
 <></>
 )} */}

          {/* <Card.Body className="d-flex font-weight-medium justify-content-between p-2">
 <Card.Text>Summary</Card.Text>
 </Card.Body>
 <Card.Body className="d-flex justify-content-between p-2">
 <Card.Text style={{ fontSize: "15px", fontWeight: "500" }}>
 <img
 style={{
 maxWidth: "22px",
 marginRight: "8px",
 verticalAlign: "sub",
 }}
 src="/clock.svg"
 />{" "}
 ASAP
 </Card.Text>
 </Card.Body> */}
          {/* <Card.Body className="d-flex justify-content-between p-2 border-top border-bottom pickup">
 <Card.Text style={{ fontSize: "15px", fontWeight: "500" }}>
 {props.restaurant.id.toLowerCase() ===
 "vishnuji-ki-rasoi-festival-mela" ? (
 <a
 target="_blank"
 href={`https://www.google.com/maps/place/Marriott+Dallas+Allen+Hotel+%26+Convention+Center/@33.0920568,-96.6820153,15z/data=!4m8!3m7!1s0x0:0xbdae87adf7c0dafb!5m2!4m1!1i2!8m2!3d33.0920568!4d-96.6820153`}
 >
 <img
 style={{
 maxWidth: "22px",
 marginRight: "8px",
 verticalAlign: "sub",
 }}
 src="/walking.svg"
 />{" "}
 Pickup at Marriott Dallas Allen Hotel &amp; Convention Center
 </a>
 ) : (
 <a
 target="_blank"
 href={`https://www.google.com/maps/search/?api=1&query=${
 props.restaurant.address.address1 +
 props.restaurant.address.address2 +
 props.restaurant.address.city +
 props.restaurant.address.country
 }`}
 >
 <img
 style={{
 maxWidth: "22px",
 marginRight: "8px",
 verticalAlign: "sub",
 }}
 src="/walking.svg"
 />{" "}
 Pickup at{" "}
 {capitalizeFirstLetter(props.restaurant.address.address1)}{" "}
 {capitalizeFirstLetter(props.restaurant.address.city)}
 </a>
 )}
 </Card.Text>
 </Card.Body> */}
          {user_data == 1 && props.restaurant.isCoupon && false ? (
            <Card.Body className="coupon-field border-bottom p-2 pricing">
              <Card.Text>
                <MultiLingualContent contentID="Coupon" />:
              </Card.Text>
              <Card.Text>
                <Form>
                  <div style={{ display: "flex" }}>
                    <Form.Group className="mb-0" style={{ width: "100%" }}>
                      <Form.Control
                        onChange={couponChange}
                        type="text"
                        name="coupon_value"
                        placeholder="Enter Coupon code"
                      //value={coupon_value}
                      />
                    </Form.Group>
                    <div className="text-center">
                      <button
                        onClick={couponSubmit}
                        style={{ padding: "5px 10px" }}
                        className="btn btn-primary mb-0 ml-2"
                        type="button"
                      >
                        <MultiLingualContent contentID="Apply" />
                      </button>
                    </div>
                  </div>
                  {coupon_error ? (
                    <div style={{ padding: "5px 10px" }}>{coupon_error}</div>
                  ) : (
                    <></>
                  )}
                </Form>
              </Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}

          {tablePrevious ? (
            <Card.Body className="d-flex justify-content-between border-bottom p-2 pricing">
              <Card.Text>
                <MultiLingualContent contentID="Previous Total" />:{" "}
              </Card.Text>
              <Card.Text>${parseFloat(tablePrevious).toFixed(2)}</Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}
          <Card.Body className="d-flex justify-content-between border-bottom p-2 pricing">
            <Card.Text>
              <MultiLingualContent contentID="Subtotal" />: {props.total}{" "}
              <MultiLingualContent contentID="items" />
            </Card.Text>
            <Card.Text>${parseFloat(props.totalPrice).toFixed(2)}</Card.Text>
          </Card.Body>
          {coupon_discount_amount ? (
            <Card.Body className="d-flex justify-content-between border-bottom p-2 pricing">
              <Card.Text>
                {" "}
                <MultiLingualContent contentID="Coupon Discount" />:
              </Card.Text>
              <Card.Text>-${parseFloat(coupon_discount_amount).toFixed(2)}</Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}

          {discountPercent ? (
            <Card.Body
              className="d-flex justify-content-between p-2 pricing"
              style={{ padding: "10px" }}
            >
              <Card.Text>{discountData.name}</Card.Text>
              {/* <Card.Text>10% Discount (Mela)</Card.Text> */}

              <Card.Text>
                - ${parseFloat(props.totalPrice * discountPercent).toFixed(2)}
              </Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}
          {props.restaurant.totalTax ? (
            <Card.Body className="d-flex justify-content-between p-2 pricing">
              <Card.Text>
                <MultiLingualContent contentID="Taxes" />
              </Card.Text>
              <Card.Text>
                $
                {coupon_discount_amount
                  ? coupon_tax
                  : discountPercent
                    ? parseFloat(
                      (
                        props.restaurant.totalTax *
                        (props.totalPrice - props.totalPrice * discountPercent)
                      )
                    ).toFixed(2)
                    : parseFloat(
                      (props.restaurant.totalTax * props.totalPrice)
                    ).toFixed(2)}
              </Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}

          {props.tips !== "0.00" ? (
            <Card.Body
              className="d-flex justify-content-between p-2 pricing"
              style={{ padding: "10px" }}
            >
              <Card.Text>
                <MultiLingualContent contentID="Tips" />
              </Card.Text>

              <Card.Text>
                $
                {coupon_discount_amount
                  ? parseFloat(coupon_total * (tip / 100)).toFixed(2)
                  : discountPercent
                    ? parseFloat(
                      (props.totalPrice - props.totalPrice * discountPercent) *
                      (tip / 100)
                    ).toFixed(2)
                    : parseFloat((tip / 100) * props.totalPrice).toFixed(2)}
              </Card.Text>
              {/* <Card.Text>${parseFloat(props.tips)}</Card.Text> */}
            </Card.Body>
          ) : (
            <></>
          )}
          {tablePrevious ? (
            <Card.Body className="d-flex justify-content-between p-2 pricing">
              <Card.Text>Current Total</Card.Text>
              <Card.Text>
                $
                {coupon_discount_amount
                  ? parseFloat(
                    props.totalPrice -
                    coupon_discount_amount +
                    coupon_tax +
                    coupon_total * (tip / 100)
                  ).toFixed(2)
                  : discountPercent
                    ? parseFloat(
                      props.totalPrice -
                      props.totalPrice * discountPercent +
                      props.restaurant.totalTax *
                      (props.totalPrice -
                        props.totalPrice * discountPercent) +
                      parseFloat(
                        (
                          (props.totalPrice -
                            props.totalPrice * discountPercent) *
                          (tip / 100)
                        ).toFixed(2)
                      )
                    ).toFixed(2)
                    : parseFloat(
                      props.totalPrice +
                      props.restaurant.totalTax * props.totalPrice +
                      (props.totalPrice -
                        props.totalPrice * discountPercent) *
                      (tip / 100)
                    ).toFixed(2)}
              </Card.Text>
            </Card.Body>
          ) : (
            <></>
          )}

          <Card.Body className="d-flex font-weight-bold border-top justify-content-between p-2">
            <Card.Text>
              <MultiLingualContent contentID="Total" />
            </Card.Text>
            <Card.Text>
              $
              {(
                Number(
                  coupon_discount_amount
                    ? parseFloat(
                      props.totalPrice -
                      coupon_discount_amount +
                      coupon_tax +
                      coupon_total * (tip / 100)
                    ).toFixed(2)
                    : discountPercent
                      ? parseFloat(
                        props.totalPrice -
                        props.totalPrice * discountPercent +
                        props.restaurant.totalTax *
                        (props.totalPrice -
                          props.totalPrice * discountPercent) +
                        parseFloat(
                          (
                            (props.totalPrice -
                              props.totalPrice * discountPercent) *
                            (tip / 100)
                          ).toFixed(2)
                        )
                      ).toFixed(2)
                      : parseFloat(
                        props.totalPrice +
                        props.restaurant.totalTax * props.totalPrice +
                        (props.totalPrice -
                          props.totalPrice * discountPercent) *
                        (tip / 100)
                      ).toFixed(2)
                ) + tablePrevious
              ).toFixed(2)}
            </Card.Text>
          </Card.Body>
          <Card.Footer className="paybtns"></Card.Footer>
          <Modal show={open} size="md" aria-labelledby="ordersucess" centered>
            <Modal.Body>
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  Your Order ID
                </p>
                <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                  {" "}
                  <b>{orderId}</b>
                </p>
                <p style={{ textAlign: "center" }}>
                  <MultiLingualContent contentID="Your order has been received. Your order will be ready in 10 minutes." />
                </p>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button style={{ marginBottom: "0" }} onClick={handleConinue}>
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>
      </div>
      <PayButton
        {...props}
        apiCall={apiCall}
        tableOccupied={tableOccupied}
        checked={checked}
        tablePaymentState={tablePaymentState}
      />
      {/* <div className="paybtns">
                <div style={{ width: "100%" }}>
                    <>
                        {apiCall ? (
                            tableOccupied && !checked ? (
                                <>
                                    <Link
                                        to={
                                            props.tablePath
                                                ? `${props.tablePath}/pay-on-cash`
                                                : `/${props.restaurant.id}/pay-on-cash`
                                        }
                                    >
                                        <Button variant="warning" block disabled={props.disabled}>
                                            Continue To Order
                                        </Button>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    {props.restaurant.integrate !== "square" && (
                                        <>
                                            {" "}
                                            <Link
                                                to={
                                                    props.tablePath
                                                        ? `${props.tablePath}/pay-on-cash`
                                                        : `/${props.restaurant.id}/pay-on-cash`
                                                }
                                            >
                                                <Button
                                                    variant="warning"
                                                    block
                                                    disabled={props.disabled}
                                                >
                                                    Pay Later
                                                </Button>
                                            </Link>
                                            <div className="my-2 text-center">OR</div>
                                        </>
                                    )}

                                    <Link
                                        to={
                                            props.tablePath
                                                ? `${props.tablePath}/pay-online`
                                                : `/${props.restaurant.id}/pay-online`
                                        }
                                    >
                                        <Button variant="warning" block disabled={props.disabled}>
                                            Pay Now
                                        </Button>
                                    </Link>
                                </>
                            )
                        ) : (
                            <p></p>
                        )}
                    </>
                </div>
            </div> */}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
    checkoutInit: (data) => {
      dispatch(checkoutInit(data));
    },
    Tip: (tip) => {
      dispatch(Tip(tip));
    },
    Notes: (notes) => {
      dispatch(Notes(notes));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    items: state.cart.items,
    customer: state.customer,
    tax: state.restaurant.totalTax,
    payOnline: state.restaurant.isPayonline,
    payRestaurant: state.restaurant.isPayRestaurant,
    tablePath: state.restaurant.tablePath,
    cart: state.cart,
    tips: state.cart.tips,
    orderCart: state.cart.orderCart,
    checkout: state.checkout,
    table: state.table,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartTableCart);