import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllOrders, getUser } from "../../utils/api";
import { setOrderStatus } from "../../dashboard/Functions/fetch";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import { CustomerData } from "../../redux/actions/customerActions";
import Modal from "react-bootstrap/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import "../../dashboard/pages/pages.css";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { BiCreditCard } from "react-icons/bi";
import { SiGooglepay, SiApple } from "react-icons/si";
import BrandingHeader from "../../components/BrandingHeader";
import { GiWallet } from "react-icons/gi";
const payIcon = {
  google: <SiGooglepay style={{ fontSize: "38px", color: "#444" }} />,
  wallet: <GiWallet style={{ fontSize: "20px", color: "#444" }} />,
  apple: <SiApple style={{ fontSize: "22px", color: "#444" }} />,
  card: <BiCreditCard style={{ fontSize: "26px", color: "#444" }} />,
  null: <></>,
};

const getCookies = () => {
  return document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value),
      }),
      {}
    );
};
const capitalizeFirstLetter = (str) => {
  // console.log(str);
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
};

const UpdateButton = (props) => {
  const [ready, setready] = useState(false);
  const [close, setclose] = useState(false);

  // const foodReady = async (data) => {
  //   let action = "ready";
  //   setready(true);
  //   const res = setFoodStatus(data, action);
  // };
  // const foodClose = async (data) => {
  //   let action = "close";
  //   setclose(true);
  //   const res = setFoodStatus(data, action);
  // };
  const [type, setType] = useState("status");
  return (
    <div style={{ display: "flex" }}>
      <Form.Group controlId="formBasicSelect" className="selectbox">
        <Form.Control
          as="select"
          value={type}
          onChange={(e) => {
            // console.log("e.target.value", e.target.value);
            e.target.value && setType(e.target.value);
            e.target.value && setOrderStatus(props.data, e.target.value);
          }}
        >
          <option value="">status</option>
          <option value="processed">Processed</option>
          <option value="ready">Ready</option>
          <option value="delivered">Delivered</option>
        </Form.Control>
      </Form.Group>
    </div>
  );
};
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
}
class TodayOrderPage extends Component {
  state = {
    orders: [],
    offset: 0,
    page: 1,
    pending: true,
    user: "",
    password: "",
    isAdmin: true,
    isOpen: false,
    isReady: false,
    isClose: false,
    loader: false,
    nodata: false,
    columns: [
      {
        name: "Date",
        cell: (row) => (
          <p className="order-date">
            {row.date} <br />
            {row.orderId} <br />
            {row.CustomerName && row.CustomerPhone ? (
              <>
                <span className="order-phone">{row.CustomerName}</span> <br />
                <span className="order-phone">
                  Phone :{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#000",
                      background: "none",
                    }}
                    href={`tel:${row.CustomerPhone}`}
                  >
                    {row.CustomerPhone}
                  </a>
                </span>
              </>
            ) : row.CustomerName ? (
              <>
                <span className="order-phone">{row.CustomerName}</span>{" "}
              </>
            ) : row.CustomerPhone ? (
              <>
                <span className="order-phone">
                  Phone :{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#000",
                      background: "none",
                    }}
                    href={`tel:${row.CustomerPhone}`}
                  >
                    {row.CustomerPhone}
                  </a>
                </span>
              </>
            ) : (
              <></>
            )}
          </p>
        ),
      },
      {
        name: "Line Items",
        cell: (row) => (
          <ul>
            {row.lineItem.map((item) => {
              // console.log(item);
              return (
                <li key={item.id}>
                  {item.name} <br />{" "}
                  {/* {capitalizeFirstLetter(item.name)} <br />{" "} */}
                  <span>
                    $ {item.price / 100} * {item.quantity}
                  </span>
                </li>
              );
            })}
          </ul>
        ),
      },
      {
        name: "Total",
        selector: (row) => (
          <div>
            Total: $ {parseFloat(row.total / 100).toFixed(2)}
            {/* <br />
            <span style={{ padding: "5px 0 0", display: "inline-block" }}>
              Subtotal: $ {parseFloat(row.subTotal / 100).toFixed(2)}
            </span> */}
          </div>
        ),
      },
      {
        name: "Status",
        cell: (row) => (
          <>
            <p>
              {row.payment === "Not Paid" ? (
                <span id="not-paid">Not Paid 👎</span>
              ) : (
                <span id="paid">
                  Paid <span>{payIcon[row.payment_type]}</span>
                </span>
              )}
            </p>

            <div style={{ display: "block", width: "100%" }}>
              {/* <UpdateButton
                data={{
                  ...row,
                  resId: this.props.restaurantId,
                  integrate: this.props.restaurant.integrate,
                }}
              /> */}
            </div>
          </>
        ),
      },
      // {
      // name: "Payment",
      // selector: (row) => row.payment,
      // },
      {
        name: "Details",
        cell: (row) => (
          <a onClick={() => this.openModal(row)} id={row.id}>
            Details
          </a>
        ),
      },
    ],
  };

  openModal = async (row) => {
    // console.log(e.target.id);
    console.log(row);
    // const data = await getReceipt(e.target.id);
    this.setState({
      data: row,
      isOpen: true,
    });
  };
  closeModal = () => this.setState({ isOpen: false });

  async componentDidMount() {
    this.setState({ loader: true });
    const items = await getAllOrders(
      this.props.restaurantId,
      40,
      this.state.offset,
      this.props.restaurantEle
    );
    try {
      if (items.length) {
        items.forEach((item) => {
          var data = {};
          // console.log(item);
          data["date"] = item["createdAt"];
          data["id"] = item["orderId"];
          data["orderId"] = `ID : ${item["orderId"]}`;
          data["subTotal"] = item["subTotal"];
          data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
          data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
            ? `${formatPhoneNumber(item["Phone"])}`
            : "";
          data["total"] = `${item["total"]}`;
          data["tip"] = item["tip"];
          data["payment"] = `${item["payment"]}`;
          data["payment_type"] = `${item["payment_type"]}`;
          data["status"] = item["status"];
          data["link"] = `/r/${item["orderId"]}`;
          data["lineItem"] = item["items"];
          data["tax"] = item["tax"];
          data["notes"] = item["notes"];
          this.setState({
            orders: [...this.state.orders, data],
          });
          this.setState({ loader: false });
        });
      } else {
        this.setState({
          orders: [],
          loader: false,
          nodata: true,
        });
      }
      this.setState({
        pending: false,
      });
    } catch (error) {
      console.log(error);
    }

    const itemsAfter = await getAllOrders(
      this.props.restaurantId,
      9999999999,
      40,
      this.props.restaurantEle
    );
    if (itemsAfter.length) {
      itemsAfter.forEach((item) => {
        var data = {};

        data["date"] = item["createdAt"];
        data["subTotal"] = item["subTotal"];
        data["id"] = item["orderId"];
        data["tip"] = item["tip"];
        data["orderId"] = `ID : ${item["orderId"]}`;
        data["CustomerName"] = item["name"] ? `Name : ${item["name"]}` : "";
        data["payment"] = `${item["payment"]}`;
        data["payment_type"] = `${item["payment_type"]}`;
        data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
          ? `${formatPhoneNumber(item["Phone"])}`
          : "";
        data["total"] = `${item["total"]}`;
        // data["total"] = `$ ${item["total"]}`;
        data["status"] = item["status"];
        data["link"] = `/r/${item["orderId"]}`;
        data["lineItem"] = item["items"];
        data["tax"] = item["tax"];
        data["notes"] = item["notes"];
        this.setState({
          orders: [...this.state.orders, data],
        });
      });
    } else {
      this.setState({
        orders: [...this.state.orders],
      });
    }
    setInterval(async () => {
      const items = await getAllOrders(
        this.props.restaurantId,
        9999999999,
        0,
        this.props.restaurantEle
      );
      try {
        if (items.length !== this.state.orders.length) {
          this.setState({
            orders: [],
          });
          if (items.length) {
            items.forEach((item) => {
              var data = {};
              // data["date"] = `${moment(item["createdAt"]).format(
              // "DD-MMMM-YYYY HH:mm a"
              // )}`;
              data["date"] = item["createdAt"];
              data["id"] = item["orderId"];
              data["orderId"] = `ID : ${item["orderId"]}`;
              data["tip"] = item["tip"];
              data["CustomerName"] = item["name"]
                ? `Name : ${item["name"]}`
                : "";
              data["subTotal"] = item["subTotal"];
              data["payment"] = `${item["payment"]}`;
              data["payment_type"] = `${item["payment_type"]}`;
              data["CustomerPhone"] = formatPhoneNumber(item["Phone"])
                ? `${formatPhoneNumber(item["Phone"])}`
                : "";
              data["total"] = `${item["total"]}`;
              // data["total"] = `$ ${item["total"]}`;

              data["status"] = item["status"];
              data["link"] = `/r/${item["orderId"]}`;
              data["lineItem"] = item["items"];
              data["tax"] = item["tax"];
              data["notes"] = item["notes"];
              this.setState({
                orders: [...this.state.orders, data],
              });
            });
          } else {
            this.setState({
              orders: [],
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 4000);
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(this.state.user);
    let res = await getUser(
      this.props.restaurantId,
      this.state.user,
      this.state.password
    );
    if (res) {
      this.setState({ isAdmin: true });
      this.props.CustomerData({ isAdmin: true });
      var today = new Date();
      var expire = new Date();
      expire.setTime(today.getTime() + 3600000 * 24 * 14);
      document.cookie =
        "hasaccess" +
        "=" +
        encodeURI(
          JSON.stringify({ resId: this.props.restaurantId, admin: true })
        ) +
        ";expires=" +
        expire.toGMTString();
      // window.location.reload(false);
    }
  };
  render() {
    return (
      <>
        {/* <BrandingHeader/> */}
        <div className="full-width">
          <Container>
            {this.state.loader ? (
              <div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    // minHeight: "100vh",
                    transition: "0.5s ease-in-out 0s",
                    height: "100%",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ScaleLoader size={12} color="#ec7211" />
                </div>
              </div>
            ) : this.state.nodata ? (
              <div style={{ textAlign: "center" }}>
                <h5 style={{ lineHeight: "1.5em", fontSize: "22px" }}>
                  {/* Store Closed <br /> */}
                  There are no online orders to display
                </h5>
              </div>
            ) : (
              <div
                className="table-group orders-dashboard orders-mobile"
                style={{ margin: "0" }}
              >
                {/* <div
style={{
maxWidth: "1140px",
margin: "0 auto",
padding: "0 10px 1em",
}}
>
<h4>
<i className="fa fa-file-text" style={{ fontSize: "25px" }}></i>{" "}
Orders{" "}
</h4>
</div> */}

                <Container
                  style={{ minHeight: "auto", marginTop: "6 rem !important" }}
                >
                  <h3>
                    <i class="fa fa-address-card-o"></i> Orders
                  </h3>
                  <DataTableExtensions
                    columns={this.state.columns}
                    data={this.state.orders}
                    export={false}
                    filterPlaceholder="Search Orders"
                    print={false}
                  >
                    <DataTable
                      paginationPerPage={10}
                      highlightOnHover={true}
                      paginationRowsPerPageOptions={[10, 20, 25, 30]}
                      columns={this.state.columns}
                      data={this.state.orders}
                      pagination
                      progressPending={this.state.pending}
                    // progressComponent={<BounceLoader color="#ec7211" />}
                    // onChangePage={this.handlePageChange}
                    />
                  </DataTableExtensions>
                </Container>
              </div>
            )}
            {this.state.isOpen ? (
              <Modal
                dialogClassName="receipt-model1"
                show={this.state.isOpen}
                onHide={this.closeModal}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div id="invoice-POS">
                    <center id="top">
                      {/*  <div className="logo">
                    <img
                      style={{ width: "30%" }}
                      src={this.props.restaurant.restaurant.logo}
                    />
                  </div> */}
                      <div className="info">
                        <h2>{this.props.restaurant.restaurant.name}</h2>
                        <p>
                          {this.props.restaurant.address.address1}{" "}
                          {this.props.restaurant.address.address2}
                          <br />
                          {this.props.restaurant.address.city},{" "}
                          {this.props.restaurant.address.state},{" "}
                          {this.props.restaurant.address.country},{" "}
                          {this.props.restaurant.address.zip}
                          <br />
                          {this.props.restaurant.address.phoneNumber}
                          <br />
                          {this.props.restaurant.restaurant.website}
                        </p>
                      </div>
                    </center>

                    <div id="bot">
                      <div id="table">
                        <table>
                          <tbody>
                            <tr className="tabletitle">
                              <td className="item">
                                <h2>Item</h2>
                              </td>
                              <td className="Hours">
                                <h2>Qty</h2>
                              </td>
                              <td className="Rate">
                                <h2>Sub Total</h2>
                              </td>
                            </tr>
                            {this.state.data.lineItem.map((item) => {
                              return (
                                <tr className="service" key={item.id}>
                                  <td className="tableitem">
                                    <p className="itemtext">{item.name}</p>
                                  </td>
                                  <td className="tableitem">
                                    <p className="itemtext">{item.quantity}</p>
                                  </td>
                                  <td className="tableitem">
                                    <p className="itemtext">
                                      {" "}
                                      $ {(item.price / 100) * item.quantity}
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}

                            <tr className="tabletitle">
                              <td className="Rate">
                                <h2>Subtotal</h2>
                              </td>
                              <td></td>
                              <td className="payment">
                                <h2> $ {this.state.data.subTotal / 100}</h2>
                              </td>
                            </tr>
                            <tr className="tabletitle">
                              <td className="Rate">
                                <h2>Total Tax</h2>
                              </td>
                              <td></td>
                              <td className="payment">
                                <h2> $ {this.state.data.tax / 100}</h2>
                              </td>
                            </tr>

                            <tr className="tabletitle">
                              <td className="Rate">
                                <h2>Order Total</h2>
                              </td>
                              <td></td>
                              <td className="payment">
                                <h2> $ {this.state.data.total / 100}</h2>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="additional">
                          {this.state.data.date} <br />
                          {this.state.data.notes ? (
                            <>"{this.state.data.notes}"</>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                {/* <Modal.Footer>
 <Button variant="secondary" onClick={this.closeModal}>
 Close
 </Button>
 </Modal.Footer> */}
              </Modal>
            ) : (
              <></>
            )}
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerId: state.customer.id,
    customer: state.customer,
    restaurantId: state.restaurant.id,
    restaurant: state.restaurant,
    restaurantEle: state.restaurant.items.elements,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TodayOrderPage);
