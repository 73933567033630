import { toast } from "react-toastify";

export async function getOrders(customerId) {
  const res = await fetch(
    `/api/v1/customer/${customerId}/orders`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}

export async function getTableOrders(customerId) {
  const res = await fetch(
    `/api/v1/customer/${customerId}/table-orders`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = await res.json();
  // console.log(json);
  if (res.status === 200) {
    return json;
  } else {
    toast.error(json.msg);
  }
}
