import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import ScaleLoader from "react-spinners/ScaleLoader";
import { async } from "q";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
const hideCss = {
  pointerEvents: "none",
  opacity: "0.5",
};
const TableDineIn = (props) => {
  const [tableData, SetTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [openAddTable, setOpenAddTable] = useState(false);
  const [bulkAdd, setBulkAdd] = useState(false);
  const [tableCount, setTableCount] = useState(false);
  const [countLimit, setCountLimit] = useState(false);
  const [tableActivated, setTableActivated] = useState(false);
  const [tableDup, setTableDup] = useState(false);
  const [isDisabledAddTable, setIsDisabledAddTable] = useState(true);
  const [isTabledisabled, setIsTabledisabled] = useState(false);
  const [tableName, setTableName] = useState();
  const [interval, setIntervale] = useState(true);

  useEffect(() => {
    (async function () {
      console.log("one time");
      return updateTableState();
    })();
    const intervalState = setInterval(() => {
      // console.log("interval");
      setIntervale(!interval);
    }, 10000);
    return () => clearInterval(intervalState);
  }, [interval]);
  const columns = [
    // {
    // name: "Id",
    // cell: (row) => <div>{row.id}</div>,
    // },
    {
      name: "Table Name",
      cell: (row) => (
        <div className="table-title">
          {window.innerWidth > 767 ? (
            <div>{row.tableName}</div>
          ) : (
            <div>
              <div>Table :</div> <div>{row.tableName}</div>
            </div>
          )}
        </div>
      ),
    },

    {
      name: "Status",
      cell: (row) => (
        <div className="table-title">
          {window.innerWidth > 767 ? (
            <>
              <div
                style={
                  row.tableStatus && row.availability
                    ? {
                        backgroundColor: " #cce6cc",
                        padding: "4px 8px",
                        borderRadius: "7px",
                        color: "#4da64d",
                        fontSize: "14px",
                        fontWeight: "600",
                      }
                    : {
                        backgroundColor: " #fbeeef",
                        padding: "4px 8px",
                        borderRadius: "7px",
                        color: "#d76a6f",
                        fontSize: "14px",
                        fontWeight: "600",
                      }
                }
              >
                {row.tableStatus && row.availability
                  ? "Available"
                  : "Not Available"}
              </div>
            </>
          ) : (
            <div>
              <div>Status :</div>

              <div
                style={
                  row.tableStatus && row.availability
                    ? {
                        backgroundColor: " #cce6cc",
                        padding: "4px 8px",
                        borderRadius: "7px",
                        color: "#4da64d",
                        fontSize: "14px",
                        fontWeight: "600",
                      }
                    : {
                        backgroundColor: " #fbeeef",
                        padding: "4px 8px",
                        borderRadius: "7px",
                        color: "#d76a6f",
                        fontSize: "14px",
                        fontWeight: "600",
                      }
                }
              >
                {row.tableStatus && row.availability
                  ? "Available"
                  : "Not Available"}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      name: "ON/OFF",
      cell: (row) => (
        <div className="table-title">
          {window.innerWidth > 767 ? (
            <>
              {/* <div>{row.tableStatus ? "Avaiable" : "Not Avaiable"}</div> */}
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  onChange={async (e) => availabilityTable(e.target.id)}
                  id={row.id}
                  defaultChecked={row.availability}
                  // checked
                />
                <span className="switch" />
              </label>
            </>
          ) : (
            <div>
              {/* <div>{row.tableStatus ? "Avaiable" : "Not Avaiable"}</div> */}
              <div>ON/OFF: </div>
              <div>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    onChange={async (e) => availabilityTable(e.target.id)}
                    id={row.id}
                    defaultChecked={row.availability}
                    // checked
                  />
                  <span className="switch" />
                </label>
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Edit Status",
      cell: (row) => (
        <div className="table-title">
          {window.innerWidth > 767 ? (
            <div>
              <Button
                // style={{ width: "70%" }}
                tableId={row.id}
                onClick={() => handleTableAvailable(row.id)}
                disabled={row.tableStatus}
              >
                Make Available
              </Button>
            </div>
          ) : (
            <>
              <div>
                <div>Change Status: </div>

                <div>
                  <Button
                    // style={{ width: "70%" }}
                    tableId={row.id}
                    onClick={() => handleTableAvailable(row.id)}
                    disabled={row.tableStatus}
                  >
                    Make Available
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      name: "Qr code",
      cell: (row) => (
        <div className="table-title">
          {window.innerWidth > 767 ? (
            <div>
              <Button
                style={{ width: "100%" }}
                element={row.id}
                onClick={() => handleDownload(row.id, row.tableName)}
              >
                Download
              </Button>
            </div>
          ) : (
            <div>
              <div>Download: </div>
              <div>
                <Button
                  style={{ width: "100%" }}
                  element={row.id}
                  onClick={() => handleDownload(row.id, row.tableName)}
                >
                  Download
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];
  const handleDownload = async (tableId, tableName) => {
    document.querySelector(`[element="${tableId}"]`).innerHTML = "Downloading";
    let url = `/qrcode/generate/${props.restaurant.id}/table/${tableId}`;
    if (["simmer-tech-inc", "qr-code-internal"].includes(
      props.restaurant.id.toLowerCase()
    )) {
      url = `/qrcode/generate/${
        props.restaurant.id
      }/table/${tableId}?staticTable=${true}`;
    }
    const rawAjaxCall = await fetch(url);
    // const rawAjaxCall = await fetch(
    //   `/qrcode/generate/${props.restaurant.id}/table/${tableId}`
    // );
    const json = await rawAjaxCall.json();
    if (json && json.data) {
      const blobImage = json.data;
      // const blobImage = `data:image/png;base64,${json.data}`;
      const data = Uint8Array.from(json.bufferData.data);
      const content = new Blob([data.buffer], { type: "application/pdf" });
      const fileName = tableName.trim().split(" ").join("-");
      fileDownload(content, `${fileName}.pdf`);
      document.querySelector(`[element="${tableId}"]`).innerHTML = "Download";
    }
    // console.log(props.restaurant.id, tableId)
  };
  const updateTableState = async () => {
    try {
      //TODO TESTING
      // setPending(true);
      const rawAjaxCall = await fetch(
        `/api/v1/merchant/get/table/${props.restaurant.id}/${props.restaurant.integrate}`
      );
      const josnData = await rawAjaxCall.json();
      if (!josnData.data) {
        SetTableData([]);
        setPending(false);
        return 0;
      }
      const tableData = josnData.data;
      const toSetTable = tableData.map((item) => {
        return {
          id: item.id,
          tableNum: item.tables_id,
          tableName: item.name,
          tableStatus: Boolean(item.available),
          orderId: item.clover_order_id,
          availability: item.availability,
        };
      });
      // console.log(toSetTable);
      SetTableData(toSetTable);
      setTableActivated(Boolean(josnData.tableActivated));
      setIsTabledisabled(!josnData.tableActivated);
      setPending(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleTableAvailable = async (ID) => {
    // console.log(ID, ORDER_ID, ORDER_IDS);
    try {
      const rawAjaxCall = await fetch(
        `/api/v1/merchant/post/table/status/${props.restaurant.id}/${props.restaurant.integrate}?id=${ID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responded = await rawAjaxCall.json();
      if (responded) {
        updateTableState();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddTable = async () => {
    if (tableName) {
      try {
        const rawAjaxCall = await fetch(
          `/api/v1/merchant/get/table/name/${props.restaurant.id}/${props.restaurant.integrate}?name=${tableName}`
        );
        const isUniqueName = await rawAjaxCall.json();
        if (isUniqueName) {
          addTable(tableName);
        } else {
          document.getElementById("tableNameField").style.boxShadow =
            "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
          setTableDup(true);
          setIsDisabledAddTable(true);
        }
        // updateTableState();
      } catch (error) {
        console.log(error);
      }
    } else if (tableCount > 1) {
      addTable();
    } else {
      setIsDisabledAddTable(true);
    }
  };
  const addTable = async (name) => {
    try {
      let url = `/api/v1/merchant/add/table/${props.restaurant.id}/${props.restaurant.integrate}?name=${name}`;
      if (tableCount > 1)
        url = `/api/v1/merchant/add/table/${props.restaurant.id}/${props.restaurant.integrate}?count=${tableCount}`;
      const rawAjaxCall = await fetch(url);
      const josnData = await rawAjaxCall.json();
      updateTableState();
      setOpenAddTable(false);
      toast.success(`Table Added`);
    } catch (error) {
      toast.error(`Please try again later!`);
      console.log(error);
    }
  };
  const clearTable = async (ID) => {
    try {
      console.log(ID);
      const rawAjaxCall = await fetch(
        `/api/v1/merchant/update/table/${props.restaurant.id}/${props.restaurant.integrate}?id=${ID}`
      );
      updateTableState();
    } catch (error) {
      console.log(error);
    }
  };
  const availabilityTable = async (ID) => {
    try {
      console.log(ID);
      const rawAjaxCall = await fetch(
        `/api/v1/merchant/availability/table/${props.restaurant.id}/${props.restaurant.integrate}?id=${ID}`
      );
      updateTableState();
    } catch (error) {
      console.log(error);
    }
  };
  const closeAddTable = () => {
    setOpenAddTable(false);
    setBulkAdd(false);
  };
  const handleTable = (e) => {
    setTableName(e.target.value);
    document.getElementById("tableNameField").style.boxShadow = "";
    setTableDup(false);
    setIsDisabledAddTable(false);
  };
  const handleCountTable = (e) => {
    let countValue = e.target.value;
    if (
      countValue < 100 ||
      ["simmer-tech-inc", "qr-code-internal"].includes(
        props.restaurant.id.toLowerCase()
      )
    ) {
      setTableCount(e.target.value);
      setCountLimit(false);
      setIsDisabledAddTable(false);
    } else {
      setIsDisabledAddTable(true);
      setCountLimit(true);
    }
    // console.log(tableCount);
    // document.getElementById("tableNameField").style.boxShadow = "";
    // setTableDup(false);
    // setIsDisabledAddTable(false);
  };
  const tableActivation = async () => {
    try {
      const rawAjaxCall = await fetch(
        `/api/v1/merchant/update/table/activation/${props.restaurant.id}/${props.restaurant.integrate}`
      );
      updateTableState();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {" "}
      <div
        className="table-dine table-group orders-dashboard orders-mobile"
        style={{ margin: "3em" }}
      >
        <Container style={{ minHeight: "auto", padding: "0" }}>
          <div
            className="addbtn-dine"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              className="mt-3 mb-2 mx-2"
              style={{ borderRadius: "3px" }}
              onClick={() => setOpenAddTable(true)}
            >
              Add Table
            </Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {tableActivated ? (
                <p style={{ margin: "0 8px 12px 0" }}>De-activate</p>
              ) : (
                <p style={{ margin: "0 8px 12px 0" }}>Activate</p>
              )}

              <label className="toggle-switch">
                <input
                  type="checkbox"
                  onChange={tableActivation}
                  id="tableActivation"
                  // defaultChecked={tableActivated}
                  checked={tableActivated}
                />
                <span className="switch" />
              </label>
            </div>
          </div>
          <div
            // style={hideCss}
            style={!tableActivated ? hideCss : {}}
          >
            <DataTable
              paginationPerPage={10}
              highlightOnHover={true}
              paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
              columns={columns}
              data={tableData}
              // pagination
              progressPending={pending}
              progressComponent={<ScaleLoader size={12} color="#ec7211" />}
              // onChangePage={this.handlePageChange}
            />
          </div>
        </Container>
      </div>
      {openAddTable ? (
        <Modal
          dialogClassName="del-model del-item"
          show={openAddTable}
          onHide={closeAddTable}
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            {bulkAdd ? (
              <>
                <h5>Enter the number of tables to add</h5>
                <FormControl
                  placeholder="Count"
                  onChange={handleCountTable}
                  type="number"
                  size="sm"
                  id="tableCountField"
                  maxLength={25}
                  // onKeyPress={(e) => {
                  // tableNum <= 400 && e.preventDefault();
                  // }}
                />
                {countLimit ? (
                  <p style={{ color: "red" }}>
                    Please the enter number less than 100
                  </p>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {" "}
                <h5>Provide Name for Table</h5>
                <FormControl
                  placeholder="Name"
                  onChange={handleTable}
                  type="text"
                  size="sm"
                  id="tableNameField"
                  maxLength={25}
                  // onKeyPress={(e) => {
                  // tableNum <= 400 && e.preventDefault();
                  // }}
                />
                {tableDup ? (
                  <p style={{ color: "red" }}>Name Aleady Exists</p>
                ) : (
                  <></>
                )}
                <div>
                  <Button
                    onClick={() => {
                      setBulkAdd(true);
                    }}
                  >
                    Bulk Add
                  </Button>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ background: "transparent", color: "#000", border: "0" }}
              onClick={closeAddTable}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={handleAddTable}
              disabled={isDisabledAddTable}
            >
              Add Table
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
  };
};

export default connect(mapStateToProps)(TableDineIn);
