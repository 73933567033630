import React from "react";
import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button, Modal } from "react-bootstrap";
import CustomButtonGroup from "./CustomButtonGroup";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import { getConditionalModifierGroups, getModifierGroups } from "../utils/api";
import { AddWithModifier } from "../redux/actions/cartActions";
import { CgShoppingCart } from "react-icons/cg";

function PopularDeliveryCard(props) {
  const [choosedModifiers, setChoosedModifiers] = useState({});
  const [loader, setLoader] = useState(false);
  const [popular, setPopular] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [show, setShow] = useState(false);
  const [modifiers, setModifiers] = useState({});
  const [modSelect, setModSelect] = useState(false);
  const [allmodifiers, setAllModifiers] = useState({});
  const [limitmodifiers, setLimitModifiers] = useState({});
  const [instructions, setInstructions] = useState("");

  const handleClose = () => {
    setModifiers({});
    setChoosedModifiers({});
    setAllModifiers({});
    setShow(false);
    setModSelect(false);
  };
  const handleInstructions = (e) => {
    setInstructions(e.target.value);
  };

  let itemTotal = 0;
  {
    props.orderCart
      .filter((item) => item.productId === props.id)
      .map((item, i) => {
        itemTotal = itemTotal + item.num;
      });
  }
  // const unique = [...new Set(data.map(item => item.group))]; // [ 'A', 'B']
  // console.log(props);
  async function getModifiers(group) {
    setLoader(true);
    const data = await getModifierGroups(
      props.restaurant.id,
      Object.keys(group)[0],
      Object.values(group)[0],
      props.restaurant.integrate,
      props.restaurant.language,
    );
    setModifiers((prevState) => ({
      ...prevState,
      [Object.keys(group)[0]]: data,
    }));
    Object.values(data).forEach((item) => {
      item.forEach((ite) => {
        if (ite.default) {
          const modData = {
            [ite.id]: {
              modifier_name: ite.name,
              modifier_price: ite.price,
              modifier_id: ite.id,
              modifier_group_id: ite.groupId,
            },
          };
          setChoosedModifiers((prevState) => ({
            ...prevState,
            ...modData,
          }));
        }
      });
    });
    setLoader(false);
  }

  const handleShow = async () => {
    setShow(true);
    if (
      props.modifierGroups.length
      // &&
      // props.restaurant.integrate === "custom"
    ) {
      // console.log(props.modifierGroups)
      let promise = Promise.resolve();

      props.modifierGroups.forEach((group, index) => {
        promise = promise.then(() => {
          // console.log(group);
          return getModifiers(group);
        });
      });
    }
  };
  const validateRequired = () => {
    let forms = document.querySelectorAll(`form[required]`);
    for (let form of forms) {
      if (!form.querySelectorAll(`:checked`).length) {
        document.getElementById("required-modifier").style.display = "block";
        return false;
      }
    }
    document.getElementById("required-modifier").style.display = "none";
    return true;
  };
  const handleChangeCheck = async (e) => {
    var limit = e.target.getAttribute("limit");

    var limitMod = document.querySelectorAll(
      `[type="checkbox"][name="${e.target.getAttribute("name")}"]:checked`
    ).length;
    var Checked = document.querySelectorAll(
      `[type="checkbox"][name="${e.target.getAttribute("name")}"]`
    );
    for (var i = 0, check; (check = Checked[i]); i++) {
      if (limitMod === Number(limit)) {
        console.log("Limit");
        if (!check.checked) {
          check.disabled = !(!e.target.checked || check === e.target);
        }
      } else {
        if (!check.checked) {
          check.disabled = false;
        }
      }
    }

    var data = {};
    if (
      document.querySelectorAll(
        `[type="checkbox"][name="${e.target.getAttribute("name")}"]:checked`
      ).length <= limit
    ) {
      var items = document.querySelectorAll(
        `[type="checkbox"][name="${e.target.getAttribute("name")}"]:checked`
      );
      items.forEach((item) => {
        var modifier_id = item.value;
        var modifier_name = item.getAttribute("modifier_name");
        var modifier_price = item.getAttribute("price");
        var modifier_group_id = item.getAttribute("modifier_id");

        data[modifier_id] = {
          modifier_name: modifier_name,
          modifier_price: modifier_price,
          modifier_id: modifier_id,
          modifier_group_id: modifier_group_id,
        };
        // console.log(data)

        setChoosedModifiers((prevState) => ({
          ...prevState,
          ...data,
        }));
      });
    } else {
      console.log("exceed");
      e.target.checked = false;
    }
    if (Object.keys(choosedModifiers).includes(e.target.value)) {
      // console.log(choosedModifiers);
      var tempModifier = { ...choosedModifiers };
      delete tempModifier[e.target.value];
      setChoosedModifiers(tempModifier);
    }
    setModSelect(true);
  };
  const handleChange = async (e) => {
    var modifier_id = e.target.value;
    var modifier_name = e.target.getAttribute("modifier_name");
    var modifier_price = e.target.getAttribute("price");
    var modifier_group_id = e.target.getAttribute("modifier_id");
    var conditional_modifier_id = e.target.getAttribute("conditional_modifier");
    var appliedModifiers = { ...modifiers };
    var allmodifiersList = { ...allmodifiers };
    allmodifiersList[modifier_group_id] = modifier_id;
    setAllModifiers((prevState) => ({
      ...prevState,
      [modifier_group_id]: modifier_id,
    }));
    if (conditional_modifier_id) {
      var conditional_modifiers = await getConditionalModifierGroups(
        props.restaurant.id,
        conditional_modifier_id,
        modifier_id,
        props.restaurant.integrate
      );
      var tem = {};
      tem[conditional_modifiers.name] = JSON.parse(
        conditional_modifiers["modifiers"]
      );

      appliedModifiers[conditional_modifier_id] = tem;
      setModifiers((prevState) => ({
        ...prevState,
        [conditional_modifier_id]: tem,
      }));
    }
    var data = {};
    // console.log(allmodifiersList, appliedModifiers);
    Object.entries(allmodifiersList).forEach(([key, value]) => {
      // console.log(modifier_group_id, key, value);
      var temData = {};
      if (modifier_group_id != key) {
        try {
          // console.log("Try", Object.values(appliedModifiers[key])[0])
          temData[key] = {
            modifier_name: Object.values(appliedModifiers[key])[0].filter(
              (it) => it.id == value
            ).length
              ? Object.values(appliedModifiers[key])[0].filter(
                  (it) => it.id == value
                )[0]["name"]
              : Object.values(appliedModifiers[key])[0][0]["name"],
            modifier_price: Object.values(appliedModifiers[key])[0].filter(
              (it) => it.id == value
            ).length
              ? Object.values(appliedModifiers[key])[0].filter(
                  (it) => it.id == value
                )[0]["price"]
              : Object.values(appliedModifiers[key])[0][0]["price"],
            modifier_id: Object.values(appliedModifiers[key])[0].filter(
              (it) => it.id == value
            ).length
              ? Object.values(appliedModifiers[key])[0].filter(
                  (it) => it.id == value
                )[0]["id"]
              : Object.values(appliedModifiers[key])[0][0]["id"],
            modifier_group_id: Object.values(appliedModifiers[key])[0].filter(
              (it) => it.id == value
            ).length
              ? Object.values(appliedModifiers[key])[0].filter(
                  (it) => it.id == value
                )[0]["groupId"]
              : Object.values(appliedModifiers[key])[0][0]["groupId"],
          };
        } catch (e) {
          // console.log("Catch")
          temData[key] = {
            modifier_name: Object.values(appliedModifiers[key])[0][0]["name"],
            modifier_price: Object.values(appliedModifiers[key])[0][0]["price"],
            modifier_id: Object.values(appliedModifiers[key])[0][0]["id"],
            modifier_group_id: Object.values(appliedModifiers[key])[0][0][
              "groupId"
            ],
          };
        }
      }
      // console.log("temData =>", temData)
      setChoosedModifiers((prevState) => ({
        ...prevState,
        ...temData,
      }));
    });
    data[modifier_group_id] = {
      modifier_name: modifier_name,
      modifier_price: modifier_price,
      modifier_id: modifier_id,
      modifier_group_id: modifier_group_id,
    };

    setChoosedModifiers((prevState) => ({
      ...prevState,
      ...data,
    }));
    setModSelect(true);
  };

  return (
    <>
      <Col md={6} xs={12} sm={12} onClick={handleShow} className="naancurry">
        <div className="menu-lists-item">
          <div className="menu-lists-item-left">
            <div className="menu-lists-item-sub">
              {props.title.includes("(") ? (
                <>
                  <h2 style={{ fontSize: "16px" }}>
                    {props.title}
                    {/* {props.title.split("(")[0].split(")")[0]} */}
                  </h2>{" "}
                  <p
                    style={{
                      position: "relative",
                      bottom: "0",
                      margin: "0px",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    {props.price
                      ? `$ ${parseFloat(props.price).toFixed(2)}`
                      : ""}
                  </p>
                </>
              ) : (
                <>
                  <h2>{props.title}</h2>

                  <p
                    style={{
                      position: "relative",
                      bottom: "0",
                      margin: "0px",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    {props.price
                      ? `$ ${parseFloat(props.price).toFixed(2)}`
                      : ""}
                    {/* $ {props.price} */}
                  </p>
                  {/* <p>{props.description}</p> */}
                </>
              )}
              {props.restaurant.isOpen ? (
                <div className="addtocartbtn">
                  <p>+</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="menu-lists-item-right">
            <img src={props.img} alt={props.title} />
            {itemTotal ? (
              <div className="item-no">
                <CgShoppingCart
                  style={{
                    verticalAlign: "-3px",
                    fontSize: "17px",
                    marginRight: "4px",
                  }}
                />
                {itemTotal}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Col>

      {show ? (
        <>
          <Modal
            centered
            animation={true}
            dialogClassName="addcartpop"
            show={show}
            onHide={handleClose}
          >
            <button type="button" id="clo" onClick={handleClose} class="close">
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
            </button>
            <Modal.Body>
              <Modal.Header
                style={{ backgroundImage: "url(" + props.img + ")" }}
              >
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <div
                style={{ padding: "15px", background: "rgb(239, 239, 239)" }}
              >
                <p style={{ margin: "0", fontWeight: "600", fontSize: "20px" }}>
                  {props.title}
                </p>
                <p
                  style={{
                    margin: "8px 0 0",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  {props.price ? `$ ${parseFloat(props.price).toFixed(2)}` : ""}
                  {/* ${props.price} */}
                </p>
                <p
                  className="description"
                  style={{
                    padding: "10px 0 0",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {props.description}
                </p>
                {/* Modifiers */}
                {/* {console.log(modifiers)} */}

                {(Object.keys(modifiers).length > 0 &&
                  props.restaurant.integrate === "custom") ||
                true ? (
                  <>
                    {Object.keys(modifiers).map((id, i) => {
                      return (
                        <React.Fragment key={i}>
                          <h2 style={{ fontSize: "22px", fontWeight: "700" }}>
                            {Object.keys(modifiers[id])[0]}{" "}
                            {console.log(
                              "gands",
                              Object.values(modifiers[id])[0][0]["required"]
                            )}
                            {/* {console.log(Object.values(modifiers[id])[0])} */}
                            {Object.values(modifiers[id])[0][0]["limit"] ? (
                              <span className="choose">{`Choose up to ${
                                Object.values(modifiers[id])[0][0]["limit"]
                              } of these items`}</span>
                            ) : (
                              <span className="choose">{`Choose ${
                                Object.values(modifiers[id])[0][0]["type"] ===
                                "radio"
                                  ? "one"
                                  : "any"
                              } item`}</span>
                            )}
                            {/* <span
 required={
 Object.values(modifiers[id])[0][0]["required"]
 ? true
 : false
 }
 >
 {Object.values(modifiers[id])[0][0][
 "required"
 ] ? (
 <>
 <span className="choose" style={{ color: "red" }}>
 *Required
 </span>
 </>
 ) : (
 ""
 )}
 </span> */}
                            <span className="choose">
                              {Object.values(modifiers[id])[0][0][
                                "required"
                              ] ? (
                                <>
                                  <span style={{ color: "red" }}>
                                    *Required
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                            {/* <span className="choose">choose one</span> */}
                          </h2>
                          <form
                            required={
                              Object.values(modifiers[id])[0][0]["required"]
                                ? true
                                : false
                            }
                          >
                            {/* <span className="choose">choose one</span> */}

                            {Object.values(modifiers[id])[0].map((group, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {group.type === "radio" ? (
                                    <>
                                      <div className="radio-model">
                                        <input
                                          onChange={handleChange}
                                          type="radio"
                                          price={group.price}
                                          name={Object.keys(
                                            modifiers[id]
                                          )[0].replace(/ /g, "_")}
                                          modifier_name={group.name}
                                          modifier_id={id}
                                          value={group.id}
                                          conditional_modifier={group.cmod}
                                          defaultChecked={group.default}
                                        />
                                        <label htmlFor="html">
                                          {group.name}
                                        </label>
                                        <label style={{ float: "right" }}>
                                          {group.price
                                            ? ` $${parseFloat(
                                                group.price / 100
                                              ).toFixed(2)}`
                                            : `$0.00`}
                                        </label>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="checkbox-mod">
                                        <input
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            verticalAlign: "middle",
                                          }}
                                          onChange={handleChangeCheck}
                                          type="checkbox"
                                          price={group.price}
                                          name={Object.keys(
                                            modifiers[id]
                                          )[0].replace(/ /g, "_")}
                                          modifier_name={group.name}
                                          modifier_id={id}
                                          limit={group.limit ? group.limit : 10}
                                          value={group.id}
                                          conditional_modifier={group.cmod}
                                          defaultChecked={group.default}
                                        />
                                        <label htmlFor="html">
                                          {group.name} +{" "}
                                          {group.price
                                            ? `$${parseFloat(
                                                group.price / 100
                                              ).toFixed(2)}`
                                            : `$0.00`}
                                        </label>
                                      </div>
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </form>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
                <div className="special-instructions">
                  <Form.Row>
                    <Form.Group as={Col} controlId="instructions">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={handleInstructions}
                        placeholder="Special Instructions"
                        required
                        value={instructions}
                      />
                    </Form.Group>
                  </Form.Row>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ border: "0" }}>
              <span
                style={{ color: "red", fontSize: "18px", display: "none" }}
                id="required-modifier"
              >
                Please fill the required fields
              </span>
              <div className="newcart" style={{ width: "100%" }}>
                <div className="plusbtn">
                  {props.isOpen ? (
                    props.stock ? (
                      <>
                        {props.restaurant.integrate ? (
                          props.price || modSelect ? (
                            <CustomButtonGroup
                              toDisabled={loader}
                              validateRequired={validateRequired}
                              choosedModifiers={choosedModifiers}
                              modifierPrice={props.modifiersPriceClicked}
                              id={props.id}
                              title={props.title}
                              num={props.num}
                              modifierGroups={props.modifierGroups}
                              onClick={handleClose}
                              instructions={instructions}
                            />
                          ) : (
                            <></>
                          )
                        ) : props.price || modSelect ? (
                          <CustomButtonGroup
                            toDisabled={loader}
                            validateRequired={validateRequired}
                            choosedModifiers={choosedModifiers}
                            modifierPrice={props.modifiersPriceClicked}
                            id={props.id}
                            title={props.title}
                            num={props.num}
                            modifierGroups={props.modifierGroups}
                            onClick={handleClose}
                            instructions={instructions}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <p
                        style={{
                          color: "#f55142",
                          fontSize: "18px",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        Out Of Stock
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    AddWithModifier: (title, modifierId, modifierName, price) => {
      dispatch(AddWithModifier(title, modifierId, modifierName, price));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
    orderCart: state.cart.orderCart,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopularDeliveryCard);
