import React, { useEffect, Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import CartTableItem from "../components/CartTableItem";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CreditCardInput from "react-credit-card-input";

import CartTotalsCheckout from "../components/CartTotalsCheckout";
import MultiLingualContent from "../context/multilingualContent";
import Card from "react-bootstrap/Card";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Form from "react-bootstrap/Form";
import {
  addCustomerAddress,
  addCustomerCard,
  addCustomerPhone,
  postNewOrderOnlineCustomCheckout,
  updateCustomer,
  updateCustomerAddress,
  updateCustomerEmail,
  updateCustomerPhone,
} from "../utils/api";
import { Set, Reset } from "../redux/actions/cartActions";
import { round } from "../utils/functions";
import { FaUserEdit } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class PayRestaurant extends Component {
  state = {
    firstName: "",
    name: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    orderId: "",
    country: "US",
    zip: "",
    email: "",
    mobile: "",
    type: "cash",
    notes: "",
    updated: true,
    customer_id: "",
    modalShow: false,
    edit: false,
    isDisable: false,
    orderType: "",
    timeZone: this.props.restaurant.restaurant.timeZone
  };
  async componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({
      customer_id: this.props.customer.id ? this.props.customer.id : "",
      restaurant_id: this.props.restaurant.id ? this.props.restaurant.id : "",
      // mobile:
      //     this.props.customer.phone
      //         ? this.props.customer.phone
      //         : "",
    });
  }
  validateFormAddress() {
    return !(
      this.state.name.length > 0 &&
      this.state.email.length > 0 &&
      this.state.mobile.length > 0
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleEdit = (e) => {
    this.setState({
      updated: false,
      edit: true,
    });
  };

  handlePaymentEdit = (e) => {
    this.setState({
      updated: false,
      editPayment: true,
    });
  };
  // handleConinue = () => {
  //     window.location.href = `/${this.props.restaurant.id}`;
  //     this.props.Reset();

  // }
  handleNewOrder = async (event) => {
    event.preventDefault();
    if ((this.state.firstName.length > 0 || this.state.name.length > 0) ||
    this.state.mobile.length === 12 && this.state.email.length > 0) {
      this.setState({ isDisable: true });
      var res = {}
      if (!(this.props.restaurant.id === "naan-n-curry-bayarea-pl")) {
      } else {
        res = await postNewOrderOnlineCustomCheckout(this.state, this.props.items, this.props.tip, this.props.tax);
        localStorage.removeItem(this.state.restaurant_id);
        window.location.href = res.url
      }
        
        
    } else {
        toast.error("Please enter your Email with Name or Phone...")
    }
      
  };

  render() {
    const cartTableItemRender = (title) => (
      <CartTableItem
        key={this.props.items[title]["productId"]}
        id={this.props.items[title]}
        img={this.props.items[title].img}
        title={this.props.items[title].title}
        price={this.props.items[title].price}
        num={this.props.items[title].num}
      />
    );
    const checkout = (
      <>
        <Row>
          {/* {console.log(this.state)} */}
          <Col xs={12} lg={8}>
            <div className="d-flex">
              <h4 className="text-uppercase mr-2 my-auto">
                <MultiLingualContent contentID="Cart" />
              </h4>
              <p className="noprod my-auto">{this.props.total} Products</p>
            </div>

            <Card className="mt-3 mb-5">
              <Card.Header
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: '#ff9900',
                                    color: '#fff',
                                fontWeight: '700',
                                    
                }}
              >
                            <span>Details</span>
                            
              </Card.Header>

              <Form
                // onSubmit={this.handleSubmit}
                className="p-4"
              >
                <Form.Row>
                  <Form.Group as={Col} controlId="name">
                    <Form.Control
                                        onChange={this.handleChange}
                                        
                      value={this.state.name}
                      placeholder="Name"
                      required
                    />
                                </Form.Group>
                                

                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Control
                      onChange={this.handleChange}
                      value={this.state.email}
                      type="email"
                      placeholder="Email"
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="mobile">
                    {/* <Form.Control
                      onChange={this.handleChange}
                      value={this.state.mobile}
                      type="text"
                      placeholder="Phone"
                      required
                    /> */}{(!this.state.mobile.replace('+', '').length || !this.state.mobile.replace('+1', '').length) && (
                      <span
                        style={{
                          color: "#000",
                          fontSize: "15px",
                          //   textAlign: "center",
                          fontWeight: "500",
                        }}
                      >
                        <MultiLingualContent contentID="Please enter the phone number to receive the order confirmation text." />
                      </span>
                    )}
                    <PhoneInput
                      country={"us"}
                      placeholder="Phone"
                      inputclassName="phoneinput"
                      value={this.state.mobile}
                      onChange={(mobile) => {
                        this.setState({ mobile: `+${mobile}` });
                      }}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="notes">
                    <Form.Control
                      onChange={this.handleChange}
                      placeholder="Notes"
                      as="textarea"
                      rows={3}
                      required
                      value={this.state.notes}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card>
            <Modal
              show={this.state.modalShow}
              size="md"
              aria-labelledby="ordersucess"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="ordersucess">
                  <Modal.Title id="ordersucess">Order Received</Modal.Title>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                <MultiLingualContent contentID="Your order has been received. Your order will be ready in 10 minutes." /> Your order ID -{" "}
                  <b>{this.state.orderId}</b>
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{ marginBottom: "0" }}
                  onClick={this.handleConinue}
                >
                  Continue
                </Button>
              </Modal.Footer>
            </Modal>
            {/* <Card className="mt-3 mb-5">
                            <Card.Header
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    background: '#ff9900',
                                    color: '#fff',
                                    fontWeight: '700',
                                }}
                            >
                                <span>PAYMENT</span>
                                {this.state.editPayment ? (
                                    <></>
                                ) : this.state.customer_id ? (
                                    <>
                                        {" "}
                                        <FaUserEdit
                                            className="mr-1"
                                            size="1.5em"
                                            onClick={this.handlePaymentEdit}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Card.Header>
                            <Card.Body>
                                <Form
                                    className="p-4"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CreditCardInput
                                        cardNumberInputProps={{
                                            value: this.state.cardNum,
                                            onChange: (e) => {
                                                this.setState({
                                                    cardNum: e.target.value,
                                                });
                                            },
                                        }}
                                        cardExpiryInputProps={{
                                            value: this.state.expiry,
                                            onChange: (e) => {
                                                this.setState({
                                                    expiry: e.target.value,
                                                });
                                            },
                                        }}
                                        cardCVCInputProps={{
                                            value: this.state.cvv,
                                            onChange: (e) => {
                                                this.setState({
                                                    cvv: e.target.value,
                                                });
                                            },
                                        }}
                                        fieldClassName="card-input form-control"
                                    />
                                </Form>
                            </Card.Body>
                        </Card> */}
          </Col>

          <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={3}>
            <CartTotalsCheckout
              handleNewOrder={this.handleNewOrder}
              disabled={this.state.isDisable}
              // disabled={!this.state.updated}
              buttonText="Pay Online"
              payment={true}
              total={this.props.total}
              totalPrice={round(this.props.totalPrice)}
            />
          </Col>
        </Row>
      </>
    );
    // eslint-disable-next-line
    const needToLogin = (
      <div className="text-center">
        <h2>You must Login or Signup to checkout</h2>
        <Link to={`/${this.props.restaurant.id}/login-signup`}>
          <Button variant="warning" className="mt-4">
            To Login/Signup
          </Button>
        </Link>
      </div>
    );

    return (
      <React.Fragment>
        {/* <CustomParallax title="Checkout" img={home_top} height={300} /> */}
        <Container className="my-auto Checkout">
          {checkout}
          {/* {this.props.loggedIn ? checkout : needToLogin} */}
        </Container>
      </React.Fragment>
    );
  }
}

function CustomCartOnlineCheckout(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const cartTableItemRender = (title) => (
  //   <CartTableItem
  //     key={props.items[title]["productId"]}
  //     id={props.items[title]}
  //     img={props.items[title].img}
  //     title={props.items[title].title}
  //     price={props.items[title].price}
  //     num={props.items[title].num}
  //   />
  // );

  const full = (
    <Row>
      {/* <div className="d-flex">
          <h4 className="text-uppercase mr-2 my-auto">Your Cart</h4>
          <p className="noprod my-auto">{props.total} Products</p>
        </div>
        <Table className=" cart-page mt-3" responsive>
          <thead>
            <tr className="head text-center">
              <th>Image</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>Total Price</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {Object.keys(props.items).map(
              (title) =>
                props.items[title].num > 0 && cartTableItemRender(title)
            )}
          </tbody>
        </Table> */}
      <PayRestaurant {...props} />
      {/* <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={3}>
        <CartTotalsCart
          disabled={false}
          buttonText="Checkout"
          total={props.total}
          totalPrice={round(props.totalPrice)}
        />
      </Col> */}
    </Row>
  );
  const empty = (
    <div className="text-center">
      <h2>Your cart is currently empty...</h2>
      <Link to={`/${props.restaurant.id}`}>
        <Button variant="warning" className="mt-4">
          Back to Order
        </Button>
      </Link>
    </div>
  );

  return (
    <React.Fragment>
      <Container className="my-auto Cart" style={{ minHeight: "200px" }}>
        {props.total > 0 ? full : empty}
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    total: state.cart.total,
    totalPrice: state.cart.totalPrice,
    tip: state.cart.tip,
    restaurant: state.restaurant,
    customer: state.customer,
    loggedIn: state.customer.isLoggedIn,
    tax: state.restaurant.totalTax,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Set: (title, num) => {
      dispatch(Set(title, num));
    },
    Reset: (title, num) => {
      dispatch(Reset(title, num));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomCartOnlineCheckout);
