import DefaultLogo from "../../assets/default.jpg";
import { toast } from "react-toastify";
import { translations } from "../../context/translations";
const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
export async function warnRedBox(id, action = "add") {
  if (action === "add") {
    document.getElementById(id).style.boxShadow =
      "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
  } else {
    document.getElementById(id).style.boxShadow = "none";
  }
}
export async function setItem(data, restaurantId, language) {
  let products = {};
  const res = await fetch(`/api/get/${restaurantId}/product-metadata`);
  // const res = await fetch(`/api/get/restaurant/${restaurantId}`);
  console.log(language)
  const translatedWord = (word) => translations[language] ? translations[language][word] : translations["en"][word]
  let Others = translatedWord("Others")
  const json = await res.json();
  if (res.status === 200) {
    let prodData = {};
    var image = [];
    var desc = [];
    json.forEach((prod) => {
      let item = {};
      item[prod.product_id] = { img: prod.image_url, desc: prod.description };
      prodData = { ...prodData, ...item };
    });
    await Promise.all(
      data["items"]["elements"].map(async (product) => {
        // products[capitalizeFirstLetter(product.name)] = {
        products[product.name] = {
          num: 0,
          img: prodData?.[product.id]
            ? prodData[product.id]["img"]
              ? prodData[product.id]["img"]
              : DefaultLogo
            : DefaultLogo,
          desc: prodData?.[product.id]
            ? prodData[product.id]["desc"]
              ? prodData[product.id]["desc"]
              : ""
            : "",
          title: product.name,
          // title: capitalizeFirstLetter(product.name),
          category: product.categories.elements.length
            ? product.categories.elements[0]["name"]
            : Others,
          modifierGroups: product.modifierGroups?.elements.length
            ? product.modifierGroups.elements.map((group) => ({
              [group.id]: group.name,
            }))
            : [],
          productId: product.id,
          price: product.price / 100,
          stock: product.available,
          autostock: product.autostock,
          hidden: product.hidden,
          hideOnline: product.hide_online,
        };
        return 1;
      })
    );
    return products;
  } else {
    toast.error(json.msg);
  }
}
