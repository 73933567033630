import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { BsCheckCircle } from "react-icons/bs";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCustomerFeed, postCustomerFeed } from "../utils/api";
import { async } from "q";

function Feedback(props) {
  const [customer_feedback, setCustomer_feedback] = useState("");
  const [subscribe, setSubscribe] = useState(0);
  const [isDefault, setIsDefault] = useState(0);
  const [activeId, setActiveId] = useState("");
  const [order_id, setOrder_id] = useState("");
  const [showModal, setShowModal] = useState(false);
  const params = useLocation();
  useEffect(() => {
    window.location.href = `/${props.restaurant.id}`
    try {
      async function state() {
        const orderId = params.state;
        setOrder_id(orderId);
        const res = await getCustomerFeed(
          props.restaurant.id,
          props.restaurant.integrate,
          orderId
        );
        if (res) {
          setIsDefault(res.promotion);
        }
      }
      state();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const handleContinue = () => {
    window.location.href = `/${props.restaurant.id}`;
  };
  const handleshow = async (e) => {
    let payload = {
      react: customer_feedback,
      subscribe: subscribe,
      feedback: activeId,
    };
    let res = await postCustomerFeed(
      props.restaurant.id,
      props.restaurant.integrate,
      order_id,
      payload
    );
    //  let payload= {
    //     "customer_feedback": "thumbs up",
    //     "subscribe": 1,
    //     "activeId": "Finding Items"
    // }
    console.clear();
    setShowModal(true);
  };

  const handleThumbs = (e) => {
    const id = e.target.id;
    console.log(id);
    customer_feedback &&
      document.getElementById(customer_feedback).classList.remove("active");
    const eleBtn = document.getElementById(id);
    eleBtn.classList.add("active");
    setCustomer_feedback(id);
    // console.log(eleBtn);
  };
  const handleBtn = (e) => {
    const id = e.target.id;
    activeId && document.getElementById(activeId).classList.remove("active");
    const eleBtn = document.getElementById(id);
    eleBtn.classList.add("active");
    setActiveId(id);
    // console.log(eleBtn);
  };
  return (
    <>
      <div className="review-group">
        <Container>
          <h2>How was your ordering experience using this website</h2>
          <div className="thumbs-up d-flex justify-content-center">
            <button className="" id="thumbs up" onClick={handleThumbs}>
              <FaRegThumbsUp id="thumbs up" />
            </button>
            <button
              className=""
              id="thumbs down"
              onClick={handleThumbs}
              style={{ backgroundColor: "#ff990066" }}
            >
              <FaRegThumbsDown id="thumbs down" />
            </button>
          </div>
          <h3>What can we improve on</h3>
          <div className="improve-grp">
            <button id="Finding Items" className="" onClick={handleBtn}>
              Finding Items
            </button>
            <button id="Customizing Items" className="" onClick={handleBtn}>
              Customizing Items
            </button>
            <button id="Payment Process" className="" onClick={handleBtn}>
              Payment Process
            </button>
            <button id="other" className="" onClick={handleBtn}>
              Other
            </button>
          </div>
          <div>
            <p>
              This feedback will be sent to PhoneDash, the ordering system, and
              is not shared with the restaurant.
            </p>
          </div>
          <div>
            <h5>
              Can our ordering system, PhoneDash, reach out to you to learn more
              about your experience?
            </h5>
          </div>

          {isDefault ? (
            <></>
          ) : (
            <div>
              <p>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    defaultChecked={isDefault}
                    onChange={() => {
                      setSubscribe(subscribe ? 0 : 1);
                    }}
                  />
                  <span className="switch" />
                </label>
                PhoneDash can react out to me.
              </p>
            </div>
          )}
          <div>
            <div style={{ padding: "1em 0", fontSize: "18px" }}>
              <a href={`/${props.restaurant.id}`}>Skip</a>
            </div>
            <button
              style={{
                fontWeight: "700",
                color: "#fff",
                backgroundColor: "#ff9900",
                margin: "1rem !important",
              }}
              className="btn"
              onClick={handleshow}
            >
              Submit
            </button>
            {showModal ? (
              <>
                <Modal show={showModal} size="md" centered>
                  <Modal.Body style={{ padding: "1em 0" }}>
                    <>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "50px",
                          color: "green",
                        }}
                      >
                        <BsCheckCircle />
                      </p>
                      <p style={{ fontSize: "20px", textAlign: "center" }}>
                        Thank you for your feedback
                      </p>
                      <Button
                        style={{
                          marginBottom: "2em",
                          fontWeight: "600",
                          display: "block",
                          margin: "0 auto",
                        }}
                        onClick={handleContinue}
                      >
                        Continue
                      </Button>
                    </>
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
  };
};

export default connect(mapStateToProps, null)(Feedback);
