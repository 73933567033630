import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
function GeneralOrderSetting(props) {
    const [objectSettings, setObjectSettings] = React.useState({
        is_pay_online: 0,
        is_pay_restaurant: 0,
    });
    const [is_pay_online, setIs_pay_online] = React.useState(0);
    const [confirmation, setConfirmation] = React.useState(true);
    const [is_pay_restaurant, setIs_pay_restaurant] = React.useState(0);
    React.useEffect(() => {
        (() => {
            getRestaurantOnlineSettings();
        })();
    }, []);
    async function getRestaurantOnlineSettings() {
        try {
            let response = await fetch(
                `/api/v1/merchant/get/restaurant/online-order/settings/${props.restaurant.id}`
            );
            response = await response.json();
            console.log("response", response.data);
            if (response.data) {
                const {
                    common_settings: { order_settings },
                } = response.data;
                setIs_pay_online(response.data.is_pay_online);
                setIs_pay_restaurant(response.data.is_pay_restaurant);
                if (order_settings) {
                    setConfirmation(
                        order_settings.togo_order_settings.confirmation_required
                    );
                }
            } else {
            }
        } catch (error) {
            console.log(error);
        }
    }
    async function updateRestaurantOnlineSettings(type, booleanValue) {
        try {
            let response = await fetch(
                `/api/v1/merchant/update/restaurant/online-order/settings/${props.restaurant.id}?type=${type}&setBoolean=${booleanValue}`
            );
            toast.success("updated");
            getRestaurantOnlineSettings();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <h4>General</h4>
            <Card className="order-filter" style={{ boxShadow: "none" }}>
                <div className="coupon-container" style={{ paddingTop: "0" }}>
                    <Form className="pb-4">
                        <Row className="align-items-center mt-4">
                            <Col lg="5" md="5" sm="12">
                                <div className="coupon-label">Order confirmation</div>
                            </Col>
                            <Col lg="7" md="7" sm="12">
                                <div>
                                    <Form.Group className="mb-0">
                                        <Form.Check
                                            type="switch"
                                            onChange={() =>
                                                updateRestaurantOnlineSettings(
                                                    "confirmation",
                                                    !confirmation
                                                )
                                            }
                                            //checked={!!this.state.checked[user.SD_Emplid]}
                                            id="custom-switch2"
                                            name="confirmation"
                                            label="OFF/ON"
                                            //value = "1"
                                            checked={confirmation}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>
        </>
    );
}

export default GeneralOrderSetting;