import React from "react";
import DiscountOption from "./DiscountOption";
import GeneralOrderSetting from "./GeneralOrderSetting";
import TableOrderConfig from "./TableOrderConfig";
import OnlineOrderConfig from "./OnlineOrderConfig";
import ConvenienceOption from "./ConvenienceOption";
import TranslateConfig from "./Translate";
import { connect } from "react-redux";
function OrderConfig(props) {
  return (
    <>
      <GeneralOrderSetting {...props} />
      <OnlineOrderConfig {...props} />
      <TableOrderConfig {...props} />
      <DiscountOption {...props} />
      <TranslateConfig {...props} />
      {props.restaurant.integrate === 'clover' ?
        <ConvenienceOption {...props} /> : <></>}
    </>
  );
}

// export default OrderConfig;
const mapStateToProps = (state) => {
  return {
    restaurant_id: state.customer.resId,
    customer: state.customer,
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(OrderConfig);