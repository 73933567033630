import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { Tip } from "../../../redux/actions/cartActions";
import { checkoutInit } from "../../../redux/actions/checkoutActions";
import Form from "react-bootstrap/Form";
import axios from "axios";
import MultiLingualContent from "../../../context/multilingualContent";

function CartTableCheckoutSquare(props) {
  const [state, setState] = useState({
    subTotal: props.totalPrice,
    taxPercent: props.restaurant.totalTax,
    tax: Number((props.totalPrice * props.restaurant.totalTax).toFixed(2)),
    tip: 0,
    finalTotal: 0,
    coupon_discount_percentage: 0,
    discountAmount: 0,
    activeTip: 0,
  });
  const [tip, setTip] = useState(props.tip);
  const [subtotal, setsubtotal] = useState(props.totalPrice);
  const [tax, setTax] = useState(
    parseFloat((props.restaurant.totalTax * props.totalPrice).toFixed(2))
  );
  const [coupon_code, setCoupon] = useState("");
  const [coupon_error, setCouponMessage] = useState("");
  const [coupon_applied, setCouponApplied] = useState(true);
  const [applied_coupon_code, setAppliedCouponCode] = useState("");
  const [coupon_discount_amount, setCouponDiscountAmount] = useState(0);
  const [coupon_discount_percentage, setCouponDiscountPercentage] = useState(0);
  const [coupon_tax, setCouponTax] = useState(0);
  const [coupon_tip, setCouponTip] = useState(0);
  const [coupon_total, setCouponTotal] = useState(0);
  const [tips, settips] = useState(parseFloat(props.tips));
  const [disTips, setDisTips] = useState(0);
  const [conv_fee, setconv_fee] = useState(
    props.totalPrice * (props.restaurant.convenience_rate / 100)
  );
  const [discount, setDiscount] = useState({ isDiscount: 0 });
  const [discountPrice, setDiscountPrice] = useState();
  // const [coupon_discount_amount, setcoupon_discount_amount] = useState(
  //   props.couponDiscount
  // );
  const [discountData, setDiscountData] = useState({ name: "" });
  const [finalPrice, setFinalPrice] = useState(
    parseFloat(subtotal + tax + tips).toFixed(2)
  );
  if (
    props.restaurant.convenience_limit &&
    conv_fee > props.restaurant.convenience_limit
  ) {
    setconv_fee(props.restaurant.convenience_limit);
  }
  useEffect(() => {
    (async function () {
      const response = await fetch(
        `/api/v1/merchant/${props.restaurant.id}/${props.restaurant.integrate}/discount`
      );
      const json = await response.json();
      setDiscount(json);
      if (props.restaurant.convenience_fee) {
        let toTotal = subtotal + tax + tips;
        let calcFee = toTotal * (props.restaurant.convenience_rate / 100);
        setconv_fee(calcFee);
        setFinalPrice(parseFloat(toTotal + calcFee).toFixed(2));
      }
      console.log("Table checkkout square");
      setPaymentData(18);
      // if (props.tablePayment) {
      //   // const orderDataRaw = await fetch(
      //   //   `/api/v1/table/get/table/order/data/${props.restaurant.id}?tableId=${props.table.table_id}`
      //   // );
      //   // const orderData = await orderDataRaw.json();

      //   const {
      //     previous_subTotal: sub_merge,
      //     previous_tip: tip_merge,
      //     previous_tax: tax_merge,
      //     previous_total: total_merge,
      //   } = props.table;
      //   setsubtotal(
      //     (Number(subtotal) + Number((sub_merge / 100).toFixed(2))).toFixed(2)
      //   );
      //   settips(Number(tips) + Number(tip_merge / 100));
      //   // settips(Number(tips) + Number(tip_merge / 100).toFixed(2));
      //   setTax(Number(tax) + Number((tax_merge / 100).toFixed(2)));
      //   setFinalPrice(
      //     (Number(finalPrice) + Number((total_merge / 100).toFixed(2))).toFixed(
      //       2
      //     )
      //   );
      // } else if (props.checkout.finalTotal) {
      //   if (props.restaurant.convenience_fee) {
      //     const tosetTotal = props.checkout.finalTotal;
      //     const toConv_fee = Number(
      //       tosetTotal * (props.restaurant.convenience_rate / 100).toFixed(2)
      //     );
      //     setconv_fee(toConv_fee);
      //     if (
      //       props.restaurant.convenience_limit &&
      //       toConv_fee > props.restaurant.convenience_limit
      //     ) {
      //       setconv_fee(props.restaurant.convenience_limit);
      //     }
      //     const finallPrice = props.checkout.finalTotal + toConv_fee;
      //     setFinalPrice(parseFloat(finallPrice).toFixed(2));
      //   } else {
      //     // const subTotalDis = props.totalPrice - discountprice;
      //     // const toTip = subTotalDis * (props.tip / 100);
      //     // setFinalPrice(parseFloat(subTotalDis + toTax + toTip).toFixed(2));
      //     const finallPrice = props.checkout.finalTotal;
      //     setFinalPrice(parseFloat(finallPrice).toFixed(2));
      //   }
      // } else if (json.isDiscount) {
      //   setDiscountData(json.data);
      //   const discountprice = parseFloat(
      //     props.totalPrice * (json.percentage / 100)
      //   );
      //   setDiscountPrice(discountprice);
      //   const discountMinusSubtotal = Number(
      //     props.totalPrice - discountprice.toFixed(2)
      //   );
      //   let toTax = Number(
      //     parseFloat(props.restaurant.totalTax * discountMinusSubtotal).toFixed(
      //       2
      //     )
      //   );
      //   setTax(toTax);
      //   const toTip = +parseFloat(
      //     discountMinusSubtotal * (props.tip / 100)
      //   ).toFixed(2);
      //   setDisTips(toTip);
      //   if (props.restaurant.convenience_fee) {
      //     const tosetTotal = +discountMinusSubtotal + toTax + toTip;
      //     const toConv_fee = Number(
      //       tosetTotal * (props.restaurant.convenience_rate / 100).toFixed(2)
      //     );
      //     setconv_fee(toConv_fee);
      //     if (
      //       props.restaurant.convenience_limit &&
      //       toConv_fee > props.restaurant.convenience_limit
      //     ) {
      //       setconv_fee(props.restaurant.convenience_limit);
      //     }
      //     console.log(discountMinusSubtotal, toTip, toConv_fee, toTax);
      //     const finallPrice =
      //       discountMinusSubtotal + toTip + toConv_fee + toTax;
      //     setFinalPrice(parseFloat(finallPrice).toFixed(2));
      //   } else {
      //     // const subTotalDis = props.totalPrice - discountprice;
      //     // const toTip = subTotalDis * (props.tip / 100);
      //     // setFinalPrice(parseFloat(subTotalDis + toTax + toTip).toFixed(2));
      //     const finallPrice = discountMinusSubtotal + toTip + toTax;
      //     setFinalPrice(parseFloat(finallPrice).toFixed(2));
      //   }
      // }
    })();
  }, []);
  // const tipsChange = (e) => {
  //   const list = document.getElementById("tipslist");
  //   var items = list.getElementsByTagName("li");
  //   for (var i = 0; i < items.length; ++i) {
  //     if (items[i].getAttribute("name") === e.target.getAttribute("name")) {
  //       items[i].classList.add("active");
  //     } else {
  //       items[i].classList.remove("active");
  //     }
  //   }
  //   if (e.target.getAttribute("name") !== "custom") {
  //     setTip(parseInt(e.target.getAttribute("name")));
  //     props.Tip(parseInt(e.target.getAttribute("name")));
  //   }
  // };
  const tipsChange = (e) => {
    const list = document.getElementById("tipslist");
    var items = list.getElementsByTagName("li");
    for (var i = 0; i < items.length; ++i) {
      if (items[i].getAttribute("name") === e.target.getAttribute("name")) {
        items[i].classList.add("active");
      } else {
        items[i].classList.remove("active");
      }
    }
    if (e.target.getAttribute("name") !== "custom") {
      // console.log(e.target.getAttribute("name"))
      const tipSeleted = Number(e.target.getAttribute("name"));
      setPaymentData(tipSeleted);
      // setTip(parseInt(e.target.getAttribute("name")));
      // props.Tip(parseInt(e.target.getAttribute("name")));
    }
  };
  const setPaymentData = (tip, data = state) => {
    props.Tip(parseInt(tip));
    const {
      subTotal,
      initTotal: total,
      initTax,
      initTip,
      coupon_discount_percentage,
    } = data;
    // tax,tip,finalTotal
    let totalTax = props.totalTax;
    let newTotal;
    console.log(`coupon_discount_percentage`, coupon_discount_percentage);
    if (coupon_discount_percentage) {
      let disAmount = subTotal * (coupon_discount_percentage / 100);
      // console.table({ subTotal, tip, totalTax });
      let disSub = subTotal - disAmount;
      let newTip = disSub * (tip / 100);
      let newTax = disSub * totalTax;
      newTotal = subTotal + newTip + newTax - disAmount;
      console.table({
        tax: newTax,
        tip: newTip,
        finalTotal: newTotal,
        activeTip: parseInt(tip),
      });
      setState((prevState) => ({
        ...prevState,
        tax: newTax,
        tip: newTip,
        finalTotal: newTotal,
        discountAmount: disAmount,
        activeTip: parseInt(tip),
      }));
      props.checkoutInit({
        finalSubtipsChangeTotal: disSub,
        finalTip: {
          value: newTip,
          percentage: tip,
        },
        finalTax: {
          value: newTax,
          percentage: props.restaurant.totalTax,
        },
        finalTotal: newTotal,
        discount: {
          name: "",
          value: disAmount,
          percentage: coupon_discount_percentage,
        },
        couponCode: {
          name: "",
          code: coupon_code,
          percentage: coupon_discount_percentage,
        },
      });
    } else {
      console.log("else");
      let newTip = Number((state.subTotal * (tip / 100)).toFixed(2));
      console.table({ sub: state.subTotal, tax: state.tax, tip: newTip });
      newTotal = Number((state.subTotal + state.tax + newTip).toFixed(2));
      setState((prevState) => ({
        ...prevState,
        tip: newTip,
        finalTotal: newTotal,
        activeTip: parseInt(tip),
      }));
    }

    const { googlePay, reInitGooglePay } = props;
    if (googlePay) {
      googlePay(newTotal);
    }
    if (reInitGooglePay) {
      reInitGooglePay(newTotal)
    }
    // const { initSubTotal: subTotal, initTotal: total, initTip } = data;
    // console.log(initTip, tip);
    // let newTip = subTotal * (tip / 100);
    // let newTotal = newTip + total;
    // this.setState({
    //   orderData: {
    //     ...this.state.orderData,
    //     ...{ total: newTotal, tip: newTip },
    //   },
    //   tip: newTip || initTip,
    //   amount: newTotal,
    //   tip_per: tip,
    // });
    // setTimeout(() => this.setState({ gpayButton: true }), 1000);
  };
  const handleTipChange = (e) => {
    setTip(e.target.value);
    props.Tip(parseInt(e.target.value));
  };
  const couponChange = (e) => {
    setCoupon(e.target.value);
    setCouponMessage("");
    setAppliedCouponCode("");
  };
  const couponSubmit = (e) => {
    e.preventDefault();
    if (coupon_code != "") {
      console.log(props.customer);
      axios
        .post(`/api/integrations/get/coupon_code/${props.restaurant.id}`, {
          coupon_code,
        })
        .then((res) => {
          let { coupon_discount, msg } = res.data;
          if (msg == "Applied") {
            if (coupon_discount > 0) {
              setState((prevState) => ({
                ...prevState,
                ...{ coupon_discount_percentage: Number(coupon_discount) },
              }));
              setPaymentData(state.activeTip, {
                ...state,
                coupon_discount_percentage: Number(coupon_discount),
              });
            }
          } else {
            // this.setState({
            //   coupon_error: msg,
            //   applied_coupon_code: "",
            // });
            setCouponMessage(msg);
            setAppliedCouponCode("");
            //   setCouponMessage(res.data.msg);
          }
          // if (res.data.msg == "Applied") {
          //   setCouponApplied(false);
          //   let discount = 0;
          //   let couponTotal = 0;
          //   let couponTax = 0;
          //   if (res.data.coupon_discount > 0) {
          //     discount = parseFloat(
          //       (props.totalPrice * res.data.coupon_discount) / 100
          //     ).toFixed(2);
          //     console.log(10, discount);
          //     couponTotal = props.totalPrice - discount;
          //     couponTax = Number(
          //       parseFloat(props.restaurant.totalTax * couponTotal).toFixed(2)
          //     );
          //     setCouponTax(couponTax);
          //     setCouponTotal(couponTotal);
          //     let toSetData = {
          //       subTotal: props.totalPrice,
          //       tip: {
          //         value: Number(
          //           parseFloat(((tip / 100) * props.totalPrice).toFixed(2))
          //         ),
          //         percentage: tip,
          //       },
          //       tax: {
          //         value: Number(
          //           parseFloat(
          //             (props.restaurant.totalTax * props.totalPrice).toFixed(2)
          //           )
          //         ),
          //         percentage: props.restaurant.totalTax,
          //       },
          //       total: Number(
          //         parseFloat(
          //           props.totalPrice +
          //             props.restaurant.totalTax * props.totalPrice +
          //             parseFloat(props.tips)
          //         ).toFixed(2)
          //       ),
          //       finalSubTotal: props.totalPrice - discount,
          //       finalTip: {
          //         value: Number(
          //           parseFloat(
          //             (props.totalPrice - discount) * (tip / 100)
          //           ).toFixed(2)
          //         ),
          //         percentage: tip,
          //       },
          //       finalTax: {
          //         value: Number(
          //           parseFloat(
          //             props.restaurant.totalTax * (props.totalPrice - discount)
          //           ).toFixed(2)
          //         ),
          //         percentage: props.restaurant.totalTax,
          //       },
          //       finalTotal: Number(
          //         parseFloat(
          //           couponTotal + couponTax + couponTotal * (tip / 100)
          //         ).toFixed(2)
          //       ),
          //       discount: {
          //         name: "",
          //         value: discount,
          //         percentage: res.data.coupon_discount,
          //       },
          //       couponCode: {
          //         name: "",
          //         code: coupon_code,
          //         percentage: res.data.coupon_discount,
          //       },
          //     };
          //     setTimeout(() => {
          //       props.checkoutInit(toSetData);
          //       const { googlePay } = props;
          //       console.log(googlePay);
          //       if (googlePay) {
          //         googlePay(toSetData.finalTotal);
          //       }
          //       // props.reInitGooglePay();
          //     }, 200);
          //     setFinalPrice(toSetData.finalTotal);
          //   }
          //   setCouponMessage(res.data.msg);
          //   setAppliedCouponCode(coupon_code);
          //   setCouponDiscountPercentage(res.data.coupon_discount);
          //   setCouponDiscountAmount(discount);
          //   setCouponApplied(true);
          // } else {
          //   setAppliedCouponCode("");
          //   setCouponMessage(res.data.msg);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };
  return (
    <div className="checkout-cont">
      {console.log(state)}
      <h4 className="my-auto" style={{ fontSize: "17px", fontWeight: "600" }}>
        <MultiLingualContent contentID="Order Summary" />
      </h4>
      <Card className="mt-3">
        {/* <Card.Header className="pl-2 font-weight-bold">
          {props.total} items
        </Card.Header> */}
        <Card.Body className="border-top" style={{ padding: "10px" }}>
          <div className="tab-group tips">
            <h6><MultiLingualContent contentID="Add Tip" /></h6>
            <ul id="tipslist">
              <li onClick={tipsChange} className="" name="0">
                <MultiLingualContent contentID="No Tip" />
              </li>
              <li onClick={tipsChange} className="" name="10">
                10%
              </li>
              <li onClick={tipsChange} className="active" name="18">
                18%
              </li>
              <li onClick={tipsChange} className="" name="20">
                20%
              </li>
            </ul>
          </div>
        </Card.Body>
        <Card.Body className="d-flex border-bottom justify-content-between p-2 ">
          <Card.Text><MultiLingualContent contentID="Subtotal" /></Card.Text>
          <Card.Text>$ {subtotal.toFixed(2)}</Card.Text>
        </Card.Body>
        {discountPrice ? (
          <Card.Body
            className="d-flex justify-content-between p-2 pricing"
            style={{ padding: "10px" }}
          >
            <Card.Text>{discountData.name}</Card.Text>
            <Card.Text>- ${parseFloat(discountPrice).toFixed(2)}</Card.Text>
          </Card.Body>
        ) : (
          <></>
        )}
        {state.discountAmount ? (
          <Card.Body
            className="d-flex justify-content-between p-2 pricing"
            style={{ padding: "10px" }}
          >
            <Card.Text><MultiLingualContent contentID="Coupon Applied" /></Card.Text>
            <Card.Text>
              - ${parseFloat(state.discountAmount).toFixed(2)}
            </Card.Text>
          </Card.Body>
        ) : (
          <></>
        )}
        {props.checkout.finalTax.value ? (
          <Card.Body className="d-flex justify-content-between p-2 pricing">
            <Card.Text><MultiLingualContent contentID="Taxes" /></Card.Text>
            <Card.Text>
              ${parseFloat(props.checkout.finalTax.value).toFixed(2)}
            </Card.Text>
          </Card.Body>
        ) : state.tax ? (
          <Card.Body className="d-flex justify-content-between p-2 pricing">
            <Card.Text><MultiLingualContent contentID="Taxes" /></Card.Text>
            <Card.Text>$ {state.tax.toFixed(2)}</Card.Text>
          </Card.Body>
        ) : (
          <></>
        )}

        {props.checkout.finalTip.value ? (
          <Card.Body
            className="d-flex justify-content-between p-2 pricing"
            style={{ padding: "10px" }}
          >
            <Card.Text><MultiLingualContent contentID="Tips" /></Card.Text>
            <Card.Text>
              ${parseFloat(props.checkout.finalTip.value).toFixed(2)}
            </Card.Text>
          </Card.Body>
        ) : state.tip ? (
          <Card.Body
            className="d-flex justify-content-between p-2 pricing"
            style={{ padding: "10px" }}
          >
            <Card.Text><MultiLingualContent contentID="Tips" /></Card.Text>
            <Card.Text>$ {state.tip.toFixed(2)}</Card.Text>
          </Card.Body>
        ) : (
          <></>
        )}

        {/* {props.restaurant.convenience_fee && path !== "pay-on-cash" ? ( */}
        {props.restaurant.convenience_fee ? (
          <Card.Body className="d-flex justify-content-between p-2">
            <Card.Text>
              Convenience Fee ({props.restaurant.convenience_rate}%)
            </Card.Text>
            <Card.Text>$ {parseFloat(conv_fee).toFixed(2)}</Card.Text>
          </Card.Body>
        ) : (
          <></>
        )}
        <Card.Body className="d-flex justify-content-between p-2">
          <Card.Text><MultiLingualContent contentID="Total" /></Card.Text>
          <Card.Text>$ {state.finalTotal.toFixed(2)}</Card.Text>
        </Card.Body>
        {props.restaurant.isCoupon ? (
          <Card.Body className="coupon-field border-bottom p-2 pricing">
            <Card.Text><MultiLingualContent contentID="Coupon" />:</Card.Text>
            <Card.Text>
              <Form style={{ boxShadow: "none" }}>
                <div style={{ display: "flex" }}>
                  <Form.Group className="mb-0" style={{ width: "100%" }}>
                    <Form.Control
                      onChange={couponChange}
                      type="text"
                      name="coupon_value"
                      placeholder="Enter Coupon code"
                    //value={coupon_value}
                    />
                  </Form.Group>
                  <div className="text-center">
                    <button
                      onClick={couponSubmit}
                      style={{ padding: "5px 10px" }}
                      className="btn btn-primary mb-0 ml-2"
                      type="button"
                    >
                      <MultiLingualContent contentID="Apply" />
                    </button>
                  </div>
                </div>
                {coupon_error ? (
                  <div style={{ padding: "5px 10px" }}>{coupon_error}</div>
                ) : (
                  <></>
                )}
              </Form>
            </Card.Text>
          </Card.Body>
        ) : (
          <></>
        )}
        <Card.Footer
          style={{ border: "0", background: "none", padding: "22px 0 0" }}
        >
          <Button
            style={{ width: "auto" }}
            onClick={props.handleNewOrder}
            variant="warning"
            block
            disabled={props.disabled}
          >
            {props.buttonText}
          </Button>

          {props.payment && (
            <>
              {" "}
              <hr />
              <h6 className="mt-3">
                <MultiLingualContent contentID="WE ACCEPT" />:
                <Image
                  className="img-thumbnail mt-1"
                  src="https://assets.asosservices.com/asos-finance/images/marketing/single.png"
                  alt="payment options"
                />
              </h6>
            </>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    Tip: (tip) => {
      dispatch(Tip(tip));
    },
    checkoutInit: (data) => {
      dispatch(checkoutInit(data));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    tip: state.cart.tip,
    tips: state.cart.tips,
    totalTax: state.restaurant.totalTax,
    checkout: state.checkout,
    table: state.table,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartTableCheckoutSquare);