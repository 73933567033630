import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useSearchParams, useParams } from "react-router-dom";
import { async } from "q";
import BeatLoader from "react-spinners/BeatLoader";
import MultiLingualContent from '../../context/multilingualContent';
function TablePayCash(props) {
  // const { tableId } = ;
  // const [searchParams] = useSearchParams();
  const [tableId, setTableId] = useState(useParams()["tableId"]);
  const [validData, setValidData] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [fetching, setFetching] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    useParams()["restaurantsId"]
  );
  const [orderId, setOrderId] = useState(useSearchParams()[0].get("order_id"));
  useEffect(() => {
    async function fetchData() {
      let checkIsValid = await fetch(
        `/api/v1/table/get/table/cash/initialize/data/${restaurantId}?tableId=${tableId}&tableOrderId=${orderId}`
        // `/api/v1/table/get/table/thankyou/data/${restaurantId}?tableId=${tableId}&tableOrderId=${orderId}`
      );
      checkIsValid = await checkIsValid.json();
      console.log(checkIsValid);
      if (!checkIsValid) {
        setValidData(false);
      } else {
        let expiresIn =
          new Date(checkIsValid.data.updatedAt).getTime() + 30 * 60000;
        let currentTime = new Date().getTime();
        console.table({ expiresIn, currentTime });
        if (
          checkIsValid.data.payment_status.toLowerCase() === "not paid" ||
          expiresIn > currentTime
        ) {
          setValidData(true);
          setOrderData(checkIsValid.data);
          setCustomerData(checkIsValid.customerData);
        } else {
          setValidData(false);
        }
      }
      setFetching(false);
    }
    fetchData();
  }, []);
  // START HERE
  const handelContinue = () => {
    window.location.href = `/${restaurantId}/table?tableId=${tableId}`;
  };
  const handelPayNoq = () => {
    window.location.href = `/${restaurantId}/table/payment/${tableId}?table_order_Id=${orderId}`;
  };

  return (
    <>
      <div className="cart-header-thank d-flex">
        <div className="tlogo">
          <img
            style={{ maxWidth: "85px" }}
            src={props.restaurant.restaurant.logo}
            alt="logo"
          />
        </div>
        <div className="taddress">
          <h2>{props.restaurant.restaurant.name}</h2>
          <p>
            {props.restaurant.address.address1}{" "}
            {props.restaurant.address.address2}, {props.restaurant.address.city}
            , {props.restaurant.address.country}, {props.restaurant.address.zip}
          </p>
        </div>
      </div>
      {fetching ? (
        <></>
      ) : validData ? (
        <>
          {/* {console.log(orderData)} */}

          <div className="table-thank">
            {/* <p style={{ fontSize: "20px" }}>Thanks for your order! </p> */}
            <h2 style={{ fontWeight: "600" }}>
            <MultiLingualContent contentID="Please reach the front desk and show the phone screen" />.
            </h2>
            <div
              className="my-5"
              style={{ margin: "0 auto", fontSize: "20px" }}
            >
              {/* <div className="d-flex justify-content-between">
                <div>Table No :</div>
                <div>{orderData.id.split("-")[0]}</div>
              </div>
              <div className="d-flex justify-content-between">
                <div>Name : </div>
                <div>{customerData.name}</div>
              </div> */}

              <table
                className="table-details"
                style={{ margin: "0 auto", maxWidth: "300px" }}
              >
                <tr>
                  <td>Table No</td>
                  <td align="center">:</td>
                  <td>{orderData.token_id.split("-")[0]}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td align="center">:</td>
                  <td>{customerData.name}</td>
                </tr>{" "}
                {customerData.mobile ? (
                  <tr>
                    <td>Mobile</td>
                    <td align="center">:</td>
                    <td>{customerData.mobile}</td>
                  </tr>
                ) : (
                  <></>
                )}
              </table>
              {/* <div style={{ fontSize: "24px" }}>{`Table No : ${
                orderData.id.split("-")[0]
              }`}</div> */}
              {/* <div></div>
              <div
                style={{ fontSize: "24px" }}
              >{`Name : ${customerData.name}`}</div>
              {customerData.mobile ? (
                <div
                  style={{ fontSize: "24px" }}
                >{`Mobile : ${customerData.mobile}`}</div>
              ) : (
                <></>
              )} */}
            </div>
            {/* <p style={{ fontSize: "20px" }}>
              {" "}
              Please{" "}
              <a
                href={`https://${window.location.host}/r/${restaurantId}/table/${orderId}`}
                style={{ textDecoration: "underline", fontWeight: "600" }}
                target="_blank"
              >
                click here
              </a>{" "}
              to view your order details.
            </p>
            <div className="thankbtns">
              {orderData.payment_status.toLowerCase() === "not paid" ? (
                <Button onClick={handelPayNoq}>Pay Now</Button>
              ) : (
                <></>
              )}
            </div> */}
            {/* <div>
              <img
                style={{ width: "100%", maxWidth: "300px", margin: "0 auto" }}
                src="/store.gif"
              />
            </div> */}
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="table-thank">
            <h2><MultiLingualContent contentID="Page you are looking is not available or expired" /></h2>
            {/* <div className="thankbtns">
    <Button>Pay Now</Button>
    <Button onClick={handelContinue}>Continue to Order</Button>
  </div> */}
          </div>
        </>
      )}
      <table class="foot-table" style={{ margin: "4em auto 0" }}>
        <tr>
          <td style={{ width: "auto", verticalAlign: "middle" }}>
            <p
              class="footer-txt"
              style={{
                fontSize: "16px",
                textAlign: "right",
                margin: "0",
                fontWeight: "600",
              }}
            >
              Powered by
            </p>
          </td>
          <td style={{ width: "auto", verticalAlign: "middle" }}>
            <img
              style={{ maxWidth: "120px" }}
              src="/logo.svg"
              class="foot-logo"
            />
          </td>
        </tr>
      </table>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    orderCart: state.cart.orderCart,
    total: state.cart.total,
    tablePath: state.restaurant.tablePath,
    totalPrice: state.cart.totalPrice,
    restaurant: state.restaurant,
    table: state.table,
  };
};

export default connect(mapStateToProps)(TablePayCash);
