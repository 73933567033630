import React, { Component } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FaMinus, FaPlus } from "react-icons/fa";
import { connect } from "react-redux";
import { Add, Sub } from "../redux/actions/cartActions";
import MultiLingualContent from '../context/multilingualContent';

class CustomButtonGroup extends Component {
  state = {
    // count: this.props.num === 0 ? 1 : this.props.num,
    count: 1,
    isOpen: false,
  };
  componentDidMount() {
    /*   this.setState({
      count: this.props.num ? this.props.num : 1
    }) */
  }

  handleAdd = async () => {
    this.setState({
      count: this.state.count + 1,
    });
  };

  handleSub = async () => {
    this.state.count >= 2 &&
      this.setState({
        count: this.state.count - 1,
      });
  };
  HandleSubmit = async () => {
    if (this.props.validateRequired()) {
      const { choosedModifiers, subMod } = this.props;
      const choosedModifiersData = Object.entries(choosedModifiers);
      let modifiers = {};
      if (choosedModifiersData.length) {
        for (let [key, value] of choosedModifiersData) {
          let { sub_modifier, modifier_name } = value;
          if (subMod && Object.keys(subMod)) {
            if (subMod[key]?.type === 'check') {
              modifiers[key] = { ...value, modifier_name: subMod[key] ? `${modifier_name} - ${subMod[key].name}` : modifier_name };
            } else {
              modifiers[key] = { ...value, modifier_name: value.modifier_id && subMod[value.modifier_id]?.modifier_id === key ? `${modifier_name} - ${subMod[value.modifier_id].name}` : modifier_name };
            }
          } else {
            modifiers[key] = { ...value };
          }
        }
      } else {
        modifiers = choosedModifiers;
      }
      this.props.Add(
        this.props.title,
        this.state.count,
        this.props.restaurant.id,
        modifiers,
        this.props.instructions
      );
      this.props.onClick();
    } else {
      console.log("Failed. Missing choosing modifiers");
    }
  };

  render() {
    //console.log("c", this.state.count)
    const addCartElement = (
      <Button className="py-2" variant="outline-dark" onClick={this.handleAdd}>
        <MultiLingualContent contentID="Add to Cart" />
      </Button>
    );
    const plusMinusElement = (
      <>
        <ButtonGroup className="w-100 crt-pm-btn" variant="outline-dark">
          <div className="mi-btn">
            <Button
              style={{ fontSize: "20px", lineHeight: "10px" }}
              variant="outline-dark"
              onClick={this.handleSub}
            >
              {" "}
              <FaMinus />{" "}
            </Button>
          </div>
          <div className="or-count">
            <Button
              style={{ fontSize: "20px", lineHeight: "10px" }}
              className="disabled"
              variant="outline-dark"
            >
              {this.state.count ? this.state.count : 0}
            </Button>
          </div>
          <div className="pl-btn">
            <Button
              style={{ fontSize: "20px", lineHeight: "10px" }}
              className="py-2"
              variant="outline-dark"
              onClick={this.handleAdd}
            >
              {" "}
              <FaPlus />{" "}
            </Button>
          </div>
        </ButtonGroup>
        <div className="addto">
          <Button
            className="py-2"
            variant="outline-dark"
            onClick={this.HandleSubmit}
            disabled={this.props.toDisabled}
          >
            <MultiLingualContent contentID="Add to Cart" />
          </Button>
        </div>
      </>
    );
    const num = this.props.num;
    // console.log(num);

    return plusMinusElement;

    // return !num || num === 0 ? addCartElement : plusMinusElement;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    Add: (title, num, id, modifications, instructions) => {
      dispatch(Add(title, num, id, modifications, instructions));
    },
    Sub: (title, num, id) => {
      dispatch(Sub(title, num, id));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomButtonGroup);