import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import checkoutReducer from "./checkoutReducer";
import customerReducer from "./customerReducer";
import dashboardReducer from "./dashboardReducer";
import restaurantReducer from "./restaurantReducer";
import tableReducer from "./tableReducer";
import webAppReducer from "./webAppReducer";

export default combineReducers({
  cart: cartReducer,
  restaurant: restaurantReducer,
  customer: customerReducer,
  dashboard: dashboardReducer,
  webApp: webAppReducer,
  checkout: checkoutReducer,
  table: tableReducer,
});
