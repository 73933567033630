import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import CustomButtonGroup from "./CustomButtonGroup";
import { connect } from "react-redux";
import { FiTrash2 } from "react-icons/fi";
import { round } from "../utils/functions";
import { getModifierGroups } from "../utils/api";
import { Zero, Edit, Sub } from "../redux/actions/cartActions";
import Button from "react-bootstrap/Button";
import MultiLingualContent from "../context/multilingualContent";
class CartTableItemSide extends Component {
  handleZero = async () => {
    this.props.Zero(
      this.props.title,
      this.props.restaurant.id,
      this.props.index
    );
    // const locData =
    //   localStorage.getItem(this.props.restaurant.id) &&
    //   JSON.parse(localStorage.getItem(this.props.restaurant.id));
    // if (locData.restaurantId === this.props.restaurant.id)
    //   localStorage.removeItem(this.props.restaurant.id);
  };
  handleSub = async () => {
    this.props.Sub(this.props.title, 1, this.props.restaurant.id);
  };
  handlePlus = async () => {
    this.props.EDIT(
      this.props.title,
      this.props.num + 1,
      this.props.modifiers,
      this.props.instructions,
      this.props.index,
      "SUM",
      this.props.restaurant.id
    );
  };
  handleMinus = async () => {
    this.props.EDIT(
      this.props.title,
      this.props.num + 1,
      this.props.modifiers,
      this.props.instructions,
      this.props.index,
      "MINUS",
      this.props.restaurant.id
    );
  };
  render() {
    return (
      <>
        {/* {console.log(this.props)} */}
        <div className="viewsidebar">
          <div style={{ padding: "1em 30px", borderBottom: "1px solid #ccc" }}>
            <label
              style={{
                fontSize: "14px",
                marginBottom: "6px",
                fontweight: "600",
              }}
            >
              <MultiLingualContent contentID="Quantity" />: <b>{this.props.num}</b>
            </label>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              <div style={{ flex: "1 1 0%" }}>
                <h4
                  style={{
                    margin: "0px",
                    fontWeight: "600",
                    fontSize: "16px",
                    paddingLeft: "0",
                  }}
                >
                  {this.props.title}
                </h4>
                {this.props.modifierName ? (
                  <p className="mod">
                    {" "}
                    {this.props.modifierName} - $
                    {parseFloat(this.props.modifierPrice / 100).toFixed(2)} Per
                    <MultiLingualContent contentID="per_item" />{" "}
                  </p>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ width: "auto", textAlign: "right" }}>
                <span
                  style={{
                    fontSize: "16px",
                    textAlign: "left",
                    fontWeight: "500",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  $
                  {this.props.modifierPrice ? (
                    <>
                      {parseFloat(
                        this.props.price * this.props.num +
                        parseFloat(
                          parseFloat(this.props.modifierPrice / 100).toFixed(
                            2
                          )
                        )
                      ).toFixed(2)}
                    </>
                  ) : (
                    <>
                      {parseFloat(this.props.price * this.props.num).toFixed(2)}
                    </>
                  )}
                </span>
              </div>
            </div>
            {Object.keys(this.props.modifiers).length ? (
              <>
                {Object.values(this.props.modifiers).map((modifier) => {
                  return (
                    <p
                      style={{
                        cursor: "pointer",
                        margin: "3px 0",
                        padding: "0",
                        fontSize: "13px",
                        fontWeight: "500",
                        verticalAlign: "middle",
                      }}
                    >
                      {/* {console.log(modifier)} */}
                      {modifier.modifier_name}
                      +$
                      {parseFloat(modifier.modifier_price / 100).toFixed(2)}
                    </p>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <p
                  style={{
                    cursor: "pointer",
                    margin: "0",
                    padding: "0",
                    fontSize: "15px",
                    color: "red",
                    verticalAlign: "middle",
                    fontWeight: "700",
                  }}
                >
                  <a onClick={this.handleZero}>
                    <MultiLingualContent contentID="Remove" /> <FiTrash2 className="mr-2 shadow-sm" size="1em" />
                  </a>
                </p>
              </div>
              <div
                className="d-flex edit-buttons"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#444",
                  padding: "0 10px",
                }}
              >
                <div>
                  <Button
                    onClick={this.handleMinus}
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                      background: "transparent",
                    }}
                  >
                    -
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outline-dark"
                    className="disabled"
                    style={{ color: "#fff" }}
                  >
                    {this.props.num}
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={this.handlePlus}
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                      background: "transparent",
                    }}
                  >
                    +
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    EDIT: (title, num, modifications, instructions, index, edit_type, id) => {
      dispatch(Edit(title, num, modifications, instructions, index, edit_type, id));
    },
    Zero: (title, id, index) => {
      dispatch(Zero(title, id, index));
    },
    Sub: (title, id) => {
      dispatch(Sub(title, id));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,

    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartTableItemSide);