import React, { Component, Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import CustomerRouters from "./Routers/Customers";
import MerchantRouters from "./Routers/Merchants";
import OrderRouters from "./Routers/Orders";
import WebsiteRouters from "./Routers/Website";
import initialization from "./utils/functions";
import { INIT, LOC, Add, Sub, Utils } from "./redux/actions/cartActions";
import { TABLE_INIT } from "./redux/actions/tableActions";
import { CustomerData } from "./redux/actions/customerActions";
import { RestaurantData } from "./redux/actions/restauratActions";
import { LanguageContext } from "./context/multilingualContext";
import { translations } from "./context/translations";
import "./App.css";
import MultiLingualContent from "./context/multilingualContent";
import Redirect from './pages/redirect/Redirect';

class App extends Component {
  state = {
    loading: true,
    type: "",
    tableOccupied: false,
    table_availability: false,
    temporarily_closed: false,
    restaurant_name: "",
    language: this.props.language || "en",
    redirect: false,
    restaurant_id: ``,
    error: false,
  };
  translatedWord = (word) => translations[this.state.language] ? translations[this.state.language][word] : translations["en"][word]
  async componentDidMount() {
    window.scrollTo(0, 0);

    var restaurant_id = window.location.pathname.split("/")[1];
    await initialization(this.state, this.props, restaurant_id, this.setValue);
  }
  setValue = (obj) => {
    this.setState({
      ...obj
    })
  }
  handleClearNeworder = async () => {
    try {
      const { table_order_id, table_orders_id } = this.props.table;
      const rawAjaxCall = await fetch(
        `/api/v1/merchant/post/table/status/${this.props.restaurant.id}/${this.props.restaurant.integrate}?id=${this.props.tableId}&table_order_id=${table_order_id}`,
        {
          method: "POST",
          body: JSON.stringify({ orderIds: table_orders_id }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responded = await rawAjaxCall.json();
      if (responded) {
        window.location.reload(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  handlePayNow = () => {
    window.location.href = `/${this.props.restaurant.id}/table/payment/${this.props.tableId}?table_order_Id=${this.props.table.table_order_id}`;
  };
  render() {
    return (
      <LanguageContext.Provider
        value={{ language: this.state.language, setLanguage: this.setLanguage }}
      >
        <Suspense fallback={<h1></h1>}>
          <div className="App">
            {this.state.redirect ? <Redirect {...this.props} restaurantId={this.state.restaurant_id} data={this.state.redirectData} /> : this.state.type === "website" ? (
              <WebsiteRouters {...this.props} />
            ) : (
              <>
                {this.state.loading ? (
                  <>
                    <div>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          minHeight: "100vh",
                          transition: "0.5s ease-in-out 0s",
                          height: "100%",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <BeatLoader size={12} color="#ec7211" />
                        {this.state.type === "merchant" ? (
                          <></>
                        ) : (
                          <p style={{ margin: "0", fontSize: "15px" }}>
                            Loading menu
                          </p>
                        )}
                        <p style={{ margin: "0", fontSize: "15px" }}>
                          <i className="fa fa-truck"></i> Powered By{" "}
                          <strong>TrySimmer</strong>
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.type === "merchant" ? (
                      <MerchantRouters {...this.props} />
                    ) : (
                      <>
                        {this.state.type === "customer" ? (
                          <CustomerRouters {...this.props} />
                        ) : (
                          <OrderRouters {...this.props} />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <Modal
              show={this.state.table_availability}
              size="lg"
              aria-labelledby="ordersucess"
              centered
            >
              <Modal.Header>
                {" "}
                <div className="d-flex flex-column justify-content-center align-items-center py-4">
                  {" "}
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Oops, the Table you looking for is not available to serve
                    you now.
                  </div>
                </div>
              </Modal.Header>
            </Modal>
            <Modal
              show={this.state.tableOccupied}
              size="lg"
              aria-labelledby="ordersucess"
              centered
            >
              <Modal.Body>
                <div
                  className="d-flex flex-column justify-content-center align-items-center py-4"
                  style={{
                    gap: "26px",
                  }}
                >
                  <Button
                    onClick={() => this.setState({ tableOccupied: false })}
                    className="btn-table-order"
                  >
                    <MultiLingualContent contentID="Continue Order" />
                  </Button>
                  <Button
                    onClick={this.handlePayNow}
                    className="btn-table-order"
                  >
                    <MultiLingualContent contentID="Pay Now" />
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.temporarily_closed}
              size="lg"
              aria-labelledby="ordersucess"
              centered
            >
              <Modal.Header>
                {" "}
                {/* <div className="d-flex flex-column justify-content-center align-items-center py-4"> */}{" "}
                <div
                  className="py-4"
                  style={{
                    textAlign: "center",
                    // padding: "3em 0 0",
                    fontWeight: "600",
                    fontSize: "16px",
                    margin: " 0 auto",
                  }}
                >
                  {" "}
                  {this.state.restaurant_name} is Temporarily not available to
                  take orders now.
                </div>
                {/* </div> */}
              </Modal.Header>
            </Modal>
          </div>
        </Suspense>
      </LanguageContext.Provider>
    );
  }
}

function MainApp(props) {
  const [searchParams] = useSearchParams();
  const phone = window.atob(searchParams.get("ph") ? searchParams.get("ph") : "");
  // eslint-disable-next-line
  const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  const isNum = re.test(phone);
  const code = searchParams.get("code");
  const connect = searchParams.get("connect");
  const support = searchParams.get("support");
  const custom = searchParams.get("custom");
  const language = searchParams.get("language");
  const tableId = searchParams.get("tableId");
  const method = searchParams.get("method");

  return (
    <>
      <App
        {...props}
        phone={phone}
        isNum={isNum}
        code={code}
        is_support={support && support === "phonedash" ? true : false}
        connect={connect}
        custom={custom}
        tableId={tableId}
        language={language}
        method={method}
        navigate={useNavigate()}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    RestaurantData: (data) => {
      dispatch(RestaurantData(data));
    },
    TABLE_INIT: (data) => {
      dispatch(TABLE_INIT(data));
    },
    INIT: (data) => {
      dispatch(INIT(data));
    },
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
    LOC: (data) => {
      dispatch(LOC(data));
    },
    Add: (title, num, id, modifications, instructions, productId) => {
      dispatch(Add(title, num, id, modifications, instructions, productId));
    },
    Sub: (title, num, id) => {
      dispatch(Sub(title, num, id));
    },
    Utils: (data) => {
      dispatch(Utils(data));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
    customer: state.customer,
    table: state.table,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
