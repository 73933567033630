import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import { useSearchParams } from "react-router-dom";
import MultiLingualContent from '../context/multilingualContent';

function OrderPopUp(props) {
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId");
    const [open, setOpen] = useState(true);
    const handleConinue = () => {
        window.location.href = `/${props.restaurant.id}`
    }
    return (
        <>
            <React.Fragment>
                <Container className="my-auto Checkout" style={{ minHeight: '100vh' }}>
                    <Modal
                        show={open}
                        size="md"
                        aria-labelledby="ordersucess"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="ordersucess">
                                Order Received
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                            <MultiLingualContent contentID="Your order has been received. Your order will be ready in 10 minutes." /> Your order ID - <b>{orderId}</b>
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button style={{ marginBottom: '0' }} onClick={handleConinue}>Continue</Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </React.Fragment>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        restaurant: state.restaurant
    };
};


export default connect(mapStateToProps, null)(OrderPopUp);
