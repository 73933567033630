import React, { useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./Aichat.css";
import { useSelector } from "react-redux";
export default function AiChatBot(props) {
  const restaurant = useSelector((state) => state.restaurant);
  const { id: restaurant_id } = restaurant;
  let [paramsData] = useSearchParams();
  [paramsData] = [Object.fromEntries(paramsData)];
  const [displayChat, setDisplayChat] = useState(false);
  console.log("aichat");
  useLayoutEffect(() => {
    const { id: restaurant_id } = restaurant;
    if (paramsData?.chat_bot_type === "aiorders") {
      setDisplayChat(true);
    }
    if (
      ["square-test", "square-pie-guys"].includes(restaurant_id.toLowerCase())
    ) {
      setDisplayChat(true);
    }
  }, []);
  return displayChat ? (
    <>
      {/* <iframe
        // src={`http://localhost:3000/chatbot/ai-order/${restaurant_id}`}
        src={`https://ai-chatbot-vertex.web.app/chatbot/ai-order/${restaurant_id}`}
        title="chat"
        className="chat-section"
        style={{
          border: "0",
          position: "fixed",
          right: "0",
          bottom: "0",
          zIndex: "999",
          width: "380px",
          height: "100vh",
        }}
      ></iframe> */}
      <div>
        <div className="ai-section">
          <div>
            <input type="checkbox" id="click" />
            <label for="click">
              <i className="fa fa-comments-o"></i>
              <i className="fa fa-close" aria-hidden="true"></i>
            </label>
            <div className="wrapper">
              <div className="chat-box">
                <iframe
                  // src={`http://localhost:3000/chatbot/ai-order/${restaurant_id}`}
                  src={`https://ai-chatbot-vertex.web.app/chatbot/ai-order/${restaurant_id}`}
                  title="chat"
                  className="chat-section"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}