import React, { useEffect, useState, Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import CartTableItem from "../../components/CartTableItem";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CreditCardInput from "react-credit-card-input";
import axios from "axios";

import CartTotalsCheckout from "../../components/CartTotalsCheckout";
import BrandingHeader from "../../components/BrandingHeader";
import Card from "react-bootstrap/Card";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Form from "react-bootstrap/Form";
import {
  postNewOrderOnlineCustom,
  builtStripItems,
  postNewOrderOnlineCustomPay,
} from "../../utils/api";
import { Set, Reset } from "../../redux/actions/cartActions";
import {
  removeCookies,
  round,
  squareInit,
  squarePaymentsInit,
  squarePaymentsReInit,
  squareTokenize,
} from "../../utils/functions";
import { FaUserEdit } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentRequestButtonElement,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { StatusModalFail } from "./StatusModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import { emailValidation } from "../../utils/validation";
import CartTableCheckoutSquare from "../../components/table/square/CartTableCheckoutSquare";

import MultiLingualContent from '../../context/multilingualContent';
import { translations } from "../../context/translations";

function resetCookiesStorage(restaurantId) {
  localStorage.removeItem(restaurantId);
  localStorage.removeItem(`${restaurantId}_sms_orders`);
  const keyName = `${restaurantId}_sms_orders_id`;
  removeCookies(keyName)
}

const RequestPay = (props) => {
  console.log("googelpay");
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [total, setTotal] = useState(
    parseFloat(
      (
        parseFloat(props.total) +
        parseFloat(props.tax) +
        parseFloat(props.tip)
      ).toFixed(2)
    )
  );
  // console.log(parseInt(parseInt(total * 100) + (parseInt(total * 100) * 0.03)))
  const [modalShow, setModalShow] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [points, setPoints] = useState(0);
  const [name, setName] = useState("");
  const [res_id, setResId] = useState(props.res_id);

  const [payment_status_failure, setPaymentStatusFailure] = useState(false);
  const [payment_status_msg, setPaymentStatusMsg] = useState("");
  const [sentTo, setSentTo] = useState("");

  useEffect(() => {
    if (stripe) {
      let amount = 0;
      let action = props.conv_fee;
      let limit = props.conv_limit;
      let rate = props.conv_rate;
      let tip = props.tipPercent;
      let tax = props.taxPercent;
      let discountPercent = props.discount;
      let total = Number(props.total);
      let orderTotal = total;
      const { checkoutData } = props;
      if (checkoutData.couponCode.percentage) {
        console.log(checkoutData);
        amount = Number((checkoutData.finalTotal * 100).toFixed(2));
      } else if (discountPercent) {
        let discount = Number(((total * discountPercent) / 100).toFixed(2));
        let applyiedTotal = total - discount;
        let to_tax = applyiedTotal * tax;
        let to_tip = applyiedTotal * (tip / 100);
        orderTotal = Number((applyiedTotal + to_tax + to_tip).toFixed(2));

        if (action) {
          let convenience_fee = Number(((orderTotal * rate) / 100).toFixed(2));
          let limitfee = Number((limit * 100).toFixed(2));
          if (limit && convenience_fee > limitfee) {
            convenience_fee = limitfee;
          }
          orderTotal += convenience_fee;
          amount = Number(orderTotal.toFixed(2)) * 100;
        } else {
          amount = Number(orderTotal.toFixed(2)) * 100;
        }
      } else {
        console.log("non-discount");
        if (action) {
          orderTotal += Number((total * (tip / 100) + total * tax).toFixed(2));

          let convenience_fee = Number(((orderTotal * rate) / 100).toFixed(2));
          let limitfee = limit * 100;
          if (limit && convenience_fee > limitfee) {
            convenience_fee = limitfee;
          }
          orderTotal += convenience_fee;
          amount = orderTotal * 100;
        } else {
          orderTotal = Number(
            (total + total * (tip / 100) + total * tax).toFixed(2)
          );
          amount = orderTotal * 100;
        }
      }
      let pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: `Total`,
          amount: Math.round(amount),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        pr.on("paymentmethod", async function (e) {
          //2
          setName(e.paymentMethod.billing_details.name);
          const paymentDetails = {
            payment_method: e.paymentMethod.id,
            shipping: {
              name: e.paymentMethod.billing_details.name,
              phone: e.paymentMethod.billing_details.phone
                ? e.paymentMethod.billing_details.phone
                : "",
              address: {
                line1: `${e.paymentMethod.billing_details.address.line1
                  ? e.paymentMethod.billing_details.address.line1
                  : ""
                  }, ${e.paymentMethod.billing_details.address.line2
                    ? e.paymentMethod.billing_details.address.line2
                    : ""
                  }`,
                city: e.paymentMethod.billing_details.address.city
                  ? e.paymentMethod.billing_details.address.city
                  : "",
                country: e.paymentMethod.billing_details.address.country
                  ? e.paymentMethod.billing_details.address.country
                  : "",
                state: e.paymentMethod.billing_details.address.state
                  ? e.paymentMethod.billing_details.address.state
                  : "",
                postal_code: e.paymentMethod.billing_details.address.postal_code
                  ? e.paymentMethod.billing_details.address.postal_code
                  : "",
              },
            },
          };
          const customer_data = {
            'name': e.paymentMethod.billing_details.name
              ? e.paymentMethod.billing_details.name
              : "",
            'phone': e.paymentMethod.billing_details.phone
              ? e.paymentMethod.billing_details.phone
              : "",
            'email': e.paymentMethod.billing_details.email
              ? e.paymentMethod.billing_details.email
              : ""
          }
          console.log(amount);
          let methodData = {
            method: "POST",
            body: JSON.stringify({
              paymentDetails,
              customer_data,
              amount: amount,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          };
          let response = await fetch(
            `/api/order/${res_id}/create-payment-intent`,
            methodData
          )
          response = await response.json()
          if (response.error) {
            if (props.support) {
              setPaymentStatusFailure(true);
              setPaymentStatusMsg(response);
            }
            e.complete("fail");
          } else {
            // e.complete('success');
            let stripe_customer_id = response.customer
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              response.client_secret
            );
            if (error) {
              console.log(error);
              return;
            }
            if (paymentIntent.status === "succeeded") {
              var data = {};
              data["stripe_customer_id"] = stripe_customer_id;
              data["name"] = e.paymentMethod.billing_details.name
                ? e.paymentMethod.billing_details.name
                : "";

              data["mobile"] = e.paymentMethod.billing_details.phone
                ? e.paymentMethod.billing_details.phone
                : "";
              data["email"] = e.paymentMethod.billing_details.email
                ? e.paymentMethod.billing_details.email
                : "";

              if (data["mobile"]) {
                setSentTo(data["mobile"]);
              } else {
                setSentTo(data["email"]);
              }
              data["notes"] = props.notes ? props.notes : "";
              data["restaurant_id"] = res_id;
              data["customer_id"] = props.customer_id;
              data["payment_id"] = paymentIntent.id;
              data["address"] = `${e.paymentMethod.billing_details.address.line1
                ? e.paymentMethod.billing_details.address.line1
                : ""
                }, ${e.paymentMethod.billing_details.address.line2
                  ? e.paymentMethod.billing_details.address.line2
                  : ""
                }`;
              data["city"] = e.paymentMethod.billing_details.address.city
                ? e.paymentMethod.billing_details.address.city
                : "";
              data["country"] = e.paymentMethod.billing_details.address.country
                ? e.paymentMethod.billing_details.address.country
                : "";
              data["state"] = e.paymentMethod.billing_details.address.state
                ? e.paymentMethod.billing_details.address.state
                : "";
              data["zip"] = e.paymentMethod.billing_details.address.postal_code
                ? e.paymentMethod.billing_details.address.postal_code
                : "";
              if (e.walletName === "googlePay") {
                data["payment"] = "google";
              } else if (e.walletName.toLowerCase().includes('apple')) {
                data["payment"] = "apple";
              } else {
                data["payment"] = "wallet";
              }
              data["tableId"] = props.tableId;
              data["tableName"] = props.tableName;
              data["tableOrderId"] = props.tableOrderId;
              data["tableOrder"] = props.tableOrder;
              data["method"] = props.method;
              const response = await postNewOrderOnlineCustomPay(
                data,
                props.items,
                props.tips,
                props.taxes,
                amount,
                props.connect,
                props.discount,
                action,
                limit,
                rate,
                props.custom,
                checkoutData,
                props.cart
              );
              // console.log(response)
              if (!response) {
                if (props.support) {
                  setPaymentStatusFailure(true);
                  setPaymentStatusMsg(response);
                }
                e.complete("fail");
              } else {
                e.complete("success");
                resetCookiesStorage(res_id)
                if (props.tableId) {
                  window.location.href = props?.language ? `/${res_id}/table/thankyou/${props.tableId}?order_id=${response.tableOrderId}&language=${props.language}` : `/${res_id}/table/thankyou/${props.tableId}?order_id=${response.tableOrderId}`;
                } else {
                  setOrderId(response.id);
                  setPoints(response.points);
                  setModalShow(true);
                }
              }
            } else {
              if (props.support) {
                setPaymentStatusFailure(true);
                setPaymentStatusMsg(response);
              }
              console.warn(
                `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
              );
            }
          }
        });
        // pr.on('token', function (e) { // 1
        //   console.log(e)
        // });
        // pr.on('source', function (e) { //3
        //   console.log(e)
        // });
        pr.on("cancel", function () {
          alert("Payment Cancelled");
        });
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);
  const handleConinue = () => {
    window.location.href = `/${props.res_id}`;
  };
  const onContinue = () => {
    setPaymentStatusMsg("");
    setPaymentStatusFailure(!payment_status_failure);
    window.location.href = `/${props.res_id}`;
  };
  if (paymentRequest) {
    return (
      <>
        <PaymentRequestButtonElement
          className="payment-element"
          options={{ paymentRequest }}
        />{" "}
        <p className="payment-or">OR</p>
        <Modal
          show={modalShow}
          size="md"
          aria-labelledby="ordersucess"
          centered
        >
          {/* {res_id.toLowerCase() ===
            "vishnuji-ki-rasoi-festival-mela".toLowerCase() ? (
            <Modal.Header>
              <Modal.Title>Aishwarya Majmudar on October 15, 2022</Modal.Title>
            </Modal.Header>
          ) : (
            <></>
          )} */}
          {res_id.toLowerCase() ===
            "vishnuji-ki-rasoi-festival-mela".toLowerCase() ? (
            <Modal.Body>
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  {`Your Order ID`}
                </p>
                <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                  {" "}
                  <b>{orderId}</b>
                </p>

                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {`Hi ${name}, You will receive an order confirmation message on ${sentTo}`}
                </p>

                {/* <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  You
                </p> */}
                {/* <p style={{ textAlign: "center" }}>
                  {res_id == "vishnuji-ki-rasoi-festival-mela"
                    ? `Your order has been received.`
                    : `Your order has been received. Your order will be ready for pick
                      up in ${props.deliverytime} minutes.`}
                </p> */}
                <div>
                  <p>
                    &#8226; Thanks for ordering. This order is valid only at the
                    event venue and expires at the close of the food booth at
                    the event location.
                  </p>
                  <p>
                    &#8226; This order <b>cannot be redeemed</b> at the
                    restaurant location.
                  </p>
                  <p>
                    &#8226; This order is final. <b>No refund</b> will be
                    provided if the order is not picked up at the event
                    location.
                  </p>
                </div>
              </>
            </Modal.Body>
          ) : (
            <Modal.Body>
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  {`Hi ${name}, Your Order ID`}
                </p>
                <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                  {" "}
                  <b>{orderId}</b>
                </p>
                <p style={{ textAlign: "center" }}>
                  {`Your order has been received. Your order will be ready for pick
                      up in ${props.deliverytime} minutes.`}
                  {/* {res_id == "vishnuji-ki-rasoi-festival-mela"
                        ? `Your order has been received.`
                        : `Your order has been received. Your order will be ready for pick
                      up in ${props.deliverytime} minutes.`} */}
                </p>
                {points ? (
                  <p style={{ textAlign: "center" }}>
                    {`You have earned ${points} points as a reward for this order.`}
                  </p>
                ) : (
                  <></>
                )}
              </>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button style={{ marginBottom: "0" }} onClick={handleConinue}>
              Continue
            </Button>
            {/* <Link
              style={{ marginBottom: "0" }}
              to={`/${props.res_id}/feedback`}
              state={orderId}
            >
              Continue
            </Link> */}
          </Modal.Footer>
        </Modal>
        {payment_status_failure ? (
          <StatusModalFail
            support={props.support}
            modalShow={payment_status_failure}
            msg={payment_status_msg}
            onContinue={onContinue}
          />
        ) : (
          <></>
        )}
      </>
    );
  }

  // Use a traditional checkout form.
  return <></>;
};
class PayRestaurant extends Component {
  state = {
    firstName: "",
    name: "",
    lastName: "",
    order_placed: false,
    payment_gateway: this.props.restaurant.payment_gateway,
    tableOrderId: this.props.table.table_order_id,
    tableOrder: false,
    tableId: this.props.table.table_id,
    tableName: this.props.table.table_name,
    payment_status_failure: false,
    payment_status_msg: "",
    orderId: "",
    country: "US",
    email: "",
    total: 0,
    mobile: "",
    type: "online",
    notes: "",
    updated: true,
    customer_id: "",
    modalShow: false,
    edit: false,
    isDisable: false,
    timeZone: this.props.restaurant.restaurant.timeZone,
    checkoutId: "",
    amount: 0,
    stripePromise: "",
    cvv: "",
    expiry: "",
    googlePay: "",
    cardNum: "",
    loader: false,
    discountPrecent: 0,
    coupon_value: "",
    coupon_error: "",
    applied_coupon_code: "",
    coupon_discount_amount: 0,
    coupon_discount_percentage: 0,
    coupon_applied: true,
    points: 0,
    method: this.props.method,
    reInitGooglePay: true,
    cardDecline: false
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      if (this.state.payment_gateway === "square") {
        this.squareInit(
          this.props.restaurant.restaurant.app_id,
          this.props.restaurant.restaurant.location_id
        );
      } else {
        const stripePromise = await loadStripe(this.props.stripe_pk);
        this.setState({
          stripePromise,
        });
      }

      const response = await fetch(
        `/api/v1/merchant/${this.props.restaurant.id}/${this.props.restaurant.integrate}/discount`
      );
      const json = await response.json();
      const tableId = window.location.pathname.split("/")[
        window.location.pathname.split("/").indexOf("table") + 1
      ]
        ? window.location.pathname.split("/")[
        window.location.pathname.split("/").indexOf("table") + 1
        ]
        : 0;
      if (tableId) {
        this.setState({
          tableOrder: true,
          table: tableId,
        });
      }
      if (json.isDiscount) {
        this.setState({ discountPrecent: json.percentage });
      }
    } catch (e) {
      console.log(e);
    }
    this.setState({
      notes: this.props.cart.notes ? this.props.cart.notes : "",
      customer_id: this.props.customer.id ? this.props.customer.id : "",
      restaurant_id: this.props.restaurant.id ? this.props.restaurant.id : "",
      mobile: this.props.customer.phone ? this.props.customer.phone : "",
    });
  }
  reInitGooglePay = () => {
    this.setState({ reInitGooglePay: false });
    setTimeout(() => this.setState({ reInitGooglePay: true }), 1000);
  };
  setValue = (obj) => {
    this.setState({
      ...obj,
    });
  };
  squareInit = async (app_id, location_id) => {
    const payments = await squareInit(app_id, location_id);
    this.setState({
      squarePayment: payments,
    });
    let amount = 0;
    let {
      convenience_fee: action,
      convenience_rate: rate,
      convenience_limit: limit,
    } = this.props.restaurant;
    let { tip, tax } = this.props;
    let discountPercent = this.state.discountPrecent;
    let total = Number(parseFloat(this.props.totalPrice).toFixed(2));
    let orderTotal = total;
    let { checkout: checkoutData } = this.props;
    if (checkoutData.couponCode.percentage) {
      amount = Number((checkoutData.finalTotal * 100).toFixed(2));
    } else if (discountPercent) {
      let discount = Number(((total * discountPercent) / 100).toFixed(2));
      let applyiedTotal = total - discount;
      let to_tax = applyiedTotal * tax;
      let to_tip = applyiedTotal * (tip / 100);
      orderTotal = Number((applyiedTotal + to_tax + to_tip).toFixed(2));

      if (action) {
        let convenience_fee = Number(((orderTotal * rate) / 100).toFixed(2));
        let limitfee = Number((limit * 100).toFixed(2));
        if (limit && convenience_fee > limitfee) {
          convenience_fee = limitfee;
        }
        orderTotal += convenience_fee;
        amount = Number(orderTotal.toFixed(2)) * 100;
      } else {
        amount = Number(orderTotal.toFixed(2)) * 100;
      }
    } else {
      console.log("non-discount");
      if (action) {
        orderTotal += Number((total * (tip / 100) + total * tax).toFixed(2));

        let convenience_fee = Number(((orderTotal * rate) / 100).toFixed(2));
        let limitfee = limit * 100;
        if (limit && convenience_fee > limitfee) {
          convenience_fee = limitfee;
        }
        orderTotal += convenience_fee;
        amount = orderTotal * 100;
      } else {
        orderTotal = Number(
          (total + total * (tip / 100) + total * tax).toFixed(2)
        );
        amount = orderTotal * 100;
      }
    }
    if (this.props.table.table_id) {
      await squarePaymentsInit(payments, this.setValue);
    } else {
      await squarePaymentsInit(
        payments,
        this.setValue,
        parseFloat(parseFloat(amount / 100).toFixed(2))
      );
    }
  };
  squarePaymentsReInitialize = async (total) => {
    squarePaymentsReInit(
      this.state,
      total,
      this.state.squarePayment,
      this.setValue
    );
  };

  createPayment = async (token) => {
    const res = await postNewOrderOnlineCustom(
      this.state,
      this.props.orderCart,
      this.props.tip,
      this.props.tax,
      this.props.restaurant.convenience_fee,
      this.props.restaurant.convenience_rate,
      this.props.restaurant.convenience_limit,
      this.props.customer.connect,
      this.state.discountPrecent,
      this.props.customer.custom,
      this.props.checkout,
      this.props.cart,
      {
        payment_gateway: this.state.payment_gateway,
        payment_type: this.state.payment_type,
        token: token,
        location_id: this.props.restaurant.location_id,
      },
    );
    resetCookiesStorage(this.state.restaurant_id)
    if (this.state.tableId) {
      window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&language=${this.props.restaurant.language}`;
    } else {
      this.setState({ isDisable: false, loader: false });
      if (Object.keys(res).includes("msg") && res.msg == "Order Placed") {
        this.setState({
          payment_status_failure: true,
          orderId: res.id,
          payment_status_msg: res,
          order_placed: true,
        });
      } else if (Object.keys(res).includes("id")) {
        let points = res.points;
        this.setState({
          modalShow: true,
          orderId: res.id,
          loader: false,
          points,
        });
      } else {
        if (this.props.support) {
          this.setState({
            payment_status_failure: true,
            payment_status_msg: res,
          });
        } else {
          // toast.error(res);
          this.setState({ cardDecline: true })
        }
      }
    }
  };

  handlePaymentMethodSubmission = async (event, paymentMethod) => {
    event.preventDefault();
    let checkMailValid = emailValidation(this.state.email);
    try {
      if (
        this.props.customer.id ||
        paymentMethod.methodType.toLowerCase().includes("google") ||
        (this.state.name.length > 0 &&
          this.state.email.length > 0 &&
          checkMailValid &&
          this.state.mobile.length === 12)
      ) {
        this.setState({ isDisable: true, loader: true });
        console.log('paymentMethod', paymentMethod)
        const token = await squareTokenize(paymentMethod, this.setValue);
        await this.createPayment(token);
      } else {
        if (this.state.name === "") {
          document.getElementById("name").style.boxShadow =
            "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        }
        if (this.state.mobile.length < 12) {
          document.querySelector(".react-tel-input input").style.boxShadow =
            "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        }
        if (this.state.email === "" || !checkMailValid) {
          document.getElementById("email").style.boxShadow =
            "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        }
      }
    } catch (e) {
      console.log(e)
      this.setState({ isDisable: false, loader: false, cardDecline: true });
    }
  };

  handleCardPayment = async (e) => {
    e.preventDefault();
    this.setState({
      payment_type: "card",
    });
    const res = await this.handlePaymentMethodSubmission(e, this.state.card);
  };

  handleApplePayment = async (e) => {
    this.setState({
      payment_type: "apple",
      isDisable: true,
      loader: true,
    });
    await this.handlePaymentMethodSubmission(e, this.state.applePay);
  };
  handleGooglePayment = async (e) => {
    this.setState({
      payment_type: "google",
      isDisable: true,
      loader: true,
    });
    await this.handlePaymentMethodSubmission(e, this.state.googlePay);
  };
  validateFormAddress() {
    return !(
      this.state.name.length > 0 &&
      this.state.email.length > 0 &&
      this.state.mobile.length > 0
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.target.style.boxShadow = "none";
  };
  handleEdit = (e) => {
    this.setState({
      updated: false,
      edit: true,
    });
  };

  handlePaymentEdit = (e) => {
    this.setState({
      updated: false,
      editPayment: true,
    });
  };

  handleNewOrder = async (event) => {
    event.preventDefault();

    if (
      (this.state.firstName.length > 0 || this.state.name.length > 0) &&
      this.state.mobile.length === 12 &&
      this.state.email.length > 0 &&
      this.state.cvv.length > 0 &&
      this.state.expiry.length > 0 &&
      this.state.cardNum.length > 0
    ) {
      this.setState({ isDisable: true, loader: true });
      var res = {};
      // if (!(this.props.restaurant.id === "naan-n-curry-bayarea-pl")) {
      // } else {
      const checkoutItems = await builtStripItems(
        this.props.restaurant.id,
        this.props.orderCart,
        this.props.tip,
        this.props.tax,
        this.props.restaurant.convenience_fee,
        this.props.restaurant.convenience_rate,
        this.props.restaurant.convenience_limit,
        false,
        this.state.discountPrecent,
        this.props.checkout
      );
      this.setState({
        checkoutId: checkoutItems.checkoutId,
        amount: checkoutItems.amount,
        total: parseInt(
          (this.props.totalPrice +
            this.props.tax * this.props.totalPrice +
            (this.props.tip / 100) * this.props.totalPrice) *
          100
        ),
      });
      res = await postNewOrderOnlineCustom(
        this.state,
        this.props.orderCart,
        this.props.tip,
        this.props.tax,
        this.props.restaurant.convenience_fee,
        this.props.restaurant.convenience_rate,
        this.props.restaurant.convenience_limit,
        this.props.customer.connect,
        this.state.discountPrecent,
        this.props.customer.custom,
        this.props.checkout,
        this.props.cart,
        {
          payment_gateway: this.state.payment_gateway,
        }
      );
      resetCookiesStorage(this.state.restaurant_id)
      if (this.state.tableId) {
        window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&language=${this.props.restaurant.language}`;
      } else {
        this.setState({ isDisable: false, loader: false });
        if (Object.keys(res).includes("msg") && res.msg == "Order Placed") {
          this.setState({
            payment_status_failure: true,
            orderId: res.id,
            payment_status_msg: res,
            order_placed: true,
          });
        } else if (Object.keys(res).includes("id")) {
          let points = res.points;
          this.setState({
            modalShow: true,
            orderId: res.id,
            loader: false,
            points,
          });
        } else {
          if (this.props.support) {
            this.setState({
              payment_status_failure: true,
              payment_status_msg: res,
            });
          } else {
            // toast.error(res);
            this.setState({ cardDecline: true })
          }
        }
      }
    }
    // }
    else {
      if (this.state.name === "") {
        document.getElementById("name").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.getElementById("name").focus();
      }
      if (this.state.mobile.length < 12) {
        document.querySelector(".react-tel-input input").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.querySelector(".react-tel-input input").focus();
      }
      if (this.state.email === "") {
        document.getElementById("email").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.getElementById("email").focus();
      }
      if (
        this.state.cvv === "" ||
        this.state.expiry === "" ||
        this.state.cardNum === ""
      ) {
        document.getElementById("field-wrapper").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        document.getElementById("field-wrapper").focus();
      }
    }
  };
  onContinue = () => {
    this.setState({
      payment_status_msg: "",
      payment_status_failure: !this.state.payment_status_failure,
    });
    window.location.href = `/${this.props.restaurant.id}`;
  };
  handleBlur = (e) => {
    // e.target.style.boxShadow = "none";
  };
  handleConinue = () => {
    window.location.href = `/${this.props.restaurant.id}`;
    this.props.Reset();
  };
  translatedWord = (word) => translations[this.props.restaurant.language] ? translations[this.props.restaurant.language][word] : translations["en"][word]
  render() {
    const checkout = (
      <Row>
        {/* {console.log(this.state)} */}
        <Col xs={12} lg={6}>
          {/* <h4 className="mr-2 my-auto">Your Cart</h4> */}

          <div className="payment-button" style={{ paddingTop: "23px" }}>
            {this.state.payment_gateway === "square" ? (
              <>
                <div
                  id="google-pay-button"
                  onClick={this.handleGooglePayment}
                ></div>
                <div
                  id="apple-pay-button"
                  onClick={this.handleGooglePayment}
                ></div>
              </>
            ) : (
              <>
                {this.state.stripePromise && this.state.reInitGooglePay ? (
                  <div style={{ width: "100%" }}>
                    {" "}
                    <Elements stripe={this.state.stripePromise}>
                      <RequestPay
                        support={this.props.support}
                        notes={this.state.notes}
                        customer_id={this.state.customer_id}
                        total={parseFloat(this.props.totalPrice).toFixed(2)}
                        name={this.props.name}
                        res_id={this.props.restaurant.id}
                        items={this.props.orderCart}
                        tip={this.props.tips}
                        tips={this.props.tip}
                        tipPercent={this.props.tip}
                        taxPercent={this.props.tax}
                        tax={
                          parseFloat(this.props.totalPrice) *
                          parseFloat(this.props.tax)
                        }
                        taxes={this.props.tax}
                        conv_fee={this.props.restaurant.convenience_fee}
                        conv_rate={this.props.restaurant.convenience_rate}
                        conv_limit={this.props.restaurant.convenience_limit}
                        deliverytime={this.props.restaurant.deliverytime}
                        connect={this.props.customer.connect}
                        custom={this.props.customer.custom}
                        discount={this.state.discountPrecent}
                        tableId={this.props.table.table_id}
                        tableName={this.props.table.table_name}
                        tableOrderId={this.props.table.table_order_id}
                        tableOrder={this.state.tableOrder}
                        checkoutData={this.props.checkout}
                        method={this.state.method}
                        language={this.props.restaurant.language}
                          cart={this.props.cart}
                      />
                    </Elements>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          <Card className="mt-3">
            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span><MultiLingualContent contentID="Billing Details" /></span>
              {this.state.edit ? (
                <></>
              ) : this.state.customer_id ? (
                <>
                  <FaUserEdit
                    className="mr-1"
                    size="1.5em"
                    onClick={this.handleEdit}
                  />
                </>
              ) : (
                <></>
              )}
            </Card.Header>
            {!this.state.edit && this.props.customer.customerId ? (
              <Card.Body>
                <Card.Text>
                  {this.state.firstName} {this.state.lastName}
                </Card.Text>
                <Card.Text>{this.state.email}</Card.Text>
                <Card.Text>{this.state.mobile}</Card.Text>
              </Card.Body>
            ) : (
              <Form
                // onSubmit={this.handleSubmit}
                className="p-4"
              >
                <Form.Row>
                  <Form.Group as={Col} controlId="name">
                    <Form.Control
                      onChange={this.handleChange}
                      onFocus={this.handleBlur}
                      value={this.state.name}
                      placeholder="Name"
                      required
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Control
                      onFocus={this.handleBlur}
                      onChange={this.handleChange}
                      value={this.state.email}
                      type="email"
                      placeholder="Email"
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="mobile">
                    {/* <Form.Control
                      onChange={this.handleChange}
                      value={this.state.mobile}
                      type="text"
                      placeholder="Phone"
                      required
                    /> */}
                    {(!this.state.mobile.replace("+", "").length ||
                      !this.state.mobile.replace("+1", "").length) && (
                        <span
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            //   textAlign: "center",
                            fontWeight: "500",
                            lineHeight: "15px",
                            display: "inline-block",
                          }}
                        >
                          <span style={{ color: "red" }}>*</span> Please enter the
                          phone number to receive the order confirmation text.
                        </span>
                      )}
                    <PhoneInput
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      disableDropdown={true}
                      country={"us"}
                      onFocus={this.handleBlur}
                      placeholder="Phone"
                      inputclassName="phoneinput"
                      value={this.state.mobile}
                      onChange={(mobile) => {
                        document.querySelector(
                          ".react-tel-input input"
                        ).style.boxShadow = "none";
                        this.setState({ mobile: `+${mobile}` });
                      }}
                    />
                  </Form.Group>
                </Form.Row>

                {this.state.edit ? (
                  <Button
                    block
                    variant="warning"
                    type="submit"
                    disabled={this.validateFormAddress()}
                    onClick={this.updateUser}
                  >
                    Update
                  </Button>
                ) : (
                  <></>
                )}
              </Form>
            )}
          </Card>
          <Modal
            show={this.state.modalShow}
            size="md"
            aria-labelledby="ordersucess"
            centered
          >
            {/* {this.props.restaurant.id.toLowerCase() ===
              "vishnuji-ki-rasoi-festival-mela".toLowerCase() ? (
              <Modal.Header>
                <Modal.Title>
                  Aishwarya Majmudar on October 15, 2022
                </Modal.Title>
              </Modal.Header>
            ) : (
              <></>
            )} */}
            {this.props.restaurant.id.toLowerCase() ===
              "vishnuji-ki-rasoi-festival-mela".toLowerCase() ? (
              <Modal.Body>
                <>
                  <p
                    style={{
                      textAlign: "center",
                      margin: "0px 0 6px 0",
                      paddingTop: " 12px",
                      fontSize: "18px",
                    }}
                  >
                    Your Order ID: {this.state.orderId}
                  </p>

                  <p
                    style={{
                      textAlign: "center",
                      margin: "0px 0 6px 0",
                      paddingTop: " 12px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {`Hi ${this.state.name}, You will receive an order confirmation message on ${this.state.mobile}`}
                  </p>
                  {/* <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                    {" "}
                    <b>{this.state.orderId}</b>
                  </p> */}
                  <div>
                    <p>
                      &#8226; Thanks for ordering. This order is valid only at
                      the event venue and expires at the close of the food booth
                      at the event location.
                    </p>
                    <p>
                      &#8226; This order <b>cannot be redeemed</b> at the
                      restaurant location.
                    </p>
                    <p>
                      &#8226; This order is final. <b>No refund</b> will be
                      provided if the order is not picked up at the event
                      location.
                    </p>
                  </div>
                  {/* <p style={{ textAlign: "center" }}>
                   
                    {this.props.restaurant.id ===
                    "vishnuji-ki-rasoi-festival-mela"
                      ? `Your order has been received.`
                      : `Your order has been received. Your order will be ready for
                        pick up in ${this.props.restaurant.deliverytime} minutes.`}
                  </p> */}
                </>
              </Modal.Body>
            ) : (
              <Modal.Body>
                <>
                  <p
                    style={{
                      textAlign: "center",
                      margin: "0px 0 6px 0",
                      paddingTop: " 12px",
                      fontSize: "18px",
                    }}
                  >
                    Your Order ID
                  </p>
                  <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                    {" "}
                    <b>{this.state.orderId}</b>
                  </p>

                  <p style={{ textAlign: "center" }}>
                    {`Your order has been received. Your order will be ready for
                        pick up in ${this.props.restaurant.deliverytime} minutes.`}
                    {this.state.points ? (
                      <p style={{ textAlign: "center" }}>
                        {`You have earned ${this.state.points} points as a reward for this order.`}
                      </p>
                    ) : (
                      <></>
                    )}
                    {/* {this.props.restaurant.id ===
                    "vishnuji-ki-rasoi-festival-mela"
                      ? `Your order has been received.`
                      : `Your order has been received. Your order will be ready for
                        pick up in ${this.props.restaurant.deliverytime} minutes.`} */}
                  </p>
                </>
              </Modal.Body>
            )}
            <Modal.Footer>
              <Button
                style={{ marginBottom: "0" }}
                onClick={this.handleConinue}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.cardDecline}
            size="lg"
            aria-labelledby="ordersucess"
            centered
            onHide={() => this.setState({ cardDecline: false })}
          >
            <Modal.Header>
              <div
                className="py-4"
                style={{
                  textAlign: "center",
                  // padding: "3em 0 0",
                  fontWeight: "600",
                  fontSize: "16px",
                  margin: " 0 auto",
                }}
              >
                Your Card Declined, Order is not Placed. Please check your card
                details!
              </div>
            </Modal.Header>
            <Modal.Footer>
              <Button onClick={() => this.setState({ cardDecline: false })}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Card className="m">
            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span><MultiLingualContent contentID="Enter card details" /></span>
              {this.state.editPayment ? (
                <></>
              ) : this.state.customer_id ? (
                <>
                  {" "}
                  <FaUserEdit
                    className="mr-1"
                    size="1.5em"
                    onClick={this.handlePaymentEdit}
                  />
                </>
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body style={{ padding: "0" }}>
              {!this.state.editPayment &&
                Object.keys(this.props.customer).length &&
                this.props.customer.cards &&
                this.props.customer.cards.elements.length ? (
                <Card.Body>
                  <Card.Text>{this.state.cardNum}</Card.Text>
                  <Card.Text>Expiry : {this.state.expiry}</Card.Text>
                </Card.Body>
              ) : (
                <>
                  {this.state.payment_gateway === "square" ? (
                    <form id="payment-form">
                      <div id="card-container"></div>
                    </form>
                  ) : (
                    <Form
                      className="payment"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CreditCardInput
                        cardNumberInputProps={{
                          value: this.state.cardNum,
                          onBlur: (e) =>
                          (document.getElementById(
                            "field-wrapper"
                          ).style.boxShadow = "none"),
                          onChange: (e) => {
                            this.setState({
                              cardNum: e.target.value,
                            });
                          },
                        }}
                        cardExpiryInputProps={{
                          value: this.state.expiry,
                          onBlur: (e) =>
                          (document.getElementById(
                            "field-wrapper"
                          ).style.boxShadow = "none"),
                          onChange: (e) => {
                            this.setState({
                              expiry: e.target.value,
                            });
                          },
                        }}
                        cardCVCInputProps={{
                          value: this.state.cvv,
                          onBlur: (e) =>
                          (document.getElementById(
                            "field-wrapper"
                          ).style.boxShadow = "none"),
                          onChange: (e) => {
                            this.setState({
                              cvv: e.target.value,
                            });
                          },
                        }}
                        fieldClassName="card-input form-control"
                      />
                    </Form>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        {this.props.support && this.state.payment_status_failure ? (
          <>
            <StatusModalFail
              order_placed={this.state.order_placed}
              orderId={this.state.orderId}
              support={this.props.support}
              modalShow={this.state.payment_status_failure}
              msg={this.state.payment_status_msg}
              onContinue={this.onContinue}
            />
          </>
        ) : (
          <></>
        )}
        <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={5}>
          {this.props.table.table_id ? (
            <CartTableCheckoutSquare
              handleNewOrder={
                this.state.payment_gateway === "square"
                  ? this.handleCardPayment
                  : this.handleNewOrder
              }
              // handleNewOrder={this.handleCardPayment}
              googlePay={this.squarePaymentsReInitialize}
              reInitGooglePay={this.reInitGooglePay}
              disabled={this.state.isDisable}
              // disabled={!this.state.updated}
              custom={true}
              buttonText="Place Order"
              payment={true}
              total={this.props.total}
              totalPrice={round(this.props.totalPrice)}
            />
          ) : (
            <CartTotalsCheckout
              handleNewOrder={
                this.state.payment_gateway === "square"
                  ? this.handleCardPayment
                  : this.handleNewOrder
              }
              // handleNewOrder={this.handleCardPayment}
              disabled={this.state.isDisable}
              // disabled={!this.state.updated}
              custom={true}
              buttonText={this.translatedWord("Place Order")}
              payment={true}
              total={this.props.total}
              totalPrice={round(this.props.totalPrice)}
            />
          )}
          {/* <CartTotalsCheckout
            handleNewOrder={this.state.payment_gateway === 'square' ? this.handleCardPayment : this.handleNewOrder}
            disabled={this.state.isDisable}
            squarePaymentsReInit={this.squarePaymentsReInitialize}
            // disabled={!this.state.updated}
            custom={true}
            buttonText="Place Order"
            payment={true}
            total={this.props.total}
            totalPrice={round(this.props.totalPrice)}
          /> */}
        </Col>
      </Row>
    );
    // eslint-disable-next-line
    const needToLogin = (
      <div className="text-center">
        <h2>You must Login or Signup to checkout</h2>
        <Link to={`/${this.props.restaurant.id}/login-signup`}>
          <Button variant="warning" className="mt-4">
            To Login/Signup
          </Button>
        </Link>
      </div>
    );

    return (
      <>
        {/* <BrandingHeader /> */}
        <div className="cart-header">
          <p>{this.props.restaurant.restaurant.name}</p>
          <button
            onClick={() => window.history.back()}
            style={{
              padding: "5px 10px",
              background: "none",
              border: "0",
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            <FontAwesomeIcon icon={faClose} size={"1x"} />
          </button>
        </div>
        <React.Fragment>
          {/* <CustomParallax title="Checkout" img={home_top} height={300} /> */}
          <Container
            className="my-auto Checkout"
            style={{ padding: "0", opacity: this.state.loader ? "0.4" : "1" }}
          >
            {checkout}
            {/* {this.props.loggedIn ? checkout : needToLogin} */}
          </Container>
        </React.Fragment>
      </>
    );
  }
}

function CustomPayOnline(props) {
  const full = (
    <Row>
      <PayRestaurant {...props} />
    </Row>
  );
  const empty = (
    <div className="text-center">
      <h2>Your cart is currently empty...</h2>
      <Link to={`/${props.restaurant.id}`}>
        <Button variant="warning" className="mt-4">
          Back to Order
        </Button>
      </Link>
    </div>
  );

  return (
    <>
      <div className="cart-header">
        <p>{props.restaurant.restaurant.name}</p>
        <button
          onClick={() => window.history.back()}
          style={{
            padding: "5px 10px",
            background: "none",
            border: "0",
            fontWeight: "600",
            fontSize: "25px",
          }}
        >
          <i className="fa fa-close"></i>
        </button>
      </div>
      <React.Fragment>
        {/* {console.log(this.props.cart.order_id)} */}
        <Container className="my-auto Cart" style={{ minHeight: "200px" }}>
          {props.total > 0 ? full : empty}
        </Container>
      </React.Fragment>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    total: state.cart.total,
    totalPrice: state.cart.totalPrice,
    tip: state.cart.tip,
    tips: state.cart.tips,
    restaurant: state.restaurant,
    name: state.restaurant.restaurant.name,
    orderCart: state.cart.orderCart,
    customer: state.customer,
    loggedIn: state.customer.isLoggedIn,
    stripe_pk: state.restaurant.restaurant.stripe_pk,
    cart: state.cart,
    tax: state.restaurant.totalTax,
    table: state.table,
    tablePath: state.restaurant.tablePath,
    support: state.restaurant.support,
    checkout: state.checkout,
    method: state.restaurant.method,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Set: (title, num) => {
      dispatch(Set(title, num));
    },
    Reset: (title, num) => {
      dispatch(Reset(title, num));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPayOnline);
