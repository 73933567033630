import React, { useEffect, Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import CartTableItem from "../components/CartTableItem";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";

import CartTotalsCheckout from "../components/CartTotalsCheckout";

import Card from "react-bootstrap/Card";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Form from "react-bootstrap/Form";
import {
    addCustomerPhone,
    postNewOrderCash,
    postNewOrderCashCustom,
    updateCustomer,
    // updateCustomerAddress,
    updateCustomerEmail,
    updateCustomerPhone,
} from "../utils/api";
import { Set, Reset } from "../redux/actions/cartActions";
import { round } from "../utils/functions";
import { FaUserEdit } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getOrderTypes } from "../utils/api";
import { toast } from "react-toastify";
import MultiLingualContent from "../context/multilingualContent";

class PayRestaurant extends Component {
    state = {
        firstName: "",
        name: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        orderId: "",
        country: "US",
        zip: "",
        email: "",
        mobile: "",
        type: "cash",
        notes: "",
        updated: true,
        customer_id: "",
        modalShow: false,
        edit: false,
        isDisable: false,
        orderType: "",
        timeZone: this.props.restaurant.restaurant.timeZone
    };
    async componentDidMount() {
        window.scrollTo(0, 0);
        if (!(this.props.restaurant.id === "naan-n-curry-bayarea-pl")) {
            const orderTypes = await getOrderTypes(
                window.location.pathname.split("/")[1]
            );
            var orderType = orderTypes.filter(
                (type) => type.label.includes("out") || type.label.includes("up")
            );
            orderType = orderType.length
                ? orderType[orderType.length - 1]["id"]
                : orderTypes[0]["id"];
            this.setState({
                orderType,
            });
        }
        // console.log(this.props.customer);
        this.setState({
            customer_id: this.props.customer.id ? this.props.customer.id : "",
            restaurant_id: this.props.restaurant.id ? this.props.restaurant.id : "",
            firstName: this.props.customer.firstName
                ? this.props.customer.firstName
                : "",
            lastName: this.props.customer.lastName
                ? this.props.customer.lastName
                : "",
            email:
                this.props.customer.emailAddresses &&
                    this.props.customer.emailAddresses.elements[0]
                    ? this.props.customer.emailAddresses.elements[0].emailAddress
                    : "",
            mobile: this.props.customer.phone ? this.props.customer.phone : "",
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };
    handleEdit = (e) => {
        this.setState({
            updated: false,
            edit: true,
        });
    };
    updateUser = async (e) => {
        e.preventDefault();
        if (
            this.props.customer.phoneNumbers &&
            this.props.customer.phoneNumbers.elements[0]
        ) {
            await updateCustomerPhone(
                this.state.restaurant_id,
                this.state.customer_id,
                this.props.customer.phoneNumbers.elements[0]["id"],
                this.state
            );
        } else {
            await addCustomerPhone(
                this.state.restaurant_id,
                this.state.customer_id,
                this.props.customer.addresses.elements[0]["id"],
                this.state
            );
        }
        if (
            this.props.customer.emailAddresses &&
            this.props.customer.emailAddresses.elements[0]
        ) {
            await updateCustomerEmail(
                this.state.restaurant_id,
                this.state.customer_id,
                this.props.customer.emailAddresses.elements[0]["id"],
                this.state
            );
        }
        await updateCustomer(
            this.state.restaurant_id,
            this.state.customer_id,
            this.state
        );
        this.setState({
            edit: false,
            updated: true,
        });
    };
    validateFormAddress() {
        return !(
            (this.state.firstName.length > 0 || this.state.name.length > 0) ||
            this.state.mobile.length > 0
        );
    }
    handleNewOrder = async (event) => {
        event.preventDefault();
        if ((this.state.firstName.length > 0 || this.state.name.length > 0) ||
            this.state.mobile.length === 12) {
            this.setState({ isDisable: true });
            var res = {}
            if (!(this.props.restaurant.id === "naan-n-curry-bayarea-pl")) {
                res = await postNewOrderCash(this.state, this.props.items);
            } else {
                res = await postNewOrderCashCustom(this.state, this.props.items, this.props.tip, this.props.tax);
            }
            if (res) {
                localStorage.removeItem(this.state.restaurant_id);
                this.setState({
                    modalShow: true,
                    orderId: res.id
                })
            }
        } else {
            toast.error("Please enter your Name or Phone...")
        }

    };
    handleConinue = () => {
        window.location.href = `/${this.props.restaurant.id}`;
        // this.props.Reset();
    }

    render() {
        const cartTableItemRender = (title) => (
            <CartTableItem
                key={this.props.items[title]["productId"]}
                id={this.props.items[title]}
                img={this.props.items[title].img}
                title={this.props.items[title].title}
                price={this.props.items[title].price}
                num={this.props.items[title].num}
            />
        );
        const checkout = (
            <>
                <Row>
                    <Col xs={12} lg={8}>
                        <div className="d-flex">
                            <h4 className="text-uppercase mr-2 my-auto"><MultiLingualContent contentID="Cart" /></h4>
                            <p className="noprod my-auto">{this.props.total} Products</p>
                        </div>
                        <Table className=" cart-page mt-3" responsive>
                            <thead>
                                <tr className="head text-center">
                                    <th>Image</th>
                                    <th>Product</th>

                                    <th>Quantity</th>
                                    <th>Total Price</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(this.props.items).map(
                                    (title) =>
                                        this.props.items[title].num > 0 &&
                                        cartTableItemRender(title)
                                )}
                            </tbody>
                        </Table>
                        <Card className="mt-3 mb-5">
                            <Card.Header
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    background: '#ff9900',
                                    color: '#fff',
                                    fontWeight: '700',
                                }}
                            >
                                <span>Details</span>

                                {this.state.edit ? (
                                    <></>
                                ) : (
                                    <FaUserEdit
                                        className="mr-1"
                                        size="1.5em"
                                        onClick={this.handleEdit}
                                    />
                                )}
                            </Card.Header>
                            {!this.state.edit && this.state.customer_id ? (
                                // {Object.keys(this.props.customer).length ? (
                                <Card.Body>
                                    <Card.Text>
                                        {this.state.firstName} {this.state.lastName}
                                    </Card.Text>
                                    <Card.Text>{this.state.email}</Card.Text>
                                    <Card.Text>{this.state.mobile}</Card.Text>
                                    {/* <Card.Text>{this.state.address}</Card.Text>
                <Card.Text>
                  {this.state.city}, {this.state.country}, {this.state.state},{" "}
                  {this.state.zip}
                </Card.Text> */}
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="notes" >
                                            <Form.Control
                                                as="textarea" rows={3}
                                                onChange={this.handleChange}
                                                placeholder="Notes"
                                                required
                                                value={this.state.notes}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Card.Body>
                            ) : (
                                <Form
                                    // onSubmit={this.handleSubmit}
                                    className="p-4"
                                >

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="name" >
                                            <Form.Control
                                                onChange={this.handleChange}
                                                placeholder="Name"
                                                required

                                                value={this.state.name}
                                            />
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>

                                        <Form.Group as={Col} controlId="mobile">
                                            {(!this.state.mobile.replace('+', '').length || !this.state.mobile.replace('+1', '').length) && (
                                                <span
                                                    style={{
                                                        color: "#000",
                                                        fontSize: "15px",
                                                        //   textAlign: "center",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    <MultiLingualContent contentID="Please enter the phone number to receive the order confirmation text." />
                                                </span>
                                            )}
                                            <PhoneInput
                                                country={"us"}
                                                placeholder="Phone"
                                                inputclassName="phoneinput"
                                                value={this.state.mobile}
                                                onChange={(mobile) => {
                                                    this.setState({ mobile: `+${mobile}` });
                                                }}
                                            />

                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="notes">
                                            <Form.Control
                                                as="textarea" rows={3}
                                                onChange={this.handleChange}
                                                placeholder="Notes"
                                                required
                                                value={this.state.notes}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    {this.state.edit ? (
                                        <Button
                                            block
                                            variant="warning"
                                            type="submit"
                                            disabled={this.validateFormAddress()}
                                            onClick={this.updateUser}
                                        >
                                            Update
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </Form>
                            )}
                        </Card>
                        <Modal
                            show={this.state.modalShow}
                            size="md"
                            aria-labelledby="ordersucess"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="ordersucess">
                                    Order Received
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>
                                <MultiLingualContent contentID="Your order has been received. Your order will be ready in 10 minutes." /> Your order ID - <b>{this.state.orderId}</b>
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button style={{ marginBottom: '0' }} onClick={this.handleConinue}>Continue</Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                    <Col xs={12} lg={4}>
                        <CartTotalsCheckout
                            handleNewOrder={this.handleNewOrder}
                            disabled={this.state.isDisable}
                            buttonText="Order and Pay at Restaurant"
                            payment={false}
                            total={this.props.total}
                            totalPrice={round(this.props.totalPrice)}
                        />
                    </Col>
                </Row>
            </>
        );
        // eslint-disable-next-line
        const needToLogin = (
            <div className="text-center">
                <h2>You must Login or Signup to checkout</h2>
                <Link to={`/${this.props.restaurant.id}/login-signup`}>
                    <Button variant="warning" className="mt-4">
                        To Login/Signup
                    </Button>
                </Link>
            </div>
        );

        return (
            <React.Fragment>
                {/* <CustomParallax title="Checkout" img={home_top} height={300} /> */}
                <Container className="my-auto Checkout">
                    {checkout}
                    {/* {this.props.loggedIn ? checkout : needToLogin} */}
                </Container>
            </React.Fragment>
        );
    }
}

function CustomCart(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // const cartTableItemRender = (title) => (
    //   <CartTableItem
    //     key={props.items[title]["productId"]}
    //     id={props.items[title]}
    //     img={props.items[title].img}
    //     title={props.items[title].title}
    //     price={props.items[title].price}
    //     num={props.items[title].num}
    //   />
    // );

    const full = (
        <Row>
            {/* <div className="d-flex">
          <h4 className="text-uppercase mr-2 my-auto">Your Cart</h4>
          <p className="noprod my-auto">{props.total} Products</p>
        </div>
        <Table className=" cart-page mt-3" responsive>
          <thead>
            <tr className="head text-center">
              <th>Image</th>
              <th>Product</th>

              <th>Quantity</th>
              <th>Total Price</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {Object.keys(props.items).map(
              (title) =>
                props.items[title].num > 0 && cartTableItemRender(title)
            )}
          </tbody>
        </Table> */}
            <PayRestaurant {...props} />
            {/* <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={3}>
        <CartTotalsCart
          disabled={false}
          buttonText="Checkout"
          total={props.total}
          totalPrice={round(props.totalPrice)}
        />
      </Col> */}
        </Row>
    );
    const empty = (
        <div className="text-center">
            <h2>Your cart is currently empty...</h2>
            <Link to={`/${props.restaurant.id}`}>
                <Button variant="warning" className="mt-4">
                    Back to Order
                </Button>
            </Link>
        </div>
    );

    return (
        <React.Fragment>
            <Container className="my-auto Cart" style={{ minHeight: "200px" }}>
                {props.total > 0 ? full : empty}
            </Container>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        items: state.cart.items,
        total: state.cart.total,
        totalPrice: state.cart.totalPrice,
        tip: state.cart.tip,
        restaurant: state.restaurant,
        customer: state.customer,
        loggedIn: state.customer.isLoggedIn,
        tax: state.restaurant.totalTax,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        Set: (title, num) => {
            dispatch(Set(title, num));
        },
        Reset: (title, num) => {
            dispatch(Reset(title, num));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCart);
