import React, { Component } from "react";
import { connect } from "react-redux";

class UserDashboard extends Component {
  state = {
  };

  async componentDidMount() {
    console.log("H(H")
  }
  
  render() {

    return (
      <>
       <div className="dashboard-group">
            <h2 style={{ marginBottom: "16px" }}>
              Welcome {/* {customer.userName} */}
            </h2>
          
            
          </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
  };
};
export default connect(mapStateToProps, null)(UserDashboard);
