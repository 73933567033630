import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import CartTableItem from "../components/CartTableItem";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import CartTotalsCart from "../components/CartTotalsCart";
import { round } from "../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import { getPopularItems } from "../utils/api";
import PopularDeliveryCard from "../components/PopularDeliveryCard";
import MultiLingualContent from "../context/multilingualContent";

function Cart(props) {
  const [popular, setPopular] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [show, setShow] = useState(false);
  async function loadPopular() {
    try {
      setLoader(true);
      const popularItems = await getPopularItems(
        props.restaurant_id,
        props.restaurant.integrate,
        props.restaurant.language
      );

      popularItems && setPopular(popularItems.slice(0, 8));
    } catch (e) {
      console.log("no data");
      setLoader(false);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    loadPopular();
  }, []);

  const itemsIterate = (items) => {
    return Object.values(items).map((item) => cardRender(item));
  };

  const cardRender = (ite) => {
    let item = props.items[ite.title];
    return (<>
      {item ? <PopularDeliveryCard
        key={item.title}
        img={item.img}
        modifierGroups={item.modifierGroups}
        title={item.title}
        price={item.price}
        modifiersPriceClicked={item.modifiersPriceClicked}
        description={item.desc}
        num={item.num}
        stock={item.stock}
        hidden={item.hidden}
        isOpen={props.restaurant.isOpen}
        id={item.productId}
      /> : <></>}
    </>

    );
  };

  const cartTableItemRender = (item, i) => (
    <CartTableItem
      key={i}
      index={i}
      id={item}
      img={item.img}
      title={item.title}
      price={item.price}
      num={item.num}
      modifierGroups={item.modifierGroups}
      modifiers={item.modifiers}
      modifierPrice={item.modifiersPrice ? item.modifiersPrice : 0}
    />
  );

  const full = (
    <>
      <div style={{ padding: "2em 0 0" }}>
        <Row>
          <Col xs={12} lg={6} style={{ padding: "0" }}>
            {/* {console.log(props.items)} */}

            {false ? (
              <>
                <div className="embed-maps">
                  {props.restaurant.id.toLowerCase() ===
                    "vishnuji-ki-rasoi-festival-mela" ? (
                    <iframe
                      style={{ border: 0 }}
                      allowfullscreen
                      zoom="11"
                      referrerpolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDAwsYZYalop44KBn1eQYji7PftPBYw5iM
              &q=${"Marriott Dallas Allen Hotel Convention Center"
                          .replaceAll(" ", "+")
                          .replaceAll(".", "") +
                        "Watters Creek Blvd".replaceAll(" ", "+").replaceAll(".", "") +
                        "Allen"
                        }`}
                    ></iframe>
                  ) : (
                    <iframe
                      style={{ border: 0 }}
                      allowfullscreen
                      zoom="11"
                      referrerpolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDAwsYZYalop44KBn1eQYji7PftPBYw5iM
              &q=376+1st+Street+Los+Altos+CA+94022`}
                    ></iframe>
                  )}
                </div>
              </>
            ) : (
              <div style={{ marginTop: "64px" }}></div>
            )}
            <p
              style={{
                maxWidth: "87%",
                margin: "2em 0 0",
                borderBottom: "1px solid #cccccc8f",
                fontWeight: "600",
                paddingBottom: "10px",
                color: "#444",
                fontSize: "18px",
              }}
              className="borderline"
            >
              <MultiLingualContent contentID="Cart" />{" "}
              <span
                style={{
                  background: "#000",
                  padding: "1px 14px",
                  borderRadius: "20px",
                  color: "#fff",
                  fontWeight: "700",
                }}
              >
                {props.total}
              </span>
            </p>
            {props.orderCart.map((item, i) => cartTableItemRender(item, i))}
            {popular.length ? (
              <div className="people-get cart-checkout">
                <h4>People also get</h4>
                <div className="d-flex flex-wrap">
                  {itemsIterate(popular)}
                </div>{" "}
              </div>
            ) : (
              <></>
            )}
          </Col>

          <Col className="payment-cont" xs={12} lg={6}>
            <CartTotalsCart
              disabled={false}
              buttonText="Checkout"
              total={props.total}
              totalPrice={round(props.totalPrice)}
            />
          </Col>
        </Row>
      </div>
    </>
  );
  const empty = (
    <>
      <p>
        <MultiLingualContent contentID="Cart" />{" "}
        <span
          style={{
            background: "#000",
            padding: "0px 7px",
            borderRadius: "20px",
            color: "#fff",
            fontWeight: "700",
          }}
        >
          {parseFloat(props.total).toFixed(2)}
        </span>
        <br />
        <span>
          from{" "}
          <span style={{ color: "#ff9900" }}>
            {" "}
            {props.restaurant.restaurant.name} ({props.restaurant.address.city},{" "}
            {props.restaurant.address.address1}{" "}
            {props.restaurant.address.address2})
          </span>
        </span>
      </p>
      <div
        style={{
          textAlign: "center",
          padding: "3em 0 0",
          fontWeight: "600",
          fontSize: "16px",
        }}
      >
        No items in your cart.
      </div>
    </>
  );

  return (
    <>
      <div className="cart-header">
        <p>{props.restaurant.restaurant.name}</p>
        <button
          onClick={() => window.history.back()}
          style={{
            padding: "5px 10px",
            background: "none",
            border: "0",
            fontWeight: "600",
            fontSize: "25px",
          }}
        >
          <FontAwesomeIcon icon={faClose} size={"1x"} />
        </button>
      </div>
      <React.Fragment>
        <Container className="Cart" style={{ minHeight: "200px" }}>
          {props.total > 0 ? full : empty}
        </Container>
      </React.Fragment>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    orderCart: state.cart.orderCart,
    total: state.cart.total,
    tablePath: state.restaurant.tablePath,
    totalPrice: state.cart.totalPrice,
    restaurant: state.restaurant,
    restaurant_id: state.restaurant.id,
    timeZone: state.restaurant.restaurant.timeZone,
  };
};

export default connect(mapStateToProps)(Cart);
