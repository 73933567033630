import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import MultiLingualContent from "../context/multilingualContent";
// import "./../dashboard/pages/pages.css";
const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
const day = {
  0: "sunday",
  1: "monday",
  2: "tue",
  3: "wed",
  4: "thurs",
  5: "fri",
  6: "sat",
};
const lang = {
  en: "English",
  es: "Spanish",
  it: "Italian",
  "zh-CN": "Chinese",
  hi: "Hindi",
  ta: "Tamil",
};
var nextDay = day[new Date().getDay() + 1];
const dayOrder = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

class BrandingHeader extends Component {
  state = {
    fetchedlan: [],
    selectedLan: "",
  };
  async componentDidMount() {
    this.fetchlanguage();
    this.setState({ selectedLan: this.props.restaurant.language });
  }
  fetchlanguage = async () => {
    let response = await fetch(
      `/api/v1/merchant/get/restaurant/online-order/language-support/settings/${this.props.restaurant.id}`
    );
    response = await response.json();
    const languages = Object.values(response);
    this.setState({ fetchedlan: languages });
  };
  language = (e) => {
    try {
      let url = window.location.href;
      const Url = new URL(url);
      const tableId = new URLSearchParams(Url.search).get("tableId");
      console.log(tableId);
      if (tableId) {
        window.location.replace(
          `${window.location.origin}/${this.props.restaurant.id}/table?tableId=${tableId}&language=${e.target.value}`
        );
      } else {
        window.location.replace(
          `${window.location.origin}/${this.props.restaurant.id}?language=${e.target.value}`
        );
      }
      // window.location.replace(`?language=${e.target.value}`);
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    if (this.props.restaurant.timing) {
      const day = moment().format("dddd").toLocaleLowerCase();
      const todayDate = this.props.restaurant.timing[day];
      const dayIndex = dayOrder.indexOf(day);
      var openStatus = "";
      var openHours = "";
      if (todayDate) {
        // console.log(todayDate, this.props.restaurant.isOpen);
        if (todayDate != "Closed" && this.props.restaurant.isOpen) {
          // console.log("1s If");
          openHours = `${day.slice(0, 3)} ${todayDate}`;
        }
        if (todayDate == "Closed" || !this.props.restaurant.isOpen) {
          // console.log("2n If");
          for (let i = dayIndex; i < dayOrder.length; i++) {
            if (this.props.restaurant.timing[dayOrder[i]] !== "Closed") {
              // console.log("2.1n If");
              openStatus = `${dayOrder[i].slice(0, 3)}  ${this.props.restaurant.timing[dayOrder[i]]
                }`;
              break;
            }
          }
        }
        if (
          !openStatus &&
          todayDate == "Closed" &&
          !this.props.restaurant.isOpen
        ) {
          // console.log("3s If");
          for (let i = 0; i < dayIndex; i++) {
            if (this.props.restaurant.timing[dayOrder[i]] !== "Closed") {
              // console.log("3.1n If");
              openStatus = `${dayOrder[i].slice(0, 3)}  ${this.props.restaurant.timing[dayOrder[i]]
                }`;
              break;
            }
          }
        }
      }
      const nextDay = day[new Date().getDay() + 1];
    }
    // console.log(time, "time")
    return (
      <>
        <div className="newheader">
          <div className="container-fluid">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "80%",
                }}
              >
                <div
                  className="head-cont"
                  style={{ position: "relative", padding: "5px 15px" }}
                >
                  <p
                    style={{ fontSize: "22px", margin: "0", padding: "15px 0" }}
                  >
                    <span style={{ color: "#00", fontWeight: "600" }}>
                      {" "}
                      {capitalizeFirstLetter(
                        this.props.restaurant.restaurant.name.replace(/-/g, " ")
                      )}{" "}
                      (
                      {/* {capitalizeFirstLetter(this.props.restaurant.address.city)}, */}{" "}
                      {capitalizeFirstLetter(
                        this.props.restaurant.address.address1
                      )}{" "}
                      {this.props.restaurant.address.address2})
                    </span>
                  </p>
                  {window.innerWidth < 768 ? (
                    <>
                      <div
                        /* style={{
                                                  background: this.props.restaurant.isOpen
                                                    ? "#54ab1a"
                                                    : "rgb(169, 68, 66)",
                                                }} */
                        className="restaurant-status"
                      >
                        <p
                          className="addressing"
                          style={{
                            fontWeight: "400",
                            fontSize: "13px ! important",
                            padding: "5px 0 ! important",
                          }}
                        >
                          {capitalizeFirstLetter(
                            this.props.restaurant.address.address1
                          )}{" "}
                          {capitalizeFirstLetter(
                            this.props.restaurant.address.city
                          )}
                          , {this.props.restaurant.address.state}{" "}
                          {this.props.restaurant.address.zip}
                        </p>
                        {/* {this.props.restaurant.id.toLowerCase() ===
                        "vishnuji-ki-rasoi-festival-mela".toLowerCase() ? (
                          <p
                            style={{
                              fontSize: "18px !important",
                              margin: "0",
                              fontWeight: 600,
                            }}
                          >
                            Event: Aishwarya Majmudar on October 15, 2022
                          </p>
                        ) : (
                          <></>
                        )} */}

                        {this.props.restaurant.isOpen ? (
                          <>
                            <p
                              style={{
                                color: "#54ab1a",
                                display: "inline-block",
                                // color: "#fff",
                                // padding: "5px 10px !important",
                                // borderRadius: "5px",
                                fontSize: "18px !important",
                                // letterSpacing: "1.1px",
                              }}
                            >
                              <MultiLingualContent contentID="Open Now" />
                            </p>
                            <p className="timings">
                              {capitalizeFirstLetter(openHours)}
                            </p>
                          </>
                        ) : this.props.restaurant.restaurant_open ? (
                          <>
                            <p
                              style={{
                                color: "rgb(169, 68, 66)",
                                display: "inline-block",
                                // color: "#fff",
                                // padding: "5px 10px !important",
                                // borderRadius: "5px",
                                fontSize: "18px !important",
                                // letterSpacing: "1.1px",
                              }}
                            >
                              <MultiLingualContent contentID="Closed" />
                            </p>
                            {openStatus ? (
                              <p className="timings">
                                Open Next : {capitalizeFirstLetter(openStatus)}
                              </p>
                            ) : (
                              <></>
                            )}
                            {/* <p id="demo">Open Next: {capitalizeFirstLetter(nextDay)}</p> */}
                          </>
                        ) : (
                          <>
                            <p
                              style={{
                                color: "rgb(169, 68, 66)",
                                display: "inline-block",
                                // color: "#fff",
                                // padding: "5px 10px !important",
                                // borderRadius: "5px",
                                fontSize: "18px !important",
                                // letterSpacing: "1.1px",
                              }}
                            >
                              <MultiLingualContent contentID="Temporarily closed" />
                            </p>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {this.state.fetchedlan.length ? (
                  <>
                    {window.innerWidth < 768 ? (
                      <>
                        <div>
                          <div>
                            <div className="language-select-mobile">
                              <select
                                defaultValue={this.state.selectedLan}
                                onChange={this.language}
                              >
                                <option id="en" value="en">
                                  {lang["en"]}
                                </option>
                                {this.state.fetchedlan.map((item, i) => {
                                  return (
                                    <>
                                      <option key={i} id={item} value={item}>
                                        {lang[item]}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ padding: "15px 15px" }}>
                          <div className="language-select">
                            <select
                              defaultValue={this.state.selectedLan}
                              onChange={this.language}
                            >
                              <option id="en" value="en">
                                {lang["en"]}
                              </option>
                              {this.state.fetchedlan.map((item, i) => {
                                return (
                                  <>
                                    <option key={i} id={item} value={item}>
                                      {lang[item]}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <h1>no language</h1> */}
                    {/* {window.location.replace(`${props.data.long_url}`)}; */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    timeZone: state.restaurant.restaurant.timeZone,
  };
};
export default connect(mapStateToProps, null)(BrandingHeader);
