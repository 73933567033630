import React from "react";
import { connect } from "react-redux";
import DeliveryMenu from "../components/DeliveryMenu";

function Home(props) {
  // window.scrollTo(0, 0);
  const slideData = [];
  Object.values(props.items).forEach((item) => {
    slideData.push({
      image: item.img,
      caption: item.title,
      price: item.price,
    });
  });

  return (

    <React.Fragment>

      <DeliveryMenu className="Delivery" />

    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    items: state.cart.items,
  };
};

export default connect(mapStateToProps, null)(Home);