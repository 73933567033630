import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import Login from "../dashboard/Authentication/Login";
import Navbar from "../dashboard/components/Navbar";
import jwt_decode from "jwt-decode";
import { CustomerData } from "../redux/actions/customerActions";
import SideBar from "../dashboard/components/SideBar";
import UserLogin from "../customerDashboard/Authentication/Login";
import UserNavbar from "../customerDashboard/components/Navbar";
import UserSidebar from "../customerDashboard/components/SideBar";

const getCookies = () => {
  return document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key.trim()]: decodeURIComponent(value),
      }),
      {}
    );
};
function ProtectedLogin(props) {
  useEffect(() => {
    // const token = getCookies().fd_token;
    // if (token) {
    //   var decoded = jwt_decode(token);
    //   props.CustomerData(decoded);
    // }
  }, []);
  return props.customer.isLoggedIn ? (
    // return true ? (
    <>
      {
        window.location.href.includes('merchant') ?
          <>
            <Navbar />
            <SideBar>
              <Outlet />
            </SideBar>
          </> 
          :
          <>
          <UserNavbar/>
            <UserSidebar>
              <Outlet />
            </UserSidebar>
          </>
      }

    </>
  ) : (
    <>
      {
        window.location.href.includes('merchant') ? <Login /> : <UserLogin />
      }
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    customerId: state.customer.id,
    customer: state.customer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedLogin);

// var delete_cookie = function(name) {
//   document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
// };
// undefined
// delete_cookie('fd_token');
