import { async } from "q";
import React, { useRef, useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { getReceipt, setOrderStatus, qrOrderStatus } from "../Functions/fetch";
import * as HiIcons from "react-icons/hi";
import { connect } from "react-redux";
import {} from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { Container, Modal, Button } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );

const paymentStyle = {
  paid: { style: { color: "green", fontWeight: 500 }, value: "Paid" },
  "not paid": { style: { color: "red", fontWeight: 500 }, value: "Not Paid" },
};
const ConvertSecToMin = (totalSeconds) => {
  // get number of full minutes
  const minutes = Math.floor(totalSeconds / 60);
  // get remainder of seconds
  const seconds = totalSeconds % 60;
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  return result;
};
const QrScanner = (props) => {
  const [data, setData] = useState("");
  const [stopStream, setStopStream] = useState(false);
  const [orderPage, setOrderPage] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [counter, setCounter] = useState(5); //Default 1
  const [interval, setIntervalTime] = useState(counter * 60);
  const [orderStatus, setOrderStatus] = useState("");
  const [autoDelievered, setAutoDelievered] = useState(true);
  const [orderPopup, setOrderPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [orderDetail, setOrderDetail] = useState({ is_refer_id: false });

  const timeIntervalRef = useRef(interval);
  timeIntervalRef.current = interval;
  const autoDelieveredRef = useRef(autoDelievered);
  autoDelieveredRef.current = autoDelievered;
  useEffect(() => {
    (async function () {
      const res = await fetch(
        `/api/v1/merchant/${props.restaurant.id}/${props.restaurant.integrate}/order/data`
      );
      const json = await res.json();
      setOrderDetail(json);
    })();
  }, []);

  const closeModal = () => {
    setOrderPopup(false);
  };
  const getOrderData = async (resId, orderId) => {
    console.log(resId, orderId);
    setStopStream(true);
    if (resId.toLowerCase() === "biryani-bowl-mela") setCounter(5);
    const data = await getReceipt(resId, orderId);
    if (data) {
      let newObj = { ...data };
      let itemarray = newObj.order.items;
      let newArray = [];
      // console.log(itemarray);
      let groupId = {};
      itemarray.forEach((item) => {
        let temp = {};
        let parse = JSON.parse(item.modifiers);
        let group = item.modifierGroups?.elements;
        if (group) {
          group.forEach((grup) => {
            // console.log(grup);
            groupId[grup.id] = grup.name;
            // groupId["name"] = grup.name;
            // console.log(groupId);
          });
        }
        if (parse) {
          let tempArr = [];
          let obijtemp = {};
          parse.forEach((ite) => {
            // console.log(ite);
            if (obijtemp[ite.modifier_group_id]) {
              obijtemp[ite.modifier_group_id]["modarr"].push(ite.modifier_name);
            } else {
              obijtemp[ite.modifier_group_id] = {
                gname: groupId[ite.modifier_group_id].toUpperCase(),
                modarr: [ite.modifier_name],
              };
            }
            // tempArr.push(
            // `${groupId[ite.modifier_group_id]} : ${ite.modifier_name}`
            // );
          });
          // console.log(obijtemp);
          // console.log(tempArr);
          let toJoinArr = [];
          Object.values(obijtemp).forEach((i) => {
            toJoinArr.push(`${i.gname} : ${i.modarr.join(", ")}`);
          });
          temp = { ...item, modifiers: obijtemp };
          // temp = { ...item, modifiers: toJoinArr.join(", ") };
          // temp = { ...item, modifiers: tempArr.join(", ") };
        } else {
          temp = { ...item };
        }
        // console.log(parse);
        newArray.push(temp);
      });
      newObj.order.items = newArray;
      let { status } = newObj.order;
      setOrderData(newObj);
      setOrderStatus(status);
      setOrderPage(true);
      setTimeout(() => {
        handlestatus(newObj, true);
      }, counter * 60000);
      let x = setInterval(() => {
        setIntervalTime(timeIntervalRef.current - 1);
        if (timeIntervalRef.current < 0) {
          clearInterval(x);
        }
      }, 1000);
    }
    setLoader(false);
  };
  const handleSubmit = async () => {
    setLoader(true);
    if (orderId.includes("-")) {
      getOrderData(props.restaurant.id, orderId);
    } else {
      let newId = `${orderDetail.order_id}-${orderId}`;
      setOrderId(newId);
      getOrderData(props.restaurant.id, newId);
    }
    setOrderPopup(false);
  };
  const parseUrl = async (result, error) => {
    if (!!result) {
      if (result.text) {
        const { text } = result;
        setData(text);
        setLoader(true);
        setStopStream(true);
        const [resId, orderId] = text.split("/").slice("4");
        setOrderId(orderId);
        getOrderData(resId, orderId);
      }
    }
    if (!!error) {
      console.info("error");
    }
  };
  const handlestatus = async (orderData, check) => {
    let data = {};
    // console.log(orderData);
    const { restaurant, order } = orderData;
    // console.log(check, autoDelieveredRef.current)
    data["orderId"] = order.id;
    data["restaurantId"] = order.restaurant_name;
    data["customer_name"] = order.customer_name;
    data["mobile"] = order.mobile;
    data["email"] = order.email;
    // console.log(check)
    // console.log(!check)
    if (!check || (check && autoDelieveredRef.current)) {
      await qrOrderStatus(data, "close");
      window.location.reload(false);
    }
  };
  const handleChange = (e) => {
    setOrderId(e.target.value);
  };
  const handleCancel = () => {
    setAutoDelievered(false);
  };
  return (
    <>
      <div className="full-width pt-4">
        <Container>
          {loader ? (
            <div style={{ textAlign: "center" }}>
              <ScaleLoader size={12} color="#ec7211" />
            </div>
          ) : (
            <>
              {" "}
              {orderStatus ? (
                <></>
              ) : (
                <>
                  <div className="text-center">
                    <button
                      style={{
                        display: "inline-block",
                        padding: "5px",
                        background: "#079907",
                        color: "#fff",
                        margin: "0",
                        border: "0",
                        fontFamily: "PT sans",
                        fontWeight: "600",
                        fontSize: "16px",
                        borderRadius: "5px",
                      }}
                      onClick={() => setOrderPopup(true)}
                    >
                      Enter Order Number
                    </button>
                    <br />
                    <p style={{ marginTop: "1em" }}>OR</p>
                  </div>
                </>
              )}
              {!stopStream ? (
                <>
                  <p
                    style={{
                      textAlign: "center",
                      margin: "0",
                      // padding: "1em 0",
                      fontSize: "18px",
                    }}
                  >
                    Scan the QR Code with this camera
                  </p>
                  <QrReader
                    onResult={parseUrl}
                    style={{ width: "100%" }}
                    className="qr-scanner"
                    constraints={{
                      facingMode: "environment",
                    }}
                    scanDelay="200"
                    stopStream={stopStream}
                  />
                </>
              ) : // <p>{data}</p>
              orderStatus && orderStatus != "close" ? (
                // <div>{data}</div>
                <>
                  {window.innerWidth > 767 ? (
                    <>
                      {interval && autoDelievered > 0 ? (
                        <div
                          className="make"
                          style={{
                            fontSize: "18px",
                            textAlign: "center",
                          }}
                        >
                          {interval && autoDelievered > 0 ? (
                            <>
                              Order automatically closed in{" "}
                              {ConvertSecToMin(interval)} Minutes{" "}
                              <span onClick={handleCancel}>
                                <MdCancel color="red" />
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="d-flex justify-content-between">
                        <div className="make text-right pr-2">
                          <button
                            className="btndeliver"
                            style={{ margin: "0" }}
                            onClick={() => window.location.reload(false)}
                          >
                            Cancel
                          </button>{" "}
                        </div>
                        <div className="make text-right pr-2">
                          <button
                            className="btndeliver"
                            style={{ margin: "0" }}
                            onClick={() => handlestatus(orderData, false)}
                          >
                            Delivered
                          </button>{" "}
                        </div>
                      </div>
                      <p style={{ padding: "10px 0" }}>
                        <strong>Order ID: {orderId}</strong>
                      </p>
                      <div className="scanner-items">
                        <table>
                          <thead>
                            <th>Customer Details</th>
                            <th>Items</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <p>{orderData.order.createdAt}</p>
                                <p
                                  style={{
                                    fontWeight: "500",
                                  }}
                                >{`Name: ${capitalizeFirstLetter(
                                  orderData.order.customer_name
                                )}`}</p>
                                {orderData.order.mobile ? (
                                  <p
                                    style={{
                                      fontWeight: "500",
                                    }}
                                  >{`Mobile: ${orderData.order.mobile}`}</p>
                                ) : (
                                  <></>
                                )}
                                {orderData.order.payment ? (
                                  <p
                                    style={
                                      paymentStyle[
                                        orderData.order.payment.toLowerCase()
                                      ].style
                                    }
                                  >{`Payment Status: ${
                                    paymentStyle[
                                      orderData.order.payment.toLowerCase()
                                    ].value
                                  }`}</p>
                                ) : (
                                  <></>
                                )}
                                <p
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >{`Subtotal: $${(
                                  orderData.order.subTotal / 100
                                ).toFixed(2)}`}</p>
                                <p
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >{`Total: $${(
                                  orderData.order.total / 100
                                ).toFixed(2)}`}</p>
                              </td>
                              <td>
                                {orderData.order.items.map((item) => {
                                  return (
                                    <tr className="service" key={item.id}>
                                      <td className="tableitem">
                                        {/* {item.modifiers} */}
                                        <p className="itemtext">
                                          {item.quantity} X {item.name}
                                        </p>
                                        {item.modifiers ? (
                                          Object.values(item.modifiers).map(
                                            (ite) => {
                                              return (
                                                <>
                                                  <div className="itemmodify">
                                                    [
                                                    {
                                                      <>
                                                        <span
                                                          style={{
                                                            fontWeight: "500",
                                                          }}
                                                        >{` ${ite.gname}: `}</span>
                                                        <span>{`${ite.modarr.join(
                                                          ", "
                                                        )} `}</span>
                                                      </>
                                                    }
                                                    ]
                                                  </div>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="scanner-items">
                        <div style={{ padding: "1em 0 0" }}>
                          {interval && autoDelievered > 0 ? (
                            <>
                              Order automatically closed in{" "}
                              {ConvertSecToMin(interval)} Minutes{" "}
                              <span onClick={handleCancel}>
                                <MdCancel color="red" />
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ alignItems: "center" }}
                        >
                          <div className="make text-right pr-2">
                            <button
                              className="btndeliver"
                              style={{ margin: "0" }}
                              onClick={() => window.location.reload(false)}
                            >
                              Cancel
                            </button>{" "}
                          </div>
                          <div
                            style={{ padding: "1em 0 0" }}
                            className="text-right"
                          >
                            <button
                              className="btndeliver"
                              onClick={() => handlestatus(orderData, false)}
                            >
                              Delivered
                            </button>{" "}
                          </div>
                        </div>
                        <p
                          style={{
                            fontSize: "18px",
                            textAlign: "center",
                            padding: "0 0 5px",
                            margin: "0",
                          }}
                        >
                          <strong>Order ID: {orderId}</strong>
                        </p>
                        <div className="scanner-mobile">
                          <table>
                            <tr>
                              <td>
                                <p>Date:</p>
                              </td>
                              <td>
                                {" "}
                                <p>{orderData.order.createdAt}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Name:</p>
                              </td>
                              <td>
                                <p
                                  style={{ fontWeight: "500" }}
                                >{`${capitalizeFirstLetter(
                                  orderData.order.customer_name
                                )}`}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>Mobile:</td>
                              <td>
                                {orderData.order.mobile ? (
                                  <p
                                    style={{
                                      fontWeight: "500",
                                    }}
                                  >{`${orderData.order.mobile}`}</p>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td>Payment Status:</td>
                              <td>
                                {orderData.order.payment ? (
                                  <p
                                    style={
                                      paymentStyle[
                                        orderData.order.payment.toLowerCase()
                                      ].style
                                    }
                                  >
                                    {
                                      paymentStyle[
                                        orderData.order.payment.toLowerCase()
                                      ].value
                                    }
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <p>Items:</p>
                              </td>
                              <td>
                                <p>
                                  {orderData.order.items.map((item) => {
                                    return (
                                      <>
                                        <p className="itemtext">
                                          {item.quantity} X {item.name}
                                        </p>
                                        {item.modifiers ? (
                                          Object.values(item.modifiers).map(
                                            (ite) => {
                                              return (
                                                <>
                                                  <div className="itemmodify">
                                                    [
                                                    {
                                                      <>
                                                        <span
                                                          style={{
                                                            fontWeight: "500",
                                                          }}
                                                        >{` ${ite.gname}: `}</span>
                                                        <span>{`${ite.modarr.join(
                                                          ", "
                                                        )} `}</span>
                                                      </>
                                                    }
                                                    ]
                                                  </div>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  })}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                Subtotal:
                              </td>
                              <td>
                                <p
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >{`$${(orderData.order.subTotal / 100).toFixed(
                                  2
                                )}`}</p>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                Total:
                              </td>
                              <td>
                                <p
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >{`$${(orderData.order.total / 100).toFixed(
                                  2
                                )}`}</p>
                              </td>
                            </tr>
                            {orderData.order.notes?.length ? (
                              <>
                                <tr>
                                  <td>
                                    <p>Notes:</p>
                                  </td>
                                  <td>
                                    <p>
                                      {orderData.order.notes ? (
                                        <> {orderData.order.notes}</>
                                      ) : (
                                        <></>
                                      )}
                                    </p>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <></>
                            )}
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "20px", marginBottom: "8px" }}>
                      The Order Id <strong>{orderId}</strong> has been delivered
                    </div>
                    <button
                      className="btndeliver"
                      onClick={() => window.location.reload(false)}
                    >
                      Scan Again
                    </button>
                  </div>
                </>
              )}
              {orderPopup ? (
                <Modal
                  dialogClassName="del-model del-item"
                  show={orderPopup}
                  onHide={closeModal}
                  centered
                >
                  {/* <Modal.Header closeButton></Modal.Header> */}
                  <Modal.Body style={{ padding: "1em 0 0" }}>
                    <h6 style={{ marinBottom: "10px", fontSize: "16px" }}>
                      Please Enter Customer Order Number
                    </h6>
                    {orderDetail.is_refer_id ? (
                      <label style={{ fontSize: "18px", fontWeight: 600 }}>
                        {orderDetail.order_id}&minus;
                      </label>
                    ) : (
                      <></>
                    )}

                    <input
                      style={{
                        padding: "5px 10px",
                        width: "60%",
                        margin: "0 auto",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                      placeholder="Order number"
                      onChange={handleChange}
                      type="text"
                      size="sm"
                      id="orderId"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      style={{
                        background: "transparent",
                        color: "#000",
                        border: "0",
                      }}
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{ background: "#e13535c7" }}
                      variant="secondary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : (
                <></>
              )}
            </>
          )}
        </Container>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
    customer: state.customer,
  };
};
export default connect(mapStateToProps, null)(QrScanner);
