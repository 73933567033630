import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import * as AiIcons from "react-icons/ai";
import { FaEllipsisV } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Navbar.css";

class Navbar extends Component {
  state = {
    showDetails: false,
    showmobDetails: false,
  };
  render() {
    return (
      <>
        {window.innerWidth > 767 ? (
          <>
            <div>
              <div className="dash-head">
                <Container>
                  <Row className="justify-center align-items-center">
                    
                    <Col
                      lg={7}
                      className="colum-account d-flex align-items-center"
                    >
                      
                    </Col>
                    <Col lg={5} className="colum">
                      <div className="menu-bar-right">
                        {/* <a href="tel:4085164542" className="menu-bars">
                          <span style={{ fontSize: "16px", color: "#000" }}>
                            <i className="fa fa-info-circle"></i> Help
                          </span>
                        </a> */}
                        <Link
                          to="#"
                          className="menu-bars"
                          onClick={() => {
                            this.setState({
                              showDetails: !this.state.showDetails,
                            });
                          }}
                        >
                          <AiIcons.AiFillSetting size={22} color="#000" />
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#000",
                              padding: "0 5px",
                            }}
                          >
                            Account
                          </span>
                        </Link>
                        {this.state.showDetails ? (
                          <>
                            <div className="accountdetails">
                              <Link
                                onClick={() =>
                                  this.setState({
                                    showDetails: false,
                                  })
                                }
                                to="/customer/account-settings"
                              >
                                {" "}
                                Account & Setup
                              </Link>

                              <p>
                                Profile <br />
                                <span>{this.props.customer.userMail}</span>
                              </p>
                              <Link to="/customer/logout">Log out</Link>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="dash-head">
                <Container>
                  <div className="mobile-nav">
                    <div className="colum-account d-flex align-items-center justify-content-end">
                      <FaEllipsisV
                        onClick={() => {
                          this.setState({
                            showmobDetails: !this.state.showmobDetails,
                          });
                        }}
                      />
                    </div>
                    <div className="colum">
                      {/* <i
                        onClick={() => {
                          this.setState({
                            showmobDetails: !this.state.showmobDetails,
                          });
                        }}
                        className="fa fa-ellipsis-v"
                      ></i> */}

                      {this.state.showmobDetails ? (
                        <>
                          <div
                            className="menu-bar-right"
                            style={{
                              height: this.state.showmobDetails ? "auto" : "0",
                            }}
                          >
                            {/* <a href="tel:4085164542" className="menu-bars">
                              <span style={{ fontSize: "16px", color: "#000" }}>
                                <i className="fa fa-info-circle"></i> Help
                              </span>
                            </a> */}
                            <Link
                              to="#"
                              className="menu-bars"
                              onClick={() => {
                                this.setState({
                                  showmobDetails: true,
                                  showDetails: !this.state.showDetails,
                                });
                              }}
                            >
                              <AiIcons.AiFillSetting size={22} color="#000" />
                              <span
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                  padding: "0 5px",
                                }}
                              >
                                Account
                              </span>
                            </Link>
                            {this.state.showDetails ? (
                              <>
                                <div className="accountdetails">
                                  <Link
                                    onClick={() =>
                                      this.setState({
                                        showDetails: false,
                                        showmobDetails: false,
                                      })
                                    }
                                    to="/customer/account-settings"
                                  >
                                    {" "}
                                    Account & Setup
                                  </Link>
                                  <p>
                                    Profile <br />
                                    <span>{this.props.customer.userMail}</span>
                                  </p>
                                  <Link to="/customer/logout">Log out</Link>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customer: state.customer,
  };
};
export default connect(mapStateToProps)(Navbar);

