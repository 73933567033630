export function INIT(data) {
  return {
    type: "INIT",
    data: data,
    num: 0,
  };
}
export function Notes(note) {
  return {
    type: "NOTES",
    note: note,
  };
}
export function UPDATE(data) {
  return {
    type: "UPDATE",
    data: data,
    num: 0,
  };
}
export function LOC(data) {
  return {
    type: "LOC",
    data: data,
    num: 0,
  };
}
export function Add(title, num, id, modifications, instructions, productId) {
  return {
    type: "ADD",
    title: title,
    num: num,
    id: id,
    modifications: modifications,
    instructions: instructions,
    productId: productId
  };
}
export function Utils(obj) {
  return {
    type: 'UTILS',
    data: obj
  }
}

export function Tip(tip) {
  return {
    type: "TIP",
    tip: tip,
  };
}

export function AddWithModifier(title, modifierId, modifierName, price) {
  return {
    type: "ADDWITHMODIFIER",
    title: title,
    modifierId: modifierId,
    modifierName: modifierName,
    price: price,
  };
}
export function Edit(
  title,
  num,
  modifications,
  instructions,
  index,
  edit_type, id
) {
  return {
    type: "EDIT",
    title: title,
    num: num,
    modifications: modifications,
    instructions: instructions,
    index: index,
    edit_type: edit_type, id: id
  };
}
export function Set(title, num) {
  return {
    type: "SET",
    title: title,
    num: num,
  };
}

export function Sub(title, num, id) {
  return {
    type: "SUB",
    title: title,
    num: num,
    id: id,
  };
}

export function Zero(title, id, index) {
  return {
    type: "ZERO",
    title: title,
    num: 0,
    id: id,
    index: index,
  };
}

export function Reset() {
  return {
    type: "RESET",
  };
}
