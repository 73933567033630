import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
// import { userLogin } from "../redux/actions/userActions";
import { getLogin } from "../utils/api";
import { connect } from "react-redux";
import { CustomerData } from "../redux/actions/customerActions";

// import { Set, Reset } from "../redux/actions/cartActions";

class Login extends Component {
  state = {
    email: "",
    password: "",
    remember: false,
  };

  validateForm() {
    return !(this.state.email.length > 0 && this.state.password.length > 0);
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleCheckbox = (e) => {
    this.setState({
      remember: e.target.checked,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    console.log(this.state);
    let res = await getLogin(
      this.state.email.toLowerCase(),
      this.state.password,
      this.props.restaurant.id
    );
    if (res) {
      this.props.customer.routeTo
        ? (window.location.href = this.props.customer.routeTo)
        : (window.location.href = `/${this.props.restaurant.id}`);
      // this.props.CustomerData({ isLogged: true, customerId: res.user_id });
    } else {
      this.setState({
        password: "",
        remember: false,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Container className="mt-5 pt-3 Login">
          <Row className="signup-cont">
            <Col xs={12} className="border-right1">
              <h3 className="text-center">
                Already registered login with credentials
              </h3>
              <Form onSubmit={this.handleSubmit} className="mt-4">
                <Form.Group controlId="email">
                  <Form.Label>Email/Username</Form.Label>
                  <Form.Control
                    placeholder="Email/Username"
                    autoFocus
                    required
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
                {/* <Form.Group controlId="remember">
                  <Form.Check
                    type="checkbox"
                    label="Remember me"
                    onChange={this.handleCheckbox}
                  />
                </Form.Group> */}
                <Button
                  block
                  variant="dark"
                  type="submit"
                  disabled={this.validateForm()}
                >
                  Login
                </Button>
              </Form>
            </Col>

            <Col xs={12}>
              <p className="text-center ">
                New Customer?{" "}
                <Link to={`/${this.props.restaurant.id}/signup`}>
                  Sign Up here
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    CustomerData: (data) => {
      dispatch(CustomerData(data));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    total: state.cart.total,
    totalPrice: state.cart.totalPrice,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
