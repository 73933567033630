import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { joinObjSameKeyGname } from "../../utils/api";
import { Col, Container } from "react-bootstrap";
import moment from "moment-timezone";
// import axios from 'axios';
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../dashboard/pages/pages.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import { BiCreditCard } from "react-icons/bi";
import {
  FaMoneyBillAlt,
} from "react-icons/fa";
import { SiGooglepay, SiApple } from "react-icons/si";
import { getOrders, getTableOrders } from "../Functions/helper";
import { MdCancel } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";

import { GiWallet } from "react-icons/gi";
const payIcon = {
  google: <SiGooglepay style={{ fontSize: "38px", color: "#444" }} />,
  wallet: <GiWallet style={{ fontSize: "20px", color: "#444" }} />,
  apple: <SiApple style={{ fontSize: "22px", color: "#444" }} />,
  card: <BiCreditCard style={{ fontSize: "26px", color: "#444" }} />,
  cash: <FaMoneyBillAlt style={{ fontSize: "26px", color: "#666" }} />,
  null: <></>,
};

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
}
class Orders extends Component {
  state = {
    orders: [],
    tableOrders: [],
    pending: true,
    isOpen: false,
    loader: false,
    nodata: false,
    columns: [
      {
        name: "Table",
        cell: (row) => <p style={{ fontWeight: 600 }} className="order-date">{row.tableName}</p>,
      },
      {
        name: "Date",
        cell: (row) => (
          <p className="order-date">
            <span
              style={{ fontWeight: 600, marginBottom: "8px" }}
            >{`Order Id: ${row.tableOrderId}`}</span>{" "}
            <br />

            <span className="order-phone">
              {row.customerOrders.length} Customer(s)
            </span>

            {
              row.customerOrders.slice(0, 1).map(customer => {
                return (
                  <>
                    <br />
                    {customer.Name && customer.Mobile ? (
                      <>
                        {window.innerWidth > 767 ? <span className="order-phone">{customer.Name}</span> : <span className="order-phone">Name : {customer.Name}</span>}
                        <br />
                        <span className="order-phone">
                          Phone :{" "}
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#000",
                              background: "none",
                            }}
                            href={`tel:${customer.Mobile}`}
                          >
                            {customer.Mobile}
                          </a>
                        </span>
                      </>
                    ) : customer.Name ? (
                      <>
                        <span className="order-phone">{customer.Name}</span>{" "}
                      </>
                    ) : customer.Mobile ? (
                      <>
                        <span className="order-phone">
                          Phone :{" "}
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#000",
                              background: "none",
                            }}
                            href={`tel:${customer.Mobile}`}
                          >
                            {customer.Mobile}
                          </a>
                        </span>
                      </>
                    ) : (
                      <></>
                    )}

                  </>
                )
              })
            }
            <br />
            {window.innerWidth > 767 ? <span className="order-phone">
              {" "}
              {moment(row.date).format("YYYY-MM-DD hh:mm a")}{" "}
            </span> :
              <span className="order-phone">
                {" "}
                Date : {moment(row.date).format("YYYY-MM-DD hh:mm a")}{" "}
              </span>}
            <br />
          </p>
        ),
      },
      {
        name: "Line Items",
        cell: (row) => (
          <div>
            {window.innerWidth > 767 ?
              (<ul key={row.id}>
                {/* {
                row.customerOrders.map(customer => {
                  return <> */}
                {
                  row.customerOrders.flatMap(item => item.lineItem).slice(0, 2).map((item, index) => {
                    return (
                      <li key={index}>
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {item.product_name}
                        </span>{" "}
                        <span style={{ fontSize: "12px" }}>x</span>{" "}
                        <span>{item.quantity}</span> <br />{" "}
                        {item.modifiers ? (
                          Object.values(item.modifiers).map((ite, indexMod) => {
                            return (
                              <>
                                <div
                                  key={indexMod}
                                  className="itemmodify"
                                  style={{ padding: "3px 0" }}
                                >
                                  {
                                    <>
                                      <span
                                      >{` ${ite.gname
                                        .replace(/choose /gi, "")
                                        .replace(/an /gi, "")
                                        .replace(/add /gi, "")
                                        .toLocaleLowerCase()}: `}</span>
                                      <span>{`${ite.modarr.join(", ")}, `}</span>
                                    </>
                                  }
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                        <span style={{ paddingTop: "10px" }}>
                          $ {(item.totalPrice / 100).toFixed(2)}
                        </span>
                      </li>
                    );
                  })
                }
                {/* </>
                })
              } */}

              </ul>) :
              (
                <ul>

                  {
                    row.customerOrders.flatMap(item => item.lineItem).slice(0, 2).map((item, index) => {
                      return (
                        <li key={item.id + index}>
                          {item.product_name} - <span style={{ paddingTop: "10px" }}>
                            $ {item.price / 100} * {item.quantity}
                          </span><br />{" "}
                          {item.modifiers ? (
                            Object.values(item.modifiers).map((ite, indexMod) => {
                              return (
                                <>
                                  <div
                                    key={indexMod}
                                    className="itemmodify"
                                    style={{ padding: "3px 0" }}
                                  >
                                    [
                                    {
                                      <>
                                        <span
                                          style={{
                                            fontWeight: "600",
                                            textTransform: "capitalize",
                                          }}
                                        >{` ${ite.gname
                                          .replace(/choose /gi, "")
                                          .replace(/an /gi, "")
                                          .replace(/add /gi, "")
                                          .toLocaleLowerCase()}: `}</span>
                                        <span>{`${ite.modarr.join(", ")} `}</span>
                                      </>
                                    }
                                    ]
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}

                        </li>
                      );
                    })
                  }
                </ul>)}
          </div>
        ),
      },
      {
        name: "Total",
        selector: (row) => (
          <div style={{ fontSize: '18px' }}>
            {/* Total: $ {parseFloat(row.customerOrders.reduce((acc, curr) => {
                return (acc = acc + curr.total);
              }, 0) / 100).toFixed(2)} */}
            Total: $ {parseFloat(row.total / 100).toFixed(2)}
          </div>
        ),
      },
      {
        name: "Status",
        cell: (row) => (
          <div>
            {
              window.innerWidth > 767 ?
                <>
                  <p>
                    {
                      row.split_bills ?
                        <>
                          <h5 className="split font-weight-bold">{row.split_bills == 1 ? "Split By Orders" : "Split By Members"}</h5>

                          <div className="d-flex1">
                            {
                              row.splitDetails.slice(0, 2).map((split, i) => {
                                return (
                                  <>

                                    {

                                      <h6 className="guest">Guest {i + 1} - $ {parseFloat(split.amount).toFixed(2)} - {split.payment_status === "Not Paid" ? (
                                        <span id="not-paid">Not Paid 👎</span>
                                      ) : (
                                        <span id="paid">
                                          Paid <span>{payIcon['cash']}</span>
                                        </span>
                                      )} </h6>

                                    }
                                  </>
                                )
                              })
                            }
                            {
                              row.splitDetails.length > 2 && "more..."
                            }
                          </div>
                        </> :
                        <>
                          {
                            row.customerOrders.filter(customer => !customer.payment.toLowerCase().includes('not paid')).length ?
                              <span id="paid">
                                Paid <span>{payIcon[row.customerOrders.filter(customer => customer.payment === 'Paid' && customer.payment_type).length ? row.customerOrders.filter(customer => customer.payment === 'Paid' && customer.payment_type)[0]['payment_type'] : 'cash']}</span>
                              </span>
                              :
                              <div style={{ textAlign: 'center' }}>
                                <span id="not-paid">Not Paid 👎</span><br />

                                {
                                  !row.order_merge ||
                                  <div className="table-select" style={{ position: 'relative', top: 10, right: 0 }}>
                                    <select
                                      onChange={(e) => this.changePaymentStatus(row, e)}
                                      style={{ width: '100%' }}
                                    >
                                      <option value="Not Paid">Not Paid</option>
                                      <option value="Paid">Paid</option>
                                    </select>
                                  </div>
                                }

                              </div>
                          }
                        </>
                    }

                  </p>

                  <div style={{ display: "block", width: "100%" }}>
                  </div>
                </> :
                <></>
            }
          </div>
        ),
      },
      {
        name: "Details",
        cell: (row) => (
          <div className="detail-btn" style={{ width: '100%' }}>
            <a onClick={() => this.openModal(row)} id={row.id}>
              More Details
            </a>
          </div>
        ),
      },
    ],
  };

  openModal = async (row) => {
    this.setState({
      data: row,
      isOpen: true,
    });
  };
  closeModal = () => this.setState({ isOpen: false });

  async componentDidMount() {
    this.setState({ loader: true });
    const customerId = this.props.customer.id;
    const tableItems = await getTableOrders(customerId);
    this.updateOrderState(tableItems);
  }
  updateOrderState = async (items) => {
    try {
      this.setState({
        orders: [],
      });
      if (items.length) {
        let temp = [];
        items.map((item, i) => {
          var data = {};
          let customerOrders = item["customerOrders"][0];
          data["date"] = item["createdAt"];
          data["tableName"] = item["tableName"];
          data["tableOrderId"] = item["tableTokenId"];
          data["split_bills"] = item["split_bills"];
          data['order_merge'] = item["order_merge"];
          data["splitDetails"] = item["splitDetails"];
          data["discount"] = item.discount
          data["tax"] = item.tax
          data["subTotal"] = item.subTotal
          data["tip"] = item.tip
          data["total"] = item.total
          if (item.split_bills) {
            data["payment"] = item.splitDetails.length === item.splitDetails.filter(item => item.payment_status === 'Paid').length ? "Paid" : item.splitDetails.filter(item => item.payment_status === 'Paid').length ? "Partially Paid" : "Not Paid"
          } else {
            data["payment"] = item.customerOrders.filter(customer => !customer.payment.toLowerCase().includes('not paid')).length ? "Paid" : "Not Paid"
          }
          data["id"] = item["id"];
          data["tableId"] = item["tableId"];
          // data['total'] = item.customerOrders.reduce((acc, curr) => {
          //   return (acc = acc + curr.total);
          // }, 0) / 100
          // if(data["discount"] > 0) {
          //   data['total'] = data['total'] - data["discount"]
          // }
          item['customerOrders'].map((order, i) => {
            item['customerOrders'][i]['lineItem'] = order['items'].map((ite, i) => {
              let temp = {};
              let parse = JSON.parse(ite.modifiers);
              if (parse) {
                let obijtemp = {};
                Object.values(parse).forEach((ite) => {
                  if (obijtemp[ite.modifier_group_id]) {
                    obijtemp[ite.modifier_group_id]["modarr"].push(
                      ite.modifier_name
                    );
                  } else {
                    obijtemp[ite.modifier_group_id] = {
                      gname: ite.modifier_group_name || "Type",
                      modarr: [ite.modifier_name],
                    };
                  }
                });
                obijtemp = joinObjSameKeyGname(obijtemp);
                temp = { ...ite, modifiers: obijtemp };

              } else {
                temp = { ...ite, modifiers: {} };
              }
              return temp
            })
          })
          data['customerOrders'] = item['customerOrders']
          temp = [...temp, data];
        });
        this.setState({
          orders: temp,
        });
        this.setState({ loader: false });
      } else {
        this.setState({
          orders: [],
          loader: false,
          nodata: true,
        });
      }
      this.setState({
        pending: false,
      });
    } catch (error) {
      console.log(error);
    }
  };


  render() {
    const { columns, data } = this.state;
    const table = { columns, data };
    return (
      <>
        {this.state.loader ? (
          <div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                // minHeight: "100vh",
                transition: "0.5s ease-in-out 0s",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ScaleLoader size={12} color="#ec7211" />
            </div>
          </div>
        ) : this.state.nodata ? (
          <div style={{ textAlign: "center" }}>
            <h5 style={{ lineHeight: "1.5em", fontSize: "22px" }}>
              {/* Store Closed <br /> */}
              There are no online orders to display
            </h5>
          </div>
        ) : (
          <>
            <div className="table-group orders-dashboard orders-mobile">
              <Container style={{ minHeight: "auto", padding: "0" }} className="customer">
                <DataTableExtensions
                  columns={this.state.columns}
                  data={this.state.orders}
                  export={false}
                  // filter={false}
                  filterPlaceholder="Search Your Orders"
                  print={false}
                >
                  <DataTable
                    defaultSortField="id"
                    defaultSortAsc={true}
                    paginationPerPage={10}
                    highlightOnHover={true}
                    paginationRowsPerPageOptions={[
                      20, 30, 40, 50, 60, 70, 80, 90, 100,
                    ]}
                    columns={this.state.columns}
                    data={this.state.orders}
                    pagination={true}
                    export={false}
                    progressPending={this.state.pending}
                  />
                </DataTableExtensions>
              </Container>

            </div>
          </>
        )}
        {this.state.isOpen ? (
          <Modal
            dialogClassName="receipt-model1 table-invoice"
            show={this.state.isOpen}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div id="invoice-POS" className="invoice-tables">
                <div>
                  <div id="table">
                    {

                      this.state.data.customerOrders.map((customer, i) => {
                        { console.log("popup", this.state.data.splitDetails) }
                        return (
                          <>
                            <div className="customer-order">
                              <div className="customer-details" style={{ textAlign: 'center' }}>
                                <h6>Guest {i + 1}</h6>

                              </div>
                              <table>
                                <tbody>
                                  <tr className="tabletitle">
                                    <td>Item</td>
                                    <td>Quantity</td>
                                    <td>Total Details</td>
                                  </tr>

                                  {customer.lineItem.map((item) => {
                                    return (
                                      <tr className="line-service" key={item.id}>

                                        <td style={{ textAlign: 'left' }}>
                                          <p style={{ textAlign: 'left', padding: '5px 0' }} >{item.product_name}</p>
                                          {item.modifiers ? (
                                            Object.values(item.modifiers).map((ite) => {
                                              return (
                                                <>
                                                  <div className="itemmodify">
                                                    [
                                                    {
                                                      <>
                                                        <span
                                                          style={{
                                                            fontWeight: "600",
                                                            textTransform: "capitalize",
                                                          }}
                                                        >{` ${ite.gname
                                                          .replace(/choose /gi, "")
                                                          .replace(/an /gi, "")
                                                          .replace(/add /gi, "")
                                                          .toLocaleLowerCase()}: `}</span>
                                                        <span>{`${ite.modarr.join(
                                                          ", "
                                                        )} `}</span>
                                                      </>
                                                    }
                                                    ]
                                                  </div>
                                                </>
                                              );
                                            })
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        <td>
                                          <p>{item.quantity}</p>
                                        </td>
                                        <td>
                                          <p> $ {parseFloat(item.totalPrice / 100).toFixed(2)}</p>
                                        </td>
                                      </tr>
                                    );
                                  })}


                                </tbody>
                              </table>
                            </div>
                          </>
                        )
                      })
                    }

                  </div>
                  <hr style={{ margin: '0' }} />
                  <table className="totals-count">
                    <tr>
                      <td>
                        <h2>Subtotal</h2>
                      </td>
                      <td></td>
                      <td>
                        <h2> $ {parseFloat(this.state.data.subTotal / 100).toFixed(2)}</h2>
                      </td>
                    </tr>
                    {
                      this.state.data.discount ? <tr>
                        <td>
                          <h2>Discount</h2>
                        </td>
                        <td></td>
                        <td>
                          <h2>
                            {" "}
                            - ${" "}
                            {parseFloat(this.state.data.discount / 100).toFixed(2)}
                          </h2>
                        </td>
                      </tr>
                        : <></>
                    }
                    <tr>
                      <td>
                        <h2>Total Tax</h2>
                      </td>
                      <td></td>
                      <td>
                        <h2>
                          {" "}
                          ${" "}
                          {parseFloat(this.state.data.tax / 100).toFixed(2)}
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>Total Tip</h2>
                      </td>
                      <td></td>
                      <td>
                        <h2>
                          {" "}
                          ${" "}
                          {parseFloat(this.state.data.tip / 100).toFixed(2)}
                        </h2>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2>Order Total</h2>
                      </td>
                      <td></td>
                      <td>
                        <h2>
                          {" "}
                          ${" "}
                          {parseFloat((this.state.data.total / 100)).toFixed(2)}
                        </h2>
                      </td>
                    </tr>
                  </table>
                  {/* {
                    window.innerWidth > 767 || true?
                      <></>
                      : */}
                  <div style={{ textAlign: 'center' }}>
                    {
                      this.state.data.split_bills ?
                        <>
                          <h5 className="split font-weight-bold">{this.state.data.split_bills == 1 ? "Split By Orders" : "Split By Members"}</h5>

                          <div className="d-flex flex-column justify-content-around flex-wrap">

                            {
                              this.state.data.splitDetails.map((split, i) => {
                                return (
                                  <>

                                    {

                                      <h6 className="guest"><span style={{ paddingTop: '10px' }}>Guest {i + 1} - $ {parseFloat(split.amount).toFixed(2)}</span> - {split.payment_status === "Not Paid" ? (
                                        <>
                                          <span id="not-paid">Not Paid 👎</span>
                                        </>
                                      ) : (
                                        <span id="paid">
                                          Paid <span>{payIcon['cash']}</span>
                                        </span>
                                      )} </h6>

                                    }
                                  </>
                                )
                              })
                            }
                          </div>
                        </> :
                        <>
                          {
                            this.state.data.customerOrders.filter(customer => !customer.payment.toLowerCase().includes('not paid')).length ?
                              <span id="paid">
                                Paid <span>{payIcon[this.state.data.customerOrders.filter(customer => customer.payment === 'Paid')[0]['payment_type']]}</span>
                              </span>
                              :
                              <span id="not-paid">Not Paid 👎</span>
                          }
                        </>
                    }
                  </div>
                  {/* } */}

                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
 <Button variant="secondary" onClick={this.closeModal}>
 Close
 </Button>
 </Modal.Footer> */}
          </Modal>
        ) : (
          <></>
        )}

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);