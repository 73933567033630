import React, { Component } from "react";
import { connect } from "react-redux";
import "react-profile-avatar/dist/index.css";
import { RestaurantData } from "../redux/actions/restauratActions";
import { Link } from "react-router-dom";
// import moment from "moment";

import moment from "moment-timezone";
import { toast } from "react-toastify";
var sort = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function _24HourTime(time) {
  var hourEnd = time.indexOf(":");
  var H = +time.substr(0, hourEnd);
  var h = H % 12 || 12;
  var ampm = H < 12 || H === 24 ? "AM" : "PM";
  time = h + time.substr(hourEnd, 3) + ampm;
  return time;
}
class Header extends Component {
  async componentDidMount() {
    const currentDay = moment()
      .tz(this.props.timeZone)
      .format("dddd")
      .toLocaleLowerCase();
    if (
      this.props.restaurant.hours &&
      this.props.restaurant.hours["elements"].length
    ) {
      var results = {};
      let isOpen = [];
      sort.forEach((da) => {
        var hours = "";
        this.props.restaurant.hours["elements"][0][da]["elements"].forEach(
          (day) => {
            var start = day["start"].toString().padStart(4, "0");
            var end = day["end"].toString().padStart(4, "0");

            start = `${start.toString().slice(0, -2)}:${start
              .toString()
              .slice(-2)}`;
            end = `${end.toString().slice(0, -2) === 24
              ? "00"
              : end.toString().slice(0, -2)
              }:${end.toString().slice(-2)}`;

            hours = `${hours ? ` ${hours} &` : ``} ${_24HourTime(
              start
            )} - ${_24HourTime(end)}`.trim();
          }
        );
        results[da] = this.props.restaurant.hours["elements"][0][da]["elements"]
          .length
          ? `${hours}`
          : `Closed`;
        if (da === currentDay) {
          this.props.restaurant.hours["elements"][0][da]["elements"].forEach(
            (day) => {
              var start = day["start"].toString().padStart(4, "0");
              var end = day["end"].toString().padStart(4, "0");

              start = `${start.toString().slice(0, -2)}:${start
                .toString()
                .slice(-2)}`;
              end = `${end.toString().slice(0, -2) === 24
                ? "00"
                : end.toString().slice(0, -2)
                }:${end.toString().slice(-2)}`;
              let lessTimeStart = !(
                this.props.restaurant.id === "naan-n-curry-bayarea-pl"
              )
                ? 15
                : 30;
              let lessTimeClose = !(
                this.props.restaurant.id === "naan-n-curry-bayarea-pl"
              )
                ? 15
                : 0;

              var beginningTime = moment(start, "H:mm").subtract(
                lessTimeStart,
                "minutes"
              );

              // var beginningTime = moment(start, "H:mm").subtract(15, 'minutes');
              var time = moment().tz(this.props.timeZone).format("HH:mm");
              var currentTime = moment(time, "H:mm");
              var endTime = moment(end, "H:mm").subtract(
                lessTimeClose,
                "minutes"
              );
              // var endTime = moment(end, "H:mm").subtract(15, 'minutes');
              console.log("START", "NOW", "END");
              console.log(
                beginningTime.format("HH:mm"),
                currentTime.format("HH:mm"),
                endTime.format("HH:mm")
              );
              // console.log(beginningTime.subtract(15, 'minutes').format("HH:mm"))
              isOpen.push(
                beginningTime.isBefore(currentTime) &&
                currentTime.isBefore(endTime)
              );
            }
          );

          /* if (!(isOpen[0] || isOpen[1])) {
            toast.error(
              <div>
                {this.props.restaurant.restaurant.name}
                <br /> Restaurant is Currently Closed.
              </div>,
              {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                enableHtml: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } */
        }
      });
      this.props.RestaurantData({
        timing: results,
        currentTime: `${currentDay[0].toUpperCase()}${currentDay.slice(1, 3)} ${results[currentDay]
          }`,
      });
      // console.log(isOpen[0] || isOpen[1]);
      if (!this.props.restaurant.restaurant_open) {
        this.props.RestaurantData({ isOpen: false });
      } else if (isOpen[0] || isOpen[1])
        this.props.RestaurantData({ isOpen: isOpen[0] || isOpen[1] });
      let locationPath = window.location.origin.toLowerCase();
      if (locationPath.includes('localhost') || locationPath.includes('ngrok')) {
        console.log("localhost url detected!!! OPENING TIME SET TO OPEN, You can change this behaviour in Header.js");
        this.props.RestaurantData({ isOpen: true });
      }
    } else {
      console.log("NO OPEN HOURS SPECIFIED");
      this.props.RestaurantData({ isOpen: true });
    }
  }

  handleLogout = () => {
    this.handleCollapse();
  };

  handleCollapse = async (e) => {
    try {
      if (window.innerWidth < 1200) {
        let toggle = await document.getElementsByClassName("navbar-toggler")[0];
        toggle.click();
      }
    } catch (e) { }
  };

  render() {
    if (this.props.restaurant.timing) {
      const day = moment().format("dddd").toLocaleLowerCase();
      const todayDate = this.props.restaurant.timing[day];
      if (todayDate) {
        const today = todayDate.split("&");
        // console.log(today.toLocaleString("en-US"));
      }
    }
    return <></>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    RestaurantData: (data) => {
      dispatch(RestaurantData(data));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
    timeZone: state.restaurant.restaurant.timeZone,
    todayTime: state.restaurant.restaurant.currentTime,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
