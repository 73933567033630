import React from 'react';
import {
    Routes,
    Route,
    Navigate,
    Outlet,
} from "react-router-dom";

import AddProduct from "../dashboard/pages/square/AddProduct";
import Modifiers from "../dashboard/pages/Modifiers";
import ModifiersGroups from "../dashboard/pages/ModifiersGroups";
import Categories from "../dashboard/pages/Categories";
import CategoryItems from "../dashboard/pages/CategoryItems";
import TableDineIn from "../dashboard/pages/TableDineIn";
import Dashboard from "../dashboard/pages/Dashboard";
import ShowQrCode from "../dashboard/pages/ShowQrCode";
import Logout from "../dashboard/pages/Logout";
import AdminHome from "../dashboard/pages/AdminHome";
import Todaymenu from "../dashboard/pages/Todaymenu";
import Integrations from "../dashboard/pages/Integrations";
import SquareModifiersGroups from "../dashboard/pages/square/SquareModifiersGroups";
import AddModifersGroup from "../dashboard/pages/square/AddModifersGroup";
import SquareModifiers from "../dashboard/pages/square/SquareModifiers";
import CategoryGroups from "../dashboard/pages/square/CategoryGroups";
import Category from "../dashboard/pages/square/Category";
import OrderConfig from "../dashboard/pages/OrderConfig";
import AutoPost from "../dashboard/pages/AutoPost";
import Payout from "../dashboard/pages/Payout";
import Inventory from "../dashboard/pages/Inventory";
import Orders from "../dashboard/pages/Orders";
import Customers from "../dashboard/pages/Customers";
import TrackerOrders from "../dashboard/pages/TrackerOrder";
import TableOrders from "../dashboard/pages/TableOrders";
import QrScanner from "../dashboard/pages/QrScanner";
import AccountSettings from "../dashboard/pages/AccountSettings";
import ShortenUrl from "../dashboard/pages/ShortenUrl";
import ProtectedLogin from "../routes/ProtectedLogin";
import Reports from "../dashboard/pages/Reports";
import ConditonalModifier from '../dashboard/pages/custom/ConditionalModifier';

const MerchantRouters = (props) => {
    return (
        <>
            <Routes>
                <Route exact element={<ProtectedLogin />}>
                    <Route path="/merchant" element={<Outlet />}>

                        <Route
                            index
                            element={
                                props.customer.isLoggedIn ? (
                                    <Navigate
                                        to={
                                            window.location.pathname + "/dashboard"
                                        }
                                    />
                                ) : (
                                    <AdminHome />
                                )
                            }
                        />
                        <Route
                            exact
                            path="integration"
                            element={<Integrations />}
                        />
                        <Route
                            exact
                            path="autopost"
                            element={<AutoPost />}
                        />
                        <Route
                            exact
                            path="buffet-menu"
                            element={<Todaymenu />}
                        />
                        <Route
                            exact
                            path="dashboard"
                            element={<Dashboard />}
                        />
                        <Route
                            exact
                            path="payment"
                            element={<Payout />}
                        />
                        <Route
                            exact
                            path="reports"
                            element={<Reports />}
                        />
                        <Route
                            exact
                            path="track-order"
                            element={<TrackerOrders />}
                        />
                        <Route
                            exact
                            path="table-orders"
                            element={<TableOrders />}
                        />
                        <Route
                            exact
                            path="qr-scanner"
                            element={<QrScanner />}
                        />
                        <Route exact path="orders" element={<Orders />} />
                        <Route
                            exact
                            path="customers"
                            element={<Customers />}
                        />
                        <Route path="inventory" element={<Outlet />}>
                            <Route index element={<Inventory />} />
                            {props.restaurant.integrate ===
                                "square" ? (
                                <>
                                    <Route
                                        path="new"
                                        element={<AddProduct />}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </Route>
                        <Route
                            exact
                            path="table-dine-in"
                            element={<TableDineIn />}
                        />
                        {props.restaurant.integrate === "square" ? (
                            <>
                                <Route path="modifiers" element={<Outlet />}>
                                    <Route
                                        index
                                        element={<SquareModifiersGroups />}
                                    />
                                    <Route
                                        path="new"
                                        element={<AddModifersGroup />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<SquareModifiers />}
                                    />
                                </Route>
                            </>
                        ) : (
                            <>
                                <Route
                                    exact
                                    path="modifiers"
                                    element={<ModifiersGroups />}
                                />
                                <Route
                                    exact
                                    path="modifiers/:modifiergroupId"
                                    element={<Modifiers />}
                                />
                            </>
                        )}
                        {Boolean(props.restaurant.integrate === "custom") &&
                            <Route path="condition-modifier" element={<Outlet />}>
                                <Route index element={<ConditonalModifier />} />
                            </Route>}
                        <Route path="categories" element={<Outlet />}>
                            {props.restaurant.integrate ===
                                "square" ? (
                                <>
                                    <Route index element={<CategoryGroups />} />
                                    <Route
                                        path=":categoryId"
                                        element={<Category />}
                                    />

                                </>
                            ) : (
                                <>
                                    {" "}
                                    <Route index element={<Categories />} />
                                    <Route
                                        path=":categoryId"
                                        element={<CategoryItems />}
                                    />
                                </>
                            )}
                        </Route>
                        <Route
                            exact
                            path="showqrcode"
                            element={<ShowQrCode />}
                        />
                        <Route
                            exact
                            path="account-settings"
                            element={<AccountSettings />}
                        />
                        <Route
                            exact
                            path="shorten-url"
                            element={<ShortenUrl />}
                        />
                        <Route
                            exact
                            path="order-settings"
                            element={<OrderConfig />}
                        />
                        <Route exact path="logout" element={<Logout />} />
                    </Route>
                </Route>
            </Routes>
        </>
    )

}

export default MerchantRouters