import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import * as queryString from "query-string";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { toast } from "react-toastify";

function AutoPost(props) {
  const [date, selectDate] = useState(new Date());
  const [image, setImage] = useState({ preview: "", data: "" });
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState("");
  const [Caption, setCaption] = useState("");
  const [location, selectedLocation] = useState("");
  const [status, setStatus] = useState("");

  // const today = new Date();
  // let in3Days = new Date();
  // in3Days.selectDate(in3Days.getDate() + 3);

  useEffect(() => {
    (function () {
      try {
        const { id: restaurantId } = props.restaurant;
        if (urlParams.code) {
          axios
            .get(`/autopost?code=` + urlParams.code)
            .then((response) => {
              setPages(response.data);
              toast.success("facebook connected");
            })
            .catch((err) => console.log(err));
        }
        if (urlInsta.access_token) {
          axios
            .post(`/autopost/instaauth/${restaurantId}`, urlInsta)
            .then((res) => {
              toast.success("Instagram connected");
            })
            .catch((err) => console.log(err));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const handleFacebook = async (e) => {
    const { id: restaurantId } = props.restaurant;
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", image.data);
    formData.append("Date", date);
    const response = await fetch(`/autopost/facebook/${restaurantId}`, {
      method: "POST",
      body: formData,
    });
    // if (response) setStatus(response.statusText);
    if (response) toast.success("Post shedualed");
  };
  const handleInstagram = async (e) => {
    const { id: restaurantId } = props.restaurant;
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", image.data);
    formData.append("caption", Caption);
    const response = await fetch(`/autopost/instagram/${restaurantId}`, {
      method: "POST",
      body: formData,
    });
    // if (response) setStatus(response.statusText);
    if (response) toast.success("Post Submitted");
  };
  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };

  const selectPage = async (e) => {
    setPage({
      selectedLocation: e.target.value,
    });
  };
  const handleConnect = (e) => {
    const { id: restaurantId } = props.restaurant;
    selectedLocation({
      selectedLocationId: pages.data.filter(
        (pageData) => pageData.id === page.selectedLocation
      )[0]["id"],
    });
    let data = {};
    data["location_id"] = page.selectedLocation;
    data["access_token"] = pages.data.filter(
      (pageData) => pageData.id === page.selectedLocation
    )[0]["access_token"];
    data["name"] = pages.data.filter(
      (pageData) => pageData.id === page.selectedLocation
    )[0]["name"];
    data["category"] = pages.data.filter(
      (pageData) => pageData.id === page.selectedLocation
    )[0]["category"];
    axios
      .post(`/autopost/selectfbpage/${restaurantId}`, data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
    toast.success(`${data["name"]} connected`);
    // window.location.reload();
  };

  const stringifiedParams = queryString.stringify({
    client_id: "1163132641223944",
    // redirect_uri: `http://localhost:3000/merchant/autopost`,
    redirect_uri: `https://sandbox.phonedash.us/merchant/autopost`,
    scope: [
      "public_profile",
      "instagram_shopping_tag_products",
      "pages_manage_posts",
      "pages_read_engagement",
      "publish_to_groups",
      "instagram_content_publish",
      "instagram_basic",
      "ads_management",
      "pages_show_list",
      "publish_video",
      "email",
      "user_posts",
      "user_friends",
      "user_videos",
      "user_photos",
    ].join(","), // comma seperated string
    response_type: "code",
    auth_type: "rerequest",
    display: "popup",
  });

  const facebookLoginUrl = `https://www.facebook.com/v4.0/dialog/oauth?${stringifiedParams}`;
  const urlParams = queryString.parse(window.location.search);

  const stringifiedInsta = queryString.stringify({
    client_id: "1163132641223944",
    display: "page",
    extras: { setup: { channel: "IG_API_ONBOARDING" } },
    // redirect_uri: `http://localhost:3000/merchant/autopost`,
    redirect_uri: `https://sandbox.phonedash.us/merchant/autopost`,
    scope: [
      "instagram_basic",
      "instagram_content_publish",
      "instagram_manage_comments",
      "instagram_manage_insights",
      "pages_show_list",
      "pages_read_engagement",
    ].join(","), // comma seperated string
    response_type: "token",
  });

  const InstagramLoginUrl = `https://www.facebook.com/v15.0/dialog/oauth?${stringifiedInsta}`;
  const urlInsta = queryString.parse(window.location.hash);

  if (urlInsta.access_token) {
    toast.success("Instagram connected");
  }

  return (
    <Container>
      <>
        <div
          style={{
            textalign: "center",
            width: "500px",
            margin: "auto",
            backgroundColor: "#fff",
          }}
          id="html-to-image"
        >
          <div
            style={{
              display: "block",
              margin: "2em auto",
              textAlign: "center",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Facebook</h2>
          </div>
          <div
            style={{
              display: "block",
              margin: "2em auto",
              textAlign: "center",
            }}
          >
            <Button
              style={{
                background: "blue",
                color: "#fff",
                fontWeight: "600",
                border: "0",
                padding: "6px 20px",
                borderRadius: "5px",
              }}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `${facebookLoginUrl}`;
              }}
            >
              {" "}
              Facebook Login
            </Button>
            {/* <a href={facebookLoginUrl}></a> */}
          </div>
          {pages.data ? (
            <>
              {console.log(pages.data)}
              <div>
                <select
                  style={{
                    display: "block",
                    margin: "2em auto",
                    textAlign: "center",
                  }}
                  onChange={selectPage}
                >
                  <option value="Select Page">Select Page</option>
                  {pages.data.map((item, i) => {
                    // console.log("item", item);
                    return (
                      <>
                        <option key={i} id={item.id} value={item.id}>
                          {item.name}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div
                style={{
                  display: "block",
                  margin: "2em auto",
                  textAlign: "center",
                }}
              >
                <Button
                  style={{
                    background: "green",
                    color: "#fff",
                    fontWeight: "600",
                    border: "0",
                    padding: "6px 20px",
                    borderRadius: "5px",
                  }}
                  onClick={handleConnect}
                >
                  Connect!
                </Button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "block",
                  margin: "2em auto",
                  textAlign: "center",
                }}
              >
                <p>You don't have any connected page under this account.</p>
                <Button
                  style={{
                    background: "green",
                    color: "#fff",
                    fontWeight: "600",
                    border: "0",
                    padding: "6px 20px",
                    borderRadius: "5px",
                  }}
                  // onClick={this.handleClose}
                >
                  Close!
                </Button>
              </div>
            </>
          )}
          <div
            style={{
              display: "block",
              margin: "2em auto",
              textAlign: "center",
            }}
          >
            {/* {image.preview && (
              <img src={image.preview} width="200" height="200" />
            )} */}
            <form style={{ display: "inline-grid" }} onSubmit={handleFacebook}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  styles={{ padding: "6px", margin: "2em auto" }}
                  renderInput={(props) => <TextField {...props} />}
                  label="Select a date & time"
                  value={date}
                  onChange={(e) => {
                    selectDate(e);
                  }}
                />
              </LocalizationProvider>
              <input
                type="file"
                name="file"
                onChange={handleFileChange}
              ></input>
              <Button
                style={{
                  background: "green",
                  color: "#fff",
                  fontWeight: "600",
                  border: "0",
                  padding: "6px 20px",
                  borderRadius: "5px",
                }}
                type="submit"
              >
                Submit
              </Button>
            </form>
            {status && <h4>{status}</h4>}
          </div>

          <div
            style={{
              display: "block",
              margin: "2em auto",
              textAlign: "center",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Instagram</h2>
          </div>
          <div
            style={{
              display: "block",
              margin: "2em auto",
              textAlign: "center",
            }}
          >
            <Button
              style={{
                background: "red",
                color: "#fff",
                fontWeight: "600",
                border: "0",
                padding: "6px 20px",
                borderRadius: "5px",
              }}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `${InstagramLoginUrl}`;
              }}
            >
              {" "}
              Instagram Login
            </Button>
          </div>
          <div
            style={{
              display: "block",
              margin: "2em auto",
              textAlign: "center",
            }}
          >
            {/* {image.preview && (
              <img src={image.preview} width="200" height="200" />
            )} */}
            <form style={{ display: "inline-grid" }} onSubmit={handleInstagram}>
              <input
                placeholder="Caption"
                type="text"
                value={Caption}
                onChange={(e) => setCaption(e.target.value)}
              />
              <input
                type="file"
                name="file"
                onChange={handleFileChange}
              ></input>
              <Button
                style={{
                  background: "green",
                  color: "#fff",
                  fontWeight: "600",
                  border: "0",
                  padding: "6px 20px",
                  borderRadius: "5px",
                }}
                type="submit"
              >
                Submit
              </Button>
            </form>
            {status && <h4>{status}</h4>}
          </div>
        </div>
      </>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
  };
};

export default connect(mapStateToProps)(AutoPost);
