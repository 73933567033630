import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { getDiscountOption } from "../Functions/fetch";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { toast } from "react-toastify";
//import avatar from "../avatar.png";

async function warnRedBox(id, action = "add") {
  if (action === "add") {
    document.getElementById(id).style.boxShadow =
      "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
  } else {
    document.getElementById(id).style.boxShadow = "none";
  }
}
class DiscountOption extends Component {
  state = {
    discount_point: "",
    data: [],
    loader: false,
    nodata: false,
    discount_percentage: "",
    discount_limit: "",
    coupon_option: "",
    point_input: "disabled",
    percent_input: "disabled",
    coupon_value: 0,
    limit_input: "disabled",
    stateUpdating: true,
  };
  async componentDidMount() {
    // this.setState({ loader: true });
    this.handleUpdateState();
  }
  handleUpdateState = async () => {
    this.setState({ stateUpdating: true });
    const res = await getDiscountOption(this.props.restaurant_id);
    if (res.length) {
      this.setState({ discount_point: res[0]["discount_points_per_dollar"] });
      this.setState({ discount_percentage: res[0]["discount_percentage"] });
      this.setState({ discount_limit: res[0]["coupon_discount_limit"] });
      if (res[0]["is_coupon_discount"] == 1) {
        this.setState({
          coupon_option: true,
        });
      } else {
        this.setState({
          coupon_option: false,
        });
      }
      this.setState({ stateUpdating: true });
      // setTimeout(() => this.setState({ loader: false }), 1000);
    } else {
      this.setState({ nodata: true, loader: false });
    }
  };
  handleSubmit = (e) => {
    return new Promise((resolve) => {
      try {
        this.setState({
          point_input: "disabled",
        });
        if (this.state.discount_point && this.state.discount_point != "") {
          axios
            .post(
              `/api/integrations/get/discount_points/${this.props.restaurant_id}`,
              { discount_points: this.state.discount_point }
            )
            .then((res) => {
              resolve(res.data);
              this.setState({
                locationData: res.data,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          toast.error("Please provide valid points");
        }
      } catch (error) {
        console.log(error);
      }
    });
  };
  handleChange = (e) => {
    warnRedBox(e.target.id, "remove");
    this.setState({
      discount_point: e.target.value,
    });
    // console.log(e.target.value);
  };

  couponPercentChange = (e) => {
    warnRedBox(e.target.id, "remove");
    this.setState({
      discount_percentage: e.target.value,
    });
    console.log(e.target.value);
  };
  percentageSubmit = (e) => {
    return new Promise((resolve) => {
      try {
        this.setState({
          percent_input: "disabled",
        });
        if (this.state.discount_percentage != "") {
          axios
            .post(
              `/api/integrations/get/discount_percentage/${this.props.restaurant_id}`,
              { discount_percentage: this.state.discount_percentage }
            )
            .then((res) => {
              resolve(res.data);
              this.setState({
                locationData: res.data,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("else");
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  limitChange = (e) => {
    warnRedBox(e.target.id, "remove");
    this.setState({
      discount_limit: e.target.value,
    });
    console.log(e.target.value);
  };
  limitSubmit = (e) => {
    return new Promise((resolve) => {
      try {
        this.setState({
          limit_input: "disabled",
        });
        if (this.state.discount_limit != "") {
          console.log(this.state);
          axios
            .post(
              `/api/integrations/get/discount_limit/${this.props.restaurant_id}`,
              { discount_limit: this.state.discount_limit }
            )
            .then((res) => {
              resolve(res.data);
              this.setState({
                locationData: res.data,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  couponOption = (e) => {
    //console.log(33,e.target.checked);
    // let temp = {};
    // if (this.coupon_option === "checked") {
    //   temp.coupon_option = "";
    //   temp.coupon_value = 1;
    // } else {
    //   temp.coupon_option = "checked";
    //   temp.coupon_value = 0;
    // }
    // this.setState(temp);
    // if (e.target.checked == true) {
    //   var coupon_value = 1;
    //   this.setState({
    //     coupon_option: "checked",
    //     coupon_value: 1,
    //   });
    // } else {
    //   this.setState({
    //     coupon_option: "",
    //     coupon_value: 0,
    //   });
    //   var coupon_value = 0;
    // }
    //console.log(coupon_value);
  };
  toggleCoupon = () => {
    return new Promise((resolve) => {
      try {
        axios
          .post(
            `/api/integrations/get/coupon_option/${this.props.restaurant_id}`,
            {
              coupon_option: this.state.coupon_option,
            }
          )
          .then((res) => {
            resolve(res.data);
            this.setState({
              locationData: res.data,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    });
  };
  handleSave = async () => {
    const { discount_point, discount_limit, discount_percentage } = this.state;
    let query = "";
    if (this.state.coupon_option) {
      query = `/api/integrations/send/discount/${this.props.restaurant.id}?status=add`;
    } else {
      query = `/api/integrations/send/discount/${this.props.restaurant.id}?status=remove`;
    }
    if (discount_point && discount_limit && discount_percentage) {
      console.table({ discount_point, discount_limit, discount_percentage });
      try {
        await Promise.all([
          this.handleSubmit(),
          this.limitSubmit(),
          this.percentageSubmit(),
          this.toggleCoupon(),
        ]);
        this.handleUpdateState();
        toast.success("Discount Setting Updated");
        fetch(query);
      } catch (error) {
        console.log(error);
      }
    } else {
      [
        { value: discount_point, id: "discount_point" },
        { value: discount_limit, id: "discount_limit" },
        { value: discount_percentage, id: "discount_percentage" },
      ].forEach((ite) => {
        if (!ite.value) {
          warnRedBox(ite.id);
        }
      });
    }
  };
  render() {
    const { data } = this.state;
    return (
      <>
        {this.state.loader ? (
          <div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                // minHeight: "100vh",
                transition: "0.5s ease-in-out 0s",
                height: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ScaleLoader size={12} color="#ff9900" />
            </div>
          </div>
        ) : this.state.nodata ? (
          <div style={{ textAlign: "center" }}>
            <h5 style={{ lineHeight: "1.5em", fontSize: "22px" }}>
              {/* Store Closed <br /> */}
              There are no customer to display
            </h5>
          </div>
        ) : (
          <>
            <div style={{ marginTop: "25px" }}>
              <h4>Discount Option</h4>
              <Card className="order-filter" style={{ boxShadow: "none" }}>
                <div className="coupon-container">
                  <Form className="pb-4">
                    <Row className="align-items-center">
                      <Col lg="5" md="5" sm="12">
                        <div className="coupon-label">Discount Option</div>
                      </Col>
                      <Col lg="7" md="7" sm="12">
                        <div>
                          <Form.Group className="mb-0">
                            <Form.Check
                              type="switch"
                              onChange={() =>
                                this.setState({
                                  coupon_option: !this.state.coupon_option,
                                })
                              }
                              //checked={!!this.state.checked[user.SD_Emplid]}
                              id="custom-switch-discount"
                              label="OFF/ON"
                              //value = "1"
                              checked={this.state.coupon_option}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Form className="pb-4">
                    <Row className="align-items-center">
                      <Col lg="5" md="5" sm="12">
                        <div className="coupon-label">
                          Discount Points per Dollar
                        </div>
                      </Col>
                      <Col lg="7" md="7" sm="12">
                        <div style={{ display: "flex" }}>
                          <Form.Group className="mb-0">
                            <Form.Control
                              onChange={this.handleChange}
                              value={this.state.discount_point}
                              type="number"
                              name="discount_point"
                              placeholder="Discount Points"
                              id="discount_point"
                              // disabled={this.state.point_input}
                              disabled={!this.state.coupon_option}
                              required
                            />
                          </Form.Group>
                          {/* <div className="text-center d-flex">
                          {this.state.point_input == "" ? (
                            <button
                              onClick={this.handleSubmit}
                              style={{ padding: "6px 10px" }}
                              className="btn btn-primary mb-0 ml-2"
                              type="button"
                              disabled={this.state.point_input}
                            >
                              save
                            </button>
                          ) : (
                            <button
                              onClick={() => this.setState({ point_input: "" })}
                              style={{ padding: "6px 10px" }}
                              className="btn btn-primary mb-0 ml-2"
                              type="button"
                            >
                              Edit
                            </button>
                          )}
                        </div> */}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Form className="pb-4">
                    <Row className="align-items-center">
                      <Col lg="5" md="5" sm="12">
                        <div className="coupon-label">
                          Discount Points Limit
                        </div>
                      </Col>
                      <Col lg="7" md="7" sm="12">
                        <div style={{ display: "flex" }}>
                          <Form.Group className="mb-0">
                            <Form.Control
                              onChange={this.limitChange}
                              value={this.state.discount_limit}
                              type="number"
                              name="discount_limit"
                              id="discount_limit"
                              placeholder="Set Discount Point Limit"
                              // disabled={this.state.limit_input}
                              disabled={!this.state.coupon_option}
                              required
                            />
                          </Form.Group>
                          {/* <div className="text-center d-flex">
                          {this.state.limit_input == "" ? (
                            <button
                              onClick={this.limitSubmit}
                              style={{ padding: "6px 10px" }}
                              className="btn btn-primary mb-0 ml-2"
                              type="button"
                            >
                              save
                            </button>
                          ) : (
                            <button
                              onClick={() => this.setState({ limit_input: "" })}
                              style={{ padding: "6px 10px" }}
                              className="btn btn-primary mb-0 ml-2"
                              type="button"
                            >
                              Edit
                            </button>
                          )}
                        </div> */}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Form className="pb-4">
                    <Row className="align-items-center">
                      <Col lg="5" md="5" sm="12">
                        <div className="coupon-label">
                          Discount Percentage(%)
                        </div>
                      </Col>
                      <Col lg="7" md="7" sm="12">
                        <div style={{ display: "flex" }}>
                          <Form.Group className="mb-0">
                            <Form.Control
                              onChange={this.couponPercentChange}
                              value={this.state.discount_percentage}
                              type="number"
                              name="discount_percentage"
                              placeholder="Discount Percentage"
                              // disabled={this.state.percent_input}
                              disabled={!this.state.coupon_option}
                              required
                              id="discount_percentage"
                            />
                          </Form.Group>
                          {/* <div className="text-center d-flex">
                          {this.state.percent_input == "" ? (
                            <button
                              onClick={this.percentageSubmit}
                              style={{ padding: "6px 10px" }}
                              className="btn btn-primary mb-0 ml-2"
                              type="button"
                            >
                              save
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                this.setState({ percent_input: "" })
                              }
                              style={{ padding: "6px 10px" }}
                              className="btn btn-primary mb-0 ml-2"
                              type="button"
                            >
                              Edit
                            </button>
                          )}
                        </div> */}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div className="d-flex justify-content-center">
                  <div></div>
                  <div>
                    <Button
                      onClick={this.handleSave}
                      disabled={this.stateUpdating}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </>
        )}
      </>
    );
  }
}
// const mapStateToProps = (state) => {
//   return {
//     restaurant_id: state.customer.resId,
//     customer: state.customer,
//     items: state.cart.items,
//     restaurant: state.restaurant,
//   };
// };
// export default connect(mapStateToProps, null)(DiscountOption);
export default DiscountOption;