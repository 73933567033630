import React, { Component, useState, useEffect } from "react";
// import storage from "../firebase/firebase";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "./pages.css";

import { Spinner } from "react-bootstrap";
import { addModifierGroup, deleteModifierGroup, getModifierGroup, updateModifierGroups } from "../Functions/fetch";
import ScaleLoader from "react-spinners/ScaleLoader";
import { compose } from "redux";
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import _ from 'lodash';
import { MdCancel, MdModeEdit } from 'react-icons/md'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function ModifierGroups(props) {
  const [modifiers, setModifiers] = useState([]);
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [state, set] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [name, setName] = useState("");
  const [choose_limit, setChooseLimit] = useState(0);
  const [pending, setPending] = useState(true);
  const [multiple, setMultiple] = useState(false);
  const [modifierGroupId, setModifierGroupId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenDel, setisOpenDel] = useState(false);
  // call your hook here
  useEffect(() => {
    async function helper() {
      try {
        const res = await getModifierGroup(
          props.restaurant.id,
          props.restaurant.integrate
        );
        // console.log(res);
        if (res) {
          let data = _.sortBy( res.elements, 'name' )
          setModifiers(data);
          setPending(false);
        } else {
          setPending(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    helper();
  }, [state]);

  const columns = [
    {
      name: "Name",
      cell: (row) => <div onClick={() => props.router.navigate(`${row.id}`, { state: {...row, restaurant_id:props.restaurant.id ,integrate:  props.restaurant.integrate} })}>{row.name}</div>,
    },
    {
      name: "No Of Modifiers",
      cell: (row) => <div>{row.modifiers.elements.length}</div>,
    },
    {
      name: "Actions",
      cell: (row) => <div><MdCancel color="red" size={"2em"} onClick={async () => {
        handleModifierDelete(row.id)
        // await deleteModifierGroup(props.restaurant.id, props.restaurant.integrate, row.id);
        // window.location.reload();
      }}/> 
      <span style={{paddingLeft: '10px', marginBotton: 10}}><MdModeEdit color="green" size={"1.2em"} onClick={() => handleModifierGroupEdit(row.id, row.name)} /></span>
      </div>,
    },
  ];
  const closeModal = () => {
    setisOpenAdd(false);
    setName("");
    setisOpenDel(false);
    setIsEdit(false);
    // console.log(descData.length || image)
  };
  const handleModifierGroupEdit = (id, name, price) => {
    setName(name);
    setModifierGroupId(id);
    setIsEdit(true);
    setisOpenAdd(true);
  }
  const handleDeleteItem = async () => {
    setSpinner(true);
    setDisabled(false);

    const res = await deleteModifierGroup(
      props.restaurant.id,
      props.restaurant.integrate,
      modifierGroupId
    );
    if (res) {
        window.location.reload();
    }
  };
  const handleModifierDelete = async (id, name) => {
    setModifierGroupId(id);
    setisOpenDel(true);
  }
  const updateModifierGroup = async () => {
    setDisabled(false);
    setSpinner(true);
    const response = await updateModifierGroups(
      props.restaurant.id,
      props.restaurant.integrate,
      modifierGroupId,
      name,
      multiple,
      choose_limit
    );
    if (response) {
      window.location.reload(false);
    }

  }

  const addItem = async () => {
    setDisabled(false);
    setSpinner(true);
    let multiple = document.getElementById('multiple-modifier')?.checked ? document.getElementById('multiple-modifier')?.checked : false
    const response = await addModifierGroup(
      props.restaurant.id,
      props.restaurant.integrate,
      name,
      multiple,
      choose_limit
    );
    if (response) {
      window.location.reload(false);
      // updateItemState();
      setDisabled(true);
      setSpinner(false);
    }
  };
  return (
    <>
      <Button
        className="mt-3 mb-2"
        style={{ borderRadius: "3px" }}
        onClick={() => setisOpenAdd(true)}
      >
        Add Modifier
      </Button>
      <DataTable
        paginationPerPage={10}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        columns={columns}
        data={modifiers}
        pagination
        progressPending={pending}
        progressComponent={<ScaleLoader size={12} color="#ec7211" />}
      // onChangePage={this.handlePageChange}
      />
      {isOpenAdd ? (
        <Modal
          dialogClassName="receipt-model1 add-item"
          show={isOpenAdd}
          onHide={closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div id="invoice-POS">
              <div id="bot">
                <div id="table">
                  <table>
                    <tbody>
                    <tr>
                        <td style={{ width: "33%" }}>Modifier Group</td>
                        <td>
                          {" "}
                          <Form.Control
                          value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            type="text"
                            size="sm"
                          />
                        </td>
                      </tr>
                      
                     {
                      props.restaurant.integrate !== 'clover' &&  !isEdit ?  <tr>
                      <td style={{ width: "33%" }}>Mutiple</td>
                      <td>
                      <input
                      onClick={() => setMultiple(!multiple)}
                          style={{marginRight:'10px'}}
                            type="checkbox"
                            id="multiple-modifier"
                          />
                      </td>
                    </tr> : <></>
                     }
                      {multiple ? <tr>
                        <td style={{ width: "33%" }}>Max Select</td>
                        <td>
                          {" "}
                          <Form.Control
                            onChange={(e) => {
                              setChooseLimit(parseInt(e.target.value));
                            }}
                            type="number"
                            size="sm"
                          />
                        </td>
                      </tr>: <></>}
                      
                    </tbody>
                  </table>
                  <center> 
                  {/* updateModifierGroup */}
                    {
                      isEdit ? <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={updateModifierGroup}
                      style={{ display: "inline-block" }}
                      disabled={name ? false : true}
                    >
                      Update
                      {spinner ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        <></>
                      )}
                    </Button> : <Button
                      className="btn mt-3 mb-3 btn-sm"
                      onClick={addItem}
                      style={{ display: "inline-block" }}
                      disabled={name ? false : true}
                    >
                      Add
                      {spinner ? (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      ) : (
                        <></>
                      )}
                    </Button>
                    }
                     
                  </center>
                </div>
              </div>
            </div>
          </Modal.Body>

        </Modal>
      ) : (
        <></>
      )}
      
      {isOpenDel ? (
        <Modal
          dialogClassName="del-model del-item"
          show={isOpenDel}
          onHide={closeModal}
        >
          <Modal.Body>
            <h5>You are about to delete a modifier group</h5>
            <p>
              This will delete your modifier group from inventory <br />
              Are you sure?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ background: "transparent", color: "#000", border: "0" }}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button variant="secondary" onClick={handleDeleteItem}>
              Delete
              {spinner ? (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
// export default connect(mapStateToProps, null)(Modifiers);

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(ModifierGroups);
