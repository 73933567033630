import React, { useRef } from "react";
import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import { Button, Modal } from "react-bootstrap";
import CustomButtonGroup from "../CustomButtonGroup";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import {
  getConditionalModifierGroups,
  getModifierGroups,
  getModifierListByProductId,
  timeDelay,
} from "../../utils/api";
import { AddWithModifier } from "../../redux/actions/cartActions";
import { CgShoppingCart } from "react-icons/cg";
import { RWebShare } from "react-web-share";
import { useSearchParams } from "react-router-dom";
import { FaShareAlt, FaRegPaperPlane } from "react-icons/fa";
import MultiLingualContent from "../../context/multilingualContent";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import whole_pizza from "../../assets/whole-pizza.png";
import left_half_pizza from "../../assets/left-half-pizza.png";
import right_half_pizza from "../../assets/right-half-pizza.png";
const subModStyle = {
  active: {
    backgroundColor: "#000",
    border: "none",
    color: "#fff",
    padding: "5px 15px",
    textAlign: "center",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    borderRadius: "50px",
  },
  not_active: {
    // backgroundColor: '#808080',
    border: "2px solid #ebe9e6",
    color: "#000",
    padding: "5px 15px",
    textAlign: "center",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    borderRadius: "50px",
    cursor: "pointer",
    backgroundColor: "#ebe9e6",
  },
};

const subModIcon = [
  { keyword: "whole", url: whole_pizza },
  { keyword: "left", url: left_half_pizza },
  { keyword: "right", url: right_half_pizza },
];
// const subModIcon = [
//   { keyword: 'whole', url: 'https://storage.googleapis.com/phonedash/images/whole-pizza.png' },
//   { keyword: 'left', url: 'https://storage.googleapis.com/phonedash/images/left-half-pizza.png' },
//   { keyword: 'right', url: 'https://storage.googleapis.com/phonedash/images/right-half-pizza.png' }
// ]

function removeKeysfromObject(obj, removeKeys) {
  if (!Array.isArray(removeKeys)) {
    removeKeys = [removeKeys];
  }
  if (!removeKeys.length) {
    console.log('keys cannot be empty');
    return obj
  }
  let mapData = new Map(Object.entries(obj));
  for (const key of removeKeys) {
    mapData.delete(key);
  }

  obj = Object.fromEntries(mapData.entries());
  return obj
}

function CustomDeliveryCard(props) {
  const [searchParams] = useSearchParams();
  const [showMore, setShowMore] = useState(false);
  const [show, setShow] = useState(false);
  // const [show, setShow] = useState(
  //   searchParams.get("itemId") && searchParams.get("itemId") === props.id
  //     ? true
  //     : false
  // );
  const [modifiers, setModifiers] = useState({});
  const [initmodifiers, setInitModifiers] = useState({});
  const [modSelect, setModSelect] = useState(false);
  const [loader, setLoader] = useState(false);
  const [choosedModifiers, setChoosedModifiers] = useState({});
  const [allmodifiers, setAllModifiers] = useState({});
  const [subModifierSelected, setSubModifierSelected] = useState({});
  const [instructions, setInstructions] = useState("");
  const [modifierChecked, setmodifierChecked] = useState({});
  const stateParent = useRef({ id: null, init_group_id: null });
  const [re_render, setRe_Render] = useState(false);
  const handleClose = () => {
    setInstructions("");
    setModifiers({});
    setChoosedModifiers({});
    setAllModifiers({});
    setShow(false);
    setModSelect(false);
    setSubModifierSelected({});
    setmodifierChecked({})
  };
  useEffect(() => {
    if (searchParams.get("itemId") && searchParams.get("itemId") === props.id) {
      handleShow();
    }
  }, []);
  const validateRequired = () => {
    let forms = document.querySelectorAll(`form[required]`);
    for (let form of forms) {
      if (!form.querySelectorAll(`:checked`).length) {
        document.getElementById("required-modifier").style.display = "block";
        return false;
      }
    }
    document.getElementById("required-modifier").style.display = "none";
    return true;
  };

  const handleInstructions = (e) => {
    setInstructions(e.target.value.replace(/[^a-zA-Z0-9 ]/gm, ""));
  };

  let itemTotal = 0;
  {
    props.orderCart
      .filter((item) => item.productId === props.id)
      .map((item, i) => {
        itemTotal = itemTotal + item.num;
      });
  }
  const language = searchParams.get("language");
  function getModifiers(productId) {
    return new Promise(async (resolve) => {
      try {
        const response = await getModifierListByProductId(
          props.restaurant.id,
          productId
        );
        if (response && response.modifier) {
          for (let data of response.data) {
            if (response.parent_grounp_id) {
              stateParent.current = { id: response.parent_grounp_id };
            }
            setModifiers((prevState) => ({
              ...prevState,
              [Object.values(data)[0][0].groupId]: data,
            }));
            setInitModifiers((prevState) => ({
              ...prevState,
              [Object.values(data)[0][0].groupId]: data,
            }));
            Object.values(data).forEach((item) => {
              item.forEach((ite) => {
                console.log("free limit count", ite.freeLimit);
                if (ite.default) {
                  if (
                    Object.values(data)?.length === 1 &&
                    Object.values(data)[0].type !== "checkbox"
                  ) {
                    setModSelect(true);
                  }
                  let id = ite.type === "checkbox" ? ite.id : ite.groupId;
                  const modData = {
                    [id]: {
                      modifier_name: ite.name,
                      modifier_price: ite.price,
                      modifier_id: ite.id,
                      modifier_group_id: ite.groupId,
                      modifier_group_name: ite.group_name,
                      modifier_free_limit: ite.freeLimit || 0
                    },
                  };
                  setChoosedModifiers((prevState) => ({
                    ...prevState,
                    ...modData,
                  }));
                }
              });
            });
          }
          resolve(true);
        } else {
          resolve(true);
        }
      } catch (error) {
        console.log(error);
        resolve(true);
      }
    });

    // setLoader(false);
  }

  const shareOpen = (e) => {
    const item = searchParams.get("item");
    console.log("shared successfully!");
  };
  const handleShow = async () => {
    // console.log(props.modifierGroups)
    setShow(true);
    if (props?.modifierGroups?.length) {
      const { id: productId } = props;
      setLoader(true);
      await getModifiers(productId);
      setLoader(false);
    }
  };
  function checkCurrentBox(modifierId) {
    return document.getElementById(modifierId).checked
  }
  function set_sorted_checkbox(group) {
    return new Promise(resolve => {
      let isclicked = checkCurrentBox(group.id)
      if (isclicked) {
        if (modifierChecked[group.groupId]) {
          setmodifierChecked(prevState => {
            let data = [...new Set([...modifierChecked[group.groupId], group.id])]
            resolve([data, []]);
            return { ...prevState, [group.groupId]: data };
          })
        } else {
          setmodifierChecked(prevState => {
            let data = [group.id];
            resolve([data, []]);
            return { ...prevState, [group.groupId]: data };
          })
        }
      } else {
        if (modifierChecked[group.groupId]) {
          setmodifierChecked(prevState => {
            let data = modifierChecked[group.groupId].filter(id => id !== group.id)
            resolve([data, [group.id]]);
            return { ...prevState, [group.groupId]: data };
          })
        }
      }
    })
  }
  const handleChangeCheck = async (e, id, group) => {
    e.persist()
    let [sorted_checkbox, remove_modifier] = await set_sorted_checkbox(group)
    let temp_choosedata = {};
    try {
      let limit = e.target.getAttribute("limit");
      let clicked_box = document.querySelectorAll(
        `[type="checkbox"][name="${e.target.getAttribute("name")}"]:checked`
      );
      let limitModLength = clicked_box.length;
      let all_check_box = document.querySelectorAll(
        `[type="checkbox"][name="${e.target.getAttribute("name")}"]`
      );
      for (let i = 0, check; (check = all_check_box[i]); i++) {
        if (limitModLength === Number(limit)) {
          console.log("Limit");
          if (!check.checked) {
            check.disabled = !(!e.target.checked || check === e.target);
          }
        } else {
          if (!check.checked) {
            check.disabled = false;
          }
        }
      }

      let data = {};
      if (
        limitModLength <= limit
      ) {
        let items = clicked_box
        items.forEach((item) => {
          let modifier_id = item.value;
          let modifier_name = item.getAttribute("modifier_name");
          let modifier_price = item.getAttribute("price");
          let modifier_group_id = item.getAttribute("modifier_id");
          let modifier_group_name = item.getAttribute("modifier_group_name");
          let sub_modifier = JSON.parse(item.getAttribute("sub_modifier"));
          // console.log(sub_modifier)
          let { freeLimit } = group;
          data[modifier_id] = {
            modifier_name: modifier_name,
            // modifier_price: modifier_price,
            modifier_price: freeLimit && sorted_checkbox.slice(0, freeLimit).includes(modifier_id)
              ? 0
              : modifier_price,
            modifier_id: modifier_id,
            modifier_group_id: modifier_group_id,
            modifier_group_name: modifier_group_name,
            // sub_modifier: sub_modifier
          };
          console.log(data)
          temp_choosedata = { ...data }
        });
        function updater() {
          return new Promise(resolve => {
            setChoosedModifiers((prevState) => {
              resolve('done')
              temp_choosedata = {
                ...prevState,
                ...temp_choosedata,
              }
              temp_choosedata = removeKeysfromObject(temp_choosedata, remove_modifier)
              return temp_choosedata
            });
          })
        }
        await updater()
      } else {
        console.log("exceed");
        e.target.checked = false;
      }
    } catch (error) {
      console.log(error);
    }

    // if (Object.keys(choosedModifiers).includes(e.target.value)) {
    //   let tempModifier = { ...choosedModifiers };
    //   delete tempModifier[e.target.value];
    //   setChoosedModifiers(tempModifier);
    // }

    // let choosedModifiersList = {...choosedModifiers}
    // if(choosedModifiersList[modifier_group_id]) {
    // choosedModifiersList[modifier_group_id] = [...choosedModifiersList[modifier_group_id], {
    // modifier_name: modifier_name,
    // modifier_price: modifier_price,
    // modifier_id: modifier_id,
    // modifier_group_id: modifier_group_id,
    // }]

    // console.log(choosedModifiersList)
    // } else {
    // choosedModifiersList[modifier_group_id] = [{
    // modifier_name: modifier_name,
    // modifier_price: modifier_price,
    // modifier_id: modifier_id,
    // modifier_group_id: modifier_group_id,
    // }]
    // }

    setModSelect(true);
  };

  const handleChange = async (e) => {
    let modifier_id = e.target.value;
    let modifier_name = e.target.getAttribute("modifier_name");
    let modifier_price = e.target.getAttribute("price");
    let modifier_group_id = e.target.getAttribute("modifier_id");
    let modifier_group_name = e.target.getAttribute("modifier_group_name");
    let sub_modifier = e.target.getAttribute("sub_modifier");
    let conditional_modifier_id = e.target.getAttribute("conditional_modifier");
    let conditional_modifier_data = e.target.getAttribute(
      "conditional_modifier_data"
    );
    let appliedModifiers = { ...modifiers };
    let allmodifiersList = { ...allmodifiers };
    allmodifiersList[modifier_group_id] = modifier_id;
    setAllModifiers((prevState) => ({
      ...prevState,
      [modifier_group_id]: modifier_id,
    }));
    if (
      stateParent.current.id &&
      stateParent.current.id === modifier_group_id
    ) {
      if (conditional_modifier_data) {
        console.log(`if conditional_modifier_data:`);
        setChoosedModifiers({});
        setLoader(true);
        setRe_Render(true);
        conditional_modifier_data = JSON.parse(conditional_modifier_data);
        if (conditional_modifier_data && conditional_modifier_data.modifier) {
          setModifiers((prevState) => ({
            [modifier_group_id]: prevState[modifier_group_id],
          }));
          for (let data of conditional_modifier_data.data) {
            setModifiers((prevState) => ({
              ...prevState,
              [Object.values(data)[0][0].groupId]: data,
            }));
            Object.values(data).forEach((item) => {
              item.forEach((ite) => {
                if (ite.default) {
                  if (
                    Object.values(data)?.length === 1 &&
                    Object.values(data)[0].type !== "checkbox"
                  ) {
                    setModSelect(true);
                  }
                  let id = ite.type === "checkbox" ? ite.id : ite.groupId;
                  const modData = {
                    [id]: {
                      modifier_name: ite.name,
                      modifier_price: ite.price,
                      modifier_id: ite.id,
                      modifier_group_id: ite.groupId,
                      modifier_group_name: ite.group_name,
                    },
                  };
                  setChoosedModifiers((prevState) => ({
                    ...prevState,
                    ...modData,
                  }));
                }
              });
            });
          }
        }
        await timeDelay(1);
        setRe_Render(false);
        setLoader(false);
      } else {
        console.log(`Else conditional_modifier_data:`);
        setLoader(true);
        setModifiers((prevState) => initmodifiers);
        appliedModifiers = { ...initmodifiers };
        allmodifiersList = { ...allmodifiers };
        allmodifiersList[modifier_group_id] = modifier_id;
        let data = {};
        data[modifier_group_id] = {
          modifier_name: modifier_name,
          modifier_price: modifier_price,
          modifier_id: modifier_id,
          modifier_group_id: modifier_group_id,
          modifier_group_name: modifier_group_name,
          sub_modifier: sub_modifier,
        };

        setChoosedModifiers(data);
        setRe_Render(true);
        await timeDelay(2);
        console.log(choosedModifiers);
        setRe_Render(false);
        setLoader(false);
        return null;
      }
    }
    console.log("reached outside");
    Object.entries(allmodifiersList).forEach(([key, value]) => {
      let temData = {};
      // if (modifier_group_id != key) {
      //   try {
      //     temData[key] = {
      //       modifier_group_name: Object.keys(appliedModifiers[key])[0],
      //       modifier_name: Object.values(appliedModifiers[key])[0].filter(
      //         (it) => it.id == value
      //       ).length
      //         ? Object.values(appliedModifiers[key])[0].filter(
      //           (it) => it.id == value
      //         )[0]["name"]
      //         : Object.values(appliedModifiers[key])[0][0]["name"],
      //       modifier_price: Object.values(appliedModifiers[key])[0].filter(
      //         (it) => it.id == value
      //       ).length
      //         ? Object.values(appliedModifiers[key])[0].filter(
      //           (it) => it.id == value
      //         )[0]["price"]
      //         : Object.values(appliedModifiers[key])[0][0]["price"],
      //       modifier_id: Object.values(appliedModifiers[key])[0].filter(
      //         (it) => it.id == value
      //       ).length
      //         ? Object.values(appliedModifiers[key])[0].filter(
      //           (it) => it.id == value
      //         )[0]["id"]
      //         : Object.values(appliedModifiers[key])[0][0]["id"],
      //       modifier_group_id: Object.values(appliedModifiers[key])[0].filter(
      //         (it) => it.id == value
      //       ).length
      //         ? Object.values(appliedModifiers[key])[0].filter(
      //           (it) => it.id == value
      //         )[0]["groupId"]
      //         : Object.values(appliedModifiers[key])[0][0]["groupId"],
      //     };
      //   } catch (e) {
      //     console.log("Catch", e)
      //     temData[key] = {
      //       modifier_group_name: Object.keys(appliedModifiers[key])[0],
      //       modifier_name: Object.values(appliedModifiers[key])[0][0]["name"],
      //       modifier_price: Object.values(appliedModifiers[key])[0][0]["price"],
      //       modifier_id: Object.values(appliedModifiers[key])[0][0]["id"],
      //       modifier_group_id: Object.values(appliedModifiers[key])[0][0][
      //         "groupId"
      //       ],
      //     };
      //   }
      // }
      // // console.log("temData =>", temData)
      // setChoosedModifiers((prevState) => ({
      //   ...prevState,
      //   ...temData,
      // }));
    });
    let data = {};
    data[modifier_group_id] = {
      modifier_name: modifier_name,
      modifier_price: modifier_price,
      modifier_id: modifier_id,
      modifier_group_id: modifier_group_id,
      modifier_group_name: modifier_group_name,
      sub_modifier: sub_modifier,
    };
    setChoosedModifiers((prevState) => ({
      ...prevState,
      ...data,
    }));
    setModSelect(true);
  };

  function handleSubMod(data, type = "check") {
    const { modifier_group_id, id, modifier_id, name } = data;
    setSubModifierSelected((prevState) => ({
      ...prevState,
      [modifier_group_id]: { id, name: name, type: type, modifier_id },
    }));
  }
  return (
    <>
      <Col
        xs={10}
        sm={3}
        md={6}
        lg={6}
        onClick={handleShow}
        className="naancurry"
      >
        <div className="menu-lists-item">
          <div className="menu-lists-item-left">
            <div className="menu-lists-item-sub">
              {props.title.includes("(") ? (
                <>
                  <h2 style={{ fontSize: "16px" }}>
                    {props.title}
                    {/* {props.title.split("(")[0].split(")")[0]} */}
                  </h2>{" "}
                  {props.description.length > 58 ? (
                    <>
                      <p
                        className="description"
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "5px",
                          color: "rgb(105, 114, 128)",
                        }}
                      >
                        {showMore ? (
                          <>{props.description} </>
                        ) : (
                          <>{props.description.substring(0, 50)}...</>
                        )}
                        <button
                          className="btnshow"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowMore(!showMore);
                          }}
                        >
                          {showMore ? "less" : "more"}
                        </button>
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className="description"
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "5px",
                          color: "rgb(105, 114, 128)",
                        }}
                      >
                        {props.description}
                      </p>
                    </>
                  )}
                  <p
                    style={{
                      position: "relative",
                      bottom: "0",
                      margin: "0px",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    {props.price
                      ? `$ ${parseFloat(props.price).toFixed(2)}`
                      : ""}
                  </p>
                </>
              ) : (
                <>
                  <h2>{props.title}</h2>
                  {props.description.length > 58 ? (
                    <>
                      <p
                        className="description"
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "5px",
                          color: "rgb(105, 114, 128)",
                        }}
                      >
                        {showMore ? (
                          <>{props.description} </>
                        ) : (
                          <>{props.description.substring(0, 50)}...</>
                        )}
                        <button
                          className="btnshow"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowMore(!showMore);
                          }}
                        >
                          {showMore ? "less" : "more"}
                        </button>
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className="description"
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "5px",
                          color: "rgb(105, 114, 128)",
                        }}
                      >
                        {props.description}
                      </p>
                    </>
                  )}
                  {/* <p style={{ margin: '0', color: "#000", fontSize: "16px" }}></p> */}
                  <p
                    style={{
                      position: "relative",
                      bottom: "0",
                      margin: "0px",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    {props.price
                      ? `$ ${parseFloat(props.price).toFixed(2)}`
                      : ""}
                    {/* $ {props.price} */}
                  </p>
                  {/* <p>{props.description}</p> */}
                </>
              )}
              <div className="d-flex">
                {props.isOpen ? (
                  <div className="addtocartbtn">
                    <p>
                      {" "}
                      <MultiLingualContent contentID="Add to Cart" />
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="share-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <RWebShare
                    data={{
                      text: `Check out ${props.title
                        } from ${props.restaurant.restaurant.name
                          .replace(/[$@&%]/g, "")
                          .replace(/ /g, " ")}`,
                      url: `${window.location.origin}/${props.restaurant.id}?itemId=${props.id}`,
                      title: `Share ${props.title} via`,
                    }}
                    onClick={shareOpen}
                  >
                    <button id="share">
                      <FaShareAlt />
                    </button>
                  </RWebShare>
                </div>
              </div>
            </div>
          </div>

          <div className="menu-lists-item-right">
            <img src={props.img} alt={props.title} />

            {itemTotal ? (
              <div className="item-no">
                <CgShoppingCart
                  style={{
                    verticalAlign: "-3px",
                    fontSize: "17px",
                    marginRight: "4px",
                  }}
                />
                {itemTotal}
              </div>
            ) : (
              <></>
            )}
            {/* <div style={{ backgroundImage: "url(" + props.img + ")" }}></div> */}
          </div>
        </div>
      </Col>

      {show ? (
        <>
          <Modal
            centered
            animation={true}
            dialogClassName="addcartpop"
            show={show}
            onHide={handleClose}
          >
            <button type="button" id="clo" onClick={handleClose} class="close">
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
            </button>
            <Modal.Body>
              <Modal.Header
                style={{ backgroundImage: "url(" + props.img + ")" }}
              >
                <Modal.Title>
                  {/* <img src={props.img} /> */}
                  {/* <div style={{ backgroundImage: "url(" + props.img + ")" }}></div> */}
                </Modal.Title>
              </Modal.Header>
              <div style={{ padding: "15px", background: "#fff" }}>
                <p style={{ margin: "0", fontWeight: "600", fontSize: "20px" }}>
                  {props.title}
                </p>
                <p
                  style={{
                    margin: "8px 0 0",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  {props.price ? `$ ${parseFloat(props.price).toFixed(2)}` : ""}
                  {/* ${props.price} */}
                </p>
                <p
                  className="description"
                  style={{
                    padding: "10px 0 0",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgb(105, 114, 128)",
                  }}
                >
                  {props.description}
                </p>
                {/* Modifiers */}
                {Object.keys(modifiers).length > 0 ? (
                  <>
                    {Object.keys(modifiers).map((id, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h2
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "800",
                                  letterSpacing: "-0.5px",
                                }}
                              >
                                {Object.keys(modifiers[id])[0]}
                              </h2>
                            </div>
                            <div>
                              {Object.values(modifiers[id])[0][0]["limit"] ? (
                                <span className="choose">{`Choose upto ${Object.values(modifiers[id])[0][0]["limit"]
                                  } of these items`}</span>
                              ) : (
                                <span className="choose">{`Choose ${Object.values(modifiers[id])[0][0]["type"] ===
                                  "radio"
                                  ? "one"
                                  : "any"
                                  } item`}</span>
                              )}
                              <span className="choose">
                                {Object.values(modifiers[id])[0][0][
                                  "required"
                                ] ? (
                                  <>
                                    <span style={{ color: "red" }}>
                                      *
                                      <MultiLingualContent contentID="Required" />
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <form
                              required={
                                Object.values(modifiers[id])[0][0]["required"]
                                  ? true
                                  : false
                              }
                            >
                              {Object.values(modifiers[id])[0].map(
                                (group, i) => {
                                  if (
                                    stateParent.current.id !== id &&
                                    re_render
                                  ) {
                                    return (
                                      <Skeleton count={2} duration={2.5} />
                                    );
                                  }
                                  return (
                                    <React.Fragment key={i}>
                                      {group.type === "radio" ? (
                                        <div className="radio-model">
                                          <input
                                            onChange={handleChange}
                                            type="radio"
                                            price={group.price}
                                            name={Object.keys(
                                              modifiers[id]
                                            )[0].replace(/ /g, "_")}
                                            modifier_name={group.name}
                                            modifier_id={id}
                                            modifier_group_name={
                                              Object.keys(modifiers[id])[0]
                                            }
                                            // modifier_group_name={group.group_name}
                                            value={group.id}
                                            id={id}
                                            conditional_modifier={group.cmod}
                                            conditional_modifier_data={
                                              group.conditional_modifier_data &&
                                              JSON.stringify(
                                                group.conditional_modifier_data
                                              )
                                            }
                                            defaultChecked={group.default}
                                            sub_modifier={JSON.stringify(
                                              group.sub_modifier
                                            )}
                                          />
                                          <label htmlFor="html">
                                            {group.name}
                                          </label>
                                          <label style={{ float: "right" }}>
                                            {group.price
                                              ? ` $${parseFloat(
                                                group.price / 100
                                              ).toFixed(2)}`
                                              : `$0.00`}
                                          </label>
                                          {group.sub_modifier &&
                                            group.sub_modifier.length &&
                                            choosedModifiers[group.groupId]
                                              ?.modifier_id === group.id ? (
                                            <div
                                              className="sub-md"
                                              style={{
                                                marginLeft: "32px",
                                                marginTop: "10px",
                                                display: "flex",
                                                gap: "10px",
                                              }}
                                            >
                                              {group.sub_modifier.map(
                                                (ite, key) => {
                                                  let { name, price } = ite;
                                                  let foundIcon =
                                                    subModIcon.find((ite) =>
                                                      name
                                                        .toLowerCase()
                                                        .includes(ite.keyword)
                                                    );
                                                  return (
                                                    <div
                                                      key={key}
                                                      style={
                                                        subModStyle[
                                                        subModifierSelected[
                                                          group.id
                                                        ] &&
                                                          subModifierSelected[
                                                            group.id
                                                          ].id === ite.id
                                                          ? "active"
                                                          : "not_active"
                                                        ]
                                                      }
                                                      onClick={() =>
                                                        handleSubMod(
                                                          {
                                                            ...ite,
                                                            modifier_group_id:
                                                              group.id,
                                                            modifier_id: id,
                                                          },
                                                          "radio"
                                                        )
                                                      }
                                                    >
                                                      {foundIcon ? (
                                                        <img
                                                          className="sb-icon"
                                                          src={foundIcon.url}
                                                        />
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {name}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="checkbox-mod">
                                          <input
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                              verticalAlign: "middle",
                                            }}
                                              onChange={(e) => {
                                                handleChangeCheck(e, id, group);
                                              }}
                                            type="checkbox"
                                            price={group.price}
                                              name={Object.keys(modifiers[id])[0]
                                                .replace(/ /g, "_")
                                                .replace(/"/g, "_")}
                                            modifier_name={group.name}
                                            modifier_group_name={
                                              Object.keys(modifiers[id])[0]
                                            }
                                            // modifier_group_name={group.group_name}
                                            modifier_id={id}
                                            limit={
                                              group.limit ? group.limit : 10
                                            }
                                              id={group.id}
                                            // id={id}
                                            value={group.id}
                                            conditional_modifier={group.cmod}
                                            defaultChecked={group.default}
                                              sub_modifier={JSON.stringify(
                                                group.sub_modifier
                                              )}
                                          />
                                          <label htmlFor="html">
                                            {group.name} +{" "}
                                            {group.price
                                              ? `$${parseFloat(
                                                group.price / 100
                                              ).toFixed(2)}`
                                              : `$0.00`}
                                          </label>
                                            {group.sub_modifier &&
                                              group.sub_modifier.length &&
                                              choosedModifiers[group.id] ? (
                                              <div
                                                className="sub-md"
                                                style={{
                                                  marginLeft: "32px",
                                                  marginTop: "10px",
                                                  display: "flex",
                                                  gap: "10px",
                                                }}
                                              >
                                                {group.sub_modifier.map(
                                                  (ite, key) => {
                                                    let {
                                                      name,
                                                      price,
                                                      id: subMObId,
                                                    } = ite;
                                                    let foundIcon =
                                                      subModIcon.find((ite) =>
                                                        name
                                                          .toLowerCase()
                                                          .includes(ite.keyword)
                                                      );
                                                    return (
                                                      <div
                                                        key={key}
                                                        style={
                                                          subModStyle[
                                                          subModifierSelected[
                                                            group.id
                                                          ] &&
                                                            subModifierSelected[
                                                              group.id
                                                            ].id === ite.id
                                                            ? "active"
                                                            : "not_active"
                                                          ]
                                                        }
                                                        onClick={() =>
                                                          handleSubMod({
                                                            ...ite,
                                                            modifier_group_id:
                                                              group.id,
                                                            modifier_id: id,
                                                          })
                                                        }
                                                      >
                                                        {foundIcon ? (
                                                          <img
                                                            className="sb-icon"
                                                            src={foundIcon.url}
                                                          />
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {name}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </form>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
                <div className="special-instructions">
                  <Form.Row>
                    <Form.Group as={Col} controlId="instructions">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={handleInstructions}
                        placeholder="Special Instructions"
                        required
                        value={instructions}
                      />
                    </Form.Group>
                  </Form.Row>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ border: "0" }}>
              <span
                style={{ color: "red", fontSize: "18px", display: "none" }}
                id="required-modifier"
              >
                <MultiLingualContent contentID="Please fill the required fields" />
              </span>
              <div className="newcart" style={{ width: "100%" }}>
                <div className="plusbtn">
                  {props.isOpen ? (
                    props.stock ? (
                      <>
                        {props.price || modSelect ? (
                          <CustomButtonGroup
                            toDisabled={loader}
                            validateRequired={validateRequired}
                            choosedModifiers={choosedModifiers}
                            modifierPrice={props.modifiersPriceClicked}
                            id={props.id}
                            title={props.title}
                            num={props.num}
                            modifierGroups={props.modifierGroups}
                            onClick={handleClose}
                            instructions={instructions}
                            subMod={subModifierSelected}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <p
                        style={{
                          color: "#f55142",
                          fontSize: "18px",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        <MultiLingualContent contentID="Out Of Stock" />
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    AddWithModifier: (title, modifierId, modifierName, price) => {
      dispatch(AddWithModifier(title, modifierId, modifierName, price));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
    orderCart: state.cart.orderCart,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomDeliveryCard);