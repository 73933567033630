export const translations = {
  en: {
    Checkout: 'Checkout',
    Cart: "Your Cart",
    from: "from",
    "Proceed to Cart": "Proceed to Cart",
    "Add to Cart": "Add to Cart",
    no_items: "No items in your cart.",
    Quantity: "Quantity",
    per_item: "Per Item",
    Remove: "Remove",
    All: "All",
    "Popular Items": "Popular Items",
    Others: "Others",
    "Out Of Stock": "Out Of Stock",
    "Please fill the required fields": "Please fill the required fields",
    Required: "Required",
    "Open Now": "Open Now",
    Closed: "Closed",
    "Temporarily closed": "Temporarily closed",
    "Pickup Time": "Pickup Time",
    ASAP: "ASAP",
    Summary: "Summary",
    "Order Summary": "Order Summary",
    "Pickup at": "Pickup at",
    Coupon: "Coupon",
    Subtotal: "Subtotal",
    Total: "Total",
    "Pay Online": "Pay Online",
    "Pay Cash": "Pay Cash",
    "Place Order": "Place Order",
    "Billing Details": "Billing Details",
    "Enter card details": "Enter card details",
    "WE ACCEPT": "WE ACCEPT",
    "Please enter the phone number to receive the order confirmation text.":
      "Please enter the phone number to receive the order confirmation text.",
    "Pay Now": "Pay Now",
    Apply: "Apply",
    "Coupon Discount": "Coupon Discount",
    "Previous Total": "Previous Total",
    items: "items",
    Taxes: "Taxes",
    Tips: "Tips",
    "No Tip": "No Tip",
    "Add Tip": "Add Tip",
    "Order notes": "Order notes",
    "Scan credit card": "Scan Credit Card",
    More: "More",
    "Coupon Applied": "Coupon Applied",
    "Thanks for your order": "Thanks for your order",
    "Continue Order": "Continue Order",
    "Your Payment was Successful": "Your Payment was Successful",
    "Enjoy your meals": "Enjoy your meals",
    "Please rescan the QR code to Add more": "Please rescan the QR code to Add more",
    "items to your existing order or Pay": "items to your existing order or Pay",
    "Page you are looking is not available or expired": "Page you are looking is not available or expired",
    Please: "Please",
    "click here": "click here",
    "to view your order details": "to view your order details",
    "Please reach the front desk and show the phone screen": "Please reach the front desk and show the phone screen",
    "Your order has been received. Your order will be ready in 10 minutes.": "Your order has been received. Your order will be ready in 10 minutes.",
  },
  it: {
    Checkout: 'Guardare',
    Cart: "Il tuo carrello",
    from: "da",
    "Proceed to Cart": "Procedi al carrello",
    "Add to Cart": "Aggiungi al carrello",
    no_items: "Nessun articolo nel carrello.",
    Quantity: "Quantità",
    per_item: "Per articolo",
    Remove: "Rimuovi",
    All: "Tutto",
    "Popular Items": "Articoli popolari",
    Others: "Altri",
    "Out Of Stock": "Esaurito",
    "Please fill the required fields":
      "Si prega di compilare i campi richiesti",
    Required: "Obbligatorio",
    "Open Now": "Apri ora",
    Closed: "Chiuso",
    "Temporarily closed": "Temporaneamente chiuso",
    "Pickup Time": "Orario di ritiro",
    ASAP: "AL PIÙ PRESTO",
    Summary: "Riepilogo",
    "Order Summary": "Riepilogo ordine",
    "Pickup at": "Ritiro alle",
    Coupon: "Buono",
    Subtotal: "Totale parziale",
    Total: "Totale",
    "Pay Online": "Paga online",
    "Pay Cash": "Paga in contanti",
    "Place Order": "Effettua ordine",
    "Billing Details": "Dettagli di fatturazione",
    "Enter card details": "Inserisci i dettagli della carta",
    "WE ACCEPT": "ACCETTO",
    "Please enter the phone number to receive the order confirmation text.":
      "Inserisci il numero di telefono per ricevere il testo di conferma dell'ordine.",
    "Pay Now": "Paga ora",
    Apply: "Applicare",
    "Coupon Discount": "Buono Sconto",
    "Previous Total": "Totale precedente",
    items: "Oggetti",
    Taxes: "Tasse",
    Tips: "Suggerimenti",
    "No Tip": "Nessuna mancia",
    "Add Tip": "Aggiungi suggerimento",
    "Order notes": "Note d'ordine",
    "Scan credit card": "Scansiona la carta di credito",
    More: "Di più",
    "Coupon Applied": "Tagliando applicato",
    "Thanks for your order": "grazie per il tuo ordine",
    "Continue Order": "Continua l'ordine",
    "Your Payment was Successful": "Il tuo pagamento è andato a buon fine",
    "Enjoy your meals": "Buon appetito",
    "Please rescan the QR code to Add more": "Eseguire nuovamente la scansione del codice QR per aggiungere altro",
    "items to your existing order or Pay": "articoli al tuo ordine esistente o Paga",
    "Page you are looking is not available or expired": "La pagina che stai cercando non è disponibile o è scaduta",
    Please: "Per favore",
    "click here": "clicca qui",
    "to view your order details": "per visualizzare i dettagli dell'ordine",
    "Please reach the front desk and show the phone screen": "Raggiungi la reception e mostra lo schermo del telefono",
    "Your order has been received. Your order will be ready in 10 minutes.": "Il suo ordine è stato ricevuto. Il tuo ordine sarà pronto in 10 minuti.",
  },
  es: {
    Checkout: 'Verificar',
    Cart: "Tu carrito",
    from: "de",
    "Proceed to Cart": "Proceder al carrito",
    "Add to Cart": "Añadir al carrito",
    no_items: "No hay artículos en su carrito.",
    Quantity: "Cantidad",
    per_item: "Por item",
    Remove: "Eliminar",
    All: "Todas",
    "Popular Items": "Artículos populares",
    Others: "Otros",
    "Out Of Stock": "Agotado",
    "Please fill the required fields":
      "Por favor, rellene los campos obligatorios",
    Required: "Requerido",
    "Open Now": "Abierto ahora",
    Closed: "Cerrado",
    "Temporarily closed": "Temporalmente cerrado",
    "Pickup Time": "Hora de recogida",
    ASAP: "lo antes posible",
    Summary: "Resumen",
    "Order Summary": "Resumen del pedido",
    "Pickup at": "Recoger en",
    Coupon: "Cupón",
    Subtotal: "Total parcial",
    Total: "Total",
    "Pay Online": "Paga en linea",
    "Pay Cash": "Pagar en efectivo",
    "Place Order": "Realizar pedido",
    "Billing Details": "Detalles de facturación",
    "Enter card details": "Ingrese los detalles de la tarjeta",
    "WE ACCEPT": "Nosotros aceptamos",
    "Please enter the phone number to receive the order confirmation text.":
      "Ingrese el número de teléfono para recibir el texto de confirmación del pedido.",
    "Pay Now": "Pagar ahora",
    Apply: "Solicitar",
    "Coupon Discount": "Cupón de descuento",
    "Previous Total": "Total anterior",
    items: "elementos",
    Taxes: "Impuestos",
    Tips: "Consejos",
    "No Tip": "Sin propina",
    "Add Tip": "Agregar sugerencia",
    "Order notes": "Pedidos",
    "Scan credit card": "Escanear tarjeta de crédito",
    More: "Más",
    "Coupon Applied": "Cupón aplicado",
    "Thanks for your order": "Gracias por tu orden",
    "Continue Order": "Continuar pedido",
    "Your Payment was Successful": "Su pago fue exitoso",
    "Enjoy your meals": "Disfruta tus comidas",
    "Please rescan the QR code to Add more": "Vuelva a escanear el código QR para agregar más",
    "items to your existing order or Pay": "artículos a su orden existente o Pagar",
    "Page you are looking is not available or expired": "La página que está buscando no está disponible o ha caducado",
    Please: "Por favor",
    "click here": "haga clic aquí",
    "to view your order details": "para ver los detalles de su pedido",
    "Please reach the front desk and show the phone screen": "Diríjase a la recepción y muestre la pantalla del teléfono.",
    "Your order has been received. Your order will be ready in 10 minutes.": "Tu orden ha sido recibida. Tu pedido estará listo en 10 minutos.",
  },
  "zh-CN": {
    Checkout: '查看',
    Cart: "您的購物車",
    from: "從",
    "Proceed to Cart": "前往購物車",
    "Add to Cart": "添加到购物车",
    no_items: "您的購物車中沒有商品.",
    Quantity: "數量",
    per_item: "每件",
    Remove: "消除",
    All: "全部",
    "Popular Items": "人气商品",
    Others: "其他",
    "Out Of Stock": "缺货",
    "Please fill the required fields": "请填写必填字段",
    Required: "必需的",
    "Open Now": "现在开门了",
    Closed: "关闭",
    "Temporarily closed": "暂时关闭",
    "Pickup Time": "取件时间",
    ASAP: "尽快",
    Summary: "概括",
    "Order Summary": "订单摘要",
    "Pickup at": "取货时间",
    Coupon: "优惠券",
    Subtotal: "小计",
    Total: "全部的",
    "Pay Online": "在线支付",
    "Pay Cash": "付现金",
    "Place Order": "下订单",
    "Billing Details": "结算明细",
    "Enter card details": "输入卡片详细信息",
    "WE ACCEPT": "我们接受",
    "Please enter the phone number to receive the order confirmation text.":
      "请输入电话号码以接收订单确认短信。",
    "Pay Now": "现在付款",
    Apply: "申请",
    "Coupon Discount": "优惠券折扣",
    "Previous Total": "以前的总计",
    items: "项目",
    Taxes: "税收",
    Tips: "提示",
    "No Tip": "不给小费",
    "Add Tip": "添加提示",
    "Order notes": "订购须知",
    "Scan credit card": "扫描信用卡",
    More: "更多的",
    "Coupon Applied": "已使用优惠券",
    "Thanks for your order": "感谢您的订单",
    "Continue Order": "继续订购",
    "Your Payment was Successful": "您的支付成功",
    "Enjoy your meals": "享受你的饭菜",
    "Please rescan the QR code to Add more": "请重新扫描二维码添加更多",
    "items to your existing order or Pay": "项目到您现有的订单或支付",
    "Page you are looking is not available or expired": "您正在查看的页面不可用或已过期",
    Please: "请",
    "click here": "点击这里",
    "to view your order details": "查看您的订单详情",
    "Please reach the front desk and show the phone screen": "请到前台出示手机屏幕",
    "Your order has been received. Your order will be ready in 10 minutes.": "您的订单已收到。您的订单将在 10 分钟内准备好。",
  },
  hi: {
    Checkout: 'चेक आउट',
    Cart: "आपकी गाड़ी",
    from: "से",
    "Proceed to Cart": "कार्ट के लिए आगे बढ़ें",
    "Add to Cart": "कार्ट में डालें",
    no_items: "आपकी कार्ट में कोई सामान नहीं.",
    Quantity: "मात्रा",
    per_item: "हर व्यस्तु पर",
    Remove: "निकालना",
    All: "सभी",
    "Popular Items": "लोकप्रिय आइटम",
    Others: "अन्य",
    "Out Of Stock": "स्टॉक ख़त्म",
    "Please fill the required fields": "कृपया आवश्यक फ़ील्ड भरें",
    Required: "आवश्यक",
    "Open Now": "अब खोलो",
    Closed: "बंद किया हुआ",
    "Temporarily closed": "अस्थायी रूप से बंद",
    "Pickup Time": "समय लेने",
    ASAP: "यथाशीघ्र",
    Summary: "सारांश",
    "Order Summary": "आदेश सारांश",
    "Pickup at": "पिकअप पर",
    Coupon: "कूपन",
    Subtotal: "उप-योग",
    Total: "संपूर्ण",
    "Pay Online": "ऑनलाइन भुगतान करें",
    "Pay Cash": "नकद भुगतान",
    "Place Order": "आदेश देना",
    "Billing Details": "बिलिंग विवरण",
    "Enter card details": "कार्ड विवरण दर्ज करें",
    "WE ACCEPT": "हम स्वीकार करते है",
    "Please enter the phone number to receive the order confirmation text.":
      "आदेश पुष्टि पाठ प्राप्त करने के लिए कृपया फ़ोन नंबर दर्ज करें।",
    "Pay Now": "अब भुगतान करें",
    Apply: "आवेदन करना",
    "Coupon Discount": "कूपन छूट",
    "Previous Total": "पिछला कुल",
    items: "सामान",
    Taxes: "कर",
    Tips: "टिप्स",
    "No Tip": "नो टिप",
    "Add Tip": "टिप जोड़ें",
    "Order notes": "ऑर्डर नोट",
    "Scan credit card": "क्रेडिट कार्ड स्कैन करें",
    More: "अधिक",
    "Coupon Applied": "कूपन लागू",
    "Thanks for your order": "आपके आदेश के लिए धन्यवाद",
    "Continue Order": "आदेश जारी रखें",
    "Your Payment was Successful": "आपका भुगतान सफल रहा",
    "Enjoy your meals": "अपने भोजन का आनंद लो",
    "Please rescan the QR code to Add more": "अधिक जोड़ने के लिए कृपया क्यूआर कोड को फिर से स्कैन करें",
    "items to your existing order or Pay": "अपने मौजूदा आदेश या भुगतान के लिए आइटम",
    "Page you are looking is not available or expired": "आप जो पृष्ठ देख रहे हैं वह उपलब्ध नहीं है या समाप्त हो गया है",
    Please: "कृपया",
    "click here": "यहाँ क्लिक करें",
    "to view your order details": "अपना ऑर्डर विवरण देखने के लिए",
    "Please reach the front desk and show the phone screen": "कृपया फ्रंट डेस्‍क पर पहुंचें और फोन की स्‍क्रीन दिखाएं",
    "Your order has been received. Your order will be ready in 10 minutes.": "तुम्हारा आदेश मिल गया है। आपका ऑर्डर 10 मिनट में तैयार हो जाएगा।",
  },
  ta: {
    Cart: "உங்கள் வண்டி",
    from: "இருந்து",
    "Proceed to Cart": "வண்டிக்குச் செல்லவும்",
    "Add to Cart": "வண்டியில் சேர்",
    no_items: "உங்கள் வண்டியில் பொருட்கள் இல்லை.",
    Quantity: "அளவு",
    per_item: "ஒரு பொருளுக்கு",
    Remove: "நீக்கு",
    All: "அனைத்தும்",
    "Popular Items": "பிரபலமான பொருட்கள்",
    Others: "மற்றவை",
    "Out Of Stock": "பங்கு இல்லை",
    "Please fill the required fields": "தேவையான புலங்களை நிரப்பவும்",
    Required: "தேவை",
    "Open Now": "இப்போது திற",
    Closed: "மூடப்பட்டது",
    "Temporarily closed": "தற்காலிகமாக மூடப்பட்டது",
    "Pickup Time": "பிக்அப் நேரம்",
    ASAP: "விரைவாக",
    Summary: "சுருக்கம்",
    "Order Summary": "ஆர்டர் சுருக்கம்",
    "Pickup at": "பிக்கப் அட்",
    Coupon: "கூப்பன்",
    Subtotal: "துணைத்தொகை",
    Total: "மொத்தம்",
    "Pay Online": "ஆன்லைனில் பணம் செலுத்து",
    "Pay Cash": "பணத்தை செலுத்து",
    "Place Order": "பிளேஸ் ஆர்டர்",
    "Billing Details": "பில்லிங் விவரங்கள்",
    "Enter card details": "அட்டை விவரங்களை உள்ளிடவும்",
    "WE ACCEPT": "நாங்கள் ஏற்றுக்கொள்கிறோம்",
    "Please enter the phone number to receive the order confirmation text.":
      "ஆர்டர் உறுதிப்படுத்தல் உரையைப் பெற தொலைபேசி எண்ணை உள்ளிடவும்.",
    "Pay Now": "இப்போது செலுத்த",
    Apply: "விண்ணப்பிக்கவும்",
    "Coupon Discount": "கூப்பன் தள்ளுபடி",
    "Previous Total": "முந்தைய மொத்தம்",
    items: "பொருட்களை",
    Taxes: "வரிகள்",
    Tips: "உதவிக்குறிப்புகள்",
    "No Tip": "நோ டிப்",
    "Add Tip": "உதவிக்குறிப்பைச் சேர்",
    "Order notes": "ஆர்டர் குறிப்புகள்",
    "Scan credit card": "கிரெடிட் கார்டை ஸ்கேன் செய்யவும்",
    More: "மேலும்",
    "Coupon Applied": "கூப்பன் பயன்படுத்தப்பட்டது",
    "Thanks for your order": "உங்கள் ஆர்டருக்கு நன்றி",
    "Continue Order": "தொடரவும் ஆர்டர்",
    "Your Payment was Successful": "உங்கள் பணம் செலுத்தப்பட்டது",
    "Enjoy your meals": "உங்கள் உணவை அனுபவிக்கவும்",
    "Please rescan the QR code to Add more": "மேலும் சேர்க்க QR குறியீட்டை மீண்டும் ஸ்கேன் செய்யவும்",
    "items to your existing order or Pay": "உங்கள் தற்போதைய ஆர்டருக்கான பொருட்கள் அல்லது பணம் செலுத்துங்கள்",
    "Page you are looking is not available or expired": "நீங்கள் தேடும் பக்கம் கிடைக்கவில்லை அல்லது காலாவதியானது",
    Please: "தயவு செய்து",
    "click here": "இங்கே கிளிக் செய்யவும்",
    "to view your order details": "உங்கள் ஆர்டர் விவரங்களைப் பார்க்க",
    "Please reach the front desk and show the phone screen": "முன் மேசையை அடைந்து ஃபோன் திரையைக் காட்டுங்கள்",
    "Your order has been received. Your order will be ready in 10 minutes.": "உங்கள் ஆணை கிடைக்க பெற்றது. உங்கள் ஆர்டர் 10 நிமிடங்களில் தயாராகிவிடும்.",
  },
};
