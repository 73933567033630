import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "../pages.css";
import { Spinner } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import { compose } from "redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { MdCancel, MdModeEdit } from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import { toast } from "react-toastify";

async function warnRedBox(id, action = "add") {
  if (action === "add") {
    document.getElementById(id).style.boxShadow =
      "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
  } else {
    document.getElementById(id).style.boxShadow = "none";
  }
}
async function warnNotify(message) {
  toast.error(message);
}
function AddProduct(props) {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [addProductTemp, setAddProductTemp] = useState({ name: "", price: "" });
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  useEffect(() => {
    (() => {
      updateIntState();
    })();
  }, []);
  async function handleProduct(e) {
    const { id, value, name } = e.target;
    warnRedBox(id, "remove");
    switch (name) {
      case "price":
        let price = Math.round(Number(value) * 100);
        setAddProductTemp((prevState) => {
          return { ...prevState, price };
        });
        break;
      case "name":
        setAddProductTemp((prevState) => {
          return { ...prevState, name: value };
        });
        break;
    }
  }
  async function updateIntState() {
    getCategory();
  }
  async function getCategory() {
    let response = await fetch(
      `/api/v1/merchant/get/square/category/${props.restaurant.id}`
    );
    response = await response.json();
    setCategories(response.data);
  }
  async function handleSave() {
    let temp = { ...addProductTemp };
    if (temp.name.length <= 0) {
      warnRedBox("name");
    } else if (!temp.price || temp.price <= 0) {
      warnRedBox("price");
    } else {
      if (selectedCategoryId) {
        temp.categoryId = selectedCategoryId;
      }
      let res = await fetch(
        `/api/v1/merchant/add/product/square/${props.restaurant.id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: temp }),
        }
      );
      if (res) {
        navigate("/merchant/inventory");
        toast.success("New Product added");
      }
    }
  }
  return (
    <>
      <div className="d-flex justify-content-between">
        <div></div>
        <div className="d-flex justify-content-between" style={{ gap: "12px" }}>
          {/* <Button>Cancel</Button> */}
          <Button onClick={handleSave}>Save</Button>
        </div>
      </div>
      <h3>Create an Item</h3>
      <div>
        <form>
          <p>Details</p>
          <fieldset className="grid-row">
            <Form.Control
              onChange={handleProduct}
              placeholder="Name"
              maxLength={255}
              type="text"
              name="name"
              id="name"
              autofocus
              style={{ width: "50%" }}
            />
          </fieldset>

          <fieldset className="grid-row">
            <Form.Group>
              <Form.Label>Select category</Form.Label>
              <Form.Control
                style={{ width: "25%" }}
                as="select"
                value={selectedCategory}
                onChange={(e) => {
                  let { value } = e.target;
                  let index = e.target.selectedIndex;
                  let optionElement = e.target.childNodes[index];
                  let id = optionElement.id;
                  setSelectedCategory(value);
                  setSelectedCategoryId(id);
                }}
              >
                <option value="categroy">categroy</option>
                {categories.map((cat, key) => (
                  <option value={cat.category_data.name} id={cat.id} key={key}>
                    {cat.category_data.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </fieldset>
          <fieldset>
            <Form.Label>Price</Form.Label>
            <Form.Control
              onChange={handleProduct}
              placeholder="$0.00"
              type="text"
              autoCapitalize="off"
              name="price"
              maxLength={5}
              id="price"
              style={{ width: "25%" }}
            />
          </fieldset>
        </form>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(AddProduct);
