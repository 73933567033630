import React, { useState, useEffect } from "react";
import {
  FaTh,
  FaColumns,
  FaBars,
  FaAngleRight,
} from "react-icons/fa";
import * as BiIcons from "react-icons/bi";

import { NavLink } from "react-router-dom";
import brandLogo from "../../assets/PhonedashLogo.jpg";
import "./SideBar.css";

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  let sideBarMenu = [
    {
      name: "Dashboard",
      path: "/customer/dashboard",
      icon: <FaTh />,
    },
    {
      name: "Orders",
      path: "/customer/orders",
      icon: <FaColumns />,
    },
    {
      name: "Table Orders",
      path: "/customer/table-orders",
      icon: <BiIcons.BiFoodMenu />,
    },
  ];


  return (
    <>
      {window.innerWidth > 991 ? (
        <>
          <div className="container-sidebar ">
            <div className="sidecontent">
              <div
                style={{
                  width: isOpen ? "220px" : "50px",
                  background: "#ec7211",
                }}
                className="sidebar"
              >
                <div className="top_section">
                  <div
                    style={{
                      marginLeft: isOpen ? "10px" : "0px",
                      marginTop: "10px",
                    }}
                    className="bars"
                  >
                    {isOpen ? (
                      <FaBars onClick={toggle} />
                    ) : (
                      <>
                        <FaAngleRight onClick={toggle} />
                      </>
                    )}
                  </div>
                </div>
                {isOpen ? (
                  <>
                    <div
                      style={{
                        marginBottom: "5px",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        padding: "7px 0",
                      }}
                    >
                      <img
                        data-v-a1f69832=""
                        src={`${brandLogo}`}
                        width="120px"
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="side-bar-scroll">
                  {sideBarMenu.map((item, index) => (
                    <>
                      <NavLink
                        to={item.path}
                        key={index}
                        className="link"
                        activeclassName="active"
                      >
                        <div className="icon">{item.icon}</div>
                        <div
                          style={{ display: isOpen ? "block" : "none" }}
                          className="link_text"
                        >
                          {item.name}
                        </div>
                      </NavLink>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <main>
              <div className="main-content-dash">
                <div className="full-width">{children}</div>
              </div>
            </main>
          </div>
        </>
      ) : (
        <>
          <div className="container-sidebar ">
            <div className="sidecontent">
              <div className="top_section">
                <div
                  style={{ marginLeft: isOpen ? "10px" : "0px" }}
                  className="bars"
                >
                  {isOpen ? (
                    <>
                      <FaBars onClick={toggle} />
                    </>
                  ) : (
                    <>

                      <FaAngleRight onClick={toggle} />
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: isOpen ? "none" : "block",
                  background: isOpen ? "#fff" : "#ec7211",
                }}
                className="sidebar"
              >
                <div className="mobile-header">
                  {isOpen ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          backgroundColor: "#fff",
                          padding: "7px 0",
                        }}
                      >
                        <img
                          data-v-a1f69832=""
                          src={`${brandLogo}`}
                          width="120px"
                        />
                      </div>
                    </>
                  )}
                  {sideBarMenu.map((item, index) => (
                    <>
                      <NavLink
                        to={item.path}
                        key={index}
                        className="link"
                        activeclassName="active"
                        onClick={toggle}
                      >
                        <div className="icon">{item.icon}</div>
                        <div
                          style={{ display: isOpen ? "none" : "block" }}
                          className="link_text"
                        >
                          {item.name}
                        </div>
                      </NavLink>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <main>
              <div className="main-content-dash">
                <div className="full-width">{children}</div>
              </div>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default SideBar;