import React, { Component, useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import CartTotalsCheckout from "../../components/CartTotalsCheckout";
import { connect } from "react-redux";
import BrandingHeader from "../../components/BrandingHeader";
import { Card, Row, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  addCustomerCard,
  addCustomerPhone,
  getPaymentType,
  postNewOrderOnline,
  postNewPayOrderOnline,
  updateCustomer,
  updateCustomerAddress,
  updateCustomerEmail,
  updateCustomerPhone,
  getCloverOrderType,
  getChargeId,
} from "../../utils/api";
import { Set, Reset } from "../../redux/actions/cartActions";
import { round } from "../../utils/functions";
import { FaUserEdit } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { Button } from "react-bootstrap";
import CreditCardInput from "react-credit-card-input";
import { getOrderTypes } from "../../utils/api";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentRequestButtonElement,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import Webcam from "react-webcam";
import vision from "react-cloud-vision-api";
import moment from "moment";
import { emailValidation } from "../../utils/validation";
import CartTableTotalsCheckout from "../../components/table/CartTableTotalsCheckout";
import MultiLingualContent from "../../context/multilingualContent";
import { translations } from "../../context/translations";

vision.init({ auth: "AIzaSyC9Lsf6CBqbjjOXuu63QLxA_SIrtj9cEKE" });

const Date = moment().startOf("month");
let videoConstraints = {
  width: 420,
  height: 420,
  // facingMode: { exact: "environment" },
  // facingMode: "user",
};
if (window.innerWidth > 767) {
  videoConstraints.facingMode = "user";
} else {
  videoConstraints.facingMode = { exact: "environment" };
}
// const stripePromise = loadStripe('pk_live_51IvaDEB8IOuWU0mtRX2RnIxZ9d8ZiYc5I2WbV69olt2u9QuXQBrr8c3iV4PzPJPmXJQW1NQgzz62Qs2ak7fpSo2d00T69wBGPG');
// const stripePromise = loadStripe(
// "pk_test_51IvaDEB8IOuWU0mtzQ6MfdLjqyD70SGdFfsMxJvTcrn1v3cMGjrGy79t5sY5XzLUqQtvRJqV6IgkgyP7BOFBsp8h00p1rMPUGP"
// );
const RequestPay = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [total, setTotal] = useState(
    parseFloat(
      (
        parseFloat(props.total) +
        parseFloat(props.tax) +
        parseFloat(props.tip)
      ).toFixed(2)
    )
  );
  const [modalShow, setModalShow] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [points, setPoints] = useState(0);
  const [name, setName] = useState("");
  const [res_id, setResId] = useState(props.res_id);
  let conv_fee = useRef(0);

  useEffect(() => {

    if (stripe) {
      let amount = parseInt(total * 100);
      if (props.convenience_fee.is_enabled) {
        let convenience_fee = props.total * (props.convenience_fee.percentage / 100);
        let limitfee = props.convenience_fee.limit;
        if (props.convenience_fee.limit && convenience_fee > limitfee) {
          convenience_fee = limitfee;
        }
        conv_fee.current = Math.round(convenience_fee * 100)
        amount = parseInt((
          parseFloat(props.total) +
          parseFloat(props.tax) +
          parseFloat(props.tip) +
          parseFloat(convenience_fee)
        ).toFixed(2) * 100)
      }
      const { checkoutData } = props;
      if (checkoutData.couponCode.percentage) {
        amount = Math.round(Number(((checkoutData.finalTotal + Number(checkoutData.convFee.toFixed(2))) * 100).toFixed(2)));
      }
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: `Total`,
          amount: amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        pr.on("paymentmethod", async function (e) {
          //2
          setName(e.paymentMethod.billing_details.name);
          const paymentDetails = {
            payment_method: e.paymentMethod.id,
            shipping: {
              name: e.paymentMethod.billing_details.name,
              phone: e.paymentMethod.billing_details.phone
                ? e.paymentMethod.billing_details.phone
                : "",
              address: {
                line1: `${e.paymentMethod.billing_details.address.line1
                  ? e.paymentMethod.billing_details.address.line1
                  : ""
                  }, ${e.paymentMethod.billing_details.address.line2
                    ? e.paymentMethod.billing_details.address.line2
                    : ""
                  }`,
                city: e.paymentMethod.billing_details.address.city
                  ? e.paymentMethod.billing_details.address.city
                  : "",
                country: e.paymentMethod.billing_details.address.country
                  ? e.paymentMethod.billing_details.address.country
                  : "",
                state: e.paymentMethod.billing_details.address.state
                  ? e.paymentMethod.billing_details.address.state
                  : "",
                postal_code: e.paymentMethod.billing_details.address.postal_code
                  ? e.paymentMethod.billing_details.address.postal_code
                  : "",
              },
            },
          };
          const customer_data = {
            'name': e.paymentMethod.billing_details.name
              ? e.paymentMethod.billing_details.name
              : "",
            'phone': e.paymentMethod.billing_details.phone
              ? e.paymentMethod.billing_details.phone
              : "",
            'email': e.paymentMethod.billing_details.email
              ? e.paymentMethod.billing_details.email
              : ""
          }
          let response = await fetch(
            `/api/order/${res_id}/create-payment-intent`,
            {
              method: "POST",
              body: JSON.stringify({
                paymentDetails,
                customer_data,
                amount: amount,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          response = await response.json()
          if (response.error) {
            console.log(response.error);
            e.complete("fail");
          } else {
            // e.complete('success');
            let stripe_customer_id = response.customer
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              response.client_secret
            );
            if (error) {
              console.log(error);
              return;
            }
            if (paymentIntent.status === "succeeded") {
              var data = {};
              data["stripe_customer_id"] = stripe_customer_id;
              data["name"] = e.paymentMethod.billing_details.name
                ? e.paymentMethod.billing_details.name
                : "";
              data["mobile"] = e.paymentMethod.billing_details.phone
                ? e.paymentMethod.billing_details.phone
                : "";
              data["email"] = e.paymentMethod.billing_details.email
                ? e.paymentMethod.billing_details.email
                : "";
              data["orderType"] = props.orderType;
              data["notes"] = props.notes ? props.notes : "";
              data["restaurant_id"] = res_id;
              data["conv_fee"] = conv_fee.current;
              data["convenience_fee"] = props.convenience_fee;
              data["chargeId"] = props.chargeId;
              data["customer_id"] = props.customer_id;
              data["address"] = `${e.paymentMethod.billing_details.address.line1
                ? e.paymentMethod.billing_details.address.line1
                : ""
                }, ${e.paymentMethod.billing_details.address.line2
                  ? e.paymentMethod.billing_details.address.line2
                  : ""
                }`;
              data["city"] = e.paymentMethod.billing_details.address.city
                ? e.paymentMethod.billing_details.address.city
                : "";
              data["country"] = e.paymentMethod.billing_details.address.country
                ? e.paymentMethod.billing_details.address.country
                : "";
              data["state"] = e.paymentMethod.billing_details.address.state
                ? e.paymentMethod.billing_details.address.state
                : "";
              data["zip"] = e.paymentMethod.billing_details.address.postal_code
                ? e.paymentMethod.billing_details.address.postal_code
                : "";
              if (e.walletName === "googlePay") {
                data["payment"] = "google";
                data["paymentId"] = props.paymentMethodIds["google"];
              } else if (e.walletName.toLowerCase().includes('apple')) {
                data["payment"] = "apple";
                data["paymentId"] = props.paymentMethodIds["apple"];
              } else {
                data["payment"] = "wallet";
                data["paymentId"] = props.paymentMethodIds["wallet"];
              }
              data["payment_id"] = e.paymentMethod.id;
              data["tableId"] = props.tableId;
              data["tableName"] = props.tableName;
              data["tableOrderId"] = props.tableOrderId;
              data["method"] = props.method;
              const response = await postNewPayOrderOnline(
                data,
                props.items,
                props.tip,
                props.tax,
                e.walletName,
                props.connect,
                checkoutData
              );
              if (!response) {
                e.complete("fail");
              } else {
                e.complete("success");
                localStorage.removeItem(res_id);
                if (props.tableId) {
                  // window.location.href = `/${res_id}/table/thankyou/${props.tableId}?order_id=${response.tableOrderId}`;
                  if (response.points) {
                    window.location.href = `/${res_id}/table/thankyou/${props.tableId}?order_id=${response.tableOrderId}&reward_points=${response.points}&language=${props.language}`;
                  } else {
                    window.location.href = `/${res_id}/table/thankyou/${props.tableId}?order_id=${response.tableOrderId}&language=${props.language}`;
                  }
                } else {
                  setOrderId(response.id);
                  setPoints(response.points);
                  setModalShow(true);
                }
              }
            } else {
              console.warn(
                `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
              );
            }
          }
        });
        // pr.on('token', function (e) { // 1
        // console.log(e)
        // });
        // pr.on('source', function (e) { //3
        // console.log(e)
        // });
        pr.on("cancel", function () {
          alert("Payment Cancelled");
        });
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);
  const handleConinue = () => {
    window.location.href = `/${props.res_id}`;
  };
  if (paymentRequest) {
    return (
      <>
        <PaymentRequestButtonElement
          className="payment-element"
          options={{ paymentRequest }}
        />{" "}
        <p className="payment-or">OR</p>
        <Modal
          show={modalShow}
          size="md"
          aria-labelledby="ordersucess"
          centered
        >
          <Modal.Body>
            <>
              <p
                style={{
                  textAlign: "center",
                  margin: "0px 0 6px 0",
                  paddingTop: " 12px",
                  fontSize: "18px",
                }}
              >
                {`Hi ${name}, Your Order ID`}
              </p>
              <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                {" "}
                <b>{orderId}</b>
              </p>
              <p style={{ textAlign: "center" }}>
                {/* {`Your order has been received. Your order will be ready for
 pick up in ${props.deliverytime} minutes.`} */}
                {props.res_id.toLowerCase() === "roadhouse-48".toLowerCase()
                  ? `Your order has been received. Your order will be ready within 20-30 minutes.`
                  : props.tableId
                    ? `Your order has been received`
                    : `Your order has been received. Your order will be ready for
 pick up in ${props.deliverytime} minutes.`}
              </p>
              {points ? (
                <p style={{ textAlign: "center" }}>
                  {`You have earned ${points} points as a reward for this order.`}
                </p>
              ) : (
                <></>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ marginBottom: "0" }} onClick={handleConinue}>
              Continue
            </Button>
            {/* <Link
 style={{ marginBottom: "0" }}
 to={`/${props.res_id}/feedback`}
 state={orderId}
 >
 Continue
 </Link> */}
          </Modal.Footer>
        </Modal>
        {/* <Modal
 show={this.state.cardDecline}
 size="lg"
 aria-labelledby="ordersucess"
 centered
 onHide={() => this.setState({ cardDecline: false })}
 >
 <Modal.Header>
 <div
 className="py-4"
 style={{
 textAlign: "center",
 // padding: "3em 0 0",
 fontWeight: "600",
 fontSize: "16px",
 margin: " 0 auto",
 }}
 >
 Your Card Declined, Order is not Placed. Please check your card
 details!
 </div>
 </Modal.Header>
 <Modal.Footer>
 <Button onClick={() => this.setState({ cardDecline: false })}>
 Close
 </Button>
 </Modal.Footer>
 </Modal> */}
      </>
    );
  }

  // Use a traditional checkout form.
  return <></>;
};

class CloverPayOnline extends Component {
  state = {
    customer_id: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    notes: "",
    cardNum: "",
    expiry: "",
    cvv: "",
    cardType: "",
    orderId: "",
    orderType: "",
    stripePromise: "",
    paymentMethod: {},
    modalShow: false,
    loader: false,
    type: "online",
    updated: true,
    isDisable: false,
    editPayment: false,
    edit: false,
    cardDecline: false,
    tableId: this.props.table.table_id,
    tableName: this.props.table.table_name,
    tableOrderId: this.props.table.table_order_id,
    method: this.props.method,
    camShow: false,
    webcamRef: "",
    canvasRef: "",
    reInitGooglePay: true,
    convenience_fee: {
      is_enabled: this.props.restaurant.convenience_fee,
      percentage: this.props.restaurant.convenience_rate,
      limit: this.props.restaurant.convenience_limit,
    }
  };
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    // console.log("convenience_fee", this.state.convenience_fee)
    this.setState({
      notes: this.props.cart.notes ? this.props.cart.notes : "",
    });

    try {
      if (this.props.stripe_pk) {
        const stripePromise = await loadStripe(this.props.stripe_pk);
        this.setState({
          stripePromise,
        });
      }
      const paymentTypes = await getPaymentType(
        window.location.pathname.split("/")[1]
      );
      var paymentType = paymentTypes.filter(
        (type) =>
          type.label.toLowerCase().includes("google") ||
          type.label.toLowerCase().includes("apple") || type.label.toLowerCase().includes("wallet")
      );
      var paymentId = {};
      paymentType.forEach((typ) => {
        if (typ.label.toLowerCase().includes("google")) {
          paymentId["google"] = typ.id;
        }
        if (typ.label.toLowerCase().includes("apple")) {
          paymentId["apple"] = typ.id;
        } if (typ.label.toLowerCase().includes("wallet")) {
          paymentId["wallet"] = typ.id;
        }
      });
      this.setState({
        paymentMethod: paymentId,
      });
      const orderTypes = await getOrderTypes(
        window.location.pathname.split("/")[1]
      );

      let orderType;
      let methodType;
      if (this.props.table.table_id) {
        methodType = "table_order";
      } else {
        if (this.state.method?.toLowerCase() === "togo") {
          methodType = "togo_order";
        } else {
          methodType = "online_order";
        }
      }
      const orderTypeId = await getCloverOrderType(
        this.props.restaurant.id,
        methodType
      );
      const charges = await getChargeId(this.props.restaurant.id);
      this.setState({ chargeId: charges.id });
      console.log(orderTypeId, orderTypeId["id"]);
      this.setState({
        orderType: orderTypeId["id"],
      });
    } catch (e) {
      console.log(e);
    }
    this.setState({
      customer_id: this.props.customer.id ? this.props.customer.id : "",
      restaurant_id: this.props.restaurant.id ? this.props.restaurant.id : "",
      firstName: this.props.customer.firstName
        ? this.props.customer.firstName
        : "",
      lastName: this.props.customer.lastName
        ? this.props.customer.lastName
        : "",
      email:
        this.props.customer.emailAddresses &&
          this.props.customer.emailAddresses.elements[0]
          ? this.props.customer.emailAddresses.elements[0].emailAddress
          : "",
      mobile: this.props.customer.phone ? this.props.customer.phone : "",
      cardNum:
        this.props.customer.cards && this.props.customer.cards.elements[0]
          ? `${this.props.customer.cards.elements[0].first6}*****${this.props.customer.cards.elements[0].last4}`
          : "",
      // expiry:
      // this.props.customer.cards && this.props.customer.cards.elements[0]
      // ? this.props.customer.cards.elements[0].expirationDate
      // .match(/.{1,2}/g)
      // .join("/")
      // : "",
      cardType:
        this.props.customer.cards && this.props.customer.cards.elements[0]
          ? this.props.customer.cards.elements[0].cardType
          : "",
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.target.style.boxShadow = "none";
  };
  handleEdit = (e) => {
    this.setState({
      updated: false,
      edit: true,
    });
  };
  handlePaymentEdit = (e) => {
    this.setState({
      updated: false,
      editPayment: true,
    });
  };
  updateUser = async (e) => {
    e.preventDefault();

    if (
      this.props.customer.phoneNumbers &&
      this.props.customer.phoneNumbers.elements[0]
    ) {
      await updateCustomerPhone(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.phoneNumbers.elements[0]["id"],
        this.state
      );
    } else {
      await addCustomerPhone(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.addresses.elements[0]["id"],
        this.state
      );
    }
    if (
      this.props.customer.emailAddresses &&
      this.props.customer.emailAddresses.elements[0]
    ) {
      await updateCustomerEmail(
        this.state.restaurant_id,
        this.state.customer_id,
        this.props.customer.emailAddresses.elements[0]["id"],
        this.state
      );
    }
    await updateCustomer(
      this.state.restaurant_id,
      this.state.customer_id,
      this.state
    );
    this.setState({
      edit: false,
      updated: true,
    });
  };
  handlePaymentUpdate = async (e) => {
    e.preventDefault();
    // if (this.props.customer.cards && this.props.customer.cards.elements[0]) {
    // await updateCustomerCard(this.state.restaurant_id, this.state.customer_id, this.props.customer.cards.elements[0]['id'], this.state.cardNum, this.state.expiry, this.state.cvv)
    // } else {
    await addCustomerCard(
      this.state.restaurant_id,
      this.state.customer_id,
      this.state.cardNum,
      this.state.expiry,
      this.state.cvv
    );
    // }
    this.setState({
      editPayment: false,
      updated: true,
    });
  };
  reInitGooglePay = () => {
    this.setState({ reInitGooglePay: false });
    setTimeout(() => this.setState({ reInitGooglePay: true }), 1000);
  };
  handleNewOrder = async (event) => {
    event.preventDefault();
    if (this.props.restaurant.integrate === "clover") {
      if (this.state.firstName.toLocaleLowerCase().includes("doordash")) {
        // const orderTypes = await getOrderTypes(this.props.restaurant.id);
        // var orderType = orderTypes.filter((type) =>
        // type.label.toLocaleLowerCase().includes("doordash")
        // );
        // orderType.length && this.setState({ orderType: orderType[0].id });
        const orderTypeId = await getCloverOrderType(
          this.props.restaurant.id,
          "doordash_order"
        );
        console.log(orderTypeId, orderTypeId["id"]);
        this.setState({ orderType: orderTypeId["id"] });
      }
    }
    let checkMailValid = emailValidation(this.state.email);
    if (
      this.props.customer.id ||
      (this.state.firstName.length > 0 &&
        this.state.email.length > 0 &&
        this.state.mobile.length === 12 &&
        checkMailValid &&
        this.state.cvv.length > 0 &&
        this.state.expiry.length > 0 &&
        this.state.cardNum.length > 0)
    ) {
      this.setState({ isDisable: true, loader: true });
      const res = await postNewOrderOnline(
        this.state,
        this.props.orderCart,
        parseInt(this.props.tips.replace(".", "")),
        this.props.tip,
        this.props.tax,
        this.props.customer.connect,
        this.props.checkout
      );
      if (res) {
        localStorage.removeItem(this.state.restaurant_id);
        if (this.state.tableId) {
          // window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}`;
          if (res.points) {
            window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&reward_points=${res.points}&language=${this.props.restaurant.language}`;
          } else {
            window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&language=${this.props.restaurant.language}`;
          }
        } else {
          this.setState({
            loader: false,
            modalShow: true,
            orderId: res.id,
            points: res.points,
          });
        }
      } else {
        this.setState({ isDisable: false, loader: false, cardDecline: true });
      }
    } else {
      if (this.state.firstName === "") {
        document.getElementById("firstName").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.getElementById("firstName").focus();
      }
      if (this.state.mobile.length < 12) {
        document.querySelector(".react-tel-input input").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.querySelector(".react-tel-input input").focus();
      }
      if (this.state.email === "" || !checkMailValid) {
        document.getElementById("email").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.getElementById("email").focus();
      }
      if (this.state.mobile === "") {
        document.querySelector(".react-tel-input input").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // document.querySelector(".react-tel-input input").focus();
      }
      // if (this.state.email === "") {
      // document.getElementById("email").style.boxShadow =
      // "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
      // document.getElementById("email").focus();
      // }
      if (
        this.state.cvv === "" ||
        this.state.expiry === "" ||
        this.state.cardNum === ""
      ) {
        document.getElementById("field-wrapper").style.boxShadow =
          "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
      }
    }
  };
  handleBlur = (e) => {
    // e.target.style.boxShadow = "none";
  };
  handleConinue = () => {
    window.location.href = `/${this.props.restaurant.id}`;
    this.props.Reset();
  };
  capture = async () => {
    const base = this.webcamRef.current.getScreenshot();
    const req = new vision.Request({
      image: new vision.Image({
        base64: base,
      }),
      features: [new vision.Feature("TEXT_DETECTION", 5)],
    });
    vision.annotate(req).then(
      (res) => {
        if (res.responses[0].fullTextAnnotation !== undefined) {
          let dataText = JSON.stringify(
            res.responses[0]?.fullTextAnnotation?.text
          );
          dataText = JSON.parse(dataText);
          let splitNum = dataText.split(/\r?\n/);
          console.log(splitNum);
          const cardNumber1 = splitNum
            .filter((sd) => sd.length === 19)
            .map((sd) => sd.replace(/[^0-9 ]/g, ""));
          const cardNumber = cardNumber1.filter((sd) => sd.length === 19);
          console.log("15151", cardNumber);
          if (cardNumber.length) {
            // this.setState({ cardNum: cardNumber[0] });
            // this.setState({ camShow: false });
            const cardExp = splitNum.filter((sd) => sd.includes("/"));
            console.log(22, cardExp);
            if (/\s/.test(cardExp) === true) {
              const e = cardExp.filter((sd) => sd.includes(" "));
              if (/\s/.test(e) === true) {
                let Exp = e.join(" ").split(" ");
                console.log(Exp);
                const cardExpFinal = Exp.filter(
                  (sd) => sd.includes("/") && sd.length === 5
                );
                console.log("check", cardExpFinal);
                const cardExpFinal1 = cardExpFinal.filter(
                  (sd) => moment(sd, "MM/YY").isSameOrAfter(Date) === true
                );
                console.log("final", cardExpFinal1);
                if (cardExpFinal1.length) {
                  this.setState({ cardNum: cardNumber[0] });
                  this.setState({ expiry: cardExpFinal1[0] });
                  this.setState({ camShow: false });
                } else {
                  this.setState({ expShow: true });
                }
              } else {
                const cardExpFinal = e.filter(
                  (sd) => sd.includes("/") && sd.length === 5
                );
                console.log("check", cardExpFinal);
                const cardExpFinal1 = cardExpFinal.filter(
                  (sd) => moment(sd, "MM/YY").isSameOrAfter(Date) === true
                );
                console.log("final", cardExpFinal1);
                if (cardExpFinal1.length) {
                  this.setState({ cardNum: cardNumber[0] });
                  this.setState({ expiry: cardExpFinal1[0] });
                  this.setState({ camShow: false });
                } else {
                  this.setState({ expShow: true });
                }
              }
            } else {
              if (cardExp.length) {
                const cardExpFinal = cardExp.filter(
                  (sd) => sd.includes("/") && sd.length === 5
                );
                console.log("check", cardExpFinal);
                const cardExpFinal1 = cardExpFinal.filter(
                  (sd) => moment(sd, "MM/YY").isSameOrAfter(Date) === true
                );
                console.log("final", cardExpFinal1);
                if (cardExpFinal1.length) {
                  this.setState({ cardNum: cardNumber[0] });
                  this.setState({ expiry: cardExpFinal1[0] });
                  this.setState({ camShow: false });
                } else {
                  this.setState({ expShow: true });
                }
              }
            }
          } else {
            this.setState({ CardInv: true });
          }
        }
      },
      (e) => {
        console.log("Error: ", e);
      }
    );
  };
  openCam = () => {
    this.setState({ camShow: true });
  };

  translatedWord = (word) => translations[this.props.restaurant.language] ? translations[this.props.restaurant.language][word] : translations["en"][word]


  render() {
    const checkout = (
      <Row>
        {/* {console.log(this.state)} */}
        <Col xs={12} lg={6}>
          <div className="payment-button">
            {this.state.reInitGooglePay &&
              this.state.orderType &&
              this.state.stripePromise ? (
              <div style={{ width: "100%" }}>
                <Elements stripe={this.state.stripePromise}>
                  <RequestPay
                    chargeId={this.state.chargeId}
                    convenience_fee={this.state.convenience_fee}
                    notes={this.state.notes}
                    customer_id={this.state.customer_id}
                    paymentMethodIds={this.state.paymentMethod}
                    total={parseFloat(this.props.totalPrice).toFixed(2)}
                    orderType={this.state.orderType}
                    name={this.props.name}
                    res_id={this.props.restaurant.id}
                    items={this.props.orderCart}
                    tip={this.props.tips}
                    checkoutData={this.props.checkout}
                    tax={
                      parseFloat(this.props.totalPrice) *
                      parseFloat(this.props.tax)
                    }
                    deliverytime={this.props.restaurant.deliverytime}
                    connect={this.props.customer.connect}
                    tableId={this.props.table.table_id}
                    tableName={this.props.table.table_name}
                    tableOrderId={this.props.table.table_order_id}
                    method={this.state.method}
                    language={this.props.restaurant.language}
                  />
                </Elements>
              </div>
            ) : (
              <></>
            )}
          </div>

          <Card className="mt-3">
            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span><MultiLingualContent contentID="Billing Details" /></span>
              {this.state.edit ? (
                <></>
              ) : this.state.customer_id ? (
                <>
                  <FaUserEdit
                    className="mr-1"
                    size="1.5em"
                    onClick={this.handleEdit}
                  />
                </>
              ) : (
                <></>
              )}
            </Card.Header>
            {!this.state.edit && this.props.customer.customerId ? (
              <Card.Body>
                <Card.Text>
                  {this.state.firstName} {this.state.lastName}
                </Card.Text>
                <Card.Text>{this.state.email}</Card.Text>
                <Card.Text>{this.state.mobile}</Card.Text>
              </Card.Body>
            ) : (
              <Form
                // onSubmit={this.handleSubmit}
                className="p-4"
              >
                <Form.Row>
                  <Form.Group as={Col} controlId="firstName">
                    <Form.Control
                      onChange={this.handleChange}
                      onFocus={this.handleBlur}
                      value={this.state.firstName}
                      placeholder="Name"
                      required
                    />
                  </Form.Group>

                  {/* <Form.Group as={Col} controlId="lastName">
 <Form.Control
 onChange={this.handleChange}
 onFocus={this.handleBlur}
 placeholder="Last Name"
 value={this.state.lastName}
 required
 />
 </Form.Group> */}
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Control
                      onFocus={this.handleBlur}
                      onChange={this.handleChange}
                      value={this.state.email}
                      type="email"
                      placeholder="Email"
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="mobile">
                    {/* <Form.Control
 onChange={this.handleChange}
 value={this.state.mobile}
 type="text"
 placeholder="Phone"
 required
 /> */}
                    {(!this.state.mobile.replace("+", "").length ||
                      !this.state.mobile.replace("+1", "").length) && (
                        <span
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            // textAlign: "center",
                            fontWeight: "500",
                            lineHeight: "15px",
                            display: "inline-block",
                          }}
                        >
                          <MultiLingualContent contentID="Please enter the phone number to receive the order confirmation text." />
                        </span>
                      )}
                    <PhoneInput
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      disableDropdown={true}
                      country={"us"}
                      onFocus={this.handleBlur}
                      placeholder="Phone"
                      inputclassName="phoneinput"
                      value={this.state.mobile}
                      onChange={(mobile) => {
                        this.setState({ mobile: `+${mobile}` });
                      }}
                    />
                  </Form.Group>
                </Form.Row>

                {this.state.edit ? (
                  <Button
                    block
                    variant="warning"
                    type="submit"
                    disabled={this.validateFormAddress()}
                    onClick={this.updateUser}
                  >
                    Update
                  </Button>
                ) : (
                  <></>
                )}
              </Form>
            )}
          </Card>
          {/* scan card */}
          <Modal
            show={this.state.camShow}
            onHide={(e) => this.setState({ camShow: false })}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {/* <ModalDialog size={true}> */}
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <MultiLingualContent contentID="Scan credit card" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div
                  style={{
                    textAlign: "center",
                    // paddingTop: " 12px",
                  }}
                >
                  <Webcam
                    audio={false}
                    width={360}
                    height={240}
                    ref={this.webcamRef}
                    imageSmoothing={true}
                    screenshotQuality={1}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                </div>
              </>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "center" }}>
              <Button
                style={{ textAlign: "center", marginBottom: "0" }}
                onClick={this.capture}
              >
                Capture
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.expShow}
            onHide={(e) => this.setState({ expShow: false })}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="scan-popup"
          >
            <Modal.Header style={{ border: "0" }} closeButton></Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", fontSize: "20px", padding: "0" }}
            >
              Your card has been expired
            </Modal.Body>
            <Modal.Footer style={{ border: "0", justifyContent: "center" }}>
              <Button
                style={{ textAlign: "center", marginBottom: "0" }}
                onClick={() => this.setState({ expShow: false })}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.CardInv}
            onHide={(e) => this.setState({ CardInv: false })}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="scan-popup"
          >
            <Modal.Header style={{ border: "0" }} closeButton></Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", fontSize: "20px", padding: "0" }}
            >
              Take a clear picture of your card
            </Modal.Body>
            <Modal.Footer style={{ border: "0", justifyContent: "center" }}>
              <Button
                style={{ textAlign: "center", marginBottom: "0" }}
                onClick={() => this.setState({ CardInv: false })}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.modalShow}
            size="md"
            aria-labelledby="ordersucess"
            centered
          >
            <Modal.Body>
              <>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0px 0 6px 0",
                    paddingTop: " 12px",
                    fontSize: "18px",
                  }}
                >
                  Your Order ID
                </p>
                <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                  {" "}
                  <b>{this.state.orderId}</b>
                </p>
                <p style={{ textAlign: "center" }}>
                  {this.props.restaurant.id.toLowerCase() ===
                    "roadhouse-48".toLowerCase()
                    ? `Your order has been received. Your order will be ready within 20-30 minutes.`
                    : this.state.orderId
                      ? `Your order has been received`
                      : `Your order has been received. Your order will be ready for
 pick up in ${this.props.restaurant.deliverytime} minutes.`}
                  {/* {`Your order has been received. Your order will be ready for
 pick up in ${this.props.restaurant.deliverytime} minutes.`} */}
                </p>
                {this.state.points ? (
                  <p style={{ textAlign: "center" }}>
                    {`You have earned ${this.state.points} points as a reward for this order.`}
                  </p>
                ) : (
                  <></>
                )}
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ marginBottom: "0" }}
                onClick={this.handleConinue}
              >
                Continue
              </Button>
              {/* <Link
 style={{ marginBottom: "0" }}
 to={`/${this.props.restaurant.id}/feedback`}
 state={this.state.orderId}
 >
 Continue
 </Link> */}
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.cardDecline}
            size="lg"
            aria-labelledby="ordersucess"
            centered
            onHide={() => this.setState({ cardDecline: false })}
          >
            <Modal.Header>
              <div
                className="py-4"
                style={{
                  textAlign: "center",
                  // padding: "3em 0 0",
                  fontWeight: "600",
                  fontSize: "16px",
                  margin: " 0 auto",
                }}
              >
                Your Card Declined, Order is not Placed. Please check your card
                details!
              </div>
            </Modal.Header>
            <Modal.Footer>
              <Button onClick={() => this.setState({ cardDecline: false })}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Card className="m">
            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span><MultiLingualContent contentID="Enter card details" /></span>
              <Button variant="warning" onClick={this.openCam} className="mt-4">
                <MultiLingualContent contentID="Scan credit card" />
              </Button>
              {this.state.editPayment ? (
                <></>
              ) : this.state.customer_id ? (
                <>
                  {" "}
                  <FaUserEdit
                    className="mr-1"
                    size="1.5em"
                    onClick={this.handlePaymentEdit}
                  />
                </>
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body style={{ padding: "0" }}>
              {!this.state.editPayment &&
                Object.keys(this.props.customer).length &&
                this.props.customer.cards &&
                this.props.customer.cards.elements.length ? (
                <Card.Body>
                  <Card.Text>{this.state.cardNum}</Card.Text>
                  <Card.Text>Expiry : {this.state.expiry}</Card.Text>
                </Card.Body>
              ) : (
                <Card.Body className="card-btn">
                  <Form
                    className="payment"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CreditCardInput
                      cardNumberInputProps={{
                        value: this.state.cardNum,
                        onBlur: (e) =>
                        (document.getElementById(
                          "field-wrapper"
                        ).style.boxShadow = "none"),
                        onChange: (e) => {
                          this.setState({
                            cardNum: e.target.value,
                          });
                        },
                      }}
                      cardExpiryInputProps={{
                        value: this.state.expiry,
                        onBlur: (e) =>
                        (document.getElementById(
                          "field-wrapper"
                        ).style.boxShadow = "none"),
                        onChange: (e) => {
                          this.setState({
                            expiry: e.target.value,
                          });
                        },
                      }}
                      cardCVCInputProps={{
                        value: this.state.cvv,
                        onBlur: (e) =>
                        (document.getElementById(
                          "field-wrapper"
                        ).style.boxShadow = "none"),
                        onChange: (e) => {
                          this.setState({
                            cvv: e.target.value,
                          });
                        },
                      }}
                      fieldClassName="card-input form-control"
                    />
                    {/* {this.state.customer_id ? (
 <span
 style={{
 marginLeft: "15px",
 border: "1px solid #ced4da",
 borderRadius: "3px",
 }}
 >
 <MdChevronRight
 size="2em"
 onClick={this.handlePaymentUpdate}
 />
 </span>
 ) : (
 <></>
 )} */}
                  </Form>
                </Card.Body>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={5}>
          {this.state.tableId ? (
            <CartTableTotalsCheckout
              handleNewOrder={this.handleNewOrder}
              disabled={this.state.isDisable}
              // disabled={!this.state.updated}
              buttonText={this.translatedWord("Place Order")}
              payment={true}
              total={this.props.total}
              totalPrice={round(this.props.totalPrice)}
              reInitGooglePay={this.reInitGooglePay}
            />
          ) : (
            <CartTotalsCheckout
              handleNewOrder={this.handleNewOrder}
              disabled={this.state.isDisable}
              // disabled={!this.state.updated}
              buttonText={this.translatedWord("Place Order")}
              payment={true}
              total={this.props.total}
              totalPrice={round(this.props.totalPrice)}
            />
          )}
        </Col>
      </Row>
    );
    // eslint-disable-next-line
    const needToLogin = (
      <div className="text-center">
        <p>You must login or signup to checkout</p>
        <Link to={`/${this.props.restaurant.id}/login-signup`}>
          <Button variant="warning" className="mt-4">
            Login/Signup
          </Button>
        </Link>
      </div>
    );
    return (
      <>
        {/* <BrandingHeader />{" "} */}
        <div className="cart-header">
          <p>{this.props.restaurant.restaurant.name}</p>
          <button
            onClick={() => window.history.back()}
            style={{
              padding: "5px 10px",
              background: "none",
              border: "0",
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            <FontAwesomeIcon icon={faClose} size={"1x"} />
          </button>
        </div>
        <React.Fragment>
          {/* <CustomParallax title="Checkout" img={home_top} height={300} /> */}
          <Container
            className="my-auto Checkout"
            style={{ opacity: this.state.loader ? "0.4" : "1" }}
          >
            {checkout}
            {/* {this.props.loggedIn ? checkout : needToLogin} */}
          </Container>
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    total: state.cart.total,
    totalPrice: state.cart.totalPrice,
    tax: state.restaurant.totalTax,
    tip: state.cart.tip,
    orderCart: state.cart.orderCart,
    tips: state.cart.tips,
    restaurant: state.restaurant,
    name: state.restaurant.restaurant.name,
    stripe_pk: state.restaurant.restaurant.stripe_pk,
    customer: state.customer,
    tax: state.restaurant.totalTax,
    cart: state.cart,
    loggedIn: state.customer.isLoggedIn,
    checkout: state.checkout,
    table: state.table,
    method: state.restaurant.method,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Set: (title, num) => {
      dispatch(Set(title, num));
    },
    Reset: (title, num) => {
      dispatch(Reset(title, num));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CloverPayOnline);