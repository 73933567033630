import React, { Component } from "react";
import brandLogo from "../../assets/PhonedashLogo.jpg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import * as AiIcons from "react-icons/ai";
import { FaEllipsisV } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Navbar.css";
function Checkbox(props) {
  const [checked, setChecked] = React.useState(true);
  React.useEffect(() => {
    console.log(props);
    stateUpdate();
  }, []);
  async function stateUpdate() {
    let ajaxCallData = await fetch(
      `/api/v1/merchant/get/openhour/${props.restaurant.id}/${props.restaurant.integrate}`
    );
    ajaxCallData = await ajaxCallData.json();
    let { restaurant_open } = ajaxCallData.data;
    setChecked(Boolean(restaurant_open));
  }
  async function updateRestaurantOpen() {
    let ajaxCallData = await fetch(
      `/api/v1/merchant/post/openhour/${props.restaurant.id}/${props.restaurant.integrate}`
    );
    stateUpdate();
  }
  return (
    <label className="toggle-switch" style={{ margin: "0 0 0 10px" }}>
      <input
        type="checkbox"
        checked={checked}
        onChange={updateRestaurantOpen}
      // onChange={() => setChecked(!checked)}
      />
      <span className="switch" />
    </label>
  );
  {
    /* <label className="toggle-switch">
                        <input
                          type="checkbox"
                          // onChange={async (e) => availabilityTable(e.target.id)}
                          // id={row.id}
                          defaultChecked={this.state.restaurant_open}
                          // checked
                        />
                        <span className="switch" />
                      </label> */
  }
}
class Navbar extends Component {
  state = {
    showDetails: false,
    showmobDetails: false,
    restaurantId: this.props.restaurant.id,
    integrate: this.props.restaurant.integrate,
    restaurant_open: false,
  };
  // async componentDidMount() {
  //   this.updateForceStop();
  // }

  /* accountChange =()=>{
  this.setState({
    showDetails: true
  })
 } */
  handleChange = (e) => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    var option = optionElement.getAttribute('integrate');
    window.localStorage.setItem("simmer", JSON.stringify({ "cached": true, restaurant: e.target.value, integrate: option }));
    window.location.reload(false);
  };
  render() {
    return (
      <>
        {window.innerWidth > 767 ? (
          <>
            <div>
              <div className="dash-head">
                <Container>
                  <Row className="justify-center align-items-center">
                    {/* <Col md={2} lg={2} className="colum">
                      
                    </Col> */}
                    <Col
                      lg={7}
                      className="colum-account d-flex align-items-center"
                    >
                      {
                        this.props.customer.locations.length === 1 ? <div>{this.props.restaurant.restaurant.name}</div> :
                          <div className="table-select" style={{ position: 'initial' }}>
                            <select
                              name="selectedTable"
                              id="selectedTable"
                              onChange={this.handleChange}
                              defaultValue={window.localStorage.getItem("simmer") ? JSON.parse(window.localStorage.getItem("simmer"))['restaurant'] : ""}
                            >
                              {this.props.customer.locations.map((location, i) => (
                                <option
                                  value={location.restaurantId}
                                  integrate={location.integrate}
                                >
                                  {location.restaurant}
                                </option>
                              ))}
                            </select>
                          </div>
                      }

                    </Col>
                    <Col lg={5} className="colum">
                      <div className="menu-bar-right">
                        <a href="tel:4085164542" className="menu-bars">
                          <span style={{ fontSize: "16px", color: "#000" }}>
                            <i className="fa fa-info-circle"></i> Help
                          </span>
                        </a>
                        <Link
                          to="#"
                          className="menu-bars"
                          onClick={() => {
                            this.setState({
                              showDetails: !this.state.showDetails,
                            });
                          }}
                        >
                          <AiIcons.AiFillSetting size={22} color="#000" />
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#000",
                              padding: "0 5px",
                            }}
                          >
                            Account
                          </span>
                        </Link>
                        {this.state.showDetails ? (
                          <>
                            <div className="accountdetails">
                              <Link
                                onClick={() =>
                                  this.setState({
                                    showDetails: false,
                                  })
                                }
                                to="/merchant/account-settings"
                              >
                                {" "}
                                Account & Setup
                              </Link>

                              <p>
                                Profile <br />
                                <span>{this.props.customer.userMail}</span>
                              </p>
                              <Link to="/merchant/logout">Log out</Link>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="dash-head">
                <Container>
                  <div className="mobile-nav">
                    <div className="colum-account d-flex align-items-center justify-content-end">
                    {
                        this.props.customer.locations.length === 1 ? <div>{this.props.restaurant.restaurant.name}</div> :
                          <div className="table-select" style={{ position: 'initial' }}>
                            <select
                              name="selectedTable"
                              id="selectedTable"
                              onChange={this.handleChange}
                              defaultValue={window.localStorage.getItem("simmer") ? JSON.parse(window.localStorage.getItem("simmer"))['restaurant'] : ""}
                            >
                              {this.props.customer.locations.map((location, i) => (
                                <option
                                  value={location.restaurantId}
                                  integrate={location.integrate}
                                >
                                  {location.restaurant}
                                </option>
                              ))}
                            </select>
                          </div>
                      }
                      {/* <div>{this.props.restaurant.restaurant.name}</div> */}
                      {/* <Checkbox {...this.props} /> */}
                      <FaEllipsisV
                        onClick={() => {
                          this.setState({
                            showmobDetails: !this.state.showmobDetails,
                          });
                        }}
                      />
                    </div>
                    <div className="colum">
                      {/* <i
                        onClick={() => {
                          this.setState({
                            showmobDetails: !this.state.showmobDetails,
                          });
                        }}
                        className="fa fa-ellipsis-v"
                      ></i> */}

                      {this.state.showmobDetails ? (
                        <>
                          <div
                            className="menu-bar-right"
                            style={{
                              height: this.state.showmobDetails ? "auto" : "0",
                            }}
                          >
                            <a href="tel:4085164542" className="menu-bars">
                              <span style={{ fontSize: "16px", color: "#000" }}>
                                <i className="fa fa-info-circle"></i> Help
                              </span>
                            </a>
                            <Link
                              to="#"
                              className="menu-bars"
                              onClick={() => {
                                this.setState({
                                  showmobDetails: true,
                                  showDetails: !this.state.showDetails,
                                });
                              }}
                            >
                              <AiIcons.AiFillSetting size={22} color="#000" />
                              <span
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                  padding: "0 5px",
                                }}
                              >
                                Account
                              </span>
                            </Link>
                            {this.state.showDetails ? (
                              <>
                                <div className="accountdetails">
                                  <Link
                                    onClick={() =>
                                      this.setState({
                                        showDetails: false,
                                        showmobDetails: false,
                                      })
                                    }
                                    to="/merchant/account-settings"
                                  >
                                    {" "}
                                    Account & Setup
                                  </Link>
                                  <p>
                                    Profile <br />
                                    <span>{this.props.customer.userMail}</span>
                                  </p>
                                  <Link to="/merchant/logout">Log out</Link>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    restaurant_id: state.customer.resId,
    customer: state.customer,
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps)(Navbar);

