import React, { Component, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import CartTotalsCheckout from "../../components/CartTotalsCheckout";
import CartTableCheckout from "../../components/table/CartTableCheckout";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Form from "react-bootstrap/Form";
import BrandingHeader from "../../components/BrandingHeader";
import {
  addCustomerPhone,
  postNewOrderCash,
  postNewOrderCashCustom,
  updateCustomer,
  updateCustomerEmail,
  updateCustomerPhone,
  getChargeId,
  getTable,
} from "../../utils/api";
import { Set, Reset } from "../../redux/actions/cartActions";
import { round } from "../../utils/functions";
import { FaUserEdit } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { Link, useLocation, Navigate } from "react-router-dom";
import { getOrderTypes, getCloverOrderType } from "../../utils/api";
import { toast } from "react-toastify";
import { async } from "q";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import MultiLingualContent from '../../context/multilingualContent';
import { translations } from "../../context/translations";

class CloverPayCashCC extends Component {
  state = {
    customer_id: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    country: "US",
    name: "",
    zip: "",
    email: "",
    mobile: "",
    type: "cash",
    notes: "",
    tableOrder: false,
    table: 0,
    orderType: "",
    orderId: "",
    chargeId: "",
    updated: true,
    modalShow: false,
    loader: false,
    edit: false,
    isDisable: false,
    feedback: false,
    discountPrecent: 0,
    tableId: this.props.table.table_id,
    tableName: this.props.table.table_name,
    tableOrderId: this.props.table.table_order_id,
  };
  async componentDidMount() {
    const response = await fetch(
      `/api/v1/merchant/${this.props.restaurant.id}/${this.props.restaurant.integrate}/discount`
    );
    const json = await response.json();

    if (json.isDiscount) {
      this.setState({ discountPrecent: json.percentage });
    }
    this.setState({
      notes: this.props.cart.notes ? this.props.cart.notes : "",
    });
    const tableId = window.location.pathname.split("/")[
      window.location.pathname.split("/").indexOf("table") + 1
    ]
      ? window.location.pathname.split("/")[
          window.location.pathname.split("/").indexOf("table") + 1
        ]
      : 0;
    if (tableId) {
      this.setState({
        tableOrder: true,
        table: tableId,
      });
    }
    window.scrollTo(0, 0);
    if (this.props.restaurant.integrate === "clover") {
      const orderTypes = await getOrderTypes(
        window.location.pathname.split("/")[1]
      );
      let orderType = orderTypes.filter((type) =>
        type.label?.toLocaleLowerCase().includes("table")
      );

      if (this.state.tableId) {
        // if (this.props.restaurant.id.toLowerCase().includes("biryani-bowl")) {
        const orderTypeId = await getCloverOrderType(
          this.props.restaurant.id,
          "table_order"
        );
        orderType = orderTypeId["id"];
        this.setState({
          orderType,
        });
        // } else {
        //   const orderTypeId = await getCloverOrderType(
        //     this.props.restaurant.id,
        //     "Table Order",

        //   );
        //   orderType = orderTypeId["id"];
        //   this.setState({
        //     orderType,
        //   });
        // }
      } else {
        const orderTypeId = await getCloverOrderType(
          this.props.restaurant.id,
          "cash_order"
        );
        orderType = orderTypeId["id"];
        this.setState({
          orderType,
        });
        // if (this.props.restaurant.id.toLowerCase().includes("biryani-bowl")) {
        //   orderType = orderTypes.filter((type) =>
        //     type.label?.includes("Online Order Pickup")
        //   );
        // } else {
        //   orderType = orderTypes.filter((type) =>
        //     type.label?.includes("Curbside")
        //   );
        // }
        // orderType = orderType[0]["id"];
        // this.setState({
        //   orderType,
        // });
      }

      const charges = await getChargeId(this.props.restaurant.id);
      this.setState({ chargeId: charges.id });
    }
    console.log(this.props.customer);
    this.setState({
      customer_id: this.props.customer.id ? this.props.customer.id : "",
      restaurant_id: this.props.restaurant.id ? this.props.restaurant.id : "",
      firstName: this.props.customer.firstName
        ? this.props.customer.firstName
        : "",
      lastName: this.props.customer.lastName
        ? this.props.customer.lastName
        : "",
      email:
        this.props.customer.emailAddresses &&
        this.props.customer.emailAddresses.elements[0]
          ? this.props.customer.emailAddresses.elements[0].emailAddress
          : "",
      mobile: this.props.customer.phone ? this.props.customer.phone : "",
    });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.target.style.boxShadow = "none";
  };
  handleEdit = (e) => {
    this.setState({
      updated: false,
      edit: true,
    });
  };
  updateUser = async (e) => {
    e.preventDefault();
    if (
      this.props.customer.phoneNumbers &&
      this.props.customer.phoneNumbers.elements[0]
    ) {
      await updateCustomerPhone(
        this.props.restaurant.id,
        this.state.customer_id,
        this.props.customer.phoneNumbers.elements[0]["id"],
        this.state
      );
    }
    if (
      this.props.customer.emailAddresses &&
      this.props.customer.emailAddresses.elements[0]
    ) {
      await updateCustomerEmail(
        this.props.restaurant.id,
        this.state.customer_id,
        this.props.customer.emailAddresses.elements[0]["id"],
        this.state
      );
    }
    await updateCustomer(
      this.props.restaurant.id,
      this.state.customer_id,
      this.state
    );
    this.setState({
      edit: false,
      updated: true,
    });
  };
  validateFormAddress() {
    return !(this.state.firstName.length > 0 && this.state.mobile.length > 0);
  }
  translatedWord = (word) => translations[this.props.restaurant.language] ? translations[this.props.restaurant.language][word] : translations["en"][word]

  handleNewOrder = async (event) => {
    event.preventDefault();
    if (this.props.restaurant.integrate === "clover") {
      if (this.state.firstName.toLocaleLowerCase().includes("doordash")) {
        const orderTypes = await getOrderTypes(this.props.restaurant.id);
        var orderType = orderTypes.filter((type) =>
          type.label.toLocaleLowerCase().includes("doordash")
        );
        orderType.length && this.setState({ orderType: orderType[0].id });
      }
    }
    if (
      ["vishnuji-ki-rasoi", "vishnuji-ki-rasoi-texas"].includes(
        this.props.restaurant.id.toLowerCase()
      )
      // this.props.restaurant.id === "Vishnuji-Ki-Rasoi" ||
      // this.props.restaurant.id === "Vishnuji-Ki-Rasoi-Texas"
    ) {
      if (this.state.mobile.length === 12 || this.state.firstName.length > 0) {
        this.setState({ isDisable: true, loader: true });
        var res = {};
        console.log("old");
        res = await postNewOrderCash(
          this.state,
          this.props.orderCart,
          this.props.cart.tip,
          this.state.chargeId,
          this.props.tax,
          this.props.customer.connect,
          this.props.checkout
        );
        // res = await postNewOrderCash(
        //   this.state,
        //   this.props.orderCart,
        //   this.props.cart.tip,
        //   this.state.chargeId,
        //   this.props.tax,
        //   this.props.customer.connect,
        //   this.props.restaurant.convenience_fee,
        //   this.props.restaurant.convenience_rate,
        //   this.props.restaurant.convenience_limit,
        //   this.props.customer.connect,
        //   this.state.discountPrecent,
        //   this.props.tip
        // );

        if (Object.keys(res).length) {
          localStorage.removeItem(this.props.restaurant.id);
          // if (this.state.paymentModeOnline) {
          //   // <Navigate
          //   //   to={`/${this.props.restaurant.id}/table/payment/${this.props.table.table_id}`}
          //   // />;
          //   window.location.href = `/${this.props.restaurant.id}/table/payment/${this.props.table.table_id}`;
          //   // this.props.history.push(
          //   //   `/${this.props.restaurant.id}/table/payment/${this.props.table.table_id}`
          //   // );
          // }
          if (this.state.tableId) {
            // window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}`;
            if (res.points) {
              window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&reward_points=${res.points}&language=${this.props.restaurant.language}`;
            } else {
              window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&language=${this.props.restaurant.language}`;
            }
          } else {
            this.setState({ orderId: res.id, points: res.points });
            this.setState({
              loader: false,
              modalShow: true,
            });
          }
        }
      } else {
        if (this.state.firstName === "") {
          document.getElementById("name").style.boxShadow =
            "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
          document.getElementById("firstName").focus();
        }
        // if (this.state.mobile === "") {
        //   document.querySelector(".react-tel-input input").style.boxShadow =
        //     "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        // }
      }
    } else {
      if (this.state.mobile.length === 12 && this.state.firstName.length > 0) {
        this.setState({ isDisable: true, loader: true });

        const res = await postNewOrderCash(
          this.state,
          this.props.orderCart,
          this.props.cart.tip,
          this.state.chargeId,
          this.props.tax,
          this.props.customer.connect,
          this.props.checkout
        );

        if (res && Object.keys(res).length) {
          localStorage.removeItem(this.props.restaurant.id);
          if (this.state.tableId) {
            if (res.points) {
              window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&reward_points=${res.points}&language=${this.props.restaurant.language}`;
            } else {
              window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}&language=${this.props.restaurant.language}`;
            }
            // window.location.href = `/${this.props.restaurant.id}/table/thankyou/${this.props.table.table_id}?order_id=${res.tableOrderId}`;
          } else {
            this.setState({
              loader: false,
              modalShow: true,
              orderId: res.id,
              points: res.points,
            });
          }
        }
      } else {
        if (this.state.firstName === "") {
          document.getElementById("firstName").style.boxShadow =
            "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
          document.getElementById("firstName").focus();
        }
        if (this.state.mobile.length < 12) {
          document.querySelector(".react-tel-input input").style.boxShadow =
            "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
        }
      }
    }
  };
  tableChange = (e) => {
    const list = document.getElementById("tipslist");
    var items = list.getElementsByTagName("li");
    for (var i = 0; i < items.length; ++i) {
      if (e.target.getAttribute("id") === "available") {
        if (items[i].getAttribute("name") === e.target.getAttribute("name")) {
          items[i].classList.add("active");
        } else {
          items[i].classList.remove("active");
        }
      } else {
        return;
      }
    }
    if (
      e.target.getAttribute("name") !== "custom" &&
      e.target.getAttribute("name") != "0"
    ) {
      console.log(parseInt(e.target.getAttribute("name")));
      this.setState({
        table: parseInt(e.target.getAttribute("name")),
      });
    }
  };
  handleBlur = (e) => {
    // e.target.style.boxShadow = "none";
  };
  handleConinue = () => {
    window.location.href = window.location.href.includes("table")
      ? `${window.location.href.split("table")[0]}table`
      : `/${this.props.restaurant.id}`;
    this.props.Reset();
  };
  render() {
    const checkout = (
      <Row>
        <Col xs={12} lg={6}>
          {console.log(this.state)}
          <Card className="mt-3 mb-5">
            <Card.Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "#000",
                border: "0",
                fontWeight: "700",
                padding: "0",
              }}
            >
              <span><MultiLingualContent contentID="Billing Details" /></span>

              {this.state.edit ? (
                <></>
              ) : (
                <FaUserEdit
                  className="mr-1"
                  size="1.5em"
                  onClick={this.handleEdit}
                />
              )}
            </Card.Header>
            {!this.state.edit && this.state.customer_id ? (
              // {Object.keys(this.props.customer).length ? (
              <Card.Body>
                <Card.Text>
                  {this.state.firstName} {this.state.lastName}
                </Card.Text>
                <Card.Text>{this.state.email}</Card.Text>
                <Card.Text>{this.state.mobile}</Card.Text>
                {/* <Form.Row>
                  <Form.Group as={Col} controlId="notes">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      onChange={this.handleChange}
                      placeholder="Notes"
                      required
                      value={this.state.notes}
                    />
                  </Form.Group>
                </Form.Row> */}
              </Card.Body>
            ) : (
              <Form
                // onSubmit={this.handleSubmit}
                className="p-4"
              >
                <Form.Row>
                  <Form.Group as={Col} controlId="firstName">
                    <p
                      style={{
                        fontSize: "15px",
                        marginBottom: "2px",
                      }}
                    >
                      <span style={{ color: "red" }}>* </span> Name{" "}
                      {/* <span style={{ color: "red", fontStyle: "italic" }}>
                        Required{" "}
                      </span> */}
                    </p>
                    <Form.Control
                      onChange={this.handleChange}
                      onFocus={this.handleBlur}
                      placeholder="Name"
                      required
                      value={this.state.firstName}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="mobile">
                    {this.state.mobile.length < 12 && (
                      <span
                        style={{
                          color: "#000",
                          fontSize: "12px",
                          //   textAlign: "center",
                          fontWeight: "500",
                          lineHeight: "15px",
                          display: "inline-block",
                        }}
                      >
                        <span style={{ color: "red" }}>* </span> Please enter
                        the phone number to receive the order confirmation text.
                      </span>
                    )}
                    <PhoneInput
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      disableDropdown={true}
                      country={"us"}
                      placeholder="Phone"
                      inputclassName="phoneinput"
                      onFocus={this.handleBlur}
                      value={this.state.mobile}
                      onChange={(mobile) => {
                        this.setState({ mobile: `+${mobile}` });
                        if (`+${mobile}`.length < 12) {
                          document.querySelector(
                            ".react-tel-input input"
                          ).style.boxShadow = "none";
                        }
                      }}
                    />
                  </Form.Group>
                </Form.Row>
                {/* <Form.Row>
                  <Form.Group as={Col} controlId="notes">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      onChange={this.handleChange}
                      placeholder="Notes"
                      required
                      value={this.state.notes}
                    />
                  </Form.Group>
                </Form.Row> */}

                {this.state.edit ? (
                  <Button
                    block
                    variant="warning"
                    type="submit"
                    disabled={this.validateFormAddress()}
                    onClick={this.updateUser}
                  >
                    Update
                  </Button>
                ) : (
                  <></>
                )}
              </Form>
            )}
          </Card>
        </Col>
        <Modal
          show={this.state.modalShow}
          size="md"
          aria-labelledby="ordersucess"
          centered
        >
          <Modal.Body>
            <>
              <p
                style={{
                  textAlign: "center",
                  margin: "0px 0 6px 0",
                  paddingTop: " 12px",
                  fontSize: "18px",
                }}
              >
                Your Order ID
              </p>
              <p style={{ textAlign: "center", paddingBottom: "10px" }}>
                {" "}
                <b>{this.state.orderId}</b>
              </p>
              <p style={{ textAlign: "center" }}>
                {/* Your order has been received. Your order will be ready for pick
                up in 10 minutes. */}
                {this.props.restaurant.id.toLowerCase() ===
                "roadhouse-48".toLowerCase()
                  ? `Your order has been received. Your order will be ready within 20-30 minutes.`
                  : this.state.tableId
                  ? `Your order has been received`
                  : `Your order has been received. Your order will be ready for
                pick up in ${this.props.restaurant.deliverytime} minutes.`}
              </p>
              {this.state.points ? (
                <p style={{ textAlign: "center" }}>
                  {`You have earned ${this.state.points} points as a reward for this order.`}
                </p>
              ) : (
                <></>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ marginBottom: "0" }} onClick={this.handleConinue}>
              Continue
            </Button>
            {/* <Link
              style={{ marginBottom: "0" }}
              to={`/${this.props.restaurant.id}/feedback`}
              state={this.state.orderId}
            >
              Continue
            </Link> */}
          </Modal.Footer>
        </Modal>
        <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={5}>
          {this.state.tableId ? (
            <CartTableCheckout
              handleNewOrder={this.handleNewOrder}
              disabled={this.state.isDisable}
              buttonText={this.translatedWord("Place Order")}
              payment={false}
              total={this.props.total}
              totalPrice={round(this.props.totalPrice)}
              tablePayment={true}
            />
          ) : (
            <CartTotalsCheckout
              handleNewOrder={this.handleNewOrder}
              disabled={this.state.isDisable}
              buttonText={this.translatedWord("Place Order")}
              payment={false}
              total={this.props.total}
              totalPrice={round(this.props.totalPrice)}
            />
          )}
        </Col>
      </Row>
    );
    // eslint-disable-next-line
    const needToLogin = (
      <div className="text-center">
        <h2>You must Login or Signup to checkout</h2>
        <Link to={`/${this.props.restaurant.id}/login-signup`}>
          <Button variant="warning" className="mt-4">
            To Login/Signup
          </Button>
        </Link>
      </div>
    );
    return (
      <>
        {/* <BrandingHeader /> */}
        <div className="cart-header">
          <p>{this.props.restaurant.restaurant.name}</p>
          <button
            onClick={() => window.history.back()}
            style={{
              padding: "5px 10px",
              background: "none",
              border: "0",
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            <FontAwesomeIcon icon={faClose} size={"1x"} />
          </button>
        </div>
        <React.Fragment>
          <Container
            className="my-auto Checkout"
            style={{ opacity: this.state.loader ? "0.4" : "1" }}
          >
            {checkout}
            {/* {this.props.loggedIn ? checkout : needToLogin} */}
          </Container>
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    total: state.cart.total,
    totalPrice: state.cart.totalPrice,
    tip: state.cart.tip,
    restaurant: state.restaurant,
    customer: state.customer,
    loggedIn: state.customer.isLoggedIn,
    tax: state.restaurant.totalTax,
    tablePath: state.restaurant.tablePath,
    cart: state.cart,
    checkout: state.checkout,
    table: state.table,
    orderCart: state.cart.orderCart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Set: (title, num) => {
      dispatch(Set(title, num));
    },
    Reset: (title, num) => {
      dispatch(Reset(title, num));
    },
  };
};
const CloverPayCash = (props) => {
  const location = useLocation();
  // const paymentModeOnline = location.state?.paymentModeOnline;
  return <CloverPayCashCC {...props} />;
};
export default connect(mapStateToProps, mapDispatchToProps)(CloverPayCash);
