import React, { Component } from "react";
import Skeleton from 'react-loading-skeleton'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const lists = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class ProductLoader extends Component {

    render() {
        return (
            <>
                <div className="Delivery container">
                    <div className="search-filter" style={{ marginBottom: '15px' }}>
                        <Skeleton count={4} style={{ width: "100%" }} />
                    </div>
                    <Row className="pb-3">
                        {
                            lists.map(lis => {
                                return (
                                    <Col key={lis} xs={10} sm={3} md={4} lg={4} className="mx-auto mx-sm-0">
                                        <div className="order-group">
                                            <div className="all-orders" >
                                                <div className="left-div" style={{ width: '100px' }}>
                                                    <Skeleton style={{ width: "100px", height: "100px" }} />
                                                </div>
                                                <div className="right-div" style={{ width: '100%' }}>
                                                    <Skeleton count={4} style={{ width: "100%" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row></div>

            </>
        );
    }
}

export default ProductLoader;


