import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Container } from "react-bootstrap";
import { Form, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import "../pages.css";
import { Spinner } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import { compose } from "redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { MdCancel, MdModeEdit } from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import { toast } from "react-toastify";
// function withRouter(Component) {
//   function ComponentWithRouterProp(props) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     return <Component {...props} router={{ location, navigate, params }} />;
//   }

//   return ComponentWithRouterProp;
// }

async function warnRedBox(id, action = "add") {
  if (action === "add") {
    document.getElementById(id).style.boxShadow =
      "#de3737 0px 0px 3px 0px, #b33a3a 0px 0px 7px 0px";
  } else {
    document.getElementById(id).style.boxShadow = "none";
  }
}
async function warnNotify(message) {
  toast.error(message);
}
function AddModifiersGroup(props) {
  const navigate = useNavigate();
  const [modifers, setModifers] = useState({});
  const [modiferListName, setModiferListName] = useState("");
  const [modiferSingle, setModiferSigle] = useState(false);
  const [modifersName, setModifersName] = useState([]);
  const [modifersPrice, setModifersPrice] = useState([]);
  async function handleSave() {
    let isValidModifersName = false;
    let arrValidModId = [];
    let formatedModifier = {};
    Object.values(modifers).forEach((value, key) => {
      let { name, id, price } = value;
      if (name || price) {
        formatedModifier[id] = value;
      }
    });
    if (Object.values(formatedModifier)?.length) {
      Object.values(formatedModifier).forEach((ite) => {
        if (ite.name) {
          isValidModifersName = true;
        } else {
          isValidModifersName = false;
          arrValidModId.push(ite.id);
        }
      });
    }
    if (modiferListName.length > 0 && isValidModifersName) {
      let object = {};
      let modifier_list_data = {};
      let dummyModListId = `#${new Date().getTime() - 10}`;
      const modifiersData = Object.values(formatedModifier).map((ite, i) => {
        const { name, price } = ite;
        let temp = {
          type: "MODIFIER",
          id: `#${new Date().getTime() + i}`,
          present_at_all_locations: false,
          modifier_data: {
            name: name,
            modifier_list_id: dummyModListId,
            //   "ordinal": 3,
          },
        };
        if (price) {
          temp.modifier_data.price_money = {
            amount: price,
            currency: "USD",
          };
        }
        return temp;
      });

      object = {
        type: "MODIFIER_LIST",
        is_deleted: false,
        present_at_all_locations: false,
        id: dummyModListId,
        modifier_list_data: {
          name: modiferListName,
          selection_type: modiferSingle ? "SINGLE" : "MULTIPLE",
          modifiers: modifiersData,
        },
      };
      let res = await fetch(
        `/api/v1/merchant/add/square/modifierList/${props.restaurant.id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ object }),
        }
      );
      if (res) {
        navigate("/merchant/modifiers");
        toast.success("New modifier added");
      }
    } else {
      let id;
      let message;
      if (!modiferListName.length > 0) {
        id = "modlist-name";
        message = "Please enter a name for your modifier set.";
        warnRedBox(id);
      } else if (!isValidModifersName) {
        console.log(arrValidModId);
        if (arrValidModId.length) {
          arrValidModId.forEach((iteId) => {
            warnRedBox(iteId);
          });
          message = "Please fill the modifiers";
        } else {
          id = "1";
          message = "Please fill a modifier";
          warnRedBox(id);
        }
      }
      warnNotify(message);
    }
  }
  async function handleModifier(e) {
    const { id, value, name } = e.target;
    warnRedBox(id, "remove");
    switch (name) {
      case "price":
        let price = Math.round(Number(value) * 100);
        if (modifers[id]) {
          setModifers((prevstate) => {
            return { ...prevstate, [id]: { ...prevstate[id], price, id } };
          });
        } else {
          setModifers((prevstate) => {
            return { ...prevstate, [id]: { price, id } };
          });
        }
        break;
      case "name":
        if (modifers[id]) {
          setModifers((prevstate) => {
            return {
              ...prevstate,
              [id]: { ...prevstate[id], name: value, id },
            };
          });
        } else {
          setModifers((prevstate) => {
            return { ...prevstate, [id]: { name: value, id } };
          });
        }
        break;
      default:
        console.log("No Name");
    }
  }
  return (
    <>
      <div className="d-flex justify-content-between">
        <div></div>
        <div className="d-flex justify-content-between" style={{ gap: "12px" }}>
          {/* <Button>Cancel</Button> */}
          <Button onClick={handleSave}>Save</Button>
        </div>
      </div>

      <div>
        <div data-test-dialog-content>
          <form>
            <fieldset className="grid-row">
              <Form.Control
                onChange={(e) => {
                  setModiferListName(e.target.value);
                  warnRedBox(e.target.id, "remove");
                }}
                placeholder="Name"
                maxLength={255}
                type="text"
                id="modlist-name"
                name="name"
                autofocus
                style={{ width: "50%" }}
              />
            </fieldset>
            <hr />
            <fieldset>
              <legend>Modifiers</legend>
              <label
                data-test-option-checkbox="Customer can only select one modifier"
                className="d-flex"
                style={{ gap: "8px" }}
              >
                <span data-test-checkbox>
                  <Form.Control
                    onChange={(e) => {
                      setModiferSigle(!modiferSingle);
                    }}
                    type="checkbox"
                    size="sm"
                  />
                </span>
                <div>
                  <p style={{ margin: 0 }}>
                    Customer can only select one modifier
                  </p>
                  {/* <small>
                    The first modifier option in your set will become the
                    default.
                  </small> */}
                </div>
              </label>
              <div id="ember417" className="ember-view">
                <div
                  id="ember420"
                  className="input-list-row l-item-modifiers-edit-option ember-view"
                />
                <table className="table table--selectable">
                  <thead>
                    <tr className="table-row">
                      <th className="table-cell-header">Modifier</th>
                      <th className="table-cell-header--col-10-24">Price $</th>
                    </tr>
                  </thead>
                  <tbody tabIndex={-1}>
                    {new Array(1, 2, 3, 4, 5).map((id, i) => {
                      return (
                        <tr style={{ position: "relative" }}>
                          <td className="table-cell">
                            <div className="l-modifier-option__table-cell">
                              <div>
                                <Form.Control
                                  onChange={handleModifier}
                                  placeholder="New Modifier"
                                  maxLength={255}
                                  type="text"
                                  id={id}
                                  name="name"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="table-cell">
                            <div>
                              <div>
                                <div>
                                  <Form.Control
                                    onChange={handleModifier}
                                    placeholder="$0.00"
                                    type="text"
                                    autoCapitalize="off"
                                    name="price"
                                    maxLength={5}
                                    id={id}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    restaurant: state.restaurant,
  };
};
export default connect(mapStateToProps, null)(AddModifiersGroup);