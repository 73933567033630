import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import CartTableItem from "../components/CartTableItem";
import CartTableItemSide from "../components/CartTableItemSide";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import CartTotalsCart from "../components/CartTotalsCart";
import { round } from "../utils/functions";
import HoverPopup from "../components/HoverPopup";
import { Nav } from "react-bootstrap";
import emptyCart from '../assets/empty-cart.jpg';
import MultiLingualContent from "../context/multilingualContent";
const capitalizeFirstLetter = (str) =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
function CartSide(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cartTableItemRender = (item, i) => (
    <CartTableItemSide
      key={i}
      index={i}
      id={item}
      img={item.img}
      title={item.title}
      price={item.price}
      num={item.num}
      modifierGroups={item.modifierGroups}
      modifierPrice={item.modifiersPrice ? item.modifiersPrice : 0}
      modifiers={item.modifiers}
      instructions={item.instructions}

    />
  );

  const full = (
    <>
      <p>
        <MultiLingualContent contentID="Cart" />{" "}
        <span
          style={{
            background: "#000",
            padding: "5px 14px",
            borderRadius: "20px",
            color: "#fff",
            fontWeight: "600",
          }}
        >
          {props.total}
        </span>
        <br />
        <span className="piklocation">
          <MultiLingualContent contentID="from" />{" "}
          <span style={{ color: "#ec7211" }}>
            {" "}
            {capitalizeFirstLetter(
              props.restaurant.restaurant.name.replace(/-/g, " ")
            )}{" "}
            ({/* {props.restaurant.address.city}, */}{" "}
            {props.restaurant.address.address1}{" "}
            {props.restaurant.address.address2})
          </span>
        </span>
      </p>
      <Row>
        <Col xs={12} lg={11}>
          {props.orderCart.map((item, i) => cartTableItemRender(item, i))}

          <div>
            <Nav className="cart-btn-new">
              {window.location.href.includes("orders") ? <></> : <HoverPopup />}
            </Nav>
          </div>
        </Col>

        <Col className="ml-lg-5 pl-lg-2 mt-4 mt-lg-0" xs={12} lg={3}>
          {/* <CartTotalsCart
              disabled={false}
              buttonText="Checkout"
              total={props.total}
              totalPrice={round(props.totalPrice)}
            /> */}
        </Col>
      </Row>
    </>
  );
  const empty = (
    <>
      <p>
        <MultiLingualContent contentID="Cart" />{" "}
        <span
          style={{
            background: "#000",
            padding: "5px 15px",
            borderRadius: "20px",
            color: "#fff",
            fontWeight: "600",
          }}
        >
          {props.total}
        </span>
        <br />
        <span className="piklocation">
          <MultiLingualContent contentID="from" />{" "}
          <span style={{ color: "#ec7211" }}>
            {" "}
            {props.restaurant.restaurant.name} (
            {props.restaurant.address.address1}{" "}
            {props.restaurant.address.address2})
          </span>
        </span>
      </p>
      <div
        className="no-items"
        style={{
          textAlign: "center",
          padding: "3em 0 0",
          fontWeight: "600",
          fontSize: "19px",
        }}
      >
        <MultiLingualContent contentID="no_items" />
        <div className="emt-cart">
          <img src={emptyCart} alt="Empty Cart" />
        </div>
      </div>
    </>
  );

  return (
    <>
      <React.Fragment>
        <Container className="my-auto Cart side" style={{ minHeight: "200px" }}>
          {props.total > 0 ? full : empty}
        </Container>
      </React.Fragment>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.items,
    orderCart: state.cart.orderCart,
    total: state.cart.total,
    totalPrice: state.cart.totalPrice,
    restaurant: state.restaurant,
  };
};

export default connect(mapStateToProps)(CartSide);